/* eslint-disable no-nested-ternary */
import React from 'react'
import { connect } from 'react-redux'
import { Modal, Image, Form, InputGroup, Tabs, Tab, Toast } from 'react-bootstrap'
import { isMobile } from 'react-device-detect'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import copy from 'copy-to-clipboard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import globalConstant from '../../../store/constant/global'
import HookHelper from '../../../helper/hookhelper'
import Formatter from '../../../helper/formatter'

import './dialogDeposit.scss'

const DepositVaModal = ({ onHide, show, dataRes }) => {
  const [tab, setTab] = React.useState('0')
  const [atm, setAtm] = React.useState([])
  const [ibank, setIbank] = React.useState([])
  const [mobileBank, setMobileBank] = React.useState([])
  const [toastShow, setToastShow] = React.useState(false)
  const [toastMsg, setToastMSg] = React.useState('')

  const closeMessage = React.useCallback(() => setToastShow(false), [])

  const onClose = React.useCallback(async () => {
    setTab('0')
    onHide()
  }, [setTab, onHide])

  const atmView = React.useCallback(async () => {
    if (dataRes) {
      if (dataRes.bank.includes('bri')) {
        setAtm(
          <div style={{ marginTop: '10px' }}>
            <div style={{ padding: '5px', display: 'flex', flexDirection: 'row' }}>
              <span style={{ marginRight: '10px' }}>1.</span>
              <span>Pilih Transaksi Lain &gt; Pembayaran &gt; Lainnya &gt; BRIVA.</span>
            </div>
            <div style={{ padding: '5px', display: 'flex', flexDirection: 'row' }}>
              <span style={{ marginRight: '10px' }}>2.</span>
              <span>Masukkan Nomor Rekening Topip BRIVA Anda.</span>
            </div>
            <div style={{ padding: '5px', display: 'flex', flexDirection: 'row' }}>
              <span style={{ marginRight: '10px' }}>3.</span>
              <span>
                Pastikan Data dilayar sesuai dengan nama :{' '}
                <span className="bold color-primary">{dataRes ? dataRes.va_username : '-'}</span>
              </span>
            </div>
          </div>
        )
      } else if (dataRes.bank.includes('permata')) {
        setAtm(
          <div style={{ marginTop: '10px' }}>
            <div style={{ padding: '5px', display: 'flex', flexDirection: 'row' }}>
              <span style={{ marginRight: '10px' }}>1.</span>
              <span>Kunjungi ATM PERMATA Terdekat.</span>
            </div>
            <div style={{ padding: '5px', display: 'flex', flexDirection: 'row' }}>
              <span style={{ marginRight: '10px' }}>2.</span>
              <span>
                Pilih Transaksi Lainnya. Kemudian pilih Transfer. Berikutnya ke rekening PERMATA
                Virtual Account.
              </span>
            </div>
            <div style={{ padding: '5px', display: 'flex', flexDirection: 'row' }}>
              <span style={{ marginRight: '10px' }}>3.</span>
              <span>Masukkan nomor PERMATA Virtual ACcount (PERMATA VA) dan nominal deposit.</span>
            </div>
            <div style={{ padding: '5px', display: 'flex', flexDirection: 'row' }}>
              <span style={{ marginRight: '10px' }}>4.</span>
              <span>
                Pastikan rekening yang dituju atas nama :{' '}
                <span className="bold color-primary">{dataRes ? dataRes.va_username : '-'}</span>
              </span>
            </div>
            <div style={{ padding: '5px', display: 'flex', flexDirection: 'row' }}>
              <span style={{ marginRight: '10px' }}>5.</span>
              <span>Ikuti instruksi untuk menyelesaikan transaksi.</span>
            </div>
          </div>
        )
      } else if (dataRes.bank.includes('mandiri')) {
        setAtm(
          <div style={{ marginTop: '10px' }}>
            <div style={{ padding: '5px', display: 'flex', flexDirection: 'row' }}>
              <span style={{ marginRight: '10px' }}>1.</span>
              <span>Pergi menuju gerai ATM Mandiri.</span>
            </div>
            <div style={{ padding: '5px', display: 'flex', flexDirection: 'row' }}>
              <span style={{ marginRight: '10px' }}>2.</span>
              <span>Masukkan kartu ATM dan tekan PIN.</span>
            </div>
            <div style={{ padding: '5px', display: 'flex', flexDirection: 'row' }}>
              <span style={{ marginRight: '10px' }}>3.</span>
              <span>
                Selanjutnya pilih menu Bayar/Beli &gt; Lainnya &gt; lainnya &gt; Multi Payment.
              </span>
            </div>
            <div style={{ padding: '5px', display: 'flex', flexDirection: 'row' }}>
              <span style={{ marginRight: '10px' }}>4.</span>
              <span>
                {' '}
                Masukkan kode perusahaan 88898 (WINPAY) dan pilih menu &quot;Benar&quot;.
              </span>
            </div>
            <div style={{ padding: '5px', display: 'flex', flexDirection: 'row' }}>
              <span style={{ marginRight: '10px' }}>5.</span>
              <span>
                {' '}
                Masukkan nomor virtual account Anda dan kemudian pilih &quot;Benar&quot;.
              </span>
            </div>
            <div style={{ padding: '5px', display: 'flex', flexDirection: 'row' }}>
              <span style={{ marginRight: '10px' }}>6.</span>
              <span>
                Periksa halaman informasi pembayaran, jika sudah benar maka pilih menu angka
                &quot;1&quot; kemudian pilih &quot;YA&quot;.
              </span>
            </div>
            <div style={{ padding: '5px', display: 'flex', flexDirection: 'row' }}>
              <span style={{ marginRight: '10px' }}>7.</span>
              <span>
                Periksa kembali layar tentang konfirmasi pembayaran lalu pilih &quot;YA&quot;.
              </span>
            </div>
            <div style={{ padding: '5px', display: 'flex', flexDirection: 'row' }}>
              <span style={{ marginRight: '10px' }}>8.</span>
              <span>Proses selesai.</span>
            </div>
          </div>
        )
      } else if (dataRes.bank.includes('bni')) {
        setAtm(
          <div style={{ marginTop: '10px' }}>
            <div style={{ padding: '5px', display: 'flex', flexDirection: 'row' }}>
              <span style={{ marginRight: '10px' }}>1.</span>
              <span>Pada ATM, Pilih opsi Transaksi Lainnya, lalu pilih opsi Transfer.</span>
            </div>
            <div style={{ padding: '5px', display: 'flex', flexDirection: 'row' }}>
              <span style={{ marginRight: '10px' }}>2.</span>
              <span>Kemudian pilih Rekening tabungan, kemudian pilih Rekening BNI Anda.</span>
            </div>
            <div style={{ padding: '5px', display: 'flex', flexDirection: 'row' }}>
              <span style={{ marginRight: '10px' }}>3.</span>
              <span>Masukkan nomor Virtual Account dan nominal topup.</span>
            </div>
            <div style={{ padding: '5px', display: 'flex', flexDirection: 'row' }}>
              <span style={{ marginRight: '10px' }}>4.</span>
              <span>
                Pastikan rekening yang dituju atas nama :{' '}
                <span className="bold color-primary">{dataRes ? dataRes.va_username : '-'}</span>
              </span>
            </div>
            <div style={{ padding: '5px', display: 'flex', flexDirection: 'row' }}>
              <span style={{ marginRight: '10px' }}>5.</span>
              <span>
                Ikuti instruksi untuk menyelesaikan transfer. Transfer berhasil dan Saldo SpeedCash
                Anda akan bertambah secara otomatis.
              </span>
            </div>
          </div>
        )
      } else {
        setAtm(<div>Tidak dapat menemukan data.</div>)
      }
    } else {
      setAtm(<div>Tidak dapat menemukan data.</div>)
    }
  }, [dataRes, setAtm])

  const ibankView = React.useCallback(async () => {
    if (dataRes) {
      if (dataRes.bank.includes('bri')) {
        setIbank(
          <div style={{ marginTop: '10px' }}>
            <div style={{ padding: '5px', display: 'flex', flexDirection: 'row' }}>
              <span style={{ marginRight: '10px' }}>1.</span>
              <span>Pilih Menu Pembayaran Internet.</span>
            </div>
            <div style={{ padding: '5px', display: 'flex', flexDirection: 'row' }}>
              <span style={{ marginRight: '10px' }}>2.</span>
              <span>Pilih Menu BRIVA.</span>
            </div>
            <div style={{ padding: '5px', display: 'flex', flexDirection: 'row' }}>
              <span style={{ marginRight: '10px' }}>3.</span>
              <span>
                Masukkan Kode Bayar dengan 15 digit Nomor Rekening Virtual Account (
                <span className="bold color-primary">
                  {dataRes ? Formatter.serial(dataRes.va_number, 20) : '-'}
                </span>
                ).
              </span>
            </div>
            <div style={{ padding: '5px', display: 'flex', flexDirection: 'row' }}>
              <span style={{ marginRight: '10px' }}>4.</span>
              <span>Masukkan nominal transfer yang Anda inginkan.</span>
            </div>
            <div style={{ padding: '5px', display: 'flex', flexDirection: 'row' }}>
              <span style={{ marginRight: '10px' }}>5.</span>
              <span>Pastikan Data yang tampil di layar sudah sesuai.</span>
            </div>
            <div style={{ padding: '5px', display: 'flex', flexDirection: 'row' }}>
              <span style={{ marginRight: '10px' }}>6.</span>
              <span>Masukkan Password iBanking dan mToken Internet Banking Anda.</span>
            </div>
            <div style={{ padding: '5px', display: 'flex', flexDirection: 'row' }}>
              <span style={{ marginRight: '10px' }}>7.</span>
              <span>Anda Mendapatkan Notifikasi Pembayaran.</span>
            </div>
          </div>
        )
      } else if (dataRes.bank.includes('permata')) {
        setIbank(
          <div style={{ marginTop: '10px' }}>
            <div style={{ padding: '5px', display: 'flex', flexDirection: 'row' }}>
              <span style={{ marginRight: '10px' }}>1.</span>
              <span>Buka new.permatanet.com</span>
            </div>
            <div style={{ padding: '5px', display: 'flex', flexDirection: 'row' }}>
              <span style={{ marginRight: '10px' }}>2.</span>
              <span>Masukkan username dan password i-banking PERMATA VA Anda.</span>
            </div>
            <div style={{ padding: '5px', display: 'flex', flexDirection: 'row' }}>
              <span style={{ marginRight: '10px' }}>3.</span>
              <span>
                Kemudian pilih Pembayaran Tagihan. Pilih Pembayaran kemudian pilih PERMATA VA.
              </span>
            </div>
            <div style={{ padding: '5px', display: 'flex', flexDirection: 'row' }}>
              <span style={{ marginRight: '10px' }}>4.</span>
              <span>
                Masukkan nomor PERMATA Virtual Account (PERMATA VA) dan nominal deposit. Masukkan
                Password dan mToken, klik Kirim.
              </span>
            </div>
            <div style={{ padding: '5px', display: 'flex', flexDirection: 'row' }}>
              <span style={{ marginRight: '10px' }}>5.</span>
              <span>Ikuti instruksi untuk menyelesaikan transaksi.</span>
            </div>
          </div>
        )
      } else if (dataRes.bank.includes('mandiri')) {
        setIbank(
          <div style={{ marginTop: '10px' }}>
            <div style={{ padding: '5px', display: 'flex', flexDirection: 'row' }}>
              <span style={{ marginRight: '10px' }}>1.</span>
              <span>Buka ib.bankmandiri.co.id</span>
            </div>
            <div style={{ padding: '5px', display: 'flex', flexDirection: 'row' }}>
              <span style={{ marginRight: '10px' }}>2.</span>
              <span>Masukkan username dan password i-banking MANDIRI Anda.</span>
            </div>
            <div style={{ padding: '5px', display: 'flex', flexDirection: 'row' }}>
              <span style={{ marginRight: '10px' }}>3.</span>
              <span>
                Kemudian pilih Menu bayar. Dan pilih Menu Multi Payment. Pilih penyedia jasa WINPAY.
              </span>
            </div>
            <div style={{ padding: '5px', display: 'flex', flexDirection: 'row' }}>
              <span style={{ marginRight: '10px' }}>4.</span>
              <span>
                Masukkan Nomor VA Mandiri dan nominal. Pastikan rekening yang dituju atas nama
                sesuai akun dan nomor VA. Klik centang top up SpeedCash.
              </span>
            </div>
            <div style={{ padding: '5px', display: 'flex', flexDirection: 'row' }}>
              <span style={{ marginRight: '10px' }}>5.</span>
              <span>Ikuti instruksi untuk menyelesaikan transaksi.</span>
            </div>
          </div>
        )
      } else if (dataRes.bank.includes('bni')) {
        setIbank(
          <div style={{ marginTop: '10px' }}>
            <div style={{ padding: '5px', display: 'flex', flexDirection: 'row' }}>
              <span style={{ marginRight: '10px' }}>1.</span>
              <span>
                Login ke internet banking BNI Anda, pilih menu Transfer, pilih menu Transfer sesama
                BNI.
              </span>
            </div>
            <div style={{ padding: '5px', display: 'flex', flexDirection: 'row' }}>
              <span style={{ marginRight: '10px' }}>2.</span>
              <span>Pilih rekening yang akan Anda gunakan untuk transfer.</span>
            </div>
            <div style={{ padding: '5px', display: 'flex', flexDirection: 'row' }}>
              <span style={{ marginRight: '10px' }}>3.</span>
              <span>
                Masukkan nomor Virtual Account BNI Anda pada kolom nomor rekening tujuan. Masukkan
                jumlah topip pada kolom jumlah.
              </span>
            </div>
            <div style={{ padding: '5px', display: 'flex', flexDirection: 'row' }}>
              <span style={{ marginRight: '10px' }}>4.</span>
              <span>
                Pastikan rekening tujuan atas nama:
                <span className="bold color-primary">{dataRes ? dataRes.va_username : '-'}</span> .
                Kemudian aktifkan token BNI e-Secure Anda, masukkan kode challenge yang tertera.
              </span>
            </div>
            <div style={{ padding: '5px', display: 'flex', flexDirection: 'row' }}>
              <span style={{ marginRight: '10px' }}>5.</span>
              <span>
                Masukkan kode otentifikasi dari token BNI e-Secure Anda, kemudian klik lanjut.
                Transfer selesai dan Saldo Speedcash Anda akan bertambah secara otomatis.
              </span>
            </div>
          </div>
        )
      } else {
        setIbank(<div>Tidak dapat menemukan data.</div>)
      }
    } else {
      setIbank(<div>Tidak dapat menemukan data.</div>)
    }
  }, [dataRes, setIbank])

  const mobileView = React.useCallback(async () => {
    if (dataRes) {
      if (dataRes.bank.includes('bri')) {
        setMobileBank(
          <div style={{ marginTop: '10px' }}>
            <div style={{ padding: '5px', display: 'flex', flexDirection: 'row' }}>
              <span style={{ marginRight: '10px' }}>1.</span>
              <span>Pilih menu Pembayaran Mobile.</span>
            </div>
            <div style={{ padding: '5px', display: 'flex', flexDirection: 'row' }}>
              <span style={{ marginRight: '10px' }}>2.</span>
              <span>Pilih Menu BRIVA.</span>
            </div>
            <div style={{ padding: '5px', display: 'flex', flexDirection: 'row' }}>
              <span style={{ marginRight: '10px' }}>3.</span>
              <span>
                Masukkan Kode Bayar dengan 15 digit Nomor Rekening Virtual Account (
                <span className="bold color-primary">
                  {dataRes ? Formatter.serial(dataRes.va_number, 20) : '-'}
                </span>
                ).
              </span>
            </div>
            <div style={{ padding: '5px', display: 'flex', flexDirection: 'row' }}>
              <span style={{ marginRight: '10px' }}>4.</span>
              <span>Masukkan nominal transfer yang Anda inginkan.</span>
            </div>
            <div style={{ padding: '5px', display: 'flex', flexDirection: 'row' }}>
              <span style={{ marginRight: '10px' }}>5.</span>
              <span>Pastikan Data yang tampil di layar sudah sesuai.</span>
            </div>
            <div style={{ padding: '5px', display: 'flex', flexDirection: 'row' }}>
              <span style={{ marginRight: '10px' }}>6.</span>
              <span>Masukkan Password iBanking dan mToken Internet Banking Anda.</span>
            </div>
            <div style={{ padding: '5px', display: 'flex', flexDirection: 'row' }}>
              <span style={{ marginRight: '10px' }}>7.</span>
              <span>Anda Mendapatkan Notifikasi Pembayaran.</span>
            </div>
          </div>
        )
      } else if (dataRes.bank.includes('permata')) {
        setMobileBank(
          <div style={{ marginTop: '10px' }}>
            <div style={{ padding: '5px', display: 'flex', flexDirection: 'row' }}>
              <span style={{ marginRight: '10px' }}>1.</span>
              <span>Buka aplikasi PERMATA VA Mobile.</span>
            </div>
            <div style={{ padding: '5px', display: 'flex', flexDirection: 'row' }}>
              <span style={{ marginRight: '10px' }}>2.</span>
              <span>Pilih Mobile Banking. Kemudian pilih Pembayaran, lalu pilih PERMATA VA.</span>
            </div>
            <div style={{ padding: '5px', display: 'flex', flexDirection: 'row' }}>
              <span style={{ marginRight: '10px' }}>3.</span>
              <span>Masukkan nomor PERMATA Virtual Account (PERMATA VA) dan nominal deposit.</span>
            </div>
            <div style={{ padding: '5px', display: 'flex', flexDirection: 'row' }}>
              <span style={{ marginRight: '10px' }}>4.</span>
              <span>Masukkan PIN PERMATA VA Anda, tekan OK/Kirim</span>
            </div>
            <div style={{ padding: '5px', display: 'flex', flexDirection: 'row' }}>
              <span style={{ marginRight: '10px' }}>5.</span>
              <span>Ikuti instruksi untuk menyelesaikan transaksi.</span>
            </div>
          </div>
        )
      } else if (dataRes.bank.includes('mandiri')) {
        setMobileBank(
          <div style={{ marginTop: '10px' }}>
            <div style={{ padding: '5px', display: 'flex', flexDirection: 'row' }}>
              <span style={{ marginRight: '10px' }}>1.</span>
              <span>Buka Mobile banking MANDIRI Anda.</span>
            </div>
            <div style={{ padding: '5px', display: 'flex', flexDirection: 'row' }}>
              <span style={{ marginRight: '10px' }}>2.</span>
              <span>Masukkan username dan password mobile banking MANDIRI Anda.</span>
            </div>
            <div style={{ padding: '5px', display: 'flex', flexDirection: 'row' }}>
              <span style={{ marginRight: '10px' }}>3.</span>
              <span>
                Kemudian pilih Menu bayar. Pilih Menu Multi Payment. Pilih penyedia jasa WINPAY.
              </span>
            </div>
            <div style={{ padding: '5px', display: 'flex', flexDirection: 'row' }}>
              <span style={{ marginRight: '10px' }}>4.</span>
              <span>
                Masukkan nomor MANDIRI Virtual Account (MANDIRI VA) dan nominal deposit. Pastikan
                rekening yang dituju atas nama sesuai akun dan nomor VA.
              </span>
            </div>
            <div style={{ padding: '5px', display: 'flex', flexDirection: 'row' }}>
              <span style={{ marginRight: '10px' }}>5.</span>
              <span>Ikuti instruksi untuk menyelesaikan transaksi.</span>
            </div>
          </div>
        )
      } else if (dataRes.bank.includes('bni')) {
        setMobileBank(
          <div style={{ marginTop: '10px' }}>
            <div style={{ padding: '5px', display: 'flex', flexDirection: 'row' }}>
              <span style={{ marginRight: '10px' }}>1.</span>
              <span>
                Login ke internet banking BNI Anda, pilih menu Transfer, pilih menu Transfer sesama
                BNI.
              </span>
            </div>
            <div style={{ padding: '5px', display: 'flex', flexDirection: 'row' }}>
              <span style={{ marginRight: '10px' }}>2.</span>
              <span>Pilih rekening yang akan Anda gunakan untuk transfer.</span>
            </div>
            <div style={{ padding: '5px', display: 'flex', flexDirection: 'row' }}>
              <span style={{ marginRight: '10px' }}>3.</span>
              <span>
                Masukkan nomor Virtual Account BNI Anda pada kolom nomor rekening tujuan. Masukkan
                jumlah topip pada kolom jumlah.
              </span>
            </div>
            <div style={{ padding: '5px', display: 'flex', flexDirection: 'row' }}>
              <span style={{ marginRight: '10px' }}>4.</span>
              <span>
                Pastikan rekening tujuan atas nama:
                <span className="bold color-primary">{dataRes ? dataRes.va_username : '-'}</span>.
                Kemudian aktifkan token BNI e-Secure Anda, masukkan kode challenge yang tertera.
              </span>
            </div>
            <div style={{ padding: '5px', display: 'flex', flexDirection: 'row' }}>
              <span style={{ marginRight: '10px' }}>5.</span>
              <span>
                Masukkan kode otentifikasi dari token BNI e-Secure Anda, kemudian klik lanjut.
                Transfer selesai dan Saldo Speedcash Anda akan bertambah secara otomatis.
              </span>
            </div>
          </div>
        )
      } else {
        setMobileBank(<div>Tidak dapat menemukan data.</div>)
      }
    } else {
      setMobileBank(<div>Tidak dapat menemukan data.</div>)
    }
  }, [dataRes, setMobileBank])

  const onTabChange = React.useCallback(
    (e) => {
      setTab(e)
    },
    [setTab]
  )

  const copyField = React.useCallback(
    (e) => {
      const { value, message } = e.currentTarget.dataset
      copy(value)
      setToastMSg(message)
      setToastShow(true)
    },
    [setToastMSg, setToastShow]
  )

  const openLivechat = React.useCallback(() => {
    onHide()
    window.$chatwoot.toggle('open')
  }, [onHide])

  React.useEffect(() => {
    HookHelper.resetLoading()
    atmView()
    ibankView()
    mobileView()
  }, [atmView, ibankView, mobileView])

  return (
    <Modal
      show={show}
      onHide={onClose}
      backdrop="static"
      keyboard={false}
      centered
      contentClassName="radius pln"
      animation={false}
    >
      <Modal.Header className="header">
        <div style={{ width: '95%' }}>
          <Modal.Title className="title">
            {dataRes ? dataRes.va_title.split('-')[0] : 'Virtual Account'}
          </Modal.Title>
        </div>
        <div className="close-modal">
          <Image
            src="/icon/clear.png"
            onClick={onHide}
            roundedCircle
            className="justify-content-end"
          />
        </div>
      </Modal.Header>

      <Modal.Body className="body">
        <div className="desc">
          <Form.Label className="label-bold txt-16">Nomor Virtual Account</Form.Label>
          <div className="desc-cont">
            <InputGroup.Text className={classNames('label-input-text', { 'is-mobile': isMobile })}>
              {dataRes ? Formatter.serial(dataRes.va_number, 20) : '-'}
            </InputGroup.Text>
            <InputGroup.Text
              className="txt-18 color-primary"
              style={{ justifyContent: 'flex-end', cursor: 'pointer' }}
              data-value={dataRes ? dataRes.va_number : '-'}
              data-message="Nomor Virtual Account Sudah Tersalin"
              onClick={copyField}
            >
              Salin
            </InputGroup.Text>
          </div>
        </div>

        <Tabs activeKey={tab} onSelect={onTabChange} transition={false} fill>
          <Tab eventKey="0" title="ATM">
            {atm}
            <div style={{ marginTop: '10px', display: 'flex', flexDirection: 'column' }}>
              {dataRes ? (
                parseFloat(dataRes.admin) > 0 ? (
                  <span style={{ fontStyle: 'italic', fontSize: '12px' }}>
                    Topup melalui Virtual Account dikenakan biaya admin Rp{' '}
                    {Formatter.price(Math.round(parseFloat(dataRes.admin)))}
                  </span>
                ) : (
                  ''
                )
              ) : (
                ''
              )}
              {dataRes ? (
                parseFloat(dataRes.corporate_fee) > 0 ? (
                  <span style={{ fontStyle: 'italic', fontSize: '12px' }}>
                    Top Up melalui Virtual Account dikenakan potongan saldo Rp{' '}
                    {Formatter.price(Math.round(parseFloat(dataRes.corporate_fee)))}
                  </span>
                ) : (
                  ''
                )
              ) : (
                ''
              )}
              <span style={{ fontStyle: 'italic', fontSize: '12px' }}>
                Minimal top up Rp 10.000
              </span>
            </div>
          </Tab>
          <Tab eventKey="1" title="Internet Banking">
            {ibank}
            <div style={{ marginTop: '10px', display: 'flex', flexDirection: 'column' }}>
              {dataRes ? (
                parseFloat(dataRes.admin) > 0 ? (
                  <span style={{ fontStyle: 'italic', fontSize: '12px' }}>
                    Topup melalui Virtual Account dikenakan biaya admin Rp{' '}
                    {Formatter.price(Math.round(parseFloat(dataRes.admin)))}
                  </span>
                ) : (
                  ''
                )
              ) : (
                ''
              )}
              {dataRes ? (
                parseFloat(dataRes.corporate_fee) > 0 ? (
                  <span style={{ fontStyle: 'italic', fontSize: '12px' }}>
                    Top Up melalui Virtual Account dikenakan potongan saldo Rp{' '}
                    {Formatter.price(Math.round(parseFloat(dataRes.corporate_fee)))}
                  </span>
                ) : (
                  ''
                )
              ) : (
                ''
              )}
              <span style={{ fontStyle: 'italic', fontSize: '12px' }}>
                Minimal top up Rp 10.000
              </span>
            </div>
          </Tab>
          <Tab eventKey="2" title="Mobile Banking">
            {mobileBank}
            <div style={{ marginTop: '10px', display: 'flex', flexDirection: 'column' }}>
              {dataRes ? (
                parseFloat(dataRes.admin) > 0 ? (
                  <span style={{ fontStyle: 'italic', fontSize: '12px' }}>
                    Topup melalui Virtual Account dikenakan biaya admin Rp{' '}
                    {Formatter.price(Math.round(parseFloat(dataRes.admin)))}
                  </span>
                ) : (
                  ''
                )
              ) : (
                ''
              )}
              {dataRes ? (
                parseFloat(dataRes.corporate_fee) > 0 ? (
                  <span style={{ fontStyle: 'italic', fontSize: '12px' }}>
                    Top Up melalui Virtual Account dikenakan potongan saldo Rp{' '}
                    {Formatter.price(Math.round(parseFloat(dataRes.corporate_fee)))}
                  </span>
                ) : (
                  ''
                )
              ) : (
                ''
              )}
              <span style={{ fontStyle: 'italic', fontSize: '12px' }}>
                Minimal top up Rp 10.000
              </span>
            </div>
          </Tab>
        </Tabs>
      </Modal.Body>
      <Modal.Footer style={{ position: 'sticky', bottom: 0 }}>
        <div className="button-back va">
          <span className="label-bold color-white" onClick={openLivechat} aria-hidden="true">
            Saldo belum masuk? Klik disini
          </span>
        </div>

        <div className="toast-div">
          <Toast onClose={closeMessage} show={toastShow} delay={3000} autohide animation={false}>
            <Toast.Header>
              <FontAwesomeIcon
                icon="info-circle"
                fixedWidth
                className="mr-3 color-primary txt-20"
              />
              <small className="color-primary">{toastMsg}</small>
            </Toast.Header>
          </Toast>
        </div>
      </Modal.Footer>
    </Modal>
  )
}

DepositVaModal.defaultProps = {
  onHide: null,
  show: false,
  dataRes: null,
}

DepositVaModal.propTypes = {
  onHide: PropTypes.func,
  show: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  dataRes: PropTypes.object,
}

export default connect(
  (state) => ({
    appIsLoading: state.Global[globalConstant.IS_LOADING],
  }),
  (dispatch) => ({ dispatch })
)(DepositVaModal)
