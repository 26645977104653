/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */
import React from 'react'
import { connect } from 'react-redux'
import { Row, Col, Container, Jumbotron, Button, Form } from 'react-bootstrap'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { isMobile } from 'react-device-detect'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import { CSVLink } from 'react-csv'
import { useHistory, useLocation } from 'react-router-dom'
import Alert from 'react-popup-alert'
import 'react-popup-alert/dist/index.css'
import ReactPaginate from 'react-paginate'
import DOMHelper from '../../../helper/domhelper'
import HookHelper from '../../../helper/hookhelper'
import DateTime from '../../../helper/datetime'
import Formatter from '../../../helper/formatter'

import './fm.scss'
import authConstant from '../../../store/constant/auth'
import SidebarComponent from '../../component/sidebar'
import HeaderComponent from '../../component/header'
import LoginModal from '../../dialog/auth/login'

import { reportFm } from '../../../api/action/fm/dashboard'
import 'bootstrap-daterangepicker/daterangepicker.css'

const headers = [
  { label: 'Tanggal Transaksi', key: 'tanggal_transaksi' },
  { label: 'ID Transaksi', key: 'id_transaksi' },
  { label: 'ID Outlet', key: 'id_outlet' },
  { label: 'Jumlah', key: 'jumlah' },
  { label: 'Keterangan', key: 'keterangan' },
]

const RapotFmScreen = ({ appuserId, appUserBalance, appUserIsEmbed, dispatch }) => {
  const history = useHistory()
  const location = useLocation()
  const isMounted = React.useRef(false)
  const limit = React.useMemo(() => 20, [])
  const offset = React.useRef(0)
  const [isOpen, setIsOpen] = React.useState(!isMobile)
  const [alertError, setAlertError] = React.useState('')
  const [loginModalShow, setLoginModalShow] = React.useState(false)
  const [dataRes, setDataRes] = React.useState([])
  const [dataResTotal, setDataResTotal] = React.useState(0)
  const [dataExport, setDataExport] = React.useState([])
  const [filter, setFilter] = React.useState([])
  const [searchTerm, setSearchTerm] = React.useState('')
  const [currentPage, setCurrentPage] = React.useState(0)
  const [startTgl, setStartTgl] = React.useState(
    location.state
      ? location.state.startTgl
        ? location.state.startTgl
        : DateTime.startOfMonth(DateTime.FMT_DATE_YMD)
      : DateTime.startOfMonth(DateTime.FMT_DATE_YMD)
  )
  const [endTgl, setEndTgl] = React.useState(
    location.state
      ? location.state.endTgl
        ? location.state.endTgl
        : DateTime.endOfMonth(DateTime.FMT_DATE_YMD)
      : DateTime.endOfMonth(DateTime.FMT_DATE_YMD)
  )
  const toggleSidebar = React.useCallback(() => setIsOpen(!isOpen), [isOpen, setIsOpen])
  const closeError = React.useCallback(() => setAlertError(''), [])

  const openLoginModal = React.useCallback(async () => {
    setLoginModalShow(true)
  }, [])

  const confirmLoginModal = React.useCallback(async () => {
    setLoginModalShow(false)
  }, [])

  const closeLoginModal = React.useCallback(async () => {
    setLoginModalShow('')
    history.push('/')
  }, [history])

  const processData = React.useCallback(async () => {
    const response = await dispatch(reportFm(appuserId, startTgl, endTgl, limit, offset.current))

    if (response.isOK()) {
      const resData = response.getData()
      const responseData = resData.data.map((value) => ({
        ...value,
        tanggal_transaksi: moment(value.tanggal_transaksi).format('YYYY-MM-DD'),
      }))
      setDataRes(responseData)
      setDataResTotal(resData.total)
    } else {
      setDataRes([])
      setDataResTotal(0)
    }
  }, [dispatch, appuserId, startTgl, endTgl, limit, offset, setDataRes, setDataResTotal])

  const getExportData = React.useCallback(async () => {
    const response = await dispatch(reportFm(appuserId, startTgl, endTgl, 1000000, 0))

    if (response.isOK()) {
      const resData = response.getData()
      const responseData = resData.data.map((value) => ({
        ...value,
        tanggal_transaksi: moment(value.tanggal_transaksi).format('YYYY-MM-DD'),
      }))
      setDataExport(responseData)
    }
  }, [dispatch, appuserId, startTgl, endTgl, setDataExport])

  const exportData = React.useCallback(async () => {
    await getExportData()
    document.getElementById('downloadCsv').click()
  }, [getExportData])

  const updateOffset = React.useCallback((value) => {
    offset.current = value
  }, [])

  const handleApply = React.useCallback(async () => {
    await processData()
    setSearchTerm('')
    setFilter([])
    setCurrentPage(0)
  }, [processData])

  const handleCallback = React.useCallback(
    (start, end) => {
      updateOffset(0)
      setStartTgl(moment(start).format('YYYY-MM-DD'))
      setEndTgl(moment(end).format('YYYY-MM-DD'))
    },
    [setStartTgl, setEndTgl, updateOffset]
  )

  const searchDataTrx = React.useCallback(
    (e) => {
      const { value } = e.target
      setSearchTerm(value)
      const displayData = dataRes.filter((el) => {
        return (
          el.id_transaksi.toLowerCase().includes(searchTerm.toLowerCase()) ||
          el.keterangan.toLowerCase().includes(searchTerm.toLowerCase())
        )
      })
      setFilter(displayData)
    },
    [searchTerm, setFilter, setSearchTerm, dataRes]
  )

  const handlePageChange = React.useCallback(
    (data) => {
      setSearchTerm('')
      setFilter([])
      setCurrentPage(data.selected)
      updateOffset((data.selected * limit) % dataResTotal)
      processData()
    },
    [limit, dataResTotal, updateOffset, processData]
  )

  React.useEffect(() => {
    DOMHelper.mountClass('fm-layout')
    HookHelper.resetLoading()

    if (!appuserId) setLoginModalShow(true)

    if (appuserId && !isMounted.current) {
      isMounted.current = true
      processData()
    }

    return () => DOMHelper.unmountClass(['fm-layout'])
  }, [appuserId, processData, dispatch])

  return (
    <Container fluid className="fm-layout">
      {!appUserIsEmbed && (
        <Row className="header">
          <Col>
            <HeaderComponent title="Rapot FM" onToggle={toggleSidebar} />
          </Col>
        </Row>
      )}

      <Row>
        {!appUserIsEmbed && (
          <Col
            md={2}
            className={classNames('col-sidebar', { 'is-open': isOpen, 'is-mobile': isMobile })}
          >
            <SidebarComponent isOpen={isOpen} />
          </Col>
        )}

        <Col
          md={{
            span: appUserIsEmbed ? 12 : isOpen ? 10 : 12,
            offset: appUserIsEmbed ? 0 : isOpen ? 2 : 0,
          }}
        >
          <Jumbotron fluid className="new-banner laporan">
            <Row>
              <Col>
                <Row>
                  <span className={classNames('banner-title', { 'is-mobile': isMobile })}>
                    <FontAwesomeIcon icon="home" fixedWidth className="mr-3" />
                    {`Rapot FM ${location.state ? location.state.title : ''}`}
                  </span>
                </Row>
              </Col>

              {!appuserId ? (
                <Col lg={3} xs={12} md={3} style={{ margin: 'auto' }}>
                  <Button className="button-login" type="button" onClick={openLoginModal}>
                    Daftar / Login
                  </Button>
                </Col>
              ) : (
                <Col lg={3} xs={12} md={3} style={{ margin: 'auto' }}>
                  <Row className="row-saldo">
                    <Col lg={10} xs={10} md={10}>
                      <div className="banner-saldo">Saldo Anda</div>
                      <div className="banner-nominal">
                        Rp. {Formatter.price(Math.round(parseFloat(appUserBalance)))}
                      </div>
                    </Col>
                    <Col lg={2} xs={2} md={2}>
                      <span className="banner-title">
                        <FontAwesomeIcon icon="wallet" fixedWidth className="mr-3" />
                      </span>
                    </Col>
                  </Row>
                </Col>
              )}
            </Row>
          </Jumbotron>

          {appuserId && (
            <div className="content-fm">
              <div className="layanan-body">
                <Row>
                  <Col
                    xs={12}
                    md={5}
                    lg={6}
                    className={classNames({
                      'mgbtm-5': isMobile,
                      'pd-0': isMobile,
                    })}
                  >
                    <div className="search-bar">
                      <span className="input-group-prepend">
                        <div className="input-group-text bg-transparent border-right-0">
                          <FontAwesomeIcon icon="search" fixedWidth className="mr-3" />
                        </div>
                      </span>
                      <Form.Control
                        placeholder="Cari Data Transaksi"
                        className="input-search"
                        value={searchTerm}
                        onChange={searchDataTrx}
                      />
                    </div>
                  </Col>
                  <Col
                    xs={12}
                    md={4}
                    lg={4}
                    className={classNames('button-picker', {
                      'mgbtm-5': isMobile,
                    })}
                  >
                    <Row>
                      <Col lg={1} md={1} xs={2} className="calendar-icon">
                        <FontAwesomeIcon icon="calendar" fixedWidth className="mr-3" />
                      </Col>
                      <Col lg={11} md={11} xs={9} className="picker-border">
                        <DateRangePicker
                          minYear={moment().format('YYYY')}
                          startDate={startTgl}
                          endDate={endTgl}
                          onApply={handleApply}
                          onCallback={handleCallback}
                        >
                          <div className="input-group mb-3" style={{ height: '100%' }}>
                            <span
                              className="input-group-text form-picker form-control"
                              id="basic-addon2"
                            >
                              {moment(startTgl).format('LL')} - {moment(endTgl).format('LL')}
                            </span>
                          </div>
                        </DateRangePicker>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={12} md={3} lg={2}>
                    <div className="button-export">
                      <Button
                        type="button"
                        className="btn btn-rounded btn-amber waves-effect waves-light btn-export"
                        onClick={exportData}
                      >
                        <FontAwesomeIcon icon="download" fixedWidth className="pr-2 fas" />
                        Export.csv
                      </Button>
                      <CSVLink
                        headers={headers}
                        filename="Komisi_Freelance_Marketing.csv"
                        data={dataExport}
                        id="downloadCsv"
                      />
                    </div>
                  </Col>
                </Row>

                <div className="table-responsive-xl table-laporan">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Tanggal Transaksi</th>
                        <th>ID Transaksi</th>
                        <th>ID Outlet</th>
                        <th>Jumlah</th>
                        <th>Keterangan</th>
                      </tr>
                    </thead>
                    <tbody>
                      {dataRes.length > 0 || filter.length > 0 ? (
                        (searchTerm === '' ? dataRes : filter).map((res) => (
                          <tr key={res.id_transaksi}>
                            <td>{res.tanggal_transaksi}</td>
                            <td>{res.id_transaksi}</td>
                            <td>{res.id_outlet}</td>
                            <td>Rp. {Formatter.price(Number(res.jumlah))}</td>
                            <td>{res.keterangan}</td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="5">
                            Ups Komisi Kamu Masih Kosong{' '}
                            <span style={{ display: 'block' }}>
                              Yuk mulai share link afiliasi dan dapatkan bonus jutaan rupiah
                            </span>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                {dataResTotal > 0 && (
                  <ReactPaginate
                    previousLabel="Previous"
                    nextLabel="Next"
                    breakLabel="..."
                    breakClassName="break-me"
                    pageCount={Math.ceil(dataResTotal / limit)}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageChange}
                    containerClassName="pagination"
                    activeClassName="active"
                    forcePage={currentPage}
                  />
                )}
              </div>
            </div>
          )}
        </Col>
      </Row>
      <Alert
        header="Perhatian"
        btnText="OK"
        text={alertError.rd}
        type="error"
        show={!!alertError.rd}
        onClosePress={closeError}
        showBorderBottom
        alertStyles={{ justifyContent: 'center' }}
      />
      <LoginModal show={!!loginModalShow} onHide={closeLoginModal} onConfirm={confirmLoginModal} />
    </Container>
  )
}

RapotFmScreen.defaultProps = {
  appuserId: '',
  appUserBalance: 0,
  appUserIsEmbed: false,
}

RapotFmScreen.propTypes = {
  appuserId: PropTypes.string,
  appUserBalance: PropTypes.number,
  appUserIsEmbed: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
}

export default connect(
  (state) => ({
    appuserId: state.Auth[authConstant.USER_ID],
    appUserBalance: state.Auth[authConstant.USER_BALANCE],
    appUserIsEmbed: state.Auth[authConstant.USER_IS_EMBED],
  }),
  (dispatch) => ({ dispatch })
)(RapotFmScreen)
