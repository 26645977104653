import MPMessage from '../mp'
import Var from '../../../helper/var'

export default class RefreshMessage extends MPMessage {
  constructor(data) {
    super(data)

    this.setPath(`${RefreshMessage.MP_PHP}/`)

    if (!data) {
      this.setMessageType('TRX')
      this.setProcessingCode('ADMTRASI')
      this.setIncludeValue(RefreshMessage.INCLUDES.PRODUCT_CODE, 'MENU')
      this.data.additional_datas = []

      this.setAsEncrypted()
    }
  }

  setCampaignUserId(utmUserId) {
    this.data.campaign.utm_user_id = utmUserId
  }

  setPulsa(pulsa) {
    this.data.includes.produk_pulsa = pulsa
  }

  getPulsa() {
    return Var.toString(this.data.includes.produk_pulsa)
  }

  setGame(game) {
    this.data.includes.produk_game = game
  }

  getGame() {
    return Var.toString(this.data.includes.produk_game)
  }

  setPayment(payment) {
    this.data.includes.produk_payment = payment
  }

  getPayment() {
    return Var.toString(this.data.includes.produk_payment)
  }

  setCash(cash) {
    this.data.includes.produk_cash = cash
  }

  getCash() {
    return Var.toString(this.data.includes.produk_cash)
  }

  setPathMp(pathmp) {
    this.data.includes.pathmp = pathmp
  }

  getPathMp() {
    return Var.toString(this.data.includes.pathmp)
  }

  setBank(bank) {
    this.data.includes.bank = bank
  }

  getBank() {
    return Var.toString(this.data.includes.bank)
  }

  setSettingMenu(settingMenu) {
    this.data.includes.setting_menu = settingMenu
  }

  getSettingMenu() {
    return Var.toString(this.data.includes.setting_menu)
  }

  setKota(kota) {
    this.data.includes.kota = kota
  }

  getKota() {
    return Var.toString(this.data.includes.kota)
  }

  setPropinsi(propinsi) {
    this.data.includes.propinsi = propinsi
  }

  getPropinsi() {
    return Var.toString(this.data.includes.propinsi)
  }

  setBankVa(bankVa) {
    this.data.includes.bank_va = bankVa
  }

  getBankVa() {
    return Var.toString(this.data.includes.bank_va)
  }

  setVa(Va) {
    this.data.includes.virtual_accounts = Va
  }

  getVa() {
    return this.data.includes.virtual_accounts
  }

  setNewVa(dataVa) {
    this.data.includes.data_va = dataVa
  }

  getNewVa() {
    return this.data.includes.data_va
  }
}
