import BaseMessage from '../message/base'
import MessageSender from '../sender'
import MessageSenderBrowser from '../senderBrowser'
import RefreshMessage from '../message/web/refresh'
import authConstant from '../../store/constant/auth'
import globalConstant from '../../store/constant/global'
import produkConstant from '../../store/constant/produk'

export const globalProduk = (outletId, pin) => async (dispatch) => {
  const msg = new RefreshMessage()
  msg.setOutletID(outletId)
  msg.setPIN(pin)
  msg.setCampaignUserId(outletId)

  const response = await new MessageSender().doPost(msg)
  const msg2 = new RefreshMessage(response)

  if (msg2.isOK()) {
    await dispatch({
      type: globalConstant.GLOBAL,
      payload: {
        [globalConstant.PATHMP]: msg2.getPathMp(),
        [globalConstant.SETTING_MENU]: msg2.getSettingMenu(),
        [globalConstant.KOTA]: msg2.getKota(),
        [globalConstant.PROPINSI]: msg2.getPropinsi(),
      },
    })
    await dispatch({
      type: produkConstant.PRODUK,
      payload: {
        [produkConstant.PRODUK_PULSA]: msg2.getPulsa(),
        [produkConstant.PRODUK_GAME]: msg2.getGame(),
        [produkConstant.PRODUK_PAYMENT]: msg2.getPayment(),
        [produkConstant.PRODUK_CASH]: msg2.getCash(),
        [produkConstant.PRODUK_BANK]: msg2.getBank(),
        [produkConstant.PRODUK_BANK_VA]: msg2.getBankVa(),
        [produkConstant.PRODUK_VA]: msg2.getNewVa(),
      },
    })
    await dispatch({
      type: authConstant.USER_BALANCE,
      payload: msg2.getBalance(),
    })
  }

  return msg2
}

export const refreshSaldo = (outletId) => async (dispatch) => {
  const msg = new BaseMessage()
  msg.setPath(`${BaseMessage.SC_API}/user_balance`)
  msg.data.id_outlet = outletId

  const response = await new MessageSender().doGet(msg)
  const msg2 = new BaseMessage(response)

  if (msg2.isOK()) {
    const data = msg2.getData()
    await dispatch({
      type: authConstant.USER_BALANCE,
      payload: data.data.balance,
    })
  }

  return msg2
}

export const komisiProduk = (outletId) => async (dispatch) => {
  const msg = new BaseMessage()
  msg.setPath(`${BaseMessage.SC_API}/products/ubp/comission`)
  msg.data.id = outletId
  msg.data.type = 'ubp'
  msg.data.timestamp = new Date().getTime()

  const response = await new MessageSender().doGet(msg)
  const msg2 = new BaseMessage(response)

  if (msg2.isOK()) {
    if (outletId === 'Premium') {
      await dispatch({
        type: produkConstant.PROD_KOMISI_PREMIUM,
        payload: msg2.getResultAsList(),
      })
    } else {
      await dispatch({
        type: produkConstant.PROD_KOMISI,
        payload: msg2.getResultAsList(),
      })
    }
  }

  return msg2
}

export const lastTransaction =
  (outletId, product, isPulsa = '0', groupProduct = '') =>
  async () => {
    const msg = new BaseMessage()
    msg.setPath(`${BaseMessage.MP_NODE}/ro/customer/history_billinfo`)
    msg.data.user_id = outletId
    msg.data.id_produk = product
    msg.data.is_pulsa = isPulsa
    msg.data.id_group_produk = groupProduct
    msg.data.utm_user_id = outletId
    msg.data.utm_campaign = ''
    msg.data.utm_medium = ''
    msg.data.utm_term = ''
    msg.data.utm_name = ''
    msg.data.utm_content = ''
    msg.data.utm_source = ''
    msg.data.timestamp = new Date().getTime()

    const response = await new MessageSender().doGet(msg)
    return new BaseMessage(response)
  }

export const listProdukTagihan = (outletId) => async (dispatch) => {
  const msg = new BaseMessage()
  msg.setPath(`${BaseMessage.SC_API}/products/ubp`)
  msg.data.id = outletId
  msg.data.grupubp = 'tagihan'
  msg.data.timestamp = new Date().getTime()

  const response = await new MessageSender().doGet(msg)
  const msg2 = new BaseMessage(response)
  if (msg2.isOK()) {
    await dispatch({
      type: produkConstant.PRODUK_TAGIHAN,
      payload: msg2.getResultAsObject(),
    })
  }

  return msg2
}

export const getSliderPromo = (outletId, campaign) => async () => {
  const msg = new BaseMessage()
  msg.setPath(`${BaseMessage.SC_API}/web/contents`)
  msg.data.appid = 'bb'
  msg.data.outletid = outletId
  msg.data.utm_user_id = outletId
  msg.data.utm_campaign = campaign.utm_campaign
  msg.data.utm_medium = campaign.utm_medium
  msg.data.utm_term = campaign.utm_term
  msg.data.utm_name = campaign.utm_name
  msg.data.utm_content = campaign.utm_content
  msg.data.utm_source = campaign.utm_source
  msg.data.is_published = '1'
  msg.data.lang = 'ID'
  msg.data.simple = 'true'
  msg.data.type = 'p_code'
  msg.data.timestamp = new Date().getTime()

  const response = await new MessageSender().doGet(msg)

  return new BaseMessage(response)
}

export const getCustomAdmin = (userId, produkId) => async () => {
  const msg = new BaseMessage()
  msg.setPath(`${BaseMessage.MP_NODE}/ro/product/custom_admin`)
  msg.data.user_id = userId
  msg.data.produk_id = produkId
  msg.data.timestamp = new Date().getTime()
  const response = await new MessageSender().doGet(msg)

  return new BaseMessage(response)
}

export const listProdukUbp = (userId, groupProduk) => async () => {
  const msg = new BaseMessage()
  msg.setPath(`${BaseMessage.MP_NODE}/dashboard/products`)
  msg.data.user_id = userId
  msg.data.group_produk = groupProduk
  msg.data.timestamp = new Date().getTime()
  const response = await new MessageSender().doGet(msg)

  return new BaseMessage(response)
}

export const getCookie = () => async (dispatch) => {
  const msg = new BaseMessage()
  msg.setPath('/cookie')

  const response = await new MessageSenderBrowser().doGet(msg)
  const msg2 = new BaseMessage(response)
  if (msg2.isOK()) {
    const data = msg2.getData()
    await dispatch({
      type: globalConstant.COOKIE,
      payload: data.data,
    })
  }

  return msg2
}
