import React from 'react'
import PropTypes from 'prop-types'
import { Route, Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'
import authConstant from '../store/constant/auth'

export default function RouteWrapper({ component: Component, page, marketer, ...rest }) {
  const userId = useSelector((state) => state.Auth[authConstant.USER_ID])
  const userPass = useSelector((state) => state.Auth[authConstant.USER_PIN])
  const userMarketer = useSelector((state) => state.Auth[authConstant.USER_IS_MARKETER])
  const signed = userId && userPass
  /**
   * Redirect user to SignIn page if he tries to access a private route
   * without authentication.
   */
  if (page === 'signed' && !signed) {
    return <Redirect to="/login" />
  }
  /**
   * Redirect user to Main page if he tries to access a non private route
   * (SignIn or SignUp) after being authenticated.
   */
  if (page === 'unsigned' && signed) {
    return <Redirect to="/home" />
  }
  /**
   * Redirect normal user to Main page if he tries to access a marketer route
   */
  if (marketer === 'true' && !userMarketer) {
    return <Redirect to="/" />
  }
  /**
   * If not included on both previous cases, redirect user to the desired route.
   */
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Route {...rest} component={Component} />
}
RouteWrapper.propTypes = {
  page: PropTypes.string,
  marketer: PropTypes.string,
  component: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.func,
    PropTypes.shape({
      type: PropTypes.func,
      WrappedComponent: PropTypes.func,
      displayName: PropTypes.string,
    }),
  ]).isRequired,
}
RouteWrapper.defaultProps = {
  page: '',
  marketer: '',
}
