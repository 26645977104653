export default {
  PRODUK: 'PRODUK',
  PRODUK_BANK: 'PRODUK_BANK',
  PRODUK_BANK_VA: 'PRODUK_BANK_VA',
  PRODUK_CASH: 'PRODUK_CASH',
  PRODUK_CLEAR: 'PRODUK_CLEAR',
  PRODUK_GAME: 'PRODUK_GAME',
  PRODUK_PAYMENT: 'PRODUK_PAYMENT',
  PRODUK_PLN: 'PRODUK_PLN',
  PRODUK_PULSA: 'PRODUK_PULSA',
  PRODUK_TAGIHAN: 'PRODUK_TAGIHAN',
  PRODUK_VA: 'PRODUK_VA',
  PROD_KOMISI: 'PROD_KOMISI',
  PROD_KOMISI_PREMIUM: 'PROD_KOMISI_PREMIUM',
}
