import React from 'react'
import { connect } from 'react-redux'
import { Container } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'

import DOMHelper from '../../../helper/domhelper'
import HookHelper from '../../../helper/hookhelper'

import '../home.scss'
import globalConstant from '../../../store/constant/global'

const RedirectPlnScreen = () => {
  const history = useHistory()

  React.useEffect(() => {
    DOMHelper.mountClass('home-layout')
    HookHelper.resetLoading()

    history.push('/pln/token-listrik')

    return () => DOMHelper.unmountClass(['home-layout'])
  }, [history])

  return <Container fluid className="home-layout" />
}

RedirectPlnScreen.propTypes = {}

export default connect(
  (state) => ({
    appIsLoading: state.Global[globalConstant.IS_LOADING],
  }),
  (dispatch) => ({ dispatch })
)(RedirectPlnScreen)
