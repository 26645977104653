/* eslint-disable no-nested-ternary */
import React from 'react'
import { connect } from 'react-redux'
import { Alert, Modal, Image, Container, Button, Col, Row } from 'react-bootstrap'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { isMobile } from 'react-device-detect'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useHistory, useLocation, Link } from 'react-router-dom'
import DOMHelper from '../../../helper/domhelper'
import DateTime from '../../../helper/datetime'
import HookHelper from '../../../helper/hookhelper'

import './struk.scss'
import globalConstant from '../../../store/constant/global'
import authConstant from '../../../store/constant/auth'
import SidebarComponent from '../../component/sidebar'
import HeaderComponent from '../../component/header'
import Formatter from '../../../helper/formatter'

const StrukScreen = ({ appUserId, appUserIsEmbed }) => {
  const history = useHistory()
  const location = useLocation()
  const [isOpen, setIsOpen] = React.useState(!isMobile)
  const [alertError, setAlertError] = React.useState('')

  const toggleSidebar = React.useCallback(() => setIsOpen(!isOpen), [isOpen, setIsOpen])
  const closeError = React.useCallback(() => setAlertError(''), [])

  const redirectBack = React.useCallback(async () => {
    history.push('/home')
  }, [history])

  const redirectReport = React.useCallback(async () => {
    history.push('/report/transaction')
  }, [history])

  const cetakStruk = React.useCallback(async () => {
    window.open(
      `${process.env.REACT_APP_STRUK_URL_PRINT}trxId=${location.state.transaksi}&scId=${appUserId}`,
      'wndStaff',
      'width=730,height=500,directories=0,location=0,resizable=1,scrollbars=1,toolbar=0,copyhistory=0,status=0'
    )
  }, [location, appUserId])

  React.useEffect(() => {
    DOMHelper.mountClass('struk-layout')
    HookHelper.resetLoading()

    return () => DOMHelper.unmountClass(['struk-layout'])
  }, [])

  return (
    <Container fluid className="struk-layout">
      {!appUserIsEmbed && (
        <Row className="header">
          <Col>
            <HeaderComponent title="Struk" onToggle={toggleSidebar} />
          </Col>
        </Row>
      )}

      <Row>
        {!appUserIsEmbed && (
          <Col
            md={2}
            className={classNames('col-sidebar', { 'is-open': isOpen, 'is-mobile': isMobile })}
          >
            <SidebarComponent isOpen={isOpen} />
          </Col>
        )}

        <Col
          md={{
            span: appUserIsEmbed ? 12 : isOpen ? 10 : 12,
            offset: appUserIsEmbed ? 0 : isOpen ? 2 : 0,
          }}
        >
          <Row className="justify-content-md-center">
            <div className={classNames('container-layout', { 'is-open': isOpen })}>
              <div
                className={classNames(
                  'banner-struk',
                  location.state.info === 'pending' ? 'banner-pending' : '',
                  location.state.info === 'gagal' ? 'banner-failed' : ''
                )}
              >
                <div className="banner-head">
                  <div className="banner-upper">
                    <div className="span-info">
                      {location.state.info === 'pending' ? (
                        <FontAwesomeIcon icon="hourglass-end" className="banner-ico ico-pending" />
                      ) : (
                        ''
                      )}
                      {location.state.info === 'sukses' ? (
                        <FontAwesomeIcon icon="check-circle" className="banner-ico" />
                      ) : (
                        ''
                      )}
                      {location.state.info === 'gagal' ? (
                        <FontAwesomeIcon icon="times-circle" className="banner-ico ico-failed" />
                      ) : (
                        ''
                      )}
                    </div>
                    <div className="span-info">
                      {location.state.info === 'pending' ? 'Sedang Dalam Proses' : ''}
                      {location.state.info === 'sukses' ? 'Transaksi Berhasil' : ''}
                      {location.state.info === 'gagal' ? 'Transaksi Gagal' : ''}
                    </div>
                    <div className="span-additional">
                      {location.state.info === 'pending'
                        ? 'Transaksi Anda akan segera berhasil, mohon ditunggu sementara waktu'
                        : ''}
                      {location.state.info === 'sukses'
                        ? 'Terima kasih telah mempercayakan transaksi Anda pada SpeedCash!'
                        : ''}
                      {location.state.info === 'gagal'
                        ? 'Mohon maaf, transaksi yang Anda lakukan gagal. Klik tombol di bawah ini untuk mencoba kembali'
                        : ''}
                    </div>
                  </div>
                  <div />
                </div>
              </div>
              <div className="content-laporan">
                <div className="layanan-body">
                  {location.state.info === 'sukses' ? (
                    <div className={classNames('body-form', { 'form-mobile': isMobile })}>
                      <Row className="body-col">
                        <Col sm={6}>
                          <div className="tes">
                            <span className="detail-head">Detail Pembayaran</span>
                          </div>
                          <div className="list-Info">
                            <div className="detail-info">Tanggal & Waktu</div>
                            <div className="detail-data">
                              {DateTime.convert(
                                location.state.trxDate,
                                DateTime.FMT_DATE_TIME_YMDHMS_NS,
                                DateTime.FMT_DATE_TIME_DMYHMS
                              )}
                            </div>
                          </div>
                          <div className="list-Info">
                            <div className="detail-info">Jenis Transaksi</div>
                            <div className="detail-data">Payment</div>
                          </div>
                          <div className="list-Info">
                            <div className="detail-info">
                              ID Pelanggan {location.state.parent === 'pln' ? '/ Nomor Meter' : ''}
                            </div>
                            <div className="detail-data">{location.state.customerId}</div>
                          </div>
                          {location.state.parent === 'pln' && location.state.produk === 'PLNPRA' ? (
                            <div className="list-Info">
                              <div className="detail-info">Nomor Token</div>
                              <div className="detail-data">{location.state.noToken}</div>
                            </div>
                          ) : (
                            ''
                          )}
                          <div className="list-Info">
                            <div className="detail-info">Total Pembayaran</div>
                            <div className="detail-data">
                              Rp. {Formatter.price(location.state.total)}
                            </div>
                          </div>
                          {location.state.discountInfo && (
                            <div className="list-Info" style={{ paddingRight: '10px' }}>
                              <Row className="info-box">
                                <Col lg={1} xs={2} md={1} style={{ margin: 'auto' }}>
                                  <FontAwesomeIcon
                                    icon="info-circle"
                                    fixedWidth
                                    className="mr-3 txt-22"
                                  />
                                </Col>
                                <Col lg={11} xs={10} md={11}>
                                  <span>{location.state.discountInfo}</span>
                                </Col>
                              </Row>
                            </div>
                          )}
                        </Col>
                        <Col sm={6}>
                          <div style={{ textAlign: 'center' }}>
                            <span className="detail-head">Bukti Pembayaran</span>
                          </div>
                          <div className="border-image">
                            <Image
                              className="image-content"
                              src={process.env.REACT_APP_STRUK_URL + location.state.transaksi}
                            />
                            <Row>
                              <Col
                                lg={6}
                                xs={6}
                                md={6}
                                className={classNames({
                                  'pd-0': isMobile,
                                })}
                              >
                                <Button className="button-print" onClick={cetakStruk}>
                                  <FontAwesomeIcon
                                    icon="print"
                                    style={{
                                      color: '#FFFFFF',
                                      fontSize: isMobile ? '14px' : '16px',
                                    }}
                                  />
                                  <span
                                    className={classNames('info-print', {
                                      'print-mobile': isMobile,
                                    })}
                                  >
                                    Cetak
                                  </span>
                                </Button>
                              </Col>
                              <Col
                                lg={6}
                                xs={6}
                                md={6}
                                className={classNames({
                                  'pd-0': isMobile,
                                })}
                              >
                                <Link
                                  className="btn button-pdf"
                                  to={{
                                    pathname: `${process.env.REACT_APP_STRUK_URL_PDF}${location.state.transaksi}`,
                                  }}
                                  target="_blank"
                                  download="struk.pdf"
                                >
                                  <span
                                    className={classNames('info-print left', {
                                      'print-mobile': isMobile,
                                    })}
                                  >
                                    Download
                                  </span>
                                  <FontAwesomeIcon
                                    icon="file-pdf"
                                    style={{
                                      color: '#FFFFFF',
                                      fontSize: isMobile ? '14px' : '16px',
                                    }}
                                  />
                                </Link>
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      </Row>
                      <div className="border-bottom" />
                      <div className="button-down">
                        <Button className="button" onClick={redirectBack}>
                          Kembali ke Menu Utama
                        </Button>
                      </div>
                    </div>
                  ) : (
                    <div className={classNames('body-form', { 'form-mobile': isMobile })}>
                      <Row className="body-col body-col-pending">
                        <Col sm={12}>
                          <div className="tes">
                            <span className="detail-head">Detail Pembayaran</span>
                          </div>
                          <div className="list-Info">
                            <div className="detail-info">Tanggal & Waktu</div>
                            <div className="detail-data">
                              {DateTime.convert(
                                location.state.trxDate,
                                DateTime.FMT_DATE_TIME_YMDHMS_NS,
                                DateTime.FMT_DATE_TIME_DMYHMS
                              )}
                            </div>
                          </div>
                          <div className="list-Info">
                            <div className="detail-info">Jenis Transaksi</div>
                            <div className="detail-data">Payment</div>
                          </div>
                          <div className="list-Info">
                            <div className="detail-info">
                              ID Pelanggan {location.state.parent === 'pln' ? '/ Nomor Meter' : ''}
                            </div>
                            <div className="detail-data">{location.state.customerId}</div>
                          </div>
                          {location.state.parent === 'pln' ? (
                            <div className="list-Info">
                              <div className="detail-info">Nomor Token</div>
                              <div className="detail-data">{location.state.noToken}</div>
                            </div>
                          ) : (
                            ''
                          )}
                          <div className="list-Info">
                            <div className="detail-info">Total Pembayaran</div>
                            <div className="detail-data">
                              Rp. {Formatter.price(Number(location.state.total))}
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <div className="button-down">
                        <Button className="button" onClick={redirectReport}>
                          Lihat Laporan Transaksi
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Row>
        </Col>
      </Row>
      <Modal
        show={!!alertError.rd}
        onHide={closeError}
        animation={false}
        contentClassName="alert-dialog"
      >
        <Alert
          variant="danger"
          show={!!alertError.rd}
          onClose={closeError}
          transition={null}
          dismissible
        >
          <span style={{ fontSize: '16px', fontWeight: 'bold' }}>{alertError.rd}</span>
        </Alert>
      </Modal>
    </Container>
  )
}

StrukScreen.defaultProps = {
  appUserId: '',
  appUserIsEmbed: false,
}

StrukScreen.propTypes = {
  appUserId: PropTypes.string,
  appUserIsEmbed: PropTypes.bool,
}

export default connect(
  (state) => ({
    appIsLoading: state.Global[globalConstant.IS_LOADING],
    appUserId: state.Auth[authConstant.USER_ID],
    appUserIsEmbed: state.Auth[authConstant.USER_IS_EMBED],
  }),
  (dispatch) => ({ dispatch })
)(StrukScreen)
