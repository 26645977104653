/* eslint-disable no-nested-ternary */
import _ from 'lodash'
import React, { useRef } from 'react'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import {
  Spinner,
  Form,
  InputGroup,
  Container,
  Jumbotron,
  Button,
  Image,
  Row,
  Col,
  Tabs,
  Tab,
} from 'react-bootstrap'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { isMobile } from 'react-device-detect'
import { useHistory, useLocation } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Alert from 'react-popup-alert'
import 'react-popup-alert/dist/index.css'
import DOMHelper from '../../../helper/domhelper'
import Formatter from '../../../helper/formatter'
import HookHelper from '../../../helper/hookhelper'

import './layanan.scss'
import globalConstant from '../../../store/constant/global'
import authConstant from '../../../store/constant/auth'
import SidebarComponent from '../../component/sidebar'
import HeaderComponent from '../../component/header'
import LoginModal from '../../dialog/auth/login'
import PlnModal from '../../dialog/layanan/pln'
import LastTrxDialog from '../../dialog/lastTransaction'
import AccordionPlnPraComponent from '../../component/accordionPlnPra'

import { plnInq, plnPay } from '../../../api/action/layanan/pln'
import { lastTransaction } from '../../../api/action/global'

const PlnPraScreen = ({
  appUserId,
  appUserPin,
  appUserBalance,
  appUserIsEmbed,
  appIsLoading,
  dispatch,
}) => {
  const history = useHistory()
  const location = useLocation()
  const btnNextRef = useRef(null)
  const [isOpen, setIsOpen] = React.useState(!isMobile)
  const [isInqWeb, setIsInqWeb] = React.useState(false)
  const [alertError, setAlertError] = React.useState('')
  const [loginModalShow, setLoginModalShow] = React.useState(false)
  const [plnModalShow, setPlnModalShow] = React.useState(false)
  const [userId] = React.useState(!appUserId ? process.env.REACT_APP_USER_DEMO_ID : appUserId)
  const [userPin] = React.useState(
    !appUserId ? process.env.REACT_APP_USER_DEMO_PASSWORD : appUserPin
  )
  const [isSaldoOk, setIsSaldoOk] = React.useState(false)
  const [lastTrxShow, setLastTrxShow] = React.useState(false)
  const [lastTrx, setLastTransaction] = React.useState([])
  const [lastTrxFiltered, setLastTransactionFiltered] = React.useState([])
  const [search, setSearch] = React.useState('')
  const [voucherQty] = React.useState('0')
  const [pelanggan, setPelanggan] = React.useState('')
  const [token, setToken] = React.useState('')
  const [total, setTotal] = React.useState(0)
  const [dataRes, setDataRes] = React.useState(null)

  const toggleSidebar = React.useCallback(() => setIsOpen(!isOpen), [isOpen, setIsOpen])

  const closeError = React.useCallback(() => setAlertError(''), [])

  const openLoginModal = React.useCallback(async () => {
    setLoginModalShow(true)
  }, [])

  const confirmLoginModal = React.useCallback(async () => {
    setLoginModalShow(false)
    // window.location.reload()
  }, [setLoginModalShow])

  const closeLoginModal = React.useCallback(async () => {
    setLoginModalShow(false)
  }, [setLoginModalShow])

  const redirectToDeposit = React.useCallback(() => {
    history.push('/deposit')
  }, [history])

  const confirmPlnModal = React.useCallback(async () => {
    if (!appUserId) {
      setPlnModalShow(false)
      setLoginModalShow(true)
    } else if (isSaldoOk) {
      const customer = dataRes.includes.customer_id_1
      const productCode = dataRes.includes.product_code
      const resPay = await dispatch(
        plnPay(userId, userPin, customer, productCode, dataRes.includes)
      )

      if (resPay.isOK()) {
        history.push({
          pathname: '/struk',
          state: {
            parent: resPay.data.includes.product_code === 'PLNPRA' ? 'pln' : 'plnpasca',
            parentPath: location.pathname,
            title: 'PLN',
            icon: 'bolt',
            transaksi: resPay.data.includes.reff_id,
            trxDate: resPay.data.transmission_datetime,
            trxTime: resPay.data.transmission_datetime,
            total:
              Number(resPay.data.includes.amount) +
              Number(resPay.data.includes.admin) +
              parseInt(resPay.data.additional_datas[93], 10),
            customerId: resPay.data.includes.customer_id_1,
            noToken:
              resPay.data.includes.product_code === 'PLNPRA' ? resPay.data.biller_info.token : '-',
            discountInfo: resPay.data.discountInfo,
            produk: resPay.data.includes.product_code,
            info: 'sukses',
          },
        })
      } else if (
        resPay.getRC() === '' ||
        resPay.getRC() === '68' ||
        resPay.getRC().toString() === resPay.data.includes.reff_id.toString()
      ) {
        history.push({
          pathname: '/struk',
          state: {
            parent: resPay.data.includes.product_code === 'PLNPRA' ? 'pln' : 'plnpasca',
            parentPath: location.pathname,
            title: 'PLN',
            icon: 'bolt',
            transaksi: resPay.data.includes.reff_id,
            trxDate: resPay.data.transmission_datetime,
            trxTime: resPay.data.transmission_datetime,
            total:
              Number(resPay.data.includes.amount) +
              Number(resPay.data.includes.admin) +
              parseInt(resPay.data.additional_datas[93], 10),
            customerId: resPay.data.includes.customer_id_1,
            noToken:
              resPay.data.includes.product_code === 'PLNPRA' ? resPay.data.biller_info.token : '-',
            discountInfo: resPay.data.discountInfo,
            produk: resPay.data.includes.product_code,
            info: 'pending',
          },
        })
      } else {
        setAlertError({ rc: resPay.getRC(), rd: resPay.getRD() })
      }
    } else {
      redirectToDeposit()
    }
  }, [
    dispatch,
    history,
    location,
    isSaldoOk,
    appUserId,
    dataRes,
    userId,
    userPin,
    redirectToDeposit,
  ])

  const closePlnModal = React.useCallback(async () => {
    setPlnModalShow(false)
  }, [setPlnModalShow])

  const openLastTransaction = React.useCallback(async () => {
    setLastTrxShow(true)
  }, [setLastTrxShow])

  const closeLastTransaction = React.useCallback(async () => {
    setLastTrxShow(false)
  }, [setLastTrxShow])

  const onSearchChange = React.useCallback(
    (e) => {
      const { value } = e.target
      const newData = _.filter(lastTrx, (item) => {
        return (
          _.includes(item.id_pelanggan, value) ||
          _.includes(item.nama_pelanggan.toLowerCase(), value.toLowerCase())
        )
      })

      setSearch(value)
      setLastTransactionFiltered(newData)
    },
    [lastTrx, setSearch, setLastTransactionFiltered]
  )

  const confirmLastTrx = React.useCallback(
    (e) => {
      const { target } = e.currentTarget.dataset
      setPelanggan(target)
      setLastTrxShow(false)
    },
    [setPelanggan, setLastTrxShow]
  )

  const handleClear = React.useCallback(
    (e) => {
      const { target } = e.currentTarget.dataset
      if (target === 'pelanggan') {
        setPelanggan('')
      }

      setIsInqWeb(false)
      setDataRes(null)
    },
    [setPelanggan, setIsInqWeb, setDataRes]
  )

  const onPelangganChange = React.useCallback((e) => {
    const { value } = e.target
    setPelanggan(Formatter.number(value))
  }, [])

  const onTabChange = React.useCallback(
    (e) => {
      if (e === 'PLNPRA') {
        setIsInqWeb(false)
        setIsSaldoOk(false)
        setPelanggan('')
        setToken('')
        setDataRes(null)
        setTotal(0)
        setSearch('')
        setLastTransaction([])
        setLastTransactionFiltered([])

        dispatch(lastTransaction(userId, e, '0')).then((response) => {
          if (response.isOK()) {
            setLastTransaction(response.getResultAsList())
            setLastTransactionFiltered(response.getResultAsList())
          } else {
            setLastTransaction([])
            setLastTransactionFiltered([])
          }
        })
      } else if (e === 'PLNPASC') {
        history.push('/pln/cek-tagihan-listrik')
      } else {
        history.push('/pln/non-taglis')
      }
    },
    [dispatch, history, userId]
  )

  const onSetToken = React.useCallback(
    (e) => {
      const { value } = e.currentTarget.dataset
      setToken(value)

      // eslint-disable-next-line no-unused-expressions
      btnNextRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end' })
    },
    [setToken]
  )

  const processPln = React.useCallback(async () => {
    if (!pelanggan) {
      setAlertError({ rc: '', rd: 'Masukkan ID Pelanggan Terlebih Dahulu' })
      return false
    }

    if (!token) {
      setAlertError({ rc: '', rd: 'Pilih Nominal Token Terlebih Dahulu' })
      return false
    }

    const response = await dispatch(plnInq(userId, userPin, voucherQty, pelanggan, 'PLNPRA', token))
    if (response.isOK()) {
      const resData = response.getData()
      const { amount } = resData.biller_info
      const { admin } = resData.biller_info
      setTotal(parseInt(amount.replace('.', ''), 10) + parseInt(admin.replace('.', ''), 10))

      const dataRespon = response.getData()
      const nominal = dataRespon.includes.amount
      const nominalAdmin = dataRespon.includes.admin
      const nominalTotal =
        parseInt(nominal, 0) +
        parseInt(nominalAdmin, 0) +
        parseInt(dataRespon.additional_datas[93], 10)
      if (Math.round(parseFloat(appUserBalance)) >= nominalTotal) {
        setIsSaldoOk(true)
      }

      setDataRes(dataRespon)
      if (isMobile) {
        setPlnModalShow(true)
      } else {
        setIsInqWeb(true)
      }
    } else {
      setAlertError({ rc: response.getRC(), rd: response.getRD() })
    }

    return true
  }, [
    dispatch,
    userId,
    userPin,
    appUserBalance,
    pelanggan,
    token,
    voucherQty,
    setIsInqWeb,
    setIsSaldoOk,
    setAlertError,
  ])

  React.useEffect(() => {
    DOMHelper.mountClass('layanan-layout')
    HookHelper.resetLoading()

    dispatch(lastTransaction(userId, 'PLNPRA', '0')).then((response) => {
      if (response.isOK()) {
        setLastTransaction(response.getResultAsList())
        setLastTransactionFiltered(response.getResultAsList())
      }
    })

    return () => DOMHelper.unmountClass(['layanan-layout'])
  }, [dispatch, userId])

  return (
    <>
      <Helmet>
        <title>Token Listrik-Beli Voucher PLN Online Dapat Diskon Disini</title>
        <meta name="title" content="Token Listrik-Beli Voucher PLN Online Dapat Diskon Disini" />
        <meta
          name="description"
          content="Beli voucher Isi ulang token listrik PLN prabayar online lebih praktis pasti diskon disini.Cek harga token listrik termurah mulai nominal 20.000.Beli Sekarang."
        />
        <meta name="google-play-app" content="app-id=com.bm.sc.bebasbayar" />
        <meta
          name="_globalsign-domain-verification"
          content="VncJWgSU_cQw-hAuU0PELvI3g4HQ4l6XFSs9v-jA1Y"
        />
        <meta name="facebook-domain-verification" content="pknnsi25vbw0rfl1x8xctdtumrh3bs" />
        <meta name="msvalidate.01" content="9A9127824837BF1E05F5AED70295F281" />
        <meta name="alexaVerifyID" content="Nc8Q89T9GsncVlA5xukQcbi5rJ0" />
        <meta httpEquiv="X-UA-Compatible" content="IE=Edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="author" content="https://www.facebook.com/SpeedCash.BebasBayarID" />
        <link rel="canonical" href="https://member.speedcash.co.id/pln/token-listrik" />
        <meta
          name="robots"
          content="follow, index, max-snippet:-1, max-video-preview:-1, max-image-preview:large"
        />
        <meta name="geo.country" content="id" />
        <meta name="revisit-after" content="1 days" />
        <meta name="geo.placename" content="Indonesia" />
        <meta content="id_id" property="og:locale" />
        <meta itemProp="name" content="Token Listrik-Beli Voucher PLN Online Dapat Diskon Disini" />
        <meta
          itemProp="description"
          content="Beli voucher Isi ulang token listrik PLN prabayar online lebih praktis pasti diskon disini.Cek harga token listrik termurah mulai nominal 20.000.Beli Sekarang."
        />
        <meta
          itemProp="image"
          content="https://www.speedcash.co.id/images/speedcash-logo-square.jpg"
        />
        <meta property="fb:app_id" content="966242223397117" />
        <meta
          property="og:title"
          content="Token Listrik-Beli Voucher PLN Online Dapat Diskon Disini"
        />
        <meta property="og:type" content="article" />
        <meta
          property="og:image"
          content="https://www.speedcash.co.id/images/speedcash-logo-square.jpg"
        />
        <meta
          property="og:description"
          content="Beli voucher Isi ulang token listrik PLN prabayar online lebih praktis pasti diskon disini.Cek harga token listrik termurah mulai nominal 20.000.Beli Sekarang."
        />
        <meta property="og:site_name" content="SpeedCash" />
        <meta property="og:url" content="https://www.speedcash.co.id/" />
        <meta
          property="article:tag"
          content="Beli voucher Isi ulang token listrik PLN prabayar online lebih praktis pasti diskon disini.Cek harga token listrik termurah mulai nominal 20.000.Beli Sekarang."
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@SpeedcashO" />
        <meta
          name="twitter:title"
          content="Token Listrik-Beli Voucher PLN Online Dapat Diskon Disini"
        />
        <meta
          name="twitter:description"
          content="Beli voucher Isi ulang token listrik PLN prabayar online lebih praktis pasti diskon disini.Cek harga token listrik termurah mulai nominal 20.000.Beli Sekarang."
        />
        <meta name="twitter:creator" content="@SpeedcashO" />
        <meta
          name="twitter:image"
          content="https://www.speedcash.co.id/images/speedcash-logo-square.jpg"
        />
        <meta
          name="twitter:image:src"
          content="https://www.speedcash.co.id/images/speedcash-logo-square.jpg"
        />
        <meta name="twitter:label1" content="Deskripsi" />
        <meta
          name="twitter:data1"
          content="Beli voucher Isi ulang token listrik PLN prabayar online lebih praktis pasti diskon disini.Cek harga token listrik termurah mulai nominal 20.000.Beli Sekarang."
        />
        <meta name="twitter:label2" content="Tag" />
        <meta
          name="twitter:data2"
          content="Beli voucher Isi ulang token listrik PLN prabayar online lebih praktis pasti diskon disini.Cek harga token listrik termurah mulai nominal 20.000.Beli Sekarang."
        />

        <script type="application/ld+json">
          {`
    {
    "@context":"https://schema.org",
    "@graph":[{
    "@type":"Organization",
    "@id":"https://member.speedcash.co.id/#organization",
    "name":"PT Bimasakti Multi Sinergi",
    "url":"https://member.speedcash.co.id",
    "sameAs":[
    "https://www.facebook.com/SpeedCash.BebasBayarID/",
    "https://www.instagram.com/speedcash.official/",
    "https://www.linkedin.com/in/aplikasi-bebasbayar-864aa7195/",
    "https://www.youtube.com/c/SpeedCashIDOfficial",
    "https://id.pinterest.com/bebasbayarofficial/",
    "https://www.tiktok.com/@speedcash.official",
    "https://twitter.com/SpeedcashO"],
    "logo":{
    "@type":"ImageObject",
    "@id":"https://member.speedcash.co.id/#logo",
    "url":"https://member.speedcash.co.id/img/speedcash.png",
    "width":240,"height":72,"caption":"SpeedCash.co.id"},
    "image":{
    "@id":"https://member.speedcash.co.id/#logo"}
    },{
    "@type":"WebSite",
    "@id":"https://member.speedcash.co.id/#website",
    "url":"https://member.speedcash.co.id/",
    "name":"SpeedCash",
    "publisher":{
    "@id":"https://member.speedcash.co.id/#organization"},
    "potentialAction":{
    "@type":"SearchAction",
    "target":"https://member.speedcash.co.id/?s={search_term_string}",
    "query-input":"required name=search_term_string"}
    },{
    "@type":"ImageObject",
    "@id":"https://member.speedcash.co.id/#primaryimage",
    "url":"https://member.speedcash.co.id/img/speedcash.png",
    "width":764,"height":401,
    "caption":"Token Listrik-Beli Voucher PLN Online Dapat Diskon Disini"
    },{
    "@type":"WebPage","@id":"https://member.speedcash.co.id/#webpage",
    "url":"https://member.speedcash.co.id/","inLanguage":"ID",
    "name":"Token Listrik-Beli Voucher PLN Online Dapat Diskon Disini",
    "isPartOf":{
    "@id":"https://member.speedcash.co.id/#website"},
    "about":{"@id":"https://member.speedcash.co.id/#organization"},
    "primaryImageOfPage":{"@id":"https://member.speedcash.co.id/#primaryimage"},
    "datePublished":"2024-02-20T03:08:35+00:00","dateModified":"2024-02-20T04:54:34+00:00",
    "description":"Beli voucher Isi ulang token listrik PLN prabayar online lebih praktis pasti diskon disini.Cek harga token listrik termurah mulai nominal 20.000.Beli Sekarang."
    }]
    }`}
        </script>

        {/* Google tag (gtag.js) */}
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-X7L58XGGSH" />
        <script>
          {`
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'G-X7L58XGGSH');`}
        </script>
      </Helmet>
      <Container fluid className="layanan-layout">
        {!appUserIsEmbed && (
          <Row className="header">
            <Col>
              <HeaderComponent title="PLN" onToggle={toggleSidebar} />
            </Col>
          </Row>
        )}

        <Row>
          {!appUserIsEmbed && (
            <Col
              md={2}
              className={classNames('col-sidebar', { 'is-open': isOpen, 'is-mobile': isMobile })}
            >
              <SidebarComponent isOpen={isOpen} />
            </Col>
          )}

          <Col
            md={{
              span: appUserIsEmbed ? 12 : isOpen ? 10 : 12,
              offset: appUserIsEmbed ? 0 : isOpen ? 2 : 0,
            }}
          >
            <Jumbotron fluid className="banner pln">
              <Row>
                <Col>
                  <Row>
                    <span className={classNames('banner-title', { 'is-mobile': isMobile })}>
                      <FontAwesomeIcon icon="bolt" fixedWidth className="mr-3" />
                      PLN Prabayar
                    </span>
                  </Row>
                </Col>

                {!appUserId ? (
                  <Col lg={3} xs={12} md={3} style={{ margin: 'auto' }}>
                    <Button
                      className={classNames('button-login', { 'is-mobile': isMobile })}
                      type="button"
                      onClick={openLoginModal}
                    >
                      Daftar / Login
                    </Button>
                  </Col>
                ) : (
                  <Col lg={3} xs={12} md={3} style={{ margin: 'auto' }}>
                    <Row>
                      <Col lg={10} xs={10} md={10}>
                        <div className="banner-saldo">Saldo Anda</div>
                        <div className="banner-nominal">
                          Rp. {Formatter.price(Math.round(parseFloat(appUserBalance)))}
                        </div>
                      </Col>
                      <Col lg={2} xs={2} md={2}>
                        <span className="banner-title">
                          <FontAwesomeIcon icon="wallet" fixedWidth className="mr-3" />
                        </span>
                      </Col>
                    </Row>
                  </Col>
                )}
              </Row>
            </Jumbotron>

            <Col className="d-flex justify-content-center mb-3 p-3">
              <h2 className="text-dark text-justify">
                Beli Token Listrik PLN Prabayar Lebih Praktis Pakai SpeedCash
              </h2>
            </Col>

            <div className="content-layanan">
              <Row className="justify-content-md-center">
                <Col lg={isInqWeb ? 7 : 8} xs={12} md={isInqWeb ? 7 : 8} className="bd-form">
                  <Tabs
                    fill
                    variant="tabs"
                    activeKey="PLNPRA"
                    onSelect={onTabChange}
                    transition={false}
                    className="tab-form txt-16 txt-bold"
                  >
                    <Tab eventKey="PLNPRA" title="PLN Prabayar">
                      <Form className="form-pelanggan">
                        <Form.Label className="form-label start">ID Pelanggan</Form.Label>
                        <InputGroup className="mb-12">
                          <Form.Control
                            placeholder="Masukkan ID Pelanggan"
                            aria-label="ID Pelanggan"
                            aria-describedby="ID Pelanggan"
                            name="pelanggan"
                            value={pelanggan}
                            onChange={onPelangganChange}
                            disabled={isInqWeb || plnModalShow}
                          />
                          <InputGroup.Append className="flex align-items-center">
                            <Image
                              className={classNames('form-icon-btn', { 'ic-mobile': isMobile })}
                              src="/icon/clear.png"
                              data-target="pelanggan"
                              onClick={handleClear}
                            />
                          </InputGroup.Append>
                        </InputGroup>
                      </Form>
                      <div className="form-produk">
                        <span className="form-label start">Nominal Token</span>
                        <Row>
                          <Col
                            xs={6}
                            md={4}
                            lg={3}
                            className="col-produk"
                            data-value="20000"
                            onClick={onSetToken}
                          >
                            <div
                              className={classNames('col-prod-item', {
                                'token-selected': token === '20000',
                              })}
                            >
                              20 ribu
                            </div>
                          </Col>
                          <Col
                            xs={6}
                            md={4}
                            lg={3}
                            className="col-produk"
                            data-value="50000"
                            onClick={onSetToken}
                          >
                            <div
                              className={classNames('col-prod-item', {
                                'token-selected': token === '50000',
                              })}
                            >
                              50 ribu
                            </div>
                          </Col>
                          <Col
                            xs={6}
                            md={4}
                            lg={3}
                            className="col-produk"
                            data-value="100000"
                            onClick={onSetToken}
                          >
                            <div
                              className={classNames('col-prod-item', {
                                'token-selected': token === '100000',
                              })}
                            >
                              100 ribu
                            </div>
                          </Col>
                          <Col
                            xs={6}
                            md={4}
                            lg={3}
                            className="col-produk"
                            data-value="200000"
                            onClick={onSetToken}
                          >
                            <div
                              className={classNames('col-prod-item', {
                                'token-selected': token === '200000',
                              })}
                            >
                              200 ribu
                            </div>
                          </Col>
                          <Col
                            xs={6}
                            md={4}
                            lg={3}
                            className="col-produk"
                            data-value="500000"
                            onClick={onSetToken}
                          >
                            <div
                              className={classNames('col-prod-item', {
                                'token-selected': token === '500000',
                              })}
                            >
                              500 ribu
                            </div>
                          </Col>
                          <Col
                            xs={6}
                            md={4}
                            lg={3}
                            className="col-produk"
                            data-value="1000000"
                            onClick={onSetToken}
                          >
                            <div
                              className={classNames('col-prod-item', {
                                'token-selected': token === '1000000',
                              })}
                            >
                              1 juta
                            </div>
                          </Col>
                          <Col
                            xs={6}
                            md={4}
                            lg={3}
                            className="col-produk"
                            data-value="5000000"
                            onClick={onSetToken}
                          >
                            <div
                              className={classNames('col-prod-item', {
                                'token-selected': token === '5000000',
                              })}
                            >
                              5 juta
                            </div>
                          </Col>
                          <Col
                            xs={6}
                            md={4}
                            lg={3}
                            className="col-produk"
                            data-value="10000000"
                            onClick={onSetToken}
                          >
                            <div
                              className={classNames('col-prod-item', {
                                'token-selected': token === '10000000',
                              })}
                            >
                              10 juta
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Tab>
                    <Tab eventKey="PLNPASC" title="PLN Pascabayar" />
                    <Tab eventKey="PLNNON" title="PLN Non-Taglis" />
                  </Tabs>
                  <Row className="row-proses" ref={btnNextRef}>
                    <Col style={{ margin: 'auto' }}>
                      <Button
                        className="btn-process"
                        size="lg"
                        block
                        onClick={processPln}
                        disabled={appIsLoading}
                      >
                        {appIsLoading && !isMobile && (
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                        )}
                        {appIsLoading && !isMobile ? ' Mohon Tunggu...' : 'Lanjutkan'}
                      </Button>
                    </Col>
                  </Row>
                  {lastTrx.length > 0 && (
                    <div className="form-last-trx">
                      <span className="form-label">Transaksi Terakhir</span>
                      <Row
                        style={{
                          border: '1px solid #DDDDDD',
                          borderRadius: '10px',
                          alignItems: 'center',
                          padding: '10px',
                        }}
                      >
                        <Col lg={1} xs={2} md={1}>
                          <Image src={lastTrx[0].image_url} height={40} />
                        </Col>
                        <Col
                          lg={8}
                          xs={10}
                          md={8}
                          data-target={lastTrx[0].id_pelanggan}
                          onClick={confirmLastTrx}
                          className="pointing"
                        >
                          <Row>
                            <span className="form-label-main">{lastTrx[0].id_pelanggan}</span>
                          </Row>
                          <Row>
                            <span className="label-text">{lastTrx[0].nama_pelanggan}</span>
                          </Row>
                        </Col>
                        <Col lg={3} xs={8} md={3} onClick={openLastTransaction}>
                          <div className="label-text color-primary pointing">
                            Lihat riwayat transaksi
                          </div>
                        </Col>
                      </Row>
                    </div>
                  )}
                </Col>
                {isInqWeb && (
                  <>
                    <Col md="auto" />
                    <Col lg={4} xs={12} md={4} className="bd-form-inq">
                      <Row className="justify-content-md-center">
                        <Col className="inq-header txt-bold">Tagihan PLN Anda</Col>
                      </Row>
                      {dataRes &&
                        dataRes.strukMaps.map((val) => (
                          <Row key={Formatter.upperFirst(Object.keys(val)[0])}>
                            <Col>{Formatter.upperFirst(Object.keys(val)[0])}</Col>
                            <Col className="inq-end-label">
                              {val ? val[Object.keys(val)[0]] : ''}
                            </Col>
                          </Row>
                        ))}
                      <Row className="justify-content-md-center inq-body-last">
                        <Col className="inq-balance">
                          <Row style={{ display: 'flex', alignItems: 'center' }}>
                            <Col lg={2} xs={2} md={2}>
                              <Image src="/img/sc.png" roundedCircle />
                            </Col>
                            <Col lg={5} xs={5} md={5}>
                              Saldo Speedcash
                            </Col>
                            <Col lg={5} xs={5} md={5} className="inq-end-label txt-bold">
                              {dataRes
                                ? `Rp ${Formatter.price(
                                    Math.round(parseFloat(dataRes.includes.balance))
                                  )}`
                                : 'Rp 0,-'}
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      {!isSaldoOk && appUserId && (
                        <Row className="justify-content-md-center inq-body-last">
                          <Col className="inq-balance not-ok">
                            <Row className="txt-bold">
                              Saldo Anda belum mencukupi untuk transaksi ini. Segera Top Up
                            </Row>
                          </Col>
                        </Row>
                      )}
                      <Row className="justify-content-md-center">
                        <Col>
                          <Button
                            className="btn-process"
                            type="button"
                            block
                            onClick={
                              appUserId
                                ? isSaldoOk
                                  ? confirmPlnModal
                                  : redirectToDeposit
                                : openLoginModal
                            }
                          >
                            {appIsLoading && (
                              <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                              />
                            )}
                            {appIsLoading
                              ? ' Mohon Tunggu...'
                              : appUserId
                              ? isSaldoOk
                                ? 'Bayar Sekarang'
                                : 'Top Up Saldo'
                              : 'Daftar / Login'}
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                  </>
                )}
              </Row>
            </div>

            <AccordionPlnPraComponent />
          </Col>
        </Row>
        <Alert
          header="Perhatian"
          btnText="OK"
          text={alertError.rd}
          type="error"
          show={!!alertError.rd}
          onClosePress={closeError}
          showBorderBottom
          alertStyles={{ justifyContent: 'center' }}
        />
        <LoginModal
          show={!!loginModalShow}
          onHide={closeLoginModal}
          onConfirm={confirmLoginModal}
        />
        <PlnModal
          show={!!plnModalShow}
          onHide={closePlnModal}
          onConfirm={confirmPlnModal}
          dataRes={dataRes}
          dataTotal={total}
          dataProduk="PLNPRA"
          isSaldoOk={isSaldoOk}
        />
        <LastTrxDialog
          show={!!lastTrxShow}
          onHide={closeLastTransaction}
          onConfirm={confirmLastTrx}
          search={search}
          onSearchChange={onSearchChange}
          data={lastTrxFiltered}
        />
      </Container>
    </>
  )
}

PlnPraScreen.defaultProps = {
  appIsLoading: false,
  appUserId: '',
  appUserPin: '',
  appUserBalance: 0,
  appUserIsEmbed: false,
}

PlnPraScreen.propTypes = {
  appUserId: PropTypes.string,
  appUserPin: PropTypes.string,
  appUserBalance: PropTypes.number,
  appUserIsEmbed: PropTypes.bool,
  appIsLoading: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
}

export default connect(
  (state) => ({
    appIsLoading: state.Global[globalConstant.IS_LOADING],
    appUserId: state.Auth[authConstant.USER_ID],
    appUserPin: state.Auth[authConstant.USER_PIN],
    appUserBalance: state.Auth[authConstant.USER_BALANCE],
    appUserIsEmbed: state.Auth[authConstant.USER_IS_EMBED],
  }),
  (dispatch) => ({ dispatch })
)(PlnPraScreen)
