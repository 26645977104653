import 'core-js/es/map'
import 'core-js/es/set'
import 'core-js/es/array/find'
import 'core-js/es/array/includes'
import 'core-js/es/number/is-nan'
import 'promise-polyfill/src/polyfill'
import 'core-js/stable'
import 'regenerator-runtime/runtime'
// import '@babel/polyfill'

import React from 'react'
import ReactDOM from 'react-dom'
import ReactGA from 'react-ga'
import TagManager from 'react-gtm-module'

import './bootstrap.min.css'
import App from './App'
import reportWebVitals from './reportWebVitals'

ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTIC)

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER,
}

TagManager.initialize(tagManagerArgs)

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
