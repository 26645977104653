import BaseMessage from '../base'
import DateTime from '../../../helper/datetime'
import Var from '../../../helper/var'

export default class DepositMessage extends BaseMessage {
  constructor(data) {
    super(data)

    this.setPath(`${DepositMessage.MP_PHP}/`)

    if (!data) {
      this.data.transmission_datetime = DateTime.format(
        new Date(),
        DateTime.FMT_DATE_TIME_YMDHMS_NS
      )
      this.data.includes = {}
      this.data.additional_datas = Array(this.getAdditionalDataCapacity()).fill('')
      this.data.campaign = {
        utm_term: '',
        utm_user_id: '',
        utm_campaign: '',
        utm_name: '',
        utm_medium: '',
        utm_content: '',
        utm_source: '',
      }

      this.setAsEncrypted()
    }
  }

  setOutletID(id) {
    this.data.outlet_id = id
  }

  getOutletID() {
    return Var.toString(this.data.outlet_id)
  }

  setPIN(pin) {
    this.data.pin = pin
  }

  getPIN() {
    return Var.toString(this.data.pin)
  }

  setMessageType(type) {
    this.data.msg_type = type
  }

  getMessageType() {
    return Var.toString(this.data.msg_type)
  }

  setProcessingCode(code) {
    this.data.processing_code = code
  }

  getProcessingCode() {
    return Var.toString(this.data.processing_code)
  }

  setAdditionalDatum(index, value) {
    this.data.additional_datas[Var.toInt(index)] = value
  }

  getAdditionalDatum(index) {
    return this.data.additional_datas[Var.toInt(index)] || ''
  }

  // eslint-disable-next-line class-methods-use-this
  getAdditionalDataCapacity() {
    return 16
  }
}
