import React from 'react'
import { Button } from 'react-bootstrap'
import './NotFoundPage.scss'

function NotFoundPage() {
  return (
    <div className="not-found-page">
      <header>
        <img
          alt="SpeedCash"
          src="/img/logo.png"
          width="150"
          height="48"
          className="d-inline-block align-top"
          style={{ height: '100%' }}
        />
      </header>
      <main>
        <h1>Oops! We could not find the page you were looking for.</h1>
        <p>Please check the URL and try again.</p>
        <Button href="/">Return to Homepage</Button>
      </main>
      <footer>&copy; {new Date().getFullYear()} www.speedcash.co.id</footer>
    </div>
  )
}

export default NotFoundPage
