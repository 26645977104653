import FmMessage from '../../message/mp/fm'
import MPMessage from '../../message/mp'
import MessageSender from '../../sender'
import authConstant from '../../../store/constant/auth'

export const checkFm = (idOutlet) => async (dispatch) => {
  const msg = new FmMessage()
  msg.setPathCheck()
  msg.setOutletId(idOutlet)

  const response = await new MessageSender().doPost(msg)

  const msg2 = new FmMessage(response)
  if (msg2.isOK()) {
    await dispatch({
      type: authConstant.USER,
      payload: {
        [authConstant.USER_IS_MARKETER]: true,
      },
    })
  }

  return msg2
}

export const reportFm = (userId, startDate, endDate, limit, offset) => async () => {
  const msg = new FmMessage()
  msg.setPathReport()
  msg.setUserId(userId)
  msg.setStartDate(startDate)
  msg.setEndDate(endDate)
  msg.setLimit(limit)
  msg.setOffset(offset)
  msg.data.timestamp = new Date().getTime()

  const response = await new MessageSender().doGet(msg)

  return new FmMessage(response)
}

export const commissionFm = (userId, startDate, endDate) => async () => {
  const msg = new FmMessage()
  msg.setPathCommission()
  msg.setUserId(userId)
  msg.setStartDate(startDate)
  msg.setEndDate(endDate)
  msg.data.timestamp = new Date().getTime()
  const response = await new MessageSender().doGet(msg)

  return new FmMessage(response)
}

export const companionFm = (userId, startDate, endDate) => async () => {
  const msg = new FmMessage()
  msg.setPathCompanion()
  msg.setUserId(userId)
  msg.setStartDate(startDate)
  msg.setEndDate(endDate)
  msg.data.timestamp = new Date().getTime()

  const response = await new MessageSender().doGet(msg)

  return new FmMessage(response)
}

export const friendFm = (userId, startDate, endDate, limit, offset) => async () => {
  const msg = new FmMessage()
  msg.setPathFriend()
  msg.setUserId(userId)
  msg.setStartDate(startDate)
  msg.setEndDate(endDate)
  msg.setLimit(limit)
  msg.setOffset(offset)
  msg.data.timestamp = new Date().getTime()

  const response = await new MessageSender().doGet(msg)

  return new FmMessage(response)
}

export const checkDisbursement = (userId, pin) => async () => {
  const msg = new MPMessage()
  msg.setPath(`${MPMessage.MP_PHP}/`)
  msg.setMessageType('TRX')
  msg.setProcessingCode('')
  msg.setIncludeValue(MPMessage.INCLUDES.PRODUCT_CODE, 'REPMERC')
  msg.setOutletID(userId)
  msg.setPIN(pin)
  msg.setAdditionalDatum(0, 'bonus_komisi_trx')
  msg.setCampaign('utm_user_id', userId)
  msg.setUploadDetail()
  msg.setUploadDetailObject(0)
  msg.setUploadDetailItem(0, 'bSortable_1', 1)
  msg.setUploadDetailItem(0, 'iSortCol_0', 1)
  msg.setUploadDetailItem(0, 'iSortingCols', 1)
  msg.setUploadDetailItem(0, 'sSortDir_0', 'desc')
  msg.setAsEncrypted()

  const response = await new MessageSender().doPost(msg)

  return new MPMessage(response)
}

export const processDisbursement = (userId, pin, nominal) => async () => {
  const msg = new MPMessage()
  msg.setPath(`${MPMessage.MP_NODE}/`)
  msg.setMessageType('TRX')
  msg.setIncludeValue(MPMessage.INCLUDES.PRODUCT_CODE, 'SCKOM')
  msg.setOutletID(userId)
  msg.setPIN(pin)
  msg.setAdditionalDatum(0, nominal)
  msg.setCampaign('utm_user_id', userId)
  msg.setAsEncrypted()
  msg.setAsManualPin()

  const response = await new MessageSender().doPost(msg)

  return new MPMessage(response)
}
