import React from 'react'
import { Button, Image, Row, Col, Accordion, Card } from 'react-bootstrap'
import './accordion.scss'

const AccordionEmoneyShopee = () => {
  return (
    <>
      <Col className="d-flex justify-content-center mt-4 mb-3 p-3">
        <h3 className="text-dark text-justify">
          Top Up ShopeePay Pakai SpeedCash Lebih Hemat Dan Praktis
        </h3>
      </Col>

      <div style={{ padding: '0px 20px 30px 20px' }}>
        <Row className="justify-content-md-center">
          <Col lg={8} xs={12} md={8} className="content-component">
            <div>
              <Row className="d-flex">
                <p className="d-block text-justify">
                  Belanja online merupakan rutinitas daring yang banyak dilakukan masyarakat pada
                  umumnya.Salah satu platform belanja online yang banyak dipakai masyarakat kita
                  saat ini adalah Shopee.Tentunya dengan platform belanja shopee mempunyai metode
                  pembayaran sendiri yaitu ShopeePay.Nah salah satu syarat untuk menggunakan
                  ShopeePay yaitu harus top up saldo ShopeePay. Disini kami akan berikan informasi
                  mengenai{' '}
                  <a href="https://member.speedcash.co.id/emoney/top-up-shopeepay">
                    top up ShopeePay
                  </a>{' '}
                  bebas nominal.
                </p>
                <Row className="d-flex mt-4 mb-2 justify-content-center text-dark w-100">
                  <h4>Cara Top Up ShopeePay Melalui Web SpeedCash</h4>
                </Row>
                <Row className="d-flex justify-content-center">
                  <Image
                    src="/img/top-up-shopeepay-gratis-admin.png"
                    data-target="top-up-shopeepay-gratis-admin"
                    alt="Topup Shopeepay Gratis Admin"
                    width="100%"
                  />
                </Row>
                <Row>
                  <p className="d-block text-justify mt-3">
                    Begini cara top up ShopeePay melalui website SpeedCash :
                  </p>
                </Row>
                <Row className="d-flex justify-content-start">
                  <ol className="text-justify list-content">
                    <li>
                      Kunjungi alamat web : https://member.speedcash.co.id/emoney/top-up-shopeepay
                    </li>
                    <li>Login dulu menggunakan nomor HP yang aktif</li>
                    <li>Jika sudah login, jangan lupa untuk isi saldo SpeedCash dulu</li>
                    <li>
                      Jika sudah mengisi saldo SpeedCash, kamu bisa langsung masuk ke menu E-Money
                    </li>
                    <li>Lalu isikan no hp kamu yang terdaftar di shopee</li>
                    <li>Dan pilih nominal saldo yang ingin kamu top up di shopee</li>
                    <li>Jika sudah kamu klik lanjutkan</li>
                  </ol>
                </Row>
              </Row>
            </div>
          </Col>

          <Col lg={8} xs={12} md={8} className="accordion-bpjs">
            <Accordion className="m-0 p-0">
              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="1"
                    className="button-collapse w-100 text-left"
                  >
                    Top Up ShopeePay Melalui Aplikasi
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="1">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Ternyata untuk top up shopeepay dengan aplikasi SpeedCash juga tidak kalah
                        praktisnya, berikut kamu bisa mengikuti caranya:
                      </p>
                      <Row className="mt-3">
                        <ul className="text-justify list-content">
                          <li>
                            Download dulu aplikasi SpeedCash yang ada di Playstore maupun Appstore
                          </li>
                          <li>
                            Jika sudah download jangan lupa untuk login dulu menggunakan nomor hp ya
                          </li>
                          <li>Jika sudah login, langsung klik menu transfer e-wallet</li>
                          <li>
                            <p>Pilih menu SALDO SHOPEE</p>
                            <Row className="d-flex justify-content-center">
                              <Image
                                src="/img/mockup-top-up-emoney.png"
                                data-target="topup-emoney"
                                width="220px"
                                height="400px"
                              />
                            </Row>
                          </li>
                          <li>Masukkan nomor HP yang aktif di aplikasi Shopee / ShopeePay</li>
                          <li>
                            <p>Lalu Masukkan Nominal top up ShopeePay yang ingin kamu masukkan</p>
                            <Row className="d-flex justify-content-center">
                              <Image
                                src="/img/mockup-top-up-shopeepay.png"
                                data-target="top-up-shopeepay"
                                width="220px"
                                height="400px"
                              />
                            </Row>
                          </li>
                          <li>Atau bisa juga kamu Top Up ShopeePay dengan nominal bebas</li>
                          <li>Jika sudah kamu bisa langsung klik Lanjutkan</li>
                          <li>Dan ikuti instruksi selanjutnya ya</li>
                        </ul>
                      </Row>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="2"
                    className="button-collapse w-100 text-left"
                  >
                    Cara Isi Saldo SpeedCash Paling Mudah Dan Cepat
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="2">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        SpeedCash adalah salah satu layanan keuangan yang semakin populer di
                        kalangan masyarakat Indonesia. Layanan ini memungkinkan pengguna untuk
                        melakukan transaksi keuangan, pembayaran, dan transfer uang secara mudah dan
                        cepat. Salah satu hal yang penting untuk memanfaatkan layanan SpeedCash
                        adalah dengan memiliki saldo yang mencukupi di akun Anda. Berikut ini adalah
                        panduan lengkap tentang cara mengisi saldo SpeedCash:
                      </p>
                      <ul className="text-justify list-content">
                        <li>Buka aplikasi SpeedCash</li>
                        <li>Klik menu Top Up (dipojok kanan atas)</li>
                        <li>Pilih menu transfer cepat </li>
                        <li>lalu pilih salah satu no rekening VA</li>
                        <li>Dan ikuti instruksi selanjutnya</li>
                      </ul>
                      <p className="d-block text-justify mt-1">
                        JIka kamu masih bingung menggunakan cara diatas, berikut kami sudah sediakan
                        mengenai cara top up saldo SpeedCash yang sudah kami sediakan di video
                        youtube dibawah ini.
                      </p>
                      <iframe
                        width="100%"
                        height="480"
                        src="https://www.youtube.com/embed/sI1fLYglLwk"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        frameBorder={0}
                        title="Cara Isi Saldo Di SpeedCash"
                        className="mb-2"
                      />
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="3"
                    className="button-collapse w-100 text-left"
                  >
                    Kenapa Harus Top Up ShopeePay Di SpeedCash
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="3">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Ternyata menggunakan SpeedCash tidak hanya bisa untuk top up saldo ShopeePay
                        tetapi juga bisa untuk digunakan layanan tagihan bulanan lainnya seperti,{' '}
                        <b>cara top up dana</b>,{' '}
                        <a href="https://member.speedcash.co.id/cek-bpjs-kesehatan">
                          cek BPJS kesehatan
                        </a>
                        , <a href="https://member.speedcash.co.id/telkom">Bayar tagihan indihome</a>{' '}
                        dan tentunya masih banyak lainnya.
                      </p>
                      <p className="d-block text-justify mt-1">
                        Kemudahan menggunakan SpeedCash untuk top up ShopeePay ternyata bukan cuma
                        itu saja, tetapi ada alasan lain yang wajib kamu ketahui mengapa harus
                        menggunakan SpeedCash untuk top up Shopee :
                      </p>
                      <p className="d-block text-justify mt-1">
                        <b>Kemudahan dan Kecepatan :</b> SpeedCash menyediakan layanan yang cepat
                        dan mudah dalam proses top up saldo ShopeePay. Anda tidak perlu repot
                        mencari tempat atau metode lain untuk melakukan top up, karena SpeedCash
                        biasanya tersedia di banyak tempat yang nyaman dan mudah diakses.
                      </p>
                      <p className="d-block text-justify mt-1">
                        <b>Keamanan :</b> SpeedCash menawarkan sistem transaksi yang aman, yang
                        dapat memberikan rasa percaya kepada pengguna bahwa transaksi mereka
                        dilakukan dengan aman dan terlindungi dari ancaman keamanan.
                      </p>
                      <p className="d-block text-justify mt-1">
                        <b>Fleksibilitas :</b> Dengan menggunakan SpeedCash, Anda dapat melakukan
                        top up saldo ShopeePay kapan saja dan di mana saja sesuai dengan kebutuhan
                        Anda. Ini memberikan fleksibilitas yang lebih besar dibandingkan dengan
                        beberapa metode lain yang mungkin memerlukan akses internet atau alat
                        pembayaran khusus.
                      </p>
                      <p className="d-block text-justify mt-1">
                        <b>Tidak Bergantung Bank :</b> Beberapa orang mungkin tidak memiliki akses
                        ke kartu bank atau tidak nyaman menggunakan informasi kartu mereka secara
                        online. Dengan SpeedCash, Anda tidak perlu kartu bank untuk melakukan
                        transaksi, karena cukup menggunakan uang tunai.
                      </p>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="4"
                    className="button-collapse w-100 text-left"
                  >
                    Nominal Top Up ShopeePay Di SpeedCash
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="4">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Ternyata untuk mengisi saldo/ top up shopeepay via speedcash selain sangat
                        mudah juga tersedia aneka banyak nominal saldo shopeepay hingga kamu bisa
                        top up saldo shopeepay bebas nominal. Berikut besaran nominal top up saldo
                        shopeepay di SpeedCash.
                      </p>
                      <table className="my-2 table table-bordered">
                        <tr>
                          <td>Nominal 10k = Rp.10.650</td>
                        </tr>
                        <tr>
                          <td>Nominal 50k = Rp.51.500</td>
                        </tr>
                        <tr>
                          <td>Nominal 150k = Rp.150.650</td>
                        </tr>
                        <tr>
                          <td>Nominal 300k = Rp.301.500</td>
                        </tr>
                        <tr>
                          <td>Nominal 400k = Rp.401.500</td>
                        </tr>
                      </table>
                      <p className="d-block text-justify mt-1">
                        Itulah tadi cuplikan jika kamu top up shopeepay melalui SpeedCash, tidak
                        hanya nominal diatas saja tetapi kamu bisa juga top up shopeepay bebas
                        nominal (artinya kamu bisa menentukan sendiri isi saldo shopeepay mu sesuai
                        dengan kebutuhanmu)
                      </p>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="5"
                    className="button-collapse w-100 text-left"
                  >
                    F.A.Q Mengenai Top Up ShopeePay Di SpeedCash
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="5">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Berikut merupakan FAQ (Pertanyaan Umum) tentang top up ShopeePay menggunakan
                        SpeedCash:
                      </p>
                      <p className="d-block text-justify mt-1">
                        <b className="d-block">Apa itu SpeedCash?</b>
                        SpeedCash adalah layanan yang memungkinkan pengguna untuk melakukan berbagai
                        transaksi finansial, termasuk top up saldo ShopeePay.
                      </p>
                      <p className="d-block text-justify mt-1">
                        <b className="d-block">
                          Bagaimana cara top up ShopeePay menggunakan SpeedCash?
                        </b>
                        Untuk melakukan top up ShopeePay menggunakan SpeedCash, Anda perlu
                        mengunjungi salah satu outlet SpeedCash yang berpartisipasi, kemudian
                        memberikan uang tunai kepada kasir dan memberikan nomor ponsel yang
                        terdaftar di akun ShopeePay Anda. Kasir akan melakukan proses top up saldo
                        ShopeePay Anda sesuai dengan jumlah yang Anda bayarkan.
                      </p>
                      <p className="d-block text-justify mt-1">
                        <b className="d-block">
                          Apakah ada biaya tambahan untuk top up ShopeePay melalui SpeedCash?
                        </b>
                        Biaya tambahan untuk top up ShopeePay melalui SpeedCash mungkin berlaku
                        tergantung pada kebijakan dan tarif dari outlet SpeedCash yang Anda
                        kunjungi. Pastikan untuk memverifikasi biaya tambahan dengan kasir sebelum
                        melakukan transaksi.
                      </p>
                      <p className="d-block text-justify mt-1">
                        <b className="d-block">
                          Berapa lama waktu yang dibutuhkan untuk top up ShopeePay melalui
                          SpeedCash?
                        </b>
                        Waktu yang dibutuhkan untuk top up ShopeePay melalui SpeedCash biasanya
                        instan atau hampir instan setelah pembayaran Anda diverifikasi oleh kasir.
                      </p>
                      <p className="d-block text-justify mt-1">
                        <b className="d-block">
                          Apakah ada batasan jumlah maksimum atau minimum untuk top up ShopeePay
                          melalui SpeedCash?
                        </b>
                        Batasan jumlah maksimum atau minimum untuk top up ShopeePay melalui
                        SpeedCash dapat bervariasi tergantung pada kebijakan dari outlet SpeedCash
                        yang Anda kunjungi. Pastikan untuk memverifikasi batasan jumlah dengan kasir
                        sebelum melakukan transaksi.
                      </p>
                      <p className="d-block text-justify mt-1">
                        <b className="d-block">
                          Apa yang harus dilakukan jika ada masalah saat melakukan top up ShopeePay
                          melalui SpeedCash?
                        </b>
                        Jika Anda mengalami masalah saat melakukan top up ShopeePay melalui
                        SpeedCash, Anda dapat menghubungi layanan pelanggan SpeedCash untuk
                        mendapatkan bantuan lebih lanjut.
                      </p>
                      <p className="d-block text-justify mt-1">
                        <b className="d-block">Apakah top up ShopeePay melalui SpeedCash aman?</b>
                        Top up ShopeePay melalui SpeedCash dapat dianggap aman karena transaksi
                        dilakukan secara langsung melalui outlet SpeedCash yang resmi. Namun,
                        pastikan untuk tidak memberikan informasi pribadi atau rahasia kepada pihak
                        yang tidak terpercaya selama proses transaksi.
                      </p>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="6"
                    className="button-collapse w-100 text-left"
                  >
                    Sekilas Tentang ShopeePay
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="6">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        ShopeePay merupakan dompet penyimpanan uang online milik Shopee dan dapat
                        digunakan untuk melakukan pembelian online.
                      </p>
                      <p className="d-block text-justify mt-1">
                        ShopeePay dapat dipahami sebagai tabungan untuk transaksi digital karena
                        Shopeepay tidak memiliki bentuk fisik seperti kartu ATM.
                      </p>
                      <p className="d-block text-justify mt-1">
                        Selain itu, Shopeepay juga berfungsi sebagai media atau wadah untuk
                        menampung refund atau pengembalian jika pembeli tidak puas dengan barang
                        yang dibeli. Pengembalian dana terjadi karena barang atau produk rusak atau
                        kehabisan stok dan bisa juga disebabkan oleh faktor lain.
                      </p>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="7"
                    className="button-collapse w-100 text-left"
                  >
                    Fungsi ShopeePay
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="7">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Penggunaan ShopeePay tidak hanya untuk pembelian di aplikasi Shopee saja,
                        namun juga bisa digunakan sebagai toko refund atau pengembalian uang.
                        Biasanya pengembalian dana ini disebabkan karena produk yang dibeli
                        kehabisan stok, rusak, atau mengalami masalah lainnya. Oleh karena itu,
                        teknologi belanja digital membawa perubahan baru, yang awalnya beralih dari
                        mentransfer uang melalui counter bank menjadi sekadar memotong saldo di
                        e-wallet. Misalnya saja Shopee yang sukses dengan ShopeePay. ShopeePay
                        tentunya bukan satu-satunya dompet digital populer di Indonesia, ada juga
                        DANA, GOPAY, LinkAja dan OVO.
                      </p>
                      <p className="d-block text-justify mt-1">
                        Belanja digital dengan ShopeePay menawarkan banyak keuntungan dan kemudahan.
                        Misalnya saja fitur gratis ongkos kirim dari Shopee yang hanya mendukung
                        metode pembayaran menggunakan ShopeePay atau SPayLater. Selain kedua metode
                        pembayaran tersebut, pembeli tetap perlu membayar biaya pengiriman
                        berdasarkan jumlah yang dikenakan.
                      </p>
                      <p className="d-block text-justify mt-1">
                        Selain gratis ongkos kirim, konsumen yang berbelanja dengan ShopeePay juga
                        memiliki opsi untuk menerima pengembalian uang, flash sale, dan instan
                        promosi. Membayar.
                      </p>
                      <p className="d-block text-justify mt-1">
                        <span className="d-block">
                          {' '}
                          Sudahkah Anda mengaktifkan fitur ShopeePay?
                        </span>
                        Mereka yang belum terbiasa dengan fitur ini mungkin mengalami kesulitan
                        menambahkan uang ke saldo ShopeePay mereka. Padahal cara mengisi ulang saldo
                        ShopeePay Anda sangat sederhana dan mencakup banyak metode modern. Ingin
                        tahu caranya? Ikuti petunjuk ini.
                      </p>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="8"
                    className="button-collapse w-100 text-left"
                  >
                    Top Up ShopeePay Via BCA
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="8">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Kamu lebih menyukai top up shopeepay melalui m-banking BCA, karena lebih
                        cepat ? nah ini cara top up shopeepay melalui m-banking BCA.
                      </p>
                      <ul className="text-justify list-content">
                        <li>Buka aplikasi shopee dulu</li>
                        <li>lalu pilih menu top up/isi saldo shopee</li>
                        <li>pilih transfer bank</li>
                        <li>pilih bank BCA</li>
                        <li>Jika sudah kamu akan mendapatkan no virtual account shopeepay mu</li>
                        <li>lalu masuk ke aplikasi SpeedCash</li>
                        <li>Pilih menu transfer bank (pilih yang virtual account)</li>
                        <li>
                          lanjutkan dengan mengisi no virtual account shopeepay yang sudah kamu
                          dapatkan tadi
                        </li>
                        <li>ikuti instruksinya hingga selesai</li>
                      </ul>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="9"
                    className="button-collapse w-100 text-left"
                  >
                    Top Up ShopeePay via Bank Mandiri
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="9">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Ternyata mengisi saldo shopeepay tidak cuma bisa melalaui BCA saja tetapi
                        juga bisa melalui bank mandiri.Begini caranya.
                      </p>
                      <ul className="text-justify list-content">
                        <li>Buka aplikasi shopee dulu</li>
                        <li>lalu pilih menu top up/isi saldo shopee</li>
                        <li>pilih transfer bank</li>
                        <li>pilih bank MANDIRI</li>
                        <li>Jika sudah kamu akan mendapatkan no virtual account shopeepay mu</li>
                        <li>lalu masuk ke aplikasi SpeedCash</li>
                        <li>Pilih menu transfer bank (pilih yang virtual account)</li>
                        <li>
                          lanjutkan dengan mengisi no virtual account shopeepay yang sudah kamu
                          dapatkan tadi
                        </li>
                        <li>ikuti instruksinya hingga selesai</li>
                      </ul>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="10"
                    className="button-collapse w-100 text-left"
                  >
                    Top Up ShopeePay Melalui Bank BNI
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="10">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Ternyata mengisi saldo shopeepay tidak cuma bisa melalui 2 bank tadi tetapi
                        juga bisa melalui bank BNI.Begini caranya.
                      </p>
                      <ul className="text-justify list-content">
                        <li>Buka aplikasi shopee dulu</li>
                        <li>lalu pilih menu top up/isi saldo shopee</li>
                        <li>pilih transfer bank</li>
                        <li>pilih bank BNI</li>
                        <li>Jika sudah kamu akan mendapatkan no virtual account shopeepay mu</li>
                        <li>lalu masuk ke aplikasi SpeedCash</li>
                        <li>Pilih menu transfer bank (pilih yang virtual account)</li>
                        <li>
                          lanjutkan dengan mengisi no virtual account shopeepay yang sudah kamu
                          dapatkan tadi
                        </li>
                        <li>ikuti instruksinya hingga selesai</li>
                      </ul>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="11"
                    className="button-collapse w-100 text-left"
                  >
                    Kantor Pusat Shopee Indonesia
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="11">
                  <Card.Body className="card-content">
                    <Row>
                      <div className="d-block text-justify w-100 mb-1">
                        <iframe
                          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15866.211700181871!2d106.7976579!3d-6.190526!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69f72f87446fb1%3A0x650c1318e1c7e623!2sWisma%2077%20Tower%201!5e0!3m2!1sid!2sid!4v1711958948010!5m2!1sid!2sid"
                          width="100%"
                          height="450"
                          style={{ border: '0' }}
                          allowfullscreen=""
                          loading="lazy"
                          referrerPolicy="no-referrer-when-downgrade"
                          title="Kantor Pusat Dana"
                        />
                      </div>

                      <p className="d-block text-justify mt-1">
                        Itulah tadi informasi mengenai top up shopeepay menggunakan cara yang mudah
                        dengan beragam nominal top up shopeepay yang bisa kamu pilih.Jika kamu
                        merasa tutorial ini berguna kamu bisa mendownload aplikasi SpeedCash melalui
                        link download dibawah ini.
                      </p>
                      <div className="mt-1 w-100 d-flex justify-content-center">
                        <h5 className="bold">
                          Top Up ShopeePay Jadi Lebih Mudah Via Aplikasi SpeedCash.
                        </h5>
                      </div>
                      <Row className="d-flex w-100 justify-content-center mb-3 gap-3">
                        <a
                          aria-hidden
                          href="https://play.google.com/store/apps/details?id=com.bm.sc.bebasbayar"
                          className="mx-1"
                        >
                          <Image
                            src="/img/playstore-speedcash.png"
                            data-target="playstore-speedcash"
                            width="220px"
                            height="60px"
                          />
                        </a>
                        <a
                          aria-hidden
                          href="https://apps.apple.com/id/app/speedcash-transfer-ewallet/id6459304122"
                          className="mx-1"
                        >
                          <Image
                            src="/img/appstore-speedcash.png"
                            data-target="appstore-speedcash"
                            width="220px"
                            height="60px"
                          />
                        </a>
                      </Row>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default AccordionEmoneyShopee
