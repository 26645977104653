import BaseMessage from '../../message/base'
import LayananMessage from '../../message/mp/layanan'
import MessageSender from '../../sender'
import authConstant from '../../../store/constant/auth'

export const emoneyProduk = (outletId) => async () => {
  const msg = new BaseMessage()
  msg.setPath(`${BaseMessage.SC_API}/products/ubp`)
  msg.data.id = outletId
  msg.data.grupubp = 'topup'
  msg.data.utm_user_id = outletId
  msg.data.utm_campaign = ''
  msg.data.utm_medium = ''
  msg.data.utm_term = ''
  msg.data.utm_name = ''
  msg.data.utm_content = ''
  msg.data.utm_source = ''
  msg.data.timestamp = new Date().getTime()

  const response = await new MessageSender().doGet(msg)
  return new BaseMessage(response)
}

export const emoneyPay = (outletId, pin, customer, discount, product) => async (dispatch) => {
  const msg = new LayananMessage()
  msg.setProcessingCode('PAY')
  msg.setOutletID(outletId)
  msg.setPIN(pin)
  msg.data.includes.product_code = product
  msg.data.includes.customer_id_1 = customer
  msg.data.includes.discount = discount

  msg.setAdditionalDatum(0, customer)

  msg.data.campaign.utm_user_id = outletId

  const response = await new MessageSender().doPost(msg)
  const msg2 = new LayananMessage(response)
  if (msg2.isOK()) {
    await dispatch({
      type: authConstant.USER_BALANCE,
      payload: Number(msg2.data.includes.balance),
    })
  }

  return msg2
}
