/* eslint-disable no-nested-ternary */
import React from 'react'
import { connect } from 'react-redux'
import { Modal, Button, Image, Card, Row, Col, Alert, Spinner } from 'react-bootstrap'
import PropTypes from 'prop-types'

import globalConstant from '../../../store/constant/global'
import authConstant from '../../../store/constant/auth'
import HookHelper from '../../../helper/hookhelper'
import Formatter from '../../../helper/formatter'

import './dialogLayanan.scss'

const FinanceModal = ({ appIsLoading, appUserId, onHide, onConfirm, show, dataRes, isSaldoOk }) => {
  const [alertError, setAlertError] = React.useState({ rc: '', rd: '' })

  const closeError = React.useCallback(async () => {
    setAlertError({ rc: '', rd: '' })
  }, [setAlertError])

  React.useEffect(() => {
    HookHelper.resetLoading()
  }, [])

  return (
    <Modal
      show={show}
      onHide={onHide}
      backdrop="static"
      keyboard={false}
      centered
      contentClassName="radius pln"
      animation={false}
    >
      <Modal.Header className="header">
        <div style={{ width: '95%' }}>
          <Modal.Title className="title">
            {dataRes ? dataRes.biller_info.productName : 'TAGIHAN'}
          </Modal.Title>
        </div>
        <div className="close-modal">
          <Image
            src="/icon/clear.png"
            onClick={onHide}
            roundedCircle
            className="justify-content-end"
          />
        </div>
      </Modal.Header>

      <Modal.Body className="body">
        <Card>
          <Card.Header className="card-header">
            <span>Pembayaran</span>
            <span className="label-bold end">{dataRes ? dataRes.biller_info.productName : ''}</span>
          </Card.Header>
          <Card.Body>
            <Card.Title className="text-center label-title">RINCIAN TAGIHAN</Card.Title>
            {dataRes &&
              dataRes.strukMaps.map((val) => (
                <Card.Text className="card-text" key={Formatter.upperFirst(Object.keys(val)[0])}>
                  <span>{Formatter.upperFirst(Object.keys(val)[0])}</span>
                  <span className="text-end">{val ? val[Object.keys(val)[0]] : ''}</span>
                </Card.Text>
              ))}
          </Card.Body>
        </Card>
        <Card style={{ padding: '10px' }}>
          <Row className="justify-content-md-center inq-body-last">
            <Col className="inq-balance">
              <Row style={{ display: 'flex', alignItems: 'center' }}>
                <Col lg={2} xs={2} md={2}>
                  <Image src="/img/sc.png" roundedCircle />
                </Col>
                <Col lg={5} xs={5} md={5}>
                  Saldo Speedcash
                </Col>
                <Col lg={5} xs={5} md={5} className="inq-end-label txt-bold">
                  {dataRes
                    ? `Rp ${Formatter.price(Math.round(parseFloat(dataRes.includes.balance)))}`
                    : 'Rp 0,-'}
                </Col>
              </Row>
            </Col>
          </Row>
        </Card>
        <Card>
          {!isSaldoOk && appUserId && (
            <Row className="justify-content-md-center inq-body-last">
              <Col className="inq-balance not-ok">
                <Row className="txt-bold">
                  Saldo Anda belum mencukupi untuk transaksi ini. Segera Top Up
                </Row>
              </Col>
            </Row>
          )}
        </Card>
      </Modal.Body>
      <Modal.Footer style={{ position: 'sticky', bottom: 0 }}>
        <Button className="button-modal" type="button" block onClick={onConfirm}>
          {appIsLoading && (
            <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
          )}
          {appIsLoading
            ? ' Mohon Tunggu...'
            : appUserId
            ? isSaldoOk
              ? 'Bayar Sekarang'
              : 'Top Up Saldo'
            : 'Daftar / Login'}
        </Button>
      </Modal.Footer>
      <Alert
        variant="danger"
        show={!!alertError.rd}
        onClose={closeError}
        transition={null}
        dismissible
      >
        {alertError.rd}
      </Alert>
    </Modal>
  )
}

FinanceModal.defaultProps = {
  appIsLoading: false,
  appUserId: '',
  onHide: null,
  onConfirm: null,
  show: false,
  isSaldoOk: false,
  dataRes: null,
}

FinanceModal.propTypes = {
  appIsLoading: PropTypes.bool,
  appUserId: PropTypes.string,
  onHide: PropTypes.func,
  onConfirm: PropTypes.func,
  show: PropTypes.bool,
  isSaldoOk: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  dataRes: PropTypes.object,
}

export default connect(
  (state) => ({
    appIsLoading: state.Global[globalConstant.IS_LOADING],
    appUserId: state.Auth[authConstant.USER_ID],
  }),
  (dispatch) => ({ dispatch })
)(FinanceModal)
