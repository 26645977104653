import MessageSender from '../../sender'
import RegistrasiMessage from '../../message/api/registrasi'

const crypto = require('crypto')

export default (data) => async () => {
  const msg = new RegistrasiMessage()
  msg.setPhone(data.phone)
  msg.setPIN(crypto.createHash('md5').update(data.pin).digest('hex'))
  msg.setOtp(data.otp)
  msg.setUpline(data.refId)
  msg.setName(data.name)

  if (data.email !== '') {
    msg.setEmail(data.email)
  } else {
    msg.setEmail(`emailuser${data.phone}@speedcash.co.id`)
  }

  const response = await new MessageSender().doPost(msg)

  return new RegistrasiMessage(response)
}
