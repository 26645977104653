import _ from 'lodash'
import LayananMessage from '../../message/mp/layanan'
import MessageSender from '../../sender'
import authConstant from '../../../store/constant/auth'

export const plnInq =
  (outletId, pin, voucherQty, customer, productCode, amount = '') =>
  async (dispatch) => {
    const msg = new LayananMessage()
    msg.setProcessingCode('INQ')
    msg.setOutletID(outletId)
    msg.setPIN(pin)
    msg.data.includes.voucher_group = 'pln'
    msg.data.includes.voucher_qty = voucherQty
    msg.data.includes.product_code = productCode
    if (amount !== '' && productCode === 'PLNPRA') {
      msg.data.includes.amount = amount
      if (customer.length === 11) {
        msg.data.includes.customer_id_1 = customer
        msg.setAdditionalDatum(0, customer)
      } else if (customer.length === 12) {
        msg.data.includes.customer_id_1 = customer
        msg.setAdditionalDatum(0, customer)
        msg.setAdditionalDatum(1, customer)
      } else {
        msg.data.includes.customer_id_1 = ''
        msg.setAdditionalDatum(0, '')
      }
    } else {
      msg.data.includes.customer_id_1 = customer
      msg.setAdditionalDatum(0, customer)
    }

    msg.data = _.omit(msg.data, ['campaign'])

    const response = await new MessageSender().doPost(msg)
    const msg2 = new LayananMessage(response)

    if (msg2.isOK()) {
      await dispatch({
        type: authConstant.USER_BALANCE,
        payload: Number(msg2.data.includes.balance),
      })
    }

    return msg2
  }

export const plnPay = (outletId, pin, customer, productCode, dataIncludes) => async (dispatch) => {
  const msg = new LayananMessage()
  msg.setProcessingCode('PAY')
  msg.setOutletID(outletId)
  msg.setPIN(pin)
  msg.data.includes = dataIncludes

  if (productCode === 'PLNPRA') {
    if (customer.length === 11) {
      msg.setAdditionalDatum(0, customer)
    } else if (customer.length === 12) {
      msg.setAdditionalDatum(0, customer)
      msg.setAdditionalDatum(1, customer)
    } else {
      msg.setAdditionalDatum(0, '')
    }
  } else {
    msg.setAdditionalDatum(0, customer)
  }

  msg.data = _.omit(msg.data, ['campaign'])

  const response = await new MessageSender().doPost(msg)
  const msg2 = new LayananMessage(response)

  if (msg2.isOK()) {
    await dispatch({
      type: authConstant.USER_BALANCE,
      payload: Number(msg2.data.includes.balance),
    })
  }

  return msg2
}
