/* eslint-disable no-nested-ternary */
import _ from 'lodash'
import React, { useRef } from 'react'
import { connect } from 'react-redux'
import {
  Tabs,
  Tab,
  Form,
  Spinner,
  Container,
  Jumbotron,
  InputGroup,
  Image,
  Button,
  Col,
  Row,
} from 'react-bootstrap'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Helmet } from 'react-helmet'
import { isMobile } from 'react-device-detect'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useHistory, useLocation } from 'react-router-dom'
import Alert from 'react-popup-alert'
import 'react-popup-alert/dist/index.css'
import DOMHelper from '../../../../helper/domhelper'
import HookHelper from '../../../../helper/hookhelper'
import Formatter from '../../../../helper/formatter'

import '../layanan.scss'
import globalConstant from '../../../../store/constant/global'
import authConstant from '../../../../store/constant/auth'
import SidebarComponent from '../../../component/sidebar'
import HeaderComponent from '../../../component/header'
import LoginModal from '../../../dialog/auth/login'
import PulsaRegulerScreen from './reguler'
import PaketDataScreen from './paketData'
import PaketSmsScreen from './paketSms'
import LastTrxDialog from '../../../dialog/lastTransaction'
import PulsaModal from '../../../dialog/layanan/pulsa'
import AccordionPulsaDataComponent from '../../../component/accordionPulsaData'

import { pulsaProduk, pulsaPay } from '../../../../api/action/layanan/pulsa'
import { lastTransaction } from '../../../../api/action/global'

const PulsaScreen = ({
  appIsLoading,
  appUserId,
  appUserPin,
  appUserBalance,
  appUserIsEmbed,
  dispatch,
}) => {
  const history = useHistory()
  const location = useLocation()
  const inqRef = useRef(null)
  const [isOpen, setIsOpen] = React.useState(!isMobile)
  const [isInqWeb, setIsInqWeb] = React.useState(false)
  const [alertError, setAlertError] = React.useState('')
  const [loginModalShow, setLoginModalShow] = React.useState(false)
  const [detailModalShow, setDetailModalShow] = React.useState(false)
  const [lastTrxShow, setLastTrxShow] = React.useState(false)
  const [userId] = React.useState(!appUserId ? process.env.REACT_APP_USER_DEMO_ID : appUserId)
  const [userPin] = React.useState(
    !appUserId ? process.env.REACT_APP_USER_DEMO_PASSWORD : appUserPin
  )
  const [isSaldoOk, setIsSaldoOk] = React.useState(false)
  const [tab, setTab] = React.useState('0')
  const [lastTrx, setLastTransaction] = React.useState([])
  const [lastTrxFiltered, setLastTransactionFiltered] = React.useState([])
  const [search, setSearch] = React.useState('')
  const [customer, setCustomer] = React.useState('')
  const [customerLabel, setCustomerLabel] = React.useState('')
  const [providerName, setProviderName] = React.useState('')
  const [providerImage, setProviderImage] = React.useState('')
  const [dataPulsaReguler, setPulsaReguler] = React.useState([])
  const [dataPaketData, setPaketData] = React.useState([])
  const [dataPaketSms, setPaketSms] = React.useState([])
  const [product, setProduct] = React.useState(null)
  const [discount] = React.useState('0')

  const toggleSidebar = React.useCallback(() => setIsOpen(!isOpen), [isOpen, setIsOpen])
  const closeError = React.useCallback(() => setAlertError(''), [])

  const confirmLoginModal = React.useCallback(async () => {
    setLoginModalShow(false)
    // window.location.reload()
  }, [])

  const openLoginModal = React.useCallback(async () => {
    setLoginModalShow(true)
  }, [])

  const closeLoginModal = React.useCallback(async () => {
    setLoginModalShow(false)
  }, [])

  const closeDetailModal = React.useCallback(async () => {
    setDetailModalShow(false)
  }, [])

  const openLastTransaction = React.useCallback(async () => {
    setLastTrxShow(true)
  }, [setLastTrxShow])

  const closeLastTransaction = React.useCallback(async () => {
    setLastTrxShow(false)
  }, [setLastTrxShow])

  const onTabChange = React.useCallback(
    (e) => {
      setIsInqWeb(false)
      setIsSaldoOk(false)
      setTab(e)
    },
    [setTab, setIsInqWeb, setIsSaldoOk]
  )

  const handleClear = React.useCallback(
    (e) => {
      const { target } = e.currentTarget.dataset
      if (target === 'customer') {
        setCustomer('')
        setCustomerLabel('')
      }

      setIsInqWeb(false)
    },
    [setCustomer, setCustomerLabel, setIsInqWeb]
  )

  const onSearchChange = React.useCallback(
    (e) => {
      const { value } = e.target
      const newData = _.filter(lastTrx, (item) => {
        return (
          _.includes(item.id_pelanggan, value) ||
          _.includes(item.nama_pelanggan.toLowerCase(), value.toLowerCase())
        )
      })

      setSearch(value)
      setLastTransactionFiltered(newData)
    },
    [lastTrx, setSearch, setLastTransactionFiltered]
  )

  const getProdukPulsa = React.useCallback(async () => {
    const response = await dispatch(pulsaProduk(userId, customer))
    if (response.isOK()) {
      const resDataProduk = response.getData()
      const { products } = resDataProduk.data[0]
      setProviderName(resDataProduk.data[0].provider)
      setProviderImage(resDataProduk.data[0].image)

      const pulsaReguler = _.filter(products, (item) => {
        return (
          !_.includes(item.namaproduk.toUpperCase(), 'DATA') &&
          !_.includes(item.namaproduk.toUpperCase(), 'GPRS') &&
          !_.includes(item.namaproduk.toUpperCase(), 'YOUTUBE') &&
          !_.includes(item.namaproduk.toUpperCase(), 'INTERNET') &&
          !_.includes(item.namaproduk.toUpperCase(), 'HOTROD') &&
          !_.includes(item.namaproduk.toUpperCase(), 'M2') &&
          !_.includes(item.namaproduk.toUpperCase(), 'SMS')
        )
      })
      setPulsaReguler(pulsaReguler)

      const paketData = _.filter(products, (item) => {
        return (
          !_.includes(item.namaproduk.toUpperCase(), 'PULSA') &&
          !_.includes(item.namaproduk.toUpperCase(), 'REGULER') &&
          !_.includes(item.namaproduk.toUpperCase(), 'SMS') &&
          (_.includes(item.namaproduk.toUpperCase(), 'DATA') ||
            _.includes(item.namaproduk.toUpperCase(), 'GPRS') ||
            _.includes(item.namaproduk.toUpperCase(), 'YOUTUBE') ||
            _.includes(item.namaproduk.toUpperCase(), 'INTERNET') ||
            _.includes(item.namaproduk.toUpperCase(), 'HOTROD') ||
            _.includes(item.namaproduk.toUpperCase(), 'M2'))
        )
      })

      setPaketData(paketData)

      const paketSms = _.filter(products, (item) => {
        return (
          !_.includes(item.namaproduk.toUpperCase(), 'PULSA') &&
          !_.includes(item.namaproduk.toUpperCase(), 'REGULER') &&
          !_.includes(item.namaproduk.toUpperCase(), 'DATA') &&
          !_.includes(item.namaproduk.toUpperCase(), 'GPRS') &&
          !_.includes(item.namaproduk.toUpperCase(), 'YOUTUBE') &&
          !_.includes(item.namaproduk.toUpperCase(), 'INTERNET') &&
          !_.includes(item.namaproduk.toUpperCase(), 'HOTROD') &&
          !_.includes(item.namaproduk.toUpperCase(), 'M2') &&
          _.includes(item.namaproduk.toUpperCase(), 'SMS')
        )
      })

      setPaketSms(paketSms)
    } else {
      setAlertError({ rc: response.getRC(), rd: response.getRD() })
    }
  }, [
    dispatch,
    userId,
    customer,
    setProviderName,
    setProviderImage,
    setPulsaReguler,
    setPaketData,
    setPaketSms,
    setAlertError,
  ])

  const onCustomerChange = React.useCallback(
    (e) => {
      const customerValue = e.target.value
      setCustomer(customerValue)
      setCustomerLabel(Formatter.serial(customerValue))
    },
    [setCustomer]
  )

  const confirmLastTrx = React.useCallback(
    (e) => {
      const { target } = e.currentTarget.dataset
      setCustomer(target)
      setCustomerLabel(Formatter.serial(target))
      setLastTrxShow(false)
    },
    [setCustomer, setCustomerLabel, setLastTrxShow]
  )

  const redirectToDeposit = React.useCallback(() => {
    history.push('/deposit')
  }, [history])

  const selectedProduct = React.useCallback(
    (value) => {
      setProduct(value)
    },
    [setProduct]
  )

  const processTransaction = React.useCallback(async () => {
    if (!product) {
      setAlertError({ rc: '', rd: 'Harap pilih produk terlebih dahulu' })
      return false
    }

    if (!appUserId) {
      setLoginModalShow(true)
      return false
    }

    if (Math.round(parseFloat(appUserBalance)) >= Math.round(parseFloat(product.hargajual))) {
      setIsSaldoOk(true)
    }

    if (isMobile) {
      setDetailModalShow(true)
    } else {
      setIsInqWeb(true)

      // eslint-disable-next-line no-unused-expressions
      inqRef.current?.scrollIntoView({ behavior: 'smooth' })
    }
    return true
  }, [
    appUserId,
    appUserBalance,
    product,
    setDetailModalShow,
    setIsInqWeb,
    setIsSaldoOk,
    setAlertError,
  ])

  const confirm = React.useCallback(async () => {
    if (isSaldoOk) {
      const response = await dispatch(
        pulsaPay(userId, userPin, customer, discount, product.idproduk)
      )
      if (response.isOK()) {
        history.push({
          pathname: '/struk',
          state: {
            parent: 'pulsa',
            parentPath: location.pathname,
            title: 'Pulsa & Paket Data',
            icon: 'mobile-alt',
            transaksi: response.data.includes.reff_id,
            trxDate: response.data.transmission_datetime,
            trxTime: response.data.transmission_datetime,
            total: response.data.includes.amount,
            customerId: response.data.includes.customer_id_1,
            discountInfo: response.data.discountInfo,
            info: 'sukses',
          },
        })
      } else if (
        response.getRC() === '' ||
        response.getRC() === '68' ||
        response.getRC().toString() === response.data.includes.reff_id.toString()
      ) {
        history.push({
          pathname: '/struk',
          state: {
            parent: 'pulsa',
            parentPath: location.pathname,
            title: 'Pulsa & Paket Data',
            icon: 'mobile-alt',
            transaksi: response.data.includes.reff_id,
            trxDate: response.data.transmission_datetime,
            trxTime: response.data.transmission_datetime,
            total: response.data.includes.amount,
            customerId: response.data.includes.customer_id_1,
            discountInfo: response.data.discountInfo,
            info: 'pending',
          },
        })
      } else {
        setAlertError({ rc: response.getRC(), rd: response.getRD() })
      }
    } else {
      redirectToDeposit()
    }
  }, [
    dispatch,
    userId,
    userPin,
    isSaldoOk,
    customer,
    discount,
    product,
    history,
    location,
    redirectToDeposit,
    setAlertError,
  ])

  React.useEffect(() => {
    DOMHelper.mountClass('layanan-layout')
    HookHelper.resetLoading()

    if (userId) {
      dispatch(lastTransaction(userId, '', '1', 'pulsa')).then((response) => {
        if (response.isOK()) {
          setLastTransaction(response.getResultAsList())
          setLastTransactionFiltered(response.getResultAsList())
        }
      })
    }

    if (customer.length > 5) {
      getProdukPulsa()
    }

    return () => DOMHelper.unmountClass(['layanan-layout'])
  }, [dispatch, userId, customer, getProdukPulsa, setLastTransaction, setLastTransactionFiltered])

  return (
    <>
      <Helmet>
        <title>Agen Pulsa Termurah Dan Jual Kuota Lengkap Harga Mulai 5.227</title>
        <meta name="title" content="Agen Pulsa Termurah Dan Jual Kuota Lengkap Harga Mulai 5.227" />
        <meta
          name="description"
          content="Agen pulsa termurah solusi tepat untuk konter pulsa. Jual pulsa dan kuota telkomsel, indosat, dan lainnya terlengkap. Aplikasi jual pulsa termurah bisa via web."
        />
        <meta name="google-play-app" content="app-id=com.bm.sc.bebasbayar" />
        <meta
          name="_globalsign-domain-verification"
          content="VncJWgSU_cQw-hAuU0PELvI3g4HQ4l6XFSs9v-jA1Y"
        />
        <meta name="facebook-domain-verification" content="pknnsi25vbw0rfl1x8xctdtumrh3bs" />
        <meta name="msvalidate.01" content="9A9127824837BF1E05F5AED70295F281" />
        <meta name="alexaVerifyID" content="Nc8Q89T9GsncVlA5xukQcbi5rJ0" />
        <meta httpEquiv="X-UA-Compatible" content="IE=Edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="author" content="https://www.facebook.com/SpeedCash.BebasBayarID" />
        <link rel="canonical" href="https://member.speedcash.co.id/isi-pulsa-termurah" />
        <meta
          name="robots"
          content="follow, index, max-snippet:-1, max-video-preview:-1, max-image-preview:large"
        />
        <meta name="geo.country" content="id" />
        <meta name="revisit-after" content="1 days" />
        <meta name="geo.placename" content="Indonesia" />
        <meta content="id_id" property="og:locale" />
        <meta
          itemProp="name"
          content="Agen Pulsa Termurah Dan Jual Kuota Lengkap Harga Mulai 5.227"
        />
        <meta
          itemProp="description"
          content="Agen pulsa termurah solusi tepat untuk konter pulsa. Jual pulsa dan kuota telkomsel, indosat, dan lainnya terlengkap. Aplikasi jual pulsa termurah bisa via web."
        />
        <meta
          itemProp="image"
          content="https://www.speedcash.co.id/images/speedcash-logo-square.jpg"
        />
        <meta property="fb:app_id" content="966242223397117" />
        <meta
          property="og:title"
          content="Agen Pulsa Termurah Dan Jual Kuota Lengkap Harga Mulai 5.227"
        />
        <meta property="og:type" content="article" />
        <meta
          property="og:image"
          content="https://www.speedcash.co.id/images/speedcash-logo-square.jpg"
        />
        <meta
          property="og:description"
          content="Agen pulsa termurah solusi tepat untuk konter pulsa. Jual pulsa dan kuota telkomsel, indosat, dan lainnya terlengkap. Aplikasi jual pulsa termurah bisa via web."
        />
        <meta property="og:site_name" content="SpeedCash" />
        <meta property="og:url" content="https://www.speedcash.co.id/" />
        <meta
          property="article:tag"
          content="Agen pulsa termurah solusi tepat untuk konter pulsa. Jual pulsa dan kuota telkomsel, indosat, dan lainnya terlengkap. Aplikasi jual pulsa termurah bisa via web."
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@SpeedcashO" />
        <meta
          name="twitter:title"
          content="Agen Pulsa Termurah Dan Jual Kuota Lengkap Harga Mulai 5.227"
        />
        <meta
          name="twitter:description"
          content="Agen pulsa termurah solusi tepat untuk konter pulsa. Jual pulsa dan kuota telkomsel, indosat, dan lainnya terlengkap. Aplikasi jual pulsa termurah bisa via web."
        />
        <meta name="twitter:creator" content="@SpeedcashO" />
        <meta
          name="twitter:image"
          content="https://www.speedcash.co.id/images/speedcash-logo-square.jpg"
        />
        <meta
          name="twitter:image:src"
          content="https://www.speedcash.co.id/images/speedcash-logo-square.jpg"
        />
        <meta name="twitter:label1" content="Deskripsi" />
        <meta
          name="twitter:data1"
          content="Agen pulsa termurah solusi tepat untuk konter pulsa. Jual pulsa dan kuota telkomsel, indosat, dan lainnya terlengkap. Aplikasi jual pulsa termurah bisa via web."
        />
        <meta name="twitter:label2" content="Tag" />
        <meta
          name="twitter:data2"
          content="Agen pulsa termurah solusi tepat untuk konter pulsa. Jual pulsa dan kuota telkomsel, indosat, dan lainnya terlengkap. Aplikasi jual pulsa termurah bisa via web."
        />

        <script type="application/ld+json">
          {`{
          "@context":"https://schema.org",
          "@graph":[{
          "@type":"Organization",
          "@id":"https://member.speedcash.co.id/#organization",
          "name":"PT Bimasakti Multi Sinergi",
          "url":"https://member.speedcash.co.id",
          "sameAs":[
          "https://www.facebook.com/SpeedCash.BebasBayarID/",
          "https://www.instagram.com/speedcash.official/",
          "https://www.linkedin.com/in/aplikasi-bebasbayar-864aa7195/",
          "https://www.youtube.com/c/SpeedCashIDOfficial",
          "https://id.pinterest.com/bebasbayarofficial/",
          "https://www.tiktok.com/@speedcash.official",
          "https://twitter.com/SpeedcashO"],
          "logo":{
          "@type":"ImageObject",
          "@id":"https://member.speedcash.co.id/#logo",
          "url":"https://member.speedcash.co.id/img/speedcash.png",
          "width":240,"height":72,"caption":"SpeedCash.co.id"},
          "image":{
          "@id":"https://member.speedcash.co.id/#logo"}
          },{
          "@type":"WebSite",
          "@id":"https://member.speedcash.co.id/#website",
          "url":"https://member.speedcash.co.id/",
          "name":"SpeedCash",
          "publisher":{
          "@id":"https://member.speedcash.co.id/#organization"},
          "potentialAction":{
          "@type":"SearchAction",
          "target":"https://member.speedcash.co.id/?s={search_term_string}",
          "query-input":"required name=search_term_string"}
          },{
          "@type":"ImageObject",
          "@id":"https://member.speedcash.co.id/#primaryimage",
          "url":"https://member.speedcash.co.id/img/speedcash.png",
          "width":764,"height":401,
          "caption":"Agen Pulsa Termurah Dan Jual Kuota Lengkap Harga Mulai 5.227"
          },{
          "@type":"WebPage","@id":"https://member.speedcash.co.id/#webpage",
          "url":"https://member.speedcash.co.id/","inLanguage":"ID",
          "name":"Agen Pulsa Termurah Dan Jual Kuota Lengkap Harga Mulai 5.227",
          "isPartOf":{
          "@id":"https://member.speedcash.co.id/#website"},
          "about":{"@id":"https://member.speedcash.co.id/#organization"},
          "primaryImageOfPage":{"@id":"https://member.speedcash.co.id/#primaryimage"},
          "datePublished":"2024-12-20T03:08:35+00:00","dateModified":"2024-12-20T04:54:34+00:00",
          "description":"Agen pulsa termurah solusi tepat untuk konter pulsa. Jual pulsa dan kuota telkomsel, indosat, dan lainnya terlengkap. Aplikasi jual pulsa termurah bisa via web."
          }]
          }`}
        </script>

        {/* Google tag (gtag.js) */}
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-X7L58XGGSH" />
        <script>
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', 'G-X7L58XGGSH');
          `}
        </script>
      </Helmet>

      <Container fluid className="layanan-layout">
        {!appUserIsEmbed && (
          <Row className="header">
            <Col>
              <HeaderComponent title="Pulsa & Paket Data" onToggle={toggleSidebar} />
            </Col>
          </Row>
        )}

        <Row>
          {!appUserIsEmbed && (
            <Col
              md={2}
              className={classNames('col-sidebar', { 'is-open': isOpen, 'is-mobile': isMobile })}
            >
              <SidebarComponent isOpen={isOpen} />
            </Col>
          )}

          <Col
            ref={inqRef}
            md={{
              span: appUserIsEmbed ? 12 : isOpen ? 10 : 12,
              offset: appUserIsEmbed ? 0 : isOpen ? 2 : 0,
            }}
          >
            <Jumbotron fluid className="banner pulsa">
              <Row>
                <Col>
                  <Row>
                    <span className={classNames('banner-title', { 'is-mobile': isMobile })}>
                      <FontAwesomeIcon icon="mobile-alt" fixedWidth className="mr-3" />
                      Pulsa & Paket Data
                    </span>
                  </Row>
                </Col>

                {!appUserId ? (
                  <Col lg={3} xs={12} md={3} style={{ margin: 'auto' }}>
                    <Button
                      className={classNames('button-login', { 'is-mobile': isMobile })}
                      type="button"
                      onClick={openLoginModal}
                    >
                      Daftar / Login
                    </Button>
                  </Col>
                ) : (
                  <Col lg={3} xs={12} md={3} style={{ margin: 'auto' }}>
                    <Row>
                      <Col lg={10} xs={10} md={10}>
                        <div className="banner-saldo">Saldo Anda</div>
                        <div className="banner-nominal">
                          Rp. {Formatter.price(Math.round(parseFloat(appUserBalance)))}
                        </div>
                      </Col>
                      <Col lg={2} xs={2} md={2}>
                        <span className="banner-title">
                          <FontAwesomeIcon icon="wallet" fixedWidth className="mr-3" />
                        </span>
                      </Col>
                    </Row>
                  </Col>
                )}
              </Row>
            </Jumbotron>

            <Col className="d-flex justify-content-center mb-3 p-3">
              <h2 className="text-dark text-justify">
                Agen Pulsa Termurah Tempat Jual Pulsa Dan Kuota Terlengkap
              </h2>
            </Col>

            <div className="content-layanan">
              <Row className="justify-content-md-center">
                <Col lg={isInqWeb ? 7 : 8} xs={12} md={isInqWeb ? 7 : 8} className="bd-form">
                  <Form className="form-pelanggan">
                    <Form.Group>
                      <Form.Label
                        className="form-label"
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                        }}
                      >
                        <span>Nomor Tujuan</span>
                        <span className="color-primary">{providerName}</span>
                      </Form.Label>
                      <InputGroup className="mb-12">
                        <Form.Control
                          placeholder="Cth: 123456789"
                          name="customer"
                          value={customerLabel}
                          onChange={onCustomerChange}
                          disabled={isInqWeb || detailModalShow}
                        />
                        <InputGroup.Append className="input-group-game">
                          {providerImage && (
                            <Image
                              width={64}
                              height={64}
                              src={providerImage}
                              rounded
                              fluid
                              style={{ marginRight: '5px' }}
                            />
                          )}
                          <Image
                            className={classNames('form-icon-btn', { 'ic-mobile': isMobile })}
                            src="/icon/clear.png"
                            data-target="customer"
                            onClick={handleClear}
                          />
                        </InputGroup.Append>
                      </InputGroup>
                    </Form.Group>
                  </Form>
                  {providerName && (
                    <Tabs
                      variant="tabs"
                      activeKey={tab}
                      onSelect={onTabChange}
                      transition={false}
                      fill
                      className="tab-form txt-16 txt-bold"
                    >
                      <Tab eventKey="0" title="Pulsa Reguler">
                        <PulsaRegulerScreen
                          dataPulsaReguler={dataPulsaReguler}
                          customer={customer}
                          providerName={providerName}
                          providerImage={providerImage}
                          isInqWeb={isInqWeb}
                          selectedProduct={selectedProduct}
                          processTransaction={processTransaction}
                        />
                      </Tab>
                      {dataPaketData.length > 0 && (
                        <Tab eventKey="1" title="Paket Data">
                          <PaketDataScreen
                            dataPaketData={dataPaketData}
                            customer={customer}
                            providerName={providerName}
                            providerImage={providerImage}
                            isInqWeb={isInqWeb}
                            selectedProduct={selectedProduct}
                            processTransaction={processTransaction}
                          />
                        </Tab>
                      )}
                      {dataPaketSms.length > 0 && (
                        <Tab eventKey="2" title="Paket SMS">
                          <PaketSmsScreen
                            dataPaketSms={dataPaketSms}
                            customer={customer}
                            providerName={providerName}
                            providerImage={providerImage}
                            isInqWeb={isInqWeb}
                            selectedProduct={selectedProduct}
                            processTransaction={processTransaction}
                          />
                        </Tab>
                      )}
                    </Tabs>
                  )}
                  {lastTrx.length > 0 && (
                    <div className="form-last-trx">
                      <span className="form-label">Transaksi Terakhir</span>
                      <Row
                        style={{
                          border: '1px solid #DDDDDD',
                          borderRadius: '10px',
                          alignItems: 'center',
                          padding: '10px',
                        }}
                      >
                        <Col lg={2} xs={3} md={2}>
                          <Image src={lastTrx[0].image_url} height={40} />
                        </Col>
                        <Col
                          lg={7}
                          xs={9}
                          md={7}
                          data-target={lastTrx[0].id_pelanggan}
                          onClick={confirmLastTrx}
                          className="pointing"
                        >
                          <Row>
                            <span className="form-label-main">{lastTrx[0].id_pelanggan}</span>
                          </Row>
                          {lastTrx[0].id_pelanggan !== lastTrx[0].nama_pelanggan ? (
                            <Row>
                              <span className="label-text">{lastTrx[0].nama_pelanggan}</span>
                            </Row>
                          ) : (
                            ''
                          )}
                        </Col>
                        <Col lg={3} xs={8} md={3} onClick={openLastTransaction}>
                          <div className="label-text color-primary pointing">
                            Lihat riwayat transaksi
                          </div>
                        </Col>
                      </Row>
                    </div>
                  )}
                </Col>
                {isInqWeb && (
                  <>
                    <Col md="auto" />
                    <Col lg={4} xs={12} md={4} className="bd-form-inq">
                      <Row className="justify-content-md-center">
                        <Image width={76} height={76} src={providerImage} rounded fluid />
                      </Row>
                      <Row>
                        <Col>Nama Provider</Col>
                        <Col className="inq-end-label">{providerName || ''}</Col>
                      </Row>
                      <Row>
                        <Col>Nama Produk</Col>
                        <Col className="inq-end-label">{product ? product.namaproduk : ''}</Col>
                      </Row>
                      <Row>
                        <Col>Nomor Tujuan</Col>
                        <Col className="inq-end-label">{Formatter.serial(customer) || ''}</Col>
                      </Row>
                      <Row>
                        <Col>Harga</Col>
                        <Col className="inq-end-label">
                          {product
                            ? `Rp. ${Formatter.price(
                                Math.round(product.hargajual) + Math.round(product.nominalUp)
                              )}`
                            : ''}
                        </Col>
                      </Row>
                      {product && Math.round(product.nominalUp) > 0 && (
                        <Row>
                          <Col>Diskon</Col>
                          <Col className="inq-end-label color-primary txt-bold">
                            {product
                              ? `-Rp. ${Formatter.price(Math.round(product.nominalUp))}`
                              : ''}
                          </Col>
                        </Row>
                      )}
                      <Row className="inq-body-last">
                        <Col>Total Bayar</Col>
                        <Col className="inq-end-label txt-bold">
                          {product
                            ? `Rp. ${Formatter.price(Math.round(product.hargajual))}`
                            : 'Rp. 0'}
                        </Col>
                      </Row>
                      <Row className="justify-content-md-center inq-body-last">
                        <Col className="inq-balance">
                          <Row style={{ display: 'flex', alignItems: 'center' }}>
                            <Col lg={2} xs={2} md={2}>
                              <Image src="/img/sc.png" roundedCircle />
                            </Col>
                            <Col lg={5} xs={5} md={5}>
                              Saldo Speedcash
                            </Col>
                            <Col lg={5} xs={5} md={5} className="inq-end-label txt-bold">
                              {appUserBalance
                                ? `Rp ${Formatter.price(Math.round(parseFloat(appUserBalance)))}`
                                : 'Rp 0,-'}
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      {!isSaldoOk && (
                        <Row className="justify-content-md-center inq-body-last">
                          <Col className="inq-balance not-ok">
                            <Row className="txt-bold">
                              Saldo Anda belum mencukupi untuk transaksi ini. Segera Top Up
                            </Row>
                          </Col>
                        </Row>
                      )}
                      <Row className="justify-content-md-center">
                        <Col>
                          <Button
                            className="btn-process"
                            type="button"
                            block
                            onClick={isSaldoOk ? confirm : redirectToDeposit}
                          >
                            {appIsLoading && (
                              <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                              />
                            )}
                            {appIsLoading
                              ? ' Mohon Tunggu...'
                              : isSaldoOk
                              ? 'Bayar Sekarang'
                              : 'Top Up Saldo'}
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                  </>
                )}
              </Row>
            </div>

            <AccordionPulsaDataComponent />
          </Col>
        </Row>
        <Alert
          header="Perhatian"
          btnText="OK"
          text={alertError.rd}
          type="error"
          show={!!alertError.rd}
          onClosePress={closeError}
          showBorderBottom
          alertStyles={{ justifyContent: 'center' }}
        />
        <LoginModal
          show={!!loginModalShow}
          onHide={closeLoginModal}
          onConfirm={confirmLoginModal}
        />
        <LastTrxDialog
          show={!!lastTrxShow}
          onHide={closeLastTransaction}
          onConfirm={confirmLastTrx}
          search={search}
          onSearchChange={onSearchChange}
          data={lastTrxFiltered}
        />
        <PulsaModal
          show={!!detailModalShow}
          onHide={closeDetailModal}
          onConfirm={confirm}
          customer={customer}
          providerName={providerName}
          providerImage={providerImage}
          product={product}
          isSaldoOk={isSaldoOk}
        />
      </Container>
    </>
  )
}

PulsaScreen.defaultProps = {
  appIsLoading: false,
  appUserId: '',
  appUserPin: '',
  appUserBalance: 0,
  appUserIsEmbed: false,
}

PulsaScreen.propTypes = {
  appIsLoading: PropTypes.bool,
  appUserId: PropTypes.string,
  appUserPin: PropTypes.string,
  appUserBalance: PropTypes.number,
  appUserIsEmbed: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
}

export default connect(
  (state) => ({
    appIsLoading: state.Global[globalConstant.IS_LOADING],
    appUserId: state.Auth[authConstant.USER_ID],
    appUserBalance: state.Auth[authConstant.USER_BALANCE],
    appUserPin: state.Auth[authConstant.USER_PIN],
    appUserIsEmbed: state.Auth[authConstant.USER_IS_EMBED],
  }),
  (dispatch) => ({ dispatch })
)(PulsaScreen)
