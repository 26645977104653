import React from 'react'
import { Button, Image, Row, Col, Accordion, Card } from 'react-bootstrap'
import './accordion.scss'

const AccordionGameNetflix = () => {
  return (
    <>
      <Col className="d-flex justify-content-center mt-4 mb-3 p-3">
        <h3 className="text-dark text-justify">
          Dapatkan Paket Harga Langganan Netflix Bulanan Termurah
        </h3>
      </Col>

      <div style={{ padding: '0px 20px 30px 20px' }}>
        <Row className="justify-content-md-center">
          <Col lg={8} xs={12} md={8} className="content-component">
            <div>
              <Row className="d-flex justify-content-center">
                <Image
                  src="/img/langganan-netflix-bulanan.png"
                  data-target="banner-netflix"
                  width="100%"
                />
              </Row>
              <Row className="d-flex justify-content-center mt-3">
                <p className="d-block text-justify">
                  Bagi sebagian orang untuk mendapatkan hiburan tidaklah harus pergi keluar rumah,
                  mungkin beberapa buat mereka mencari hiburan paling praktis bisa langsung
                  didapatkan cukup dari rumah saja, seperti menonton tayang film. Saat ini aktivitas
                  menonton film terbaru juga tidak harus datang ke bioskop namun bisa dilakukan
                  melalui platform Netflix. Nah disini admin akan bagikan informasi mengenai beli
                  paket{' '}
                  <a href="https://member.speedcash.co.id/game/langganan-netflix">
                    langganan netflix bulanan
                  </a>{' '}
                  baik itu standar maupun premium dengan harga termurah yang bisa didapatkan.
                </p>
              </Row>
            </div>
          </Col>

          <Col lg={8} xs={12} md={8} className="accordion-component">
            <Accordion className="m-0 p-0">
              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="1"
                    className="button-collapse w-100 text-left"
                  >
                    Cara Beli Paket Netflix Bulanan :
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="1">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Untuk mendapatkan paket langganan neflix bulanan harga termurah, kamu bisa
                        simak cara berikut ini :
                      </p>
                    </Row>
                    <Row className="d-flex justify-content-start">
                      <ol className="text-justify list-content">
                        <li>
                          Kunjungi alamat : https://member.speedcash.co.id/game/langganan-netflix
                        </li>
                        <li>PilihMasuk / Login</li>
                        <li>Input nomor telepon kamu, lalu lanjutkan</li>
                        <li>Ikuti langkah-langkahnya, dan klik lanjutkan</li>
                        <li>Tunggu ada notifikasi yang masuk via WA/SMS</li>
                        <li>
                          Masukkan 4 digit terakhir nomor yang diberikan via WA/SMS, lalu klik
                          lanjutkan.
                        </li>
                        <li>Pertama pastikan isi saldo SpeedCashmu dulu</li>
                        <li>Jika sudah lanjutkan dengan pilih menu VOUCHER GAME</li>
                        <li>
                          Lalu Pilih menu NETFLIX atau pilih paket yang kamu inginkan. (disini ada 2
                          jenis yaitu : paket standar dan paket premium)
                        </li>
                        <li>Pilih nominalnya, kemudian beli.</li>
                        <li>Ikuti instruksi yang diberikan</li>
                      </ol>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="2"
                    className="button-collapse w-100 text-left"
                  >
                    (Beli Paket) Harga Langganan Netflix Bulanan Di Aplikasi :
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="2">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Cara paling praktis untuk berlangganan streaming film dari netflix bisa kamu
                        dapatkan juga melalui aplikasi, berikut cara (beli paket) langganan netflix
                        bulanan harga termurah :
                      </p>
                      <Row className="mt-3 w-100">
                        <ol className="text-justify list-content w-100">
                          <li>
                            Buka aplikasi SpeedCash jika kamu belum punya download dulu ya.
                            (speedcash tersedia di android dan IOS)
                          </li>
                          <li>
                            Lalu masuk/daftar SpeedCash (jika sudah pernah daftar, kamu bisa login
                            langsung menggunakan no hp yang terdaftar di SpeedCash)
                          </li>
                          <li>Jika sudah, kamu bisa isi saldo SpeedCash dulu</li>

                          <li>
                            <p>Lalu pilih menu STREAMING</p>
                            <Row className="d-flex justify-content-center">
                              <Image
                                src="/img/harga-langganan-netflix.png"
                                data-target="harga-langganan-netflix"
                                width="220px"
                                height="400px"
                              />
                            </Row>
                          </li>
                          <li>
                            <p>Pilih NETFLIX</p>
                            <Row className="d-flex justify-content-center">
                              <Image
                                src="/img/langganan-netflix-bulanan-murah.png"
                                data-target="langganan-netflix-bulanan-murah"
                                width="220px"
                                height="400px"
                              />
                            </Row>
                          </li>
                          <li>Masukkan No handphone yang terdaftar di netflix</li>
                          <li>Pilih paket berlangganan (standar atau premium)</li>
                          <li>Jika sudah maka akan tampil total harga yang harus kamu bayar</li>
                          <li>
                            Dan Ikuti instruksi selanjutnya hingga paket netflix sudah aktif di
                            akunmu
                          </li>
                        </ol>
                      </Row>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="4"
                    className="button-collapse w-100 text-left"
                  >
                    Kenapa Harus Beli Paket Langganan Netflix Disini ?
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="4">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Dengan berlangganan paket netflix yang kamu beli disini, kamu tidak perlu
                        repot mencari tempat beli paket langganan netflix yang resmi, dan tidak
                        hanya itu saja alasan untuk beli paket netflix di{' '}
                        <a href="https://www.speedcash.co.id/">SpeedCash</a>, tetapi juga :
                      </p>
                      <ol className="text-justify list-content">
                        <li>
                          SpeedCash merupakan vendor resmi netflix di indonesia yang menwarkan paket
                          langganan netflix
                        </li>
                        <li>Terdapat 2 paket netflix yang bisa kamu beli dengan harga murah</li>
                        <li>
                          Tidak hanya itu, di SpeedCash kamu juga bisa buat : top up game seperti{' '}
                          <a href="https://member.speedcash.co.id/game/top-up-ff">
                            Diamond Free Fire
                          </a>
                          ,{' '}
                          <a href="https://member.speedcash.co.id/game/top-up-ml">
                            Top Up Mobile Legend (ML)
                          </a>{' '}
                          dan bisa juga untuk cek tagihan bulanan lainnya hingga beli pulsa dan
                          transfer antar bank gratis.
                        </li>
                        <li>
                          Bisa di akses tanpa aplikasi, kamu cukup mengunjungi alamat web :
                          https://member.speedcash.co.id/game/langganan-netflix
                        </li>
                        <li>
                          Dan masih banyak keunggulan lainnya kenapa kamu wajib punya SpeedCash.
                        </li>
                      </ol>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="5"
                    className="button-collapse w-100 text-left"
                  >
                    Bagaimana Cara Isi Saldo SpeedCash
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="5">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Sebelum membeli voucher game / top up unipin menggunakan SpeedCash, hal yang
                        harus kamu perhatikan ialah saldo SpeedCashmu. Agar pembelian berjalan
                        dengan lancar maka kamu harus mengisi saldo dulu. Caranya juga sangat mudah
                        kamu bisa ikuti cara isi saldo melalui video berikut ini :
                      </p>
                      <Row className="d-flex w-100 justify-content-center mb-3">
                        <iframe
                          width="100%"
                          height="480"
                          src="https://www.youtube.com/embed/sI1fLYglLwk"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                          frameBorder={0}
                          title="Cara Isi Saldo Di SpeedCash"
                        />
                      </Row>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="6"
                    className="button-collapse w-100 text-left"
                  >
                    Bagaimana Jika Ada Kendala Saat Beli Paket Netflix ?
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="6">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Mungkin beberapa diantara kamu akan panik jika terjadi kendala saat
                        melakukan pembelian paket netflix, seperti sudah membayar namun paket
                        netflix belum aktif ? atau terjadi error saat menggunakan SpeedCash ? hal
                        itu semua tidak perlu kamu takutkan lagi karena di SpeedCash sudah ada tim
                        CS yang siap membantu selama 24 jam dalam 7 hari jika kamu mengalami
                        kendala. Kamu juga bisa menghubungi CS SpeedCash melalui fitur livechat yang
                        ada di aplikasi.
                      </p>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="7"
                    className="button-collapse w-100 text-left"
                  >
                    Sekilas Info Tentang Netflix
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="7">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Netflix adalah layanan streaming yang menyediakan berbagai konten hiburan
                        seperti film, acara TV, dokumenter, dan lebih banyak lagi. Didirikan pada
                        tahun 1997 oleh Reed Hastings dan Marc Randolph di Scotts Valley,
                        California, Netflix awalnya beroperasi sebagai layanan penyewaan DVD melalui
                        pos. Namun, pada tahun 2007, Netflix memulai layanan streaming yang
                        memungkinkan pengguna menonton konten secara langsung melalui internet.
                      </p>
                      <p className="d-block text-justify mt-1">
                        Netflix telah mengubah cara kita menonton film dan acara TV dengan
                        menyediakan akses mudah dan cepat ke berbagai jenis konten hiburan. Dengan
                        investasi dalam konten orisinal, fitur personalisasi, dan kemudahan akses di
                        berbagai perangkat, Netflix terus menjadi pilihan utama bagi banyak orang di
                        seluruh dunia. Sebagai pelopor dalam industri streaming, Netflix terus
                        berinovasi untuk memenuhi kebutuhan hiburan penggunanya.
                      </p>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="10"
                    className="button-collapse w-100 text-left"
                  >
                    Cara Langganan Netflix Di Android :
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="10">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Netflix, platform streaming film dan serial ternama, kini dapat diakses
                        dengan mudah melalui perangkat Android. Bagi kamu yang belum pernah
                        berlangganan Netflix, berikut panduan langkah demi langkah untuk
                        berlangganan di Android:
                      </p>
                      <ol className="text-justify list-content">
                        <li>
                          Kamu bisa unduh terlebih dahulu aplikasi Netflix di Play Store atau
                          kunjungi situs https://netflix.com/signup.
                        </li>
                        <li>
                          Buat atau Sign Up akun Netflix kamu melalui email aktif dan kata sandi.
                        </li>
                        <li>
                          Nantinya, kamu bakal notifikasi email dari Netflix yang berisi tautan link
                          untuk menyelesaikan pendaftaran melalui Netflix.com. Kamu bisa gunakan
                          browser handphone atau desktop, ya.
                        </li>
                        <li>Sesudah itu, pilih paket langganan Netflix yang kamu inginkan.</li>
                        <li>Pilih metode pembayaran yang ada, lalu selesaikan pembayaran.</li>
                      </ol>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="11"
                    className="button-collapse w-100 text-left"
                  >
                    Cara Langganan Netflix Di IOS :
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="11">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Netflix merupakan platform streaming film dan acara TV yang populer di
                        kalangan masyarakat. Bagi pengguna iPhone, iPad, atau iPod Touch,
                        berlangganan Netflix dapat dilakukan dengan mudah melalui beberapa langkah
                        berikut:
                      </p>
                      <ol className="text-justify list-content mt-1">
                        <li>
                          Unduh aplikasi Netflix di App Store atau kunjungi situs netflix.com/signup
                          di Safari atau browser yang ada di ponsel.
                        </li>
                        <li>Pilih paket langganan Netflix yang kamu butuhkan.</li>
                        <li>
                          Sign Up atau buat akun Netflix dengan menggunakan email aktif dan kata
                          sandi.
                        </li>
                        <li>
                          Kamu bisa pilih metode pembayaran yang diinginkan, kemudian selesai
                          pembayaran paket langganan.
                        </li>
                        <li>
                          Terakhir, lakukan Log In dengan email dan kata sandi yang terdaftar agar
                          bisa menonton layanan Netflix sepuasnya melalui perangkat iOS.
                        </li>
                      </ol>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="12"
                    className="button-collapse w-100 text-left"
                  >
                    Cara Langganan Netflix Di PC/Komputer/Laptop
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="12">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Bagi kamu yang ingin mulai berlangganan Netflix di PC, ikuti langkah-langkah
                        mudah berikut:
                      </p>
                      <ol className="text-justify list-content mt-1">
                        <li>
                          Buka browser yang ada di komputer kamu, lalu kunjungi situs
                          netflix.com/signup.
                        </li>
                        <li>Pilih salah satu paket langganan Netflix yang kamu inginkan.</li>
                        <li>
                          Daftarkan atau Sign Up akun Netflix kamu dengan menggunakan email aktif
                          dan kata sandi.
                        </li>
                        <li>
                          Jangan lupa, pilih metode pembayaran yang kamu inginkan dan selesaikan
                          pembayaran.
                        </li>
                      </ol>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="13"
                    className="button-collapse w-100 text-left"
                  >
                    Cara Langganan Netflix Di Smart TV
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="13">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Buat kamu yang ingin mendaftarkan paket langganan Netflix melalui Smart TV
                        berikut cara mudah berlangganan Netflix di Smart TV:
                      </p>
                      <ol className="text-justify list-content">
                        <li>
                          Pastikan, di Smart TV atau perangkat kamu sudah ada aplikasi Netflix.
                        </li>
                        <li>
                          Buka aplikasi Netflix, lalu pilih paket langganan yang kamu inginkan.
                        </li>
                        <li>
                          Lakukan pendaftaran atau klik Sign Up dengan memasukkan email aktif dan
                          kata sandi.
                        </li>
                        <li>
                          Pilih metode pembayaran yang kamu inginkan, lalu selesai pembayaran sesuai
                          dengan nominal yang ada.
                        </li>
                        <li>
                          Lakukan Log In kembali seperti semula, kemudian kamu bisa menikmati
                          berbagai layanan streaming di Netflix
                        </li>
                      </ol>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="14"
                    className="button-collapse w-100 text-left"
                  >
                    Macam-Macam Paket Netflix
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="14">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Netflix menawarkan berbagai paket dengan fitur dan harga yang berbeda untuk
                        memenuhi kebutuhan hiburan Anda. Berikut adalah penjelasan singkat mengenai
                        paket-paket Netflix di Indonesia:
                      </p>
                      <div className="d-block mt-1 w-100">
                        <p className="d-block text-justify">
                          <b>Paket Standar</b>
                        </p>
                        <span className="d-block">Harga: Rp 162.125/bulan</span>
                        <span className="d-block">Fitur:</span>
                        <ul className="text-justify list-content">
                          <li>Menonton di 2 perangkat sekaligus</li>
                          <li>Kualitas video HD</li>
                          <li>Akses ke semua film dan serial TV</li>
                          <li>Akses ke game mobile tak terbatas</li>
                          <li>Bebas iklan</li>
                        </ul>
                      </div>
                      <div className="d-block mt-1 w-100">
                        <p className="d-block text-justify">
                          <b>Paket Premium</b>
                        </p>
                        <span className="d-block">Harga: Rp 213.000/bulan</span>
                        <span className="d-block">Fitur:</span>
                        <ul className="text-justify list-content">
                          <li>Menonton di 4 perangkat sekaligus</li>
                          <li>Kualitas video Ultra HD</li>
                          <li>Akses ke semua film dan serial TV</li>
                          <li>Akses ke game mobile tak terbatas</li>
                          <li>Bebas iklan</li>
                          <li>Akses audio spasial</li>
                        </ul>
                      </div>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="15"
                    className="button-collapse w-100 text-left"
                  >
                    Bisakah Menambah Anggota Di Paket Netflix ?
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="15">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Secara resmi netflix di indonesia belum meuncurkan fitur tambah anggota,
                        namun kamu bisa menggunakan beberapa alternatif agar temanmu bisa
                        menggunakan akun netflixmu, berikut caranya :
                      </p>
                      <div className="d-block mt-1 w-100">
                        <ol className="text-justify list-content">
                          <li>
                            <p className="d-block text-justify">
                              <b>Mengizinkan orang lain menggunakan profil Anda:</b>
                              <ul className="d-block text-justify mt-3">
                                <li>Setiap akun Netflix memiliki hingga 5 profil.</li>
                                <li>
                                  Orang lain dapat membuat profil mereka sendiri dan menonton
                                  Netflix di perangkat mereka sendiri.
                                </li>
                                <li>
                                  Namun, mereka tidak dapat memiliki preferensi tontonan yang
                                  dipersonalisasi, dan Anda dapat melihat apa yang mereka tonton.
                                </li>
                              </ul>
                            </p>
                          </li>
                          <li>
                            <p className="d-block text-justify">
                              <b>
                                Menggunakan fitur &ldquo;Kelola Profil & Kontrol Orang Tua&rdquo;:
                              </b>
                              <ul className="d-block text-justify mt-3">
                                <li>
                                  Fitur ini memungkinkan Anda membatasi akses ke profil tertentu dan
                                  memblokir konten yang tidak pantas.
                                </li>
                                <li>
                                  Ini bisa menjadi pilihan yang baik jika Anda ingin berbagi akun
                                  Netflix dengan anak-anak.
                                </li>
                              </ul>
                            </p>
                          </li>
                        </ol>
                      </div>
                      <p className="d-block text-justify mt-1">
                        Penting untuk diketahui bahwa berbagi akun Netflix dengan orang yang tidak
                        tinggal di rumah Anda melanggar ketentuan layanan Netflix.
                      </p>
                      <p className="d-block text-justify mt-1">
                        Netflix berhak menangguhkan atau menutup akun Anda jika mereka mengetahui
                        Anda melakukannya.
                      </p>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="16"
                    className="button-collapse w-100 text-left"
                  >
                    Cara Ubah Paket Netflix
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="16">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Beberapa keuntungan menggunakan netflix salah satunya pengguna bisa mengubah
                        paket berlangganan kapan saja, netflix memberikan flexibelitas yang membantu
                        pengguna agar semakin betah. Berikut langkahnya untuk mengubah paket
                        berlangganan netflix :
                      </p>
                      <p className="d-block text-justify">
                        <b>Buka Netflix</b>: Buka aplikasi Netflix di perangkat Anda (ponsel,
                        tablet, laptop, atau PC) atau kunjungi situs web Netflix di
                        https://www.netflix.com/.
                      </p>
                      <p className="d-block text-justify">
                        <b>Masuk Akun</b>: Masuk ke akun Netflix Anda menggunakan alamat email dan
                        kata sandi.
                      </p>
                      <p className="d-block text-justify">
                        <b>Akses Menu Akun</b>: Buka menu profil Anda yang terletak di pojok kanan
                        atas layar. Pilih &ldquo;Akun&rdquo; dari daftar yang muncul.
                      </p>
                      <p className="d-block text-justify">
                        <b>Temukan &ldquo;Detail Paket&rdquo;</b>: Pada halaman Akun, temukan bagian
                        &ldquo;Keanggotaan & Paket&rdquo;. Di sana, Anda akan melihat paket Netflix
                        yang saat ini Anda gunakan beserta detailnya.
                      </p>
                      <p className="d-block text-justify">
                        <b>Klik &ldquo;Ubah Paket&rdquo;</b>: Di bawah detail paket, klik tombol
                        &ldquo;Ubah Paket&rdquo;.{' '}
                      </p>
                      <p className="d-block text-justify">
                        <b>Pilih Paket Baru</b>: Netflix menyediakan tiga pilihan paket:
                      </p>
                      <ul className="text-justfity list-content ml-3">
                        <li>
                          <b>Paket Standar</b>: Dua layar, kualitas HD, cocok untuk pengguna yang
                          ingin menonton bersama keluarga.
                        </li>
                        <li>
                          <b>Paket Premium</b>: Empat layar, kualitas UHD dan HDR, cocok untuk
                          pengguna yang menginginkan pengalaman menonton terbaik.
                        </li>
                      </ul>
                      <p className="d-block text-justify">
                        <b>Konfirmasi Perubahan</b>: Setelah memilih paket baru, klik tombol
                        &ldquo;Lanjutkan&rdquo; atau &ldquo;Perbarui&rdquo;. Anda akan diarahkan ke
                        halaman konfirmasi. Pastikan detail paket sudah sesuai, kemudian klik
                        &ldquo;Konfirmasikan Perubahan&rdquo;.
                      </p>

                      <p className="d-block text-justify mt-1">
                        Itulah tadi informasi tentang harga langganan netflix bulanan baik untuk
                        paket standar maupun premium paling murah yang bisa kamu dapatkan langsung
                        disini. Segera tentukan paket langganan netflix favoritmu sesuai dengan
                        kebutuhan.
                      </p>
                      <div className="mt-1 w-100 d-flex justify-content-center">
                        <h5 className="bold">
                          Dapatkan Harga Langganan Netflix Termurah Sekarang :
                        </h5>
                      </div>
                      <Row className="d-flex w-100 justify-content-center mb-3 gap-3">
                        <a
                          aria-hidden
                          href="https://play.google.com/store/apps/details?id=com.bm.sc.bebasbayar&listing=speedcash_banner_app"
                          className="mx-1"
                        >
                          <Image
                            src="/img/playstore-speedcash.png"
                            data-target="playstore-speedcash"
                            width="220px"
                            height="60px"
                          />
                        </a>
                        <a
                          aria-hidden
                          href="https://apps.apple.com/id/app/speedcash-transfer-ewallet/id6459304122"
                          className="mx-1"
                        >
                          <Image
                            src="/img/appstore-speedcash.png"
                            data-target="appstore-speedcash"
                            width="220px"
                            height="60px"
                          />
                        </a>
                      </Row>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default AccordionGameNetflix
