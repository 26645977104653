export default {
  USER_ID: 'AUTH_USER_ID',
  USER_NAME: 'AUTH_USER_NAME',
  USER_PHONE: 'AUTH_USER_PHONE',
  USER_PIN: 'AUTH_USER_PIN',
  USER_TOKEN: 'AUTH_USER_TOKEN',
  USER_IN_ACCESS_TOKEN: 'AUTH_IN_ACCESS_TOKEN',
  USER_ACCESS_TOKEN: 'AUTH_ACCESS_TOKEN',
  USER_BALANCE: 'AUTH_USER_BALANCE',
  USER_CAMPAIGN: 'AUTH_USER_CAMPAIGN',
  USER: 'AUTH_USER',
  USER_IS_EMBED: 'AUTH_USER_IS_EMBED',
  USER_APPID: 'AUTH_USER_APPID',
  USER_IS_MARKETER: 'AUTH_USER_IS_MARKETER',
  CLEAR: 'AUTH_CLEAR',
}
