import React from 'react'
import { Modal, Button } from 'react-bootstrap'
import PropTypes from 'prop-types'
import HookHelper from '../../helper/hookhelper'

const RedirrectModal = ({ onHide, onConfirm, show, showCancel, children }) => {
  React.useEffect(() => {
    HookHelper.resetLoading()
  }, [])

  return (
    <Modal
      show={show}
      onHide={onHide}
      backdrop="static"
      keyboard={false}
      centered
      animation={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>Pengumuman</Modal.Title>
      </Modal.Header>

      <Modal.Body>{children}</Modal.Body>

      <Modal.Footer>
        {showCancel && (
          <Button variant="secondary" onClick={onHide}>
            Batal
          </Button>
        )}
        <Button variant="primary" onClick={onConfirm}>
          OK
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

RedirrectModal.defaultProps = {
  onHide: null,
  onConfirm: null,
  show: false,
  showCancel: false,
  children: null,
}

RedirrectModal.propTypes = {
  onHide: PropTypes.func,
  onConfirm: PropTypes.func,
  show: PropTypes.bool,
  showCancel: PropTypes.bool,
  children: PropTypes.element,
}

export default RedirrectModal
