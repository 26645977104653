import React from 'react'
import { connect } from 'react-redux'
import { Modal, Button, Image, Alert, Form, Row, Col } from 'react-bootstrap'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import { isMobile } from 'react-device-detect'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import globalConstant from '../../../store/constant/global'
import HookHelper from '../../../helper/hookhelper'
import Formatter from '../../../helper/formatter'
import login from '../../../api/action/auth/loginpin'
import changepin from '../../../api/action/auth/changepin'
import registrasi from '../../../api/action/auth/registrasi'
import { checkFm } from '../../../api/action/fm/dashboard'
import { checkUser, requestOtp, checkOtp } from '../../../api/action/auth/otp'
import { getCookie, globalProduk, listProdukTagihan } from '../../../api/action/global'

import '../dialog.scss'

const LoginModal = ({ appIsLoading, onHide, show, dispatch }) => {
  const STEP_LOGIN = 0
  const STEP_PIN = 1
  const STEP_OTP = 2
  const STEP_OTP_CONFIRM = 3
  const STEP_REG = 4
  const STEP_RESET = 5
  const OTP_STATE_LOGIN = 'LOGIN'
  const OTP_STATE_REGISTER = 'REGISTER'
  const OTP_STATE_RESET = 'RESET'
  const OTP_TYPE_WA = 'WA'
  const OTP_TYPE_SMS = 'SMS'
  const PROSS_CODE_LOGIN = 'SIGNON'
  const PROSS_CODE_LOGIN_OTP_V2 = 'SIGNONT'
  const PROSS_CODE_LOGIN_REG = 'SIGNONT'

  const [step, setStep] = React.useState(STEP_LOGIN)
  const [alertError, setAlertError] = React.useState({ rc: '', rd: '' })
  const [isDoLogin, setIsDoLogin] = React.useState(false)
  const [otpState, setOtpState] = React.useState(OTP_STATE_LOGIN)
  const [otpType, setOtpType] = React.useState(OTP_TYPE_SMS)
  const [isWa, setIsWa] = React.useState(false)
  const [isDelayOtp, setIsDelayOtp] = React.useState(false)
  const [phone, setPhone] = React.useState('')
  const [userPhone, setUserPhone] = React.useState('')
  const [userPin, setUserPin] = React.useState('')
  const [rePin, setRePin] = React.useState('')
  const [otp, setOtp] = React.useState('')
  /* Rec State */
  const [name, setName] = React.useState('')
  const [email, setEmail] = React.useState('')
  const [refId, setRefId] = React.useState('')

  const closeError = React.useCallback(async () => {
    setAlertError({ rc: '', rd: '' })
  }, [setAlertError])

  const toStep = React.useCallback(
    (e) => {
      const { target } = e.currentTarget.dataset
      setStep(Number(target))
    },
    [setStep]
  )

  const toOtp = React.useCallback(
    (e) => {
      const { target } = e.currentTarget.dataset
      setAlertError({ rc: '', rd: '' })
      setStep(STEP_OTP)
      setOtpState(target)
    },
    [setAlertError, setStep, setOtpState]
  )

  const onClose = React.useCallback(async () => {
    setStep(STEP_LOGIN)
    setOtpState(OTP_STATE_LOGIN)
    setOtpType(OTP_TYPE_SMS)
    setIsWa(false)
    setIsDelayOtp(false)
    setAlertError({ rc: '', rd: '' })
    setPhone('')
    setUserPhone('')
    setUserPin('')
    setRePin('')
    setOtp('')
    setName('')
    setEmail('')
    onHide()
  }, [onHide])

  /* Login Action */
  const onPhoneChange = (e) => {
    const { value } = e.target
    setPhone(Formatter.serial(value))
    setUserPhone(value.replace(/[^A-Z0-9]/gi, ''))
  }

  const doCheck = React.useCallback(async () => {
    setAlertError({ rc: '', rd: '' })
    if (!phone) {
      setAlertError({ rc: '', rd: 'Masukkan Nomor HP Anda' })
    } else {
      setIsDoLogin(true)
      setOtpType(OTP_TYPE_SMS)
      setIsWa(false)
      const response = await dispatch(checkUser(userPhone.replace(/[^A-Z0-9]/gi, '')))
      const resData = response.getResultAsObject()
      if (resData.isUserWA === 1) {
        setOtpType(OTP_TYPE_WA)
        setIsWa(true)
      }
      if (response.isOK()) {
        // TODO: PIN Page
        setAlertError({ rc: '', rd: '' })
        setStep(STEP_PIN)
        setIsDoLogin(false)
      } else if (response.isUnregistered()) {
        // TODO: Register Page
        const resp = await dispatch(getCookie())
        if (resp.isOK()) {
          const data = resp.getData()
          const referralId = data.data.referral_id !== undefined ? data.data.referral_id : ''
          setRefId(referralId)
        }
        setAlertError({ rc: '', rd: '' })
        setStep(STEP_OTP)
        setOtpState(OTP_STATE_REGISTER)
        setIsDoLogin(false)
        // toOtp(OTP_STATE_REGISTER)
      } else {
        setIsDoLogin(false)
        setAlertError({ rc: response.getRC(), rd: response.getRD() })
      }
    }
  }, [
    dispatch,
    phone,
    userPhone,
    setOtpState,
    setOtpType,
    setIsWa,
    setStep,
    setAlertError,
    setIsDoLogin,
  ])

  /* PIN Action */
  const onPinChange = React.useCallback(
    (e) => {
      const { value } = e.target
      setUserPin(value.replace(/[^0-9]/g, ''))
    },
    [setUserPin]
  )

  const doLogin = React.useCallback(async () => {
    setAlertError({ rc: '', rd: '' })
    if (!userPin) {
      setAlertError({ rc: '', rd: 'Masukkan PIN Anda' })
    } else {
      setIsDoLogin(true)
      let productCode = PROSS_CODE_LOGIN
      let response

      if (otpState === OTP_STATE_LOGIN && step === STEP_PIN) {
        productCode = PROSS_CODE_LOGIN
      } else if (otpState === OTP_STATE_LOGIN && step === STEP_OTP_CONFIRM) {
        productCode = PROSS_CODE_LOGIN_OTP_V2
      } else if (otpState === OTP_STATE_REGISTER && step === STEP_REG) {
        productCode = PROSS_CODE_LOGIN_REG
      } else {
        productCode = PROSS_CODE_LOGIN
      }

      if (otpState === OTP_STATE_LOGIN && step === STEP_OTP_CONFIRM) {
        response = await dispatch(login(userPhone, userPin, otp, productCode, otpType, otpState))
      } else {
        response = await dispatch(login(userPhone, userPin, otp, productCode))
      }

      if (response.isOK()) {
        await dispatch(checkFm(response.getOutletID()))
        // const settingKomisi = await dispatch(komisiProduk(response.getOutletID()))
        // if (settingKomisi.isOK()) {
        const settingGlobal = await dispatch(
          globalProduk(response.getOutletID(), response.getUserPin())
        )
        if (settingGlobal.isOK()) {
          const settingTagihan = await dispatch(listProdukTagihan(response.getOutletID()))
          if (settingTagihan.isOK()) {
            setIsDoLogin(false)
            window.location.reload()
          }
        }
        // }
        setIsDoLogin(false)
      } else {
        setAlertError({ rc: response.getRC(), rd: response.getRD() })
        setIsDoLogin(false)
      }
    }
  }, [dispatch, userPhone, userPin, otp, otpState, otpType, step, setAlertError, setIsDoLogin])

  /* OTP Action */
  const onOtpChange = React.useCallback(
    (e) => {
      const { value } = e.target
      setOtp(value.replace(/[^0-9]/g, ''))
    },
    [setOtp]
  )

  const getOtp = React.useCallback(
    async (e) => {
      let delay
      const { type: valueType } = e.currentTarget.dataset
      setAlertError({ rc: '', rd: '' })
      setOtpType(valueType)
      const response = await dispatch(requestOtp(userPhone, otpState, valueType))
      if (response.isOK()) {
        setIsDelayOtp(true)
        setStep(STEP_OTP_CONFIRM)
        setOtp('')
        delay = setTimeout(() => setIsDelayOtp(false), 15000)
      } else {
        setAlertError({ rc: response.getRC(), rd: response.getRD() })
      }

      return () => clearTimeout(delay)
    },
    [dispatch, userPhone, otpState, setAlertError, setStep, setOtp, setOtpType, setIsDelayOtp]
  )

  const confirmOtp = React.useCallback(async () => {
    setAlertError({ rc: '', rd: '' })
    if (!otp) {
      setAlertError({ rc: '', rd: 'Masukkan OTP' })
    } else {
      const response = await dispatch(checkOtp(userPhone, otp, otpState, otpType))
      if (response.isOK()) {
        if (otpState === OTP_STATE_LOGIN) {
          doLogin()
        } else if (otpState === OTP_STATE_RESET) {
          setStep(STEP_RESET)
        } else {
          setStep(STEP_REG)
        }
      } else {
        setAlertError({ rc: response.getRC(), rd: response.getRD() })
        setOtp('')
      }
    }
  }, [dispatch, otp, otpState, otpType, userPhone, doLogin, setAlertError, setStep, setOtp])

  // const doResetOtp = React.useCallback(async () => {
  //   setAlertError({ rc: '', rd: '' })
  //   setOtp('')
  //   setStep(STEP_OTP)
  // }, [setAlertError, setStep, setOtp])

  /* Reset Action */
  const onRepinChange = React.useCallback(
    (e) => {
      const { value } = e.target
      setRePin(value.replace(/[^0-9]/g, ''))
    },
    [setRePin]
  )

  const changePin = React.useCallback(async () => {
    setAlertError({ rc: '', rd: '' })
    if (!userPin) {
      setAlertError({ rc: '', rd: 'Masukkan PIN' })
    } else if (!rePin) {
      setAlertError({ rc: '', rd: 'Masukkan Ulangi PIN' })
    } else if (userPin !== rePin) {
      setAlertError({ rc: '', rd: 'PIN tidak sama, pastikan PIN sama' })
    } else {
      const response = await dispatch(changepin(userPhone, userPin, otp))
      if (response.isOK()) {
        doLogin()
      } else {
        setAlertError({ rc: response.getRC(), rd: response.getRD() })
      }
    }
  }, [dispatch, otp, userPin, rePin, userPhone, doLogin, setAlertError])

  /* Registrasi Action */
  const onNameChange = React.useCallback(
    (e) => {
      const { value } = e.target
      setName(value)
    },
    [setName]
  )

  const onEmailChange = React.useCallback(
    (e) => {
      const { value } = e.target
      setEmail(value)
    },
    [setEmail]
  )

  const onRefIdChange = React.useCallback(
    (e) => {
      const { value } = e.target
      setRefId(value)
    },
    [setRefId]
  )

  const otpReg = React.useCallback(async () => {
    setAlertError({ rc: '', rd: '' })
    if (!name) {
      setAlertError({ rc: '', rd: 'Masukkan Nama Lengkap Anda' })
    } else if (!userPin) {
      setAlertError({ rc: '', rd: 'Masukkan PIN' })
    } else if (!rePin) {
      setAlertError({ rc: '', rd: 'Masukkan Ulangi PIN' })
    } else if (userPin !== rePin) {
      setAlertError({ rc: '', rd: 'PIN tidak sama, pastikan PIN sama' })
    } else {
      const data = {
        phone: userPhone,
        name,
        pin: userPin,
        refId,
        email,
        otp,
      }
      const response = await dispatch(registrasi(data))
      if (response.isOK()) {
        doLogin()
      } else {
        setAlertError({ rc: response.getRC(), rd: response.getRD() })
      }
    }
  }, [dispatch, otp, name, email, userPin, rePin, refId, userPhone, doLogin, setAlertError])

  const openLivechat = React.useCallback(() => {
    onClose()
    window.$chatwoot.toggle('open')
  }, [onClose])

  /* View */
  const loginView = () => {
    return (
      <>
        <Modal.Header className="header">
          <div style={{ width: '95%' }}>
            <Modal.Title className={classNames('title', { 't-mobile': isMobile })}>
              Masuk ke SpeedCash
            </Modal.Title>
          </div>
          <div className="close-modal">
            <Image
              src="/icon/clear.png"
              onClick={onClose}
              roundedCircle
              className="justify-content-end"
              style={{ width: '36px', height: '36px' }}
            />
          </div>
        </Modal.Header>

        <Modal.Body className="body">
          <Form>
            <Form.Group controlId="formBasicLogin" className="group">
              <Form.Label className="text-center label">
                Untuk <span className="label-bold">Daftar</span> atau{' '}
                <span className="label-bold">Login</span>
              </Form.Label>
              <Form.Label className="text-center label label-bold">Masukkan Nomor HP</Form.Label>
              <Form.Control
                className="text-center"
                placeholder="Cth : 08XXXXXXXXXX"
                aria-label="Nomor HP"
                aria-describedby="phone-addon"
                name="phone"
                value={phone}
                onChange={onPhoneChange}
              />
            </Form.Group>
            <Button
              className="button-modal"
              type="button"
              onClick={doCheck}
              block
              disabled={appIsLoading || isDoLogin}
            >
              {appIsLoading || isDoLogin ? 'Mohon Tunggu...' : 'Lanjutkan'}
            </Button>
            <Row className="info pd-top-20">
              <Col>
                <span className="label pd-right">Perlu Bantuan ?</span>
              </Col>
              <Col>
                <span className="label link" onClick={openLivechat} aria-hidden="true">
                  Hubungi Customer Service
                </span>
              </Col>
            </Row>
            <div className="info pd-top-30">
              {/* <span className="small">Disupport oleh</span> */}
            </div>
            <div className="info">
              <Image src="/img/speedcash.png" />
            </div>
          </Form>
        </Modal.Body>
      </>
    )
  }

  const pinView = () => {
    return (
      <>
        <Modal.Header className="header">
          <div style={{ width: '95%' }}>
            <Modal.Title className={classNames('title', { 't-mobile': isMobile })}>
              Masukkan PIN
            </Modal.Title>
          </div>
          <div className="close-modal">
            <Image
              src="/icon/clear.png"
              data-target={STEP_LOGIN}
              onClick={toStep}
              roundedCircle
              className="justify-content-end"
              style={{ width: '36px', height: '36px' }}
            />
          </div>
        </Modal.Header>

        <Modal.Body className="body">
          <Form>
            <Form.Group className="group">
              <Form.Label className="text-center label">
                Masukkan 6 digit PIN SpeedCash Anda
              </Form.Label>
              <Form.Control
                type="password"
                className="text-center"
                autoComplete="new-password"
                maxLength={6}
                name="userPin"
                value={userPin}
                placeholder="&#9679;&nbsp;&nbsp;&#9679;&nbsp;&nbsp;&#9679;&nbsp;&nbsp;&#9679;&nbsp;&nbsp;&#9679;&nbsp;&nbsp;&#9679;"
                onChange={onPinChange}
              />
            </Form.Group>
            <Button
              className="button-modal"
              type="button"
              onClick={doLogin}
              disabled={appIsLoading || isDoLogin}
              block
            >
              {appIsLoading || isDoLogin ? 'Mohon Tunggu...' : 'Masuk'}
            </Button>
            <div
              className="pd-top-20"
              style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
              <Button
                type="button"
                className="lupa-pin-btn"
                data-target={OTP_STATE_RESET}
                onClick={toOtp}
              >
                Anda{' '}
                <span
                  style={{ fontWeight: 'bold', color: '#777777', paddingLeft: 3, paddingRight: 3 }}
                >
                  {' '}
                  Lupa PIN ?
                </span>{' '}
                Klik disini
              </Button>
            </div>
            <Row className="info pd-top-20">
              <Col>
                <span className="label pd-right">Perlu Bantuan ?</span>
              </Col>
              <Col>
                <span className="label link" onClick={openLivechat} aria-hidden="true">
                  Hubungi Customer Service
                </span>
              </Col>
            </Row>
            <div className="info pd-top-30">
              {/* <span className="small">Disupport oleh</span> */}
            </div>
            <div className="info">
              <Image src="/img/speedcash.png" />
            </div>
          </Form>
        </Modal.Body>
      </>
    )
  }

  const otpView = () => {
    return (
      <>
        <Modal.Header className="header">
          <div style={{ width: '95%' }}>
            <Modal.Title className="title">Verifikasi Nomor Handphone</Modal.Title>
          </div>
          <div className="close-modal">
            <Image
              src="/icon/clear.png"
              data-target={STEP_LOGIN}
              onClick={toStep}
              roundedCircle
              className="justify-content-end"
            />
          </div>
        </Modal.Header>
        <Modal.Body className="body">
          <span className="text-ket">
            Demi alasan keamanan akunmu selama menggunakan layanan kami, kamu harus melakukan
            verifikasi nomor handphone terlebih dahulu.
          </span>
          <div className="list-info mg-top-10">
            <div className="ls">
              <div className="ls-number">
                <div className="no-circle">1</div>
              </div>
              <div className="ls-content">
                Setelah menekan tombol <span className="label-bold">LANJUTKAN</span>, kamu akan
                menerima SMS / WA di nomor <span className="label-bold">{userPhone}</span>
              </div>
            </div>
            <div className="ls">
              <div className="ls-number">
                <div className="no-circle">2</div>
              </div>
              <div className="ls-content">
                Tulis kembali <span className="label-bold">4 DIGIT</span> kode OTP tersebut sebagai{' '}
                <span className="label-bold">Kode Verifikasi</span> saat diminta di halaman
                berikutnya
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer style={{ position: 'sticky', bottom: 0 }}>
          <Button className="button-modal" type="button" data-type={otpType} onClick={getOtp} block>
            {appIsLoading ? 'Mohon Tunggu...' : 'Lanjutkan'}{' '}
            <FontAwesomeIcon
              icon="arrow-right"
              fixedWidth
              style={{ display: appIsLoading ? 'none' : '' }}
            />
          </Button>
        </Modal.Footer>
      </>
    )
  }

  const otpConfirmView = () => {
    return (
      <>
        <Modal.Header className="header">
          <div style={{ width: '95%' }}>
            <Modal.Title className="title">Verifikasi Nomor Handphone</Modal.Title>
          </div>
          <div className="close-modal">
            <Image
              src="/icon/clear.png"
              data-target={STEP_OTP}
              onClick={toStep}
              roundedCircle
              className="justify-content-end"
            />
          </div>
        </Modal.Header>
        <Modal.Body className="body">
          <span className="text-ket">
            Masukkan 4 DIGIT kode OTP pada {otpType} yang barusan kamu terima
          </span>
          <Form className="frm-otp">
            <div className="frm-view sms">
              <Form.Control
                className="plchd-text sms"
                placeholder="****"
                name="otp"
                value={otp}
                onChange={onOtpChange}
                maxLength={4}
                style={{ textAlign: 'center', margin: 'auto' }}
              />
            </div>
          </Form>
          <Button
            className="button-modal"
            type="button"
            onClick={confirmOtp}
            block
            disabled={appIsLoading || isDoLogin}
          >
            {appIsLoading || isDoLogin ? 'Mohon Tunggu...' : 'Konfirmasi'}
          </Button>
          <div className="info pd-top-20">
            <span className="label pd-right">Belum dapat kode OTP? Kirim ulang melalui</span>
          </div>
          <div className="otp-btn-div pd-top-20">
            <Button
              className="btn-otp"
              type="button"
              data-type={OTP_TYPE_SMS}
              onClick={getOtp}
              disabled={appIsLoading || isDoLogin || isDelayOtp}
              style={{ margin: isWa ? '0' : 'auto' }}
            >
              <FontAwesomeIcon icon="sms" fixedWidth className="mr-3" />
              SMS
            </Button>
            {isWa && (
              <Button
                className="btn-otp wa"
                type="button"
                data-type={OTP_TYPE_WA}
                onClick={getOtp}
                disabled={appIsLoading || isDoLogin || isDelayOtp}
              >
                <FontAwesomeIcon icon={['fab', 'whatsapp']} fixedWidth className="mr-3" />
                WA
              </Button>
            )}
          </div>
          <div className="info pd-top-20">
            <span className="label pd-right">Perlu Bantuan ?</span>
            <span className="label link" onClick={openLivechat} aria-hidden="true">
              Hubungi Customer Service
            </span>
          </div>
        </Modal.Body>
      </>
    )
  }

  const regView = () => {
    return (
      <>
        <Modal.Header className="header">
          <div style={{ width: '95%' }}>
            <Modal.Title className="title">Daftar Akun SpeedCash</Modal.Title>
          </div>
          <div className="close-modal">
            <Image
              src="/icon/clear.png"
              data-target={STEP_LOGIN}
              onClick={toStep}
              roundedCircle
              className="justify-content-end"
            />
          </div>
        </Modal.Header>
        <Modal.Body className="body">
          <Form>
            <Form.Group>
              <Form.Label className="text-center label label-bold">Nama Lengkap</Form.Label>
              <Form.Control
                type="input"
                placeholder="Cth : Alexander Ahmad"
                name="name"
                value={name}
                onChange={onNameChange}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label className="text-center label label-bold">
                Email Aktif (Tidak Wajib)
              </Form.Label>
              <Form.Control
                type="input"
                placeholder="Cth : email.saya@email.com"
                name="email"
                value={email}
                onChange={onEmailChange}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label className="text-center label label-bold">PIN</Form.Label>
              <Form.Control
                type="password"
                placeholder="&#9679;&nbsp;&nbsp;&#9679;&nbsp;&nbsp;&#9679;&nbsp;&nbsp;&#9679;&nbsp;&nbsp;&#9679;&nbsp;&nbsp;&#9679;"
                name="userPin"
                value={userPin}
                maxLength={6}
                onChange={onPinChange}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label className="text-center label label-bold">Ulangi PIN</Form.Label>
              <Form.Control
                type="password"
                placeholder="&#9679;&nbsp;&nbsp;&#9679;&nbsp;&nbsp;&#9679;&nbsp;&nbsp;&#9679;&nbsp;&nbsp;&#9679;&nbsp;&nbsp;&#9679;"
                name="rePin"
                value={rePin}
                maxLength={6}
                onChange={onRepinChange}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label className="text-center label label-bold">
                ID Referral (Tidak Wajib)
              </Form.Label>
              <Form.Control
                type="input"
                placeholder="Cth : 123999999"
                name="refId"
                value={refId}
                onChange={onRefIdChange}
              />
              <Form.Text className="text-muted">
                Masukkan ID SpeedCash yang merekomendasikan Anda
              </Form.Text>
            </Form.Group>
            <div
              className="info pd-top-20"
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <span className="medium pd-right">Data Anda 100% aman!</span>
              <span className="medium pd-right">SpeedCash telah terdaftar dan diawasi oleh</span>
              <div
                className="pd-top-10"
                style={{ width: '80%', display: 'flex', flexDirection: 'row' }}
              >
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Image src="/img/bi.png" />
                </div>
              </div>
            </div>
            <div
              className="info pd-top-20"
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <span className="medium pd-right">
                Dengan mendaftar, Anda telah membaca dan menyetujui
              </span>
            </div>
            <div
              className="info pd-top-20"
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {/* <span className="medium link">Syarat & Ketentuan</span> */}
              <Link className="medium link" to="/syarat-dan-ketentuan" target="_blank">
                Syarat & Ketentuan
              </Link>
              <span className="medium">&nbsp;dan&nbsp;</span>
              {/* <span className="medium link">Kebijakan Privasi</span> */}
              <Link className="medium link" to="/kebijakan-privasi" target="_blank">
                Kebijakan Privasi
              </Link>
            </div>
            <div
              className="info pd-top-30"
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {/* <span className="small">Disupport oleh</span> */}
            </div>
            <div
              className="info"
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Image src="/img/speedcash.png" />
            </div>
          </Form>
        </Modal.Body>

        <Modal.Footer
          style={{ position: 'sticky', bottom: 0, zIndex: 1, backgroundColor: '#FFFFFF' }}
        >
          <Button
            className="button-modal"
            type="button"
            onClick={otpReg}
            disabled={appIsLoading || isDoLogin}
            block
          >
            {appIsLoading || isDoLogin ? 'Mohon Tunggu...' : 'Daftar Sekarang'}
          </Button>
        </Modal.Footer>
      </>
    )
  }

  const resetView = () => {
    return (
      <>
        <Modal.Header className="header">
          <div style={{ width: '95%' }}>
            <Modal.Title className="title">Buat Pin Baru Anda</Modal.Title>
          </div>
          <div className="close-modal">
            <Image
              src="/icon/clear.png"
              data-target={STEP_PIN}
              onClick={toStep}
              roundedCircle
              className="justify-content-end"
            />
          </div>
        </Modal.Header>

        <Modal.Body className="body">
          <Form>
            <Form.Group>
              <Form.Label className="text-center label label-bold">Input PIN</Form.Label>
              <Form.Control
                type="password"
                placeholder="&#9679;&nbsp;&nbsp;&#9679;&nbsp;&nbsp;&#9679;&nbsp;&nbsp;&#9679;&nbsp;&nbsp;&#9679;&nbsp;&nbsp;&#9679;"
                name="userPin"
                value={userPin}
                onChange={onPinChange}
                maxLength={6}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label className="text-center label label-bold">Ulangi PIN</Form.Label>
              <Form.Control
                type="password"
                placeholder="&#9679;&nbsp;&nbsp;&#9679;&nbsp;&nbsp;&#9679;&nbsp;&nbsp;&#9679;&nbsp;&nbsp;&#9679;&nbsp;&nbsp;&#9679;"
                name="rePin"
                value={rePin}
                onChange={onRepinChange}
                maxLength={6}
              />
            </Form.Group>
          </Form>
        </Modal.Body>

        <Modal.Footer style={{ position: 'sticky', bottom: 0, zIndex: 1, borderTop: 'none' }}>
          <Button
            className="button-modal"
            type="button"
            onClick={changePin}
            block
            disabled={appIsLoading || isDoLogin}
          >
            {appIsLoading || isDoLogin ? 'Mohon Tunggu...' : 'Lanjutkan'}
          </Button>
        </Modal.Footer>
      </>
    )
  }

  const mainView = () => {
    if (step === STEP_PIN) {
      return pinView()
    }
    if (step === STEP_OTP) {
      return otpView()
    }
    if (step === STEP_OTP_CONFIRM) {
      return otpConfirmView()
    }
    if (step === STEP_REG) {
      return regView()
    }
    if (step === STEP_RESET) {
      return resetView()
    }

    return loginView()
  }

  React.useEffect(() => {
    HookHelper.resetLoading()
  }, [])

  return (
    <Modal
      show={show}
      onHide={onClose}
      backdrop="static"
      keyboard={false}
      centered
      contentClassName={step === STEP_REG ? 'radius reg-modal' : 'radius'}
      animation={false}
      scrollable={step === STEP_REG}
    >
      {mainView()}
      <Alert
        variant="danger"
        show={!!alertError.rd}
        onClose={closeError}
        transition={null}
        dismissible
      >
        {alertError.rd}
        {alertError.rc.toString() === '130' && (
          <>
            <hr />
            <div className="d-flex justify-content-end">
              <Button
                data-target={OTP_STATE_LOGIN}
                onClick={toOtp}
                variant="outer-danger"
                style={{
                  color: '#FFFFFF',
                  background: 'none',
                  boxShadow: 'none',
                  border: '#FFF solid',
                  borderWidth: 1,
                }}
              >
                Ok
              </Button>
            </div>
          </>
        )}
      </Alert>
    </Modal>
  )
}

LoginModal.defaultProps = {
  appIsLoading: false,
  onHide: null,
  // onConfirm: null,
  show: false,
}

LoginModal.propTypes = {
  appIsLoading: PropTypes.bool,
  onHide: PropTypes.func,
  // onConfirm: PropTypes.func,
  show: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
}

export default connect(
  (state) => ({
    appIsLoading: state.Global[globalConstant.IS_LOADING],
  }),
  (dispatch) => ({ dispatch })
)(LoginModal)
