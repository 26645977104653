import React from 'react'
import { Button, Image, Row, Col, Accordion, Card } from 'react-bootstrap'
import './accordionPln.scss'

const AccordionPln = () => {
  return (
    <>
      <Col className="d-flex justify-content-center mt-4 mb-3 p-3">
        <h3 className="text-dark text-justify">
          Kenapa Harus Beli Token Listrik PLN Prabayar Disini
        </h3>
      </Col>

      <div style={{ padding: '0px 20px 30px 20px' }}>
        <Row className="justify-content-md-center">
          <Col lg={8} xs={12} md={8} className="content-pln">
            <div>
              <Row className="d-flex justify-content-center">
                <p className="d-block text-justify">
                  Sering panik ketika tengah malam tiba-tiba listrik rumah mati eh ternyata token
                  listrik prabayar habis? sekarang ga perlu panik karena kamu bisa beli token
                  listrik PLN prabayar lebih cepat dan hemat di SpeedCash.Beli token listrik 24 jam
                  pun tidak masalah,ga perlu repot harus keluar rumah, ga perlu bingung harus bayar
                  pakai uang cash,karena semua kemudahan untuk kebutuhanmu ada di SpeedCash.
                </p>
              </Row>
              <Row className="d-flex my-2 justify-content-center text-dark">
                <h4>Cara Beli / Isi Voucher Token Listrik Pakai SpeedCash</h4>
              </Row>
              <Row>
                <ol className="text-justify list-content">
                  <li>
                    Masukkan kode ID Pelanggan PLN kamu di kolom (prabayar) yang sudah tersedia
                  </li>
                  <li>
                    Pilih nominal token listrikPLN yang ingin kamu beli (nominal token PLN paling
                    kecil mulai 20.000 dan maksimal hingga 10 juta)
                  </li>
                  <li>
                    Jika sudah ikuti saja instruksi selanjutnya (kami sarankan untuk login dulu agar
                    lebih cepat dan praktis)
                  </li>
                </ol>
              </Row>
            </div>
            <div className="mt-3">
              <Row className="d-flex justify-content-center text-dark">
                <h4>
                  Beli Token Listrik PLN Prabayar Bisa Juga Melalui Aplikasi SpeedCash Cek Videonya
                  Disini
                </h4>
              </Row>
              <Row className="d-flex justify-content-center mt-2">
                <p className="d-block text-justify">
                  Membeli token listrik PLN prabayar kini bisa dilakukan lewat online melalui HP
                  baik android maupun IOS, dan pastinya lebih praktis,cepat.Kapanpun dimanapun kamu
                  bisa mengisi token listrikmu sendiri,yuk! simak video berikut ini.
                </p>
              </Row>
              <Row className="d-flex justify-content-center">
                <iframe
                  width="100%"
                  height="480"
                  src="https://www.youtube.com/embed/KMGeMvCH8rA"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  frameBorder={0}
                  title="Cara cek tagihan listrik Via SpeedCash"
                />
              </Row>
            </div>
            <div className="mt-5">
              <Row className="d-flex justify-content-center text-dark">
                <h4>Kenapa Harus Beli Token Listrik PLN Prabayar Disini</h4>
              </Row>
              <Row>
                <p className="d-block text-justify">
                  Kenapa isi voucher token listrik pln prabayar di SpeedCash banyak menjadi pilihan
                  masyarakat ? beberapa alasan utama kenapa beli token listrik PLN prabayar melalui
                  SpeedCash :
                </p>
                <Row className="mt-2">
                  <ul className="text-justify list-content">
                    <li>Pasti dapat diskon ketika beli token listrik di SpeedCash </li>
                    <li>
                      Praktis karena bisa beli token listrik selama 24 jam. Kapanpun dan dimanapun
                    </li>
                    <li>Download aplikasinya gratis</li>
                    <li>Tidak harus punya rekening bank</li>
                    <li>
                      Pilihan nominal voucher token listrik terlengkap mulai dari 20.000 hingga 10
                      juta
                    </li>
                    <li>Banyak promo menarik setiap bulannya</li>
                    <li>Fitur dan layanan keuangan terlengkap di indonesia</li>
                  </ul>
                </Row>
              </Row>
            </div>
          </Col>

          <Col lg={8} xs={12} md={8} className="accordion-pln">
            <Accordion className="m-0 p-0">
              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="1"
                    className="button-collapse w-100 text-left"
                  >
                    Langkah-Langkah Beli Token Listrik Via Aplikasi SpeedCash
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="1">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Untuk membeli/mengisi token listrik via aplikasi SpeedCash juga sangat
                        mudah,cepat dan hemat.Kamu bisa mengikuti langkah-langkah berikut ini :
                      </p>
                      <Row className="mt-3">
                        <ul className="text-justify list-content">
                          <li>
                            Pertama download aplikasi SpeedCash yang sudah tersedia untuk android
                            maupun IOS{' '}
                          </li>
                          <li>Jika sudah lalu daftar/login menggunakan nomor HP aktif</li>
                          <li>
                            <p>
                              Lalu akan muncul tampilan halaman utama aplikasi SpeedCash seperti
                              berikut ini
                            </p>
                            <Row className="d-flex justify-content-center">
                              <Image
                                src="/img/btaset1.png"
                                data-target="plnpra-aset1"
                                width="220px"
                                height="400px"
                              />
                            </Row>
                          </li>
                          <li>
                            Jika sudah muncul seperti halaman beranda diatas, maka dilanjutkan untuk
                            klik menu <strong>PLN Token-Tagihan</strong>
                          </li>
                          <li>
                            Lalu pilih menu <strong>token-PLN</strong>
                          </li>
                          <li>
                            <p>Dan isikan ID Pelanggan PLN mu</p>
                            <Row className="d-flex justify-content-center">
                              <Image
                                src="/img/btaset2.png"
                                data-target="plnpra-aset2"
                                width="220px"
                                height="400px"
                              />
                            </Row>
                          </li>
                          <li>
                            <p>Pilih nominal token PLN mu</p>
                            <Row className="d-flex justify-content-center">
                              <Image
                                src="/img/btaset3.png"
                                data-target="plnpra-aset3"
                                width="220px"
                                height="400px"
                              />
                            </Row>
                          </li>
                        </ul>
                      </Row>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="2"
                    className="button-collapse w-100 text-left"
                  >
                    Tidak Hanya Untuk Beli Token Listrik PLN Prabayar Saja, Dengan SpeedCash Kamu
                    Juga Bisa
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="2">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Ternyata di SpeedCash kamu bisa juga mendapatkan layanan selain beli
                        token/voucher listrik PLN prabayar loh, tetapi juga bisa untuk :
                      </p>
                      <Row className="mt-3">
                        <ul className="text-justify list-content">
                          <li>
                            <a href="https://www.speedcash.co.id/transfer-bank-gratis">
                              Transfer antar bank gratis
                            </a>{' '}
                            sepuasnya
                          </li>
                          <li>
                            Beli{' '}
                            <a href="https://www.speedcash.co.id/pesan-tiket-kereta-api-online">
                              tiket kereta
                            </a>{' '}
                            api gratis biaya admin dan layanan
                          </li>
                          <li>
                            Beli{' '}
                            <a href="https://www.speedcash.co.id/pesan-tiket-pesawat-promo-online">
                              tiket pesawat
                            </a>{' '}
                            dengan garansi harga paling murah
                          </li>
                          <li>Bayar kode billing KUA</li>
                          <li>Bayar denda tilang online</li>
                          <li>Bayar pajak kendaraan online</li>
                          <li>
                            <a href="https://member.speedcash.co.id/pdam">Cek tagihan PDAM</a>
                          </li>
                          <li>
                            <a href="https://member.speedcash.co.id/bpjs">
                              Cek tagihan BPJS kesehatan
                            </a>
                          </li>
                          <li>Dan masih banyak layanan lainnya juga loh!</li>
                        </ul>
                      </Row>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="3"
                    className="button-collapse w-100 text-left"
                  >
                    Syarat Dan Ketentuan Pembelian Token Listrik Di SpeedCash
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="3">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Sebelum melakukan pembelian{' '}
                        <a href="https://member.speedcash.co.id/pln/token-listrik">token listrik</a>{' '}
                        pascabayar melalui SpeedCash, sebaiknya pelajari terlebih dahulu syarat dan
                        ketentuan berikut ini:
                      </p>
                    </Row>
                    <Row className="d-flex justify-content-start">
                      <ol className="text-justify list-content">
                        <li>
                          Pastikan Kamu sudah memiliki akun SpeedCash dan telah memverifikasi nomor
                          ponselmu.
                        </li>
                        <li>
                          Terjadi kesalahan saat memasukkan nomor pelanggan atau nomor meteran,
                          tidak. Proses refund akan dilakukan.
                        </li>
                        <li>
                          Pengguna dikenakan biaya administrasi untuk setiap pembelian token
                          listrik.
                        </li>
                        <li>
                          Dalam 1 kali transaksi, pembeli hanya dapat membeli token listrik satu
                          kali dan kemudian menyelesaikan proses pembayaran.
                        </li>
                        <li>
                          Jika ingin menukarkan token listrik, pembeli harus menyelesaikan transaksi
                          hingga pembayaran berhasil.
                        </li>
                        <li>
                          SpeedCash tidak bertanggung jawab atas kesalahan apa pun pada proses
                          pembelian Token listrik yang dimasukkan oleh pelanggan karena kesalahan.
                          nomor pengguna atau nomor meteran.
                        </li>
                        <li>
                          Administrator SpeedCash tidak pernah meminta email dan kata sandi akun
                          pengguna. Mohon untuk tidak membagikan data ini kepada siapapun.
                        </li>
                        <li>Harga dapat berubah sewaktu-waktu tanpa pemberitahuan.</li>
                        <li>
                          Sesuai ketentuan PLN, setiap hari mulai pukul 23.00 hingga 01.00 WIB token
                          PLN tidak dapat dibeli.
                        </li>
                      </ol>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="4"
                    className="button-collapse w-100 text-left"
                  >
                    Berapa Nominal Token Listrik PLN Prabayar Yang Tersedia Di SpeedCash
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="4">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Beli token listrik PLN prabayar dengan harga terjangkau bukan mimpi lagi,
                        dengan SpeedCash kamu bisa mendapatkan pilihan Nominal token listrik PLN
                        beragam dan paling lengkap tentunya dengan harga token listrik PLN prabayar
                        murah yang kami sediakan.
                      </p>
                      <p className="d-block text-justify mt-1">
                        Pilihan Nominal token listrik yang bisa kamu dapatkan di{' '}
                        <a href="https://www.speedcash.co.id/">SpeedCash</a> mulai dari nominal
                        20.000,50.000,100.000,200.000,500.000,1Juta,5Juta dan 10Juta
                      </p>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="5"
                    className="button-collapse w-100 text-left"
                  >
                    Lalu apa itu listrik prabayar
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="5">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Listrik prabayar adalah sistem pembayaran listrik yang memungkinkan pengguna
                        untuk membeli dan menggunakan listrik sesuai kebutuhan mereka. Dalam sistem
                        ini, pengguna membeli token listrik atau kredit listrik yang dapat diisi
                        ulang, seringkali melalui mesin ATM, gerai penjualan khusus, atau aplikasi
                        ponsel. Setelah pembelian, pengguna memasukkan token atau kode yang
                        diberikan ke dalam meteran listrik prabayar mereka untuk mendapatkan pasokan
                        listrik sesuai dengan nilai kredit yang dibeli.
                      </p>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="6"
                    className="button-collapse w-100 text-left"
                  >
                    Dan Apa Itu Token Listrik
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="6">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Token listrik adalah suatu kode atau informasi khusus yang berisi nilai
                        kredit listrik. Kode ini diberikan kepada pengguna listrik prabayar setelah
                        mereka melakukan pembelian kredit listrik. Token tersebut dapat berupa angka
                        atau kombinasi karakter tertentu yang dihasilkan sebagai bukti pembayaran.
                      </p>
                      <p className="d-block text-justify mt-1">
                        Token listrik berfungsi sebagai alat pembayaran dan pengakuan pembelian
                        dalam sistem listrik prabayar, memungkinkan pengguna untuk mengontrol
                        pemakaian listrik mereka sesuai dengan jumlah kredit yang dimiliki.
                      </p>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="7"
                    className="button-collapse w-100 text-left"
                  >
                    Keunggulan Pakai Listrik Prabayar
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="7">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Listrik prabayar atau listrik pintar dengan menggunakan token PLN sebagai
                        alat pembayarannya memiliki banyak keunggulan yang berbeda-beda, terutama
                        dalam menghemat penggunaan listrik sehingga masyarakat dapat mandiri
                        mengendalikan biaya listrik
                      </p>
                      <p className="d-block text-justify mt-1">
                        Selain itu, keunggulan listrik prabayar adalah biaya pemasangan yang semakin
                        lama semakin mahal. lebih rendah dan tidak ada deposit. Pelanggan hanya
                        perlu mengeluarkan sejumlah uang untuk membeli token listrik awal tanpa
                        harus melakukan deposit.
                      </p>
                      <p className="d-block text-justify mt-1">
                        Dengan menggunakan listrik prabayar, pelanggan juga tidak perlu membayar
                        sesuai pemakaian. Jika pelanggan tidak menggunakan listrik selama sebulan,
                        maka mereka tidak perlu membayar apa pun. Berbeda dengan penggunaan listrik
                        pascabayar, biasanya meskipun tidak digunakan tetap harus membayar biaya
                        pengurusan registrasi.
                      </p>
                      <p className="d-block text-justify mt-1">
                        Penggunaan listrik prabayar juga menjamin keamanan pelanggan ketika agen PLN
                        tidak lagi datang ke rumah. Pemakaian listrik Anda dibatasi berdasarkan
                        token listrik yang Anda isi meterannya. Setiap bulannya, Anda tidak perlu
                        antri di loket pembayaran tagihan.
                      </p>
                      <p className="d-block text-justify mt-1">
                        Kelemahan listrik pascabayar adalah kesalahan registrasi rekening yang
                        menyebabkan biaya listrik meningkat. Listrik pintar menggunakan token
                        listrik menggunakan teknologi digital yang memastikan batasan konsumsi
                        listrik Anda secara tepat.
                      </p>
                      <p className="d-block text-justify mt-1">
                        Keuntungan utama listrik prabayar adalah mengedukasi masyarakat tentang
                        rezim hidup untuk menghemat listrik. Untuk menggunakan energi listrik,
                        masyarakat harus mengisi token PLN terlebih dahulu. Token Listrik akan habis
                        jika tidak diisi ulang, dan ketika Token Listrik habis maka energi listrik
                        tidak akan mengalir. Masyarakat akan lebih memperhatikan penggunaan listrik
                        karena jumlah token PLN yang dimiliki terbatas.
                      </p>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="8"
                    className="button-collapse w-100 text-left"
                  >
                    Cara Pasang Listrik PLN Prabayar
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="8">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Untuk memanfaatkan instalasi listrik pintar atau prabayar, Anda harus
                        mengajukan permohonan ke PLN, penyedia listrik. Pemasangan listrik prabayar
                        sangat sederhana dan dapat dilakukan secara online. Berikut
                        langkah-langkahnya:
                      </p>
                      <p className="d-block text-justify mt-1">
                        Buka situs resmi PLN Online dan pilih opsi “Pengaturan Koneksi”. Setelah
                        membaca dan menerima syarat dan ketentuan yang ditetapkan oleh PT PLN, Anda
                        akan diarahkan ke halaman berikutnya. memuat kolom data tentang data
                        pelanggan dan kandidat. Isikan data secara lengkap untuk mempermudah dan
                        mempercepat proses seleksi lamaran.
                      </p>
                      <p className="d-block text-justify mt-1">
                        Setelah mengisi data pelanggan dan data calon, Anda akan melihat tabel
                        bertajuk Jadwal Listrik/Harga Baru, yang di dalamnya Anda dapat memilih
                        jenisnya layanan PLN yang Anda inginkan. (Pascabayar atau prabayar) dan
                        kapasitas daya yang dibutuhkan. Klik kalkulator biaya untuk mengetahui
                        berapa biaya pemasangan PLN baru yang harus Anda bayar.
                      </p>
                      <p className="d-block text-justify mt-1">
                        Setelah Anda memilih layanan PLN yang Anda inginkan, centang kotak kecil
                        untuk menunjukkan bahwa Anda menerima Dapatkan ketentuan dan isi kolom data
                        dengan benar. Kemudian klik opsi “Minta Pendaftaran”.
                      </p>
                      <p className="d-block text-justify mt-1">
                        Setelah disetujui, Anda akan menerima email yang merinci biaya yang harus
                        Anda bayarkan beserta nomor pendaftaran Anda. Lakukan pembayaran paling
                        lambat 30 hari setelah menerima email.
                      </p>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="9"
                    className="button-collapse w-100 text-left"
                  >
                    Cara Memasukkan Voucher Token Listrik Ke Meteran PLN
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="9">
                  <Card.Body className="card-content">
                    <Row className="d-flex justify-content-start">
                      <ol className="text-justify list-content">
                        <li>Pastikan Kamu telah membeli voucher token listrik PLN Di SpeedCash.</li>
                        <li>
                          Lalu masukkan 20 angka token listrik yang kamu dapatkan di alat meteran
                          PLN Prabayar. Jika kamu merasa meteran PLN Prabayar terletak terlalu
                          tinggi, bisa gunakan kursi atau tangga sebagai sanggahan.
                        </li>
                        <li>
                          Setelah 20 digit nomor voucher token listrik dimasukan dengan tepat, tekan
                          tombol enter pada meteran.
                        </li>
                        <li>
                          Perhatikan adanya notifikasi “ACCEPTED” akan muncul pada layar jika nomor
                          token listrik yang Anda masukan sudah benar dan jumlah kWh listrikmu akan
                          bertambah. Jika tampil notifikasi “REJECTED” muncul, tandanya pengisian
                          ulang token listrik PLN gagal. Periksa kembali 20 digit voucher token
                          listrik dan masukan kembali pada alat meteran.
                        </li>
                      </ol>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="10"
                    className="button-collapse w-100 text-left"
                  >
                    Cara Pindah Langganan Listrik PLN Pascabayar Ke PLN Prabayar
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="10">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Tagihan listrik prabayar menggunakan token listrik sehingga pembayarannya
                        jauh lebih sederhana dan efektif dibandingkan tagihan listrik pascabayar.
                        Jika Kamu ingin beralih dari listrik pascabayar ke prabayar, ikuti
                        langkah-langkah di bawah ini.
                      </p>
                    </Row>
                    <Row className="d-flex justify-content-start">
                      <ol className="text-justify list-content">
                        <li>
                          Kembalikan seluruh data tagihan listrik pascabayarmu. Sebelum pindah, Kamu
                          harus memastikan tidak ada utang terutang atau tagihan listrik yang belum
                          dibayar.
                        </li>
                        <li>KTP asli dan fotokopi KTP pemilik atau penanggung jawab.</li>
                        <li>
                          Daftar online dengan mengunjungi petugas Website PLN atau melalui call
                          center PLN 123.
                        </li>
                        <li>
                          Lengkapi data yang diminta dan tunggu hingga proses verifikasi PLN
                          selesai.
                        </li>
                        <li>
                          Kamu harus membayar biaya tambahan pulsa awal melalui transfer bank.
                          Kredit listrik akan diberikan pada saat pemasangan meteran listrik
                          prabayar.
                        </li>
                      </ol>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="11"
                    className="button-collapse w-100 text-left"
                  >
                    Hal-Hal Yang Sering Ditanyakan Mengenai Listrik PLN Prabayar
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="11">
                  <Card.Body className="card-content">
                    <Row>
                      <div>
                        <h4>Apakah Listrik Prabayar Lebih Boros Dari Listrik Pascabayar ?</h4>
                        <p className="d-block text-justify">
                          TIDAK. Menurut informasi dari PLN, listrik prabayar dan listrik pascabayar
                          memiliki harga per kWh yang sama. Kamu dapat mengontrol biaya dengan
                          listrik prabayar dan memantau konsumsi listrikmu agar penggunaan listrik
                          lebih hemat.
                        </p>
                      </div>
                      <div className="mt-3">
                        <h4>Apakah Voucher Token Listrik PLN Ada Masa Kadaluarsa ?</h4>
                        <p className="d-block text-justify">
                          Token listrik PLN tidak memiliki tanggal kadaluwarsa dan berlaku
                          selamanya. Jika Kamu belum memasukkan nomor token, jika lupa atau hilang
                          maka dianggap hilang.
                        </p>
                      </div>
                      <div className="mt-3">
                        <h4>Apa Yang Terjadi Jika saldo token listrik benar-benar habis ?</h4>
                        <p className="d-block text-justify">
                          Yang akan terjadi jika saldo token listrik pln prabayarmu habis,maka daya
                          listrik akan benar-benar terputus secara otomatis
                        </p>
                      </div>
                      <div className="mt-3">
                        <h4>
                          Meteran Listrik Muncul Notifikasi “PERIKSA”, Apa Yang Harus Dilakukan ?
                        </h4>
                        <p className="d-block text-justify">
                          Jika muncul pesan “Periksa” akan muncul jika sistem kelistrikan yang
                          berhubungan dengan meteran rumah Anda mengalami masalah. Silakan segera
                          menghubungi layanan pelanggan PLN 123 dan tanyakan kepada dealer resmi
                          untuk memeriksa apakah ada masalah pada sistem kelistrikan meteran Anda.
                        </p>
                      </div>
                      <p className="d-block text-justify mt-1">
                        Itulah tadi informasi beli token listrik di SpeedCash dengan pilihan nominal
                        yang beragam dan tentunya dengan kemudahan yang bisa kamu dapatkan hanya
                        dalam 1 aplikasi.Dimana lagi bisa beli token listrik dengan pilihan nominal
                        yang banyak dan lengkap kalau ga di SpeedCash.
                      </p>
                      <div className="mt-1 w-100 d-flex justify-content-center">
                        <h5 className="bold">
                          Beli Token Listrik Lebih Cepat Dan Praktis Via Aplikasi
                        </h5>
                      </div>
                      <Row className="d-flex w-100 justify-content-center mb-3 gap-3">
                        <a
                          aria-hidden
                          href="https://play.google.com/store/apps/details?id=com.bm.sc.bebasbayar&listing=token-listrik"
                          className="mx-1"
                        >
                          <Image
                            src="/img/playstore-speedcash.png"
                            data-target="playstore-speedcash"
                            width="220px"
                            height="60px"
                          />
                        </a>
                        <a
                          aria-hidden
                          href="https://apps.apple.com/id/app/speedcash-transfer-ewallet/id6459304122"
                          className="mx-1"
                        >
                          <Image
                            src="/img/appstore-speedcash.png"
                            data-target="appstore-speedcash"
                            width="220px"
                            height="60px"
                          />
                        </a>
                      </Row>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default AccordionPln
