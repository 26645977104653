/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-nested-ternary */
import _ from 'lodash'
import React from 'react'
import { connect } from 'react-redux'
import {
  Spinner,
  Form,
  InputGroup,
  Container,
  Jumbotron,
  Button,
  Image,
  Row,
  Col,
} from 'react-bootstrap'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { isMobile } from 'react-device-detect'
import { useHistory, useLocation } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Alert from 'react-popup-alert'
import 'react-popup-alert/dist/index.css'
import DOMHelper from '../../../helper/domhelper'
import Formatter from '../../../helper/formatter'
import HookHelper from '../../../helper/hookhelper'
import utilConstant from '../../../helper/utilconstants'

import './layanan.scss'
import globalConstant from '../../../store/constant/global'
import authConstant from '../../../store/constant/auth'
import SidebarComponent from '../../component/sidebar'
import HeaderComponent from '../../component/header'
import LoginModal from '../../dialog/auth/login'
import GasModal from '../../dialog/layanan/gas'
import ProductListNewDialog from '../../dialog/layanan/productListNew'
import LastTrxDialog from '../../dialog/lastTransaction'

import { tagihanInq, tagihanPay } from '../../../api/action/layanan/tagihan'
import { lastTransaction, listProdukUbp } from '../../../api/action/global'

const GasScreen = ({
  appUserId,
  appUserPin,
  appUserBalance,
  appUserIsEmbed,
  appIsLoading,
  dispatch,
}) => {
  const GROUP_PRODUK = 'GAS'
  const history = useHistory()
  const location = useLocation()
  const [isOpen, setIsOpen] = React.useState(!isMobile)
  const [isInqWeb, setIsInqWeb] = React.useState(false)
  const [alertError, setAlertError] = React.useState('')
  const [loadingGetProduk, setLoadingGetProduk] = React.useState(false)
  const [loginModalShow, setLoginModalShow] = React.useState(false)
  const [tagihanModalShow, setTagihanModalShow] = React.useState(false)
  const [userId] = React.useState(!appUserId ? process.env.REACT_APP_USER_DEMO_ID : appUserId)
  const [userPin] = React.useState(
    !appUserId ? process.env.REACT_APP_USER_DEMO_PASSWORD : appUserPin
  )
  const [lastTrxShow, setLastTrxShow] = React.useState(false)
  const [lastTrx, setLastTransaction] = React.useState([])
  const [lastTrxFiltered, setLastTransactionFiltered] = React.useState([])
  const [searchLastTrx, setSearchLastTrx] = React.useState('')
  const [isSaldoOk, setIsSaldoOk] = React.useState(false)
  const [voucherQty] = React.useState('0')
  const [produk, setProduk] = React.useState('')
  const [produkLabel, setProdukLabel] = React.useState('')
  const [customer, setCustomer] = React.useState('')
  const [dataProduk, setDataProduk] = React.useState([])
  const [dataProdukFiltered, setDataProdukFiltered] = React.useState([])
  const [dataRes, setDataRes] = React.useState(null)
  const [serachProdukShow, setSearchProdukShow] = React.useState(false)
  const [searchProduk, setSearchProduk] = React.useState('')

  const toggleSidebar = React.useCallback(() => setIsOpen(!isOpen), [isOpen, setIsOpen])

  const closeError = React.useCallback(() => setAlertError(''), [])

  const openLoginModal = React.useCallback(async () => {
    setLoginModalShow(true)
  }, [])

  const confirmLoginModal = React.useCallback(async () => {
    setLoginModalShow(false)
    // window.location.reload()
  }, [])

  const closeLoginModal = React.useCallback(async () => {
    setLoginModalShow(false)
  }, [])

  const closeTagihanModal = React.useCallback(async () => {
    setTagihanModalShow(false)
  }, [])

  const openProdukShow = React.useCallback(async () => {
    setSearchProdukShow(true)
  }, [setSearchProdukShow])

  const closeProdukShow = React.useCallback(async () => {
    setSearchProdukShow(false)
  }, [setSearchProdukShow])

  const openLastTransaction = React.useCallback(async () => {
    setLastTrxShow(true)
  }, [setLastTrxShow])

  const closeLastTransaction = React.useCallback(async () => {
    setLastTrxShow(false)
  }, [setLastTrxShow])

  const onSearchLastTrxChange = React.useCallback(
    (e) => {
      const { value } = e.target
      const newData = _.filter(lastTrx, (item) => {
        return (
          _.includes(item.id_pelanggan, value) ||
          _.includes(item.nama_pelanggan.toLowerCase(), value.toLowerCase())
        )
      })

      setSearchLastTrx(value)
      setLastTransactionFiltered(newData)
    },
    [lastTrx, setSearchLastTrx, setLastTransactionFiltered]
  )

  const onSearchProdukChange = React.useCallback(
    (e) => {
      const { value } = e.target
      const newData = _.filter(dataProduk, (item) => {
        return _.includes(item.produk.toLowerCase(), value.toLowerCase())
      })

      setSearchProduk(value)
      setDataProdukFiltered(newData)
    },
    [dataProduk, setSearchProduk, setDataProdukFiltered]
  )

  const confirmLastTrx = React.useCallback(
    (e) => {
      const { target } = e.currentTarget.dataset
      setCustomer(Formatter.number(target))
      setLastTrxShow(false)
    },
    [setCustomer, setLastTrxShow]
  )

  const onCustomerChange = React.useCallback((e) => {
    const { value } = e.target
    setCustomer(Formatter.number(value))
  }, [])

  const onProdukChange = React.useCallback(
    (e) => {
      const { target, label } = e.currentTarget.dataset
      setProduk(target)
      setProdukLabel(label)
      setSearchProdukShow(false)

      if (target !== '') {
        dispatch(lastTransaction(userId, target, '0')).then((response) => {
          if (response.isOK()) {
            setLastTransaction(response.getResultAsList())
            setLastTransactionFiltered(response.getResultAsList())
          } else {
            setLastTransaction([])
            setLastTransactionFiltered([])
          }
        })
      } else {
        setLastTransaction([])
        setLastTransactionFiltered([])
      }

      setIsInqWeb(false)
      setDataRes(null)
      setCustomer('')
    },
    [
      dispatch,
      userId,
      setProduk,
      setProdukLabel,
      setLastTransaction,
      setLastTransactionFiltered,
      setSearchProdukShow,
      setCustomer,
      setIsInqWeb,
      setDataRes,
    ]
  )

  const handleClear = React.useCallback(
    (e) => {
      const { target } = e.currentTarget.dataset
      if (target === 'customer') {
        setCustomer('')
      }

      setIsInqWeb(false)
      setDataRes(null)
    },
    [setCustomer, setIsInqWeb, setDataRes]
  )

  const onProcess = React.useCallback(async () => {
    if (!produk) {
      setAlertError({ rc: '', rd: 'Pilih Produk PGN Terlebih Dahulu' })
      return false
    }

    if (!customer) {
      setAlertError({ rc: '', rd: 'Masukkan Nomor Pelanggan Terlebih Dahulu' })
      return false
    }

    const responseInq = await dispatch(tagihanInq(userId, userPin, GROUP_PRODUK, produk, customer))
    if (responseInq.isOK()) {
      const dataRespon = responseInq.getData()
      const { amount } = dataRespon.includes
      const { admin } = dataRespon.includes
      const total =
        parseInt(amount, 0) + parseInt(admin, 0) + parseInt(dataRespon.additional_datas[93], 10)
      if (Math.round(parseFloat(appUserBalance)) >= total) {
        setIsSaldoOk(true)
      }

      setDataRes(dataRespon)
      if (isMobile) {
        setTagihanModalShow(true)
      } else {
        setIsInqWeb(true)
      }
    } else {
      setAlertError({ rc: responseInq.getRC(), rd: responseInq.getRD() })
    }

    return true
  }, [
    dispatch,
    userId,
    userPin,
    appUserBalance,
    produk,
    customer,
    setIsInqWeb,
    setIsSaldoOk,
    setAlertError,
  ])

  const redirectToDeposit = React.useCallback(() => {
    history.push('/deposit')
  }, [history])

  const confirmPayment = React.useCallback(async () => {
    if (!appUserId) {
      setTagihanModalShow(false)
      setLoginModalShow(true)
    } else if (isSaldoOk) {
      const dataIncludes = dataRes.includes
      const { amount, admin } = dataIncludes
      const reffId = dataIncludes.reff_id
      const responsePay = await dispatch(
        tagihanPay(
          userId,
          userPin,
          voucherQty,
          GROUP_PRODUK,
          produk,
          customer,
          amount,
          admin,
          reffId
        )
      )
      if (responsePay.isOK()) {
        history.push({
          pathname: '/struk',
          state: {
            parent: 'gas',
            parentPath: location.pathname,
            title: 'PGN',
            icon: 'burn',
            transaksi: responsePay.data.includes.reff_id,
            trxDate: responsePay.data.transmission_datetime,
            trxTime: responsePay.data.transmission_datetime,
            total:
              Number(responsePay.data.includes.amount) +
              Number(responsePay.data.includes.admin) +
              parseInt(responsePay.data.additional_datas[93], 10),
            customerId: responsePay.data.includes.customer_id_1,
            discountInfo: responsePay.data.discountInfo,
            info: 'sukses',
          },
        })
      } else if (
        responsePay.getResponseCode() === '' ||
        responsePay.getRC() === '68' ||
        responsePay.getRC().toString() === responsePay.data.includes.reff_id.toString()
      ) {
        history.push({
          pathname: '/struk',
          state: {
            parent: 'gas',
            parentPath: location.pathname,
            title: 'PGN',
            icon: 'burn',
            transaksi: responsePay.data.includes.reff_id,
            trxDate: responsePay.data.transmission_datetime,
            trxTime: responsePay.data.transmission_datetime,
            total:
              Number(responsePay.data.includes.amount) +
              Number(responsePay.data.includes.admin) +
              parseInt(responsePay.data.additional_datas[93], 10),
            customerId: responsePay.data.includes.customer_id_1,
            discountInfo: responsePay.data.discountInfo,
            info: 'pending',
          },
        })
      } else {
        setAlertError({ rc: responsePay.getRC(), rd: responsePay.getRD() })
      }
    } else {
      redirectToDeposit()
    }
  }, [
    dispatch,
    history,
    location,
    isSaldoOk,
    appUserId,
    userId,
    userPin,
    voucherQty,
    produk,
    customer,
    dataRes,
    setAlertError,
    redirectToDeposit,
  ])

  React.useEffect(() => {
    DOMHelper.mountClass('layanan-layout')
    HookHelper.resetLoading()

    dispatch(listProdukUbp(userId, utilConstant.groupProdukGas)).then((response) => {
      if (response.isOK()) {
        const resData = response.getData()
        setDataProduk(resData.data)
        setDataProdukFiltered(resData.data)
      }
    })

    return () => DOMHelper.unmountClass(['layanan-layout'])
  }, [dispatch, userId, setLoadingGetProduk])

  return (
    <Container fluid className="layanan-layout">
      {!appUserIsEmbed && (
        <Row className="header">
          <Col>
            <HeaderComponent title="PGN" onToggle={toggleSidebar} />
          </Col>
        </Row>
      )}

      <Row>
        {!appUserIsEmbed && (
          <Col
            md={2}
            className={classNames('col-sidebar', { 'is-open': isOpen, 'is-mobile': isMobile })}
          >
            <SidebarComponent isOpen={isOpen} />
          </Col>
        )}

        <Col
          md={{
            span: appUserIsEmbed ? 12 : isOpen ? 10 : 12,
            offset: appUserIsEmbed ? 0 : isOpen ? 2 : 0,
          }}
        >
          <Jumbotron fluid className="banner">
            <Row>
              <Col>
                <Row>
                  <span className={classNames('banner-title', { 'is-mobile': isMobile })}>
                    <FontAwesomeIcon icon="burn" fixedWidth className="mr-3" />
                    PGN
                  </span>
                </Row>
              </Col>

              {!appUserId ? (
                <Col lg={3} xs={4} md={3} style={{ margin: 'auto' }}>
                  <Button
                    className={classNames('button-login', { 'is-mobile': isMobile })}
                    type="button"
                    onClick={openLoginModal}
                  >
                    Daftar / Login
                  </Button>
                </Col>
              ) : (
                <Col lg={3} xs={12} md={3} style={{ margin: 'auto' }}>
                  <Row>
                    <Col lg={10} xs={10} md={10}>
                      <div className="banner-saldo">Saldo Anda</div>
                      <div className="banner-nominal">
                        Rp. {Formatter.price(Math.round(parseFloat(appUserBalance)))}
                      </div>
                    </Col>
                    <Col lg={2} xs={2} md={2}>
                      <span className="banner-title">
                        <FontAwesomeIcon icon="wallet" fixedWidth className="mr-3" />
                      </span>
                    </Col>
                  </Row>
                </Col>
              )}
            </Row>
          </Jumbotron>

          <div className="content-layanan">
            <Row className="justify-content-md-center">
              <Col lg={isInqWeb ? 7 : 8} xs={12} md={isInqWeb ? 7 : 8} className="bd-form">
                <Form className="form-pelanggan single">
                  {loadingGetProduk && (
                    <Spinner
                      animation="border"
                      role="status"
                      style={{ alignSelf: 'center' }}
                      variant="primary"
                    />
                  )}
                  {!loadingGetProduk && (
                    <>
                      <Form.Group>
                        <Form.Label className="form-label start">Produk PGN</Form.Label>
                        <InputGroup
                          className="mb-12"
                          onClick={openProdukShow}
                          style={{ cursor: 'pointer' }}
                        >
                          <Form.Control
                            placeholder="---Pilih Produk PGN---"
                            value={produkLabel}
                            readOnly
                            style={{ cursor: 'pointer' }}
                          />
                          <InputGroup.Append>
                            <FontAwesomeIcon icon="chevron-down" className="form-icon single" />
                          </InputGroup.Append>
                        </InputGroup>
                      </Form.Group>
                      <Form.Group>
                        <Form.Label className="form-label start">Nomor Pelanggan</Form.Label>
                        <InputGroup className="mb-12">
                          <Form.Control
                            placeholder="Masukkan Nomor Pelanggan"
                            value={Formatter.serial(customer)}
                            onChange={onCustomerChange}
                            disabled={isInqWeb || tagihanModalShow}
                          />
                          <InputGroup.Append className="flex align-items-center">
                            <Image
                              className={classNames('form-icon-btn', { 'ic-mobile': isMobile })}
                              src="/icon/clear.png"
                              data-target="customer"
                              onClick={handleClear}
                            />
                          </InputGroup.Append>
                        </InputGroup>
                      </Form.Group>
                    </>
                  )}
                </Form>
                <Row className="row-proses">
                  <Col style={{ margin: 'auto' }}>
                    {!loadingGetProduk && (
                      <Button
                        className="btn-process"
                        size="lg"
                        block
                        onClick={onProcess}
                        disabled={appIsLoading}
                      >
                        {appIsLoading && !isMobile && (
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                        )}
                        {appIsLoading && !isMobile ? ' Mohon Tunggu...' : 'Cek Tagihan Sekarang'}
                      </Button>
                    )}
                  </Col>
                </Row>
                {lastTrx.length > 0 && (
                  <div className="form-last-trx">
                    <span className="form-label">Transaksi Terakhir</span>
                    <Row
                      style={{
                        border: '1px solid #DDDDDD',
                        borderRadius: '10px',
                        alignItems: 'center',
                        padding: '10px',
                      }}
                    >
                      <Col lg={1} xs={2} md={1}>
                        <Image src={lastTrx[0].image_url} height={40} />
                      </Col>
                      <Col
                        lg={8}
                        xs={10}
                        md={8}
                        data-target={lastTrx[0].id_pelanggan}
                        onClick={confirmLastTrx}
                        className="pointing"
                      >
                        <Row>
                          <span className="form-label-main">{lastTrx[0].id_pelanggan}</span>
                        </Row>
                        <Row>
                          <span className="label-text">{lastTrx[0].nama_pelanggan}</span>
                        </Row>
                      </Col>
                      <Col lg={3} xs={8} md={3} onClick={openLastTransaction}>
                        <div className="label-text color-primary pointing">
                          Lihat riwayat transaksi
                        </div>
                      </Col>
                    </Row>
                  </div>
                )}
              </Col>
              {isInqWeb && (
                <>
                  <Col md="auto" />
                  <Col lg={4} xs={12} md={4} className="bd-form-inq">
                    <Row className="justify-content-md-center">
                      <Col className="inq-header txt-bold">
                        {dataRes ? `Tagihan ${dataRes.biller_info.productName}` : 'Tagihan PGN'}
                      </Col>
                    </Row>
                    {dataRes &&
                      dataRes.strukMaps.map((val) => (
                        <Row key={Formatter.upperFirst(Object.keys(val)[0])}>
                          <Col>{Formatter.upperFirst(Object.keys(val)[0])}</Col>
                          <Col className="inq-end-label">{val ? val[Object.keys(val)[0]] : ''}</Col>
                        </Row>
                      ))}
                    <Row className="justify-content-md-center inq-body-last">
                      <Col className="inq-balance">
                        <Row style={{ display: 'flex', alignItems: 'center' }}>
                          <Col lg={2} xs={2} md={2}>
                            <Image src="/img/sc.png" roundedCircle />
                          </Col>
                          <Col lg={5} xs={5} md={5}>
                            Saldo Speedcash
                          </Col>
                          <Col lg={5} xs={5} md={5} className="inq-end-label txt-bold">
                            {dataRes
                              ? `${Formatter.price(
                                  Math.round(parseFloat(dataRes.includes.balance))
                                )}`
                              : 'Rp 0,-'}
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    {!isSaldoOk && appUserId && (
                      <Row className="justify-content-md-center inq-body-last">
                        <Col className="inq-balance not-ok">
                          <Row className="txt-bold">
                            Saldo Anda belum mencukupi untuk transaksi ini. Segera Top Up
                          </Row>
                        </Col>
                      </Row>
                    )}
                    <Row className="justify-content-md-center">
                      <Col>
                        <Button
                          className="btn-process"
                          type="button"
                          block
                          onClick={
                            appUserId
                              ? isSaldoOk
                                ? confirmPayment
                                : redirectToDeposit
                              : openLoginModal
                          }
                        >
                          {appIsLoading && (
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />
                          )}
                          {appIsLoading
                            ? ' Mohon Tunggu...'
                            : appUserId
                            ? isSaldoOk
                              ? 'Bayar Sekarang'
                              : 'Top Up Saldo'
                            : 'Daftar / Login'}
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </>
              )}
            </Row>
          </div>
        </Col>
      </Row>
      <Alert
        header="Perhatian"
        btnText="OK"
        text={alertError.rd}
        type="error"
        show={!!alertError.rd}
        onClosePress={closeError}
        showBorderBottom
        alertStyles={{ justifyContent: 'center' }}
      />
      <LoginModal show={!!loginModalShow} onHide={closeLoginModal} onConfirm={confirmLoginModal} />
      <GasModal
        show={!!tagihanModalShow}
        onHide={closeTagihanModal}
        onConfirm={confirmPayment}
        dataRes={dataRes}
        isSaldoOk={isSaldoOk}
      />
      <ProductListNewDialog
        title="PGN"
        group="gas"
        show={!!serachProdukShow}
        onHide={closeProdukShow}
        onConfirm={onProdukChange}
        search={searchProduk}
        onSearchChange={onSearchProdukChange}
        data={dataProdukFiltered}
      />
      <LastTrxDialog
        show={!!lastTrxShow}
        onHide={closeLastTransaction}
        onConfirm={confirmLastTrx}
        search={searchLastTrx}
        onSearchChange={onSearchLastTrxChange}
        data={lastTrxFiltered}
      />
    </Container>
  )
}

GasScreen.defaultProps = {
  appIsLoading: false,
  appUserId: '',
  appUserPin: '',
  appUserBalance: 0,
  appUserIsEmbed: false,
}

GasScreen.propTypes = {
  appUserId: PropTypes.string,
  appUserPin: PropTypes.string,
  appUserBalance: PropTypes.number,
  appUserIsEmbed: PropTypes.bool,
  appIsLoading: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
}

export default connect(
  (state) => ({
    appIsLoading: state.Global[globalConstant.IS_LOADING],
    appUserId: state.Auth[authConstant.USER_ID],
    appUserPin: state.Auth[authConstant.USER_PIN],
    appUserBalance: state.Auth[authConstant.USER_BALANCE],
    appUserIsEmbed: state.Auth[authConstant.USER_IS_EMBED],
    appUserPhone: state.Auth[authConstant.USER_PHONE],
  }),
  (dispatch) => ({ dispatch })
)(GasScreen)
