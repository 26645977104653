import React from 'react'
import { Button, Image, Row, Col, Accordion, Card } from 'react-bootstrap'
import './accordion.scss'

const AccordionTelkom = () => {
  return (
    <>
      <Col className="d-flex justify-content-center mt-4 mb-3 p-3">
        <h3 className="text-dark text-justify">Cara Cek Tagihan Wifi Indihome Telkom Disini</h3>
      </Col>

      <div style={{ padding: '0px 20px 30px 20px' }}>
        <Row className="justify-content-md-center">
          <Col lg={8} xs={12} md={8} className="content-component">
            <div>
              <Row className="d-flex justify-content-center">
                <p className="d-block text-justify">
                  Sekarang untuk melihat total tagihan bulanan wifi indihome sudah sangat
                  mudah,karena disini kamu bisa langsung cek tagihan indihome dan bayar online tanpa
                  harus menginstall aplikasi namun tetap mendapat diskon saat pembayaran.Begini
                  langkah <a href="https://member.speedcash.co.id/telkom">cek tagihan indihome</a>{' '}
                  di web resmi SpeedCash :
                </p>
              </Row>
              <Row>
                <ol className="text-justify list-content">
                  <li>
                    Pastikan kamu mengunjungi halaman :{' '}
                    <a href="https://member.speedcash.co.id/telkom">
                      https://member.speedcash.co.id/telkom
                    </a>
                  </li>
                  <li>Lanjutkan dengan login dulu menggunakan nomor hp yang aktif ya</li>
                  <li>Jika sudah, lalu masuk ke menu telkom & indihome</li>
                  <li>Siapkan ID Pelanggan telkom/indihome</li>
                  <li>Masukkan ID Pelanggan mu</li>
                  <li>Lalu klik Cek tagihan Indihome Sekarang</li>
                  <li>Dan akan muncul total tagihan beserta diskon yang kamu bayar</li>
                  <li>Ikuti instruksi selanjutnya</li>
                </ol>
              </Row>
              <Row className="d-flex justify-content-center mt-1">
                <Image
                  src="/img/cek-tagihan-indihome.png"
                  data-target="cek-tagihan-indihome"
                  alt="Cek Tagihan Indihome"
                  width="100%"
                />
              </Row>
            </div>
          </Col>

          <Col lg={8} xs={12} md={8} className="accordion-component">
            <Accordion className="m-0 p-0">
              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="1"
                    className="button-collapse w-100 text-left"
                  >
                    Bisa Juga Kamu Simak Video Cek Tagihan Indihome Via Aplikasi SpeedCash
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="1">
                  <Card.Body className="card-content">
                    <Row>
                      <iframe
                        width="100%"
                        height="480"
                        src="https://www.youtube.com/embed/vgxLpwWZNuA"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        frameBorder={0}
                        title="Cara cek tagihan indihome Via SpeedCash"
                        className="mb-3"
                      />
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="2"
                    className="button-collapse w-100 text-left"
                  >
                    Cek Tagihan Indihome Di Aplikasi Resmi SpeedCash
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="2">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        JIka kamu masih bingung atau ingin mendapatkan kemudahan dalam 1
                        genggaman,kamu bisa menggunakan aplikasi{' '}
                        <a href="https://www.speedcash.co.id">SpeedCash</a> untuk cek tagihan
                        indihome dan bayar online langsung di aplikasi. Begini langkah-langkahnya :
                      </p>
                      <Row className="mt-3">
                        <ul className="text-justify list-content">
                          <li>
                            Download dulu SpeedCash yang sudah tersedia di playstore maupun appstore
                            (IOS)
                          </li>
                          <li>Lanjutkan dengan login menggunakan no hp yang aktif ya</li>
                          <li>
                            <p>Jika sudah login, langsung saja kamu klik menu Telkom</p>
                            <Row className="d-flex justify-content-center">
                              <Image
                                src="/img/cek_tagihan_wifi.png"
                                data-target="cek_tagihan_wifi"
                                width="220px"
                                height="400px"
                              />
                            </Row>
                          </li>
                          <li>Jika sudah, masukkan ID Pelangganmu</li>
                          <li>
                            <p>
                              JIka kamu sudah pernah membayar tagihan wifi indihome melalui aplikasi
                              maka akan muncul history ID pelangganmu seperti ini.
                            </p>
                            <Row className="d-flex justify-content-center">
                              <Image
                                src="/img/cek_tagihan_wifi_indihome.png"
                                data-target="cek_tagihan_wifi_indihome"
                                width="220px"
                                height="400px"
                              />
                            </Row>
                          </li>
                        </ul>
                      </Row>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="3"
                    className="button-collapse w-100 text-left"
                  >
                    Mengapa Harus Cek Tagihan Indihome Dan Bayar Langsung Di SpeedCash
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="3">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Ternyata membayar tagihan bulanan menggunakan SpeedCash memberikan kamu
                        banyak keuntungan.Salah satunya diskon saat pembayaran dan hebatnya lagi
                        menggunakan SpeedCash tidak hanya untuk mengecek tagihan indihome saja,
                        tetapi juga bisa untuk{' '}
                        <a href="https://member.speedcash.co.id/pln/cek-tagihan-listrik">
                          cek tagihan listrik
                        </a>
                        , <a href="https://member.speedcash.co.id/bpjs">cek tagihan BPJS</a> dan
                        beli{' '}
                        <a href="https://member.speedcash.co.id/pln/token-listrik">token listrik</a>{' '}
                        juga.
                      </p>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="4"
                    className="button-collapse w-100 text-left"
                  >
                    Kelebihan Lain Menggunakan SpeedCash
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="4">
                  <Card.Body className="card-content">
                    <Row>
                      <ul className="text-justify list-content">
                        <li>Gratis notifikasi pengingat tagihan listrik PLN bulanan</li>
                        <li>
                          Tidak hanya PLN, namun lengkap dengan PDAM, BPJS, isi pulsa, paket data,
                          gopay, ovo, shopeepay, dana, game online dll
                        </li>
                        <li>Transfer uang ke semua bank gratis biaya admin</li>
                        <li>Tiket pesawat garansi harga termurah</li>
                        <li>Tiket Kereta Api gratis biaya admin & layanan</li>
                        <li>Pasti Dapat Diskon disetiap transaksi</li>
                        <li>Downloadnya aplikasinya gratis 100%</li>
                        <li>Tidak perlu punya rekening bank pribadi</li>
                      </ul>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="5"
                    className="button-collapse w-100 text-left"
                  >
                    Kendala Saat Cek Tagihan Indihome Telkom Di SpeedCash
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="5">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Biasanya Ada beberapa kendala yang muncul saat pembayaran tagihan IndiHome,
                        antara lain:
                      </p>
                      <div className="mt-1">
                        <b>Pembayaran yang Gagal</b>
                        <p className="d-block text-justify mt-1">
                          Saat menemui kendala ini muncul, Kamu bisa mencoba melakukan pembayaran
                          ulang beberapa menit kemudian. Jika masih gagal, Anda bisa mencoba
                          langsung menghubungi customer care SpeedCash melalui live chat di aplikasi
                          ataupun menghubungi support@speedcash.co.id.
                        </p>
                      </div>
                      <div className="mt-1">
                        <b>Pembayaran Berhasil tapi Tagihan Kerap Kali Masih Muncul</b>
                        <p className="d-block text-justify mt-1">
                          Jika kamu mendapati kendala satu ini, Kamu bisa langsung melakukan
                          komplain kepada operator IndiHome supaya dilakukan pengecekan ulang.
                        </p>
                      </div>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="6"
                    className="button-collapse w-100 text-left"
                  >
                    Info Sekilas Tentang Indihome
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="6">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        IndiHome merupakan singkatan dari Rumah Digital Indonesia yang diproduksi
                        oleh PT Telkom Indonesia (Persero) Tbk. Seperti diketahui, PT Telkom
                        bergerak di bidang paket komunikasi dan layanan data.
                      </p>
                      <p className="d-block text-justify mt-1">
                        IndiHome sendiri diperkenalkan sebagai layanan di bidang Internet, layanan
                        komunikasi telepon rumah dan layanan yang sangat populer saat ini yaitu
                        televisi interaktif. PT Telkom menamai IndiHome karena menyediakan layanan
                        3-in-1 yaitu Internet, TV berbayar, dan saluran telepon.
                      </p>
                      <p className="d-block text-justify mt-1">
                        IndiHome sendiri telah hadir untuk mendukung masyarakat Indonesia sejak
                        tahun 2015 dan masih banyak peminatnya karena pelayanannya yang sangat
                        memuaskan. Memang PT Telkom telah menggandeng beberapa pengembang teknologi
                        telekomunikasi rumah untuk menghadirkan konsep digital.
                      </p>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="7"
                    className="button-collapse w-100 text-left"
                  >
                    Kelebihan Menggunakan Wifi Indihome
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="7">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Tentunya banyak keuntungan yang bisa Anda nikmati sebagai pengguna IndiHome,
                        antara lain:
                      </p>
                      <div className="mt-1">
                        <b>Pilihan paket sesuai kebutuhan rumah dan industri Anda</b>
                        <p className="d-block text-justify mt-1">
                          IndiHome telah menyediakan nomor paket sesuai kebutuhan Anda. Paket yang
                          ditawarkan antara lain adalah Paket Dual Play, Paket Triple Play Premium,
                          dan Paket Triple Play Deluxe.
                        </p>
                      </div>
                      <div className="mt-1">
                        <b>Nonton TV dengan lancar tanpa jeda</b>
                        <p className="d-block text-justify mt-1">
                          Layanan TV berbayar langsung Layanan streaming yang disediakan IndiHome
                          menawarkan beragam pilihan saluran domestik dan internasional yang baik.
                          Anda juga bisa memanfaatkan fungsi membaca di layanan ini.
                        </p>
                      </div>
                      <div className="mt-1">
                        <b>Perlindungan ekstra bebas virus</b>
                        <p className="d-block text-justify mt-1">
                          Banyak ancaman virus digital saat ini, namun Anda tidak perlu khawatir
                          karena IndiHome memiliki perlindungan antivirus tambahan.
                        </p>
                      </div>
                      <div className="mt-1">
                        <b>Harga murah dan terjangkau dimana-mana</b>
                        <p className="d-block text-justify mt-1">
                          Paket layanan yang ditawarkan IndiHome ditawarkan dengan harga yang sangat
                          terjangkau dibandingkan dengan layanan jaringan dan fungsi yang diberikan.
                          Pembayaran juga bisa kamu lakukan dimana saja, salah satunya dengan
                          SpeedCash, karena dengan SpeedCash kamu bisa melihat tagihan Indihome dan
                          membayar langsung melalui aplikasi atau tanpa aplikasi.
                        </p>
                      </div>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="8"
                    className="button-collapse w-100 text-left"
                  >
                    Paket Internet Indihome Dan TV Kabel telkom Indihome
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="8">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Paket Dual Play Paket ini adalah paket pilihan karena mencakup layanan
                        telepon rumah dan Internet fiber. Namun, Anda juga dapat memilih jenis
                        layanan kedua, UseeTV dan Fiber Internet. Paket ini dibanderol Rp 340.000.
                      </p>
                      <div className="mt-1">
                        <b>Triple Play Premium</b>
                        <p className="d-block text-justify mt-1">
                          Pada layanan ini Anda bisa mendapatkan tiga jenis paket yaitu telepon,
                          Internet fiber dengan pilihan 20 Mbps atau 100 Mbps, dan USeeTV menawarkan
                          beragam pilihan saluran. Harga paket ini mulai dari Rp 700.000.
                        </p>
                      </div>

                      <div className="mt-1">
                        <b>Triple Play Deluxe</b>
                        <p className="d-block text-justify mt-1">
                          Hampir sama dengan paket Triple Play Premium, bedanya paket ini dilengkapi
                          dengan fasilitas tambahan khusus IndiHome Fiber, Iflix, Bergerak dan
                          Menangkap. Anda juga mendapatkan bonus panggilan 50 hingga 100 menit.
                        </p>
                      </div>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="9"
                    className="button-collapse w-100 text-left"
                  >
                    Cara Pasang / Berlangganan Indihome
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="9">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Bagi yang ingin memasang IndiHome baru harus melampirkan persyaratan seperti
                        foto KTP asli, foto setengah orang memegang KTP, mengisi alamat email, nomor
                        ponsel, masuk ke pengaturan Alamat. dan bagikan lokasi Anda untuk memudahkan
                        agen mengecek dan mengambil foto rumah yang Anda lihat. IndiHome akan
                        terinstall, terakhir Anda juga harus memilih paket internet IndiHome yang
                        ingin Anda gunakan.
                      </p>
                      <p className="d-block text-justify mt-1">
                        Anda bisa mendaftar secara online di website resmi IndiHome atau bisa juga
                        langsung ke cabang IndiHome yang lebih dekat ke Ban. kota. Dengan melakukan
                        registrasi atau pra-registrasi, agen IndiHome akan datang ke rumah Anda
                        untuk menginstal IndiHome Internet di rumah atau kantor Anda.
                      </p>
                      <b className="d-block text-justify">
                        Untuk memasang IndiHome secara online, Anda dapat melakukan hal berikut:
                      </b>
                      <Row className="mt-3">
                        <ul className="text-justify list-content">
                          <li>Akses Website Resmi IndiHome melalui website resminya.</li>
                          <li>
                            Kemudian Anda akan melihat beberapa informasi penting pada website
                            tersebut.
                          </li>
                          <li>
                            Kemudian tekan menu Daftar untuk mengisi data, alamat pemasangan dan
                            informasi lain yang diperlukan.
                          </li>
                          <li>
                            Setelah pengajuan selesai dan kirimkan, daftar dan tunggu IndiHome
                            menghubungi Anda secara langsung.
                          </li>
                          <li>
                            Ingatlah untuk memasukkan alamat email dan nomor ponsel Anda karena Anda
                            akan dihubungi melalui nomor telepon atau email Anda.
                          </li>
                        </ul>
                      </Row>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="10"
                    className="button-collapse w-100 text-left"
                  >
                    Cek Tagihan Indihome Via Aplikasi My Indihome
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="10">
                  <Card.Body className="card-content">
                    <Row>
                      <ol className="text-justify list-content">
                        <li>Login aplikasi MyIndiHome, pilih tab Bantuan</li>
                        <li>Klik Pengaduan Layanan dan pilih kategori Administrasi dan Tagihan</li>
                        <li>Pilih gangguan yang dialami: Buka Status Isolir</li>
                        <li>Isi detail komentar dan klik Laporkan</li>
                        <li>Tunggu hingga proses selesai</li>
                      </ol>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="11"
                    className="button-collapse w-100 text-left"
                  >
                    ( F.A.Q) Hal Lain Yang Sering Ditanyakan Tentang Indihome
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="11">
                  <Card.Body className="card-content">
                    <Row>
                      <div className="w-100">
                        <p className="d-block text-justify">
                          <strong>T:</strong> Apakah Indihome jadi satu dengan telkomsel ?{' '}
                        </p>
                      </div>
                      <div className="w-100 my-1">
                        <p className="d-block text-justify">
                          {' '}
                          <strong>J:</strong> Mulai 1 Juli 2023 layanan IndiHome secara resmi
                          bergabung menjadi bagian dari Telkomsel.
                        </p>
                      </div>
                      <div className="w-100 my-1">
                        <p className="d-block text-justify">
                          <strong>T:</strong> Kelebihan IndiHome setelah dialihkan ke Telkomsel?
                        </p>
                      </div>
                      <div className="w-100 my-1">
                        <p className="d-block text-justify">
                          {' '}
                          <strong>J:</strong> Dengan mengintegrasikan layanan IndiHome yang
                          menawarkan koneksi internet tetap melalui serat optik serta dengan
                          jaringan seluler broadband yang luas, memungkinkan pelanggan IndiHome dan
                          Telkomsel untuk mendapatkan pengalaman konektivitas broadband yang mulus
                          dan pengalaman digital yang lebih baik di dalam maupun di luar rumah,
                          tanpa terikat pada satu teknologi jaringan tertentu. Ke depannya,
                          Telkomsel juga akan mengembangkan beragam penawaran produk dan layanan
                          yang lebih terjangkau dan bernilai tambah, yang mengintegrasikan seluruh
                          keunggulan, baik layanan fixed broadband IndiHome maupun layanan mobile
                          broadband Telkomsel, seperti Orbit, Telkomsel PraBayar, dan Halo.
                        </p>
                      </div>
                      <div className="w-100 my-1">
                        <p className="d-block text-justify">
                          <strong>T:</strong> Apa saja layanan pelanggan indihome yang resmi ?
                        </p>
                      </div>
                      <div className="w-100 my-1">
                        <p className="d-block text-justify">
                          {' '}
                          <strong>J :</strong> Layanan pelanggan IndiHome dapat dihubungi melalui:
                        </p>
                        <div className="ml-3">
                          <ul className="text-justify list-content">
                            <li>Call Center: 188 dan 147</li>
                            <li>Twitter, Instagram, Facebook: @IndiHomeCare</li>
                            <li>Website IndiHome</li>
                            <li>WhatsApp</li>
                            <li>Email: cs@telkomsel.com</li>
                            <li>GraPARI Telkomsel dan Plasa Telkom Group</li>
                          </ul>
                        </div>
                      </div>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="12"
                    className="button-collapse w-100 text-left"
                  >
                    Kantor Pusat Indihome
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="12">
                  <Card.Body className="card-content">
                    <Row>
                      <div className="d-block text-justify w-100 mb-1">
                        <iframe
                          src="https://www.google.com/maps/embed?pb=!1m23!1m12!1m3!1d126925.39428681092!2d106.77654755207323!3d-6.208392329486341!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m8!3e6!4m0!4m5!1s0x2e69f575c3df29d9%3A0x2bc28af8c369349f!2sQVR5%2BJHW%2C%20RT.4%2FRW.3%2C%20Kb.%20Manggis%2C%20Kec.%20Matraman%2C%20Kota%20Jakarta%20Timur%2C%20Daerah%20Khusus%20Ibukota%20Jakarta%2013150!3m2!1d-6.208414299999999!2d106.8589107!5e0!3m2!1sid!2sid!4v1710995008920!5m2!1sid!2sid"
                          width="100%"
                          height="450"
                          style={{ border: '0' }}
                          allowfullscreen=""
                          loading="lazy"
                          referrerPolicy="no-referrer-when-downgrade"
                          title="kantor indihome"
                        />
                      </div>
                      <p className="d-block text-justify mt-1">
                        Itulah tadi informasi mengenai cek tagihan indihome dan bayar online
                        langsung di SpeedCash.Jika kamu merasa terbantu dengan artikel ini maka kamu
                        bisa bantu kami dengan mencoba download langsung aplikasi SpeedCash melalui
                        link download yang tersedia dibawah ini.
                      </p>
                      <div className="mt-1 w-100 d-flex justify-content-center">
                        <h5 className="bold">Yuk! Download Aplikasi Cek Tagihan Indihome Disini</h5>
                      </div>
                      <Row className="d-flex w-100 justify-content-center mb-3 gap-3">
                        <a
                          aria-hidden
                          href="https://play.google.com/store/apps/details?id=com.bm.sc.bebasbayar&listing=speedcash_banner_app"
                          className="mx-1"
                        >
                          <Image
                            src="/img/playstore-speedcash.png"
                            data-target="playstore-speedcash"
                            width="220px"
                            height="60px"
                          />
                        </a>
                        <a
                          aria-hidden
                          href="https://apps.apple.com/id/app/speedcash-transfer-ewallet/id6459304122"
                          className="mx-1"
                        >
                          <Image
                            src="/img/appstore-speedcash.png"
                            data-target="appstore-speedcash"
                            width="220px"
                            height="60px"
                          />
                        </a>
                      </Row>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default AccordionTelkom
