import React from 'react'
import { connect } from 'react-redux'
import { Container } from 'react-bootstrap'
import { isMobile } from 'react-device-detect'

import DOMHelper from '../../helper/domhelper'
import HookHelper from '../../helper/hookhelper'

import './home.scss'
import globalConstant from '../../store/constant/global'

const RedirectScreen = () => {
  React.useEffect(() => {
    DOMHelper.mountClass('home-layout')
    HookHelper.resetLoading()

    window.setTimeout(() => {
      window.location.href = process.env.REACT_APP_BASE_URL
    }, 8000)

    return () => DOMHelper.unmountClass(['home-layout'])
  }, [])

  return (
    <Container fluid className="home-layout">
      <div className={isMobile ? 'img-redirrect-mobile' : 'img-redirrect'} />
    </Container>
  )
}

RedirectScreen.propTypes = {}

export default connect(
  (state) => ({
    appIsLoading: state.Global[globalConstant.IS_LOADING],
  }),
  (dispatch) => ({ dispatch })
)(RedirectScreen)
