const Formatter = {
  price: (mny) => {
    let money = mny
    if (!money) {
      money = ''
    }
    if (typeof money === 'number') {
      money = money.toString()
    }
    const v = money.replace(/[^0-9]/gi, '')
    let f = parseInt(v) // eslint-disable-line radix
    if (Number.isNaN(f)) {
      f = 0
    }
    return f.toFixed(0).replace(/./g, (c, i, a) => {
      let x
      if (i && c !== '.' && !((a.length - i) % 3)) {
        x = `.${c}`
      } else if (c === '.') {
        x = `,${c.substring(1)}`
      } else {
        x = c
      }
      return x
    })
  },

  number: (vlue) => {
    return vlue.replace(/[^0-9]/gi, '')
  },

  serial: (vlue, lngth, asis) => {
    let value = vlue
    let length = lngth
    if (!value) {
      value = ''
    }
    if (!length) {
      length = 16
    }
    let v = value.replace(/-+/g, '')
    let rgx
    if (!asis) {
      v = v.replace(/[^0-9]/gi, '')
      rgx = `\\d{4,${length}}`
    } else {
      rgx = `.{4,${length}}`
    }
    const matches = v.match(new RegExp(rgx, 'g'))
    const match = (matches && matches[0]) || ''
    const parts = []

    for (let i = 0, len = match.length; i < len; i += 4) {
      parts.push(match.substring(i, i + 4))
    }

    if (parts.length) {
      return parts.join('-')
    }
    return value
  },

  upperFirst: (string) => {
    const splitStr = string.toLowerCase().split(' ')
    for (let i = 0; i < splitStr.length; i += 1) {
      splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1)
    }
    return splitStr.join(' ')
  },

  noSpaces: (value) => {
    return value.replace(/\s/g, '')
  },

  noAsterisk: (value) => {
    return value.replace('*', '')
  },
}

export default Formatter
