/* eslint-disable func-names */
import React from 'react'

const Livechat = () => {
  React.useEffect(() => {
    window.chatwootSettings = {
      position: 'right',
      type: 'standard',
      launcherTitle: 'Chat with us',
    }
    ;(function (d, t) {
      const BASE_URL = 'https://chat.speedcash.co.id'
      const g = d.createElement(t)
      const s = d.getElementsByTagName(t)[0]
      g.src = `${BASE_URL}/packs/js/sdk.js`
      g.defer = true
      g.async = true
      s.parentNode.insertBefore(g, s)

      g.onload = function () {
        window.chatwootSDK.run({
          websiteToken: process.env.REACT_APP_LIVECHAT_TOKEN,
          baseUrl: BASE_URL,
        })
      }
    })(document, 'script')
  }, [])

  return null
}

export default Livechat
