const utilConstant = {
  trfSpesial: {
    prefixProduct: 'SCCOE',
    prefixProductDefault: 'E',
    prefixProductBca: 'BCA',
    prefixProductBri: 'BRI',
    prefixProductBni: 'BNI',
    prefixProductMandiri: 'MDR',
    bankCodeBca: '014',
    bankCodeBri: '002',
    bankCodeBni: '009',
    bankCodeMandiri: '008',
    eCommerceTokopedia: 'TOKOPEDIA',
    eCommerceShopee: 'SHOPEE',
    eCommerceBukalapak: 'BUKALAPAK',
    eCommerceLazada: 'LAZADA',
    eCommerceJdid: 'JDID',
    eCommerceBlibli: 'BLIBLI',
    eCommerceLainnya: 'LAINNYA',
  },
  groupProdukPkb: 'PAJAK',
  groupProdukPbb: 'PBB',
  groupProdukTvBerlangganan: 'TV BERLANGGANAN',
  groupProdukPdam: 'PDAM',
  groupProdukFinance: 'MULTI FINANCE',
  groupProdukKartuKredit: 'KARTU KREDIT',
  groupProdukGas: 'GAS',
  groupProdukPasca: 'TELEPON PASCA BAYAR',
  groupProdukAsuransi: {
    asuransi: 'ASURANSI',
    jiwasraya: 'JIWASRAYA',
    jiwasraya2: 'ASURANSI PERJALANAN',
    bpjs: 'BPJS',
  },
  idProdukPkbJatim: 'PKBJATIM',
}

export default utilConstant
