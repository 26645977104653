import LayananMessage from '../../message/mp/layanan'
import BaseMessage from '../../message/base'
import MessageSender from '../../sender'
import authConstant from '../../../store/constant/auth'

export const checkMember = (outletId, searchId) => async () => {
  const msg = new BaseMessage()
  msg.setPath(`${BaseMessage.SC_API}/merchants/checkid`)
  msg.data.id = outletId
  msg.data.searchid = searchId
  msg.data.utm_user_id = outletId
  msg.data.utm_campaign = ''
  msg.data.utm_medium = ''
  msg.data.utm_term = ''
  msg.data.utm_name = ''
  msg.data.utm_content = ''
  msg.data.utm_source = ''
  msg.data.timestamp = new Date().getTime()

  const response = await new MessageSender().doGet(msg)
  return new BaseMessage(response)
}

export const transferPay = (outletId, pin, customer, nominal, berita) => async (dispatch) => {
  const msg = new LayananMessage()
  msg.setProcessingCode('SCTI')
  msg.setOutletID(outletId)
  msg.setPIN(pin)
  msg.setAsManualPin()
  msg.data.includes.product_code = 'SCTI'
  msg.setAdditionalDatum(0, customer)
  msg.setAdditionalDatum(1, nominal)
  msg.setAdditionalDatum(2, berita)

  msg.data.campaign.utm_user_id = outletId

  const response = await new MessageSender().doPost(msg)
  const msg2 = new LayananMessage(response)
  if (msg2.isOK()) {
    await dispatch({
      type: authConstant.USER_BALANCE,
      payload: Number(msg2.data.includes.balance),
    })
  }

  return msg2
}
