/* eslint-disable no-nested-ternary */
import React from 'react'
import { connect } from 'react-redux'
import {
  Modal,
  Button,
  Image,
  Card,
  Row,
  Col,
  Alert,
  Spinner,
  Form,
  InputGroup,
} from 'react-bootstrap'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import globalConstant from '../../../store/constant/global'
import authConstant from '../../../store/constant/auth'
import HookHelper from '../../../helper/hookhelper'
import Formatter from '../../../helper/formatter'

import '../layanan/dialogLayanan.scss'

const TransferSaldoModal = ({
  appIsLoading,
  appUserId,
  onHide,
  onConfirm,
  show,
  dataRes,
  customer,
  nominal,
  balance,
  berita,
  pin,
  showPin,
  isShowPin,
  onUserPinChange,
}) => {
  const [alertError, setAlertError] = React.useState({ rc: '', rd: '' })

  const closeError = React.useCallback(async () => {
    setAlertError({ rc: '', rd: '' })
  }, [setAlertError])

  React.useEffect(() => {
    HookHelper.resetLoading()
  }, [])

  return (
    <Modal
      show={show}
      onHide={onHide}
      backdrop="static"
      keyboard={false}
      centered
      contentClassName="radius pln"
      animation={false}
    >
      <Modal.Header className="header">
        <div style={{ width: '95%' }}>
          <Modal.Title className="title">Konfirmasi Transfer</Modal.Title>
        </div>
        <div className="close-modal">
          <Image
            src="/icon/clear.png"
            onClick={onHide}
            roundedCircle
            className="justify-content-end"
          />
        </div>
      </Modal.Header>

      <Modal.Body className="body">
        <Card>
          <Card.Body>
            <Card.Text className="card-text">
              <span>No. HP / ID Penerima</span>
              <span>{customer}</span>
            </Card.Text>
            <Card.Text className="card-text">
              <span>Nama Penerima</span>
              <span>{dataRes}</span>
            </Card.Text>
            <Card.Text className="card-text">
              <span>Nominal Transfer</span>
              <span>Rp. {Formatter.price(nominal)}</span>
            </Card.Text>
            <Card.Text className="card-text">
              <span>Biaya Admin</span>
              <span>Rp. 0</span>
            </Card.Text>
            <Card.Text className="card-text">
              <span>Total</span>
              <span className="bold">Rp. {Formatter.price(nominal)}</span>
            </Card.Text>
            <Card.Text className="card-text">
              <span>Berita</span>
              <span className="bold">{berita}</span>
            </Card.Text>
          </Card.Body>
        </Card>
        <Card style={{ padding: '10px' }}>
          <Row className="justify-content-md-center">
            <Col className="inq-balance">
              <Row style={{ display: 'flex', alignItems: 'center' }}>
                <Col lg={2} xs={2} md={2}>
                  <Image src="/img/sc.png" roundedCircle />
                </Col>
                <Col lg={5} xs={5} md={5}>
                  Saldo Speedcash
                </Col>
                <Col lg={5} xs={5} md={5} className="inq-end-label txt-bold">
                  Rp {Formatter.price(Math.round(parseFloat(balance)))}
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="justify-content-md-center inq-body-last">
            <Form className="form-pelanggan">
              <Form.Group>
                <Form.Label
                  className="form-label text-center color-primary txt-16 txt-bold"
                  style={{ width: '100%' }}
                >
                  Masukkan PIN
                </Form.Label>
                <InputGroup className="mb-12">
                  <Form.Control
                    type={isShowPin ? 'text' : 'password'}
                    className="text-center"
                    maxLength={6}
                    value={pin}
                    onChange={onUserPinChange}
                  />
                  <InputGroup.Append className="pointing" style={{ alignItems: 'center' }}>
                    <FontAwesomeIcon
                      icon={isShowPin ? 'eye-slash' : 'eye'}
                      data-target="pin"
                      onClick={showPin}
                    />
                  </InputGroup.Append>
                </InputGroup>
              </Form.Group>
            </Form>
          </Row>
        </Card>
      </Modal.Body>
      <Modal.Footer style={{ position: 'sticky', bottom: 0 }}>
        <Button className="button-modal" type="button" block onClick={onConfirm}>
          {appIsLoading && (
            <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
          )}
          {appIsLoading ? ' Mohon Tunggu...' : appUserId ? 'Transfer Sekarang' : 'Daftar / Login'}
        </Button>
      </Modal.Footer>
      <Alert
        variant="danger"
        show={!!alertError.rd}
        onClose={closeError}
        transition={null}
        dismissible
      >
        {alertError.rd}
      </Alert>
    </Modal>
  )
}

TransferSaldoModal.defaultProps = {
  appIsLoading: false,
  appUserId: '',
  onHide: null,
  onConfirm: null,
  show: false,
  dataRes: '',
  customer: '',
  nominal: '0',
  balance: 0,
  berita: '',
  pin: '',
  onUserPinChange: null,
  showPin: null,
  isShowPin: false,
}

TransferSaldoModal.propTypes = {
  appIsLoading: PropTypes.bool,
  appUserId: PropTypes.string,
  onHide: PropTypes.func,
  onConfirm: PropTypes.func,
  show: PropTypes.bool,
  dataRes: PropTypes.string,
  customer: PropTypes.string,
  nominal: PropTypes.string,
  balance: PropTypes.number,
  berita: PropTypes.string,
  pin: PropTypes.string,
  onUserPinChange: PropTypes.func,
  showPin: PropTypes.func,
  isShowPin: PropTypes.bool,
}

export default connect(
  (state) => ({
    appIsLoading: state.Global[globalConstant.IS_LOADING],
    appUserId: state.Auth[authConstant.USER_ID],
  }),
  (dispatch) => ({ dispatch })
)(TransferSaldoModal)
