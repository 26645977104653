/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */
import React from 'react'
import { connect } from 'react-redux'
import { Row, Col, Container, Jumbotron, Button, Card } from 'react-bootstrap'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { isMobile } from 'react-device-detect'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import { useHistory, useLocation } from 'react-router-dom'
import 'react-popup-alert/dist/index.css'
import DOMHelper from '../../../helper/domhelper'
import HookHelper from '../../../helper/hookhelper'
import DateTime from '../../../helper/datetime'
import Formatter from '../../../helper/formatter'

import './fm.scss'
import authConstant from '../../../store/constant/auth'
import SidebarComponent from '../../component/sidebar'
import HeaderComponent from '../../component/header'
import LoginModal from '../../dialog/auth/login'

import {
  commissionFm,
  checkDisbursement,
  processDisbursement,
} from '../../../api/action/fm/dashboard'
import 'bootstrap-daterangepicker/daterangepicker.css'
import PencairanKomisiModal from '../../dialog/fm/pencairanKomisi'

const PencairanFmScreen = ({ appUserId, appUserPin, appUserBalance, appUserIsEmbed, dispatch }) => {
  const history = useHistory()
  const location = useLocation()
  const isMounted = React.useRef(false)
  const [userPin] = React.useState(
    !appUserId ? process.env.REACT_APP_USER_DEMO_PASSWORD : appUserPin
  )
  const [manualUserPin, setManualUserPin] = React.useState('')
  const [isOpen, setIsOpen] = React.useState(!isMobile)
  const [alertError, setAlertError] = React.useState('')
  const [loginModalShow, setLoginModalShow] = React.useState(false)
  const [pencairanModalShow, setPencairanModalShow] = React.useState(false)
  const [commission, setCommission] = React.useState(0)
  const [commissionCheck, setCommissionCheck] = React.useState(0)
  const [nominal, setNominal] = React.useState('0')
  const [isShowPin, setIsShowPin] = React.useState(false)
  const [startTgl, setStartTgl] = React.useState(
    location.state
      ? location.state.startTgl
        ? location.state.startTgl
        : DateTime.startOfMonth(DateTime.FMT_DATE_YMD)
      : DateTime.startOfMonth(DateTime.FMT_DATE_YMD)
  )
  const [endTgl, setEndTgl] = React.useState(
    location.state
      ? location.state.endTgl
        ? location.state.endTgl
        : DateTime.endOfMonth(DateTime.FMT_DATE_YMD)
      : DateTime.endOfMonth(DateTime.FMT_DATE_YMD)
  )
  const toggleSidebar = React.useCallback(() => setIsOpen(!isOpen), [isOpen, setIsOpen])
  const closeError = React.useCallback(() => setAlertError(''), [])

  const openLoginModal = React.useCallback(async () => {
    setLoginModalShow(true)
  }, [])

  const confirmLoginModal = React.useCallback(async () => {
    setLoginModalShow(false)
  }, [])

  const closeLoginModal = React.useCallback(async () => {
    setLoginModalShow('')
    history.push('/')
  }, [history])

  const openPencairanModal = React.useCallback(async () => {
    setPencairanModalShow(true)
  }, [])

  const closePencairanModal = React.useCallback(async () => {
    setPencairanModalShow(false)
  }, [])

  const confirmPencairan = React.useCallback(async () => {
    const userCommissionCheck = Math.round(parseFloat(commissionCheck))
    setAlertError('')

    if (!nominal || parseInt(nominal, 10) <= 0) {
      setAlertError({ rc: '', rd: 'Nominal harus diatas 0' })
    } else if (parseInt(nominal, 10) > userCommissionCheck) {
      setAlertError({
        rc: '06',
        rd: 'Komisi tersedia anda tidak cukup untuk melakukan pencairan ini.',
      })
    } else if (!manualUserPin) {
      setAlertError({ rc: '', rd: 'Masukkan PIN Terlebih Dahulu' })
    } else {
      const response = await dispatch(processDisbursement(appUserId, manualUserPin, nominal))

      if (response.isOK()) {
        history.push({
          pathname: '/struk',
          state: {
            parent: 'fm/pencairan_fm',
            parentPath: location.pathname,
            title: 'Pencairan Komisi FM',
            icon: 'university',
            transaksi: response.data.includes.reff_id,
            trxDate: response.data.transmission_datetime,
            trxTime: response.data.transmission_datetime,
            total: Number(response.data.additional_datas[0]),
            customerId: '',
            info: 'sukses',
          },
        })
      } else if (
        response.getRC() === '' ||
        response.getRC() === '68' ||
        response.getRC().toString() === response.data.includes.reff_id.toString()
      ) {
        history.push({
          pathname: '/struk',
          state: {
            parent: 'fm/pencairan_fm',
            parentPath: location.pathname,
            title: 'Pencairan Komisi FM',
            icon: 'university',
            transaksi: response.data.includes.reff_id,
            trxDate: response.data.transmission_datetime,
            trxTime: response.data.transmission_datetime,
            total: Number(response.data.additional_datas[0]),
            customerId: '',
            info: 'pending',
          },
        })
      } else {
        setAlertError({ rc: response.getResponseCode(), rd: response.getRD() })
      }
    }
  }, [dispatch, commissionCheck, nominal, manualUserPin, appUserId, history, location.pathname])

  const onUserPinChange = React.useCallback(
    (e) => {
      const { value } = e.target
      setManualUserPin(Formatter.number(value))
    },
    [setManualUserPin]
  )

  const onNominalChange = React.useCallback(
    (e) => {
      const { value } = e.target
      setNominal(Formatter.number(value))
    },
    [setNominal]
  )

  const showPin = React.useCallback(async () => {
    setIsShowPin((val) => !val)
  }, [setIsShowPin])

  const processData = React.useCallback(async () => {
    const response = await dispatch(commissionFm(appUserId, startTgl, endTgl))

    if (response.isOK()) {
      const { total_komisi: totalCommission } = response.getResultAsObject()

      setCommission(totalCommission)
    }
  }, [dispatch, appUserId, startTgl, endTgl, setCommission])

  const disbursementCheck = React.useCallback(async () => {
    const response = await dispatch(checkDisbursement(appUserId, userPin))

    if (response.isOK()) {
      const { sisa_cashback: checkCommission } = response.getData()

      setCommissionCheck(checkCommission)
    }
  }, [dispatch, appUserId, userPin, setCommissionCheck])

  const handleApply = React.useCallback(async () => {
    await processData()
  }, [processData])

  const handleCallback = React.useCallback(
    (start, end) => {
      setStartTgl(moment(start).format('YYYY-MM-DD'))
      setEndTgl(moment(end).format('YYYY-MM-DD'))
    },
    [setStartTgl, setEndTgl]
  )

  React.useEffect(() => {
    DOMHelper.mountClass('fm-layout')
    HookHelper.resetLoading()

    if (!appUserId) setLoginModalShow(true)

    if (appUserId && !isMounted.current) {
      isMounted.current = true
      processData()
      disbursementCheck()
    }

    return () => DOMHelper.unmountClass(['fm-layout'])
  }, [appUserId, processData, dispatch, disbursementCheck])

  return (
    <Container fluid className="fm-layout">
      {!appUserIsEmbed && (
        <Row className="header">
          <Col>
            <HeaderComponent title="Pencairan Komisi" onToggle={toggleSidebar} />
          </Col>
        </Row>
      )}

      <Row>
        {!appUserIsEmbed && (
          <Col
            md={2}
            className={classNames('col-sidebar', { 'is-open': isOpen, 'is-mobile': isMobile })}
          >
            <SidebarComponent isOpen={isOpen} />
          </Col>
        )}

        <Col
          md={{
            span: appUserIsEmbed ? 12 : isOpen ? 10 : 12,
            offset: appUserIsEmbed ? 0 : isOpen ? 2 : 0,
          }}
        >
          <Jumbotron fluid className="new-banner laporan">
            <Row>
              <Col>
                <Row>
                  <span className={classNames('banner-title', { 'is-mobile': isMobile })}>
                    <FontAwesomeIcon icon="home" fixedWidth className="mr-3" />
                    {`Pencairan Komisi ${location.state ? location.state.title : ''}`}
                  </span>
                </Row>
              </Col>

              {!appUserId ? (
                <Col lg={3} xs={12} md={3} style={{ margin: 'auto' }}>
                  <Button className="button-login" type="button" onClick={openLoginModal}>
                    Daftar / Login
                  </Button>
                </Col>
              ) : (
                <Col lg={3} xs={12} md={3} style={{ margin: 'auto' }}>
                  <Row className="row-saldo">
                    <Col lg={10} xs={10} md={10}>
                      <div className="banner-saldo">Saldo Anda</div>
                      <div className="banner-nominal">
                        Rp. {Formatter.price(Math.round(parseFloat(appUserBalance)))}
                      </div>
                    </Col>
                    <Col lg={2} xs={2} md={2}>
                      <span className="banner-title">
                        <FontAwesomeIcon icon="wallet" fixedWidth className="mr-3" />
                      </span>
                    </Col>
                  </Row>
                </Col>
              )}
            </Row>
          </Jumbotron>

          {appUserId && (
            <div className="content-fm">
              <div className="layanan-body">
                <Row>
                  <Col
                    xs={12}
                    md={4}
                    lg={4}
                    className={classNames('button-picker', {
                      'mgbtm-5': isMobile,
                    })}
                  >
                    <Row>
                      <Col lg={1} md={1} xs={2} className="calendar-icon">
                        <FontAwesomeIcon icon="calendar" fixedWidth className="mr-3" />
                      </Col>
                      <Col lg={11} md={11} xs={9} className="picker-border">
                        <DateRangePicker
                          minYear={moment().format('YYYY')}
                          startDate={startTgl}
                          endDate={endTgl}
                          onApply={handleApply}
                          onCallback={handleCallback}
                        >
                          <div className="input-group mb-3" style={{ height: '100%' }}>
                            <span
                              className="input-group-text form-picker form-control"
                              id="basic-addon2"
                            >
                              {moment(startTgl).format('LL')} - {moment(endTgl).format('LL')}
                            </span>
                          </div>
                        </DateRangePicker>
                      </Col>
                    </Row>
                  </Col>
                </Row>

                {commission > 0 ? (
                  <>
                    <Row style={{ marginTop: '20px' }}>
                      <Col className="pd-10" md={6} lg={6} xs={12}>
                        <Card>
                          <Card.Body>
                            <Card.Title className="txt-16 txt-bold color-primary">
                              Komisi kamu saat ini
                            </Card.Title>
                            <Row>
                              <Col md={9} lg={9} xs={9} style={{ margin: 'auto' }}>
                                <Card.Text className="color-primary txt-24">
                                  Rp. {Formatter.price(Math.round(parseFloat(commission)))}
                                </Card.Text>
                              </Col>
                              <Col
                                md={3}
                                lg={3}
                                xs={3}
                                className="text-end"
                                style={{ margin: 'auto' }}
                              >
                                <FontAwesomeIcon
                                  icon="dollar-sign"
                                  fixedWidth
                                  className="color-primary txt-48"
                                />
                              </Col>
                            </Row>
                          </Card.Body>
                        </Card>
                      </Col>
                      <Col className="pd-10" md={6} lg={6} xs={12}>
                        <Card>
                          <Card.Body>
                            <Card.Title className="txt-16 txt-bold color-success">
                              Komisi yang bisa dicairkan
                            </Card.Title>
                            <Row>
                              <Col md={9} lg={9} xs={9} style={{ margin: 'auto' }}>
                                <Card.Text className="color-success txt-24">
                                  Rp. {Formatter.price(Math.round(parseFloat(commissionCheck)))}
                                </Card.Text>
                              </Col>
                              <Col
                                md={3}
                                lg={3}
                                xs={3}
                                className="text-end"
                                style={{ margin: 'auto' }}
                              >
                                <FontAwesomeIcon
                                  icon="funnel-dollar"
                                  fixedWidth
                                  className="color-success txt-48"
                                />
                              </Col>
                            </Row>
                          </Card.Body>
                        </Card>
                      </Col>
                    </Row>
                    {commissionCheck > 0 && (
                      <Row style={{ marginTop: '20px' }}>
                        <Col className="pd-10" md={6} lg={6} xs={12}>
                          <Button style={{ fontWeight: 700 }} onClick={openPencairanModal}>
                            <FontAwesomeIcon
                              icon="check-circle"
                              fixedWidth
                              style={{ marginRight: '10px' }}
                            />
                            Cairkan komisi
                          </Button>
                        </Col>
                      </Row>
                    )}
                  </>
                ) : (
                  <div className="card-total">
                    Yahhh komisi kamu masih Rp 0, dapatkan komisi hingga jutaan rupiah dengan hanya
                    ajak teman kamu menggunakan SpeedCash.
                    <span style={{ display: 'block' }}>
                      Yuk share link afiliasi kamu... Buka app SpeedCash &gt; pilih menu Akun &gt;
                      pilih pertemanan &gt; bagikan link afiliasi atau pilih konten yang kamu sukai
                      ke teman-teman kamu.
                    </span>
                  </div>
                )}
              </div>
            </div>
          )}
        </Col>
      </Row>
      <LoginModal show={!!loginModalShow} onHide={closeLoginModal} onConfirm={confirmLoginModal} />
      <PencairanKomisiModal
        show={!!pencairanModalShow}
        onHide={closePencairanModal}
        onConfirm={confirmPencairan}
        pin={manualUserPin}
        nominal={nominal}
        commission={commissionCheck}
        onUserPinChange={onUserPinChange}
        onNominalChange={onNominalChange}
        isShowPin={isShowPin}
        showPin={showPin}
        closeError={closeError}
        alertError={alertError}
      />
    </Container>
  )
}

PencairanFmScreen.defaultProps = {
  appUserId: '',
  appUserPin: '',
  appUserBalance: 0,
  appUserIsEmbed: false,
}

PencairanFmScreen.propTypes = {
  appUserId: PropTypes.string,
  appUserPin: PropTypes.string,
  appUserBalance: PropTypes.number,
  appUserIsEmbed: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
}

export default connect(
  (state) => ({
    appUserId: state.Auth[authConstant.USER_ID],
    appUserPin: state.Auth[authConstant.USER_PIN],
    appUserBalance: state.Auth[authConstant.USER_BALANCE],
    appUserIsEmbed: state.Auth[authConstant.USER_IS_EMBED],
  }),
  (dispatch) => ({ dispatch })
)(PencairanFmScreen)
