const DOMHelper = {
  addBodyClass: (className) => document.body.classList.add(className),

  removeBodyClass: (className) => document.body.classList.remove(className),

  mountClass: (className) => {
    if (className instanceof Array) {
      className.map(DOMHelper.addBodyClass)
    } else if (className) {
      DOMHelper.addBodyClass(className)
    }
  },

  unmountClass: (className) => {
    if (className instanceof Array) {
      className.map(DOMHelper.removeBodyClass)
    } else if (className) {
      DOMHelper.removeBodyClass(className)
    }
  },
}

export default DOMHelper
