import globalConstant from '../constant/global'

const initialState = {
  [globalConstant.IS_LOADING]: false,
  [globalConstant.PATHMP]: '',
  [globalConstant.SETTING_MENU]: '',
  [globalConstant.KOTA]: '',
  [globalConstant.PROPINSI]: '',
  [globalConstant.COOKIE]: {},
  [globalConstant.CSRF_TOKEN]: '',
}

export default (state = initialState, action) => {
  switch (action.type) {
    case globalConstant.PATHMP:
    case globalConstant.SETTING_MENU:
    case globalConstant.KOTA:
    case globalConstant.PROPINSI:
      return {
        ...state,
        [globalConstant.type]: action.payload,
      }
    case globalConstant.IS_LOADING:
      return {
        ...state,
        [globalConstant.IS_LOADING]: action.payload,
      }
    case globalConstant.COOKIE:
      return {
        ...state,
        [globalConstant.COOKIE]: action.payload,
      }
    case globalConstant.CSRF_TOKEN:
      return {
        ...state,
        [globalConstant.CSRF_TOKEN]: action.payload,
      }
    case globalConstant.GLOBAL:
      return {
        ...state,
        ...action.payload,
      }
    case globalConstant.CLEAR:
      return initialState
    default:
      return state
  }
}
