/* eslint-disable no-nested-ternary */
import _ from 'lodash'
import React from 'react'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import {
  Spinner,
  Form,
  InputGroup,
  Container,
  Jumbotron,
  Row,
  Col,
  Image,
  Button,
} from 'react-bootstrap'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { isMobile } from 'react-device-detect'
import { useHistory, useLocation } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Alert from 'react-popup-alert'
import 'react-popup-alert/dist/index.css'
import DOMHelper from '../../../helper/domhelper'
import Formatter from '../../../helper/formatter'
import HookHelper from '../../../helper/hookhelper'

import './layanan.scss'
import globalConstant from '../../../store/constant/global'
import authConstant from '../../../store/constant/auth'
import SidebarComponent from '../../component/sidebar'
import HeaderComponent from '../../component/header'
import LoginModal from '../../dialog/auth/login'
import TelkomModal from '../../dialog/layanan/telkom'
import LastTrxDialog from '../../dialog/lastTransaction'
import AccordionTelkomComponent from '../../component/accordionTelkom'

import { telkomInq, telkomPay } from '../../../api/action/layanan/telkom'
import { lastTransaction } from '../../../api/action/global'

const TelkomScreen = ({
  appUserId,
  appUserPin,
  appUserBalance,
  appUserIsEmbed,
  appIsLoading,
  dispatch,
}) => {
  const PRODUK = 'SPEEDY'
  const history = useHistory()
  const location = useLocation()
  const [isOpen, setIsOpen] = React.useState(!isMobile)
  const [isInqWeb, setIsInqWeb] = React.useState(false)
  const [alertError, setAlertError] = React.useState('')
  const [loginModalShow, setLoginModalShow] = React.useState(false)
  const [telkomModalShow, setTelkomModalShow] = React.useState(false)
  const [lastTrxShow, setLastTrxShow] = React.useState(false)
  const [userId] = React.useState(!appUserId ? process.env.REACT_APP_USER_DEMO_ID : appUserId)
  const [userPin] = React.useState(
    !appUserId ? process.env.REACT_APP_USER_DEMO_PASSWORD : appUserPin
  )
  const [isSaldoOk, setIsSaldoOk] = React.useState(false)
  const [lastTrx, setLastTransaction] = React.useState([])
  const [lastTrxFiltered, setLastTransactionFiltered] = React.useState([])
  const [search, setSearch] = React.useState('')
  const [voucherQty] = React.useState('0')
  const [customer, setCustomer] = React.useState('')
  const [dataRes, setDataRes] = React.useState(null)

  const toggleSidebar = React.useCallback(() => setIsOpen(!isOpen), [isOpen, setIsOpen])

  const closeError = React.useCallback(() => setAlertError(''), [])

  const openLoginModal = React.useCallback(async () => {
    setLoginModalShow(true)
  }, [])

  const confirmLoginModal = React.useCallback(async () => {
    setLoginModalShow(false)
    // window.location.reload()
  }, [])

  const closeLoginModal = React.useCallback(async () => {
    setLoginModalShow(false)
  }, [])

  const closeTelkomModal = React.useCallback(async () => {
    setTelkomModalShow(false)
  }, [])

  const openLastTransaction = React.useCallback(async () => {
    setLastTrxShow(true)
  }, [setLastTrxShow])

  const closeLastTransaction = React.useCallback(async () => {
    setLastTrxShow(false)
  }, [setLastTrxShow])

  const handleClear = React.useCallback(
    (e) => {
      const { target } = e.currentTarget.dataset
      if (target === 'customer') {
        setCustomer('')
      }

      setIsInqWeb(false)
      setDataRes(null)
    },
    [setCustomer, setIsInqWeb, setDataRes]
  )

  const onCustomerChange = React.useCallback((e) => {
    const { value } = e.target
    setCustomer(Formatter.number(value))
  }, [])

  const onSearchChange = React.useCallback(
    (e) => {
      const { value } = e.target
      const newData = _.filter(lastTrx, (item) => {
        return (
          _.includes(item.id_pelanggan, value) ||
          _.includes(item.nama_pelanggan.toLowerCase(), value.toLowerCase())
        )
      })

      setSearch(value)
      setLastTransactionFiltered(newData)
    },
    [lastTrx, setSearch, setLastTransactionFiltered]
  )

  const confirmLastTrx = React.useCallback(
    (e) => {
      const { target } = e.currentTarget.dataset
      setCustomer(target)
      setLastTrxShow(false)
    },
    [setCustomer, setLastTrxShow]
  )

  const onProcess = React.useCallback(async () => {
    if (!customer) {
      setAlertError({ rc: '', rd: 'Masukkan ID Pelanggan Terlebih Dahulu' })
      return false
    }

    const responseInq = await dispatch(telkomInq(userId, userPin, voucherQty, customer))
    if (responseInq.isOK()) {
      const dataRespon = responseInq.getData()
      const { amount } = dataRespon.includes
      const { admin } = dataRespon.includes
      const total =
        parseInt(amount, 0) + parseInt(admin, 0) + parseInt(dataRespon.additional_datas[93], 10)
      if (Math.round(parseFloat(appUserBalance)) >= total) {
        setIsSaldoOk(true)
      }

      setDataRes(dataRespon)

      if (isMobile) {
        setTelkomModalShow(true)
      } else {
        setIsInqWeb(true)
      }
    } else {
      setAlertError({ rc: responseInq.getRC(), rd: responseInq.getRD() })
    }

    return true
  }, [
    dispatch,
    appUserBalance,
    userId,
    userPin,
    voucherQty,
    customer,
    setIsInqWeb,
    setIsSaldoOk,
    setAlertError,
  ])

  const redirectToDeposit = React.useCallback(() => {
    history.push('/deposit')
  }, [history])

  const confirmTelkom = React.useCallback(async () => {
    if (!appUserId) {
      setTelkomModalShow(false)
      setLoginModalShow(true)
    } else if (isSaldoOk) {
      const dataIncludes = dataRes.includes
      const { amount, admin } = dataIncludes
      const reffId = dataIncludes.reff_id

      const responsePay = await dispatch(
        telkomPay(userId, userPin, voucherQty, customer, amount, admin, reffId)
      )
      if (responsePay.isOK()) {
        history.push({
          pathname: '/struk',
          state: {
            parent: 'telkom',
            parentPath: location.pathname,
            title: 'Telkom & Indihome',
            icon: 'phone-square-alt',
            transaksi: responsePay.data.includes.reff_id,
            trxDate: responsePay.data.transmission_datetime,
            trxTime: responsePay.data.transmission_datetime,
            total:
              Number(responsePay.data.includes.amount) +
              Number(responsePay.data.includes.admin) +
              parseInt(responsePay.data.additional_datas[93], 10),
            customerId: responsePay.data.includes.customer_id_1,
            discountInfo: responsePay.data.discountInfo,
            info: 'sukses',
          },
        })
      } else if (
        responsePay.getResponseCode() === '' ||
        responsePay.getRC() === '68' ||
        responsePay.getRC().toString() === responsePay.data.includes.reff_id.toString()
      ) {
        history.push({
          pathname: '/struk',
          state: {
            parent: 'telkom',
            parentPath: location.pathname,
            title: 'Telkom & Indihome',
            icon: 'phone-square-alt',
            transaksi: responsePay.data.includes.reff_id,
            trxDate: responsePay.data.transmission_datetime,
            trxTime: responsePay.data.transmission_datetime,
            total:
              Number(responsePay.data.includes.amount) +
              Number(responsePay.data.includes.admin) +
              parseInt(responsePay.data.additional_datas[93], 10),
            customerId: responsePay.data.includes.customer_id_1,
            discountInfo: responsePay.data.discountInfo,
            info: 'pending',
          },
        })
      } else {
        setAlertError({ rc: responsePay.getRC(), rd: responsePay.getRD() })
      }
    } else {
      redirectToDeposit()
    }
  }, [
    dispatch,
    history,
    isSaldoOk,
    appUserId,
    location,
    userId,
    userPin,
    voucherQty,
    customer,
    dataRes,
    setAlertError,
    redirectToDeposit,
  ])

  React.useEffect(() => {
    DOMHelper.mountClass('layanan-layout')
    HookHelper.resetLoading()

    dispatch(lastTransaction(userId, PRODUK, '0')).then((response) => {
      if (response.isOK()) {
        setLastTransaction(response.getResultAsList())
        setLastTransactionFiltered(response.getResultAsList())
      }
    })

    return () => DOMHelper.unmountClass(['layanan-layout'])
  }, [dispatch, userId])

  return (
    <>
      <Helmet>
        <title>Cek Tagihan Indihome Dan Bayar Online Telkomsel Pasti Diskon</title>
        <meta name="title" content="Cek Tagihan Indihome Dan Bayar Online Telkomsel Pasti Diskon" />
        <meta
          name="description"
          content="Cek tagihan wifi indihome.Bayar tagihan telkomsel online langsung disini pasti dapat diskon.Yuk cek tagihan indihome bulan ini sekarang juga.Cek sekarang juga."
        />
        <meta name="google-play-app" content="app-id=com.bm.sc.bebasbayar" />
        <meta
          name="_globalsign-domain-verification"
          content="VncJWgSU_cQw-hAuU0PELvI3g4HQ4l6XFSs9v-jA1Y"
        />
        <meta name="facebook-domain-verification" content="pknnsi25vbw0rfl1x8xctdtumrh3bs" />
        <meta name="msvalidate.01" content="9A9127824837BF1E05F5AED70295F281" />
        <meta name="alexaVerifyID" content="Nc8Q89T9GsncVlA5xukQcbi5rJ0" />
        <meta httpEquiv="X-UA-Compatible" content="IE=Edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="author" content="https://www.facebook.com/SpeedCash.BebasBayarID" />
        <link rel="canonical" href="https://member.speedcash.co.id/telkom" />
        <meta
          name="robots"
          content="follow, index, max-snippet:-1, max-video-preview:-1, max-image-preview:large"
        />
        <meta name="geo.country" content="id" />
        <meta name="revisit-after" content="1 days" />
        <meta name="geo.placename" content="Indonesia" />
        <meta content="id_id" property="og:locale" />
        <meta
          itemProp="name"
          content="Cek Tagihan Indihome Dan Bayar Online Telkomsel Pasti Diskon"
        />
        <meta
          itemProp="description"
          content="Cek tagihan wifi indihome.Bayar tagihan telkomsel online langsung disini pasti dapat diskon.Yuk cek tagihan indihome bulan ini sekarang juga.Cek sekarang juga."
        />
        <meta
          itemProp="image"
          content="https://blog.speedcash.co.id/wp-content/uploads/2024/03/diskon-bayar-tagihan-indihome.png"
        />
        <meta property="fb:app_id" content="966242223397117" />
        <meta
          property="og:title"
          content="Cek Tagihan Indihome Dan Bayar Online Telkomsel Pasti Diskon"
        />
        <meta property="og:type" content="article" />
        <meta
          property="og:image"
          content="https://www.speedcash.co.id/images/speedcash-logo-square.jpg"
        />
        <meta
          property="og:description"
          content="Cek tagihan wifi indihome.Bayar tagihan telkomsel online langsung disini pasti dapat diskon.Yuk cek tagihan indihome bulan ini sekarang juga.Cek sekarang juga."
        />
        <meta property="og:site_name" content="SpeedCash" />
        <meta property="og:url" content="https://www.speedcash.co.id/" />
        <meta
          property="article:tag"
          content="Cek tagihan wifi indihome.Bayar tagihan telkomsel online langsung disini pasti dapat diskon.Yuk cek tagihan indihome bulan ini sekarang juga.Cek sekarang juga."
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@SpeedcashO" />
        <meta
          name="twitter:title"
          content="Cek Tagihan Indihome Dan Bayar Online Telkomsel Pasti Diskon"
        />
        <meta
          name="twitter:description"
          content="Cek tagihan wifi indihome.Bayar tagihan telkomsel online langsung disini pasti dapat diskon.Yuk cek tagihan indihome bulan ini sekarang juga.Cek sekarang juga."
        />
        <meta name="twitter:creator" content="@SpeedcashO" />
        <meta
          name="twitter:image"
          content="https://www.speedcash.co.id/images/speedcash-logo-square.jpg"
        />
        <meta
          name="twitter:image:src"
          content="https://www.speedcash.co.id/images/speedcash-logo-square.jpg"
        />
        <meta name="twitter:label1" content="Deskripsi" />
        <meta
          name="twitter:data1"
          content="Cek tagihan wifi indihome.Bayar tagihan telkomsel online langsung disini pasti dapat diskon.Yuk cek tagihan indihome bulan ini sekarang juga.Cek sekarang juga."
        />
        <meta name="twitter:label2" content="Tag" />
        <meta
          name="twitter:data2"
          content="Cek tagihan wifi indihome.Bayar tagihan telkomsel online langsung disini pasti dapat diskon.Yuk cek tagihan indihome bulan ini sekarang juga.Cek sekarang juga."
        />

        <script type="application/ld+json">
          {`{
          "@context":"https://schema.org",
          "@graph":[{
          "@type":"Organization",
          "@id":"https://member.speedcash.co.id/#organization",
          "name":"PT Bimasakti Multi Sinergi",
          "url":"https://member.speedcash.co.id",
          "sameAs":[
          "https://www.facebook.com/SpeedCash.BebasBayarID/",
          "https://www.instagram.com/speedcash.official/",
          "https://www.linkedin.com/in/aplikasi-bebasbayar-864aa7195/",
          "https://www.youtube.com/c/SpeedCashIDOfficial",
          "https://id.pinterest.com/bebasbayarofficial/",
          "https://www.tiktok.com/@speedcash.official",
          "https://twitter.com/SpeedcashO"],
          "logo":{
          "@type":"ImageObject",
          "@id":"https://member.speedcash.co.id/#logo",
          "url":"https://member.speedcash.co.id/img/speedcash.png",
          "width":240,"height":72,"caption":"SpeedCash.co.id"},
          "image":{
          "@id":"https://member.speedcash.co.id/#logo"}
          },{
          "@type":"WebSite",
          "@id":"https://member.speedcash.co.id/#website",
          "url":"https://member.speedcash.co.id/",
          "name":"SpeedCash",
          "publisher":{
          "@id":"https://member.speedcash.co.id/#organization"},
          "potentialAction":{
          "@type":"SearchAction",
          "target":"https://member.speedcash.co.id/?s={search_term_string}",
          "query-input":"required name=search_term_string"}
          },{
          "@type":"ImageObject",
          "@id":"https://member.speedcash.co.id/#primaryimage",
          "url":"https://member.speedcash.co.id/img/speedcash.png",
          "width":764,"height":401,
          "caption":"Cek Tagihan Indihome Dan Bayar Online Telkomsel Pasti Diskon"
          },{
          "@type":"WebPage","@id":"https://member.speedcash.co.id/#webpage",
          "url":"https://member.speedcash.co.id/","inLanguage":"ID",
          "name":"Cek Tagihan Indihome Dan Bayar Online Telkomsel Pasti Diskon",
          "isPartOf":{
          "@id":"https://member.speedcash.co.id/#website"},
          "about":{"@id":"https://member.speedcash.co.id/#organization"},
          "primaryImageOfPage":{"@id":"https://member.speedcash.co.id/#primaryimage"},
          "datePublished":"2024-02-20T03:08:35+00:00","dateModified":"2024-02-20T04:54:34+00:00",
          "description":"Cek tagihan wifi indihome.Bayar tagihan telkomsel online langsung disini pasti dapat diskon.Yuk cek tagihan indihome bulan ini sekarang juga.Cek sekarang juga."
          }]
          }`}
        </script>

        {/* Google tag (gtag.js) */}
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-X7L58XGGSH" />
        <script>
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', 'G-X7L58XGGSH');
          `}
        </script>
      </Helmet>
      <Container fluid className="layanan-layout">
        {!appUserIsEmbed && (
          <Row className="header">
            <Col>
              <HeaderComponent title="Telkom & Indihome" onToggle={toggleSidebar} />
            </Col>
          </Row>
        )}

        <Row>
          {!appUserIsEmbed && (
            <Col
              md={2}
              className={classNames('col-sidebar', { 'is-open': isOpen, 'is-mobile': isMobile })}
            >
              <SidebarComponent isOpen={isOpen} />
            </Col>
          )}

          <Col
            md={{
              span: appUserIsEmbed ? 12 : isOpen ? 10 : 12,
              offset: appUserIsEmbed ? 0 : isOpen ? 2 : 0,
            }}
          >
            <Jumbotron fluid className="banner telkom">
              <Row>
                <Col lg={9} xs={12} md={9} style={{ margin: 'auto' }}>
                  <Row>
                    <span className={classNames('banner-title', { 'is-mobile': isMobile })}>
                      <FontAwesomeIcon icon="phone-square-alt" fixedWidth className="mr-3" />
                      Telkom & Indihome
                    </span>
                  </Row>
                </Col>

                {!appUserId ? (
                  <Col lg={3} xs={12} md={3} style={{ margin: 'auto' }}>
                    <Button
                      className={classNames('button-login', { 'is-mobile': isMobile })}
                      type="button"
                      onClick={openLoginModal}
                    >
                      Daftar / Login
                    </Button>
                  </Col>
                ) : (
                  <Col lg={3} xs={12} md={3} style={{ margin: 'auto' }}>
                    <Row>
                      <Col lg={10} xs={10} md={10}>
                        <div className="banner-saldo">Saldo Anda</div>
                        <div className="banner-nominal">
                          Rp. {Formatter.price(Math.round(parseFloat(appUserBalance)))}
                        </div>
                      </Col>
                      <Col lg={2} xs={2} md={2}>
                        <span className="banner-title">
                          <FontAwesomeIcon icon="wallet" fixedWidth className="mr-3" />
                        </span>
                      </Col>
                    </Row>
                  </Col>
                )}
              </Row>
            </Jumbotron>

            <Col className="d-flex justify-content-center mb-3 p-3">
              <h2 className="text-dark text-justify">
                Cek Tagihan Indihome Dan Bayar Online Disini Langsung Dapat Diskon
              </h2>
            </Col>

            <div className="content-layanan">
              <Row className="justify-content-md-center">
                <Col lg={isInqWeb ? 7 : 8} xs={12} md={isInqWeb ? 7 : 8} className="bd-form">
                  <Form className="form-pelanggan">
                    <Form.Group>
                      <Form.Label className="form-label start">ID Pelanggan</Form.Label>
                      <InputGroup>
                        <Form.Control
                          placeholder="Masukkan Polis/ID Pelanggan"
                          name="customer"
                          value={customer}
                          onChange={onCustomerChange}
                          disabled={isInqWeb || telkomModalShow}
                        />
                        <InputGroup.Append className="flex align-items-center">
                          <Image
                            className={classNames('form-icon-btn', { 'ic-mobile': isMobile })}
                            src="/icon/clear.png"
                            data-target="customer"
                            onClick={handleClear}
                          />
                        </InputGroup.Append>
                      </InputGroup>
                    </Form.Group>
                  </Form>
                  <Row className="row-proses">
                    <Col style={{ margin: 'auto' }}>
                      <Button
                        className="btn-process"
                        size="lg"
                        block
                        onClick={onProcess}
                        disabled={appIsLoading}
                      >
                        {appIsLoading && !isMobile && (
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                        )}
                        {appIsLoading && !isMobile ? ' Mohon Tunggu...' : 'Cek Tagihan Sekarang'}
                      </Button>
                    </Col>
                  </Row>
                  {lastTrx.length > 0 && (
                    <div className="form-last-trx">
                      <span className="form-label">Transaksi Terakhir</span>
                      <Row
                        style={{
                          border: '1px solid #DDDDDD',
                          borderRadius: '10px',
                          alignItems: 'center',
                          padding: '10px',
                        }}
                      >
                        <Col lg={1} xs={2} md={1}>
                          <Image src={lastTrx[0].image_url} height={40} />
                        </Col>
                        <Col
                          lg={8}
                          xs={10}
                          md={8}
                          data-target={lastTrx[0].id_pelanggan}
                          onClick={confirmLastTrx}
                          style={{ cursor: 'pointer' }}
                        >
                          <Row>
                            <span className="form-label-main">{lastTrx[0].id_pelanggan}</span>
                          </Row>
                          <Row>
                            <span className="label-text">{lastTrx[0].nama_pelanggan}</span>
                          </Row>
                        </Col>
                        <Col lg={3} xs={8} md={3} onClick={openLastTransaction}>
                          <div className="label-text color-primary" style={{ cursor: 'pointer' }}>
                            Lihat riwayat transaksi
                          </div>
                        </Col>
                      </Row>
                    </div>
                  )}
                </Col>
                {isInqWeb && (
                  <>
                    <Col md="auto" />
                    <Col lg={4} xs={12} md={4} className="bd-form-inq">
                      <Row className="justify-content-md-center">
                        <Col className="inq-header txt-bold">Tagihan Telkom/Indihome Anda</Col>
                      </Row>
                      {dataRes &&
                        dataRes.strukMaps.map((val) => (
                          <Row key={Formatter.upperFirst(Object.keys(val)[0])}>
                            <Col>{Formatter.upperFirst(Object.keys(val)[0])}</Col>
                            <Col className="inq-end-label">
                              {val ? val[Object.keys(val)[0]] : ''}
                            </Col>
                          </Row>
                        ))}
                      <Row className="justify-content-md-center inq-body-last">
                        <Col className="inq-balance">
                          <Row style={{ display: 'flex', alignItems: 'center' }}>
                            <Col lg={2} xs={2} md={2}>
                              <Image src="/img/sc.png" roundedCircle />
                            </Col>
                            <Col lg={5} xs={5} md={5}>
                              Saldo Speedcash
                            </Col>
                            <Col lg={5} xs={5} md={5} className="inq-end-label txt-bold">
                              {dataRes
                                ? `Rp ${Formatter.price(
                                    Math.round(parseFloat(dataRes.includes.balance))
                                  )}`
                                : 'Rp 0,-'}
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      {!isSaldoOk && appUserId && (
                        <Row className="justify-content-md-center inq-body-last">
                          <Col className="inq-balance not-ok">
                            <Row className="txt-bold">
                              Saldo Anda belum mencukupi untuk transaksi ini. Segera Top Up
                            </Row>
                          </Col>
                        </Row>
                      )}
                      <Row className="justify-content-md-center">
                        <Col>
                          <Button
                            className="btn-process"
                            type="button"
                            block
                            onClick={
                              appUserId
                                ? isSaldoOk
                                  ? confirmTelkom
                                  : redirectToDeposit
                                : openLoginModal
                            }
                          >
                            {appIsLoading && (
                              <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                              />
                            )}
                            {appIsLoading
                              ? ' Mohon Tunggu...'
                              : appUserId
                              ? isSaldoOk
                                ? 'Bayar Sekarang'
                                : 'Top Up Saldo'
                              : 'Daftar / Login'}
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                  </>
                )}
              </Row>
            </div>

            <AccordionTelkomComponent />
          </Col>
        </Row>
        <Alert
          header="Perhatian"
          btnText="OK"
          text={alertError.rd}
          type="error"
          show={!!alertError.rd}
          onClosePress={closeError}
          showBorderBottom
          alertStyles={{ justifyContent: 'center' }}
        />
        <LoginModal
          show={!!loginModalShow}
          onHide={closeLoginModal}
          onConfirm={confirmLoginModal}
        />
        <TelkomModal
          show={!!telkomModalShow}
          onHide={closeTelkomModal}
          onConfirm={confirmTelkom}
          dataRes={dataRes}
          isSaldoOk={isSaldoOk}
        />
        <LastTrxDialog
          show={!!lastTrxShow}
          onHide={closeLastTransaction}
          onConfirm={confirmLastTrx}
          search={search}
          onSearchChange={onSearchChange}
          data={lastTrxFiltered}
        />
      </Container>
    </>
  )
}

TelkomScreen.defaultProps = {
  appIsLoading: false,
  appUserId: '',
  appUserPin: '',
  appUserBalance: 0,
  appUserIsEmbed: false,
}

TelkomScreen.propTypes = {
  appUserId: PropTypes.string,
  appUserPin: PropTypes.string,
  appUserBalance: PropTypes.number,
  appUserIsEmbed: PropTypes.bool,
  appIsLoading: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
}

export default connect(
  (state) => ({
    appIsLoading: state.Global[globalConstant.IS_LOADING],
    appUserId: state.Auth[authConstant.USER_ID],
    appUserPin: state.Auth[authConstant.USER_PIN],
    appUserBalance: state.Auth[authConstant.USER_BALANCE],
    appUserIsEmbed: state.Auth[authConstant.USER_IS_EMBED],
  }),
  (dispatch) => ({ dispatch })
)(TelkomScreen)
