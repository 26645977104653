import LayananMessage from '../../message/mp/layanan'
import MessageSender from '../../sender'
import authConstant from '../../../store/constant/auth'

export const telkomInq = (outletId, pin, voucherQty, customer) => async (dispatch) => {
  const msg = new LayananMessage()
  msg.setProcessingCode('INQ')
  msg.setOutletID(outletId)
  msg.setPIN(pin)
  msg.data.includes.product_code = 'SPEEDY'
  msg.data.includes.voucher_group = 'TELKOM'
  msg.data.includes.voucher_qty = voucherQty
  msg.data.includes.customer_id_1 = customer
  msg.setAdditionalDatum(0, customer)
  msg.setAdditionalDatum(9, customer)

  msg.data.campaign.utm_user_id = outletId

  const response = await new MessageSender().doPost(msg)
  const msg2 = new LayananMessage(response)

  if (msg2.isOK()) {
    await dispatch({
      type: authConstant.USER_BALANCE,
      payload: Number(msg2.data.includes.balance),
    })
  }

  return msg2
}

export const telkomPay =
  (outletId, pin, voucherQty, customer, amount, admin, reffId) => async (dispatch) => {
    const msg = new LayananMessage()
    msg.setProcessingCode('PAY')
    msg.setOutletID(outletId)
    msg.setPIN(pin)
    msg.data.includes.product_code = 'SPEEDY'
    msg.data.includes.voucher_group = 'TELKOM'
    msg.data.includes.voucher_qty = voucherQty
    msg.data.includes.customer_id_1 = customer
    msg.data.includes.amount = amount
    msg.data.includes.admin = admin
    msg.data.includes.reff_id = reffId
    msg.setAdditionalDatum(0, customer)
    msg.setAdditionalDatum(9, customer)

    msg.data.campaign.utm_user_id = outletId

    const response = await new MessageSender().doPost(msg)
    const msg2 = new LayananMessage(response)

    if (msg2.isOK()) {
      await dispatch({
        type: authConstant.USER_BALANCE,
        payload: Number(msg2.data.includes.balance),
      })
    }

    return msg2
  }
