import BaseMessage from '../base'

export default class FmMessage extends BaseMessage {
  constructor(data) {
    super(data)

    if (!data) {
      this.data.limit = '10'
      this.data.offset = '0'
      this.data.appid = 'SPEEDCASH'
      this.data.location = '0,0'
    }
  }

  setPathCheck() {
    this.setPath(`${FmMessage.MP_NODE}/dashboard/fm/check`)
  }

  setPathReport() {
    this.setPath(`${FmMessage.MP_NODE}/dashboard/fm/report`)
  }

  setPathCommission() {
    this.setPath(`${FmMessage.MP_NODE}/dashboard/fm/commission`)
  }

  setPathCompanion() {
    this.setPath(`${FmMessage.MP_NODE}/dashboard/fm/companion`)
  }

  setPathFriend() {
    this.setPath(`${FmMessage.MP_NODE}/dashboard/fm/friend`)
  }

  setPathDisbursement() {
    this.setPath(`${FmMessage.MP_NODE}/dashboard/fm/disbursement`)
  }

  setOutletId(idOutlet) {
    this.data.id_outlet = idOutlet
  }

  setStartDate(startDate) {
    this.data.start_date = startDate
  }

  setEndDate(endDate) {
    this.data.end_date = endDate
  }

  setUserId(userId) {
    this.data.id = userId
  }

  setLimit(limit) {
    this.data.limit = limit
  }

  setOffset(offset) {
    this.data.offset = offset
  }
}
