import React from 'react'
import { connect } from 'react-redux'
import { Spinner, Row, Col, Image, Form, InputGroup, Button } from 'react-bootstrap'
import { isMobile } from 'react-device-detect'
import Alert from 'react-popup-alert'
import 'react-popup-alert/dist/index.css'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import DOMHelper from '../../../helper/domhelper'
import Formatter from '../../../helper/formatter'
import HookHelper from '../../../helper/hookhelper'

import './deposit.scss'
import globalConstant from '../../../store/constant/global'
import authConstant from '../../../store/constant/auth'

import DepositBankModal from '../../dialog/deposit/bank'
import { depositInq } from '../../../api/action/deposit/deposit'

const BankScreen = ({ appUserId, appUserPin, appIsLoading, dispatch }) => {
  const DEP_BCA = 'BCA'
  const DEP_BRI = 'BRI'
  const DEP_BNI = 'BNI'
  const DEP_MANDIRI = 'MANDIRI'

  const [alertError, setAlertError] = React.useState('')
  const [depositBankModalShow, setDepositBankModalShow] = React.useState(false)
  const [bank, setBank] = React.useState('')
  const [saldo, setSaldo] = React.useState('')
  const [saldoLabel, setSaldoLabel] = React.useState('')
  const [dataResDeposit, setDataResDeposit] = React.useState(null)

  const closeError = React.useCallback(() => setAlertError(''), [])

  const closeDepositBankModal = React.useCallback(async () => {
    setDepositBankModalShow(false)
    // window.location.reload()
  }, [])

  const onSaldoChange = React.useCallback(
    (e) => {
      const { value } = e.target
      setSaldo(value)
      setSaldoLabel(Formatter.price(value.replace(/[^A-Z0-9]/gi, '')))
    },
    [setSaldo, setSaldoLabel]
  )

  const selectBank = React.useCallback(
    (e) => {
      const { value } = e.currentTarget.dataset
      setBank(value)
    },
    [setBank]
  )

  const processDeposit = React.useCallback(async () => {
    if (!saldo) {
      setAlertError({ rc: '', rd: 'Nominal saldo harus lebih dari 0' })
      return false
    }

    const responseInq = await dispatch(depositInq(appUserId, appUserPin, bank, saldo))
    if (responseInq.isOK()) {
      setDataResDeposit(responseInq.getData())
      setDepositBankModalShow(true)
    } else {
      setAlertError({ rc: responseInq.getRC(), rd: responseInq.getRD() })
    }

    return true
  }, [dispatch, appUserId, appUserPin, bank, saldo, setAlertError])

  React.useEffect(() => {
    DOMHelper.mountClass('form-ct-body')
    HookHelper.resetLoading()

    return () => DOMHelper.unmountClass(['form-ct-body'])
  }, [])

  return (
    <>
      <div className="form-ct-body tb">
        <div style={{ width: '100%', paddingTop: '20px' }}>
          {!bank ? (
            <span className="ct-body-label">
              Pilih Bank yang akan Anda gunakan untuk Isi Saldo SpeedCash
            </span>
          ) : (
            <>
              <Form>
                <Form.Group controlId="formSaldo">
                  <Form.Label className="label-bold">
                    Nominal saldo yang ingin di deposit
                  </Form.Label>
                  <InputGroup className="mb-3">
                    <InputGroup.Prepend>
                      <InputGroup.Text className="label-bold">Rp.</InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                      placeholder="Masukkan Nominal Saldo"
                      aria-label="saldo"
                      className="label-text txt-bold"
                      name="saldo"
                      value={saldoLabel}
                      onChange={onSaldoChange}
                    />
                  </InputGroup>
                </Form.Group>
              </Form>
              <Row className="row-proses">
                <Col className="nominal-minimal">
                  <span className="label-text primary">Nominal minimal deposit</span>
                  <span className={classNames('nominal-minimal-text', { 'is-mobile': isMobile })}>
                    Rp. 10.000,-
                  </span>
                </Col>
                <Col style={{ display: 'flex' }}>
                  <Button
                    className="btn-next"
                    size="lg"
                    block
                    onClick={processDeposit}
                    disabled={appIsLoading}
                  >
                    {appIsLoading && (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    )}
                    {appIsLoading ? ' Mohon Tunggu...' : 'Isi Saldo Sekarang'}
                  </Button>
                </Col>
              </Row>
            </>
          )}
        </div>

        <Row className="icon-row">
          <Col className="icon-col" data-value={DEP_BRI} onClick={selectBank}>
            <div className={classNames('icon-list', { selected: bank === DEP_BRI })}>
              <Image src="/img/bank/bri.png" rounded />
              <span className="icon-label">Bank BRI</span>
            </div>
          </Col>
          <Col className="icon-col" data-value={DEP_MANDIRI} onClick={selectBank}>
            <div className={classNames('icon-list', { selected: bank === DEP_MANDIRI })}>
              <Image src="/img/bank/mandiri.png" rounded />
              <span className="icon-label">Bank Mandiri</span>
            </div>
          </Col>
        </Row>
        <Row className="icon-row">
          <Col className="icon-col" data-value={DEP_BNI} onClick={selectBank}>
            <div className={classNames('icon-list', { selected: bank === DEP_BNI })}>
              <Image src="/img/bank/bni.png" rounded />
              <span className="icon-label">Bank BNI</span>
            </div>
          </Col>
          <Col className="icon-col" data-value={DEP_BCA} onClick={selectBank}>
            <div className={classNames('icon-list', { selected: bank === DEP_BCA })}>
              <Image src="/img/bank/bca.png" rounded />
              <span className="icon-label">Bank BCA</span>
            </div>
          </Col>
        </Row>
      </div>
      <Alert
        header="Perhatian"
        btnText="OK"
        text={alertError.rd}
        type="error"
        show={!!alertError.rd}
        onClosePress={closeError}
        showBorderBottom
        alertStyles={{ justifyContent: 'center' }}
      />
      <DepositBankModal
        show={!!depositBankModalShow}
        onHide={closeDepositBankModal}
        dataRes={dataResDeposit}
      />
    </>
  )
}

BankScreen.defaultProps = {
  appIsLoading: false,
  appUserId: '',
  appUserPin: '',
}

BankScreen.propTypes = {
  appUserId: PropTypes.string,
  appUserPin: PropTypes.string,
  appIsLoading: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
}

export default connect(
  (state) => ({
    appIsLoading: state.Global[globalConstant.IS_LOADING],
    appUserId: state.Auth[authConstant.USER_ID],
    appUserPin: state.Auth[authConstant.USER_PIN],
  }),
  (dispatch) => ({ dispatch })
)(BankScreen)
