import { isMobile } from 'react-device-detect'
import { v5 as uuidv5 } from 'uuid'
import Var from '../../helper/var'

export default class BaseMessage {
  static MP_PHP = 'blackpink'

  static SC_API = 'twice'

  static MP_NODE = 'red-velvet'

  static BB_WEB = 'weeekly'

  static JSON = 'json'

  static FORM = 'form'

  static PLAIN = 'plain'

  constructor(data) {
    this.data = {
      version_name: process.env.REACT_APP_VERSION,
      version_code: process.env.REACT_APP_VERSION_CODE,
      uuid: uuidv5(navigator.userAgent, uuidv5.URL),
      user_uuid: uuidv5(navigator.userAgent, uuidv5.URL),
      via: isMobile ? 'BB MOBILE WEB' : 'BEBASBAYAR',
      app_id: 'SPEEDCASH',
      appid: 'SPEEDCASH',
    }
    if (data instanceof BaseMessage) {
      this.data = { ...this.data, ...data.getData() }
      this.originalData = data.getOriginalData()
    } else if (typeof data === 'object' && data !== null) {
      this.data = { ...this.data, ...data }
      this.originalData = data
    }
    this.path = ''
    this.header = {
      'time-request': Date.now(),
    }
    this.contentType = BaseMessage.JSON
  }

  setRC(rc) {
    this.data.rc = rc
  }

  getRC() {
    return Var.toString(this.data.rc)
  }

  setResponseCode(rc) {
    this.data.response_code = rc
  }

  getResponseCode() {
    return Var.toString(this.data.response_code)
  }

  isOK() {
    return this.getRC() === '00' || this.getResponseCode() === '00'
  }

  setDescription(rd) {
    this.data.description = rd
  }

  getDescription() {
    return Var.toString(this.data.description)
  }

  setRD(rd) {
    this.data.rd = rd
  }

  getRD() {
    return this.data.rd ? Var.toString(this.data.rd) : Var.toString(this.data.description)
  }

  getResultAsList() {
    return Var.toList(this.data.data)
  }

  getResultAsObject() {
    return Var.toObject(this.data.data)
  }

  getData() {
    return this.data
  }

  getPath() {
    return this.path
  }

  setPath(path) {
    this.path = path
  }

  setError(err) {
    this.data.err = err
  }

  getError() {
    return this.data.err
  }

  setErrorMessage(errMessage) {
    this.data.errMessage = errMessage
  }

  getErrorMessage() {
    return this.data.errMessage
  }

  getContentType() {
    return this.contentType
  }

  setContentType(ct) {
    this.contentType = ct
  }

  getHeader() {
    return this.header
  }

  setHeader(header) {
    this.header = header
  }

  setAsEncrypted() {
    this.header['X-ENC-WITH'] = 1
  }

  getItem(key) {
    return Var.toString(this.data[key])
  }

  setOriginalData(data) {
    this.originalData = data
  }

  getOriginalData() {
    return Var.toObject(this.originalData)
  }

  getNotLiveData() {
    return Var.toString(this.data.external_id)
  }

  getLiveData() {
    const live = this.getOriginalData()
    delete live.external_id
    return Var.toObject(live)
  }

  setAsManualPin() {
    this.header['X-MNL-WITH'] = 1
  }
}
