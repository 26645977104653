import OTPMessage from '../../message/mp/otp'
import MessageSender from '../../sender'

export const checkUser = (phone) => async () => {
  const msg = new OTPMessage()
  msg.setPhone(phone)
  msg.setUserCheckMode()

  const response = await new MessageSender().doPost(msg)

  return new OTPMessage(response)
}

export const requestOtp = (phone, state, type) => async () => {
  const msg = new OTPMessage()
  msg.setPhone(phone)
  msg.setState(state)
  msg.setType(type)
  msg.setRequestMode()

  const response = await new MessageSender().doPost(msg)

  return new OTPMessage(response)
}

export const checkOtp = (phone, token, state, type) => async () => {
  const msg = new OTPMessage()
  msg.setPhone(phone)
  msg.setState(state)
  msg.setType(type)
  msg.setToken(token)
  msg.setOTPCheckMode()

  const response = await new MessageSender().doPost(msg)

  return new OTPMessage(response)
}
