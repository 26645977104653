import React from 'react'
import { Button, Image, Row, Col, Accordion, Card } from 'react-bootstrap'
import './accordionGame.scss'

const AccordionGamePubg = () => {
  return (
    <>
      <Col className="d-flex justify-content-center mt-4 mb-3 p-3">
        <h3 className="text-dark text-justify">
          Disini Top Up UC PUBG Termurah,Cepat Dan Terpercaya
        </h3>
      </Col>

      <div style={{ padding: '0px 20px 30px 20px' }}>
        <Row className="justify-content-md-center">
          <Col lg={8} xs={12} md={8} className="content-game">
            <div>
              <Row className="d-flex justify-content-center">
                <p className="d-block text-justify mt-3">
                  Salah satu keharusan bagi pecinta game PUBG mobile ialah mengisi UC, istilah{' '}
                  <a href="https://member.speedcash.co.id/game/top-up-uc-pubg">top up UC PUBG</a>{' '}
                  termurah sudah tidak asing bagi mereka yang gemar sekali bermain game ini.Hal ini
                  dikarenakan jika ingin bermain PUBG mobile agar bisa melengkapi peralatan saat
                  bermain top up UC sudah merupakan keharusan yang wajib dilakukan.Nah disini kami
                  akan berikan cara mengenai top up UC PUBG murah dengan cepat dan tentunya di
                  tempat yang sudah terpercaya.
                </p>
              </Row>
              <Row className="d-flex justify-content-center">
                <Image
                  src="/img/Top-up-UC-PUBG.png"
                  data-target="Top-up-UC-PUBG.png"
                  width="100%"
                />
              </Row>
              <Row className="d-flex mt-4 mb-2 justify-content-center text-dark">
                <h4> Top Up UC PUBG Melalui Web Resmi SpeedCash</h4>
              </Row>
              <Row>
                <p className="d-block text-justify">
                  Untuk membeli UC / top up UC PUBG mobile di sini ada dua cara, salah satunya
                  melalui web resmi SpeedCash.berikut caranya top up UC PUBG termurah :
                </p>
              </Row>
              <Row className="d-flex justify-content-start">
                <ol className="text-justify list-content">
                  <li>
                    Kunjungi alamat :{' '}
                    <a href="https://member.speedcash.co.id/game/top-up-uc-pubg">
                      https://member.speedcash.co.id/game/top-up-uc-pubg
                    </a>{' '}
                  </li>
                  <li>Klik Masuk / Login</li>
                  <li>Masukkan nomor telepon kamu, lalu lanjutkan</li>
                  <li>Ikuti langkah-langkahnya, klik lanjutkan</li>
                  <li>Tunggu ada notifikasi yang masuk via WA/SMS</li>
                  <li>
                    Masukkan 4 digit terakhir nomor yang diberikan via WA/SMS, lalu klik lanjutkan.
                  </li>
                  <li>Pertama pastikan isi saldo SpeedCash dulu</li>
                  <li>Jika sudah lanjutkan dengan pilih menu VOUCHER GAME</li>
                  <li>Lalu Pilih menu PUBG atau pilih game yang kamu inginkan.</li>
                  <li>Pilih nominalnya, kemudian beli.</li>
                  <li>Ikuti instruksi selanjutnya</li>
                </ol>
              </Row>
            </div>
          </Col>

          <Col lg={8} xs={12} md={8} className="accordion-game">
            <Accordion className="m-0 p-0">
              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="1"
                    className="button-collapse w-100 text-left"
                  >
                    Top Up UC PUBG Murah Di Aplikasi Resmi SpeedCash
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="1">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Sangat mudah ternyata untuk membeli UC / Top Up UC PUBG karena bisa
                        dilakukan via aplikasi android maupun IOS. Dan ini dia cara top up UC PUBG
                        termurah dan cepat di aplikasi :
                      </p>
                      <Row className="mt-3">
                        <ol className="text-justify list-content">
                          <li>
                            Unduh aplikasi SpeedCash jika kamu belum punya (speedcash tersedia di
                            android dan IOS)
                          </li>
                          <li>
                            Masuk/daftar SpeedCash (jika sudah pernah daftar, kamu bisa login
                            langsung menggunakan no hp yang terdaftar di SpeedCash)
                          </li>
                          <li>Jika sudah, kamu bisa isi saldo SpeedCash dulu</li>
                          <li>
                            <p>Lalu pilih menu VOUCHER GAME</p>
                            <Row className="d-flex justify-content-center">
                              <Image
                                src="/img/voucher-game1.png"
                                data-target="voucher-game1"
                                width="220px"
                                height="400px"
                              />
                            </Row>
                          </li>
                          <li>
                            <p>Pilih Top Up</p>
                            <Row className="d-flex justify-content-center">
                              <Image
                                src="/img/top_up_uc_pubg_murah.png"
                                data-target="top_up_uc_pubg_murah"
                                width="220px"
                                height="400px"
                              />
                            </Row>
                          </li>
                          <li>Lanjutkan dengan klik icon / pilih PUBG</li>
                          <li>
                            <p>Masukan ID Pengguna</p>
                            <Row className="d-flex justify-content-center">
                              <Image
                                src="/img/top_up_uc_murah.png"
                                data-target="top_up_uc_murah"
                                width="220px"
                                height="400px"
                              />
                            </Row>
                          </li>
                          <li>Pilih UC PUBG yang sesuai dengan pilihan kamu</li>
                          <li>Ikuti instruksi selanjutnya hingga kamu berhasil top up</li>
                        </ol>
                      </Row>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="2"
                    className="button-collapse w-100 text-left"
                  >
                    Keuntungan Beli UC/Top Up UC PUBG Mobile Di SpeedCash
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="2">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Jika kamu membeli UC PUBG / Top up UC PUBG di SpeedCash kamu akan banyak
                        mendapatkan beragam kelebihan ketimbang kamu membeli UC PUBG di tempat
                        lain.Nah ini dia yang pasti kamu dapatkan jika top up UC PUBG melalui
                        SpeedCash.
                      </p>
                      <ol className="text-justify list-content">
                        <li>Banyaknya pilihan Denom UC PUBG yang bisa kamu beli</li>
                        <li>Tersedia denom diamond mulai 50 UC PUBG</li>
                        <li>Harga UC mulai 10.575 rupiah</li>
                        <li>Bisa top up UC PUBG tanpa aplikasi</li>
                        <li>
                          Masih banyak kelebihan yang bisa kamu dapatkan jika top up UC PUBG di
                          SpeedCash
                        </li>
                      </ol>
                      <p className="d-block text-justify mt-1">
                        Hebatnya dengan SpeedCash kamu tidak hanya bisa membeli UC PUBG saja tetapi
                        kamu juga bisa{' '}
                        <a href="https://member.speedcash.co.id/game/top-up-ff">top up ff</a> dan{' '}
                        <a href="https://member.speedcash.co.id/game/top-up-ml">top up ml</a> dengan
                        harga termurah dibandingkan dengan tempat lainnya loh!
                      </p>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="3"
                    className="button-collapse w-100 text-left"
                  >
                    Bagaimana Cara Mengisi Saldo SpeedCash
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="3">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Untuk mengisi saldo SpeedCash ternnyata sangat mudah karena terdapat
                        beberapa cara yang bisa kamu pilih seperti mengisi saldo via{' '}
                        <a href="https://www.speedcash.co.id/">Transfer bank</a> , Virtual account
                        bank hingga mengisi saldo SpeedCash melalui indomaret/minimarket. Untuk
                        mengetahui lebih jelas tentang cara isi saldo SpeedCash kamu bisa mengikuti
                        tutorial yang ada di Video Ini
                      </p>
                      <Row className="d-flex w-100 justify-content-center mb-3">
                        <iframe
                          width="100%"
                          height="480"
                          src="https://www.youtube.com/embed/sI1fLYglLwk"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                          frameBorder={0}
                          title="Cara Isi Saldo Di SpeedCash"
                        />
                      </Row>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="4"
                    className="button-collapse w-100 text-left"
                  >
                    Bagaimana Jika Ada Kendala Saat Membeli UC / Top Up UC PUBG Di SpeedCash ?
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="4">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Jika mengalami kendala saat membeli/ top up UC PUBG termurah ataupun ketika
                        menggunakan layanan lainnya yang ada di SpeedCash kamu bisa langsung
                        mengubungi tim support kami melalui menu CHAT CS, Cs kami akan dengan senang
                        hati melayani selama 24/7 (24 jam dalam 7 hari)
                      </p>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="5"
                    className="button-collapse w-100 text-left"
                  >
                    Sekilas Tentang PUBG Mobile
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="5">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        PUBG Mobile bukanlah game orisinal untuk ponsel. Merupakan adaptasi dari
                        game PC dan konsol populer PlayerUnknown&apos;s Battlegrounds (PUBG) [PUBG
                        Mobile]. PUBG sendiri dikembangkan oleh PUBG Corp (sekarang Krafton) asal
                        Korea Selatan.Untuk versi mobile, PUBG Corp menggandeng Tencent Games,
                        raksasa gaming asal Tiongkok.
                      </p>
                      <p className="d-block text-justify mt-1">
                        PUBG Mobile pertama kali dirilis pada 19 Maret 2018 untuk perangkat Android
                        dan iOS. Permainan ini menghadirkan konsep yang sama seperti versi PC-nya,
                        yaitu sebuah pertempuran bertahan hidup di mana pemain terjun payung ke
                        pulau besar, mencari senjata, perlengkapan, dan berusaha bertahan hidup
                        sambil mengalahkan pemain lain. Grafis, kontrol, dan mekanisme permainan
                        disesuaikan untuk pengalaman permainan mobile.
                      </p>
                      <p className="d-block text-justify mt-1">
                        Sejak rilisnya, PUBG Mobile telah menjadi salah satu game ponsel yang paling
                        populer di dunia. Game ini memiliki jutaan pemain aktif setiap bulannya dan
                        telah menginspirasi banyak game serupa di platform seluler. Pengembang
                        secara teratur menghadirkan pembaruan dan konten baru untuk menjaga
                        kesegaran permainan dan terus menarik minat pemain.
                      </p>
                      <p className="d-block text-justify mt-1">
                        Selain mode permainan utama, seperti mode &ldquo;Battle Royale&rdquo; dan
                        &ldquo;Team Deathmatch,&rdquo; PUBG Mobile juga memiliki berbagai mode
                        permainan khusus, kolaborasi dengan merek terkenal, dan turnamen eSports
                        yang besar. Game ini telah menjadi fenomena budaya pop yang signifikan di
                        seluruh dunia, mempengaruhi tidak hanya industri game, tetapi juga budaya
                        populer secara luas.
                      </p>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="6"
                    className="button-collapse w-100 text-left"
                  >
                    Mode Permainan Dalam PUBG Mobile
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="6">
                  <Card.Body className="card-content">
                    <Row>
                      <b className="d-block text-justify ">
                        PUBG Mobile menawarkan beberapa jenis mode permainan, antara lain:
                      </b>
                      <p className="d-block text-justify mt-1">
                        <b>Mode Battle Royale:</b> Mode ini merupakan mode utama PUBG Mobile, di
                        mana pemain terjun payung ke sebuah pulau besar dan berusaha untuk Bertahan
                        sambil mengalahkan musuh pemain lain. Tujuannya adalah menjadi yang terakhir
                        yang selamat.
                      </p>
                      <p className="d-block text-justify mt-1">
                        <b>Mode Team Deathmatch:</b> Dalam mode ini, pemain dibagi menjadi dua tim
                        dan bersaing dalam pertarungan tim untuk mencapai tujuan numerik pada titik
                        tertentu. Tim dengan skor tertinggi memenangkan pertandingan.
                      </p>
                      <p className="d-block text-justify mt-1">
                        <b>Mode perang:</b> Mode ini adalah pertarungan intens di mana pemain dibagi
                        menjadi beberapa tim dan bertarung untuk merebut wilayah tertentu atau
                        mengumpulkan poin dengan mengalahkan lawan.
                      </p>
                      <p className="d-block text-justify mt-1">
                        <b>Mode Payload:</b> Dalam mode ini, pemain dapat menggunakan berbagai jenis
                        kendaraan dan senjata berat, termasuk helikopter dan tank, untuk memenangkan
                        pertempuran.
                      </p>
                      <p className="d-block text-justify mt-1">
                        <b>Mode Arkade:</b> Mode ini mencakup berbagai sub-mode, seperti Quick
                        Match, Mini-Zone, Sniper Training, dll, memberikan pengalaman bermain game
                        yang lebih cepat dan mendalam.
                      </p>
                      <p className="d-block text-justify mt-1">
                        Setiap mode permainan PUBG Mobile menghadirkan keunikan dan kesenangan bagi
                        pemain, dengan tantangan dan strategi yang berbeda-beda.{' '}
                      </p>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="7"
                    className="button-collapse w-100 text-left"
                  >
                    Apa Itu UC (Unknown Cash) Dalam PUBG Mobile
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="7">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        UC (Unknown Cash) di PUBG Mobile merupakan mata uang virtual yang digunakan
                        untuk membeli berbagai item dalam game, seperti kostum karakter, senjata,
                        aksesoris, dan masih banyak item kosmetik lainnya. Pemain dapat menggunakan
                        UC untuk mendapatkan keuntungan dalam permainan atau meningkatkan penampilan
                        karakternya. UC dapat dibeli dengan uang sungguhan melalui pembelian dalam
                        aplikasi atau dengan menggunakan kode penukaran yang disediakan oleh PUBG
                        Mobile selama acara khusus atau promosi tertentu.
                      </p>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="8"
                    className="button-collapse w-100 text-left"
                  >
                    Apakah Wajib Top Up UC PUBG Termurah Di SpeedCash
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="8">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Sebetulnya untuk top up UC PUBG di speedcash bukanlah suatu hal yang wajib
                        dilakukan para pecinta game PUBG mobile.Namun dengan kehadiran SpeedCash
                        diharapkan bisa menjembatani kebutuhan para gamer untuk beli ataupun top up
                        UC PUBG termurah dengan cepat dan pastinya legal terpercaya.
                      </p>
                      <p className="d-block text-justify mt-1">
                        Karena selain itu jika membeli UC PUBG di SpeedCash juga akan memberikan
                        diskon dan pilihan denom beragam mulai dari 50 UC hingga 5000 UC
                      </p>
                      <p className="d-block text-justify mt-1">
                        Jadi, top up UC PUBG murah merupakan pilihan khusus bagi para pecinta game
                        PUBG yang ingin memperoleh item-item khusus, meningkatkan gaya permainan,
                        atau mendukung pengembang dalam mempertahankan dan mengembangkan permainan.
                        Jadi, sementara top up UC dapat meningkatkan pengalaman permainan dengan
                        menawarkan akses ke konten premium, hal tersebut bukanlah suatu keharusan
                        untuk menikmati permainan PUBG Mobile.
                      </p>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="9"
                    className="button-collapse w-100 text-left"
                  >
                    Apa Fungsi UC Dalam Permainan PUBG Mobile
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="9">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        UC (Unknown Cash) dalam PUBG Mobile memiliki beberapa kegunaan, termasuk:
                      </p>
                      <p className="d-block text-justify mt-1">
                        <b>Membeli Kostum dan Skin:</b> UC dapat digunakan untuk membeli kostum
                        karakter, skin senjata, dan aksesori lainnya yang memengaruhi penampilan
                        karakter pemain dalam permainan.
                      </p>
                      <p className="d-block text-justify mt-1">
                        <b>Mendapatkan Royale Pass:</b> Pemain dapat menggunakan UC untuk membeli
                        Royale Pass atau Royale Pass Elite, yang memberikan akses kepada pemain
                        untuk mendapatkan hadiah eksklusif, misi tambahan, dan keuntungan lainnya
                        selama durasi Royale Pass tersebut.
                      </p>
                      <p className="d-block text-justify mt-1">
                        <b>Memperoleh Kekuatan dan Perlengkapan Tambahan:</b> UC dapat digunakan
                        untuk membeli item dalam permainan yang memberikan keunggulan taktis,
                        seperti crate pengadaan senjata, item kesehatan, dan berbagai perlengkapan
                        lainnya yang meningkatkan kinerja pemain dalam pertempuran.
                      </p>
                      <p className="d-block text-justify mt-1">
                        <b>Mendapatkan Kekuatan dan Perlengkapan Tambahan:</b> UC dapat digunakan
                        untuk membeli item dalam permainan yang memberikan keunggulan taktis,
                        seperti crate pengadaan senjata, item kesehatan, dan berbagai perlengkapan
                        lainnya yang meningkatkan kinerja pemain dalam pertempuran.
                      </p>
                      <p className="d-block text-justify mt-1">
                        <b>Upgrade Kostum dan Skin:</b> UC juga dapat digunakan untuk meningkatkan
                        atau memperbaiki kostum dan skin yang sudah dimiliki oleh pemain, memberikan
                        efek visual yang lebih menarik atau fitur tambahan lainnya.
                      </p>
                      <p className="d-block text-justify mt-1">
                        <b>Mengikuti Event dan Promosi:</b> Kadang-kadang, UC digunakan untuk
                        membeli tiket masuk ke event khusus atau promosi dalam permainan yang
                        memberikan hadiah eksklusif atau keuntungan lainnya kepada para pemain.
                      </p>
                      <p className="d-block text-justify mt-1">
                        Penggunaan UC ini memungkinkan pemain untuk menyesuaikan dan meningkatkan
                        pengalaman mereka dalam permainan PUBG Mobile, tetapi tidak diperlukan untuk
                        memainkan permainan atau untuk mencapai kesuksesan dalam permainan.
                      </p>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="10"
                    className="button-collapse w-100 text-left"
                  >
                    Cara Main Game PUBG Di Laptop/PC
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="10">
                  <Card.Body className="card-content">
                    <Row>
                      <div className="d-flex flex-column">
                        <p className="d-block text-justify mt-3">
                          Berikut adalah langkah-langkah untuk bermain game PUBG di laptop, pertama
                          kamu wajib menginstall PUBG dulu, berikut langkahnya:
                        </p>
                        <Row className="mt-1">
                          <ol className="text-justify list-content">
                            <li>
                              Pastikan laptop Anda memenuhi spesifikasi minimum yang diperlukan
                              untuk menjalankan PUBG. Spesifikasi ini biasanya termasuk prosesor
                              yang cukup kuat, kartu grafis yang mendukung, dan ruang penyimpanan
                              yang cukup.
                            </li>
                            <li>
                              Buka platform game seperti Steam atau situs web resmi PUBG untuk
                              membeli atau mengunduh permainan. PUBG tersedia untuk dibeli dan
                              diunduh melalui Steam atau platform game lainnya.
                            </li>
                            <li>
                              Jika Anda menggunakan Steam, buka aplikasi Steam di laptop Anda. Jika
                              Anda belum memiliki akun Steam, buatlah satu dan login.
                            </li>
                            <li>
                              Di jendela Steam, cari &ldquo;PUBG&rdquo; menggunakan kotak pencarian
                              di bagian atas jendela.
                            </li>
                            <li>
                              Klik pada hasil pencarian PUBG, lalu klik tombol &ldquo;Beli&rdquo;
                              atau &ldquo;Tambahkan ke Keranjang&rdquo; untuk membeli permainan jika
                              Anda belum memilikinya. Jika Anda telah membeli permainan, klik tombol
                              &ldquo;Install&rdquo; untuk mulai mengunduh dan menginstalnya.
                            </li>
                            <li>
                              Ikuti petunjuk yang muncul di layar untuk menyelesaikan proses
                              pengunduhan dan instalasi. Pastikan untuk mengikuti setiap instruksi
                              dengan cermat.
                            </li>
                          </ol>
                        </Row>
                      </div>
                      <p className="d-block text-justify mt-1">
                        Setelah instalasi selesai, Anda dapat meluncurkan PUBG dari perpustakaan
                        Steam atau shortcut yang dibuat di desktop Anda. Setelah permainan
                        diluncurkan, Anda mungkin perlu melakukan beberapa pengaturan awal, seperti
                        menyesuaikan pengaturan grafis, kontrol, dan suara sesuai preferensi Anda.
                      </p>
                      <p className="d-block text-justify mt-1">
                        Dengan mengikuti langkah-langkah di atas, Anda seharusnya dapat menginstal
                        PUBG di laptop Anda dan siap untuk memainkannya. Pastikan untuk memperbarui
                        perangkat lunak dan driver hardware Anda untuk memastikan kinerja yang
                        optimal.
                      </p>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="11"
                    className="button-collapse w-100 text-left"
                  >
                    Cara Melihat ID PUBG Mobile
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="11">
                  <Card.Body className="card-content">
                    <Row>
                      <div className="d-flex flex-column">
                        <p className="d-block text-justify mt-3">
                          Untuk mengetahui ID PUBG Anda, sangatlah mudah kamu bisa ikuti
                          langkah-langkah berikut:
                        </p>
                        <Row className="mt-1">
                          <ol className="text-justify list-content">
                            <li>Buka aplikasi PUBG Mobile di perangkat Anda.</li>
                            <li>
                              Setelah masuk ke dalam permainan, di layar utama, klik pada ikon
                              &ldquo;Profil&rdquo; di pojok kiri atas layar. Ikon profil biasanya
                              terlihat seperti gambar kepala manusia kecil.
                            </li>
                            <li>
                              Setelah Anda klik ikon profil, Anda akan dibawa ke layar profil Anda.
                              Di bagian atas layar profil, Anda akan melihat beberapa informasi,
                              termasuk nama Anda, level, dan ID PUBG Anda.
                            </li>
                            <li>
                              ID PUBG Anda biasanya terletak di bawah nama Anda dan di atas level
                              Anda. Ini akan ditampilkan sebagai serangkaian angka unik.
                            </li>
                          </ol>
                        </Row>
                      </div>
                      <p className="d-block text-justify-mt-1">
                        Dengan mengikuti langkah-langkah ini, Anda dapat dengan mudah mengetahui ID
                        PUBG Anda di PUBG Mobile.
                      </p>
                      <p className="d-block text-justify mt-1">
                        Itulah tadi sekilas informasi mengenai tempat top up PUBG di SpeedCash,
                        tidak hanya top up UC PUBG saja yang bisa kamu lakukan di SpeedCash namun
                        kamu juga bisa pakai SpeedCash untuk :{' '}
                        <a href="https://member.speedcash.co.id/pln/cek-tagihan-listrik">
                          cek tagihan listrik
                        </a>{' '}
                        ,{' '}
                        <a href="https://member.speedcash.co.id/bpjs">cek tagihan BPJS Kesehatan</a>{' '}
                        ,{' '}
                        <a href="https://member.speedcash.co.id/pln/token-listrik">
                          beli token listrik
                        </a>{' '}
                        dengan harga termurah hingga bayar pajak kendaraan online, transfer antar
                        bank gratis, dan masih banyak layanan SpeedCash lainnya yang bisa kamu
                        manfaatkan.
                      </p>
                      <div className="mt-1 w-100 d-flex justify-content-center">
                        <h5 className="bold">
                          Yuk! Download Aplikasi Top Up UC PUBG termurah Disini
                        </h5>
                      </div>
                      <Row className="d-flex w-100 justify-content-center mb-3 gap-3">
                        <a
                          aria-hidden
                          href="https://play.google.com/store/apps/details?id=com.bm.sc.bebasbayar"
                          className="mx-1"
                        >
                          <Image
                            src="/img/playstore-speedcash.png"
                            data-target="playstore-speedcash"
                            width="220px"
                            height="60px"
                          />
                        </a>
                        <a
                          aria-hidden
                          href="https://apps.apple.com/id/app/speedcash-transfer-ewallet/id6459304122"
                          className="mx-1"
                        >
                          <Image
                            src="/img/appstore-speedcash.png"
                            data-target="appstore-speedcash"
                            width="220px"
                            height="60px"
                          />
                        </a>
                      </Row>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default AccordionGamePubg
