import React from 'react'
import { Button, Image, Row, Col, Accordion, Card } from 'react-bootstrap'
import './accordion.scss'

const AccordionBpjsTk = () => {
  return (
    <>
      <Col className="d-flex justify-content-center mt-4 mb-3 p-3">
        <h3 className="text-dark text-justify">
          Cek & Bayar Tagihan BPJS Ketenagakerjaan Bulanan Ini
        </h3>
      </Col>

      <div style={{ padding: '0px 20px 30px 20px' }}>
        <Row className="justify-content-md-center">
          <Col lg={8} xs={12} md={8} className="content-component">
            <div>
              <Row className="d-flex">
                <Row className="d-flex justify-content-center">
                  <Image
                    src="/img/bayar-tagihan-bpjs-ketenagakerjaan.png"
                    data-target="bayar-tagihan-bpjs-ketenagakerjaan"
                    alt="bayar-tagihan-bpjs-ketenagakerjaan"
                    width="100%"
                  />
                </Row>
                <Row className="mt-2">
                  <p className="d-block text-justify">
                    <a href="https://member.speedcash.co.id/asuransi/bpjs-ketenagakerjaan">
                      Bayar tagihan BPJS Ketenagakerjaan
                    </a>{' '}
                    saat ini sudah semakin mudah tidak perlu harus mengantri keluar rumah hanya
                    untuk membayar tagihan bulanan BPJS ketenagakerjaan.Cara melihat total tagihan
                    BPJS ketenagakerjaan bulan ini atau bulan sebelumnya bisa juga dilakukan tanpa
                    aplikasi hanya melalui website. Berikut caranya :
                  </p>
                </Row>
                <Row className="d-flex justify-content-start">
                  <ol className="text-justify list-content">
                    <li>
                      Pastikan kamu mengunjungi halaman :
                      <a href="https://member.speedcash.co.id/asuransi/bpjs-ketenagakerjaan">
                        https://member.speedcash.co.id/asuransi/bpjs-ketenagakerjaan
                      </a>
                    </li>
                    <li>Lanjutkan dengan login dulu menggunakan nomor hp yang aktif ya</li>
                    <li>Jika sudah, lalu masuk ke menu asuransi</li>
                    <li>Pilih sub-menu asuransi ketenagakerjaan</li>
                    <li>Pilih asuransi BPJS ketenagakerjaan</li>
                    <li>Siapkan NIK (pastikan NIK mu terdaftar)</li>
                    <li>Masukkan nomor induk KTP (NIK)</li>
                    <li>Lalu klik Cek tagihan Sekarang</li>
                    <li>Dan akan muncul total tagihan beserta diskon yang kamu bayar</li>
                    <li>Ikuti instruksi selanjutnya</li>
                  </ol>
                </Row>
              </Row>
            </div>
          </Col>

          <Col lg={8} xs={12} md={8} className="accordion-component">
            <Accordion className="m-0 p-0">
              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="1"
                    className="button-collapse w-100 text-left"
                  >
                    Cara Bayar Tagihan Asuransi BPJS Ketenagakerjaan Di Aplikasi
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="1">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Selain membayar tagihan BPJS ketenagakerjaan melalui website,ada juga cara
                        yang lebih praktis cukup melalui aplikasi resmi kamu sudah bisa melihat
                        total tagihan dan membayar bpjs ketenagakerjaan rutin tanpa harus khawatir
                        terkena denda keterlambatan. berikut caranya :
                      </p>
                      <Row className="mt-3">
                        <ul className="text-justify list-content">
                          <li>
                            Download dulu aplikasi SpeedCash yang sudah tersedia di playstore maupun
                            appstore
                          </li>
                          <li>
                            <p>
                              Jika sudah lanjutkan dengan login aplikasi menggunakan nomor hp aktif
                            </p>
                            <Row className="d-flex justify-content-center">
                              <Image
                                src="/img/bayar-bpjs-ketenagakerjaan.png"
                                data-target="bayar bpjs ketenagakerjaan"
                                width="220px"
                                height="400px"
                              />
                            </Row>
                          </li>
                          <li>
                            <p>Lalu bila sudah login, masuk ke menu asuransi</p>
                            <Row className="d-flex justify-content-center">
                              <Image
                                src="/img/cek-bpjs-ketenagakerjaan.png"
                                data-target="cek bpjs ketenagakerjaan"
                                width="220px"
                                height="400px"
                              />
                            </Row>
                          </li>
                          <li>Pilih asuransi ketenagakerjaan</li>
                          <li>Pilih BPJS Ketenagakerjaan</li>
                          <li>
                            <p>Masukkan nomor KTP (NIK)</p>
                            <Row className="d-flex justify-content-center">
                              <Image
                                src="/img/aplikasi-bpjs-ketenagakerjaan.png"
                                data-target="aplikasi bpjs ketenagakerjaan"
                                width="220px"
                                height="400px"
                              />
                            </Row>
                          </li>
                          <li>Lalu klik LANJUTKAN</li>
                          <li>Ikuti instruksi selanjutnya hingga sukses membayar tagihan</li>
                        </ul>
                      </Row>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="2"
                    className="button-collapse w-100 text-left"
                  >
                    Mengapa Bayar BPJS Ketenagakerjaan Disini Lebih Mudah ?
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="2">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Keuntungan yang kamu dapatkan untuk bayar BPJS ketenagakerjaan melalui
                        SpeedCash salah satunya ialah diskon, sehingga kamu bisa semakin hemat
                        membayar tagihan bulanan.karena pada faktanya SpeedCash tidak hanya
                        digunakan untuk bayar BPJS ketenagakerjaan (jamsostek) tetapi juga bisa
                        digunakan untuk membayar tagihan bulanan lainnya seperti :{' '}
                        <a href="https://member.speedcash.co.id/cek-bpjs-kesehatan">
                          cek tagihan BPJS kesehatan
                        </a>
                        ,{' '}
                        <a href="https://member.speedcash.co.id/pln/cek-tagihan-listrik">
                          cek tagihan listrik
                        </a>
                        , beli{' '}
                        <a href="https://member.speedcash.co.id/pln/token-listrik">token listrik</a>{' '}
                        dan masih banyak fungsi dari SpeedCash yang bisa kamu manfaatkan.
                      </p>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="3"
                    className="button-collapse w-100 text-left"
                  >
                    Kendala Saat Membayar BPJS Ketenagakerjaan
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="3">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Saat kamu mencoba membayar tagihan / iuran BPJS ketenagakerjaan mungkin
                        suatu waktu kamu mengalami kendala.Untuk hal itu kamu tidak perlu khawatir
                        jika mengalami kesulitan ada kendala saat bayar BPJS ketenagakerjaan di
                        SpeedCash karena di SpeedCash sudah tersedia fitur livechat yang bisa kamu
                        manfaatkan jika kamu menemukan kesulitas membayar.Cukup klik tombol live
                        chat yang ada di website ini.Maka dengan senang hati CS kami akan membantumu
                        selama 24jam selama 7 hari (1 minggu)
                      </p>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="4"
                    className="button-collapse w-100 text-left"
                  >
                    Hal Lain Yang Harus Diperhatikan
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="4">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Sebelum bayar tagihan BPJS ketenagakerjaan ada beberapa hal wajib kamu
                        perhatikan sebelumnya, salah satunya mengisi saldo SpeedCash dulu agar saat
                        menggunakan SpeedCash menjadi lebih mudah. Berikut cara isi saldo{' '}
                        <a href="https://www.speedcash.co.id/">SpeedCash</a>
                      </p>
                      <iframe
                        width="100%"
                        height="480"
                        src="https://www.youtube.com/embed/sI1fLYglLwk"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        frameBorder={0}
                        title="cara isi saldo SpeedCash"
                        className="my-3"
                      />
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="5"
                    className="button-collapse w-100 text-left"
                  >
                    Tentang BPJS Ketenagakerjaan
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="5">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        BPJS Ketenagakerjaan atau Organisasi Penyelenggara Jaminan Sosial
                        Ketenagakerjaan merupakan badan hukum yang dapat diakses oleh masyarakat.
                        Badan hukum ini bertujuan untuk menjamin perlindungan kerja bagi pekerja di
                        Indonesia terhadap berbagai risiko ekonomi dan sosial. Pelayanan jaminan
                        asuransi sosial ini sebelumnya bernama Jaminan Sosial Ketenagakerjaan
                        (Jamsostek). Perusahaan swasta tersebut kemudian berganti nama menjadi BPJS
                        Ketenagakerjaan pada 1 Januari 2014. Hal ini berdasarkan ketentuan UU No. 24
                        tahun 2011. BPJS Ketenagakerjaan mencakup seluruh pekerja di semua sektor,
                        baik negeri maupun swasta. Karena manfaatnya, pemerintah menghimbau setiap
                        dunia usaha untuk mendaftarkan seluruh pekerjanya dalam skema BPJS
                        Ketenagakerjaan. Selain BPJS Pekerjaan, ada produk BPJS lainnya seperti BPJS
                        Kesehatan dan Pembayaran Denda BPJS.
                      </p>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="6"
                    className="button-collapse w-100 text-left"
                  >
                    Manfaat Mengikuti BPJS Ketenagakerjaan
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="6">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Dengan mendaftar menjadi anggota BPJS Ketenagakerjaan tentunya banyak
                        keuntungan yang bisa Anda peroleh. Yang pertama adalah Anda bisa mengakses
                        fasilitas KPR atau Kredit Pemilikan Rumah dan perumahan bersubsidi. Kedua
                        utilitas ini tentunya akan sangat bermanfaat bagi masyarakat berpenghasilan
                        rendah yang ingin menjadi pemilik rumah. Misalnya saja program KPR yang
                        memudahkan Anda dalam membayar biaya perumahan bulanan sehingga tidak
                        terkesan memberatkan. Selain itu, pekerja yang terdaftar dalam program
                        asuransi pemerintah ini juga dapat memanfaatkan program pinjaman untuk
                        merenovasi rumah mereka. Manfaat lain dari BPJS Ketenagakerjaan adalah
                        memberikan beasiswa kepada anak pekerja kurang mampu. Selain itu, layanan
                        BPJS ini juga memberikan layanan konsultasi perencanaan berbagai proyek
                        konstruksi. Hal ini dimaksudkan untuk meminimalisir risiko kecelakaan di
                        tempat kerja.
                      </p>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="7"
                    className="button-collapse w-100 text-left"
                  >
                    Jenis BPJS Ketenagakerjaan
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="7">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block-text-justify">
                        Layanan BPJS meliputi beberapa kategori. Yang pertama adalah Program Jaminan
                        Hari Tua atau JHT. Program ini ditujukan bagi peserta BPJS Ketenagakerjaan
                        yang telah pensiun atau terpaksa pensiun dini karena cacat total akibat
                        kecelakaan kerja. Peserta yang sudah meninggal juga diikutsertakan dalam
                        program jenis ini. Program lainnya adalah Program Asuransi Kompensasi
                        Pekerja. Program ini memberikan kompensasi kepada peserta BPJS yang menjadi
                        korban kecelakaan kerja. Termasuk santunan kematian bagi peserta yang
                        meninggal dunia akibat kecelakaan kerja. Jenis BPJS yang ketiga adalah
                        asuransi kematian. Skema tersebut berupa santunan kematian yang dibayarkan
                        kepada ahli waris jika ada anggota BPJS Ketenagakerjaan meninggal dunia
                        akibat kecelakaan industri. Jenis skema yang terakhir adalah skema jaminan
                        pensiun. Sama halnya dengan skema Jaminan Hari Tua, skema jaminan pensiun
                        ini memastikan bahwa peserta BPJS yang pensiun akan menerima iuran bulanan
                        hingga peserta meninggal dunia.
                      </p>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="8"
                    className="button-collapse w-100 text-left"
                  >
                    Proses Pencairan BPJS Ketenagakerjaan
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="8">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Saat ini pencairan BPJS pekerjaan cukup mudah dilakukan. Pencairannya bisa
                        Anda lakukan tanpa kendala karena kini pencairan BPJS Kerja sudah bisa
                        dilakukan secara online. Untuk melakukan pencairan, Anda dapat memindai QR
                        Code di cabang tempat Anda tinggal. Kemudian isi datanya yaitu nama lengkap,
                        nomor anggota dan NIK. Sistem kemudian akan melakukan pengecekan dan Anda
                        akan diminta untuk mengisi data secara lengkap sesuai petunjuk portal.
                        Unggah semua dokumen yang diperlukan seperti E-KTP, buku tabungan, kartu
                        keluarga, kartu peserta BPJS ketenagakerjaan dan surat izin pemberhentian
                        kerja. Sampaikan pemberitahuan tersebut kepada manajer cabang dan proses
                        selanjutnya akan dilakukan oleh cabang dengan pencairan melalui rekening
                        Anda.{' '}
                      </p>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="9"
                    className="button-collapse w-100 text-left"
                  >
                    Cara Daftar BPJS Ketenagakerjaan
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="9">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block-text-justify">
                        Buat kamu yang sedang mencari informasi pendaftaran BPJS ketenagakerjaan,
                        ada baiknya kamu perhatikan cara mendaftar BPJS berikut ini :
                      </p>
                      <Row className="mt-1">
                        <ol className="text-justify list-content">
                          <li>SpeedCash bisa digunakan untuk beli tiket kereta api</li>
                          <li>Bisa juga untuk transfer bank bebas biaya admin</li>
                          <li>Beli tiket pesawat dengan garansi harga paling murah</li>
                          <li>Hingga bayar pajak kendaraan (samsat) </li>
                        </ol>
                      </Row>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="10"
                    className="button-collapse w-100 text-left"
                  >
                    Cara Berhenti Menjadi Peserta BPJS Ketenagakerjaan
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="10">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Jika masa kerjamu sudah habis atau kamu ingin menonaktifkan BPJS
                        ketenagakerjaan, kamu bisa ikuti langkah berikut ini!
                      </p>
                      <p className="d-block text-justify mt-1">
                        Pertama, buka www.sipp.bpjsketenagakerjaan.go.id. Kemudian lanjutkan dengan
                        login menggunakan username dan password yang sudah Anda miliki dan pilih
                        perusahaannya. Cari nomor kartu atau pegawai BPJS yang ingin dinonaktifkan.
                        Pilih menu &ldquo;Tindakan&rdquo; dan pilih &ldquo;Nonaktifkan Agen&rdquo;.
                        Kemudian konfirmasi penonaktifan dan proses selesai. Dengan menggunakan
                        prosedur ini, maka BPJS ketenagakerjaan (jamsostek) Anda tidak akan aktif
                        lagi. Anda dapat menarik dana BPJS Ketenagakerjaan dengan mengikuti
                        langkah-langkah di atas.
                      </p>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="11"
                    className="button-collapse w-100 text-left"
                  >
                    S & K Penerima BPJS Ketenagakerjaan
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="11">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Untuk mendaftar dan menjadi anggota BPJS Pekerjaan, Anda harus memperhatikan
                        beberapa syarat dan ketentuan. Bagi calon peserta BPJS Ketenagakerjaan
                        perusahaan wajib menyiapkan dokumen asli dan fotokopi SIUP (Surat Izin Usaha
                        Perdagangan), NPWP perusahaan, KTP (Kartu Tanda Penduduk), Akte Komersial
                        Perusahaan, Kartu Keluarga, dan dokumen tanda pengenal pegawai. Foto dokumen
                        identitas. Selain untuk pegawai perusahaan, BPJS Ketenagakerjaan juga bisa
                        digunakan untuk pekerja mandiri. Berikut persyaratan yang harus dipenuhi
                        bagi calon peserta BPJS dari sektor mandiri: Surat Izin Usaha, KTP, KK dan
                        pas foto (1 lembar) setiap calon pekerja peserta.
                      </p>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="12"
                    className="button-collapse w-100 text-left"
                  >
                    Nominal IURAN BPJS Ketenagakerjaan
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="12">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Nominal iuran peserta BPJS Ketenagakerjaan berbeda-beda tergantung kategori
                        yang dipilih. Misalnya, penerima iuran Jaminan Hari Tua harus membayar
                        sebesar 5,7 persen dari gajinya setiap bulan. Untuk program asuransi
                        kompensasi pekerja, peserta harus memberikan kontribusi sesuai dengan
                        tingkat risiko di bidang pekerjaannya. Rasio risiko bervariasi dari 0,24%
                        hingga 1,74%. Terkait manfaat kematian, peserta BPJS wajib memberikan iuran
                        bulanan sebesar 0,3% dari gaji yang dibayarkan perusahaan. Untuk iuran
                        Jaminan Pensiun, peserta cukup membayar iuran bulanan sebesar 1% untuk
                        karyawan dan 2% untuk perusahaan.
                      </p>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="13"
                    className="button-collapse w-100 text-left"
                  >
                    Cek Tagihan Iuran BPJS Ketenagakerjaan
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="13">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Penting untuk memeriksa tagihan iuran BPJS Ketenagakerjaan Anda untuk
                        memastikan bahwa Anda telah membayar iuran tepat waktu dan dalam jumlah yang
                        benar. Jika Anda tidak membayar iuran BPJS Ketenagakerjaan tepat waktu, Anda
                        akan dikenakan denda. Selain itu, Anda juga tidak bisa mendapatkan manfaat
                        BPJS Ketenagakerjaan jika belum berkontribusi. Lalu bagaimana cara cek resi
                        donasi BPJS Ketenagakerjaan secara online?
                      </p>
                      <p className="d-block text-justify mt-1">
                        Terdapat dua cara untuk mengecek tagihan iuran BPJS ketenagakerjaan :{' '}
                      </p>
                      <div className="w-100 my-1">
                        <p className="d-block text-justify">Melalui website BPJS Ketenagakerjaan</p>
                        <ol className="text-justify list-content">
                          <li>
                            Buka website BPJS Ketenagakerjaan di
                            https://www.bpjsketenagakerjaan.go.id/.
                          </li>
                          <li>Login ke akun BPJS Ketenagakerjaan Anda</li>
                          <li>Klik tombol “ Tombol Menu Donasi.</li>
                          <li>
                            Faktur donasi BPJS Ketenagakerjaan Anda akan ditampilkan di halaman ini.
                          </li>
                        </ol>
                      </div>
                      <div className="w-100 my-1">
                        <p className="d-block text-justify">
                          Melalui aplikasi JMO (Jamsostek Mobile)
                        </p>
                        <ol className="text-justify list-content">
                          <li>Download dan install aplikasi JMO di smartphone Anda .</li>
                          <li>Login ke akun JMO Anda.</li>
                          <li>
                            Faktur iuran BPJS Ketenagakerjaan Anda akan ditampilkan di halaman utama
                            aplikasi JMO.
                          </li>
                        </ol>
                      </div>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="14"
                    className="button-collapse w-100 text-left"
                  >
                    Cek Saldo BPJS Ketenagakerjaan
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="14">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Berikut dua cara mengecek saldo BPJS ketenagakerjaan yang harus kamu ketahui
                        :
                      </p>
                      <div className="w-100 my-1">
                        <p className="d-block text-justify">Melalui website BPJS Jobs</p>
                        <ol className="text-justify list-content">
                          <li>Buka website BPJS Jobs di https://www.bpjsketenagakerjaan.go.id/.</li>
                          <li>Login ke akun BPJS Jobs Anda. Klik “menu saldo JHT ”.</li>
                          <li>Saldo JHT Anda akan ditampilkan di halaman ini.</li>
                        </ol>
                      </div>
                      <div className="w-100 my-1">
                        <p className="d-block text-justify">
                          Melalui aplikasi JMO (Jamsostek Mobile)
                        </p>
                        <ol className="text-justify list-content">
                          <li>Download dan install, aplikasi JMO di smartphone Anda.</li>
                          <li>Masuk ke akun JMO Anda.</li>
                          <li>Saldo JHT Anda akan ditampilkan di halaman utama aplikasi JMO.</li>
                        </ol>
                      </div>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="15"
                    className="button-collapse w-100 text-left"
                  >
                    Perbedaan BPJS Ketenagakerjaan & BPJS Kesehatan :
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="15">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        <b>BPJS Ketenagakerjaan</b> : Program ini bertujuan untuk melindungi para
                        pekerja dan buruh dari risiko sosial ekonomi yang terkait dengan
                        ketenagakerjaan, seperti kecelakaan kerja, penyakit akibat kerja, dan
                        meninggal dunia dalam masa kerja. Peserta BPJS Ketenagakerjaan, baik pekerja
                        formal maupun informal, memberikan kontribusi secara berkala untuk
                        mendapatkan manfaat perlindungan yang mencakup santunan dalam bentuk uang
                        tunai, bantuan pemulihan, dan manfaat lainnya sesuai dengan ketentuan yang
                        berlaku. Program ini memberikan perlindungan finansial yang penting bagi
                        para pekerja agar dapat merasa lebih aman dan terlindungi selama menjalankan
                        aktivitas kerja mereka.
                      </p>
                      <p className="d-block text-justify">
                        <b>BPJS Kesehatan</b> : Program ini memberikan jaminan kesehatan bagi
                        seluruh penduduk Indonesia, baik yang bekerja formal maupun informal, serta
                        keluarga mereka. Peserta BPJS Kesehatan membayar iuran bulanan atau tahunan
                        untuk mendapatkan akses terhadap layanan kesehatan yang meliputi pemeriksaan
                        kesehatan, pengobatan, rawat inap, operasi, dan layanan kesehatan lainnya.
                      </p>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="16"
                    className="button-collapse w-100 text-left"
                  >
                    Ketentuan & Syarat Pembayaran BPJS
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="16">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">Definisi Umum :</p>
                      <Row className="mt-1">
                        <ol className="text-justify list-content">
                          <li>
                            SpeedCash adalah PT bimasakti multi sinergi dan seluruh afiliasi atau
                            anak perusahaannya, suatu perseroan terbatas yang menjalankan kegiatan
                            usaha jasa web portal www.SpeedCash.co.id, yakni situs pencarian toko
                            dan barang yang dijual oleh penjual terdaftar.
                          </li>
                          <li>
                            Situs/Aplikasi adalah situs www.SpeedCash.co.id milik SpeedCash yang
                            dapat diakses melalui desktop site dan/atau aplikasi yang berbasis
                            Android atau iOS.
                          </li>
                          <li>
                            Pengguna adalah pihak yang menggunakan layanan SpeedCash, termasuk namun
                            tidak terbatas pada Pembeli, Penjual maupun pihak lain yang sekedar
                            berkunjung ke Situs/Aplikasi.
                          </li>
                          <li>
                            BPJS adalah Badan Penyelenggara Jaminan Sosial, badan hukum publik yang
                            menyelenggarakan program jaminan kesehatan nasional dan jaminan sosial
                            ketenagakerjaan berdasarkan peraturan perundang-undangan yang berlaku.
                          </li>
                          <li>
                            BPJS Kesehatan adalah program jaminan kesehatan nasional yang
                            diselenggarakan oleh BPJS.
                          </li>
                          <li>
                            BPJS Ketenagakerjaan adalah program jaminan sosial ketenagakerjaan yang
                            diselenggarakan oleh BPJS.
                          </li>
                          <li>
                            Fitur Autodebet adalah fitur/layanan yang disediakan pada Situs/Aplikasi
                            untuk memudahkan Pengguna melakukan pembayaran BPJS Kesehatan secara
                            otomatis.
                          </li>
                          <li>
                            MyBills adalah fitur/layanan yang disediakan pada Situs/Aplikasi untuk
                            membantu Pengguna mengatur pembayaran kebutuhan bulanan.
                          </li>
                          <li>
                            Biaya Admin adalah Biaya yang dibebankan kepada pengguna yang akan
                            digunakan untuk memproses suatu transaksi hingga transaksi tersebut
                            berhasil.
                          </li>
                          <li>
                            Ketentuan situs adalah adalah Syarat dan Ketentuan Situs/Aplikasi,
                            Kebijakan Privasi, Syarat dan Ketentuan ini dan setiap Syarat dan
                            Ketentuan lain yang dapat berlaku untuk atau sehubungan dengan
                            Penggunaan Situs/Aplikasi dan seluruh fitur yang terdapat di dalamnya.
                          </li>
                          <li>
                            Syarat dan Ketentuan adalah Syarat dan Ketentuan ini untuk menggunakan
                            produk BPJS Kesehatan.
                          </li>
                        </ol>
                      </Row>
                      <p className="d-block text-justify">Pemakaian Layanan : </p>
                      <Row className="mt-1">
                        <ol className="text-justify list-content">
                          <li>
                            Layanan Pembayaran Tagihan BPJS hanya bisa digunakan oleh Pengguna
                            terdaftar pada Situs/Aplikasi.
                          </li>
                          <li>
                            Dengan menggunakan layanan Pembayaran Tagihan BPJS, Pengguna dianggap
                            telah menyetujui dan mematuhi Syarat dan Ketentuan ini serta Ketentuan
                            Situs.
                          </li>
                          <li>
                            SpeedCash berhak, tanpa pemberitahuan sebelumnya, melakukan
                            tindakan-tindakan yang diperlukan termasuk namun tidak terbatas pada
                            pembatalan transaksi pembayaran, menahan dana, atau menutup akun, jika
                            ditemukan adanya manipulasi maupun indikasi kecurangan atau pelanggaran
                            pelanggaran Syarat dan Ketentuan ini, Ketentuan Situs SpeedCash,
                            dan/atau ketentuan hukum yang berlaku di wilayah negara Indonesia.
                          </li>
                          <li>
                            SpeedCash berwenang untuk melakukan perubahan pada seluruh atau sebagian
                            daripada Syarat dan Ketentuan ini. Oleh karena itu, SpeedCash
                            menyarankan agar Pengguna membaca secara seksama dan memeriksa Syarat
                            dan Ketentuan ini dari waktu ke waktu untuk mengetahui perubahan apapun,
                            dengan mengakses Situs/Aplikasi, maka dianggap telah membaca dan
                            menyetujui Syarat dan Ketentuan ini.
                          </li>
                        </ol>
                      </Row>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="17"
                    className="button-collapse w-100 text-left"
                  >
                    Kantor BPJS Ketenagakerjaan
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="17">
                  <Card.Body className="card-content">
                    <div>
                      <Row className="d-flex flex-column">
                        <b>Kantor BPJS Ketenagakerjaan Pusat:</b>
                        <span>Jalan Jendral Gatot Subroto No. 79, RT.8/RW.2,</span>
                        <span className="d-block text-justify">
                          Karet Semanggi, RT.8/RW.2, Karet Semanggi,
                        </span>
                        <span className="d-block text-justify">Kota Jakarta Selatan,</span>
                        <span className="d-block text-justify">
                          Daerah Khusus Ibukota Jakarta 12930
                        </span>
                      </Row>
                      <Row className="d-flex flex-column mt-2">
                        <b>Kantor BPJS Ketenagakerjaan Jakarta:</b>
                        <span>Jalan Buncit Raya No. 24 Kav 1A,</span>
                        <span className="d-block text-justify">
                          Pancoran, RT.7/RW.2, Duren Tiga, Pancoran,
                        </span>
                        <span className="d-block text-justify">Kota Jakarta Selatan,</span>
                        <span className="d-block text-justify">
                          Daerah Khusus Ibukota Jakarta 12790
                        </span>
                      </Row>
                      <Row className="d-flex flex-column mt-2">
                        <b>Kantor BPJS Ketenagakerjaan Semarang:</b>
                        <span>Jl. Pemuda No. 130, Sekayu,</span>
                        <span className="d-block text-justify">
                          Semarang Tengah, Sekayu, Semarang Tengah,
                        </span>
                        <span className="d-block text-justify">Kota Semarang,</span>
                        <span className="d-block text-justify">Jawa Tengah 50132</span>
                      </Row>
                      <Row className="d-flex flex-column mt-2">
                        <b>Kantor BPJS Ketenagakerjaan Bandung:</b>
                        <span>Jl. Lodaya No.42S, Turangga,</span>
                        <span className="d-block text-justify">Lengkong, Kota Bandung,</span>
                        <span className="d-block text-justify">Jawa Barat 40262</span>
                      </Row>
                      <Row className="d-flex flex-column mt-2">
                        <b>Kantor BPJS Ketenagakerjaan Pekanbaru:</b>
                        <span>Jl. Tengku Zainal Abidin No.26,</span>
                        <span className="d-block text-justify">Sekip, LimaPuluh,</span>
                        <span className="d-block text-justify">Kota Pekanbaru, Riau 28151</span>
                      </Row>
                      <Row className="d-flex flex-column mt-2">
                        <b>Kantor BPJS Ketenagakerjaan Surabaya:</b>
                        <span>Jl. Karimun Jawa No.6, Gubeng,</span>
                        <span className="d-block text-justify">Kota SBY, Jawa Timur 60281</span>
                      </Row>
                      <Row className="d-flex flex-column mt-2">
                        <b>Kantor BPJS Ketenagakerjaan Medan:</b>
                        <span>Jalan Kapten Patimura No. 334,</span>
                        <span className="d-block text-justify">
                          Medan Baru, D A R A T, Medan Baru,
                        </span>
                        <span className="d-block text-justify">
                          Kota Medan, Sumatera Utara 20152
                        </span>
                      </Row>
                      <Row className="d-flex flex-column mt-2">
                        <b>Kantor BPJS Ketenagakerjaan Palembang:</b>
                        <span>Jalan Sudirman No.131,</span>
                        <span className="d-block text-justify">
                          20 Ilir D. I, Ilir Timur I, 20 Ilir D. I,
                        </span>
                        <span className="d-block text-justify">Ilir Tim. I, Kota Palembang,</span>
                        <span className="d-block text-justify">Sumatera Selatan 30126</span>
                      </Row>
                      <Row className="d-flex flex-column mt-2">
                        <b>Kantor BPJS Ketenagakerjaan Lampung:</b>
                        <span>Jl. Drs. Warsito No.4, Talang,</span>
                        <span className="d-block text-justify">Tlk. Betung Sel.,</span>
                        <span className="d-block text-justify">
                          Kota Bandar Lampung, Lampung 35229
                        </span>
                      </Row>
                      <Row className="d-flex flex-column mt-2">
                        <b>Kantor BPJS Ketenagakerjaan Banjarmasin:</b>
                        <span>Jl. Brig Jend. Hasan Basri No.84A, Pangeran,</span>
                        <span className="d-block text-justify">
                          Banjarmasin Utara, Kota Banjarmasin,
                        </span>
                        <span className="d-block text-justify">Kalimantan Selatan 70124</span>
                      </Row>
                      <Row className="d-flex flex-column my-2">
                        <b>Kantor BPJS Ketenagakerjaan Makassar:</b>
                        <span>Jl. Urip Sumorarjo Km.4,5,</span>
                        <span className="d-block text-justify">Pampang, Kec. Makassar,</span>
                        <span className="d-block text-justify">
                          Kota Makassar, Sulawesi Selatan 90231
                        </span>
                      </Row>
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default AccordionBpjsTk
