const crypto = require('crypto')

function doValidateLiveData(msg) {
  const notLive = msg.getNotLiveData()
  const live = JSON.stringify(msg.getLiveData())

  const str = `LIVE|${live}`
  const generatedLiveData = crypto.createHash('sha256').update(str).digest('hex')

  return notLive === generatedLiveData
}

module.exports = {
  doValidateLiveData,
}
