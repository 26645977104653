import React from 'react'
import { Button, Image, Row, Col, Accordion, Card } from 'react-bootstrap'
import './accordionPln.scss'

const AccordionPln = () => {
  return (
    <>
      <Col className="d-flex justify-content-center mt-4 mb-3 p-3">
        <h3 className="text-dark text-justify">Cek Tagihan PLN Non Taglis Via SpeedCash</h3>
      </Col>

      <div style={{ padding: '0px 20px 30px 20px' }}>
        <Row className="justify-content-md-center">
          <Col lg={8} xs={12} md={8} className="content-pln">
            <div>
              <Row className="d-flex justify-content-center">
                <p className="d-block text-justify">
                  Rutinitas tagihan yang harus dibayarkan setiap bulannya di PLN Non Taglis ini
                  harus dilakukan secara rutin dan sebelum itu Anda bisa melakukan pengecekan
                  tagihan terlebih dahulu di SpeedCash. Cara untuk mengecek tagihan PLN Non Taglis
                  di SpeedCash ini cukup mudah, yaitu masuk terlebih dahulu ke halaman
                  <a href="https://member.speedcash.co.id"> https://member.speedcash.co.id</a> dan
                  setelah itu temukan kategori “PLN”. Langkah selanjutnya adalah pilih menu “PLN Non
                  Taglis” di kolom tagihan dan setelah itu Anda bisa langsung mengecek tagihan dari
                  PLN Non Taglis. Anda hanya perlu memasukan nomor registrasi/ID Pelanggan dengan
                  benar dan setelah itu pilih “Lihat Tagihan” maka secara otomatis akan muncul
                  jumlah tagihan yang harus dibayarkan.
                </p>
              </Row>
              <Row className="d-flex justify-content-center">
                <Image
                  src="/img/pln-nontaglis-banner.png"
                  data-target="pln-nontaglis-banner"
                  width="100%"
                />
              </Row>
              <Row className="d-flex mt-4 mb-2 justify-content-center text-dark">
                <h4>Bayar Tagihan PLN Non Taglis Online Di SpeedCash</h4>
              </Row>
              <Row className="d-flex justify-content-center">
                <p className="d-block text-justify">
                  <a href="https://member.speedcash.co.id/pln/non-taglis">
                    Bayar tagihan PLN Non Taglis
                  </a>{' '}
                  adalah aktivitas yang harus dilakukan untuk melakuakn pembayaran untuk layanan PLN
                  di luar tagihan listrik bulanan (pascabayar) dan pembelian token listrik
                  (prabayar).Cek tagihan PLN Non-Taglis sendiri adalah istilah yang digunakan untuk
                  menggambarkan pelanggan listrik yang tidak menggunakan sistem pembayaran tagihan
                  listrik secara bulanan. Ini bisa berarti pelanggan yang menggunakan sistem
                  prabayar atau pelanggan yang membayar tagihan listrik mereka secara langsung di
                  kantor PLN atau melalui website SpeedCash (tanpa aplikasi) maupun melalui aplikasi
                  SpeedCash yang sudah tersedia di playstore dan appstore.
                </p>
              </Row>
            </div>
          </Col>

          <Col lg={8} xs={12} md={8} className="accordion-pln">
            <Accordion className="m-0 p-0">
              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="1"
                    className="button-collapse w-100 text-left"
                  >
                    Bayar Tagihan PLN Non Taglis Melalui Website (tanpa aplikasi)
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="1">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Saat ini sungguh mudah untuk melakukan pembayaran tagihan PLN (perusahaan
                        listrik negera) untk Non Taglis (Non tagihan listrik) karena sudah bisa
                        dilakukan cepat,tepat dan pasti akurat hanya melalui website SpeedCash.
                        Berikut untuk cara bayar tagihan PLN non taglis via website (tanpa aplikasi)
                        di SpeedCash :
                      </p>
                    </Row>
                    <Row className="d-flex justify-content-start">
                      <ol className="text-justify list-content">
                        <li>
                          Kunjungi alamat web :{' '}
                          <a href="https://member.speedcash.co.id/pln/non-taglis">
                            https://member.speedcash.co.id/pln/non-taglis
                          </a>
                        </li>
                        <li>Isikan kode ID Pelanggan PLN kamu melalui kolom diatas</li>
                        <li>Lalu klik lanjutkan</li>
                        <li>
                          Dan akan muncul total tagihan listrik PLN (non taglis) yang harus kamu
                          bayar
                        </li>
                        <li>Klik Daftar/Login (jika kamu belum punya akun SpeedCash)</li>
                        <li>
                          Jika kamu sudah punya akun SpeedCash (jangan lupa untuk isi saldo
                          SpeedCash mu dulu ya)
                        </li>
                        <li>Lalu pilih lanjutkan/bayar tagihan</li>
                      </ol>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="2"
                    className="button-collapse w-100 text-left"
                  >
                    Bayar Tagihan PLN Non Taglis Melalui Aplikasi
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="2">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Ternyata untuk melakukan pembayar tagihan PLN non taglis tidak hanya bisa
                        melalui website saja, jika ingin bayar tagihan PLN non taglis juga dapat
                        dilakukan via aplikasi loh.Berikut cara bayar tagihan PLN Non taglis jika
                        menggunakan aplikasi SpeedCash
                      </p>
                      <Row className="mt-3">
                        <ol className="text-justify list-content">
                          <li>
                            <p>
                              Download aplikasi SpeedCash yang tersedia di playstore/IOS (untuk
                              pengguna iphone)
                            </p>
                            <Row className="d-flex justify-content-center">
                              <Image
                                src="/img/pln-nontaglis1.png"
                                data-target="pln-nontaglis1"
                                width="220px"
                                height="400px"
                              />
                            </Row>
                          </li>
                          <li>Jika sudah download, login menggunakan no hp yang aktif</li>
                          <li>
                            <p>
                              Jika sudah masuk ke menu <strong>PLN Token-Tagihan</strong>
                            </p>
                            <Row className="d-flex justify-content-center">
                              <Image
                                src="/img/pln-nontaglis2.png"
                                data-target="pln-nontaglis2"
                                width="220px"
                                height="400px"
                              />
                            </Row>
                          </li>
                          <li>
                            Jika sudah pilih menu <strong>Tagihan PLN</strong>
                          </li>
                          <li>Lalu masukkan kode pelanggan / ID pelanggan PLN</li>
                          <li>
                            <p>
                              Jika sudah lalu akan muncul total tagihan yang harus dibayar (nampak
                              seperti gambar dibawah ini)
                            </p>
                            <Row className="d-flex justify-content-center">
                              <Image
                                src="/img/pln-nontaglis3.png"
                                data-target="pln-nontaglis3"
                                width="220px"
                                height="400px"
                              />
                            </Row>
                          </li>
                        </ol>
                      </Row>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="3"
                    className="button-collapse w-100 text-left"
                  >
                    Mengapa Harus Bayar Tagihan PLN Non Taglis Di SpeedCash
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="3">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Membayar tagihan PLN non taglis merupakan kewajiban bagi masyarakat yang
                        ingin mendapakan pelayanan selain listrik pascabayar maupun listrik
                        prabayar.Sehingga PLN non taglis merupakan aktivitas yang harus dilakukan
                        semua orang sebelum mendapatkan manfaat layanan dari PLN.
                      </p>
                      <p className="d-block text-justify mt-1">
                        Oleh sebab itu kehadiran SpeedCash untuk melayani dan memudahkan bayar
                        tagihan PLN non taglis secara online baik via aplikasi maupun via website
                        bisa dilakukan kapanpun dan dimanapun asalkan kamu terhubung dengan internet
                        maka bisa dilakukan.
                      </p>
                      <p className="d-block text-justify mt-1">
                        Menghindari antrian, dan daripada harus keluar rumah atau meninggalkan
                        aktivitas penting lainnya, mengecek tagihan dan membayar PLN non taglis via
                        SpeedCash tergolong jauh lebih mudah,cepat dan praktis.
                      </p>
                      <p className="d-block text-justify mt-1">
                        Tidak hanya itu setelah kamu membayar tagihan di SpeedCash kamu juga
                        mendapatkan bukti bayar tagihan PLN Non taglis yang bisa kamu simpan atau
                        kamu cetak jika kamu memerlukan bukti tersebut.Sehingga mengecek tagihan
                        listrik dan membayar tagihan listrik PLN di SpeedCash sangat disarankan
                        karena kemudahannya.
                      </p>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="4"
                    className="button-collapse w-100 text-left"
                  >
                    Keuntungan Bayar Tagihan PLN Non Taglis Melalui SpeedCash
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="4">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Melakukan pembayaran tagihan PLN non taglis via SpeedCash, memberikan
                        beragam keuntungan yang bisa kamu manfaatkan.Berikut adalah beberapa
                        keuntungan yang bisa Anda dapatkan dengan membayar tagihan PLN non-taglis di
                        SpeedCash:
                      </p>
                    </Row>
                    <Row className="d-flex justify-content-start">
                      <ol className="text-justify list-content">
                        <li>
                          Praktis tidak harus via aplikasi,dapat dilakukan kapan saja dan di mana
                          saja karena sudah menerapkan sistem pembayaran secara online dan cara ini
                          menjadi lebih praktis.
                        </li>
                        <li>
                          Setiap tagihan yang dibayarkan secara otomatis akan tercatat di sistem
                          SpeedCash sehingga akan terhindar dari denda atau pembayaran yang telat.
                        </li>
                        <li>
                          Sistem keamanan pembayaran yang sudah sangat terjamin dan melindungi
                          setiap data pribadi yang dimasukan sehingga akan membuat transaksi
                          pembayaran menjadi lebih nyaman. Tak hanya saat bayar tagihan PLN Non
                          Taglis saja, tetapi di SpeedCash juga bisa melakukan{' '}
                          <a href="https://member.speedcash.co.id/pln/cek-tagihan-listrik">
                            cek tagihan listrik
                          </a>
                          dan pembelian{' '}
                          <a href="https://member.speedcash.co.id/pln/token-listrik">
                            token listrik
                          </a>{' '}
                          Beragam keuntungan membayar tagihan PLN Non Taglis dan tagihan lainnya
                          bisa didapatkan dengan mudah jika membayar melalui SpeedCash.
                        </li>
                        <li>
                          Diskon pasti,jika kamu melakukan pembayaran tagihan/pembelian
                          pulsa/transaksi apapun dipastikan kamu akan mendapatkan diskon setiap
                          transaksinya.Ga percaya ?? cuss wajib coba bayar tagihan bulanan di
                          SpeedCash.
                        </li>
                        <li>
                          Customer service (CS) siap membantu 24 jam, kapanpun dimanapun ketika kamu
                          mendapatkan kendala saat membayar tagihan PLN baik non taglis maupun
                          tagihan bulanan lainnya.Kamu bisa langsung menghubungi CS dengan klik
                          tombol bantuan dipojok kanan bawah.
                        </li>
                        <li>
                          Memakai SpeedCash terbukti memberikan jutaan kemudahan karena SpeedCash
                          memiliki produk unggulan lainnya yang bisa kamu manfaatkan seperti :
                          <a href="https://www.speedcash.co.id/transfer-bank-gratis">
                            transfer bank gratis
                          </a>
                          ,{' '}
                          <a href="https://www.speedcash.co.id/pesan-tiket-kereta-api-online">
                            beli tiket kereta api gratis admin
                          </a>
                          ,{' '}
                          <a href="https://www.speedcash.co.id/pesan-tiket-pesawat-promo-online">
                            tiket pesawat
                          </a>{' '}
                          garansi harga termurah,bayar tilang online dan masih banyak keunggulan
                          lainnya.
                        </li>
                      </ol>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="5"
                    className="button-collapse w-100 text-left"
                  >
                    Mengisi/Top up Saldo SpeedCash Apakah Wajib ?
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="5">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Langkah selanjutnya yang harus diperhatikan untuk melakukan
                        pengecekkan/meilhat jumlah tagihan PLN non taglis yang harus dibayarkan
                        ialah mengisi saldo/top up.Dan untuk ketentuannya tidak ada nominal khusus
                        yang harus di top up kamu bisa isi saldo SpeedCash sesuai dengan jumlah dana
                        yang harus dibayarkan untuk membayar tagihan PLN non taglis.
                      </p>
                      <Row className="d-flex w-100 justify-content-center mt-3">
                        <h5>Berikut Video Mengenai Cara Isi Saldo Di SpeedCash</h5>
                      </Row>
                      <p className="d-block text-justify mt-1">
                        Sebelum menggunakan SpeedCash untuk melakukan aktivitas bayar tagihan PLN
                        non taglis akan lebih cepat dan semakin memudahkan jika kamu sudah mengisi
                        saldo SpeedCash.Saat ini mengisi saldo SpeedCash juga semakin mudah karena
                        kamu bisa top up saldo melalui indomaret,alfamaret,virtual account bank
                        maupun transfer bank seperti : BCA,bank syariah mandiri,BRIVA,bank
                        permata,BNI,CIMB niaga,bank mandiri.
                      </p>
                      <Row className="d-flex w-100 justify-content-center">
                        <iframe
                          width="100%"
                          height="480"
                          src="https://www.youtube.com/embed/KMGeMvCH8rA"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                          frameBorder={0}
                          title="Cara Isi Saldo Di SpeedCash"
                        />
                      </Row>

                      <div className="d-flex w-100 flex-column justify-content-center mt-4">
                        <p className="d-block text-center">
                          <strong>Jenis Layanan PLN Non Taglis</strong>
                        </p>
                        <Row className="d-block mt-2">
                          <p>
                            Ternyata ini, jenis-jenis layanan PLN Non taglis yang wajib untuk kamu
                            ketahui:{' '}
                          </p>
                          <ul className="text-justify list-content">
                            <li>Penyambungan baru</li>
                            <li>Perubahan daya</li>
                            <li>Migrasi ke Prabayar/Pascabayar</li>
                            <li>Pemasangan kembali</li>
                            <li>Penerangan Sementara</li>
                            <li>Pelunasan angsuran</li>
                            <li>Pengaduan teknik</li>
                            <li>Sewa trafo, kapasitor, dan operasi paralel</li>
                          </ul>
                        </Row>
                      </div>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="6"
                    className="button-collapse w-100 text-left"
                  >
                    Penyambungan Baru Pada Layanan PLN Non Taglis
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="6">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Penyambungan baru PLN adalah proses pemasangan sambungan listrik baru oleh
                        PT PLN (Persero) atau Perusahaan Listrik Negara. Proses ini dilakukan ketika
                        seseorang atau sebuah bangunan membutuhkan pasokan listrik baru, misalnya
                        saat membangun rumah baru, gedung, atau fasilitas lainnya yang memerlukan
                        pasokan listrik.
                      </p>
                      <Row className="d-flex w-100 justify-content-center mt-3">
                        <h5>
                          Proses Penyambungan Baru Pada Layanan PLN Non Taglis Melibatkan Beberapa
                          Tahapan, Antara lain:
                        </h5>
                      </Row>
                      <div className="mt-3">
                        <p className="d-block text-justify">
                          <strong>Pendaftaran:</strong> Pemohon harus mendaftarkan permohonan
                          penyambungan baru ke PLN. Biasanya, ini dilakukan melalui kantor PLN
                          setempat atau melalui aplikasi online yang disediakan oleh PLN.
                        </p>
                      </div>
                      <div className="mt-3">
                        <p className="d-block text-justify">
                          <strong>Pengukuran:</strong> Setelah pendaftaran, petugas PLN akan
                          melakukan survei dan pengukuran untuk menentukan kebutuhan daya listrik
                          yang dibutuhkan oleh pemohon. Ini penting karena biaya penyambungan baru
                          akan bergantung pada kebutuhan daya listrik tersebut.
                        </p>
                      </div>
                      <div className="mt-3">
                        <p className="d-block text-justify">
                          <strong>Pembayaran:</strong> Setelah pengukuran selesai, pemohon harus
                          membayar biaya penyambungan baru sesuai dengan kebutuhan daya listrik yang
                          ditentukan. Biaya ini meliputi biaya administrasi, biaya instalasi, dan
                          biaya penyambungan.
                        </p>
                      </div>
                      <div className="mt-3">
                        <p className="d-block text-justify">
                          <strong>Pemasangan:</strong> Setelah pembayaran selesai, petugas PLN akan
                          melakukan pemasangan sambungan listrik baru sesuai dengan kebutuhan daya
                          listrik yang telah ditentukan.
                        </p>
                      </div>
                      <div className="mt-3">
                        <p className="d-block text-justify">
                          <strong>Pengujian:</strong> Setelah pemasangan selesai, petugas PLN akan
                          melakukan pengujian untuk memastikan bahwa sambungan listrik baru
                          berfungsi dengan baik dan aman.
                        </p>
                      </div>
                      <div className="mt-3">
                        <p className="d-block text-justify">
                          <strong>Penyambungan:</strong> Setelah pengujian selesai, sambungan
                          listrik baru akan dihubungkan ke jaringan listrik PLN, dan pemohon akan
                          mendapatkan pasokan listrik baru.
                        </p>
                      </div>
                      <p className="d-block text-justify mt-1">
                        Proses penyambungan baru PLN ini membutuhkan waktu dan biaya tertentu, nah
                        kamu tidak perlu khawatir mengenai cara bayar biaya tagihan PLN Non Taglis
                        ini karena kamu sudah tepat menemukan SpeedCash. namun penting untuk
                        memastikan bahwa pasokan listrik yang diberikan aman dan sesuai dengan
                        kebutuhan.
                      </p>
                      <div>
                        <h4 className="text-center">
                          Pengertian Perubahan Daya Di Layanan PLN Non Taglis
                        </h4>
                        <p className="d-block text-justify">
                          Perubahan daya PLN adalah proses di mana pelanggan listrik mengajukan
                          permohonan untuk meningkatkan atau menurunkan daya listrik yang mereka
                          gunakan. Ini bisa terjadi karena berbagai alasan, seperti perubahan
                          kebutuhan listrik di rumah atau bisnis, atau karena perubahan dalam
                          peraturan atau kebijakan PLN.
                        </p>
                      </div>
                      <div>
                        <h4 className="text-center">Layanan Migrasi Di PLN Non Taglis</h4>
                        <p className="d-block text-justify">
                          Layanan migrasi dari pascabayar ke prabayar di PLN adalah layanan yang
                          disediakan oleh PT PLN (Persero) atau Perusahaan Listrik Negara untuk
                          memfasilitasi pelanggan yang ingin beralih dari sistem pembayaran
                          pascabayar ke sistem pembayaran prabayar. Ini bisa terjadi karena berbagai
                          alasan, seperti perubahan preferensi pembayaran pelanggan atau untuk
                          mengelola pengeluaran listrik dengan lebih efisien.
                        </p>
                      </div>
                      <div>
                        <h4 className="text-center">
                          Pemasangan Kembali Daya Listrik (PLN Non Taglis)
                        </h4>
                        <p className="d-block text-justify">
                          Pemasangan daya listrik di PLN Non-Taglis adalah proses pemasangan
                          sambungan listrik yang dilakukan oleh PT PLN (Persero) atau Perusahaan
                          Listrik Negara untuk pelanggan yang tidak menggunakan sistem pembayaran
                          tagihan listrik secara bulanan. Ini bisa berarti pelanggan yang
                          menggunakan sistem prabayar atau pelanggan yang membayar tagihan listrik
                          mereka secara langsung di kantor PLN atau melalui agen pembayaran.
                        </p>
                      </div>
                      <div>
                        <h4 className="text-center">
                          Bayar Sewa Trafo,Kapsitor Dan Operasi Pararel Di Layanan PLN Non Taglis
                        </h4>
                        <p className="d-block text-justify">
                          PT PLN (Persero) atau Perusahaan Listrik Negara adalah penyedia layanan
                          listrik terbesar di Indonesia. Salah satu layanan yang disediakan oleh PLN
                          adalah layanan Non-Taglis, yang merupakan layanan bagi pelanggan yang
                          tidak menggunakan sistem pembayaran tagihan listrik secara bulanan. Dalam
                          layanan ini, pelanggan dapat membayar sewa trafo, kapsitor, dan operasi
                          pararel.
                        </p>
                      </div>
                      <div className="mt-3">
                        <p className="d-block text-justify">
                          <strong>Trafo adalah</strong> perangkat yang digunakan untuk mengubah
                          tegangan listrik dari satu tingkat ke tingkat yang lain. Trafo ini
                          biasanya ditempatkan di gardu distribusi dan digunakan untuk mengubah
                          tegangan listrik dari tegangan tinggi menjadi tegangan rendah sehingga
                          dapat digunakan oleh pelanggan. Pelanggan yang menggunakan layanan PLN
                          Non-Taglis dapat membayar sewa trafo sesuai dengan kebutuhan daya listrik
                          yang mereka gunakan.
                        </p>
                      </div>
                      <div className="mt-3">
                        <p className="d-block text-justify">
                          <strong>Kapasitor adalah</strong> perangkat yang digunakan untuk
                          meningkatkan faktor daya listrik. Faktor daya adalah rasio antara daya
                          aktif (watt) dan daya reaktif (VAR). Kapsitor digunakan untuk mengurangi
                          daya reaktif sehingga meningkatkan efisiensi penggunaan listrik. Pelanggan
                          yang menggunakan layanan PLN Non-Taglis dapat membayar sewa kapsitor
                          sesuai dengan kebutuhan daya listrik mereka.
                        </p>
                      </div>
                      <div className="mt-3">
                        <p className="d-block text-justify">
                          <strong>Operasi pararel adalah</strong> operasi yang dilakukan untuk
                          menghubungkan dua atau lebih sumber listrik secara pararel. Ini dilakukan
                          untuk meningkatkan keandalan pasokan listrik dan memastikan bahwa pasokan
                          listrik tetap stabil. Pelanggan yang menggunakan layanan PLN Non-Taglis
                          dapat membayar biaya operasi pararel sesuai dengan kebutuhan daya listrik
                          mereka.
                        </p>
                      </div>
                      <div className="mt-3">
                        <p className="d-block text-justify">
                          <strong>Layanan PLN Non-Taglis</strong> adalah layanan yang disediakan
                          oleh PT PLN (Persero) atau Perusahaan Listrik Negara bagi pelanggan yang
                          tidak menggunakan sistem pembayaran tagihan listrik secara bulanan. Dalam
                          layanan ini, pelanggan dapat membayar sewa trafo, kapsitor, dan operasi
                          pararel sesuai dengan kebutuhan daya listrik mereka. Semua biaya ini harus
                          dibayar oleh pelanggan untuk memastikan bahwa pasokan listrik yang
                          diberikan oleh PLN tetap stabil dan andal.
                        </p>
                      </div>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="7"
                    className="button-collapse w-100 text-left"
                  >
                    Cek Tagihan PLN Non Taglis Via Website Resmi
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="7">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Salah satu layanan yang disediakan oleh PLN adalah layanan Non-Taglis, yang
                        merupakan layanan bagi pelanggan yang tidak menggunakan sistem pembayaran
                        tagihan listrik secara bulanan. Dalam layanan ini, pelanggan dapat membayar
                        tagihan listrik mereka melalui berbagai cara, salah satunya adalah melalui
                        website resmi PLN.
                      </p>
                      <div className="w-100 mt-1">
                        <h4 className="text-center">
                          Cara Cek Tagihan PLN Non-Taglis Melalui Website Resmi PLN :
                        </h4>
                        <Row className="mt-3">
                          <ol className="text-justify list-content">
                            <li>Kunjungi website PLN: www.pln.co.id</li>
                            <li>Pilih menu &ldquo;Pelayanan Pelanggan&rdquo;.</li>
                            <li>Klik &ldquo;Cek Tagihan&rdquo;.</li>
                            <li>Pilih &ldquo;Non Taglis&rdquo;.</li>
                            <li>Masukkan nomor registrasi.</li>
                            <li>Klik &ldquo;Cari&rdquo;.</li>
                            <li>Tagihan Anda akan muncul di layar.</li>
                          </ol>
                        </Row>
                      </div>
                      <div className="w-100 mt-1">
                        <h4 className="text-center">
                          Bayar Tagihan PLN Non-Taglis Via Aplikasi PLN Mobile
                        </h4>
                        <Row className="mt-3">
                          <p>Cara Membayar Tagihan PLN Non-Taglis di Aplikasi PLN Mobile</p>
                          <ol className="text-justify list-content">
                            <li>
                              Unduh aplikasi PLN Mobile dari Google Play Store atau Apple App Store.
                            </li>
                            <li>Buka aplikasi PLN Mobile dan masuk menggunakan akun PLN Anda.</li>
                            <li>
                              Pilih menu &ldquo;Pembayaran&rdquo; dan pilih &ldquo;Tagihan
                              Listrik&rdquo;.
                            </li>
                            <li>
                              Masukkan nomor ID pelanggan Anda dan klik tombol &ldquo;Cari&rdquo;.
                            </li>
                            <li>
                              Anda akan melihat informasi tagihan listrik Anda, termasuk jumlah
                              tagihan, tanggal jatuh tempo, dan jumlah yang harus dibayar.
                            </li>
                            <li>
                              Jika Anda ingin membayar tagihan listrik Anda melalui aplikasi PLN
                              Mobile, klik tombol &ldquo;Bayar Sekarang&rdquo; dan ikuti petunjuk
                              yang diberikan.
                            </li>
                          </ol>
                        </Row>
                      </div>
                      <div className="w-100 mt-1">
                        <h4 className="text-center">
                          Cek Dan Bayar Tagihan PLN Non Taglis Via Call Center
                        </h4>
                        <Row className="mt-3">
                          <ol className="text-justify list-content">
                            <li>Hubungi Call Center PLN 123 melalui telepon atau handphone.</li>
                            <li>Pilih menu &ldquo;Cek Tagihan&rdquo;.</li>
                            <li>Ikuti instruksi yang diberikan oleh operator.</li>
                            <li>Sebutkan nomor registrasi Anda.</li>
                            <li>Operator akan memberitahukan tagihan Anda.</li>
                          </ol>
                        </Row>
                      </div>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="8"
                    className="button-collapse w-100 text-left"
                  >
                    Kantor Pusat PLN
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="8">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Kantor Pusat PT PLN (Persero) terletak di Jalan Trunojoyo Nomor 1, Kebayoran
                        Baru, Jakarta Selatan, DKI Jakarta. Kantor Pusat PLN ini merupakan kantor
                        pusat dari seluruh kegiatan operasional PT PLN (Persero) di seluruh
                        Indonesia.
                      </p>
                      <div className="d-flex flex-column">
                        <strong className="mb-1">
                          Berikut adalah alamat lengkap Kantor Pusat PT PLN (Persero):
                        </strong>
                        <span>
                          Jalan Trunojoyo Nomor 1, Kebayoran Baru, Jakarta Selatan, DKI Jakarta
                        </span>
                        <span>Kode Pos: 12110Telepon: (021) 7261123</span>
                        <span>Faksimili: (021) 7261124</span>
                      </div>
                      <div className="d-flex flex-column">
                        <p className="d-block text-justify mt-3">
                          Kantor PLN di Indonesia dapat dikelompokkan menjadi beberapa kategori,
                          yaitu:
                        </p>
                        <Row className="mt-1">
                          <ul className="text-justify list-content">
                            <li>PLN Kantor Pusat</li>
                            <li>Kantor Wilayah PLN</li>
                            <li>Kantor Wilayah PLN</li>
                            <li>Kantor Unit Customer Service (ULP)</li>
                            <li>Unit Office Mobile Layanan Pelanggan (ULP Seluler)</li>
                          </ul>
                        </Row>
                      </div>
                      <div className="mt-3">
                        <h4 className="d-block">Kantor Wilayah PLN</h4>
                        <p className="d-block text-justify">
                          Kantor Wilayah PLN adalah kantor yang membawahi beberapa Kantor Area PLN.
                          Kantor Wilayah PLN bertanggung jawab atas operasional PLN di wilayah kerja
                          masing-masing.
                        </p>
                      </div>
                      <div className="mt-3">
                        <h4 className="d-block">Kantor Area PLN</h4>
                        <p className="d-block text-justify">
                          Kantor Area PLN adalah kantor yang membawahi beberapa Kantor Unit Layanan
                          Pelanggan. Kantor Area PLN bertanggung jawab atas operasional PLN di
                          wilayah kerja masing-masing.
                        </p>
                      </div>
                      <div className="mt-3">
                        <h4 className="d-block">Kantor Unit PLN</h4>
                        <p className="d-block text-justify">
                          Kantor Unit Layanan Pelanggan (ULP) adalah kantor yang melayani pelanggan
                          PLN di tingkat kabupaten/kota. ULP menyediakan berbagai layanan pelanggan,
                          seperti:
                        </p>
                        <Row className="mt-1">
                          <ul className="text-justify list-content">
                            <li> Layanan informasi tagihan listrik</li>
                            <li> Layanan perubahan daya</li>
                            <li> Layanan penyambungan baru</li>
                            <li> Layanan pengaduan pelanggan</li>
                            <li> Layanan lainnya</li>
                          </ul>
                        </Row>
                      </div>
                      <div className="mt-3">
                        <h4 className="d-block">Kantor Unit Layanan Pelangggan Keliling</h4>
                        <p className="d-block text-justify">
                          Kantor Unit Layanan Pelanggan Keliling (ULP Keliling) adalah kantor yang
                          melayani pelanggan PLN di daerah-daerah terpencil dan terluar. ULP
                          Keliling menyediakan layanan pelanggan yang sama dengan ULP.
                        </p>
                      </div>
                      <p className="d-block text-justify mt-1">
                        Itulah tadi informasi mengenai cek tagihan listrik PLN dan langsung{' '}
                        <a href="https://www.speedcash.co.id/">bayar tagihan listrik bulanan</a> di
                        SpeedCash, jika kamu merasa informasi ini sangat bermanfaat dan alat cek
                        tagihan listrik ini bisa membantu untuk mengontrol tagihan listrik
                        bulanan,Kamu bisa mendownload aplikasi SpeedCash melalui link download
                        dibawah ini.
                      </p>
                      <div className="mt-1 w-100 d-flex justify-content-center">
                        <h5 className="bold">Cek Dan Bayar Tagihan PLN Di Aplikasi Lebih Cepat:</h5>
                      </div>
                      <Row className="d-flex w-100 justify-content-center mb-3 gap-3">
                        <a
                          aria-hidden
                          href="https://play.google.com/store/apps/details?id=com.bm.sc.bebasbayar&listing=token-listrik"
                          className="mx-1"
                        >
                          <Image
                            src="/img/playstore-speedcash.png"
                            data-target="playstore-speedcash"
                            width="220px"
                            height="60px"
                          />
                        </a>
                        <a
                          aria-hidden
                          href="https://apps.apple.com/id/app/speedcash-transfer-ewallet/id6459304122"
                          className="mx-1"
                        >
                          <Image
                            src="/img/appstore-speedcash.png"
                            data-target="appstore-speedcash"
                            width="220px"
                            height="60px"
                          />
                        </a>
                      </Row>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default AccordionPln
