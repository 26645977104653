import { combineReducers } from 'redux'
import globalReducer from './reducer/global'
import authReducer from './reducer/auth'
import profileReducer from './reducer/profile'
import produkReducer from './reducer/produk'

const appReducer = combineReducers({
  Global: globalReducer,
  Auth: authReducer,
  Profile: profileReducer,
  Produk: produkReducer,
})

const rootReducer = (state, action) => {
  if (action.type === 'USER_LOGOUT') {
    return appReducer(undefined, action)
  }

  return appReducer(state, action)
}

export default rootReducer
