import BaseMessage from '../base'

export default class ChangePinMessage extends BaseMessage {
  constructor(data) {
    super(data)

    this.setPath(`${ChangePinMessage.SC_API}/otp/change_pin`)

    if (!data) {
      this.data.appid = 'SPEEDCASH'
      this.data.location = '0,0'
    }
  }

  // eslint-disable-next-line class-methods-use-this
  setPhone(phone) {
    this.data.no_telp = phone
  }

  setPIN(pin) {
    this.data.pin = pin
  }

  setOtp(otp) {
    this.data.otp = otp
  }
}
