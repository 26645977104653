import { library } from '@fortawesome/fontawesome-svg-core'
import * as FA from '@fortawesome/free-solid-svg-icons'
import * as FAB from '@fortawesome/free-brands-svg-icons'

library.add(
  FA.faArrowLeft,
  FA.faArrowRight,
  FA.faBars,
  FA.faBolt,
  FA.faBookReader,
  FA.faBuilding,
  FA.faBurn,
  FA.faCalendar,
  FA.faCalculator,
  FA.faCashRegister,
  FA.faCheckCircle,
  FA.faChevronCircleRight,
  FA.faChevronDown,
  FA.faChevronRight,
  FA.faCode,
  FA.faCommentDots,
  FA.faCopy,
  FA.faCreditCard,
  FA.faDownload,
  FA.faExchangeAlt,
  FA.faEye,
  FA.faEyeSlash,
  FA.faFileAlt,
  FA.faFileCsv,
  FA.faFileDownload,
  FA.faFileExcel,
  FA.faFileExport,
  FA.faFileImage,
  FA.faFileImport,
  FA.faFilePdf,
  FA.faFileUpload,
  FA.faGamepad,
  FA.faGlobe,
  FA.faHandHoldingHeart,
  FA.faHeadset,
  FA.faHome,
  FA.faHourglassEnd,
  FA.faHourglass,
  FA.faInfo,
  FA.faInfoCircle,
  FA.faKey,
  FA.faLandmark,
  FA.faList,
  FA.faMobile,
  FA.faMobileAlt,
  FA.faMotorcycle,
  FA.faPhone,
  FA.faPhoneSquareAlt,
  FA.faPhoneVolume,
  FA.faPlane,
  FA.faPrint,
  FA.faSearch,
  FA.faShieldAlt,
  FA.faShoppingCart,
  FA.faSignInAlt,
  FA.faSignature,
  FA.faSignOutAlt,
  FA.faSms,
  FA.faSubway,
  FA.faSync,
  FA.faTags,
  FA.faTint,
  FA.faTimesCircle,
  FA.faTv,
  FA.faUniversity,
  FA.faUser,
  FA.faUserSecret,
  FA.faUserShield,
  FA.faWallet,
  FAB.faGooglePlay,
  FAB.faWhatsapp,
  FAB.faWhatsappSquare,
  FA.faMoneyBillAlt,
  FA.faUserFriends,
  FA.faAddressBook,
  FA.faCoins,
  FA.faDollarSign,
  FA.faUsers,
  FA.faFunnelDollar
)
