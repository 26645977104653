import React from 'react'
import { Button, Image, Row, Col, Accordion, Card } from 'react-bootstrap'
import './accordionGame.scss'

const AccordionGameFf = () => {
  return (
    <>
      <Col className="d-flex justify-content-center mt-4 mb-3 p-3">
        <h3 className="text-dark text-justify">
          Disini Tempat Top Up FF Termurah Banyak Pilihan Diamond
        </h3>
      </Col>

      <div style={{ padding: '0px 20px 30px 20px' }}>
        <Row className="justify-content-md-center">
          <Col lg={8} xs={12} md={8} className="content-game">
            <div>
              <Row className="d-flex justify-content-center">
                <p className="d-block text-justify">
                  Mencari tempat{' '}
                  <a href="https://member.speedcash.co.id/game/top-up-ff">top up termurah</a>{' '}
                  merupakan pilihan banyak pecinta game free fire, sebuah game besutan garena ini
                  banyak sekali memiliki user aktif yang hampir setiap hari, jutaan orang setiap jam
                  selalu memainkan permainan free fire.Mendapatkan diamond FF dengan mencari tempat
                  top up ff termurah. Disini kami akan berikan info mengenai cara top up ff termurah
                  menggunakan SpeedCash.
                </p>
              </Row>
              <Row className="d-flex justify-content-center">
                <Image src="/img/banner-ff.png" data-target="banner-ff" width="100%" />
              </Row>
            </div>
          </Col>

          <Col lg={8} xs={12} md={8} className="accordion-game">
            <Accordion className="m-0 p-0">
              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="1"
                    className="button-collapse w-100 text-left"
                  >
                    Top Up Diamond FF Di SpeedCash
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="1">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Untuk mengisi saldo/top up diamond free fire di speedcash kamu bisa
                        mengikuti langkah mudah berikut ini :
                      </p>
                    </Row>
                    <Row className="d-flex justify-content-start">
                      <ol className="text-justify list-content">
                        <li>Klik Masuk / Login</li>
                        <li>Masukkan nomor telepon kamu, lalu lanjutkan</li>
                        <li>Ikuti langkah-langkahnya, klik lanjutkan</li>
                        <li>Tunggu ada notifikasi yang masuk via WA/SMS</li>
                        <li>
                          Masukkan 4 digit terakhir nomor yang diberikan via WA/SMS, lalu klik
                          lanjutkan.
                        </li>
                        <li>Pertama pastikan isi saldo SpeedCashmu dulu </li>
                        <li>Jika sudah lanjutkan dengan pilih menu VOUCHER GAME</li>
                        <li>Lalu Pilih menu FREE FIRE atau pilih game yang kamu inginkan.</li>
                        <li>Pilih nominalnya, kemudian beli.</li>
                        <li>Ikuti instruksi yang diberikan</li>
                      </ol>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="2"
                    className="button-collapse w-100 text-left"
                  >
                    Top Up FF Melalui Aplikasi Resmi SpeedCash
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="2">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Ternyata untuk mendapatkan diamond FF kamu bisa juga menggunakan aplikasi
                        resmi SpeedCash yang sudah tersedia di playstore ataupun IOS, berikut langkh
                        cepat untuk top up FF termurah di aplikasi SpeedCash baik untuk android
                        maupun IOS.
                      </p>
                      <Row className="mt-3">
                        <ol className="text-justify list-content">
                          <li>
                            Download SpeedCash jika kamu belum punya (speedcash tersedia di android
                            dan IOS)
                          </li>
                          <li>
                            Masuk/daftar SpeedCash (jika sudah pernah daftar, kamu bisa login
                            langsung menggunakan no hp yang terdaftar di SpeedCash)
                          </li>
                          <li>Jika sudah, kamu bisa isi saldo SpeedCash dulu</li>
                          <li>
                            <p>Lalu pilih menu VOUCHER GAME</p>
                            <Row className="d-flex justify-content-center">
                              <Image
                                src="/img/voucher-game1.png"
                                data-target="voucher-game1"
                                width="220px"
                                height="400px"
                              />
                            </Row>
                          </li>
                          <li>
                            <p>Pilih Top Up</p>
                            <Row className="d-flex justify-content-center">
                              <Image
                                src="/img/voucher-game2.png"
                                data-target="voucher-game2"
                                width="220px"
                                height="400px"
                              />
                            </Row>
                          </li>
                          <li>Lanjutkan dengan klik icon / pilih Free Fire</li>
                          <li>
                            <p>Masukan ID Pengguna</p>
                            <Row className="d-flex justify-content-center">
                              <Image
                                src="/img/voucher-game3.png"
                                data-target="voucher-game3"
                                width="220px"
                                height="400px"
                              />
                            </Row>
                          </li>
                          <li>Pilih diamond FF yang sesuai dengan pilihan kamu</li>
                          <li>Ikuti instruksi selanjutnya hingga kamu berhasil top up</li>
                        </ol>
                      </Row>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="4"
                    className="button-collapse w-100 text-left"
                  >
                    Keunggulan membeli/ top up diamond FF termurah di SpeedCash
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="4">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Dengan membeli diamond FREE FIRE (FF) di SpeedCash kamu mendapatkan banyak
                        kelebihan dibandingkan jika kamu membeli diamond FF di tempat lain, berikut
                        banyaknya keunggulan yang kamu dapatkan jika mengisi/
                        <a href="https://www.speedcash.co.id/">top up FF di SpeedCash</a>:
                      </p>
                    </Row>
                    <Row className="d-flex justify-content-start">
                      <ol className="text-justify list-content">
                        <li>Banyaknya pilihan Denom Diamond FF yang bisa kamu pilih</li>
                        <li>Tersedia denom diamond mulai 5 Free Fire Diamond</li>
                        <li>Harga Diamond mulai 800 rupiah</li>
                        <li>Bisa top up Diamond tanpa aplikasi</li>
                        <li>
                          Masih banyak kelebihan yang bisa kamu dapatkan jika top up ff di SpeedCash
                        </li>
                      </ol>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="5"
                    className="button-collapse w-100 text-left"
                  >
                    Lalu Bagaimana Cara Isi Saldo SpeedCash
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="5">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Cara mengisi saldo SpeedCash juga sangat mudah karena terdapat beberapa cara
                        yang bisa kamu pilih seperti mengisi via Transfer bank,Virtual account bank
                        hingga mengisi saldo SpeedCash melalui indomaret.
                      </p>
                      <p className="d-block text-justify mt-1">
                        Untuk mengetahui lebih jelas tentang cara isi saldo SpeedCash kamu bisa
                        mengikuti tutorial yang ada di Video Ini :
                      </p>
                      <Row className="d-flex w-100 justify-content-center mb-3">
                        <iframe
                          width="100%"
                          height="480"
                          src="https://www.youtube.com/embed/sI1fLYglLwk"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                          frameBorder={0}
                          title="Cara Isi Saldo Di SpeedCash"
                        />
                      </Row>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="6"
                    className="button-collapse w-100 text-left"
                  >
                    JIka Ada Kendala Dalam Pengisian / Top Up FF Termurah Di SpeedCash Bagaimana ?
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="6">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Jika mengalami kendala dalam membeli diamond FF termurah ataupun ketika
                        menggunakan layanan lainnya yanga ada di SpeedCash kamu bisa langsung
                        mengubungi kami melalui menu CHAT CS, Cs kami akan dengan senang hati
                        melayani selama 24/7 (24 jam dalam 7 hari)
                      </p>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="7"
                    className="button-collapse w-100 text-left"
                  >
                    Sekilas Tentang Game Free Fire
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="7">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Free Fire adalah permainan battle royale yang dikembangkan oleh 111 Dots
                        Studio, sebuah perusahaan game asal Singapura. Game ini pertama kali
                        diluncurkan pada 30 September 2017 untuk perangkat mobile. Sejak saat itu,
                        Free Fire telah menjadi salah satu permainan mobile paling populer di dunia,
                        terutama di berbagai negara Asia Tenggara, Amerika Latin, dan Timur Tengah.
                      </p>
                      <p className="d-block text-justify mt-1">
                        Free Fire awalnya dikenal dengan nama &ldquo;Free Fire - Battleground&rdquo;
                        Permainan ini dirancang untuk dapat dimainkan oleh pengguna perangkat mobile
                        dengan spesifikasi yang lebih rendah, sehingga dapat diakses oleh lebih
                        banyak pemain. Free Fire menawarkan pengalaman permainan battle royale yang
                        cepat dan intens, di mana 50 pemain terjun dari pesawat ke pulau terpencil
                        dan berusaha menjadi yang terakhir bertahan hidup.
                      </p>
                      <p className="d-block text-justify mt-1">
                        Dalam perjalanan waktu, Free Fire terus mengalami perkembangan dan
                        pembaruan. Pengembang secara rutin memperkenalkan karakter baru, senjata,
                        peta, dan mode permainan untuk menjaga keseruan dan menarik minat pemain.
                        Kehadiran berbagai event dan turnamen esports juga telah membantu
                        meningkatkan popularitas Free Fire di kalangan komunitas gamer.
                      </p>
                      <p className="d-block text-justify mt-1">
                        Free Fire juga dikenal karena mendukung permainan dalam mode solo, duo, dan
                        squad, memungkinkan pemain berkolaborasi dengan teman-teman mereka.
                        Kesuksesan Free Fire tidak hanya tercermin dalam jumlah pemain aktifnya,
                        tetapi juga dalam prestasi di berbagai kompetisi esports, di mana pemain dan
                        tim dari seluruh dunia bersaing untuk hadiah besar.
                      </p>
                      <p className="d-block text-justify mt-1">
                        Dengan grafis yang memukau, kontrol yang intuitif, dan pengalaman bermain
                        yang seru, Free Fire terus menjadi salah satu game mobile paling populer di
                        seluruh dunia.
                      </p>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="8"
                    className="button-collapse w-100 text-left"
                  >
                    Perbedaan Free Fire Biasa Dengan Free Fire Max
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="8">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Free Fire MAX (FF Max) adalah versi enhanced dari game battle royale
                        populer, Free Fire. Dikembangkan oleh Garena International, Free Fire MAX
                        menawarkan pengalaman bermain yang lebih premium dengan peningkatan grafis,
                        fitur tambahan, dan gameplay yang lebih halus.
                      </p>
                      <div className="d-flex flex-column">
                        <p className="d-block text-justify mt-3">
                          Berikut adalah beberapa hal yang membedakan FF Max dari Free Fire Biasa:
                        </p>
                        <Row className="mt-1">
                          <ul className="text-justify list-content">
                            <li>
                              <b>Grafik yang lebih baik</b>: Free Fire MAX menampilkan grafis yang
                              lebih detail dan realistis dibandingkan dengan Free Fire. Ini termasuk
                              peningkatan pada tekstur, pencahayaan, dan efek visual.
                            </li>
                            <li>
                              <b>Fitur tambahan</b>: Free Fire MAX memiliki beberapa fitur eksklusif
                              yang tidak tersedia di Free Fire, seperti mode permainan
                              &ldquo;Craftland&rdquo; dimana pemain dapat membuat map sendiri dan
                              &ldquo;Clash Squad Ranked&rdquo; mode ranked baru.
                            </li>
                            <li>
                              <b>Gameplay yang lebih halus</b>: Free Fire MAX menawarkan gameplay
                              yang lebih halus dan stabil dengan frame rate yang lebih tinggi.
                            </li>
                          </ul>
                        </Row>
                      </div>
                      <p className="d-block text-justify mt-1">
                        Meskipun Free Fire MAX menawarkan peningkatan yang signifikan, perlu dicatat
                        bahwa kedua game tersebut masih memiliki elemen dan gameplay inti yang sama.
                        Pemain dari kedua versi game tersebut dapat bermain bersama melalui
                        teknologi &ldquo;Firelink&rdquo; eksklusif.
                      </p>
                      <p className="d-block text-justify mt-1">
                        Jika Anda mencari pengalaman bermain Free Fire yang lebih premium dengan
                        grafis yang lebih baik dan fitur tambahan, maka Free Fire MAX adalah pilihan
                        yang tepat untuk Anda. Namun, jika Anda memiliki perangkat dengan
                        spesifikasi yang lebih rendah atau lebih suka gameplay yang lebih sederhana,
                        maka Free Fire versi original mungkin masih menjadi pilihan yang lebih baik.
                      </p>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="9"
                    className="button-collapse w-100 text-left"
                  >
                    Lalu Apa Itu Diamond Dalam Free Fire (FF) ?
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="9">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Diamond dalam Free Fire adalah mata uang dalam game yang digunakan untuk
                        melakukan pembelian item-item khusus, seperti kostum, senjata, aksesoris,
                        dan lainnya. Mata uang ini memainkan peran penting dalam memperkaya
                        pengalaman bermain pemain, karena memungkinkan mereka untuk mengakses konten
                        premium dan mendapatkan keuntungan tambahan di dalam permainan.
                      </p>
                      <p className="d-block text-justify mt-1">
                        Pemain bisa mendapatkan diamond di Free Fire dengan beberapa cara. Salah
                        satunya adalah dengan melakukan pembelian menggunakan uang sungguhan melalui
                        toko dalam game atau melalui platform pembayaran resmi. Diamond juga dapat
                        diperoleh melalui hadiah-hadiah dalam permainan, event-event khusus, atau
                        melalui misi-misi tertentu.
                      </p>
                      <p className="d-block text-justify mt-1">
                        Dengan menggunakan diamond, pemain dapat membeli Royale Pass, yang
                        memberikan akses ke berbagai hadiah eksklusif dan menantang pemain untuk
                        menyelesaikan misi-misi dalam permainan. Diamond juga dapat digunakan untuk
                        membeli bundel-bundel kosmetik, senjata, dan item lain yang dapat
                        meningkatkan gaya permainan atau memberikan keunggulan tertentu.
                      </p>
                      <p className="d-block text-justify mt-1">
                        Penting untuk diingat bahwa diamond dalam Free Fire bersifat virtual dan
                        terkait langsung dengan akun pemain di dalam permainan. Penggunaannya
                        dikontrol oleh sistem dalam game dan tidak dapat ditukar dengan mata uang
                        sungguhan atau barang fisik di dunia nyata.
                      </p>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="10"
                    className="button-collapse w-100 text-left"
                  >
                    Apakah Wajib Top Up Diamond FF Termurah Di SpeedCash
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="10">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Tidak, top up diamond di Free Fire tidak wajib dilakukan. Free Fire
                        dirancang untuk dapat dimainkan secara gratis, dan pemain dapat meraih
                        keberhasilan serta mendapatkan sebagian besar item dalam permainan tanpa
                        melakukan pembelian diamond.
                      </p>
                      <p className="d-block text-justify mt-1">
                        Diamond digunakan untuk membeli item-item kosmetik, Royale Pass, dan
                        beberapa item premium lainnya. Namun, sebagian besar item tersebut bersifat
                        opsional dan tidak mempengaruhi kemampuan pemain dalam bertahan hidup atau
                        berkompetisi. Pemain yang tidak melakukan top up diamond masih dapat
                        menikmati permainan, berpartisipasi dalam event-event, dan meraih kemenangan
                        tanpa harus mengeluarkan uang.
                      </p>
                      <p className="d-block text-justify mt-1">
                        <a href="https://play.google.com/store/apps/details?id=com.bm.sc.bebasbayar&listing=topup-ff">
                          Top up FF
                        </a>{' '}
                        diamond hanyalah pilihan bagi pemain yang ingin memperoleh item-item khusus,
                        meningkatkan gaya permainan, atau mendukung pengembang dalam mempertahankan
                        dan mengembangkan permainan. Jadi, sementara top up diamond dapat
                        meningkatkan pengalaman permainan dengan menawarkan akses ke konten premium,
                        hal tersebut bukanlah suatu keharusan untuk menikmati Free Fire.
                      </p>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="11"
                    className="button-collapse w-100 text-left"
                  >
                    Apa Kegunaan Diamond FF
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="11">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Diamond di Free Fire memiliki beberapa kegunaan yang melibatkan berbagai
                        aspek permainan. Berikut adalah beberapa kegunaan utama diamond di Free
                        Fire:
                      </p>
                      <p className="d-block text-justify mt-1">
                        <b>Pembelian Royale Pass:</b> Diamond dapat digunakan untuk membeli Royale
                        Pass, yang memberikan pemain akses ke berbagai hadiah eksklusif, misi-misi
                        tambahan, dan pengalaman permainan yang lebih mendalam.
                      </p>
                      <p className="d-block text-justify mt-1">
                        <b>Pembelian Bundel dan Kostum:</b> Diamond memungkinkan pemain untuk
                        membeli berbagai bundel karakter, kostum, dan aksesoris yang dapat
                        meningkatkan penampilan karakter mereka dalam permainan.
                      </p>
                      <p className="d-block text-justify mt-1">
                        <b>Pembelian Senjata dan Aksesoris Premium:</b> Diamond dapat digunakan
                        untuk memperoleh senjata, aksesoris, dan skin premium yang mungkin
                        memberikan tampilan unik atau efek khusus pada senjata dalam pertandingan.
                      </p>
                      <p className="d-block text-justify mt-1">
                        <b>Top Up dan Pembelian Item di In-Game Shop:</b> Diamond dapat digunakan
                        untuk melakukan top up, yaitu membeli diamond dengan uang sungguhan. Selain
                        itu, pemain dapat menggunakan diamond untuk membeli item-item tertentu di
                        toko dalam game.
                      </p>
                      <p className="d-block text-justify mt-1">
                        <b>Pembelian Item dalam Event Khusus:</b> Diamond seringkali dibutuhkan
                        untuk berpartisipasi dalam event-event khusus atau pembelian item khusus
                        yang terbatas waktu.
                      </p>
                      <p className="d-block text-justify mt-1">
                        Penting untuk diingat bahwa sementara diamond dapat meningkatkan pengalaman
                        permainan dan memberikan keunggulan kosmetik, mereka tidak memengaruhi
                        kemampuan pemain dalam pertandingan. Pemain dapat menikmati Free Fire tanpa
                        harus melakukan pembelian diamond, karena sebagian besar konten dan item
                        dalam game tetap dapat diakses tanpa biaya.
                      </p>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="12"
                    className="button-collapse w-100 text-left"
                  >
                    Bagaimana Cara Install FF di Laptop
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="12">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Untuk menginstal Free Fire di laptop, Anda dapat mengikuti langkah-langkah
                        berikut:
                      </p>
                      <div>
                        <b>Persiapkan Laptop:</b>
                        <p className="d-block text-justify">
                          Pastikan laptop Anda memenuhi persyaratan sistem minimum untuk menjalankan
                          Free Fire. Biasanya, perangkat dengan spesifikasi menengah ke atas akan
                          dapat menjalankan game ini dengan lancar.
                        </p>
                      </div>
                      <div className="mt-3">
                        <b>Download dan Instal Emulator:</b>

                        <p className="d-block text-justify">
                          Free Fire secara resmi tidak mendukung instalasi langsung pada laptop.
                          Namun, Anda dapat menggunakan emulator Android seperti BlueStacks,
                          LDPlayer, atau NoxPlayer untuk menjalankan game tersebut. Unduh emulator
                          Android pilihan Anda dari situs web resminya dan ikuti petunjuk instalasi.
                        </p>
                      </div>
                      <div className="mt-3">
                        <b>Buka Emulator dan Login ke Akun Google:</b>

                        <p className="d-block text-justify">
                          Setelah emulator terinstal, buka aplikasinya. Login atau buat akun Google,
                          karena Anda akan menggunakan akun Google untuk mengakses Google Play Store
                          dan mengunduh Free Fire.
                        </p>
                      </div>
                      <div className="mt-3">
                        <b>Pencarian dan Unduh Free Fire:</b>

                        <p className="d-block text-justify">
                          Buka Google Play Store di dalam emulator. Gunakan fitur pencarian dan cari
                          &ldquo;Free Fire.&rdquo; Temukan aplikasi Free Fire dan klik tombol
                          &ldquo;Install&rdquo; untuk mengunduhnya.
                        </p>
                      </div>
                      <div className="mt-3">
                        <b>Buka Free Fire:</b>

                        <p className="d-block text-justify">
                          Setelah proses unduhan selesai, buka aplikasi Free Fire dari layar utama
                          emulator.
                        </p>
                      </div>
                      <div className="mt-3">
                        <b>Login ke Akun Free Fire:</b>

                        <p className="d-block text-justify">
                          Jika Anda sudah memiliki akun Free Fire, login menggunakan akun tersebut.
                          Jika belum, buat akun baru.
                        </p>
                      </div>
                      <div className="mt-3">
                        <b>Mainkan Free Fire di Laptop:</b>

                        <p className="d-block text-justify">
                          Setelah login berhasil, Anda dapat mulai bermain Free Fire di laptop
                          menggunakan keyboard dan mouse atau mengonfigurasi kontrol sesuai
                          preferensi Anda.
                        </p>
                      </div>
                      <p className="d-block text-justify mt-1">
                        Pastikan untuk selalu mengikuti panduan dan persyaratan yang diberikan oleh
                        emulator yang Anda pilih. Perhatikan bahwa penggunaan emulator untuk bermain
                        game mungkin melanggar aturan tertentu yang diberlakukan oleh pengembang
                        game atau platform distribusinya. Pastikan untuk membaca syarat dan
                        ketentuan yang berlaku sebelum menggunakan emulator.
                      </p>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="13"
                    className="button-collapse w-100 text-left"
                  >
                    Bagaimana Cara Mengetahui ID pengguna free Fire ?
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="13">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Untuk mengetahui ID Free Fire Anda, Anda dapat mengikuti langkah-langkah
                        berikut:
                      </p>
                      <div>
                        <b>Buka Aplikasi Free Fire:</b>

                        <p className="d-block text-justify">
                          Buka aplikasi Free Fire di perangkat Anda.
                        </p>
                      </div>
                      <div className="mt-3">
                        <b>Login ke Akun Anda:</b>

                        <p className="d-block text-justify">
                          Login ke akun Free Fire Anda menggunakan akun yang biasa Anda gunakan
                          untuk bermain.
                        </p>
                      </div>
                      <div className="mt-3">
                        <b>Menu Profil:</b>

                        <p className="d-block text-justify">
                          Setelah masuk ke dalam permainan, cari dan ketuk ikon profil Anda. Ikon
                          profil biasanya terletak di bagian pojok kiri atas layar.
                        </p>
                      </div>
                      <div className="mt-3">
                        <b>Temukan ID Free Fire:</b>

                        <p className="d-block text-justify">
                          Di menu profil, Anda akan melihat beberapa informasi, termasuk gambar
                          profil, nama akun, dan ID Free Fire Anda. ID Free Fire terlihat di bagian
                          bawah layar. Nomor ID ini terdiri dari sejumlah angka unik.
                        </p>
                      </div>
                      <div className="mt-3">
                        <b>Catat atau Screenshot ID:</b>

                        <p className="d-block text-justify">
                          Catat nomor ID Free Fire Anda atau ambil screenshot sebagai referensi
                          untuk penggunaan di kemudian hari.
                        </p>
                      </div>

                      <p className="d-block text-justify mt-1">
                        Jika Anda menghadapi kesulitan menemukan ID Free Fire Anda di dalam
                        permainan, Anda juga dapat mencarinya di bagian pengaturan atau menu profil,
                        tergantung pada pembaruan terbaru dari aplikasi Free Fire. Ingatlah bahwa ID
                        Free Fire merupakan informasi penting yang diperlukan saat menghubungi
                        dukungan pelanggan atau untuk berbagai keperluan dalam permainan.
                      </p>
                      <p className="d-block text-justify mt-1">
                        Itulah tadi informasi mengenai tempat top up ff termurah di SpeedCash, tidak
                        hanya top up diamond FF saja yang bisa kamu lakukan di SpeedCash namun kamu
                        juga bisa pakai SpeedCash untuk :{' '}
                        <a href="https://member.speedcash.co.id/pln">cek tagihan listrik PLN,</a>{' '}
                        <a href="https://member.speedcash.co.id/bpjs">cek tagihan BPJS,</a>
                        beli pulsa all provider dengan harga termurah hingga bayar pajak kendaraan
                        online, transfer antar bank gratis, dan masih banyak layanan SpeedCash
                        lainnya yang bisa kamu manfaatkan.
                      </p>
                      <div className="mt-1 w-100 d-flex justify-content-center">
                        <h5 className="bold">Download Aplikasi Top Up FF Termurah Disini : </h5>
                      </div>
                      <Row className="d-flex w-100 justify-content-center mb-3 gap-3">
                        <a
                          aria-hidden
                          href="https://play.google.com/store/apps/details?id=com.bm.sc.bebasbayar&listing=topup-ff"
                          className="mx-1"
                        >
                          <Image
                            src="/img/playstore-speedcash.png"
                            data-target="playstore-speedcash"
                            width="220px"
                            height="60px"
                          />
                        </a>
                        <a
                          aria-hidden
                          href="https://apps.apple.com/id/app/speedcash-transfer-ewallet/id6459304122"
                          className="mx-1"
                        >
                          <Image
                            src="/img/appstore-speedcash.png"
                            data-target="appstore-speedcash"
                            width="220px"
                            height="60px"
                          />
                        </a>
                      </Row>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default AccordionGameFf
