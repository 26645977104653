import authConstant from '../constant/auth'

const initialState = {
  [authConstant.USER_ID]: '',
  [authConstant.USER_NAME]: '',
  [authConstant.USER_PHONE]: '',
  [authConstant.USER_PIN]: '',
  [authConstant.USER_TOKEN]: '',
  [authConstant.USER_IN_ACCESS_TOKEN]: '',
  [authConstant.USER_ACCESS_TOKEN]: '',
  [authConstant.USER_BALANCE]: 0,
  [authConstant.USER_CAMPAIGN]: {
    utm_term: '',
    utm_user_id: '',
    utm_campaign: '',
    utm_name: '',
    utm_medium: '',
    utm_content: '',
    utm_source: '',
  },
  [authConstant.USER_IS_EMBED]: false,
  [authConstant.USER_APPID]: '',
  [authConstant.USER_IS_MARKETER]: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case authConstant.USER_ID:
    case authConstant.USER_NAME:
    case authConstant.USER_PHONE:
    case authConstant.USER_PIN:
    case authConstant.USER_TOKEN:
    case authConstant.USER_IN_ACCESS_TOKEN:
    case authConstant.USER_ACCESS_TOKEN:
    case authConstant.USER_BALANCE:
    case authConstant.USER_CAMPAIGN:
    case authConstant.USER_IS_EMBED:
    case authConstant.USER_IS_MARKETER:
    case authConstant.USER_APPID:
      return {
        ...state,
        [action.type]: action.payload,
      }
    case authConstant.USER:
      return {
        ...state,
        ...action.payload,
      }
    case authConstant.CLEAR:
      return initialState
    default:
      return state
  }
}
