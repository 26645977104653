import React from 'react'
import { Button, Image, Row, Col, Accordion, Card } from 'react-bootstrap'
import './accordionBpjs.scss'

const AccordionBpjs = () => {
  return (
    <>
      <Col className="d-flex justify-content-center mt-4 mb-3 p-3">
        <h3 className="text-dark text-justify">
          Keuntungan Cek BPJS Kesehatan Dan Bayar Tagihan Di SpeedCash
        </h3>
      </Col>

      <div style={{ padding: '0px 20px 30px 20px' }}>
        <Row className="justify-content-md-center">
          <Col lg={8} xs={12} md={8} className="content-bpjs">
            <div>
              <Row className="d-flex justify-content-center">
                <Image
                  src="/img/cek-tagihan-bpjs.png"
                  data-target="cek-tagihan-bpjs"
                  alt="Cek Tagihan BPJS"
                  width="100%"
                />
              </Row>
              <Row className="d-flex justify-content-center">
                <p className="d-block text-justify mt-3">
                  Cek tagihan BPJS kesehatan sudah menjadi aktivitas rutin yang dilakukan banyak
                  masyarakat yang memiliki nomor BPJS hal ini dilakukan agar menghindari denda
                  keterlambatan akibat melewati batas waktu pembayaran tagihan BPJS.Dengan
                  menggunakan SpeedCash kamu bisa langsung untuk{' '}
                  <a href="https://member.speedcash.co.id/cek-bpjs-kesehatan">cek bpjs kesehatan</a>{' '}
                  dan bayar via online di website SpeedCash.
                </p>
              </Row>
              <Row className="d-flex justify-content-center">
                <p className="d-block text-justify">
                  Kemudahan menggunakan SpeedCash untuk cek BPJS kesehatan dan bayar tagihan BPJS
                  online langsung via website menjadi alasan orang yang ingin membayar suatu tagihan
                  tanpa harus banyak menggunakan aplikasi pembayaran online yang di install di HP
                  mereka.Berikut mengapa harus cek BPJS kesehatan dan bayar langsung di SpeedCash.
                </p>
              </Row>
              <Row>
                <ul className="text-justify list-content">
                  <li>
                    <strong>Praktis</strong>: kamu tidak perlu menginstall aplikasi jika mau
                    membayar tagihan BPJS kesehatan via online di website resmi SpeedCash
                  </li>
                  <li>
                    <strong>Cepat </strong>: tidak perlu harus keluar rumah dan meninggalkan
                    aktivitas penting lainnya untuk harus mengecek tagihan dan bayar tagihan
                    BPJS,kamu cukup akses halaman member area SpeedCash maka kamu sudah bisa cek
                    BPJS kesehatan dan bayar langsung disini.
                  </li>
                  <li>
                    <strong>Aman</strong> : Ketika kamu ingin mengecek tagihan BPJS maka kamu harus
                    mengisi data / nomor pelanggan BPJS mu dan jika ingin membayar langsung maka
                    kamu menggunakan saldo SpeedCash yang sudah kamu isi sebelumnya.Untuk kemanan
                    data pelanggan kamu tidak perlu ragu lagi karena SpeedCash merupakan dompet
                    digital yang dimiliki 100% oleh orang indonesia dan sudah terdaftar,terlisensi
                    resmi di bank indonesia.
                  </li>
                  <li>
                    <strong>Diskon</strong> : untuk setiap transaksi yang dilakukan melalui
                    SpeedCash baik untuk bayar tagihan bulanan ataupun membeli kebutuhan seperti
                    pulsa maka kamu akan mendapatkan diskon setiap pembayaran/pembelian disini.
                  </li>
                </ul>
              </Row>
              <Row className="d-flex justify-content-center">
                <p className="d-block text-justify">
                  SpeedCash juga mendukung program pemerintah untuk memudahkan akses layanan
                  kesehatan salah satunya melalui akses untuk cek BPJS kesehatan dan bayar tagihan
                  langsung disini pasti diskon.
                </p>
              </Row>
              <Row className="d-flex justify-content-center">
                <p className="d-block text-justify">
                  Menggunakan SpeedCash tidak hanya untuk cek BPJS kesehatan saja tetapi juga bisa
                  untuk{' '}
                  <a href="https://member.speedcash.co.id/pln/cek-tagihan-listrik">
                    cek tagihan listrik
                  </a>
                  ,{' '}
                  <a href="https://member.speedcash.co.id/pln/token-listrik">beli token listrik</a>,{' '}
                  transfer bank,
                  <a href="https://member.speedcash.co.id/game/top-up-ff">Top up ff</a>, tiket
                  pesawat dan masih banyak lainnya.Hal ini tentu menjadi alternatif pilihan buat
                  kamu jika kamu ingin mendapatkan layanan finansial services yang lengkap,cepat dan
                  mempunya layanan CS yang selalui standby 24 jam selama 7 hari.
                </p>
              </Row>
              <Row className="d-flex my-2 justify-content-center text-dark">
                <h4>Cara Cek BPJS Kesehatan Di HP Via SpeedCash</h4>
              </Row>
              <Row>
                <p className="d-block text-justify">
                  Untuk mengecek BPJS kesehatan melalui halaman SpeedCash cukup mudah, hanya 4
                  langkah saja, berikut caranya :
                </p>
                <ul className="text-justify list-content">
                  <li>Pertama kamu isikan nomor polis/ID pelanggan BPJS</li>
                  <li>Lalu isikan nomor handphone</li>
                  <li>Jika sudah lalu pilih bulan berapa yang ingin kamu lihat tagihannya</li>
                  <li>Dan langkah terakhir kamu klik “cek tagihan sekarang”</li>
                </ul>
                <p className="d-block text-justify">
                  Apakah cek tagihan BPJS kesehatan hanya bisa dilakukan via website SpeedCash saja
                  ? tentu tidak, kamu bisa lakukan cek BPJS kesehatan dan membayar langsung via
                  aplikasi juga.Berikut cara cek BPJS kesehatan di HP menggunakan aplikasi resmi
                  SpeedCash.
                </p>
              </Row>
            </div>
          </Col>

          <Col lg={8} xs={12} md={8} className="accordion-bpjs">
            <Accordion className="m-0 p-0">
              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="1"
                    className="button-collapse w-100 text-left"
                  >
                    Cek BPJS Kesehatan di Aplikasi
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="1">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Melihat tagihan BPJS kesehatanmelalui aplikasi SpeedCash juga sangat
                        mudah,hampir sama jika mengecek tagihan BPJS ksehetan melalui website
                        SpeedCash, berikut untuk langkahnya :
                      </p>
                      <Row className="mt-3">
                        <ul className="text-justify list-content">
                          <li>
                            Buka aplikasi SpeedCash (jika belum punya kamu bisa download DISINI){' '}
                            <a href="https://www.speedcash.co.id/download-aplikasi-speedcash">
                              DISINI
                            </a>
                            )
                          </li>
                          <li>
                            Jika sudah lalu silahkan login dulu menggunakan nomor HP yang aktif
                          </li>
                          <li>
                            <p>
                              Lalu masuk ke halaman beranda aplikasi dan pilih menu{' '}
                              <strong>BPJS</strong>
                            </p>
                            <Row className="d-flex justify-content-center">
                              <Image
                                src="/img/home-sc.png"
                                data-target="home-sc"
                                width="220px"
                                height="400px"
                              />
                            </Row>
                          </li>
                          <li>Isikan nomor polis/ID pelanggan BPJS mu</li>
                          <li>
                            <p>Masukkan nomor HP</p>
                            <Row className="d-flex justify-content-center">
                              <Image
                                src="/img/bayar-tagihan-bpjs.png"
                                data-target="bayar-tagihan-bpjs"
                                width="220px"
                                height="400px"
                              />
                            </Row>
                          </li>
                          <li>Pilih bulan berapa yang ingin kamu lihat total tagihannya.</li>
                          <li>
                            (optional dan disarankan) kamu bisa pilih aktifkan fitur auto payment
                            untuk secara otomatis kamu bayar melalui aplikasi SpeedCash
                          </li>
                          <li>
                            Jika sudah kamu bisa klik <b>Cek BPJS Kesehatan Sekarang</b> dan ikuti
                            proses selanjutnya.
                          </li>
                        </ul>
                      </Row>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="2"
                    className="button-collapse w-100 text-left"
                  >
                    Atau Bisa Juga Kamu Simak Video Berikut Untuk Melihat Tagihan BPJS Kesehatan
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="2">
                  <Card.Body className="card-content">
                    <Row className="d-flex justify-content-center my-3">
                      <iframe
                        width="100%"
                        height="480"
                        src="https://www.youtube.com/embed/QAoFhstUt-o"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        frameBorder={0}
                        title="Cara cek tagihan listrik Via SpeedCash"
                      />
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="3"
                    className="button-collapse w-100 text-left"
                  >
                    Kendala Pembayaran BPJS Kesehatan Di SpeedCash
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="3">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        JIka kamu mengalami kendala pembayaran BPJS maupun saat{' '}
                        <a href="https://www.speedcash.co.id/">cek tagihan bulanan</a> lainnya
                        ketika menggunakan SpeedCash baik itu melalui website maupun di aplikasi,
                        kamu tidak perlu khawatir dengan saldo/danamu karena saldo tetap menjadi
                        milikmu dan jika terjadi kendala ini kamu bisa menghubungi CS SpeedCash /
                        pusat bantuan SpeedCash melalui live chat tau bisa juga langsung menghubungi
                        admin SpeedCash di sosial media.
                      </p>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="4"
                    className="button-collapse w-100 text-left"
                  >
                    Sejarah BPJS Di Indonesia
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="4">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        BPJS (Badan Penyelenggara Jaminan Sosial) di Indonesia memiliki sejarah yang
                        cukup panjang. Program Jaminan Sosial di Indonesia sudah ada sejak era
                        kolonial Belanda, tetapi baru pada tahun 1968, pemerintah Indonesia mulai
                        menggulirkan program Jaminan Kesehatan Masyarakat (Jamkesmas) sebagai
                        langkah awal.
                      </p>
                      <p className="d-block text-justify mt-1">
                        Pada tahun 2004, Pemerintah Indonesia mulai menggulirkan program Jaminan
                        Kesehatan Nasional (JKN) yang kemudian menjadi cikal bakal BPJS. Pada tahun
                        2011, Undang-Undang Nomor 24 Tahun 2011 tentang Badan Penyelenggara Jaminan
                        Sosial (BPJS) disahkan oleh pemerintah untuk memberikan dasar hukum bagi
                        pembentukan BPJS.
                      </p>
                      <p className="d-block text-justify mt-1">
                        BPJS secara resmi beroperasi pada 1 Januari 2014, menggantikan peran
                        beberapa badan jaminan sosial yang sebelumnya ada. BPJS Kesehatan dan BPJS
                        Ketenagakerjaan adalah dua badan yang terpisah yang bertanggung jawab
                        masing-masing dalam bidang kesehatan dan ketenagakerjaan.
                      </p>
                      <p className="d-block text-justify mt-1">
                        BPJS Kesehatan bertujuan memberikan perlindungan kesehatan bagi seluruh
                        rakyat Indonesia. Masyarakat yang terdaftar sebagai peserta BPJS Kesehatan
                        membayar iuran setiap bulan dan mendapatkan akses layanan kesehatan yang
                        mencakup pemeriksaan, pengobatan, serta fasilitas kesehatan lainnya.
                      </p>
                      <p className="d-block text-justify mt-1">
                        Meskipun BPJS membawa manfaat dalam memberikan akses pelayanan kesehatan
                        kepada masyarakat yang lebih luas, program ini juga menghadapi berbagai
                        tantangan, termasuk keberlanjutan keuangan dan peningkatan kualitas
                        pelayanan. Pemerintah terus berupaya untuk memperbaiki dan mengoptimalkan
                        sistem BPJS agar dapat memberikan manfaat yang lebih baik bagi seluruh
                        rakyat Indonesia.
                      </p>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="5"
                    className="button-collapse w-100 text-left"
                  >
                    Perbedaan BPJS Kesehatan Dan Ketenagakerjaan
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="5">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        BPJS adalah singkatan dari Badan Penyelenggara Jaminan Sosial. Ini merupakan
                        lembaga yang bertanggung jawab dalam menyelenggarakan program jaminan sosial
                        di Indonesia. BPJS memiliki dua cabang utama, yaitu BPJS Kesehatan dan BPJS
                        Ketenagakerjaan.
                      </p>
                      <p className="d-block text-justify mt-1">
                        <strong>BPJS Kesehatan:</strong> Merupakan program jaminan kesehatan yang
                        bertujuan untuk memberikan perlindungan finansial kepada peserta dalam
                        memenuhi kebutuhan kesehatan. Peserta BPJS Kesehatan membayar iuran setiap
                        bulan dan berhak mendapatkan pelayanan kesehatan yang mencakup pemeriksaan,
                        pengobatan, serta fasilitas kesehatan lainnya.
                      </p>
                      <p className="d-block text-justify mt-1">
                        <strong>BPJS Ketenagakerjaan:</strong> Fokus pada perlindungan bagi pekerja
                        atau buruh, melibatkan program jaminan ketenagakerjaan seperti jaminan
                        kecelakaan kerja, jaminan kematian, jaminan hari tua, dan jaminan pensiun.
                        Pekerja formal di Indonesia secara otomatis menjadi peserta BPJS
                        Ketenagakerjaan melalui pemotongan iuran dari gaji mereka.
                      </p>
                      <p className="d-block text-justify mt-1">
                        Kedua program BPJS ini diimplementasikan untuk memberikan akses pelayanan
                        kesehatan dan perlindungan sosial kepada masyarakat Indonesia secara luas.
                        Pemerintah mengatur dan mengawasi kebijakan serta operasional BPJS guna
                        memastikan ketersediaan jaminan sosial bagi seluruh rakyat Indonesia.
                      </p>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="6"
                    className="button-collapse w-100 text-left"
                  >
                    Manfaat Mempunyai BPJS Kesehatan
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="6">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        BPJS Kesehatan memberikan sejumlah manfaat kepada pesertanya, di antaranya:
                      </p>
                      <p className="d-block text-justify mt-1">
                        <strong>Perlindungan Finansial:</strong> BPJS Kesehatan memberikan
                        perlindungan finansial melalui mekanisme pembayaran iuran bulanan. Peserta
                        tidak perlu membayar biaya penuh saat menerima layanan kesehatan, karena
                        sebagian besar biaya ditanggung oleh BPJS.{' '}
                      </p>
                      <p className="d-block text-justify mt-1">
                        <strong>Akses Pelayanan Kesehatan:</strong> Peserta BPJS Kesehatan memiliki
                        akses ke berbagai layanan kesehatan, termasuk pemeriksaan kesehatan, rawat
                        inap, operasi, obat-obatan, dan tindakan medis lainnya. Ini membantu
                        memastikan bahwa masyarakat dapat menerima perawatan yang diperlukan tanpa
                        terkendala oleh faktor keuangan.
                      </p>
                      <p className="d-block text-justify mt-1">
                        <strong>Pemeliharaan Kesehatan:</strong> BPJS Kesehatan juga
                        menyelenggarakan program kesehatan preventif, seperti imunisasi, pemeriksaan
                        kesehatan berkala, dan promosi kesehatan. Hal ini bertujuan untuk mencegah
                        penyakit dan mempromosikan gaya hidup sehat.{' '}
                      </p>
                      <p className="d-block text-justify mt-1">
                        <strong>Pengembalian Biaya (Reimbursement):</strong> Peserta yang sudah
                        membayar biaya kesehatan secara pribadi dapat mengajukan klaim untuk
                        mendapatkan pengembalian biaya dari BPJS Kesehatan sesuai dengan ketentuan
                        yang berlaku.
                      </p>
                      <p className="d-block text-justify mt-1">
                        <strong>Jaminan Kesehatan Keluarga:</strong> BPJS Kesehatan tidak hanya
                        mencakup peserta, tetapi juga memberikan jaminan kesehatan kepada keluarga
                        peserta, seperti suami/istri dan anak-anak, tergantung pada ketentuan yang
                        berlaku.{' '}
                      </p>
                      <p className="d-block text-justify mt-1">
                        <strong>Pelayanan Darurat:</strong> BPJS Kesehatan memberikan dukungan dalam
                        situasi darurat, termasuk kecelakaan dan kondisi medis mendesak, sehingga
                        peserta dapat segera mendapatkan perawatan yang dibutuhkan.{' '}
                      </p>
                      <p className="d-block text-justify mt-1">
                        <strong>Kemudahan Administrasi:</strong> BPJS Kesehatan menyediakan sistem
                        administrasi yang terorganisir untuk proses klaim dan pemantauan
                        kepesertaan, memudahkan peserta dalam mengakses informasi terkait jaminan
                        kesehatan mereka.
                      </p>
                      <p className="d-block text-justify mt-1">
                        Melalui manfaat-manfaat ini, BPJS Kesehatan bertujuan untuk meningkatkan
                        akses pelayanan kesehatan bagi masyarakat Indonesia dan memberikan
                        perlindungan finansial yang lebih baik dalam menghadapi risiko kesehatan.
                      </p>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="7"
                    className="button-collapse w-100 text-left"
                  >
                    Penyakit Yang Dapat Dicover BPJS Kesehatan
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="7">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        BPJS Kesehatan menyediakan jaminan kesehatan untuk berbagai penyakit dan
                        kondisi medis. Berikut adalah beberapa jenis penyakit dan layanan kesehatan
                        yang umumnya ditanggung oleh BPJS Kesehatan:
                      </p>
                      <p className="d-block text-justify mt-1">
                        <strong>Pemeriksaan Kesehatan Umum:</strong> Termasuk pemeriksaan rutin,
                        konsultasi dokter umum, dan pelayanan medis dasar.
                      </p>
                      <p className="d-block text-justify mt-1">
                        <strong>Rawat Inap:</strong> BPJS Kesehatan menyediakan jaminan untuk
                        perawatan di rumah sakit, termasuk biaya perawatan, obat-obatan, dan
                        pelayanan medis lainnya selama masa rawat inap.
                      </p>
                      <p className="d-block text-justify mt-1">
                        <strong>Operasi dan Tindakan Medis:</strong> Jaminan mencakup biaya operasi
                        dan tindakan medis yang diperlukan untuk penyembuhan penyakit atau kondisi
                        tertentu.
                      </p>
                      <p className="d-block text-justify mt-1">
                        <strong>Persalinan dan Perawatan Maternal:</strong> BPJS Kesehatan
                        menyediakan jaminan untuk perawatan selama kehamilan, persalinan, dan
                        perawatan pascamelahirkan.
                      </p>
                      <p className="d-block text-justify mt-1">
                        <strong>Pelayanan Kesehatan Gigi:</strong> BPJS Kesehatan juga mencakup
                        pelayanan kesehatan gigi, termasuk pemeriksaan gigi, pencabutan gigi, dan
                        perawatan gigi lainnya.
                      </p>
                      <p className="d-block text-justify mt-1">
                        <strong>Pelayanan Penyakit Menular:</strong> BPJS Kesehatan menyediakan
                        jaminan untuk pengobatan penyakit menular seperti infeksi saluran
                        pernapasan, tuberkulosis, dan penyakit menular lainnya.
                      </p>
                      <p className="d-block text-justify mt-1">
                        <strong>Pelayanan Penyakit Kronis:</strong> Termasuk pengobatan dan
                        perawatan untuk penyakit kronis seperti diabetes, hipertensi, dan penyakit
                        jantung.
                      </p>
                      <p className="d-block text-justify mt-1">
                        <strong>Pelayanan Kesehatan Anak dan Bayi:</strong> BPJS Kesehatan mencakup
                        pelayanan kesehatan khusus untuk anak-anak, termasuk imunisasi, pemeriksaan
                        perkembangan, dan perawatan kesehatan anak.
                      </p>
                      <p className="d-block text-justify mt-1">
                        <strong>Pelayanan Kesehatan Lansia:</strong> Jaminan kesehatan untuk lansia
                        mencakup pemeriksaan kesehatan rutin, pengobatan, dan perawatan kesehatan
                        lainnya.
                      </p>
                      <p className="d-block text-justify mt-1">
                        Perlu diingat bahwa ketentuan dan cakupan pelayanan kesehatan dapat
                        mengalami perubahan dari waktu ke waktu, dan peserta BPJS Kesehatan
                        sebaiknya selalu memeriksa informasi terbaru melalui saluran resmi BPJS atau
                        pusat layanan informasi untuk mendapatkan informasi yang akurat dan terkini.
                      </p>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="8"
                    className="button-collapse w-100 text-left"
                  >
                    Cek Iuran Dan Kelas BPJS Kesehatan
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="8">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        BPJS adalah singkatan dari Penyelenggaraan Jaminan Sosial Kesehatan. BPJS
                        Kesehatan merupakan lembaga penyelenggara program Jaminan Kesehatan Nasional
                        (JKN) di Indonesia. JKN merupakan program jaminan kesehatan yang
                        diselenggarakan pemerintah untuk melindungi kesehatan seluruh masyarakat
                        Indonesia.
                      </p>
                      <p className="d-block text-justify mt-1">
                        BPJS Kesehatan memiliki tiga tingkatan/kelas, yaitu tingkat 1, tingkat 2,
                        dan tingkat 3. Besaran iuran tiap kelas berbeda-beda. Berikut besaran iuran
                        BPJS Kesehatan per bulan dan per orang:
                      </p>
                      <div className="d-flex flex-column mt-1">
                        <span>
                          <strong>Kelas 1 :</strong> Rp 150.000
                        </span>
                        <span>
                          <strong>Kelas 2 :</strong> Rp 100.000
                        </span>
                        <span>
                          <strong>Kelas 3 :</strong> Rp 35.000
                        </span>
                      </div>
                      <p className="d-block text-justify mt-2">
                        Bagi peserta Peserta BPJS Kesehatan Pegawai Tidak Dibayar (PBPU) dan Bukan
                        Pekerja (BP), dapat memilih kelas sesuai keinginan dan kemampuannya.
                        Sedangkan bagi peserta BPJS Kesehatan Pekerja Penerima Upah (PPU), besaran
                        iuran akan dibayarkan oleh pemberi kerja dan peserta berdasarkan persentase
                        gaji.
                      </p>
                      <p className="d-block text-justify mt-1">
                        Perbedaan Fasilitas BPJS Kesehatan kategori 1, 2, dan 3 terletak pada jenis
                        ruangan dan jumlah pasien dalam suatu ruangan. Berikut penjelasannya:
                      </p>
                      <div className="d-flex flex-column mt-1">
                        <span>
                          <strong>Kelas 1 :</strong> peserta kelas 1 mendapat manfaat kamar rumah
                          sakit dengan kapasitas 1 hingga 2 tempat tidur.
                        </span>
                        <span>
                          <strong>Kelas 2 :</strong> peserta kelas 2 mendapatkan manfaat kamar Rumah
                          Sakit dengan kapasitas 3 hingga 4 tempat tidur.
                        </span>
                        <span>
                          <strong>Kelas 3 :</strong> Peserta tingkat 3 mendapatkan manfaat dari
                          kamar kos dengan 6 hingga 8 tempat tidur.
                        </span>
                      </div>
                      <p className="d-block text-justify mt-2">
                        Selain itu, peserta tingkat 1 mendapatkan manfaat dari fasilitas tambahan,
                        seperti: Ruang tunggu khusus Keperawatan khusus layanan,Makanan spesial.
                      </p>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="9"
                    className="button-collapse w-100 text-left"
                  >
                    Fasilitas Kesehatan Di BPJS
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="9">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Fasilitas kesehatan yang menerima BPJS adalah fasilitas kesehatan yang telah
                        bekerja sama dengan BPJS Kesehatan untuk memberikan pelayanan kesehatan
                        kepada peserta BPJS Kesehatan. Fasilitas kesehatan ini bisa berupa rumah
                        sakit, klinik, puskesmas, atau optik.
                      </p>
                      <div className="w-100 mt-3">
                        <p className="text-center">
                          <strong>Jenis Fasilitas Kesehatan yang Menerima BPJS</strong>
                        </p>
                        <div className="d-flex flex-column mt-2">
                          <span className="mb-2">
                            Terdapat dua jenis fasilitas kesehatan yang menerima BPJS, yaitu:
                          </span>
                          <span className="my-1">
                            <strong>Fasilitas kesehatan tingkat pertama (FKTP):</strong> Puskesmas,
                            klinik, dan dokter praktek perorangan.
                          </span>
                          <span className="my-1">
                            <strong>Fasilitas kesehatan tingkat lanjut (FKL):</strong> Rumah sakit.
                          </span>
                        </div>
                      </div>
                      <div className="w-100 mt-3">
                        <p className="text-center">
                          <strong>Cara Mencari Fasilitas Kesehatan BPJS</strong>
                        </p>
                        <div className="d-flex flex-column mt-2">
                          <span className="mb-2">
                            Kamu bisa mencari fasilitas kesehatan yang menerima BPJS melalui
                            berbagai cara, yaitu:
                          </span>
                          <span className="my-1">
                            <strong>Website BPJS Kesehatan:</strong> Kamu bisa mengunjungi website
                            BPJS Kesehatan (bpjs-kesehatan.go.id) dan klik menu &ldquo;Fasilitas
                            Kesehatan&rdquo;.
                          </span>
                          <span className="my-1">
                            <strong>Mobile app BPJS Kesehatan (JKN Mobile):</strong> Kamu bisa
                            mendownload dan menginstall aplikasi JKN Mobile di smartphone kamu.
                            Setelah itu, buka aplikasi dan klik menu &ldquo;Fasilitas
                            Kesehatan&rdquo;.
                          </span>
                          <span className="my-1">
                            <strong>Petugas BPJS:</strong> Kamu bisa bertanya kepada petugas BPJS
                            Kesehatan di kantor BPJS Kesehatan terdekat.
                          </span>
                        </div>
                      </div>
                      <div className="w-100 mt-3">
                        <p className="text-center">
                          <strong>
                            Manfaat Menggunakan Fasilitas Kesehatan yang Menerima BPJS
                          </strong>
                        </p>
                        <div className="d-flex flex-column mt-2">
                          <span className="mb-2">
                            Ada banyak manfaat menggunakan fasilitas kesehatan yang menerima BPJS,
                            yaitu:
                          </span>
                          <span className="my-1">
                            <strong>Gratis:</strong> Kamu bisa berobat gratis di fasilitas kesehatan
                            yang menerima BPJS.
                          </span>
                          <span className="my-1">
                            <strong>Mudah:</strong> Kamu bisa berobat di fasilitas kesehatan yang
                            menerima BPJS tanpa harus repot mengurus administrasi.
                          </span>
                          <span className="my-1">
                            <strong>Lengkap:</strong> Fasilitas kesehatan yang menerima BPJS
                            menyediakan berbagai macam layanan kesehatan, mulai dari pemeriksaan
                            kesehatan hingga perawatan penyakit.
                          </span>
                        </div>
                      </div>
                      <div className="w-100 mt-3 mb-2">
                        <p className="text-center">
                          <strong>Contoh Fasilitas Kesehatan yang Menerima BPJS</strong>
                        </p>
                        <div className="d-flex flex-column mt-2">
                          <span className="mb-2">
                            Berikut adalah beberapa contoh fasilitas kesehatan yang menerima BPJS:
                          </span>
                          <span className="my-1">
                            <strong>Rumah sakit pemerintah:</strong> Rumah Sakit Umum Pusat (RSUP),
                            Rumah Sakit Umum Daerah (RSUD), dan Rumah Sakit Khusus Daerah (RSKD).
                          </span>
                          <span className="my-1">
                            <strong>Rumah sakit swasta:</strong> Rumah Sakit Umum Pusat (RSUP)
                            swasta, Rumah Sakit Umum Daerah (RSUD) swasta, dan Rumah Sakit Khusus
                            Daerah (RSKD) swasta.
                          </span>
                          <span className="my-1">
                            <strong>Klinik:</strong> Klinik pratama, klinik spesialis, dan klinik
                            dokter gigi.
                          </span>
                          <span className="my-1">
                            <strong>Puskesmas:</strong> Puskesmas pembantu, puskesmas kelurahan, dan
                            puskesmas kecamatan.
                          </span>
                        </div>
                      </div>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="10"
                    className="button-collapse w-100 text-left"
                  >
                    Cara Daftar BPJS Kesehatan Online
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="10">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Mendaftar BPJS kesehatan secara online mandiri juga sangat mudah, tanpa
                        harus keluar rumah atau meninggalkan aktivitas, berikut langkah mudah
                        mendaftar BPJS kesehatan secara online mandiri :
                      </p>
                      <div className="d-block text-justify mt-1">
                        <b>Kunjungi Situs Resmi BPJS Kesehatan:</b>
                        <p className="d-block text-justify">
                          Buka browser web dan kunjungi situs resmi BPJS Kesehatan
                          bpjs-kesehatan.go.id
                        </p>
                      </div>
                      <div className="d-block text-justify mt-1">
                        <b>Pilih Menu Pendaftaran Online:</b>
                        <p className="d-block text-justify">
                          Cari menu pendaftaran online atau e-Registration yang biasanya terdapat di
                          halaman utama situs.
                        </p>
                      </div>
                      <div className="d-block text-justify mt-1">
                        <b>Isi Formulir Pendaftaran:</b>
                        <p className="d-block text-justify">
                          Isi formulir pendaftaran online dengan data yang benar dan lengkap.
                          Pastikan untuk memasukkan informasi yang sesuai dengan dokumen identitas
                          Anda.
                        </p>
                      </div>
                      <div className="d-block text-justify mt-1">
                        <b>Unggah Dokumen Pendukung:</b>
                        <p className="d-block text-justify">
                          Lampirkan dokumen-dokumen pendukung yang diperlukan, seperti foto KTP dan
                          dokumen lainnya sesuai petunjuk yang disediakan dalam formulir.
                        </p>
                      </div>
                      <div className="d-block text-justify mt-1">
                        <b>Verifikasi Identitas:</b>
                        <p className="d-block text-justify">
                          Lakukan proses verifikasi identitas yang mungkin melibatkan pengisian kode
                          OTP (One Time Password) yang akan dikirimkan melalui SMS atau email.
                        </p>
                      </div>
                      <div className="d-block text-justify mt-1">
                        <b>Pembayaran Iuran:</b>
                        <p className="d-block text-justify">
                          Setelah formulir terverifikasi, Anda akan diberikan informasi mengenai
                          besaran iuran yang harus dibayarkan.Lakukan pembayaran iuran sesuai dengan
                          metode pembayaran yang disediakan.
                        </p>
                      </div>
                      <div className="d-block text-justify mt-1">
                        <b>Aktivasi Akun:</b>
                        <p className="d-block text-justify">
                          Setelah pembayaran, akun Anda akan diaktifkan dan Anda akan menerima
                          konfirmasi melalui email atau SMS.
                        </p>
                      </div>
                      <div className="d-block text-justify mt-1">
                        <b>Download Kartu BPJS:</b>
                        <p className="d-block text-justify">
                          Log in ke akun Anda dan download kartu BPJS Kesehatan yang sudah
                          diaktivasi. Kartu ini dapat dicetak atau disimpan dalam format digital.
                        </p>
                      </div>
                      <div className="d-block text-justify mt-1">
                        <b>Pemilihan Fasilitas Kesehatan:</b>
                        <p className="d-block text-justify">
                          Pilih fasilitas kesehatan yang akan menjadi tempat pelayanan kesehatan
                          Anda. Biasanya, Anda dapat memilih rumah sakit atau puskesmas yang bekerja
                          sama dengan BPJS.
                        </p>
                      </div>
                      <div className="d-block text-justify mt-1">
                        <b>Cek Status Keanggotaan:</b>
                        <p className="d-block text-justify">
                          Secara berkala, cek status keanggotaan Anda melalui akun online BPJS
                          Kesehatan untuk memastikan bahwa keanggotaan Anda tetap aktif.
                        </p>
                      </div>
                      <div className="d-block text-justify mt-1">
                        Pastikan untuk mengikuti petunjuk dengan cermat pada situs resmi BPJS
                        Kesehatan dan memastikan bahwa informasi yang Anda masukkan benar. Jika Anda
                        mengalami kesulitan atau membutuhkan bantuan, Anda dapat menghubungi pusat
                        layanan BPJS Kesehatan melalui kontak yang disediakan di situs web resmi
                        mereka.
                      </div>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="11"
                    className="button-collapse w-100 text-left"
                  >
                    Cara Daftar BPJS Offline Dikantor Langsung
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="11">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Berikut adalah langkah-langkah cara mendaftar BPJS Kesehatan offline datang
                        ke kantor langsung:
                      </p>
                      <div className="d-block text-justify mt-1">
                        <b>Persiapan Dokumen:</b>
                        <p className="d-block text-justify">
                          Siapkan dokumen-dokumen yang diperlukan, seperti Kartu Tanda Penduduk
                          (KTP) atau dokumen identitas lainnya, dan nomor Pokok Wajib Pajak (NPWP),
                          jika dimiliki.Dokumen tambahan seperti kartu keluarga atau surat nikah
                          mungkin juga diperlukan untuk pendaftaran keluarga.
                        </p>
                      </div>
                      <div className="d-block text-justify mt-1">
                        <b>Kunjungi Kantor BPJS Kesehatan Terdekat:</b>
                        <p className="d-block text-justify">
                          Datang langsung ke kantor BPJS Kesehatan terdekat di wilayah tempat
                          tinggal Anda. Anda dapat menemukan kantor BPJS Kesehatan di berbagai
                          daerah di Indonesia.
                        </p>
                      </div>
                      <div className="d-block text-justify mt-1">
                        <b>Isi Formulir Pendaftaran:</b>
                        <p className="d-block text-justify">
                          Mintalah formulir pendaftaran kepada petugas di kantor BPJS Kesehatan.
                          Isilah formulir tersebut dengan data yang benar dan lengkap.
                        </p>
                      </div>
                      <div className="d-block text-justify mt-1">
                        <b>Verifikasi Dokumen:</b>
                        <p className="d-block text-justify">
                          Setelah mengisi formulir, serahkan formulir pendaftaran beserta dokumen
                          pendukung ke petugas. Petugas akan melakukan verifikasi dokumen dan
                          informasi yang Anda berikan.
                        </p>
                      </div>
                      <div className="d-block text-justify mt-1">
                        <b>Pembayaran Iuran:</b>
                        <p className="d-block text-justify">
                          Setelah dokumen diverifikasi, Anda akan diberikan informasi tentang
                          besaran iuran yang harus dibayarkan.Lakukan pembayaran iuran sesuai dengan
                          ketentuan yang berlaku. Pembayaran dapat dilakukan melalui berbagai metode
                          yang disediakan.
                        </p>
                      </div>
                      <div className="d-block text-justify mt-1">
                        <b>Aktivasi Kartu BPJS:</b>

                        <p className="d-block text-justify">
                          Setelah pembayaran iuran, Anda akan mendapatkan kartu BPJS Kesehatan.
                          Kartu ini dapat diaktifkan dengan cara melakukan pendaftaran kartu melalui
                          aplikasi BPJS Kesehatan atau dengan mengunjungi kantor BPJS.
                        </p>
                      </div>
                      <div className="d-block text-justify mt-1">
                        <b>Pemilihan Fasilitas Kesehatan:</b>

                        <p className="d-block text-justify">
                          Setelah kartu diaktifkan, pilih fasilitas kesehatan yang akan menjadi
                          tempat pelayanan kesehatan Anda. Ini bisa rumah sakit atau puskesmas yang
                          bekerja sama dengan BPJS.
                        </p>
                      </div>
                      <div className="d-block text-justify mt-1">
                        <b>Cek Status Keanggotaan:</b>

                        <p className="d-block text-justify">
                          Pastikan untuk secara berkala mengecek status keanggotaan Anda melalui
                          aplikasi atau layanan online BPJS Kesehatan untuk memastikan bahwa
                          keanggotaan Anda aktif. Langkah-langkah di atas dapat mengalami sedikit
                          perubahan tergantung pada kebijakan BPJS Kesehatan yang berlaku.
                        </p>
                      </div>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="12"
                    className="button-collapse w-100 text-left"
                  >
                    Cara Klaim Asuransi BPJS Kesehatan
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="12">
                  <Card.Body className="card-content">
                    <Row>
                      <div className="d-block text-justify mt-1">
                        <b>Kunjungi fasilitas kesehatan terdekat.</b>

                        <p className="d-block text-justify">
                          BPJS Kesehatan dapat digunakan untuk semua jenis penyakit namun harus
                          dipenuhi syarat-syarat tertentu. Pertama, pergi ke fasilitas kesehatan
                          tingkat 1 untuk berobat. Fasilitas kesehatan tingkat 1 meliputi klinik,
                          puskesmas, dokter keluarga, dan rumah sakit kelas D.
                        </p>
                      </div>
                      <div className="d-block text-justify mt-1">
                        <b>Surat rujukan diperlukan.</b>

                        <p className="d-block text-justify">
                          Surat rujukan akan diberikan bila fasilitas kesehatan tingkat 1 tidak
                          dapat mengobati penyakit dan mengadu ke fasilitas yang dimilikinya.
                        </p>
                        <p className="d-block text-justify">
                          Jika Anda mengalami gejala yang tidak dapat ditangani oleh fasilitas
                          kesehatan tingkat 1 dan memerlukan penanganan cepat, Anda dapat langsung
                          berobat ke rumah sakit yang fasilitasnya lengkap. bekerja sama dengan BPJS
                          Kesehatan tanpa surat rujukan.
                        </p>
                      </div>
                      <div className="d-block text-justify mt-1">
                        <b>Dokumen lengkap</b>

                        <p className="d-block text-justify">
                          Dokumen lain yang harus dibawa bersama surat rujukan untuk klaim santunan
                          BPJS Kesehatan adalah asli kartu BPJS Kesehatan yang dilampiri fotokopi,
                          fotokopi KTP, fotokopi kartu keluarga, fotokopi rujukan dari fasilitas
                          kesehatan kelas 1.{' '}
                        </p>
                      </div>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="13"
                    className="button-collapse w-100 text-left"
                  >
                    Cara Berhenti Dari Keanggotaan BPJS Kesehatan
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="13">
                  <Card.Body className="card-content">
                    <Row>
                      <div>
                        <h4>Hal-Hal Yang Bisa Membuat Kamu Berhenti Dari BPJS</h4>
                        <ol className="text-justify list-content">
                          <li>Meninggal Dunia</li>
                          <li>Pindah Kewarganegaraan/keluar dari indonesia</li>
                          <li>
                            Kamu sudah tidak bekerja dan tidak mampu membayar BPJS kesehatan secara
                            mandiri
                          </li>
                          <li>Mengundurkan diri dan tidak ingin memakai BPJS Kesehatan</li>
                        </ol>
                      </div>
                      <div className="mt-3">
                        <h4>Cara Berhenti Dari BPJS Kesehatan</h4>
                        <ol className="text-justify list-content">
                          <li>
                            Melalui online : Kamu bisa berhenti BPJS Kesehatan secara online melalui
                            website BPJS Kesehatan (bpjs-kesehatan.go.id) atau mobile app BPJS
                            Kesehatan (JKN Mobile).
                          </li>
                          <li>
                            Melalui offline : Kamu bisa berhenti BPJS Kesehatan secara offline
                            dengan datang langsung ke kantor BPJS Kesehatan terdekat.
                          </li>
                        </ol>
                      </div>
                      <div className="mt-3">
                        <h4>Berhenti Dari BPJS Kesehatan Melalui Online</h4>
                        <p>
                          Mau berhenti BPJS Kesehatan melalui online, kamu bisa ikuti
                          langkah-langkah berikut:
                        </p>
                        <ol className="text-justify list-content">
                          <li>
                            Buka website BPJS Kesehatan (bpjs-kesehatan.go.id) atau mobile app BPJS
                            Kesehatan (JKN Mobile).
                          </li>
                          <li>Login dengan menggunakan akun BPJS Kesehatan kamu.</li>
                          <li>Klik menu &ldquo;Pengajuan Penonaktifan Peserta&rdquo;.</li>
                          <li>Pilih jenis kepesertaan yang ingin kamu nonaktifkan.</li>
                          <li>
                            Isi data yang diperlukan, seperti tanggal nonaktif dan alasan nonaktif.
                          </li>
                          <li>Klik tombol &ldquo;Submit&rdquo;.</li>
                        </ol>
                      </div>
                      <div className="mt-3">
                        <h4>Berhenti BPJS Kesehatan Melalui Offline</h4>
                        <ol className="text-justify list-content">
                          <li>Datang langsung ke kantor BPJS Kesehatan terdekat.</li>
                          <li>Ambil nomor antrean di bagian pelayanan penonaktifan peserta.</li>
                          <li>Tunggu hingga nomor antrean kamu dipanggil.</li>
                          <li>
                            Sampaikan kepada petugas bahwa kamu ingin berhenti BPJS Kesehatan.
                          </li>
                          <li>
                            Serahkan dokumen yang diperlukan, seperti kartu BPJS Kesehatan dan KTP.
                          </li>
                          <li>
                            Petugas akan memproses pengajuan penonaktifan BPJS Kesehatan kamu.
                          </li>
                        </ol>
                      </div>
                      <div className="mt-3">
                        <h4>Lama Proses Penonaktifan Dari BPJS Kesehatan</h4>
                        <p>
                          Untuk melepas keanggotaan dari BPJS Kesehatan biasanya membutuhkan waktu
                          hingga 1-2 minggu (14 hari kerja) setelah resmi keanggotaan mu tidak aktif
                          dari BPJS kesehatan maka kamu sudah tidak bisa mendapatkan fasilitas dan
                          pelayanan lagi.
                        </p>
                      </div>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="14"
                    className="button-collapse w-100 text-left"
                  >
                    Syarat Dan Ketentuan Pembayaran BPJS Kesehatan
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="14">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Syarat dan ketentuan pembayaran tagihan BPJS merupakan bagian dari syarat
                        dan ketentuan website/aplikasi SpeedCash. Pengajuan dan penggunaan Layanan
                        Pembayaran Tagihan BPJS tunduk pada Syarat dan Ketentuan Website/Aplikasi,
                        Kebijakan Privasi dan Syarat dan Ketentuan yang tertulis di bawah ini.
                        Pengguna hendaknya membaca dengan seksama karena hal ini dapat mempengaruhi
                        hak dan kewajiban hukum Pengguna.
                      </p>
                      <p className="d-block text-justify mt-1">
                        Dengan mendaftar dan/atau menggunakan website https://www.speedcash.co.id,
                        Pengguna dianggap telah membaca, memahami , memahami dan menerima seluruh
                        isi Ketentuan Umum. Syarat dan ketentuan ini merupakan suatu bentuk
                        perjanjian sebagaimana tertuang dalam perjanjian sah antara pengguna dengan
                        PT Bimasakti Multi Sinergi. Jika pengguna tidak menerima seluruh atau
                        sebagian isi ketentuan umum, ia tidak boleh menggunakan SpeedCash.
                      </p>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="15"
                    className="button-collapse w-100 text-left"
                  >
                    Cek BPJS Kesehatan Dengan NIK
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="15">
                  <Card.Body className="card-content">
                    <Row>
                      <div className="w-100 my-1">
                        <p className="d-block text-justify">
                          Untuk mengecek BPJS kesehatan menggunakan NIK (nomor induk ktp) ada
                          beberapa cara berikut langkahnya:
                        </p>
                      </div>
                      <div className="w-100 my-1">
                        <div className="ml-3">
                          <ul className="text-justify list-content">
                            <li>
                              Simpan nomor <b>0877-7550-0400</b> ke dalam kontak telepon Anda.
                            </li>
                            <li>
                              Ketik pesan <b>NIK (spasi) nomor NIK KTP Anda</b>. Contoh:{' '}
                              <b>NIK 1234567890</b>.
                            </li>
                            <li>
                              Kirim pesan ke <b>0877-7550-0400</b>.
                            </li>
                            <li>
                              Tunggu balasan SMS yang berisi informasi nomor BPJS Kesehatan Anda.
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="w-100 my-1">
                        <p className="d-block text-justify">
                          Untuk cara kedua melihat BPJS kesehatan dengan NIK begini caranya :
                        </p>
                      </div>
                      <div className="w-100 my-1">
                        <div className="ml-3">
                          <ul className="text-justify list-content">
                            <li>
                              Kunjungi website <b>https://bpjs-kesehatan.go.id/</b>.
                            </li>
                            <li>
                              Pilih menu <b>&ldquo;Cek Status Peserta&rdquo;</b>.
                            </li>
                            <li>
                              Masukkan <b>NIK</b> dan <b>tanggal lahir</b> Anda.
                            </li>
                            <li>
                              Klik <b>&ldquo;Cek&rdquo;</b>.
                            </li>
                            <li>Informasi nomor BPJS Kesehatan Anda akan ditampilkan.</li>
                          </ul>
                        </div>
                      </div>

                      <div className="mt-1">
                        <h4 className="text-center">Info Lain Mengenai BPJS Kesehatan</h4>
                        <ol className="text-justify list-content">
                          <li>
                            Bayar Tagihan BPJS Kesehatan dilakukan paling lambat per tanggal 10
                            (sepuluh) setiap bulannya.
                          </li>
                          <li>
                            Transaksi pada tanggal 10 (sepuluh) pukul 00.01 WIB akan dideteksi
                            “terlambat membayar” oleh sistem BPJS Kesehatan.
                          </li>
                          <li>
                            Pembayaran BPJS tidak dapat dilakukan pada pukul 23.00 WIB – 01.00 WIB
                            dikarenakan pihak BPJS melakukan cut off.
                          </li>
                          <li>
                            Untuk bayar Tagihan BPJS Kesehatan menggunakan Kartu Keluarga sehingga
                            jenis pembayaran perorangan tidak dapat lagi dilakukan.
                          </li>
                          <li>
                            Pelanggan yang melakukan Pembayaran Tagihan BPJS Kesehatan harus
                            membayar total pembayaran seluruh anggota keluarga yang tertera di Kartu
                            Keluarga termasuk tunggakan (jika ada).
                          </li>
                          <li>
                            Jika tidak melakukan pembayaran BPJS Kesehatan setelah tanggal 10
                            (sepuluh), kartu peserta BPJS Kesehatan Anda akan dinonaktifkan dan
                            tidak dapat digunakan.
                          </li>
                          <li>
                            Pembayaran Tagihan BPJS Kesehatan dapat menggunakan metode pembayaran
                            Saldo SpeedCash. Pembayaran Tagihan BPJS tidak dapat menggunakan metode
                            pembayaran cicilan.
                          </li>
                          <li>
                            Pembayaran Tagihan BPJS Kesehatan hanya melayani pembayaran iuran
                            bulanan dan tunggakan BPJS Kesehatan saja (tidak termasuk pembayaran
                            denda).
                          </li>
                          <li>
                            Pembayaran Tagihan BPJS Kesehatan melalui Situs/Aplikasi dikenakan biaya
                            admin yang berlaku di SpeedCash
                          </li>
                        </ol>
                      </div>
                      <p className="d-block text-justify mt-1">
                        Itulah tadi informasi mengenai cek bpjs kesehatan online dan bayar langsung
                        di speedcash agar mendapat diskon s.d Rp.50.000. Jika kamu merasa tertarik
                        untuk mengecek BPJS kesehatan bulananmu kamu bisa mulai cek tagihan BPJS
                        kesehatan di HP melalui website SpeedCash ataupun melalui halaman ini.
                      </p>
                      <div className="mt-1 w-100 d-flex justify-content-center">
                        <h5 className="bold text-center">
                          Tunggu apalagi,cek BPJS kesehatan sekarang juga.
                        </h5>
                      </div>
                      <Row className="d-flex w-100 justify-content-center mb-3 gap-3">
                        <a
                          aria-hidden
                          href="https://play.google.com/store/apps/details?id=com.bm.sc.bebasbayar&listing=speedcash_banner_app"
                          className="mx-1"
                        >
                          <Image
                            src="/img/playstore-speedcash.png"
                            data-target="playstore-speedcash"
                            width="220px"
                            height="60px"
                          />
                        </a>
                        <a
                          aria-hidden
                          href="https://apps.apple.com/id/app/speedcash-transfer-ewallet/id6459304122"
                          className="mx-1"
                        >
                          <Image
                            src="/img/appstore-speedcash.png"
                            data-target="appstore-speedcash"
                            width="220px"
                            height="60px"
                          />
                        </a>
                      </Row>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default AccordionBpjs
