/* eslint-disable no-nested-ternary */
/* eslint-disable react/forbid-prop-types */
import React, { useContext } from 'react'
import { Accordion, Nav } from 'react-bootstrap'
import AccordionContext from 'react-bootstrap/AccordionContext'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useLocation } from 'react-router-dom'
import classNames from 'classnames'
import PropTypes from 'prop-types'

const SubMenuComponent = ({ id, icon, title, items, classStyle }) => {
  const location = useLocation()
  const currentActiveKey = useContext(AccordionContext)

  return (
    <Nav.Item className={classNames(...classStyle, { open: id === currentActiveKey })}>
      <Accordion.Toggle
        as={Nav.Link}
        variant="link"
        eventKey={id}
        data-menuid={id}
        className={classNames('parent', { open: id === currentActiveKey })}
      >
        {!!icon && <FontAwesomeIcon icon={icon} fixedWidth className="mr-2" />}
        <strong>
          {title}
          <FontAwesomeIcon
            icon={id !== currentActiveKey ? 'chevron-right' : 'chevron-down'}
            className="float-right mt-1 mr-2"
            fixedWidth
          />
        </strong>
      </Accordion.Toggle>

      <Accordion.Collapse eventKey={id}>
        <nav className="nav flex-column pb-3">
          {items.map((item) => (
            <Nav.Link
              className={classNames('nav-item', 'nav-link', {
                active:
                  item.path === '/pln'
                    ? location.pathname.includes('pln')
                    : item.path === '/game'
                    ? location.pathname.includes('game')
                    : item.path === '/kartukredit'
                    ? location.pathname.includes('kartukredit')
                    : item.path === '/emoney'
                    ? location.pathname.includes('emoney')
                    : item.path === '/asuransi'
                    ? location.pathname.includes('asuransi')
                    : item.path === '/pascabayar'
                    ? location.pathname.includes('pascabayar')
                    : item.path === '/finance'
                    ? location.pathname.includes('finance')
                    : item.path === '/e-samsat'
                    ? location.pathname.includes('e-samsat')
                    : item.path === location.pathname,
              })}
              href={item.path}
              key={item.path}
            >
              <FontAwesomeIcon icon={item.icon} className="mt-1 mr-2" fixedWidth />
              {item.title}
            </Nav.Link>
          ))}
        </nav>
      </Accordion.Collapse>
    </Nav.Item>
  )
}

SubMenuComponent.defaultProps = {
  icon: '',
  title: '',
  id: '',
  items: [],
  classStyle: [],
}

SubMenuComponent.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string,
  id: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.any),
  classStyle: PropTypes.arrayOf(PropTypes.any),
}

export default SubMenuComponent
