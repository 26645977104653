/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-nested-ternary */
import _ from 'lodash'
import React from 'react'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import {
  Spinner,
  Form,
  InputGroup,
  Container,
  Jumbotron,
  Button,
  Image,
  Row,
  Col,
} from 'react-bootstrap'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { isMobile } from 'react-device-detect'
import { useHistory, useLocation } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Alert from 'react-popup-alert'
import 'react-popup-alert/dist/index.css'
import DOMHelper from '../../../helper/domhelper'
import Formatter from '../../../helper/formatter'
import HookHelper from '../../../helper/hookhelper'
import utilConstant from '../../../helper/utilconstants'

import './layanan.scss'
import globalConstant from '../../../store/constant/global'
import authConstant from '../../../store/constant/auth'
import SidebarComponent from '../../component/sidebar'
import HeaderComponent from '../../component/header'
import LoginModal from '../../dialog/auth/login'
import KartuKreditModal from '../../dialog/layanan/kartukredit'
import ProductListNewDialog from '../../dialog/layanan/productListNew'
import AccordionKkBniComponent from '../../component/accordionKkBni'

import { tagihanInq, tagihanPay } from '../../../api/action/layanan/tagihan'
import { listProdukUbp } from '../../../api/action/global'

const KartuKreditBniScreen = ({
  appUserId,
  appUserPin,
  appUserBalance,
  appUserIsEmbed,
  appIsLoading,
  dispatch,
}) => {
  const GROUP_PRODUK = 'KARTU KREDIT'
  const history = useHistory()
  const location = useLocation()
  const [isOpen, setIsOpen] = React.useState(!isMobile)
  const [isInqWeb, setIsInqWeb] = React.useState(false)
  const [alertError, setAlertError] = React.useState('')
  const [loadingGetProduk, setLoadingGetProduk] = React.useState(false)
  const [loginModalShow, setLoginModalShow] = React.useState(false)
  const [tagihanModalShow, setTagihanModalShow] = React.useState(false)
  const [userId] = React.useState(!appUserId ? process.env.REACT_APP_USER_DEMO_ID : appUserId)
  const [userPin] = React.useState(
    !appUserId ? process.env.REACT_APP_USER_DEMO_PASSWORD : appUserPin
  )
  const [isSaldoOk, setIsSaldoOk] = React.useState(false)
  const [voucherQty] = React.useState('0')
  const [produk, setProduk] = React.useState('')
  const [produkLabel, setProdukLabel] = React.useState('')
  const [cardNumber, setCardNumber] = React.useState('')
  const [nominal, setNominal] = React.useState('0')
  const [dataProduk, setDataProduk] = React.useState([])
  const [dataProdukFiltered, setDataProdukFiltered] = React.useState([])
  const [dataRes, setDataRes] = React.useState(null)
  const [serachProdukShow, setSearchProdukShow] = React.useState(false)
  const [searchProduk, setSearchProduk] = React.useState('')

  const toggleSidebar = React.useCallback(() => setIsOpen(!isOpen), [isOpen, setIsOpen])

  const closeError = React.useCallback(() => setAlertError(''), [])

  const openLoginModal = React.useCallback(async () => {
    setLoginModalShow(true)
  }, [])

  const confirmLoginModal = React.useCallback(async () => {
    setLoginModalShow(false)
    // window.location.reload()
  }, [])

  const closeLoginModal = React.useCallback(async () => {
    setLoginModalShow(false)
  }, [])

  const closeTagihanModal = React.useCallback(async () => {
    setTagihanModalShow(false)
  }, [])

  const openProdukShow = React.useCallback(async () => {
    setSearchProdukShow(true)
  }, [setSearchProdukShow])

  const closeProdukShow = React.useCallback(async () => {
    setSearchProdukShow(false)
  }, [setSearchProdukShow])

  const onSearchProdukChange = React.useCallback(
    (e) => {
      const { value } = e.target
      const newData = _.filter(dataProduk, (item) => {
        return _.includes(item.produk.toLowerCase(), value.toLowerCase())
      })

      setSearchProduk(value)
      setDataProdukFiltered(newData)
    },
    [dataProduk, setSearchProduk, setDataProdukFiltered]
  )

  const onCardNumberChange = React.useCallback((e) => {
    const { value } = e.target
    setCardNumber(Formatter.number(value))
  }, [])

  const onNominalChange = React.useCallback((e) => {
    const { value } = e.target
    setNominal(Formatter.number(value))
  }, [])

  const onProdukChange = React.useCallback(
    (e) => {
      const { target, label } = e.currentTarget.dataset

      if (target === 'KKBNI') {
        history.push({
          pathname: '/kartukredit/bni',
          state: {
            target,
            label,
          },
        })
      } else {
        history.push({
          pathname: '/kartukredit',
          state: {
            target,
            label,
          },
        })
      }
    },
    [history]
  )

  const handleClear = React.useCallback(
    (e) => {
      const { target } = e.currentTarget.dataset
      if (target === 'card-number') {
        setCardNumber('')
      }
      if (target === 'nominal') {
        setNominal('0')
      }

      setIsInqWeb(false)
      setDataRes(null)
    },
    [setCardNumber, setNominal, setIsInqWeb, setDataRes]
  )

  const onProcess = React.useCallback(async () => {
    if (!produk) {
      setAlertError({ rc: '', rd: 'Pilih Produk Kartu Kredit Terlebih Dahulu' })
      return false
    }

    if (!cardNumber) {
      setAlertError({ rc: '', rd: 'Masukkan Nomor Kartu Terlebih Dahulu' })
      return false
    }

    if (Math.round(parseFloat(nominal)) <= 0) {
      setAlertError({ rc: '', rd: 'Nominal harus diatas 0' })
      return false
    }

    const responseInq = await dispatch(
      tagihanInq(userId, userPin, GROUP_PRODUK, produk, cardNumber, nominal, voucherQty)
    )
    if (responseInq.isOK()) {
      const dataRespon = responseInq.getData()
      const { amount } = dataRespon.includes
      const { admin } = dataRespon.includes
      const total =
        parseInt(amount, 0) + parseInt(admin, 0) + parseInt(dataRespon.additional_datas[93], 10)
      if (Math.round(parseFloat(appUserBalance)) >= total) {
        setIsSaldoOk(true)
      }

      setDataRes(dataRespon)
      if (isMobile) {
        setTagihanModalShow(true)
      } else {
        setIsInqWeb(true)
      }
    } else {
      setAlertError({ rc: responseInq.getRC(), rd: responseInq.getRD() })
    }

    return true
  }, [
    dispatch,
    userId,
    userPin,
    appUserBalance,
    voucherQty,
    produk,
    cardNumber,
    nominal,
    setIsInqWeb,
    setIsSaldoOk,
    setAlertError,
  ])

  const redirectToDeposit = React.useCallback(() => {
    history.push('/deposit')
  }, [history])

  const confirmPayment = React.useCallback(async () => {
    if (!appUserId) {
      setTagihanModalShow(false)
      setLoginModalShow(true)
    } else if (isSaldoOk) {
      const dataIncludes = dataRes.includes
      const { amount, admin } = dataIncludes
      const reffId = dataIncludes.reff_id
      const responsePay = await dispatch(
        tagihanPay(
          userId,
          userPin,
          voucherQty,
          GROUP_PRODUK,
          produk,
          cardNumber,
          amount,
          admin,
          reffId
        )
      )
      if (responsePay.isOK()) {
        history.push({
          pathname: '/struk',
          state: {
            parent: 'kartukredit',
            parentPath: location.pathname,
            title: 'Kartu Kredit',
            icon: 'credit-card',
            transaksi: responsePay.data.includes.reff_id,
            trxDate: responsePay.data.transmission_datetime,
            trxTime: responsePay.data.transmission_datetime,
            total:
              Number(responsePay.data.includes.amount) +
              Number(responsePay.data.includes.admin) +
              parseInt(responsePay.data.additional_datas[93], 10),
            customerId: responsePay.data.includes.customer_id_1,
            discountInfo: responsePay.data.discountInfo,
            info: 'sukses',
          },
        })
      } else if (
        responsePay.getResponseCode() === '' ||
        responsePay.getRC() === '68' ||
        responsePay.getRC().toString() === responsePay.data.includes.reff_id.toString()
      ) {
        history.push({
          pathname: '/struk',
          state: {
            parent: 'kartukredit',
            parentPath: location.pathname,
            title: 'Kartu Kredit',
            icon: 'credit-card',
            transaksi: responsePay.data.includes.reff_id,
            trxDate: responsePay.data.transmission_datetime,
            trxTime: responsePay.data.transmission_datetime,
            total:
              Number(responsePay.data.includes.amount) +
              Number(responsePay.data.includes.admin) +
              parseInt(responsePay.data.additional_datas[93], 10),
            customerId: responsePay.data.includes.customer_id_1,
            discountInfo: responsePay.data.discountInfo,
            info: 'pending',
          },
        })
      } else {
        setAlertError({ rc: responsePay.getRC(), rd: responsePay.getRD() })
      }
    } else {
      redirectToDeposit()
    }
  }, [
    dispatch,
    history,
    location,
    isSaldoOk,
    appUserId,
    userId,
    userPin,
    voucherQty,
    produk,
    cardNumber,
    dataRes,
    setAlertError,
    redirectToDeposit,
  ])

  React.useEffect(() => {
    DOMHelper.mountClass('layanan-layout')
    HookHelper.resetLoading()

    dispatch(listProdukUbp(userId, utilConstant.groupProdukKartuKredit)).then((response) => {
      if (response.isOK()) {
        const resData = response.getData()
        setDataProduk(resData.data)
        setDataProdukFiltered(resData.data)
      }
    })

    const target = location?.state?.target
    const label = location?.state?.label

    if (target && label) {
      setProduk(target)
      setProdukLabel(label)
      setSearchProdukShow(false)

      setIsInqWeb(false)
      setDataRes(null)
      setCardNumber('')
      setNominal('0')
    }

    return () => DOMHelper.unmountClass(['layanan-layout'])
  }, [dispatch, userId, setLoadingGetProduk, location])

  return (
    <>
      <Helmet>
        <title>Cek Tagihan Kartu Kredit BNI Dan Bayar Langsung Ada Diskon</title>
        <meta name="title" content="Cek Tagihan Kartu Kredit BNI Dan Bayar Langsung Ada Diskon" />
        <meta
          name="description"
          content="Cara cek tagihan kartu kredit BNI dan bayar online langsung disini bisa dapat diskon.Bayar tagihan bulanan lainnya bisa hemat s.d Rp.50.000.Cek Sekarang."
        />
        <meta name="google-play-app" content="app-id=com.bm.sc.bebasbayar" />
        <meta
          name="_globalsign-domain-verification"
          content="VncJWgSU_cQw-hAuU0PELvI3g4HQ4l6XFSs9v-jA1Y"
        />
        <meta name="facebook-domain-verification" content="pknnsi25vbw0rfl1x8xctdtumrh3bs" />
        <meta name="msvalidate.01" content="9A9127824837BF1E05F5AED70295F281" />
        <meta name="alexaVerifyID" content="Nc8Q89T9GsncVlA5xukQcbi5rJ0" />
        <meta httpEquiv="X-UA-Compatible" content="IE=Edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="author" content="https://www.facebook.com/SpeedCash.BebasBayarID" />
        <link rel="canonical" href="https://member.speedcash.co.id/kartukredit/bni" />
        <meta
          name="robots"
          content="follow, index, max-snippet:-1, max-video-preview:-1, max-image-preview:large"
        />
        <meta name="geo.country" content="id" />
        <meta name="revisit-after" content="1 days" />
        <meta name="geo.placename" content="Indonesia" />
        <meta content="id_id" property="og:locale" />
        <meta
          itemProp="name"
          content="Cek Tagihan Kartu Kredit BNI Dan Bayar Langsung Ada Diskon"
        />
        <meta
          itemProp="description"
          content="Cara cek tagihan kartu kredit BNI dan bayar online langsung disini bisa dapat diskon.Bayar tagihan bulanan lainnya bisa hemat s.d Rp.50.000.Cek Sekarang."
        />
        <meta
          itemProp="image"
          content="https://www.speedcash.co.id/images/speedcash-logo-square.jpg"
        />
        <meta property="fb:app_id" content="966242223397117" />
        <meta
          property="og:title"
          content="Cek Tagihan Kartu Kredit BNI Dan Bayar Langsung Ada Diskon"
        />
        <meta property="og:type" content="article" />
        <meta
          property="og:image"
          content="https://blog.speedcash.co.id/wp-content/uploads/2024/03/cek-tagihan-kartu-kredit-bni.png"
        />
        <meta
          property="og:description"
          content="Cara cek tagihan kartu kredit BNI dan bayar online langsung disini bisa dapat diskon.Bayar tagihan bulanan lainnya bisa hemat s.d Rp.50.000.Cek Sekarang."
        />
        <meta property="og:site_name" content="SpeedCash" />
        <meta property="og:url" content="https://www.speedcash.co.id/" />
        <meta
          property="article:tag"
          content="Cara cek tagihan kartu kredit BNI dan bayar online langsung disini bisa dapat diskon.Bayar tagihan bulanan lainnya bisa hemat s.d Rp.50.000.Cek Sekarang."
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@SpeedcashO" />
        <meta
          name="twitter:title"
          content="Cek Tagihan Kartu Kredit BNI Dan Bayar Langsung Ada Diskon"
        />
        <meta
          name="twitter:description"
          content="Cara cek tagihan kartu kredit BNI dan bayar online langsung disini bisa dapat diskon.Bayar tagihan bulanan lainnya bisa hemat s.d Rp.50.000.Cek Sekarang."
        />
        <meta name="twitter:creator" content="@SpeedcashO" />
        <meta
          name="twitter:image"
          content="https://blog.speedcash.co.id/wp-content/uploads/2024/03/cek-tagihan-kartu-kredit-bni.png"
        />
        <meta
          name="twitter:image:src"
          content="https://blog.speedcash.co.id/wp-content/uploads/2024/03/cek-tagihan-kartu-kredit-bni.png"
        />
        <meta name="twitter:label1" content="Deskripsi" />
        <meta
          name="twitter:data1"
          content="Cara cek tagihan kartu kredit BNI dan bayar online langsung disini bisa dapat diskon.Bayar tagihan bulanan lainnya bisa hemat s.d Rp.50.000.Cek Sekarang."
        />
        <meta name="twitter:label2" content="Tag" />
        <meta
          name="twitter:data2"
          content="Cara cek tagihan kartu kredit BNI dan bayar online langsung disini bisa dapat diskon.Bayar tagihan bulanan lainnya bisa hemat s.d Rp.50.000.Cek Sekarang."
        />

        <script type="application/ld+json">
          {`{
          "@context":"https://schema.org",
          "@graph":[{
          "@type":"Organization",
          "@id":"https://member.speedcash.co.id/#organization",
          "name":"PT Bimasakti Multi Sinergi",
          "url":"https://member.speedcash.co.id",
          "sameAs":[
          "https://www.facebook.com/SpeedCash.BebasBayarID/",
          "https://www.instagram.com/speedcash.official/",
          "https://www.linkedin.com/in/aplikasi-bebasbayar-864aa7195/",
          "https://www.youtube.com/c/SpeedCashIDOfficial",
          "https://id.pinterest.com/bebasbayarofficial/",
          "https://www.tiktok.com/@speedcash.official",
          "https://twitter.com/SpeedcashO"],
          "logo":{
          "@type":"ImageObject",
          "@id":"https://member.speedcash.co.id/#logo",
          "url":"https://member.speedcash.co.id/img/speedcash.png",
          "width":240,"height":72,"caption":"SpeedCash.co.id"},
          "image":{
          "@id":"https://member.speedcash.co.id/#logo"}
          },{
          "@type":"WebSite",
          "@id":"https://member.speedcash.co.id/#website",
          "url":"https://member.speedcash.co.id/",
          "name":"SpeedCash",
          "publisher":{
          "@id":"https://member.speedcash.co.id/#organization"},
          "potentialAction":{
          "@type":"SearchAction",
          "target":"https://member.speedcash.co.id/?s={search_term_string}",
          "query-input":"required name=search_term_string"}
          },{
          "@type":"ImageObject",
          "@id":"https://member.speedcash.co.id/#primaryimage",
          "url":"https://member.speedcash.co.id/img/speedcash.png",
          "width":764,"height":401,
          "caption":"Cek Tagihan Kartu Kredit BNI Dan Bayar Langsung Ada Diskon"
          },{
          "@type":"WebPage","@id":"https://member.speedcash.co.id/#webpage",
          "url":"https://member.speedcash.co.id/","inLanguage":"ID",
          "name":"Cek Tagihan Kartu Kredit BNI Dan Bayar Langsung Ada Diskon",
          "isPartOf":{
          "@id":"https://member.speedcash.co.id/#website"},
          "about":{"@id":"https://member.speedcash.co.id/#organization"},
          "primaryImageOfPage":{"@id":"https://member.speedcash.co.id/#primaryimage"},
          "datePublished":"2024-03-20T03:08:35+00:00","dateModified":"2024-03-20T04:54:34+00:00",
          "description":"Cara cek tagihan kartu kredit BNI dan bayar online langsung disini bisa dapat diskon.Bayar tagihan bulanan lainnya bisa hemat s.d Rp.50.000.Cek Sekarang."
          }]
          }`}
        </script>

        {/* Google tag (gtag.js) */}
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-X7L58XGGSH" />
        <script>
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', 'G-X7L58XGGSH');
          `}
        </script>
      </Helmet>
      <Container fluid className="layanan-layout">
        {!appUserIsEmbed && (
          <Row className="header">
            <Col>
              <HeaderComponent title="Kartu Kredit" onToggle={toggleSidebar} />
            </Col>
          </Row>
        )}

        <Row>
          {!appUserIsEmbed && (
            <Col
              md={2}
              className={classNames('col-sidebar', { 'is-open': isOpen, 'is-mobile': isMobile })}
            >
              <SidebarComponent isOpen={isOpen} />
            </Col>
          )}

          <Col
            md={{
              span: appUserIsEmbed ? 12 : isOpen ? 10 : 12,
              offset: appUserIsEmbed ? 0 : isOpen ? 2 : 0,
            }}
          >
            <Jumbotron fluid className="banner">
              <Row>
                <Col>
                  <Row>
                    <span className={classNames('banner-title', { 'is-mobile': isMobile })}>
                      <FontAwesomeIcon icon="credit-card" fixedWidth className="mr-3" />
                      Kartu Kredit
                    </span>
                  </Row>
                </Col>

                {!appUserId ? (
                  <Col lg={3} xs={4} md={3} style={{ margin: 'auto' }}>
                    <Button
                      className={classNames('button-login', { 'is-mobile': isMobile })}
                      type="button"
                      onClick={openLoginModal}
                    >
                      Daftar / Login
                    </Button>
                  </Col>
                ) : (
                  <Col lg={3} xs={12} md={3} style={{ margin: 'auto' }}>
                    <Row>
                      <Col lg={10} xs={10} md={10}>
                        <div className="banner-saldo">Saldo Anda</div>
                        <div className="banner-nominal">
                          Rp. {Formatter.price(Math.round(parseFloat(appUserBalance)))}
                        </div>
                      </Col>
                      <Col lg={2} xs={2} md={2}>
                        <span className="banner-title">
                          <FontAwesomeIcon icon="wallet" fixedWidth className="mr-3" />
                        </span>
                      </Col>
                    </Row>
                  </Col>
                )}
              </Row>
            </Jumbotron>

            <Col className="d-flex justify-content-center mb-3 p-3">
              <h2 className="text-dark text-justify">
                Cek Tagihan Kartu Kredit BNI Diskon Bayar Tagihan Langsung Disini
              </h2>
            </Col>

            <div className="content-layanan">
              <Row className="justify-content-md-center">
                <Col lg={isInqWeb ? 7 : 8} xs={12} md={isInqWeb ? 7 : 8} className="bd-form">
                  <Form className="form-pelanggan single">
                    {loadingGetProduk && (
                      <Spinner
                        animation="border"
                        role="status"
                        style={{ alignSelf: 'center' }}
                        variant="primary"
                      />
                    )}
                    {!loadingGetProduk && (
                      <>
                        <Form.Group>
                          <Form.Label className="form-label start">Produk Kartu Kredit</Form.Label>
                          <InputGroup
                            className="mb-12"
                            onClick={openProdukShow}
                            style={{ cursor: 'pointer' }}
                          >
                            <Form.Control
                              placeholder="---Pilih Produk Kartu Kredit---"
                              value={produkLabel}
                              readOnly
                              style={{ cursor: 'pointer' }}
                            />
                            <InputGroup.Append>
                              <FontAwesomeIcon icon="chevron-down" className="form-icon single" />
                            </InputGroup.Append>
                          </InputGroup>
                        </Form.Group>
                        <Form.Group>
                          <Form.Label className="form-label start">Nomor Kartu</Form.Label>
                          <InputGroup className="mb-12">
                            <Form.Control
                              placeholder="Masukkan Nomor Kartu"
                              value={Formatter.serial(cardNumber)}
                              onChange={onCardNumberChange}
                            />
                            <InputGroup.Append className="flex align-items-center">
                              <Image
                                className={classNames('form-icon-btn', { 'ic-mobile': isMobile })}
                                src="/icon/clear.png"
                                data-target="card-number"
                                onClick={handleClear}
                              />
                            </InputGroup.Append>
                          </InputGroup>
                        </Form.Group>
                        <Form.Group>
                          <Form.Label className="form-label start">Nominal</Form.Label>
                          <InputGroup className="mb-12">
                            <Form.Control
                              placeholder="Masukkan Nominal"
                              value={Formatter.price(nominal)}
                              onChange={onNominalChange}
                            />
                            <InputGroup.Append className="flex align-items-center">
                              <Image
                                className={classNames('form-icon-btn', { 'ic-mobile': isMobile })}
                                src="/icon/clear.png"
                                data-target="nominal"
                                onClick={handleClear}
                              />
                            </InputGroup.Append>
                          </InputGroup>
                        </Form.Group>
                        <Row className="info-box">
                          <Col lg={1} xs={2} md={1} style={{ margin: 'auto' }}>
                            <FontAwesomeIcon
                              icon="info-circle"
                              fixedWidth
                              className="mr-3 txt-28"
                            />
                          </Col>
                          <Col lg={11} xs={10} md={11}>
                            <span style={{ marginRight: '0.5em', fontWeight: 'bold' }}>
                              *PERHATIAN!
                            </span>
                            <span>
                              Untuk menghindari denda Pembayaran Kartu Kredit Anda, dimohon
                              melakukan pembayaran 3 hari sebelum jatuh tempo dan sebelum hari
                              Jumat.
                            </span>
                          </Col>
                        </Row>
                      </>
                    )}
                  </Form>
                  <Row className="row-proses">
                    <Col style={{ margin: 'auto' }}>
                      {!loadingGetProduk && (
                        <Button
                          className="btn-process"
                          size="lg"
                          block
                          onClick={onProcess}
                          disabled={appIsLoading}
                        >
                          {appIsLoading && !isMobile && (
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />
                          )}
                          {appIsLoading && !isMobile ? ' Mohon Tunggu...' : 'Cek Tagihan Sekarang'}
                        </Button>
                      )}
                    </Col>
                  </Row>
                </Col>
                {isInqWeb && (
                  <>
                    <Col md="auto" />
                    <Col lg={4} xs={12} md={4} className="bd-form-inq">
                      <Row className="justify-content-md-center">
                        <Col className="inq-header txt-bold">
                          {dataRes ? `Tagihan ${dataRes.biller_info.productName}` : 'Kartu Kredit'}
                        </Col>
                      </Row>
                      {dataRes &&
                        dataRes.strukMaps.map((val) => (
                          <Row key={Formatter.upperFirst(Object.keys(val)[0])}>
                            <Col>{Formatter.upperFirst(Object.keys(val)[0])}</Col>
                            <Col className="inq-end-label">
                              {val ? val[Object.keys(val)[0]] : ''}
                            </Col>
                          </Row>
                        ))}
                      <Row className="justify-content-md-center inq-body-last">
                        <Col className="inq-balance">
                          <Row style={{ display: 'flex', alignItems: 'center' }}>
                            <Col lg={2} xs={2} md={2}>
                              <Image src="/img/sc.png" roundedCircle />
                            </Col>
                            <Col lg={5} xs={5} md={5}>
                              Saldo Speedcash
                            </Col>
                            <Col lg={5} xs={5} md={5} className="inq-end-label txt-bold">
                              {dataRes
                                ? `Rp ${Formatter.price(
                                    Math.round(parseFloat(dataRes.includes.balance))
                                  )}`
                                : 'Rp 0,-'}
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      {!isSaldoOk && appUserId && (
                        <Row className="justify-content-md-center inq-body-last">
                          <Col className="inq-balance not-ok">
                            <Row className="txt-bold">
                              Saldo Anda belum mencukupi untuk transaksi ini. Segera Top Up
                            </Row>
                          </Col>
                        </Row>
                      )}
                      <Row className="justify-content-md-center">
                        <Col>
                          <Button
                            className="btn-process"
                            type="button"
                            block
                            onClick={
                              appUserId
                                ? isSaldoOk
                                  ? confirmPayment
                                  : redirectToDeposit
                                : openLoginModal
                            }
                          >
                            {appIsLoading && (
                              <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                              />
                            )}
                            {appIsLoading
                              ? ' Mohon Tunggu...'
                              : appUserId
                              ? isSaldoOk
                                ? 'Bayar Sekarang'
                                : 'Top Up Saldo'
                              : 'Daftar / Login'}
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                  </>
                )}
              </Row>
            </div>

            <AccordionKkBniComponent />
          </Col>
        </Row>
        <Alert
          header="Perhatian"
          btnText="OK"
          text={alertError.rd}
          type="error"
          show={!!alertError.rd}
          onClosePress={closeError}
          showBorderBottom
          alertStyles={{ justifyContent: 'center' }}
        />
        <LoginModal
          show={!!loginModalShow}
          onHide={closeLoginModal}
          onConfirm={confirmLoginModal}
        />
        <KartuKreditModal
          show={!!tagihanModalShow}
          onHide={closeTagihanModal}
          onConfirm={confirmPayment}
          dataRes={dataRes}
          isSaldoOk={isSaldoOk}
        />
        <ProductListNewDialog
          title="Kartu Kredit"
          group="kk"
          show={!!serachProdukShow}
          onHide={closeProdukShow}
          onConfirm={onProdukChange}
          search={searchProduk}
          onSearchChange={onSearchProdukChange}
          data={dataProdukFiltered}
        />
      </Container>
    </>
  )
}

KartuKreditBniScreen.defaultProps = {
  appIsLoading: false,
  appUserId: '',
  appUserPin: '',
  appUserBalance: 0,
  appUserIsEmbed: false,
}

KartuKreditBniScreen.propTypes = {
  appUserId: PropTypes.string,
  appUserPin: PropTypes.string,
  appUserBalance: PropTypes.number,
  appUserIsEmbed: PropTypes.bool,
  appIsLoading: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
}

export default connect(
  (state) => ({
    appIsLoading: state.Global[globalConstant.IS_LOADING],
    appUserId: state.Auth[authConstant.USER_ID],
    appUserPin: state.Auth[authConstant.USER_PIN],
    appUserBalance: state.Auth[authConstant.USER_BALANCE],
    appUserIsEmbed: state.Auth[authConstant.USER_IS_EMBED],
    appUserPhone: state.Auth[authConstant.USER_PHONE],
  }),
  (dispatch) => ({ dispatch })
)(KartuKreditBniScreen)
