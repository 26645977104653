import React from 'react'
import { connect } from 'react-redux'
import { Modal, Image, Form, InputGroup, Toast } from 'react-bootstrap'
import { isMobile } from 'react-device-detect'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import copy from 'copy-to-clipboard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import globalConstant from '../../../store/constant/global'
import HookHelper from '../../../helper/hookhelper'
import Formatter from '../../../helper/formatter'
import DateTime from '../../../helper/datetime'

import './dialogDeposit.scss'

const DepositBankModal = ({ onHide, show, dataRes }) => {
  const [title, setTitle] = React.useState('Deposit')
  const [noRek, setNoRek] = React.useState('-')
  const [imgBank, setImgBank] = React.useState('')
  const [tujuan, setTujuan] = React.useState('')
  const [toastShow, setToastShow] = React.useState(false)
  const [toastMsg, setToastMSg] = React.useState('')

  const closeMessage = React.useCallback(() => setToastShow(false), [])

  const copyField = React.useCallback(
    (e) => {
      const { value, message } = e.currentTarget.dataset
      copy(value)
      setToastMSg(message)
      setToastShow(true)
    },
    [setToastMSg, setToastShow]
  )

  const openLivechat = React.useCallback(() => {
    onHide()
    window.$chatwoot.toggle('open')
  }, [onHide])

  React.useEffect(() => {
    HookHelper.resetLoading()
    if (dataRes) {
      const bankName = dataRes.additional_datas[0]

      if (bankName === 'MANDIRIPC') {
        setTitle(`Transfer ke ${dataRes.additional_datas[14]}`)
        setNoRek(dataRes.additional_datas[15])
        setImgBank('/img/bank/mandiri.png')
        setTujuan(dataRes.additional_datas[14])
      } else {
        setTitle(`Transfer ke Bank ${bankName}`)
        setNoRek(dataRes.additional_datas[12])
        setImgBank(`/img/bank/${bankName.toLowerCase()}.png`)
        setTujuan(dataRes.additional_datas[14])
      }
    }
  }, [dataRes, setTitle, setNoRek, setImgBank, setTujuan])

  return (
    <Modal
      show={show}
      onHide={onHide}
      backdrop="static"
      keyboard={false}
      centered
      contentClassName="radius pln"
      animation={false}
    >
      <Modal.Header className="header">
        <div style={{ width: '95%' }}>
          <Modal.Title className="title">{title}</Modal.Title>
        </div>
        <div className="close-modal">
          <Image
            src="/icon/clear.png"
            onClick={onHide}
            roundedCircle
            className="justify-content-end"
          />
        </div>
      </Modal.Header>

      <Modal.Body className="body">
        <div className="desc">
          <Form.Label className="label-bold txt-16">Nominal yang Harus Di Transfer</Form.Label>
          <div className="desc-cont">
            <InputGroup.Text className="label-input-text">
              Rp. {dataRes ? Formatter.price(dataRes.additional_datas[1].split('.')[0]) : '0'}
            </InputGroup.Text>
            <InputGroup.Text
              className="txt-18 color-primary"
              style={{ justifyContent: 'flex-end', cursor: 'pointer' }}
              data-value={dataRes ? dataRes.additional_datas[1].split('.')[0] : '0'}
              data-message="Nominal Deposit Sudah Tersalin"
              onClick={copyField}
            >
              Salin
            </InputGroup.Text>
          </div>
        </div>

        <div className="desc">
          <Form.Label className="label-bold txt-16">Nomor Rekening Tujuan</Form.Label>
          <InputGroup className="mb-12 desc-cont">
            <InputGroup.Text className={classNames('label-input-text', { 'is-mobile': isMobile })}>
              {Formatter.serial(noRek)}
            </InputGroup.Text>
            <InputGroup.Append className="input-group-game">
              {imgBank && (
                <Image
                  width={64}
                  height={64}
                  src={imgBank}
                  rounded
                  fluid
                  style={{ marginRight: '5px' }}
                />
              )}
              <InputGroup.Text
                className="txt-18 color-primary"
                style={{ justifyContent: 'flex-end', cursor: 'pointer' }}
                data-value={noRek}
                data-message="Nomor Rekening Tujuan Sudah Tersalin"
                onClick={copyField}
              >
                Salin
              </InputGroup.Text>
            </InputGroup.Append>
          </InputGroup>
          <Form.Text className="label-bold txt-16">{`a.n ${tujuan}`}</Form.Text>
        </div>

        <div className="desc-cont">
          <span>
            Tiket aktif selama <span className="bold">2 jam</span>, segera transfer sebelum
          </span>
          <span className="label-bold" style={{ justifyContent: 'flex-end' }}>
            {dataRes
              ? DateTime.getExpired(dataRes.transmission_datetime, DateTime.FMT_DATE_TIME_YMDHMS_NS)
              : '-'}
          </span>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <span>Dan pastikan transfer Anda sesuai dengan nominal diatas</span>
          <span>Saldo Anda akan masuk maksimal 10 menit setelah Anda melakukan transfer</span>
        </div>
      </Modal.Body>
      <Modal.Footer style={{ position: 'sticky', bottom: 0 }}>
        <div className="button-back">
          <span className="label-bold color-secondary" onClick={openLivechat} aria-hidden="true">
            Saldo belum masuk? Klik disini
          </span>
        </div>

        <div className="toast-div">
          <Toast onClose={closeMessage} show={toastShow} delay={3000} autohide animation={false}>
            <Toast.Header>
              <FontAwesomeIcon
                icon="info-circle"
                fixedWidth
                className="mr-3 color-primary txt-20"
              />
              <small className="color-primary">{toastMsg}</small>
            </Toast.Header>
          </Toast>
        </div>
      </Modal.Footer>
    </Modal>
  )
}

DepositBankModal.defaultProps = {
  onHide: null,
  show: false,
  dataRes: null,
}

DepositBankModal.propTypes = {
  onHide: PropTypes.func,
  show: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  dataRes: PropTypes.object,
}

export default connect(
  (state) => ({
    appIsLoading: state.Global[globalConstant.IS_LOADING],
  }),
  (dispatch) => ({ dispatch })
)(DepositBankModal)
