import React from 'react'
import { Button, Image, Row, Col, Accordion, Card } from 'react-bootstrap'
import './accordion.scss'

const AccordionEmoneyDana = () => {
  return (
    <>
      <Col className="d-flex justify-content-center mt-4 mb-3 p-3">
        <h3 className="text-dark text-justify">
          Cara Top Up Dana Yang Cepat,Praktis Dan Aman Pakai SpeedCash
        </h3>
      </Col>

      <div style={{ padding: '0px 20px 30px 20px' }}>
        <Row className="justify-content-md-center">
          <Col lg={8} xs={12} md={8} className="content-component">
            <div>
              <Row className="d-flex">
                <p className="d-block text-justify">
                  Mencari aplikasi keuangan yang aman untuk mengisi saldo dompet digital / ewallet
                  meruakan aktivitas yang lazim banyak dilakukan sebagian orang.Saat ini sudah
                  tersedia cara untuk top up dana yang cepat praktis dan tentunya aman dan bisa
                  dilakukan melalui HP baik dengan aplikasi ataupun website (tanpa aplikasi).Untuk
                  mengetahui lebih lanjut mengenai{' '}
                  <a href="https://member.speedcash.co.id/emoney/top-up-dana">top up dana</a>{' '}
                  cepat,praktis dan aman yuk simak caranya berikut ini.
                </p>
                <Row className="d-flex justify-content-center">
                  <Image
                    src="/img/top-up-dana-murah.png"
                    data-target="top-up-dana-murah"
                    alt="Topup Dana Murah"
                    width="100%"
                  />
                </Row>
                <Row className="d-flex mt-4 mb-2 justify-content-center text-dark w-100">
                  <h4>Top Up Dana Tanpa Aplikasi Dan Tanpa Rekening Bank</h4>
                </Row>
                <Row>
                  <p className="d-block text-justify">
                    Berikut cara top up dana tanpa aplikasi,hanya melalui website resmi SpeedCash :
                  </p>
                </Row>
                <Row className="d-flex justify-content-start">
                  <ol className="text-justify list-content">
                    <li>Kunjungi alamat web : https://member.speedcash.co.id/emoney/top-up-dana</li>
                    <li>Login dulu menggunakan nomor HP yang aktif</li>
                    <li>Jika sudah login, jangan lupa untuk isi saldo SpeedCash dulu</li>
                    <li>
                      Jika sudah mengisi saldo SpeedCash, kamu bisa langsung masuk ke menu E-Money
                    </li>
                    <li>Lalu isikan no hp kamu yang terdaftar di aplikasi dana</li>
                    <li>Dan pilih nominal saldo yang ingin kamu top up di dana</li>
                    <li>Jika sudah kamu klik lanjutkan</li>
                  </ol>
                </Row>
              </Row>
            </div>
          </Col>

          <Col lg={8} xs={12} md={8} className="accordion-bpjs">
            <Accordion className="m-0 p-0">
              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="1"
                    className="button-collapse w-100 text-left"
                  >
                    Top Up Dana Dengan Aplikasi SpeedCash
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="1">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Selanjutnya jika kamu ingin top up dana dengan aplikasi SpeedCash kamu bisa
                        mengikuti cara berikut ini:
                      </p>
                      <Row className="mt-3">
                        <ul className="text-justify list-content">
                          <li>
                            Download dulu aplikasi SpeedCash yang ada di Playstore maupun Appstore
                          </li>
                          <li>
                            Jika sudah download jangan lupa untuk login dulu menggunakan nomor hp ya
                          </li>
                          <li>Jika sudah login, langsung klik menu transfer e-wallet</li>
                          <li>
                            <p>Pilih menu SALDO DANA</p>
                            <Row className="d-flex justify-content-center">
                              <Image
                                src="/img/mockup-top-up-emoney.png"
                                data-target="topup-emoney"
                                width="220px"
                                height="400px"
                              />
                            </Row>
                          </li>
                          <li>Masukkan nomor HP yang aktif di aplikasi Dana</li>
                          <li>
                            <p>Masukkan Nominal top up dana yang ingin kamu masukkan</p>
                            <Row className="d-flex justify-content-center">
                              <Image
                                src="/img/mockup-top-up-dana.png"
                                data-target="top-up-dana"
                                width="220px"
                                height="400px"
                              />
                            </Row>
                          </li>
                          <li>Atau bisa juga kamu Top Up Dana dengan nominal bebas</li>
                          <li>Jika sudah kamu bisa langsung klik Lanjutkan</li>
                          <li>Dan ikuti instruksi selanjutnya ya</li>
                        </ul>
                      </Row>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="2"
                    className="button-collapse w-100 text-left"
                  >
                    Cara Mengisi Saldo SpeedCash
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="2">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Sebelum memulai untuk mengisi saldo di aplikasi dana menggunaka SpeedCash
                        maka kamu wajib memastikan kalau aplikasi SpeedCashmu sudah memiliki saldo
                        yang cukup,nah bagi kamu yang masih merasa kebingungan untuk mengisi saldo
                        SpeedCash, kamu bisa simak video berikut ini.
                      </p>
                      <iframe
                        width="100%"
                        height="480"
                        src="https://www.youtube.com/embed/sI1fLYglLwk"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        frameBorder={0}
                        title="Cara Isi Saldo Di SpeedCash"
                        className="mb-2"
                      />
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="3"
                    className="button-collapse w-100 text-left"
                  >
                    Mengapa Harus Top Up Dana Di SpeedCash
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="3">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Ternyata menggunakan SpeedCash tidak hanya bisa untuk top up saldo dana
                        tetapi juga bisa untuk digunakan layanan tagihan bulanan lainnya seperti,
                        <a href="https://member.speedcash.co.id/pln/cek-tagihan-listrik">
                          cek tagihan listrik
                        </a>
                        ,{' '}
                        <a href="https://member.speedcash.co.id/cek-bpjs-kesehatan">
                          cek BPJS kesehatan
                        </a>
                        , <a href="https://member.speedcash.co.id/telkom">Bayar tagihan indihome</a>{' '}
                        dan tentunya masih banyak lainnya.
                      </p>
                      <p className="d-block text-justify mt-1">
                        Kemudahan menggunakan SpeedCash untuk top up dana ternyata bukan cuma itu
                        saja, tetapi ada alasan lain yang wajib kamu ketahui mengapa harus
                        menggunakan SpeedCash untuk top up dana :
                      </p>
                      <p className="d-block text-justify mt-1">
                        <b>Kemudahan dan Kecepatan :</b> SpeedCash menyediakan layanan yang cepat
                        dan mudah dalam proses top up saldo dana. Anda tidak perlu repot mencari
                        tempat atau metode lain untuk melakukan top up, karena SpeedCash biasanya
                        tersedia di banyak tempat yang nyaman dan mudah diakses.
                      </p>
                      <p className="d-block text-justify mt-1">
                        <b>Keamanan :</b> SpeedCash menawarkan sistem transaksi yang aman, yang
                        dapat memberikan rasa percaya kepada pengguna bahwa transaksi mereka
                        dilakukan dengan aman dan terlindungi dari ancaman keamanan
                      </p>
                      <p className="d-block text-justify mt-1">
                        <b>Fleksibilitas :</b> Dengan menggunakan SpeedCash, Anda dapat melakukan
                        top up saldo dana kapan saja dan di mana saja sesuai dengan kebutuhan Anda.
                        Ini memberikan fleksibilitas yang lebih besar dibandingkan dengan beberapa
                        metode lain yang mungkin memerlukan akses internet atau alat pembayaran
                        khusus.
                      </p>
                      <p className="d-block text-justify mt-1">
                        <b>Tidak Bergantung Bank :</b> Beberapa orang mungkin tidak memiliki akses
                        ke kartu bank atau tidak nyaman menggunakan informasi kartu mereka secara
                        online. Dengan SpeedCash, Anda tidak perlu kartu bank untuk melakukan
                        transaksi, karena cukup menggunakan uang tunai.{' '}
                      </p>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="4"
                    className="button-collapse w-100 text-left"
                  >
                    Kendala Top Up Dana Di SpeedCash
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="4">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Saat kamu sedang mengisi saldo dana melalui aplikasi SpeedCash bukan tidak
                        mungkin kamu akan mengalami kendala.untuk mengatasi hal tersebut SpeedCash
                        sudah menyiapkan live chat yang siap stand by 24 jam selama 7 hari. Namun
                        tidak hanya itu jika kamu mengalami kendala lainnya kamu bisa menghubungi cs
                        kami melalui email di :
                        <a href="mailto:support@speedcash.co.id"> support@speedcash.co.id</a>.
                      </p>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="5"
                    className="button-collapse w-100 text-left"
                  >
                    Sekilas Tentang Aplikasi Dana
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="5">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Dana merupakan salah satu metode pembayaran online yang biasa digunakan
                        masyarakat Indonesia untuk melakukan transaksi e-commerce atau offline.
                        Kehadiran Dana memberikan banyak dampak positif bagi masyarakat, terutama
                        bagi mereka yang rutin meluangkan waktu untuk berbelanja atau jalan-jalan.
                      </p>
                      <p className="d-block text-justify mt-1">
                        Selain sebagai halaman belanja waktu, Dana juga bisa Anda gunakan untuk
                        membayar makan. dan masih banyak produk menarik lainnya. Keunggulan Dana
                        dibandingkan dompet digital lainnya adalah promo menarik yang berbeda-beda
                        setiap harinya.
                      </p>
                      <p className="d-block text-justify mt-1">
                        Dana memiliki banyak promo baru dan menarik seperti diskon atau cashback
                        setiap pengguna melakukan pembayaran di partner Dana. Anda kemudian dapat
                        menggunakan cash back atau diskon tersebut untuk berbelanja di mitra
                        e-commerce Dana. Jadi, nominal pembelian Anda menjadi lebih murah
                        dibandingkan harga sebenarnya.
                      </p>
                      <p className="d-block text-justify mt-1">
                        Semua promosi dan nominal menarik hanya dapat dinikmati oleh pelanggan yang
                        berpartisipasi dalam transaksi. Soalnya transaksi Dana hanya bisa dilakukan
                        setelah mengisi saldo akun Anda dengan dana yang cukup sebesar jumlah
                        pembelian
                      </p>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="6"
                    className="button-collapse w-100 text-left"
                  >
                    Cara Top Up Dana Via Minimarket
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="6">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Beberapa tahap pengisian saldo Dana yang paling populer di kalangan
                        pelanggan adalah transfer via mini market. Proses dan mekanismenya mudah
                        karena baik Alfamart, Alfamidi maupun Indomaret tersedia di mana-mana.
                        Tertarik isi saldo Dana di mini market? Ikuti petunjuknya berikut ini:
                      </p>
                      <ol className="text-justify list-content">
                        <li>
                          Pilih salah satu mini market terdekat dari kediaman Anda entah itu
                          Indomaret, Alfamidi maupun Alfamart
                        </li>
                        <li>
                          Cari kasir yang bertugas dan sampaikan maksud kedatangan Anda untuk top up
                          saldo Dana
                        </li>
                        <li>Sampaikan dengan jelas dan hati-hati nomor ponsel Dana</li>
                        <li>
                          Sampaikan pula nominal top up saldo dana. Pastikan Anda isi sama dengan
                          atau lebih dari Rp 50.000
                        </li>
                      </ol>
                      <p className="d-block text-justify mt-1">
                        Biarkan kasir mengisi saldo dana setelah itu cek akun Anda untuk melihat
                        status saldo terbaru. Jangan lupa simpan bukti atau setruk belanja yang
                        diberikan oleh kasir.
                      </p>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="7"
                    className="button-collapse w-100 text-left"
                  >
                    Top Up Dana via ATM BCA
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="7">
                  <Card.Body className="card-content">
                    <Row>
                      <ol className="text-justify list-content">
                        <li>Masukkan kartu ATM BCA ke dalam mesin ATM dan masukkan PIN Anda.</li>
                        <li>Pilih opsi &ldquo;Transaksi Lainnya&rdquo; dari menu utama.</li>
                        <li>
                          Pilih &ldquo;Menu Lainnya&rdquo; untuk melihat opsi transaksi tambahan.
                        </li>
                        <li>Pilih &ldquo;Isi Ulang E-Money / Dompet Elektronik&rdquo;.</li>
                        <li>
                          Pilih e-wallet yang ingin Anda isi ulang, seperti OVO, GoPay, atau DANA.
                        </li>
                        <li>
                          Masukkan nomor telepon yang terdaftar pada akun e-wallet yang ingin Anda
                          isi ulang.
                        </li>
                        <li>
                          Masukkan jumlah saldo yang ingin Anda tambahkan ke akun e-wallet tersebut.
                        </li>
                        <li>Konfirmasikan transaksi Anda dan tunggu hingga proses selesai.</li>
                        <li>
                          ATM akan mencetak struk sebagai bukti transaksi Anda. Pastikan untuk
                          mengambil struk tersebut sebelum meninggalkan mesin ATM.
                        </li>
                      </ol>
                      <p className="d-block text-justify mt-1">
                        Dengan mengikuti langkah-langkah tersebut, Anda dapat dengan mudah melakukan
                        top up saldo dana melalui ATM BCA untuk berbagai{' '}
                        <a href="https://www.speedcash.co.id/">e-wallet</a> yang didukung. Pastikan
                        untuk memasukkan informasi dengan benar dan amankan kartu ATM serta struk
                        transaksi Anda setelah selesai.
                      </p>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="8"
                    className="button-collapse w-100 text-left"
                  >
                    Top Up Dana Via M-Banking BCA
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="8">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Untuk melakukan top up saldo dana melalui m-banking BCA, ikuti
                        langkah-langkah berikut:
                      </p>
                      <ol className="text-justify list-content">
                        <li>
                          Masuk ke Aplikasi M-Banking BCA: Buka aplikasi m-banking BCA di ponsel
                          pintar Anda.
                        </li>
                        <li>
                          Masuk dan Otentikasi: Masukkan informasi login yang sesuai, seperti nomor
                          rekening dan kata sandi m-banking Anda, untuk masuk ke akun Anda.
                        </li>
                        <li>
                          Pilih Menu &ldquo;Transfer&rdquo;: Setelah masuk, cari dan pilih menu yang
                          berkaitan dengan &ldquo;Transfer&rdquo; di layar utama aplikasi.
                        </li>
                        <li>
                          Pilih Tujuan Transfer: Pilih opsi transfer ke &ldquo;Rekening BCA&rdquo;
                          atau &ldquo;Rekening Bank Lain&rdquo; tergantung pada akun e-wallet Anda.
                        </li>
                        <li>
                          Masukkan Nomor Rekening: Masukkan nomor rekening tujuan atau pilih dari
                          daftar kontak jika nomor rekening e-wallet Anda sudah tersimpan
                          sebelumnya.
                        </li>
                        <li>
                          Masukkan Nominal: Tentukan jumlah saldo dana yang ingin Anda top up ke
                          akun e-wallet Anda.
                        </li>
                        <li>
                          Konfirmasi Transaksi: Setelah memasukkan nominal, aplikasi m-banking akan
                          menampilkan ringkasan transaksi. Pastikan semua informasi sudah benar,
                          lalu lanjutkan untuk melakukan konfirmasi transaksi.
                        </li>
                        <li>
                          Otentikasi Transaksi: Untuk keamanan, Anda mungkin perlu melakukan
                          otentikasi tambahan, seperti memasukkan kode OTP (One Time Password) yang
                          dikirimkan ke nomor ponsel Anda atau menggunakan fitur otentikasi lain
                          yang disediakan oleh aplikasi.
                        </li>
                        <li>
                          Tunggu Konfirmasi: Setelah transaksi berhasil, tunggu konfirmasi dari
                          aplikasi m-banking BCA yang menandakan bahwa top up saldo dana telah
                          berhasil dilakukan.
                        </li>
                      </ol>
                      <p className="d-block text-justify mt-1">
                        Dengan mengikuti langkah-langkah di atas, Anda dapat dengan mudah melakukan
                        top up saldo dana ke akun e-wallet Anda melalui layanan m-banking BCA dengan
                        cepat dan aman. Pastikan untuk memasukkan informasi dengan benar dan amankan
                        informasi akun Anda untuk keamanan yang lebih baik.
                      </p>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="9"
                    className="button-collapse w-100 text-left"
                  >
                    Kantor Pusat Dana Di Indonesia
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="9">
                  <Card.Body className="card-content">
                    <Row>
                      <div className="d-block text-justify w-100 mb-1">
                        <iframe
                          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15864.952963038786!2d106.8201592!3d-6.2322925!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69f3c3ee0a9cc1%3A0xde98c8644a41700c!2sCapital%20Place!5e0!3m2!1sid!2sid!4v1711441623676!5m2!1sid!2sid"
                          width="100%"
                          height="450"
                          style={{ border: '0' }}
                          allowfullscreen=""
                          loading="lazy"
                          referrerPolicy="no-referrer-when-downgrade"
                          title="Kantor Pusat Dana"
                        />
                      </div>
                      <p className="d-block text-justify mt-1">
                        <i> Alamat</i> : Jl. Gatot Subroto No.18, RT.6/RW.1, Kuningan Bar., Kec.
                        Mampang Prpt., Kota Jakarta Selatan, Daerah Khusus Ibukota Jakarta 12710
                      </p>
                      <p className="d-block text-justify mt-1">
                        Jumlah bank lokal Indonesia yang bekerjasama dengan Dana sangat banyak,
                        tidak hanya satu. Demi keamanan dan kenyamanan Anda, silakan kunjungi lebih
                        lanjut website resmi Dana untuk penjelasan detailnya. Untuk apa? Karena
                        setiap bank mungkin memiliki proses berbeda dalam menambahkan uang ke saldo.
                      </p>
                      <p className="d-block text-justify mt-1">
                        Selama beberapa tahun terakhir, Dana telah berhasil memantapkan dirinya
                        sebagai salah satu dompet digital paling populer di industri air. Jumlah
                        penggunanya sangat banyak dan hadir di seluruh Indonesia. Kemudahan Dana dan
                        promo menarik menjadi salah satu alasan mengapa aplikasi ini begitu populer.
                        Dana hadir dengan mengedepankan teknologi informasi modern yang terus
                        ditingkatkan agar tidak tertinggal dari kompetitor. Selain itu, kehadiran
                        dompet digital lainnya seperti SpeedCash, OVO,dan ShopeePay semakin
                        meningkat.
                      </p>
                      <p className="d-block text-justify mt-1">
                        Semoga informasi sederhana yang kami berikan dapat membantu Anda yang ingin
                        bertransaksi dengan mudah menggunakan dana digital yang aman dan terpercaya.
                      </p>
                      <div className="mt-1 w-100 d-flex justify-content-center">
                        <h5 className="bold">
                          Top Up Dana Jadi Lebih Mudah Via Aplikasi SpeedCash.
                        </h5>
                      </div>
                      <Row className="d-flex w-100 justify-content-center mb-3 gap-3">
                        <a
                          aria-hidden
                          href="https://play.google.com/store/apps/details?id=com.bm.sc.bebasbayar"
                          className="mx-1"
                        >
                          <Image
                            src="/img/playstore-speedcash.png"
                            data-target="playstore-speedcash"
                            width="220px"
                            height="60px"
                          />
                        </a>
                        <a
                          aria-hidden
                          href="https://apps.apple.com/id/app/speedcash-transfer-ewallet/id6459304122"
                          className="mx-1"
                        >
                          <Image
                            src="/img/appstore-speedcash.png"
                            data-target="appstore-speedcash"
                            width="220px"
                            height="60px"
                          />
                        </a>
                      </Row>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default AccordionEmoneyDana
