import BaseMessage from '../base'

export default class ChangePinMessage extends BaseMessage {
  constructor(data) {
    super(data)

    this.setPath(`${ChangePinMessage.SC_API}/otp/registration`)

    if (!data) {
      this.data.appid = 'SPEEDCASH'
      this.data.location = '0,0'
      this.data.campaign = {
        utm_term: '',
        utm_user_id: '',
        utm_campaign: '',
        utm_name: '',
        utm_medium: '',
        utm_content: '',
        utm_source: '',
      }
    }
  }

  // eslint-disable-next-line class-methods-use-this
  setPhone(phone) {
    this.data.no_hp = phone
  }

  setOtp(otp) {
    this.data.otp = otp
  }

  setPIN(pin) {
    this.data.pin = pin
  }

  setUpline(refId) {
    this.data.upline = refId
  }

  setName(name) {
    this.data.nama = name
  }

  setEmail(email) {
    this.data.email = email
  }
}
