import React from 'react'
import { Switch } from 'react-router-dom'

import HomeScreen from '../screen/page/home'
import HomeScreenEmbed from '../screen/page/homeEmbed'
import RedirectScreen from '../screen/page/redirect'
import DepositScreen from '../screen/page/deposit'
import AsuransiScreen from '../screen/page/layanan/asuransi'
import AsuransiBpjsTkScreen from '../screen/page/layanan/asuransiBpjsTk'
import BpjsScreen from '../screen/page/layanan/bpjs'
import BpjsRedirectScreen from '../screen/page/layanan/bpjsRedirect'
import EmoneyScreen from '../screen/page/layanan/emoney'
import EmoneyDanaScreen from '../screen/page/layanan/emoneyDana'
import EmoneyShopeeScreen from '../screen/page/layanan/emoneyShopee'
import EcommerceScreen from '../screen/page/transfer/ecommerce'
import FinanceScreen from '../screen/page/layanan/finance'
import FinanceFifScreen from '../screen/page/layanan/financeFif'
import GameScreen from '../screen/page/layanan/games'
import GameFfScreen from '../screen/page/layanan/gameFf'
import GameMlScreen from '../screen/page/layanan/gameMl'
import GamePubgScreen from '../screen/page/layanan/gamePubg'
import GameUnipinScreen from '../screen/page/layanan/gameUnipin'
import GameNetflixScreen from '../screen/page/layanan/gameNetflix'
import GasScreen from '../screen/page/layanan/gas'
import KartuKreditScreen from '../screen/page/layanan/kartukredit'
import KartuKreditBniScreen from '../screen/page/layanan/kartukreditBni'
import PascabayarScreen from '../screen/page/layanan/pascabayar'
import PascabayarTelkomselScreen from '../screen/page/layanan/pascabayarTelkomsel'
import PascabayarIndosatScreen from '../screen/page/layanan/pascabayarIndosat'
import PbbScreen from '../screen/page/layanan/pbb'
import PdamScreen from '../screen/page/layanan/pdam'
import PenerimaanNegaraScreen from '../screen/page/layanan/djkm'
import PkbScreen from '../screen/page/layanan/pkb'
import PkbJatimScreen from '../screen/page/layanan/pkbJatim'
import PkbRedirectScreen from '../screen/page/layanan/pkbRedirect'
import PlnPascaScreen from '../screen/page/layanan/plnPasca'
import PlnPraScreen from '../screen/page/layanan/plnPra'
import PlnNonScreen from '../screen/page/layanan/plnNon'
import PlnRedirectScreen from '../screen/page/layanan/plnRedirect'
import PulsaScreen from '../screen/page/layanan/pulsa'
import PulsaRedirectScreen from '../screen/page/layanan/pulsaRedirect'
import TelkomScreen from '../screen/page/layanan/telkom'
import TvScreen from '../screen/page/layanan/tv'
import StrukScreen from '../screen/page/layanan/struk'
import ZakatScreen from '../screen/page/layanan/zakat'
import LaporanTransaksiScreen from '../screen/page/laporan/laporan_transaksi'
import LaporanMutasiScreen from '../screen/page/laporan/laporan_mutasi'
import RapotFmScreen from '../screen/page/fm/rapot_fm'
import KomisiFmScreen from '../screen/page/fm/komisi_fm'
import CompanionFmScreen from '../screen/page/fm/companion_fm'
import FriendFmScreen from '../screen/page/fm/teman_fm'
import PencairanFmScreen from '../screen/page/fm/pencairan_fm'
import TransferBankScreen from '../screen/page/transfer/bank'
import TransferSaldoScreen from '../screen/page/transfer/saldo'
import TransferSpesialScreen from '../screen/page/transfer/spesial'
import NotFoundPage from '../screen/page/error/NotFoundPage'
import Route from './wrapper'

export default function Routes() {
  const contactUs = React.useCallback(async () => {
    window.location.href = process.env.REACT_APP_URL_LANDING
  }, [])

  const syaratKetentuan = React.useCallback(async () => {
    window.location.href = process.env.REACT_APP_URL_SK
  }, [])

  const kebijakanPrivasi = React.useCallback(async () => {
    window.location.href = process.env.REACT_APP_URL_PP
  }, [])

  const host = `${window.location.protocol}//${window.location.host}`

  if (host === 'https://dashboard.bebasbayar.com') {
    return (
      <Switch>
        <Route exact path="/" component={RedirectScreen} />
        <Route path="/home" component={RedirectScreen} />
        <Route path="/embed" component={RedirectScreen} />
        <Route path="/register" component={RedirectScreen} />
        <Route path="/deposit" component={RedirectScreen} />
        <Route path="/asuransi" component={RedirectScreen} />
        <Route path="/bpjs" component={RedirectScreen} />
        <Route path="/ecommerce" component={RedirectScreen} />
        <Route path="/emoney" component={RedirectScreen} />
        <Route path="/finance" component={RedirectScreen} />
        <Route path="/game" component={RedirectScreen} />
        <Route path="/gas" component={RedirectScreen} />
        <Route path="/kartukredit" component={RedirectScreen} />
        <Route path="/pages/vouchergame" component={RedirectScreen} />
        <Route path="/pascabayar" component={RedirectScreen} />
        <Route path="/pbb" component={RedirectScreen} />
        <Route path="/pdam" component={RedirectScreen} />
        <Route path="/penerimaan-negara" component={RedirectScreen} />
        <Route path="/pkb" component={RedirectScreen} />
        <Route path="/pln" component={RedirectScreen} />
        <Route path="/pulsa" component={RedirectScreen} />
        <Route path="/telkom" component={RedirectScreen} />
        <Route path="/tv" component={RedirectScreen} />
        <Route path="/struk" component={RedirectScreen} />
        <Route path="/zakat" component={RedirectScreen} />
        <Route path="/report/transaction" component={RedirectScreen} />
        <Route path="/report/mutation" component={RedirectScreen} />
        <Route path="/report/marketer" component={RedirectScreen} />
        <Route path="/transfer/bank" component={RedirectScreen} />
        <Route path="/transfer/saldo" component={RedirectScreen} />
        <Route path="/transfer/spesial" component={RedirectScreen} />
        <Route path="/contact-us" component={contactUs} />
        <Route path="/syarat-dan-ketentuan" component={syaratKetentuan} />
        <Route path="/kebijakan-privasi" component={kebijakanPrivasi} />
      </Switch>
    )
  }

  return (
    <Switch>
      <Route exact path="/" component={HomeScreen} />
      <Route path="/home" component={HomeScreen} />
      <Route path="/embed" component={HomeScreenEmbed} />
      <Route path="/register" component={HomeScreen} />
      <Route path="/deposit" component={DepositScreen} />
      <Route path="/asuransi/bpjs-ketenagakerjaan" component={AsuransiBpjsTkScreen} />
      <Route path="/asuransi" component={AsuransiScreen} />
      <Route path="/cek-bpjs-kesehatan" component={BpjsScreen} />
      <Route path="/bpjs" component={BpjsRedirectScreen} />
      <Route path="/ecommerce" component={EcommerceScreen} />
      <Route path="/emoney/top-up-dana" component={EmoneyDanaScreen} />
      <Route path="/emoney/top-up-shopeepay" component={EmoneyShopeeScreen} />
      <Route path="/emoney" component={EmoneyScreen} />
      <Route path="/finance/angsuran-fif" component={FinanceFifScreen} />
      <Route path="/finance" component={FinanceScreen} />
      <Route path="/game/top-up-ff" component={GameFfScreen} />
      <Route path="/game/top-up-ml" component={GameMlScreen} />
      <Route path="/game/top-up-uc-pubg" component={GamePubgScreen} />
      <Route path="/game/top-up-unipin" component={GameUnipinScreen} />
      <Route path="/game/langganan-netflix" component={GameNetflixScreen} />
      <Route path="/game" component={GameScreen} />
      <Route path="/gas" component={GasScreen} />
      <Route path="/kartukredit/bni" component={KartuKreditBniScreen} />
      <Route path="/kartukredit" component={KartuKreditScreen} />
      <Route path="/pages/vouchergame" component={GameScreen} />
      <Route path="/pascabayar/telkomsel-halo" component={PascabayarTelkomselScreen} />
      <Route path="/pascabayar/indosat" component={PascabayarIndosatScreen} />
      <Route path="/pascabayar" component={PascabayarScreen} />
      <Route path="/pbb" component={PbbScreen} />
      <Route path="/pdam" component={PdamScreen} />
      <Route path="/penerimaan-negara" component={PenerimaanNegaraScreen} />
      <Route path="/e-samsat/cek-pajak-kendaraan-jatim" component={PkbJatimScreen} />
      <Route path="/e-samsat" component={PkbScreen} />
      <Route path="/pkb" component={PkbRedirectScreen} />
      <Route path="/pln/cek-tagihan-listrik" component={PlnPascaScreen} />
      <Route path="/pln/token-listrik" component={PlnPraScreen} />
      <Route path="/pln/non-taglis" component={PlnNonScreen} />
      <Route path="/pln" component={PlnRedirectScreen} />
      <Route path="/isi-pulsa-termurah" component={PulsaScreen} />
      <Route path="/pulsa" component={PulsaRedirectScreen} />
      <Route path="/telkom" component={TelkomScreen} />
      <Route path="/tv" component={TvScreen} />
      <Route path="/struk" component={StrukScreen} />
      <Route path="/zakat" component={ZakatScreen} />
      <Route path="/report/transaction" component={LaporanTransaksiScreen} />
      <Route path="/report/mutation" component={LaporanMutasiScreen} />
      <Route path="/fm/report" marketer="true" component={RapotFmScreen} />
      <Route path="/fm/commission" marketer="true" component={KomisiFmScreen} />
      <Route path="/fm/companion" marketer="true" component={CompanionFmScreen} />
      <Route path="/fm/friend" marketer="true" component={FriendFmScreen} />
      <Route path="/fm/disbursement" marketer="true" component={PencairanFmScreen} />
      <Route path="/transfer/bank" component={TransferBankScreen} />
      <Route path="/transfer/saldo" component={TransferSaldoScreen} />
      <Route path="/transfer/spesial" component={TransferSpesialScreen} />
      <Route path="/contact-us" component={contactUs} />
      <Route path="/syarat-dan-ketentuan" component={syaratKetentuan} />
      <Route path="/kebijakan-privasi" component={kebijakanPrivasi} />
      <Route path="*" component={NotFoundPage} status={404} />
    </Switch>
  )
}
