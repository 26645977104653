import React from 'react'
import { Modal, Image, InputGroup, FormControl, ListGroup, Row, Col } from 'react-bootstrap'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import HookHelper from '../../../helper/hookhelper'
import ImgPath from '../../../helper/imgpath'

import '../dialog.scss'

const AsuransiListDialog = ({
  title,
  group,
  onHide,
  onConfirm,
  show,
  onSearchChange,
  search,
  data,
}) => {
  React.useEffect(() => {
    HookHelper.resetLoading()
  }, [])

  return (
    <Modal
      show={show}
      onHide={onHide}
      backdrop="static"
      keyboard={false}
      centered
      contentClassName="radius"
      animation={false}
    >
      <Modal.Header className="header">
        <div style={{ width: '95%' }}>
          <Modal.Title className="title">Pilih Produk {title}</Modal.Title>
        </div>
        <div className="close-modal">
          <Image
            src="/icon/clear.png"
            onClick={onHide}
            roundedCircle
            className="justify-content-end"
          />
        </div>
      </Modal.Header>

      <Modal.Body>
        <InputGroup className="mb-3">
          <FormControl placeholder="Cari.." value={search} onChange={onSearchChange} />
          <InputGroup.Append style={{ margin: 'auto' }}>
            <FontAwesomeIcon icon="search" fixedWidth className="mr-3" />
          </InputGroup.Append>
        </InputGroup>
        <ListGroup variant="flush">
          {data.map((item, index) => (
            <React.Fragment key={item.id_produk}>
              {(index === 0 || item.grouplabel !== data[index - 1].grouplabel) && (
                <ListGroup.Item variant="dark">{item.grouplabel}</ListGroup.Item>
              )}
              <ListGroup.Item
                data-target={item.id_produk}
                data-label={item.produk}
                onClick={onConfirm}
                style={{ cursor: 'pointer' }}
              >
                <Row>
                  <Col lg={2} xs={2} md={2} style={{ margin: 'auto' }}>
                    <Image
                      rounded
                      src={`/icon/${group}/${ImgPath.produk(group, item.id_produk)}.png`}
                      onError={(e) => {
                        e.target.src = `/icon/default/${group}.png`
                      }}
                      className="justify-content-end"
                      width={36}
                    />
                  </Col>
                  <Col lg={9} xs={8} md={9}>
                    <Row>
                      <span className="medium">{item.produk}</span>
                    </Row>
                    {/* {item.produk_komisi[0].dsetting.diskon &&
                      item.produk_komisi[0].dsetting.diskon !== 'Rp 0,-' && (
                        <Row>
                          <span className="text-12 text-italic text-bb-primary">Diskon:</span>
                          <span>&nbsp;</span>
                          <span className="text-12 text-bold text-bb-primary">
                            {item.produk_komisi[0].dsetting.diskon}
                          </span>
                        </Row>
                      )} */}
                  </Col>
                  <Col lg={1} xs={2} md={1} style={{ margin: 'auto' }}>
                    <FontAwesomeIcon icon="chevron-right" fixedWidth className="mr-3" />
                  </Col>
                </Row>
              </ListGroup.Item>
            </React.Fragment>
          ))}
        </ListGroup>
      </Modal.Body>
    </Modal>
  )
}

AsuransiListDialog.defaultProps = {
  title: '',
  group: '',
  onHide: null,
  onConfirm: null,
  show: false,
  data: [],
  search: '',
  onSearchChange: null,
}

AsuransiListDialog.propTypes = {
  title: PropTypes.string,
  group: PropTypes.string,
  onHide: PropTypes.func,
  onConfirm: PropTypes.func,
  show: PropTypes.bool,
  search: PropTypes.string,
  onSearchChange: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.array,
}

export default AsuransiListDialog
