import React from 'react'
import ReactGA from 'react-ga'
import { Helmet } from 'react-helmet'
import { Router } from 'react-router-dom'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import store, { persistor } from './store'
import history from './navigator/history'
import Routes from './navigator/routes'
import './App.scss'
import './helper/icon'
import Livechat from './screen/component/livechat'

function App() {
  history.listen((location) => {
    ReactGA.set({ page: location.pathname })
    ReactGA.pageview(location.pathname)
  })

  React.useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search)
  }, [])

  return (
    <>
      <Helmet>
        <title>Login SpeedCash melalui web</title>
        <meta
          name="description"
          content="Login SpeedCash melalui web. Nikmati layanan transfer bank special, pembayaran e-commerce, diskon pembayaran PLN, BPJS, PDAM dll."
        />
      </Helmet>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Router history={history}>
            <Routes />
          </Router>
          <Livechat />
        </PersistGate>
      </Provider>
    </>
  )
}

export default App
