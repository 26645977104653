import MessageSender from '../../sender'
import ChangePinMessage from '../../message/api/changepin'
import authConstant from '../../../store/constant/auth'

export default (phone, pin, otp) => async (dispatch) => {
  const msg = new ChangePinMessage()
  msg.setPhone(phone)
  msg.setPIN(pin)
  msg.setOtp(otp)

  const response = await new MessageSender().doPost(msg)

  const msg2 = new ChangePinMessage(response)
  if (msg2.isOK()) {
    await dispatch({
      type: authConstant.USER_ACCESS_TOKEN,
      payload: '',
    })
  }

  return msg2
}
