const ImgPath = {
  produk: (groupProduk, produkId) => {
    if (groupProduk === 'kk') {
      return produkId
    }
    switch (true) {
      case produkId.toUpperCase().includes('TVGEN'):
        return 'gen'
      case produkId.toUpperCase().includes('TVKV'):
        return 'kv'
      case produkId.toUpperCase().includes('TVORG'):
        return 'orange'
      case produkId.toUpperCase().includes('TVSKYFAM'):
        return 'skyfam'
      case produkId.toUpperCase().includes('TVINDVS'):
        return 'indvs'
      case produkId.toUpperCase().includes('TVNEX'):
        return 'nex'
      case produkId.toUpperCase().includes('TVTLKMV'):
        return 'tlkmv'
      case produkId.toUpperCase().includes('TVTOPAS'):
        return 'topas'
      case produkId.toUpperCase().includes('TVBIG'):
        return 'big'
      case produkId.toUpperCase().includes('FNBAF'):
        return 'baf'
      case produkId.toUpperCase().includes('FNFIF'):
        return 'fif'
      case produkId.toUpperCase().includes('FNMAF'):
        return 'maf'
      case produkId.toUpperCase().includes('FNMEGA'):
        return 'mega'
      case produkId.toUpperCase().includes('FNWOM'):
        return 'wom'
      case produkId.toUpperCase().includes('HPESIA'):
        return 'esia'
      case produkId.toUpperCase().includes('HPFREN'):
        return 'fren'
      case produkId.toUpperCase().includes('HPMTRIX'):
        return 'matrix'
      case produkId.toUpperCase().includes('HPSMART'):
        return 'smartfren'
      case produkId.toUpperCase().includes('HPTHREE'):
        return 'three'
      case produkId.toUpperCase().includes('HPTSEL'):
        return 'halo'
      case produkId.toUpperCase().includes('HPXL'):
        return 'xplor'
      case produkId.toUpperCase().includes('ALLIANZ'):
        return 'allianz'
      case produkId.toUpperCase().includes('ASAAB'):
        return 'astra_buana'
      case produkId.toUpperCase().includes('ASRAJS'):
        return 'sequis_life'
      case produkId.toUpperCase().includes('ASRCAR'):
        return 'car'
      case produkId.toUpperCase().includes('ASRCMNW'):
        return 'commonwealth'
      case produkId.toUpperCase().includes('ASRPRU'):
      case produkId.toUpperCase().includes('PRUDENT'):
        return 'prudential'
      case produkId.toUpperCase().includes('ASRTOKIO'):
      case produkId.toUpperCase().includes('ASRTOKIOS'):
        return 'tokio'
      case produkId.toUpperCase().includes('ASRTPENJLN'):
        return 'taspen_life'
      case produkId.toUpperCase().includes('ASRBPJSTK'):
        return 'bpjstk'
      case produkId.toUpperCase().includes('ASRJWS'):
        return 'jiwasraya'
      default:
        return produkId
    }
  },
}

export default ImgPath
