import MessageSender from '../../sender'
import LoginMessagePin from '../../message/api/loginpin'
import authConstant from '../../../store/constant/auth'
import globalConstant from '../../../store/constant/global'
import BaseMessage from '../../message/base'
import MessageSenderBrowser from '../../senderBrowser'

export default (phone, pin, otp = '', productCode, type = '', state = '', isEmbed = false) =>
  async (dispatch) => {
    const msg = new LoginMessagePin()
    msg.setOutletID(phone)
    msg.setPIN(pin)
    if (productCode !== 'SIGNON') {
      msg.setIncludeValue(LoginMessagePin.INCLUDES.PRODUCT_CODE, productCode)
      msg.setAdditionalDatum(LoginMessagePin.ADD_OTP_CODE, otp)
      msg.setToken(otp)
    }
    if (type !== '' && state !== '') {
      msg.setType(type)
      msg.setState(state)
      msg.setLoginV2Motp()
    }

    const response = await new MessageSender().doPost(msg)
    const msg2 = new LoginMessagePin(response)
    if (msg2.isOK()) {
      await dispatch({
        type: authConstant.USER,
        payload: {
          [authConstant.USER_ID]: msg2.getOutletID(),
          [authConstant.USER_PIN]: msg2.getUserPin(),
          [authConstant.USER_TOKEN]: msg2.getToken(),
          [authConstant.USER_PHONE]: phone,
          [authConstant.USER_IN_ACCESS_TOKEN]: msg2.getAccessToken(),
          [authConstant.USER_BALANCE]: msg2.getBalance(),
          [authConstant.USER_CAMPAIGN]: msg2.getCampaigns(),
          [authConstant.USER_IS_EMBED]: isEmbed,
        },
      })

      // Generate New CSRF Token On Login
      const msgCsrf = new BaseMessage()
      msgCsrf.setPath('/csrf')
      const responseCsrf = await new MessageSenderBrowser().doGet(msgCsrf)
      const msgResponseCsrf = new BaseMessage(responseCsrf)
      if (msgResponseCsrf.isOK()) {
        const token = msgResponseCsrf.getItem('csrf_token')
        await dispatch({
          type: globalConstant.CSRF_TOKEN,
          payload: token,
        })
      }
    }

    return msg2
  }
