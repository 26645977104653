import { isMobile, browserName, mobileModel } from 'react-device-detect'

import MPMessage from '../mp'
import Var from '../../../helper/var'

export default class LoginMessagePin extends MPMessage {
  static ADD_GREETING_MENU = 0

  static ADD_GREETING_TITLE = 1

  static ADD_GREETING_BODY = 2

  static ADD_ACTION = 3

  static ADD_DEVICE_ID = 4

  static ADD_OTP_CODE = 5

  static ADD_PLATFORM = 6

  static ADD_DEVICE_NAME = 7

  static ADD_USER_AGENT = 8

  static ADD_GEO_LOCATION = 9

  static ADD_SOSMED_PROVIDER = 10

  static ADD_VERSION_CODE = 11

  constructor(data) {
    super(data)

    this.setPath(`${LoginMessagePin.SC_API}/login`)

    if (!data) {
      this.setMessageType('NET')
      this.setProcessingCode('SIGNON')
      this.setIncludeValue(LoginMessagePin.INCLUDES.PRODUCT_CODE, 'SIGNON')
      this.setAdditionalDatum(LoginMessagePin.ADD_GREETING_MENU, '')
      this.setAdditionalDatum(LoginMessagePin.ADD_GREETING_TITLE, '')
      this.setAdditionalDatum(LoginMessagePin.ADD_GREETING_BODY, '')
      this.setAdditionalDatum(LoginMessagePin.ADD_ACTION, '')
      this.setAdditionalDatum(LoginMessagePin.ADD_DEVICE_ID, this.data.uuid)
      this.setAdditionalDatum(LoginMessagePin.ADD_OTP_CODE, '')
      this.setAdditionalDatum(LoginMessagePin.ADD_PLATFORM, isMobile ? 'MOBILE WEB' : 'WEB')
      this.setAdditionalDatum(LoginMessagePin.ADD_DEVICE_NAME, isMobile ? mobileModel : browserName)
      this.setAdditionalDatum(LoginMessagePin.ADD_USER_AGENT, this.data.uuid)
      this.setAdditionalDatum(LoginMessagePin.ADD_GEO_LOCATION, '0,0')
      this.setAdditionalDatum(LoginMessagePin.ADD_SOSMED_PROVIDER, '')
      this.setAdditionalDatum(LoginMessagePin.ADD_VERSION_CODE, this.data.version_code.toString())

      this.data.appid = 'SPEEDCASH'
      this.data.is_paralel = false
      this.setAsEncrypted()
    }
  }

  setLoginV2Motp() {
    this.setPath(`${LoginMessagePin.SC_API}/login/v2/motp`)
  }

  // eslint-disable-next-line class-methods-use-this
  getAdditionalDataCapacity() {
    return 12
  }

  getAccessToken() {
    return Var.toString(this.data.access_token)
  }

  isChangeDevice() {
    return this.getRC() === '130'
  }

  isInvalidOTP() {
    return this.getRC() === '131'
  }

  isInvalidPIN() {
    return this.getRC() === '02'
  }

  getUserPin() {
    return Var.toString(this.data.user_pin)
  }
}
