import MPMessage from '../mp'

export default class LayananMessage extends MPMessage {
  constructor(data) {
    super(data)

    this.setPath(`${LayananMessage.MP_NODE}/`)

    if (!data) {
      this.setMessageType('TRX')
      this.data.additional_datas = Array(this.getAdditionalDataCapacity()).fill('')
      this.setAsEncrypted()
    }
  }
}
