import profileConstant from '../constant/profile'

const initialState = {
  [profileConstant.NAME]: '',
  [profileConstant.PHONE]: '',
  [profileConstant.EMAIL]: '',
}

export default (state = initialState, action) => {
  switch (action.type) {
    case profileConstant.NAME:
    case profileConstant.PHONE:
    case profileConstant.EMAIL:
      return {
        ...state,
        [action.type]: action.payload,
      }
    default:
      return state
  }
}
