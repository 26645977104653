import BaseMessage from '../base'

export default class OTPMessage extends BaseMessage {
  constructor(data) {
    super(data)

    if (!data) {
      this.data.location = '0,0'
    }
  }

  setUserCheckMode() {
    this.setPath(`${OTPMessage.SC_API}/user/check`)
  }

  setRequestMode() {
    this.setPath(`${OTPMessage.SC_API}/otp/generate`)
  }

  setOTPCheckMode() {
    this.setPath(`${OTPMessage.SC_API}/otp/validate`)
  }

  setState(state) {
    this.data.state = state
  }

  setPhone(phone) {
    this.data.phone = phone
  }

  setToken(token) {
    this.data.token = token
  }

  setType(type) {
    this.data.type = type
  }

  isUnregistered() {
    return this.getRC() === '404'
  }
}
