import BaseMessage from '../base'

export default class ReportsMessage extends BaseMessage {
  constructor(data) {
    super(data)

    if (!data) {
      this.data.limit = '10'
      this.data.offset = '0'
    }
  }

  setPathAll() {
    this.setPath(`${ReportsMessage.MP_NODE}/dashboard/report/all/`)
  }

  setPathSuccess() {
    this.setPath(`${ReportsMessage.MP_NODE}/dashboard/report/sukses/`)
  }

  setPathFailed() {
    this.setPath(`${ReportsMessage.MP_NODE}/dashboard/report/gagal/`)
  }

  setPathResumeTrx() {
    this.setPath(`${ReportsMessage.MP_NODE}/dashboard/report/resume/sukses/`)
  }

  setPathResumeTrxAll() {
    this.setPath(`${ReportsMessage.MP_NODE}/dashboard/report/resume/all/`)
  }

  setStartDate(startDate) {
    this.data.start_date = startDate
  }

  setEndDate(endDate) {
    this.data.end_date = endDate
  }

  setUserId(userId) {
    this.data.id = userId
  }

  setIdUser(userId) {
    this.data.user_id = userId
  }

  setDateStart(startDate) {
    this.data.date_start = startDate
  }

  setDateEnd(endDate) {
    this.data.date_end = endDate
  }

  setPhone(phone) {
    this.data.number = phone
  }

  setLimit(limit) {
    this.data.limit = limit
  }

  setOffset(offset) {
    this.data.offset = offset
  }
}
