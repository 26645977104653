import React, { useRef } from 'react'
import { connect } from 'react-redux'
import { Spinner, Alert, Modal, Form, Button, Row, Col } from 'react-bootstrap'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { isMobile } from 'react-device-detect'
import DOMHelper from '../../../../helper/domhelper'
import HookHelper from '../../../../helper/hookhelper'
import Formatter from '../../../../helper/formatter'

import '../layanan.scss'
import globalConstant from '../../../../store/constant/global'
import authConstant from '../../../../store/constant/auth'

const PaketDataScreen = ({
  appIsLoading,
  dataPaketData,
  selectedProduct,
  isInqWeb,
  processTransaction,
}) => {
  const btnProcessRef = useRef(null)
  const [alertError, setAlertError] = React.useState('')
  const [product, setProduct] = React.useState(null)

  const closeError = React.useCallback(() => setAlertError(''), [])

  const selectProduct = React.useCallback(
    (e) => {
      const { id } = e.currentTarget.dataset
      const [filteredProduct] = dataPaketData.filter((g) => `${g.idproduk}` === id)
      if (filteredProduct.is_gangguan === '0') {
        setProduct(filteredProduct)
        selectedProduct(filteredProduct)
      } else {
        setAlertError({
          rc: '',
          rd: 'Produk yang Anda pilih masih dalam gangguan. Silahkan pilih produk lain',
        })
      }

      // eslint-disable-next-line no-unused-expressions
      btnProcessRef.current?.scrollIntoView({ behavior: 'smooth' })
    },
    [dataPaketData, setProduct, selectedProduct]
  )

  React.useEffect(() => {
    DOMHelper.mountClass('layanan-layout')
    HookHelper.resetLoading()

    return () => DOMHelper.unmountClass(['layanan-layout'])
  }, [])

  return (
    <>
      <div style={{ width: '100%' }}>
        {dataPaketData && (
          <div>
            <Form className="form-pelanggan">
              <Row>
                {dataPaketData.map((value) => (
                  <Col
                    className="icon-col-game produk"
                    xs={6}
                    md={4}
                    lg={3}
                    key={value.idproduk}
                    data-id={value.idproduk}
                    onClick={selectProduct}
                  >
                    <div
                      className={classNames('col-list-game pulsa', {
                        trouble: value.is_gangguan === '1',
                        'selected-produk': value.idproduk === (product ? product.idproduk : ''),
                      })}
                    >
                      <div className="produk-body">
                        <span
                          className={classNames('produk-label', {
                            trouble: value.is_gangguan === '1',
                            'selected-produk': value.idproduk === (product ? product.idproduk : ''),
                          })}
                        >
                          {Math.round(value.denom) > 0
                            ? Formatter.price(Math.round(value.denom))
                            : Formatter.price(Math.round(value.hargajual))}
                        </span>
                        <span
                          className={classNames('produk-sub-label', {
                            trouble: value.is_gangguan === '1',
                            'is-mobile': isMobile,
                            'selected-produk': value.idproduk === (product ? product.idproduk : ''),
                          })}
                          style={{
                            paddingLeft: '5px',
                            paddingRight: '5px',
                            marginTop: '5px',
                            marginBottom: '5px',
                          }}
                        >
                          {value.namaproduk}
                        </span>
                        {Math.round(value.nominalUp) > 0 && (
                          <span
                            className={classNames('produk-sub-label color-primary txt-bold', {
                              trouble: value.is_gangguan === '1',
                              'is-mobile': isMobile,
                              'selected-produk':
                                value.idproduk === (product ? product.idproduk : ''),
                            })}
                          >
                            {`Diskon Rp. ${Formatter.price(Math.round(value.nominalUp))}`}
                          </span>
                        )}
                      </div>
                      <div
                        className={classNames('produk-footer', {
                          trouble: value.is_gangguan === '1',
                          'selected-produk': value.idproduk === (product ? product.idproduk : ''),
                        })}
                      >
                        {Math.round(value.nominalUp) > 0 && Math.round(value.hargajual) > 0 && (
                          <span
                            className={classNames('produk-label-footer', {
                              'is-mobile': isMobile || isInqWeb,
                              'is-diskon': true,
                            })}
                          >
                            {`Rp ${Formatter.price(
                              Math.round(value.hargajual) + Math.round(value.nominalUp)
                            )}`}
                          </span>
                        )}
                        <span
                          className={classNames('produk-label-footer', {
                            'is-mobile': isMobile || isInqWeb,
                          })}
                        >
                          {value.hargajual > 0
                            ? `Rp ${Formatter.price(Math.round(value.hargajual))}`
                            : ' '}
                        </span>
                      </div>
                    </div>
                  </Col>
                ))}
              </Row>
            </Form>

            <Col
              style={{ display: 'flex', justifyContent: 'flex-end', paddingTop: '10px' }}
              ref={btnProcessRef}
            >
              <Button
                variant="warning"
                className="btn-next"
                size="lg"
                onClick={processTransaction}
                disabled={appIsLoading}
                style={{ textTransform: 'none', width: '50%' }}
              >
                {appIsLoading && !isMobile && (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                )}
                {appIsLoading && !isMobile ? ' Mohon Tunggu...' : 'Lanjutkan'}
              </Button>
            </Col>

            <hr />
          </div>
        )}
      </div>
      <Modal
        show={!!alertError.rd}
        onHide={closeError}
        animation={false}
        contentClassName="alert-dialog"
      >
        {alertError === '17' ? (
          <>
            <Modal.Body>{alertError.rd}</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={closeError}>
                Tutup
              </Button>
            </Modal.Footer>
          </>
        ) : (
          <>
            <Alert
              variant="danger"
              show={!!alertError.rd}
              onClose={closeError}
              transition={null}
              dismissible
            >
              <span style={{ fontSize: '16px', fontWeight: 'bold' }}>{alertError.rd}</span>
            </Alert>
          </>
        )}
      </Modal>
    </>
  )
}

PaketDataScreen.defaultProps = {
  appIsLoading: false,
  isInqWeb: false,
  dataPaketData: [],
}

PaketDataScreen.propTypes = {
  appIsLoading: PropTypes.bool,
  isInqWeb: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  dataPaketData: PropTypes.array,
  selectedProduct: PropTypes.func.isRequired,
  processTransaction: PropTypes.func.isRequired,
}

export default connect(
  (state) => ({
    appIsLoading: state.Global[globalConstant.IS_LOADING],
    appUserId: state.Auth[authConstant.USER_ID],
  }),
  (dispatch) => ({ dispatch })
)(PaketDataScreen)
