/* eslint-disable no-nested-ternary */
import _ from 'lodash'
import React from 'react'
import { connect } from 'react-redux'
import { Tabs, Tab, Row, Container, Col } from 'react-bootstrap'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { isMobile } from 'react-device-detect'
import { useHistory } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Alert from 'react-popup-alert'
import 'react-popup-alert/dist/index.css'
import DOMHelper from '../../../helper/domhelper'
import HookHelper from '../../../helper/hookhelper'
import DateTime from '../../../helper/datetime'

import './deposit.scss'
import globalConstant from '../../../store/constant/global'
import authConstant from '../../../store/constant/auth'
import SidebarComponent from '../../component/sidebar'
import HeaderComponent from '../../component/header'
import LoginModal from '../../dialog/auth/login'
import BankScreen from './bank'
import VaScreen from './va'
import DepositAktifModal from '../../dialog/deposit/depositAktif'

import { depositList } from '../../../api/action/deposit/deposit'

const DepositScreen = ({ appUserId, appUserIsEmbed, dispatch }) => {
  const DEP0SIT_AKTIF = '0'
  const history = useHistory()
  const [isOpen, setIsOpen] = React.useState(!isMobile)
  const [alertError, setAlertError] = React.useState('')
  const [loginModalShow, setLoginModalShow] = React.useState(false)
  const [depositAktifModal, setDepositAktifModal] = React.useState(false)
  const [tab, setTab] = React.useState(0)
  const [depositAktif, setDepositAktif] = React.useState([])

  const toggleSidebar = React.useCallback(() => setIsOpen(!isOpen), [isOpen, setIsOpen])
  const closeError = React.useCallback(() => setAlertError(''), [])

  const confirmLoginModal = React.useCallback(async () => {
    setLoginModalShow(false)
    // window.location.reload()
  }, [setLoginModalShow])

  const closeDepositAktifModal = React.useCallback(async () => {
    setDepositAktifModal(false)
  }, [setDepositAktifModal])

  const openDepositAktifModal = React.useCallback(async () => {
    setDepositAktifModal(true)
  }, [setDepositAktifModal])

  const closeLoginModal = React.useCallback(async () => {
    if (!appUserId) {
      setLoginModalShow('')
      history.push('/')
    } else {
      setLoginModalShow('')
    }
  }, [appUserId, history, setLoginModalShow])

  const onTabChange = React.useCallback(
    (e) => {
      setTab(e)
    },
    [setTab]
  )

  React.useEffect(() => {
    DOMHelper.mountClass('deposit-layout')
    HookHelper.resetLoading()

    if (!appUserId) {
      setLoginModalShow(true)
    }

    if (appUserId) {
      dispatch(depositList(appUserId, DEP0SIT_AKTIF)).then((response) => {
        if (response.isOK()) {
          const result = response.getResultAsList()
          const tiketAktif = _.filter(result, (item) => {
            return !DateTime.isExpired(item.time_request)
          })
          setDepositAktif(tiketAktif)
        }
      })
    }
  }, [dispatch, appUserId, setDepositAktif, setLoginModalShow])

  return (
    <Container fluid className="deposit-layout">
      {!appUserIsEmbed && (
        <Row className="header">
          <Col>
            <HeaderComponent title="Isi Saldo" onToggle={toggleSidebar} />
          </Col>
        </Row>
      )}

      <Row>
        {!appUserIsEmbed && (
          <Col
            md={2}
            className={classNames('col-sidebar', { 'is-open': isOpen, 'is-mobile': isMobile })}
          >
            <SidebarComponent isOpen={isOpen} />
          </Col>
        )}

        <Col
          md={{
            span: appUserIsEmbed ? 12 : isOpen ? 10 : 12,
            offset: appUserIsEmbed ? 0 : isOpen ? 2 : 0,
          }}
        >
          <Row className="justify-content-md-center">
            <div className="content-layout">
              <div className="content-head">
                <div className="head-top">
                  <span className="head-title">Isi Saldo SpeedCash</span>
                  <span className="head-sub-title">
                    Nikmati kemudahan bertransaksi di SpeedCash, tapi sebelum itu Isi Saldo dulu ya
                  </span>
                </div>
                <div className="head-top" />
              </div>
              <div className="content-body">
                <div className={classNames('body-form', { 'is-mobile': isMobile })}>
                  <div className="form-content">
                    {depositAktif.length > 0 && (
                      <div className="deposit-aktif">
                        <Row className="rw-body">
                          <Col md={2} lg={2} xs={12} className="text-center">
                            <FontAwesomeIcon
                              icon="wallet"
                              fixedWidth
                              className="mr-4 color-primary icon-48"
                            />
                          </Col>
                          <Col md={7} lg={7} xs={12}>
                            <Row>
                              <span className="label-bold color-primary txt-20">{`${depositAktif.length} Tiket Deposit Anda masih Aktif`}</span>
                            </Row>
                            <Row className="add-space">
                              <span className="txt-14">
                                Selesaikan proses transfer sebelum
                                <span className="txt-14 txt-bold">
                                  {` ${DateTime.getExpired(depositAktif[0].time_request)}`}
                                </span>
                              </span>
                            </Row>
                          </Col>
                          <Col md={3} lg={3} xs={12} onClick={openDepositAktifModal}>
                            <div className="label-text primary" style={{ cursor: 'pointer' }}>
                              Klik untuk lihat detail tiket
                            </div>
                          </Col>
                        </Row>
                      </div>
                    )}
                    <Tabs
                      variant="tabs"
                      activeKey={tab}
                      onSelect={onTabChange}
                      transition={false}
                      fill
                      className="tab-form txt-16 txt-bold"
                    >
                      <Tab eventKey="0" title="Transfer Bank">
                        <BankScreen />
                      </Tab>
                      <Tab eventKey="1" title="Transfer Cepat (VA)">
                        <VaScreen />
                      </Tab>
                    </Tabs>
                  </div>
                </div>
              </div>
            </div>
          </Row>
        </Col>
      </Row>
      <Alert
        header="Perhatian"
        btnText="OK"
        text={alertError.rd}
        type="error"
        show={!!alertError}
        onClosePress={closeError}
        showBorderBottom
        alertStyles={{ justifyContent: 'center' }}
      />
      <LoginModal show={!!loginModalShow} onHide={closeLoginModal} onConfirm={confirmLoginModal} />
      <DepositAktifModal
        show={!!depositAktifModal}
        onHide={closeDepositAktifModal}
        status={DEP0SIT_AKTIF}
        dataDepositAktif={depositAktif}
      />
    </Container>
  )
}

DepositScreen.defaultProps = {
  appUserId: '',
  appUserIsEmbed: false,
}

DepositScreen.propTypes = {
  appUserId: PropTypes.string,
  appUserIsEmbed: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
}

export default connect(
  (state) => ({
    appIsLoading: state.Global[globalConstant.IS_LOADING],
    appUserId: state.Auth[authConstant.USER_ID],
    appUserIsEmbed: state.Auth[authConstant.USER_IS_EMBED],
  }),
  (dispatch) => ({ dispatch })
)(DepositScreen)
