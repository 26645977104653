import React from 'react'
import { Button, Image, Row, Col, Accordion, Card } from 'react-bootstrap'
import './accordion.scss'

const AccordionGameUnipin = () => {
  return (
    <>
      <Col className="d-flex justify-content-center mt-4 mb-3 p-3">
        <h3 className="text-dark text-justify">
          Top Up Unipin Dan Voucher Game Harga Mulai 10ribuan
        </h3>
      </Col>

      <div style={{ padding: '0px 20px 30px 20px' }}>
        <Row className="justify-content-md-center">
          <Col lg={8} xs={12} md={8} className="content-component">
            <div>
              <Row className="d-flex justify-content-center">
                <Image src="/img/top-up-unipin.png" data-target="banner-unipin" width="100%" />
              </Row>
              <Row className="d-flex justify-content-center mt-3">
                <p className="d-block text-justify">
                  Top up unipin dan mendapatkan harga termurah merupakan idaman bagi pencinta game
                  online di seluruh dunia. Sekarang untuk membeli voucher game unipin juga sudah
                  sangat mudah. Kamu tidak perlu repot harus keluar rumah dan datang ke minimarket
                  hanya untuk sekedar mengisi saldo unipin. Berikut akan kami berikan cara{' '}
                  <a href="https://member.speedcash.co.id/game/top-up-unipin">top up unipin</a>{' '}
                  termurah harga mulai 10 ribuan kamu sudah bisa bermain game besutan unipin.
                </p>
                <p className="d-block text-justify mt-1">
                  Kemudahan beli voucher game maupun top up game lain seperti{' '}
                  <a href="https://member.speedcash.co.id/game/top-up-ff">top up ff</a> , top up
                  diamond ml hingga beli pulsa dan transfer uang ke bank tanpa biaya admin dapat
                  kamu peroleh hanya disini
                </p>
                <p className="d-block text-justify mt-1">
                  Itulah alasan kenapa banyak orang menggunakan SpeedCash walaupun hanya untuk top
                  up unipin saja karena mereka mendapatkan banyak kelebihan yang ditawarkan disini
                </p>
              </Row>
            </div>
          </Col>

          <Col lg={8} xs={12} md={8} className="accordion-component">
            <Accordion className="m-0 p-0">
              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="1"
                    className="button-collapse w-100 text-left"
                  >
                    Cara Top Up Unipin Melalui Website
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="1">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Untuk mengisi saldo/top up diamond free fire di speedcash kamu bisa
                        mengikuti langkah mudah berikut ini :
                      </p>
                    </Row>
                    <Row className="d-flex justify-content-start">
                      <ol className="text-justify list-content">
                        <li>Kunjungi alamat : https://member.speedcash.co.id/game/top-up-unipin</li>
                        <li>Lanjutkan dengan login menggunakan nomor hp yang aktif ya</li>
                        <li>Dan masuk ke menu voucher game</li>
                        <li>Masukkan user ID atau nomor HP yang terdaftar di akun unipin</li>
                        <li>Pilih nominal yang kamu isikan</li>
                        <li>Jika sudah klik lanjutkan dan ikuti saja instruksi selanjutnya</li>
                      </ol>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="2"
                    className="button-collapse w-100 text-left"
                  >
                    Top Up Unipin Via Aplikasi Android Atau IOS
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="2">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Selain membeli voucher unipin / top up unipin melalui website ternyata kamu
                        juga bisa melakukan di aplikasi. Caranya juga sangat mudah sekali hanya
                        dalam beberapa langkah saja. Berikut caranya :
                      </p>
                      <Row className="mt-3 w-100">
                        <ol className="text-justify list-content w-100">
                          <li>
                            Dapatkan SpeedCash yang ada di playstore (Android) maupun appstore (IOS)
                          </li>
                          <li>Lalu login menggunakan no hp yang aktif ya</li>
                          <li>Jika sudah login, langsung saja kamu klik menu Voucher Game</li>

                          <li>
                            <p>Pilih layanan Voucher Game</p>
                            <Row className="d-flex justify-content-center">
                              <Image
                                src="/img/unipin1.png"
                                data-target="unipin1"
                                width="220px"
                                height="400px"
                              />
                            </Row>
                          </li>
                          <li>
                            <p>Jika sudah, pilih dulu nominal voucher yang ingin kamu isi</p>
                            <Row className="d-flex justify-content-center">
                              <Image
                                src="/img/unipin2.png"
                                data-target="unipin2"
                                width="220px"
                                height="400px"
                              />
                            </Row>
                          </li>
                          <li>Selanjutnya isi user ID / No handphone</li>
                          <li>
                            Lalu klik lanjutkan dan ikuti instruksi selanjutnya hingga voucher
                            terisi
                          </li>
                        </ol>
                      </Row>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="4"
                    className="button-collapse w-100 text-left"
                  >
                    Kenapa Harus Top Up Unipin / Beli Voucher Di SpeedCash
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="4">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Beberapa orang baru menyadari ketika mereka membeli voucher game / top up
                        game online ditempat lain mungkin kurang aman, mulai dari voucher yang tidak
                        masuk dan CS yang susah dihubungi atau banyak keluhan lainnya. Tapi jika
                        kamu mengisi voucher game unipin maupun top up unipin dan game lainnya kamu
                        akan mendapatkan kemudahan,kecepatan saat pengisian.
                      </p>
                      <p className="d-block text-justify mt-1">
                        Kamu juga tidak perlu khawatir jika voucher yang kamu beli tidak masuk,
                        karena di SpeedCash menyediakan layanan customer service selama 24 jam / 7
                        hari. Kapanpun dimanapun kamu mengalami kendala kamu bisa langsung
                        menghubungi CS di menu live chat aplikasi{' '}
                        <a href="https://www.speedcash.co.id/">SpeedCash</a>.
                      </p>
                      <p className="d-block text-justify mt-1">
                        Promo menarik lainnya juga sudah menanti kamu, jika kamu menyadari saat kamu
                        membayar voucher unipin disini kamu akan langsung memperoleh poin / diskon
                        langsung yang bisa kamu kumpulkan untuk selanjutnya bisa kamu gunakan
                        bertransaksi di menu produk/layanan lainnya.
                      </p>
                      <p className="d-block text-justify mt-1">
                        Dan yang tidak kalah penting lainnya untuk segi keamanan, bertransaksi
                        menggunakan SpeedCash kamu tidak perlu ragu, karena SpeedCash sudah
                        berlisensi resmi bank indonesia sehingga saldo/uang mu yang ada di akun
                        SpeedCash tidak akan hilang begitu saja.
                      </p>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="5"
                    className="button-collapse w-100 text-left"
                  >
                    Bagaimana Cara Isi Saldo SpeedCash
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="5">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Sebelum membeli voucher game / top up unipin menggunakan SpeedCash, hal yang
                        harus kamu perhatikan ialah saldo SpeedCashmu. Agar pembelian berjalan
                        dengan lancar maka kamu harus mengisi saldo dulu. Caranya juga sangat mudah
                        kamu bisa ikuti cara isi saldo melalui video berikut ini :
                      </p>
                      <Row className="d-flex w-100 justify-content-center mb-3">
                        <iframe
                          width="100%"
                          height="480"
                          src="https://www.youtube.com/embed/sI1fLYglLwk"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                          frameBorder={0}
                          title="Cara Isi Saldo Di SpeedCash"
                        />
                      </Row>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="6"
                    className="button-collapse w-100 text-left"
                  >
                    Harga Unipin Credits Voucher Di SpeedCash :
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="6">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Pastikan kamu tidak salah tempat untuk top up unipin dengan harga termurah,
                        berikut list harga unipin credits yang bisa kamu beli :
                      </p>
                      <table className="mt-2 mb-4 table table-bordered">
                        <tr>
                          <th>Unipin Credits</th>
                          <th>Harga</th>
                        </tr>
                        <tr>
                          <td>GUNI10 (UNIPIN10RB)</td>
                          <td>Rp10,225</td>
                        </tr>
                        <tr>
                          <td>GUNI20 (UNIPIN20RB)</td>
                          <td>Rp19,775</td>
                        </tr>
                        <tr>
                          <td>GUNI50 (UNIPIN50RB)</td>
                          <td>Rp48,425</td>
                        </tr>
                        <tr>
                          <td>GUNI100 (UNIPIN100RB)</td>
                          <td>Rp96,225</td>
                        </tr>
                        <tr>
                          <td>GUNI300 (UNIPIN300RB)</td>
                          <td>Rp295,525</td>
                        </tr>
                        <tr>
                          <td>GUNI500 (UNIPIN500RB)</td>
                          <td>Rp485,525</td>
                        </tr>
                      </table>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="7"
                    className="button-collapse w-100 text-left"
                  >
                    Apa Itu Unipin ?
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="7">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        UniPin (Universal Pin) adalah platform isi ulang kredit game online
                        terkemuka di Asia Tenggara, yang menyediakan cara cepat dan mudah bagi
                        pemain untuk membeli kredit game. Platform ini menawarkan metode pembayaran
                        yang beragam sehingga mudah diakses oleh banyak pengguna. dari platform yang
                        berbeda. Layanan UniPin mencakup banyak judul populer, termasuk game
                        seluler, game PC, dan game konsol.
                      </p>
                      <p className="d-block text-justify mt-1">
                        Salah satu keunggulan utama UniPin adalah kemudahan penggunaannya. Dengan
                        UniPin, pemain dapat melakukan top up kredit game dengan cepat dan aman
                        tanpa menggunakan kartu kredit. Hal ini sangat berguna bagi orang-orang yang
                        tidak memiliki akses ke metode pembayaran reguler.
                      </p>
                      <div className="d-flex flex-column">
                        <p className="d-block text-justify mt-3">Dua produk utama unipin:</p>
                        <Row className="mt-1">
                          <ul className="text-justify list-content">
                            <li>
                              <b>UniPin Credits</b>: Saldo digital yang bisa digunakan untuk
                              melakukan pembelian di berbagai layanan yang bekerjasama dengan
                              UniPin. Anda bisa melakukan top up UniPin Credits melalui berbagai
                              metode pembayaran.
                            </li>
                            <li>
                              <b>UniPin Voucher</b>: Voucher fisik yang bisa dibeli di minimarket
                              seperti Indomaret dan ditukarkan menjadi UniPin Credits.
                            </li>
                          </ul>
                        </Row>
                      </div>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="8"
                    className="button-collapse w-100 text-left"
                  >
                    Dan Apa Itu Unipin Wallet ?
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="8">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        UniPin Wallet adalah metode pembayaran e-wallet khusus untuk pelanggan
                        gaming di Indonesia.
                      </p>
                      <p className="d-block text-justify mt-1">
                        Dengan UniPin Wallet, Anda dapat mengisi saldo dan kemudian menggunakannya
                        untuk membeli berbagai item atau layanan di game online favorit Anda, baik
                        secara lokal dan secara internasional. Selain itu, UniPin Wallet juga dapat
                        digunakan untuk menambah saldo layanan hiburan digital lainnya.
                      </p>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="9"
                    className="button-collapse w-100 text-left"
                  >
                    Game Yang Bisa Di Top Up Menggunakan Unipin :
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="9">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Beberapa game populer yang sering dimainkan para gamer baik di indonesia
                        maupun luar negeri ternyata juga bisa di top up melalui unipin, berikut list
                        permainan nama game populer yang didukung layanan unipin :
                      </p>
                      <table className="mt-2 mb-4 table table-bordered">
                        <tr>
                          <td>Mobile Legends: Bang Bang</td>
                          <td>
                            Merupakan game mobile MOBA (Multiplayer Online Battle Arena) yang sangat
                            populer di Asia Tenggara. dengan unipin kamu bisa top up diamond ML juga
                            loh
                          </td>
                        </tr>
                        <tr>
                          <td>PUBG Mobile</td>
                          <td>
                            Adalah game battle royale mobile yang mendunia dan memiliki basis
                            pengguna yang besar.Untuk membeli UC juga bisa menggunakan Unipin
                          </td>
                        </tr>
                        <tr>
                          <td>Free Fire</td>
                          <td>
                            Siapa yang tidak kenal dengan FF (free fire) permainan battle royal yang
                            sudah mendarah daging ini ternyata juga didukung untuk top up diamond
                            melalui unipin
                          </td>
                        </tr>
                        <tr>
                          <td>Call of Duty: Mobile</td>
                          <td>
                            Adalah waralaba permainan video penembak orang pertama yang diterbitkan
                            oleh Activision. Dimulai pada tahun 2003
                          </td>
                        </tr>
                        <tr>
                          <td>Arena of Valor</td>
                          <td>
                            Arena of Valor (AOV) adalah permainan video berjenis multiplayer online
                            battle arena (MOBA) yang khusus dikembangkan untuk perangkat mobile.
                          </td>
                        </tr>
                        <tr>
                          <td>Genshin Impact</td>
                          <td>
                            Merupakan permainan video action RPG open-world yang dikembangkan dan
                            diterbitkan oleh miHoYo, perusahaan asal China yang terkenal dengan game
                            Honkai Impact 3rd.
                          </td>
                        </tr>
                        <tr>
                          <td>Valorant</td>
                          <td>
                            Adalah permainan video penembak taktis berbasis karakter 5v5 yang
                            dikembangkan dan diterbitkan oleh Riot Games
                          </td>
                        </tr>
                        <tr>
                          <td>Steam Wallet</td>
                          <td>
                            Steam Wallet adalah sistem pembayaran digital yang bisa digunakan untuk
                            membeli berbagai macam produk di platform Steam. Steam sendiri merupakan
                            platform distribusi digital game terbesar di dunia yang dikembangkan
                            oleh Valve Corporation.
                          </td>
                        </tr>
                        <tr>
                          <td>PlayStation Network</td>
                          <td>
                            Pengisian saldo untuk PlayStation Network, memungkinkan pembelian game
                            dan layanan di ekosistem PlayStation.
                          </td>
                        </tr>
                      </table>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="10"
                    className="button-collapse w-100 text-left"
                  >
                    Cara Daftar Akun Unipin
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="10">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Untuk mendapatkan akun unipin juga sangat mudah, kamu bisa mendaftar akun
                        unipin melalui website. Untuk lebih jelasnya kamu bisa ikuti cara berikut
                        ini :
                      </p>
                      <ul className="text-justify list-content">
                        <li>Buka aplikasi Chrome atau Firefox di PC/Laptop Anda.</li>
                        <li>klik link http://www.unipin.co.id/registration </li>
                        <li>Pilih “Daftar” </li>
                        <li>Lengkapi formulir pendaftaran yang tersedia.</li>
                        <li>Klik “Kirim”</li>
                        <li>
                          Setelah mengklik opsi ini, UniPin akan mengirimkan permintaan verifikasi
                          melalui email ke akun Google Anda
                        </li>
                        <li>Masuk ke akun Google Anda dan memulai. di halaman Gmail Anda.</li>
                        <li>Pilih pesan yang diterima dari UniPin</li>
                        <li>Klik verifikasi</li>
                        <li>
                          Setelah verifikasi, akun UniPin Anda akan langsung aktif dan siap
                          digunakan.
                        </li>
                      </ul>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="11"
                    className="button-collapse w-100 text-left"
                  >
                    Beli Voucher Unipin Melalui ATM Mandiri
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="11">
                  <Card.Body className="card-content">
                    <Row>
                      <ul className="text-justify list-content">
                        <li>Pilih &ldquo;Bayar/Beli&ldquo;</li>
                        <li>
                          Pilih &ldquo;Multipayment&ldquo; (di menu bayar/beli/lainnya Pilih
                          Multipayment.)
                        </li>
                        <li>
                          Masukkan &ldquo;Kode Perusahaan&ldquo; 60009 (Masukkan 60009 sebagai Kode
                          Perusahaan/Institusi kemudian Pilih Benar.)
                        </li>
                        <li>
                          Masukkan &ldquo;Nomor Handphone&ldquo; (Masukkan Nomor Handphone dan
                          pastikan nomor handphone sesuai.)
                        </li>
                        <li>Pilih Nominal Voucher</li>
                        <li>
                          &ldquo;Konfirmasi Pembelian&ldquo;(Periksa kembali transaksi dan Pilih YA
                          untuk konfirmasi.)
                        </li>
                      </ul>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="12"
                    className="button-collapse w-100 text-left"
                  >
                    Top Up Unipin Melalui Virtual Account
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="12">
                  <Card.Body className="card-content">
                    <Row>
                      <ul className="text-justify list-content">
                        <li>
                          Kunjungi situs UniPin.com, lalu pilih produk/game yang anda inginkan
                          (contoh: Free Fire).
                        </li>
                        <li>Masukan user ID unipin</li>
                        <li>Pilih denom yang diinginkan</li>
                        <li>
                          Pilih pembayaran dengan menggunakan transfer bank Mandiri Virtual Account.
                        </li>
                        <li>
                          Masukkan nama, email dan nomor telepon yang digunakan, lalu pilih
                          &ldquo;KONFIRMASI&rdquo;.
                        </li>
                        <li>Baca kembali detail pembayarannya</li>
                        <li>
                          Periksa email anda dan ikuti petunjuk pembayaran tersebut untuk
                          menyelesaikan pembayaran.
                        </li>
                      </ul>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="13"
                    className="button-collapse w-100 text-left"
                  >
                    Cara Redeem Voucher Unipin
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="13">
                  <Card.Body className="card-content">
                    <Row>
                      <ul className="text-justify list-content">
                        <li>Pastikan kamu sudah beli / top up unipin di SpeedCash</li>
                        <li>Masuk ke website UniPin dan lakukan Login ke akun kamu.</li>
                        <li>Klik reload pada halaman browser.</li>
                        <li>
                          Pilih “UniPin Voucher Online” untuk melakukan isi ulang UniPin Credit akun
                          kamu.
                        </li>
                        <li>
                          Masukkan nomor serial dan nomor yang tertera pada voucher UniPin kamu
                        </li>
                        <li>Klik “OK”</li>
                        <li>Redeem Voucher UniPin kamu telah sukses.</li>
                      </ul>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="14"
                    className="button-collapse w-100 text-left"
                  >
                    Cara Top Up ML Melalui Unipin
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="14">
                  <Card.Body className="card-content">
                    <Row>
                      <ul className="text-justify list-content">
                        <li>Kunjungi situs resmi UniPin</li>
                        <li>
                          Perhatikan kolom search di sebelah kanan atas, ketik “Mobile Legends” dan
                          tekan “Enter”
                        </li>
                        <li>
                          Bisa juga langsung kunjungi metode pembayaran UniPin untuk Mobile Legends
                          secara langsung di https://unipin.co.id/mobile-legends.
                        </li>
                        <li>
                          Masukkan user ID kamu (Untuk mengetahuinya, klik profile di bagian kiri
                          atas pada menu utama game. User ID kamu adalah yang ada di bawah nama
                          karakter game kamu).
                        </li>
                        <li>
                          Pilih metode pembayaran. Kamu bebas memilih pembayaran menggunakan
                          voucher, wallet, pulsa, internet banking, bank transfer dan lain-lain.
                        </li>
                        <li>
                          Lakukan Direct Top Up menggunakan voucher UniPin yang bisa kamu beli di
                          Tokopedia
                        </li>
                        <li>Lengkapi data Serial Number dan PIN kamu.</li>
                        <li>Pilih “Kirim”.</li>
                        <li>
                          Kamu juga bisa{' '}
                          <a href="https://member.speedcash.co.id/game/top-up-ml">top up ml </a>
                          murah di speedcash
                        </li>
                      </ul>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="15"
                    className="button-collapse w-100 text-left"
                  >
                    FAQ Mengenai Top Up Unipin Di SpeedCash
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="15">
                  <Card.Body className="card-content">
                    <Row>
                      <div className="w-100">
                        <p className="d-block text-justify">
                          <strong>A :</strong> Dimana saya bisa top up unipin ?{' '}
                        </p>
                      </div>
                      <div className="w-100 my-1">
                        <p className="d-block text-justify">
                          {' '}
                          <strong>Q :</strong> Top up unipin bisa kamu lakukan di speedcash.Beli
                          voucher unipin disini pasti langsung dapat diskon dan bonus poin
                        </p>
                      </div>
                      <div className="w-100 my-1">
                        <p className="d-block text-justify">
                          <strong>A :</strong> Apakah saldo unipin bisa diuangkan ?
                        </p>
                      </div>
                      <div className="w-100 my-1">
                        <p className="d-block text-justify">
                          <strong>Q :</strong> Tentu tidak bisa, namun saldo unipin bisa kamu
                          gunakan untuk membeli keperluan gaming favoritmu saja
                        </p>
                      </div>
                      <div className="w-100 my-1">
                        <p className="d-block text-justify">
                          <strong>A :</strong> Top Up Unipin Di sini apakah aman ?
                        </p>
                      </div>
                      <div className="w-100 my-1">
                        <p className="d-block text-justify">
                          <strong>Q :</strong> Tentu saja, kamu tidak perlu khawatir mengenai
                          keamanan untuk top up/ beli voucher unipin di speedcash, kami juga
                          menyediakan layanan live chat CS selama 24 jam
                        </p>
                      </div>
                      <div className="w-100 my-1">
                        <p className="d-block text-justify">
                          <strong>A :</strong> Apakah untuk beli voucher unipin memerlukan rekening
                          bank ?
                        </p>
                      </div>
                      <div className="w-100 my-1">
                        <p className="d-block text-justify">
                          <strong>Q :</strong> Jika kamu menggunakan speedcash,artinya kamu tidak
                          memerlukan rekening bank untuk top up unipin maupun beli voucher game
                          lainnya.
                        </p>
                      </div>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="16"
                    className="button-collapse w-100 text-left"
                  >
                    Syarat Dan Ketentuan Top Up Unipin Di SpeedCash
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="16">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Sebelum top up unipin sebaiknya kamu perhatikan dulu syarat dan ketentuan
                        penggunaan SpeedCash disini :
                      </p>
                      <ol className="text-justify list-content">
                        <li>
                          Pastikan kembali voucher game yang kamu ingin kamu beli sudah benar, yaitu
                          voucher game UniPin.
                        </li>
                        <li>
                          SpeedCash tidak bertanggung jawab atas kesalahan pembelian dikarenakan
                          kesalahan pembelian voucher game UniPin oleh pengguna.
                        </li>
                        <li>
                          Pihak SpeedCash tidak pernah meminta email dan password akun pengguna.
                          jadi jangan pernah menginfokan data tersebut ke pihak manapun.
                        </li>
                        <li>
                          Harga voucher game unipin dapat berubah sewaktu-waktu tanpa pemberitahuan.
                        </li>
                        <li>
                          Pelanggan bertanggung jawab atas perlindungan dan kerahasiaan kode voucher
                          game UniPin. SpeedCash tidak bertanggung jawab terhadap segala bentuk
                          kerugian yang diderita oleh Pelanggan akibat kegagalan dalam melindungi
                          kerahasiaan kode voucher game UniPin.
                        </li>
                      </ol>
                      <p className="d-block text-justify mt-1">
                        Itulah tadi informasi mengenai top up unipin dan beli voucher game lainnya
                        melalui SpeedCash yang bisa kami bagikan, jika kamu merasa informasi ini
                        dibutuhkan maka kamu bisa membagikan info ini ke orang lain. Untuk lebih
                        mudah top up unipin bisa kamu lakukan melalui aplikasi juga loh!
                      </p>
                      <div className="mt-1 w-100 d-flex justify-content-center">
                        <h5 className="bold">
                          Top Up Unipin Dan Beli Voucher Game Lebih Mudah Di Aplikasi
                        </h5>
                      </div>
                      <Row className="d-flex w-100 justify-content-center mb-3 gap-3">
                        <a
                          aria-hidden
                          href="https://play.google.com/store/apps/details?id=com.bm.sc.bebasbayar&listing=speedcash_banner_app"
                          className="mx-1"
                        >
                          <Image
                            src="/img/playstore-speedcash.png"
                            data-target="playstore-speedcash"
                            width="220px"
                            height="60px"
                          />
                        </a>
                        <a
                          aria-hidden
                          href="https://apps.apple.com/id/app/speedcash-transfer-ewallet/id6459304122"
                          className="mx-1"
                        >
                          <Image
                            src="/img/appstore-speedcash.png"
                            data-target="appstore-speedcash"
                            width="220px"
                            height="60px"
                          />
                        </a>
                      </Row>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default AccordionGameUnipin
