import BaseMessage from './base'
import DateTime from '../../helper/datetime'
import Var from '../../helper/var'

export default class MPMessage extends BaseMessage {
  static INCLUDES = {
    PRODUCT_CODE: 'product_code',
    CUSTOMER_ID_1: 'customer_id_1',
    CUSTOMER_ID_2: 'customer_id_2',
    CUSTOMER_ID_3: 'customer_id_3',
    AMOUNT: 'amount',
    ADMIN: 'admin',
    BANK_ID: 'bank_id',
    REFF_ID: 'reff_id',
    BALANCE: 'balance',
    CASHBACK: 'cashback',
    FEE: 'fee',
    CURRENCY: 'currency',
    CUSTOMER_PHONE_NUMBER: 'customer_phone_number',
    CUSTOMER_NAME: 'customer_name',
    CUSTOMER_ADDRESS: 'customer_address',
    BILL_QUANTITY: 'bill_quantity',
    JENIS_STRUK: 'jenis_struk',
    BILLER_PRODUCT_CODE: 'biller_product_code',
    SETTING_MENU: 'setting_menu',
    DISCOUNT: 'discount',
    NOMINAL_TOTAL: 'nominal_total',
    TOKEN: 'token',
    PRODUCT_NAME: 'productName',
    VOUCHER_QTY: 'voucher_qty',
    VOUCHER_GROUP: 'voucher_group',
  }

  constructor(data) {
    super(data)

    if (!data) {
      this.data.transmission_datetime = DateTime.format(
        new Date(),
        DateTime.FMT_DATE_TIME_YMDHMS_NS
      )
      this.data.includes = {}
      this.data.additional_datas = Array(this.getAdditionalDataCapacity()).fill('')
      this.data.campaign = {
        utm_term: '',
        utm_user_id: '',
        utm_campaign: '',
        utm_name: '',
        utm_medium: '',
        utm_content: '',
        utm_source: '',
      }
    }
  }

  setRC(rc) {
    this.data.response_code = rc
  }

  getRC() {
    return Var.toString(this.data.response_code)
  }

  setRD(rd) {
    this.data.description = rd
  }

  getRD() {
    return Var.toString(this.data.description)
  }

  setOutletID(id) {
    this.data.outlet_id = id
  }

  getOutletID() {
    return Var.toString(this.data.outlet_id)
  }

  setPIN(pin) {
    this.data.pin = pin
  }

  getPIN() {
    return Var.toString(this.data.pin)
  }

  setToken(token) {
    this.data.token = token
  }

  getToken() {
    return Var.toString(this.data.token)
  }

  setMessageType(type) {
    this.data.msg_type = type
  }

  getMessageType() {
    return Var.toString(this.data.msg_type)
  }

  setProcessingCode(code) {
    this.data.processing_code = code
  }

  getProcessingCode() {
    return Var.toString(this.data.processing_code)
  }

  getBalance() {
    return Var.toFloat(this.getIncludeValue(MPMessage.INCLUDES.BALANCE))
  }

  setIncludeValue(key, value) {
    this.data.includes[key] = value
  }

  getIncludeValue(key) {
    return this.data.includes[key] || ''
  }

  setAdditionalDatum(index, value) {
    this.data.additional_datas[Var.toInt(index)] = value
  }

  getAdditionalDatum(index) {
    return this.data.additional_datas[Var.toInt(index)] || ''
  }

  // eslint-disable-next-line class-methods-use-this
  getAdditionalDataCapacity() {
    return 65
  }

  setCampaign(type, value) {
    this.data.campaign[type] = value
  }

  getCampaign(type) {
    return this.data.campaign[type] || ''
  }

  setCampaigns(value) {
    this.data.campaign = value
  }

  getCampaigns() {
    return this.data.campaign
  }

  setType(type) {
    this.data.type = type
  }

  setState(state) {
    this.data.state = state
  }

  setUploadDetail() {
    this.data.uploads_detail = []
  }

  setUploadDetailObject(index) {
    this.data.uploads_detail[index] = {}
  }

  setUploadDetailItem(index, key, value) {
    this.data.uploads_detail[index][key] = value
  }
}
