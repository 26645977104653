/* eslint-disable no-nested-ternary */
import _ from 'lodash'
import React, { useRef } from 'react'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import {
  Button,
  Form,
  InputGroup,
  Container,
  Jumbotron,
  Spinner,
  Image,
  Row,
  Col,
} from 'react-bootstrap'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { isMobile } from 'react-device-detect'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useHistory, useLocation } from 'react-router-dom'
import Alert from 'react-popup-alert'
import 'react-popup-alert/dist/index.css'
import DOMHelper from '../../../helper/domhelper'
import HookHelper from '../../../helper/hookhelper'
import Formatter from '../../../helper/formatter'

import './layanan.scss'
import globalConstant from '../../../store/constant/global'
import authConstant from '../../../store/constant/auth'
import SidebarComponent from '../../component/sidebar'
import HeaderComponent from '../../component/header'
import LoginModal from '../../dialog/auth/login'
import LastTrxDialog from '../../dialog/lastTransaction'
import GameModal from '../../dialog/layanan/game'
import AccordionGameFfComponent from '../../component/accordionGameFf'

import { gameProduk, gamePay } from '../../../api/action/layanan/game'
import { lastTransaction } from '../../../api/action/global'

const GameFfScreen = ({
  appUserId,
  appUserPin,
  appUserBalance,
  appUserIsEmbed,
  appIsLoading,
  dispatch,
}) => {
  const history = useHistory()
  const location = useLocation()
  const formGameRef = useRef(null)
  const [isOpen, setIsOpen] = React.useState(!isMobile)
  const [isInqWeb, setIsInqWeb] = React.useState(false)
  const [alertError, setAlertError] = React.useState('')
  const [loginModalShow, setLoginModalShow] = React.useState(false)
  const [gameModalShow, setGameModalShow] = React.useState(false)
  const [userId] = React.useState(!appUserId ? process.env.REACT_APP_USER_DEMO_ID : appUserId)
  const [userPin] = React.useState(
    !appUserId ? process.env.REACT_APP_USER_DEMO_PASSWORD : appUserPin
  )
  const [isSaldoOk, setIsSaldoOk] = React.useState(false)
  const [lastTrx, setLastTransaction] = React.useState([])
  const [lastTrxFiltered, setLastTransactionFiltered] = React.useState([])
  const [lastTrxShow, setLastTrxShow] = React.useState(false)
  const [searchLastTrx, setSearchLastTrx] = React.useState('')
  const [dataGames, setDataGames] = React.useState([])
  const [product, setProduct] = React.useState(null)
  const [provider, setProvider] = React.useState(null)
  const [providerProduct, setProviderProduct] = React.useState([])
  const [banner, setBanner] = React.useState('')
  const [customer, setCustomer] = React.useState('')
  const [discount] = React.useState('0')

  const toggleSidebar = React.useCallback(() => setIsOpen(!isOpen), [isOpen, setIsOpen])

  const closeError = React.useCallback(() => setAlertError(''), [])

  const confirmLoginModal = React.useCallback(async () => {
    setLoginModalShow(false)
    // window.location.reload()
  }, [setLoginModalShow])

  const closeLoginModal = React.useCallback(async () => {
    setLoginModalShow(false)
  }, [setLoginModalShow])

  const openLoginModal = React.useCallback(async () => {
    setLoginModalShow(true)
  }, [setLoginModalShow])

  const closeGameModal = React.useCallback(async () => {
    setGameModalShow(false)
  }, [setGameModalShow])

  const openLastTransaction = React.useCallback(async () => {
    setLastTrxShow(true)
  }, [setLastTrxShow])

  const closeLastTransaction = React.useCallback(async () => {
    setLastTrxShow(false)
  }, [setLastTrxShow])

  const handleClear = React.useCallback(
    (e) => {
      const { target } = e.currentTarget.dataset
      if (target === 'customer') {
        setCustomer('')
      }
      setIsInqWeb(false)
    },
    [setCustomer, setIsInqWeb]
  )

  const selectProvider = React.useCallback(
    async (e) => {
      setLastTransaction([])
      setLastTransactionFiltered([])
      const { id } = e.currentTarget.dataset
      const providerId = parseInt(id, 10)

      if (providerId === 119) {
        history.push('/game/top-up-ff')
      } else if (providerId === 106) {
        history.push('/game/top-up-ml')
      } else if (providerId === 112) {
        history.push('/game/top-up-uc-pubg')
      } else if (providerId === 65) {
        history.push('/game/top-up-unipin')
      } else if (providerId === 125) {
        history.push('/game/langganan-netflix')
      } else {
        history.push({
          pathname: '/game',
          state: {
            provider_id: providerId,
          },
        })
      }
    },
    [history]
  )

  const selectProduct = React.useCallback(
    (e) => {
      const { id } = e.currentTarget.dataset
      const [filteredProduct] = providerProduct.filter((g) => `${g.idproduk}` === id)
      if (filteredProduct.is_gangguan === '0') {
        setProduct(filteredProduct)
      } else {
        setAlertError({
          rc: '',
          rd: 'Produk yang Anda pilih sedang gangguan. Harap pilih produk yang lain.',
        })
      }
      setIsInqWeb(false)
    },
    [providerProduct, setProduct, setAlertError, setIsInqWeb]
  )

  const onCustomerChange = React.useCallback(
    (e) => {
      setCustomer(e.target.value)
    },
    [setCustomer]
  )

  const confirmLastTrx = React.useCallback(
    (e) => {
      const { target } = e.currentTarget.dataset
      setCustomer(target)
      setLastTrxShow(false)
    },
    [setCustomer, setLastTrxShow]
  )

  const onSearchLastTrxChange = React.useCallback(
    (e) => {
      const { value } = e.target
      const newData = _.filter(lastTrx, (item) => {
        return (
          _.includes(item.id_pelanggan, value) ||
          _.includes(item.nama_pelanggan.toLowerCase(), value.toLowerCase())
        )
      })

      setSearchLastTrx(value)
      setLastTransactionFiltered(newData)
    },
    [lastTrx, setSearchLastTrx, setLastTransactionFiltered]
  )

  const redirectToDeposit = React.useCallback(() => {
    history.push('/deposit')
  }, [history])

  const proccessGame = React.useCallback(async () => {
    if (!customer) {
      setAlertError({ rc: '', rd: 'User ID / Nomor HP harap diisi terlebih dahulu' })
      return false
    }

    if (!product) {
      setAlertError({ rc: '', rd: 'Harap pilih produk terlebih dahulu' })
      return false
    }

    if (Math.round(parseFloat(appUserBalance)) >= Math.round(parseFloat(product.hargajual))) {
      setIsSaldoOk(true)
    }

    if (isMobile) {
      setGameModalShow(true)
    } else {
      setIsInqWeb(true)
    }
    return true
  }, [
    appUserBalance,
    customer,
    product,
    setGameModalShow,
    setIsInqWeb,
    setIsSaldoOk,
    setAlertError,
  ])

  const confirmGame = React.useCallback(async () => {
    if (!appUserId) {
      setGameModalShow(false)
      setLoginModalShow(true)
    } else if (isSaldoOk) {
      const response = await dispatch(
        gamePay(userId, userPin, customer, discount, product.idproduk)
      )
      if (response.isOK()) {
        history.push({
          pathname: '/struk',
          state: {
            parent: 'game',
            parentPath: location.pathname,
            title: 'Voucher GAME',
            icon: 'gamepad',
            transaksi: response.data.includes.reff_id,
            trxDate: response.data.transmission_datetime,
            trxTime: response.data.transmission_datetime,
            total: response.data.includes.amount,
            customerId: response.data.includes.customer_id_1,
            discountInfo: response.data.discountInfo,
            info: 'sukses',
          },
        })
      } else if (
        response.getResponseCode() === '' ||
        response.getRC() === '68' ||
        response.getRC().toString() === response.data.includes.reff_id.toString()
      ) {
        history.push({
          pathname: '/struk',
          state: {
            parent: 'game',
            parentPath: location.pathname,
            title: 'Voucher GAME',
            icon: 'gamepad',
            transaksi: response.data.includes.reff_id,
            trxDate: response.data.transmission_datetime,
            trxTime: response.data.transmission_datetime,
            total: response.data.includes.amount,
            customerId: response.data.includes.customer_id_1,
            discountInfo: response.data.discountInfo,
            info: 'pending',
          },
        })
      } else {
        setAlertError({ rc: response.getRC(), rd: response.getRD() })
      }
    } else {
      redirectToDeposit()
    }
  }, [
    dispatch,
    appUserId,
    userId,
    userPin,
    customer,
    discount,
    product,
    history,
    location,
    isSaldoOk,
    setGameModalShow,
    setLoginModalShow,
    redirectToDeposit,
    setAlertError,
  ])

  React.useEffect(() => {
    DOMHelper.mountClass('layanan-layout')
    HookHelper.resetLoading()

    dispatch(gameProduk(userId)).then((response) => {
      if (response.isOK()) {
        const resDataProduk = response.getResultAsObject()

        const games = _.orderBy(
          resDataProduk,
          ['game_topup', 'prioritas', 'provider'],
          ['desc', 'asc', 'asc']
        )
        setDataGames(games)

        const [providerGame] = games.filter((g) => g.provider_id === 119)
        if (providerGame) {
          setProvider(providerGame)
          setProviderProduct(providerGame.products)

          if (providerGame.banner) {
            setBanner(providerGame.banner)
          } else if (providerGame.image) {
            setBanner(providerGame.image)
          } else {
            setBanner('/img/gamepad.png')
          }

          dispatch(lastTransaction(userId, '', '1', providerGame.provider_id)).then((res) => {
            if (res.isOK()) {
              setLastTransaction(res.getResultAsList())
              setLastTransactionFiltered(res.getResultAsList())
            }
          })
        }
      } else {
        setAlertError({ rc: response.getRC(), rd: response.getRD() })
      }
    })

    return () => DOMHelper.unmountClass(['layanan-layout'])
  }, [
    dispatch,
    appUserId,
    userId,
    location,
    setDataGames,
    setProvider,
    setProviderProduct,
    setBanner,
    setAlertError,
    setLastTransaction,
    setLastTransactionFiltered,
  ])

  return (
    <>
      <Helmet>
        <title>Top Up FF (Free Fire) Diamond Termurah Banyak Diskon Disini</title>
        <meta name="title" content="Top Up FF (Free Fire) Diamond Termurah Banyak Diskon Disini" />
        <meta
          name="description"
          content="Situs top up FF (free fire) diamond harga termurah.Beli voucher game online pakai pulsa.Banyak pilihan denom dan pasti ada diskon.100 diamond hanya 14.800."
        />
        <meta name="google-play-app" content="app-id=com.bm.sc.bebasbayar" />
        <meta
          name="_globalsign-domain-verification"
          content="VncJWgSU_cQw-hAuU0PELvI3g4HQ4l6XFSs9v-jA1Y"
        />
        <meta name="facebook-domain-verification" content="pknnsi25vbw0rfl1x8xctdtumrh3bs" />
        <meta name="msvalidate.01" content="9A9127824837BF1E05F5AED70295F281" />
        <meta name="alexaVerifyID" content="Nc8Q89T9GsncVlA5xukQcbi5rJ0" />
        <meta httpEquiv="X-UA-Compatible" content="IE=Edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="author" content="https://www.facebook.com/SpeedCash.BebasBayarID" />
        <link rel="canonical" href="https://member.speedcash.co.id/game/top-up-ff" />
        <meta
          name="robots"
          content="follow, index, max-snippet:-1, max-video-preview:-1, max-image-preview:large"
        />
        <meta name="geo.country" content="id" />
        <meta name="revisit-after" content="1 days" />
        <meta name="geo.placename" content="Indonesia" />
        <meta content="id_id" property="og:locale" />
        <meta
          itemProp="name"
          content="Top Up FF (Free Fire) Diamond Termurah Banyak Diskon Disini"
        />
        <meta
          itemProp="description"
          content="Situs top up FF (free fire) diamond harga termurah.Beli voucher game online pakai pulsa.Banyak pilihan denom dan pasti ada diskon.100 diamond hanya 14.800."
        />
        <meta itemProp="image" content="https://member.speedcash.co.id/img/banner-ff.png" />
        <meta property="fb:app_id" content="966242223397117" />
        <meta
          property="og:title"
          content="Top Up FF (Free Fire) Diamond Termurah Banyak Diskon Disini"
        />
        <meta property="og:type" content="article" />
        <meta property="og:image" content="https://member.speedcash.co.id/img/banner-ff.png" />
        <meta
          property="og:description"
          content="Situs top up FF (free fire) diamond harga termurah.Beli voucher game online pakai pulsa.Banyak pilihan denom dan pasti ada diskon.100 diamond hanya 14.800."
        />
        <meta property="og:site_name" content="SpeedCash" />
        <meta property="og:url" content="https://www.speedcash.co.id/" />
        <meta
          property="article:tag"
          content="Situs top up FF (free fire) diamond harga termurah.Beli voucher game online pakai pulsa.Banyak pilihan denom dan pasti ada diskon.100 diamond hanya 14.800."
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@SpeedcashO" />
        <meta
          name="twitter:title"
          content="Top Up FF (Free Fire) Diamond Termurah Banyak Diskon Disini"
        />
        <meta
          name="twitter:description"
          content="Situs top up FF (free fire) diamond harga termurah.Beli voucher game online pakai pulsa.Banyak pilihan denom dan pasti ada diskon.100 diamond hanya 14.800."
        />
        <meta name="twitter:creator" content="@SpeedcashO" />
        <meta name="twitter:image" content="https://member.speedcash.co.id/img/banner-ff.png" />
        <meta name="twitter:image:src" content="https://member.speedcash.co.id/img/banner-ff.png" />
        <meta name="twitter:label1" content="Deskripsi" />
        <meta
          name="twitter:data1"
          content="Situs top up FF (free fire) diamond harga termurah.Beli voucher game online pakai pulsa.Banyak pilihan denom dan pasti ada diskon.100 diamond hanya 14.800."
        />
        <meta name="twitter:label2" content="Tag" />
        <meta
          name="twitter:data2"
          content="Situs top up FF (free fire) diamond harga termurah.Beli voucher game online pakai pulsa.Banyak pilihan denom dan pasti ada diskon.100 diamond hanya 14.800."
        />

        <script type="application/ld+json">
          {`{
          "@context":"https://schema.org",
          "@graph":[{
          "@type":"Organization",
          "@id":"https://www.speedcash.co.id/#organization",
          "name":"PT Bimasakti Multi Sinergi",
          "url":"https://www.speedcash.co.id/",
          "sameAs":[
          "https://www.facebook.com/SpeedCash.BebasBayarID/",
          "https://www.instagram.com/speedcash.official/",
          "https://www.linkedin.com/in/aplikasi-bebasbayar-864aa7195/",
          "https://www.youtube.com/c/SpeedCashIDOfficial",
          "https://id.pinterest.com/bebasbayarofficial/",
          "https://www.tiktok.com/@speedcash.official",
          "https://twitter.com/SpeedcashO"],
          "logo":{
          "@type":"ImageObject",
          "@id":"https://www.speedcash.co.id/#logo",
          "url":"https://www.speedcash.co.id/images/speedcash-logo.png",
          "width":240,"height":72,"caption":"SpeedCash.co.id"},
          "image":{
          "@id":"https://www.speedcash.co.id/#logo"}
          },{
          "@type":"WebSite",
          "@id":"https://www.speedcash.co.id/#website",
          "url":"https://www.speedcash.co.id/",
          "name":"SpeedCash",
          "publisher":{
          "@id":"https://www.speedcash.co.id/#organization"},
          "potentialAction":{
          "@type":"SearchAction",
          "target":"https://www.speedcash.co.id/?s={search_term_string}",
          "query-input":"required name=search_term_string"}
          },{
          "@type":"ImageObject",
          "@id":"https://www.speedcash.co.id/#primaryimage",
          "url":"https://www.speedcash.co.id/images/speedcash-logo.png",
          "width":764,"height":401,
          "caption":"Top Up FF (Free Fire) Diamond Termurah Banyak Diskon Disini"
          },{
          "@type":"WebPage","@id":"https://www.speedcash.co.id/#webpage",
          "url":"https://www.speedcash.co.id/","inLanguage":"ID",
          "name":"Top Up FF (Free Fire) Diamond Termurah Banyak Diskon Disini",
          "isPartOf":{
          "@id":"https://www.speedcash.co.id/#website"},
          "about":{"@id":"https://www.speedcash.co.id/#organization"},
          "primaryImageOfPage":{"@id":"https://www.speedcash.co.id/#primaryimage"},
          "datePublished":"2024-05-20T03:08:35+00:00","dateModified":"2024-05-20T04:54:34+00:00",
          "description":"Situs top up FF (free fire) diamond harga termurah.Beli voucher game online pakai pulsa.Banyak pilihan denom dan pasti ada diskon.100 diamond hanya 14.800."
          }]
          }`}
        </script>

        {/* Google tag (gtag.js) */}
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-X7L58XGGSH" />
        <script>
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', 'G-X7L58XGGSH');
          `}
        </script>
      </Helmet>

      <Container fluid className="layanan-layout">
        {!appUserIsEmbed && (
          <Row className="header">
            <Col>
              <HeaderComponent title="Voucher Game" onToggle={toggleSidebar} />
            </Col>
          </Row>
        )}

        <Row>
          {!appUserIsEmbed && (
            <Col
              md={2}
              className={classNames('col-sidebar', { 'is-open': isOpen, 'is-mobile': isMobile })}
            >
              <SidebarComponent isOpen={isOpen} />
            </Col>
          )}

          <Col
            md={{
              span: appUserIsEmbed ? 12 : isOpen ? 10 : 12,
              offset: appUserIsEmbed ? 0 : isOpen ? 2 : 0,
            }}
          >
            <Jumbotron fluid className="banner game">
              <Row>
                <Col>
                  <Row>
                    <span className={classNames('banner-title', { 'is-mobile': isMobile })}>
                      <FontAwesomeIcon icon="gamepad" fixedWidth className="mr-3" />
                      Voucher Game
                    </span>
                  </Row>
                </Col>

                {!appUserId ? (
                  <Col lg={3} xs={12} md={3} style={{ margin: 'auto' }}>
                    <Button
                      className={classNames('button-login', { 'is-mobile': isMobile })}
                      type="button"
                      onClick={openLoginModal}
                    >
                      Daftar / Login
                    </Button>
                  </Col>
                ) : (
                  <Col lg={3} xs={12} md={3} style={{ margin: 'auto' }}>
                    <Row>
                      <Col lg={10} xs={10} md={10}>
                        <div className="banner-saldo">Saldo Anda</div>
                        <div className="banner-nominal">
                          Rp. {Formatter.price(Math.round(parseFloat(appUserBalance)))}
                        </div>
                      </Col>
                      <Col lg={2} xs={2} md={2}>
                        <span className="banner-title">
                          <FontAwesomeIcon icon="wallet" fixedWidth className="mr-3" />
                        </span>
                      </Col>
                    </Row>
                  </Col>
                )}
              </Row>
            </Jumbotron>

            <Col className="d-flex justify-content-center mb-3 p-3">
              <h2 className="text-dark text-justify">
                Top Up FF Termurah | Beli Diamond FF Murah Banyak Pilihan
              </h2>
            </Col>

            <div className="content-layanan" ref={formGameRef}>
              <Row className="justify-content-md-center">
                <Col lg={isInqWeb ? 7 : 8} xs={12} md={isInqWeb ? 7 : 8} className="bd-form">
                  {provider && (
                    <Row>
                      <Form className={classNames('form-pelanggan', { 'is-mobile': isMobile })}>
                        <Form.Group>
                          <Form.Label
                            className="form-label"
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                            }}
                          >
                            <span>User ID / Nomor HP</span>
                            <span className="color-primary" style={{ textAlign: 'end' }}>
                              {provider.provider}
                            </span>
                          </Form.Label>
                          <InputGroup className="mb-12">
                            <Form.Control
                              placeholder="Cth: 123456789"
                              name="customer"
                              value={customer}
                              onChange={onCustomerChange}
                              disabled={isInqWeb || gameModalShow}
                            />
                            <InputGroup.Append className="input-group-game">
                              <Image
                                width={48}
                                height={48}
                                src={banner}
                                rounded
                                fluid
                                style={{ marginRight: '5px' }}
                              />
                              <Image
                                className={classNames('form-icon-btn', { 'ic-mobile': isMobile })}
                                src="/icon/clear.png"
                                data-target="customer"
                                onClick={handleClear}
                              />
                            </InputGroup.Append>
                          </InputGroup>
                        </Form.Group>

                        <Row>
                          {providerProduct.map((value) => (
                            <Col
                              className="icon-col-game produk pointing"
                              xs={6}
                              md={4}
                              lg={3}
                              key={value.idproduk}
                              data-id={value.idproduk}
                              onClick={selectProduct}
                            >
                              <div
                                className={classNames('col-list-game produk pointing', {
                                  trouble: value.is_gangguan === '1',
                                  'selected-produk':
                                    value.idproduk === (product ? product.idproduk : ''),
                                })}
                              >
                                <div className="produk-body">
                                  {/* <span
                                  className={classNames('produk-label', {
                                    trouble: value.is_gangguan === '1',
                                    'is-mobile': isMobile,
                                    'selected-produk':
                                      value.idproduk === (product ? product.idproduk : ''),
                                  })}
                                >
                                  {`Rp. ${Formatter.price(value.hargajual)}`}
                                </span> */}
                                  <span
                                    className={classNames('produk-sub-label', {
                                      trouble: value.is_gangguan === '1',
                                      'is-mobile': isMobile,
                                      'selected-produk':
                                        value.idproduk === (product ? product.idproduk : ''),
                                    })}
                                  >
                                    {value.namaproduk}
                                  </span>
                                  {Math.round(value.nominalUp) > 0 && (
                                    <span
                                      className={classNames(
                                        'produk-sub-label color-primary txt-bold',
                                        {
                                          trouble: value.is_gangguan === '1',
                                          'is-mobile': isMobile,
                                          'selected-produk':
                                            value.idproduk === (product ? product.idproduk : ''),
                                        }
                                      )}
                                    >
                                      {`Diskon Rp. ${Formatter.price(Math.round(value.nominalUp))}`}
                                    </span>
                                  )}
                                </div>
                                <div
                                  className={classNames('produk-footer', {
                                    trouble: value.is_gangguan === '1',
                                    'selected-produk':
                                      value.idproduk === (product ? product.idproduk : ''),
                                  })}
                                >
                                  {Math.round(value.nominalUp) > 0 &&
                                    Math.round(value.hargajual) > 0 && (
                                      <span
                                        className={classNames('produk-label-footer', {
                                          'is-mobile': isMobile || isInqWeb,
                                          'is-diskon': true,
                                        })}
                                      >
                                        {`Rp ${Formatter.price(
                                          Math.round(value.hargajual) + Math.round(value.nominalUp)
                                        )}`}
                                      </span>
                                    )}
                                  <span
                                    className={classNames('produk-label-footer', {
                                      'is-mobile': isMobile || isInqWeb,
                                    })}
                                  >
                                    {value.hargajual > 0
                                      ? `Rp ${Formatter.price(Math.round(value.hargajual))}`
                                      : ' '}
                                  </span>
                                </div>
                              </div>
                            </Col>
                          ))}
                        </Row>
                      </Form>
                      <Col
                        style={{ display: 'flex', justifyContent: 'flex-end', paddingTop: '10px' }}
                      >
                        <Button
                          variant="warning"
                          className="btn-next"
                          size="lg"
                          onClick={proccessGame}
                          disabled={appIsLoading}
                          style={{ textTransform: 'none', width: '50%' }}
                        >
                          {appIsLoading && !isMobile && (
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />
                          )}
                          {appIsLoading && !isMobile ? ' Mohon Tunggu...' : 'Lanjutkan'}
                        </Button>
                      </Col>

                      <hr />
                    </Row>
                  )}
                  {lastTrx.length > 0 && appUserId && (
                    <div className="form-last-trx">
                      <span className="form-label">Transaksi Terakhir</span>
                      <Row
                        style={{
                          border: '1px solid #DDDDDD',
                          borderRadius: '10px',
                          alignItems: 'center',
                          padding: '10px',
                        }}
                      >
                        <Col lg={1} xs={2} md={1}>
                          <Image src={lastTrx[0].image_url} width={36} />
                        </Col>
                        <Col
                          lg={8}
                          xs={10}
                          md={8}
                          data-target={lastTrx[0].id_pelanggan}
                          onClick={confirmLastTrx}
                          className="pointing"
                        >
                          <Row>
                            <span className="form-label-main">{lastTrx[0].id_pelanggan}</span>
                          </Row>
                          {lastTrx[0].id_pelanggan !== lastTrx[0].nama_pelanggan ? (
                            <Row>
                              <span className="label-text">{lastTrx[0].nama_pelanggan}</span>
                            </Row>
                          ) : (
                            ''
                          )}
                        </Col>
                        <Col lg={3} xs={8} md={3} onClick={openLastTransaction}>
                          <div className="label-text color-primary pointing">
                            Lihat riwayat transaksi
                          </div>
                        </Col>
                      </Row>
                    </div>
                  )}
                  <Row style={{ padding: '20px' }}>
                    {dataGames.map((item) => (
                      <Col
                        className="cl-list-game pointing"
                        xs={6}
                        md={4}
                        lg={3}
                        key={item.provider_id}
                        data-id={item.provider_id}
                        onClick={selectProvider}
                      >
                        <Row
                          className={classNames('ct-list-game', {
                            'selected-produk':
                              item.provider_id === (provider ? provider.provider_id : ''),
                          })}
                        >
                          <Row className="justify-content-md-center row-ct-list-game">
                            <Col>
                              <Image
                                className="image-logo"
                                src={
                                  // eslint-disable-next-line no-nested-ternary
                                  item.banner
                                    ? item.banner
                                    : item.image
                                    ? item.image
                                    : '/img/gamepad.png'
                                }
                                fluid
                              />
                            </Col>
                          </Row>
                          <Row className="justify-content-md-center row-provider">
                            <Col className="subcol-label">{item.provider}</Col>
                          </Row>
                        </Row>
                      </Col>
                    ))}
                  </Row>
                </Col>
                {isInqWeb && (
                  <>
                    <Col md="auto" />
                    <Col lg={4} xs={12} md={4} className="bd-form-inq">
                      <Row className="justify-content-md-center">
                        <Image className="image-logo" src={banner} rounded fluid />
                      </Row>
                      <Row>
                        <Col>Nama Provider</Col>
                        <Col className="inq-end-label">{provider ? provider.provider : ''}</Col>
                      </Row>
                      <Row>
                        <Col>Nama Produk</Col>
                        <Col className="inq-end-label">{product ? product.namaproduk : ''}</Col>
                      </Row>
                      <Row>
                        <Col>ID Tujuan</Col>
                        <Col className="inq-end-label">{customer || ''}</Col>
                      </Row>
                      <Row>
                        <Col>Harga</Col>
                        <Col className="inq-end-label">
                          {product
                            ? `Rp. ${Formatter.price(
                                Math.round(product.hargajual) + Math.round(product.nominalUp)
                              )}`
                            : ''}
                        </Col>
                      </Row>
                      {product && Math.round(product.nominalUp) > 0 && (
                        <Row>
                          <Col>Diskon</Col>
                          <Col className="inq-end-label color-primary txt-bold">
                            {product
                              ? `-Rp. ${Formatter.price(Math.round(product.nominalUp))}`
                              : ''}
                          </Col>
                        </Row>
                      )}
                      <Row className="inq-body-last">
                        <Col>Total Bayar</Col>
                        <Col className="inq-end-label txt-bold">
                          {product ? `Rp. ${Formatter.price(product.hargajual)}` : 'Rp. 0'}
                        </Col>
                      </Row>
                      <Row className="justify-content-md-center inq-body-last">
                        <Col className="inq-balance">
                          <Row style={{ display: 'flex', alignItems: 'center' }}>
                            <Col lg={2} xs={2} md={2}>
                              <Image src="/img/sc.png" roundedCircle />
                            </Col>
                            <Col lg={5} xs={5} md={5}>
                              Saldo Speedcash
                            </Col>
                            <Col lg={5} xs={5} md={5} className="inq-end-label txt-bold">
                              {appUserBalance
                                ? `Rp ${Formatter.price(Math.round(parseFloat(appUserBalance)))}`
                                : 'Rp 0,-'}
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      {!isSaldoOk && appUserId && (
                        <Row className="justify-content-md-center inq-body-last">
                          <Col className="inq-balance not-ok">
                            <Row className="txt-bold">
                              Saldo Anda belum mencukupi untuk transaksi ini. Segera Top Up
                            </Row>
                          </Col>
                        </Row>
                      )}
                      <Row className="justify-content-md-center">
                        <Col>
                          <Button
                            className="btn-process"
                            type="button"
                            block
                            onClick={
                              appUserId
                                ? isSaldoOk
                                  ? confirmGame
                                  : redirectToDeposit
                                : openLoginModal
                            }
                          >
                            {appIsLoading && (
                              <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                              />
                            )}
                            {appIsLoading
                              ? ' Mohon Tunggu...'
                              : appUserId
                              ? isSaldoOk
                                ? 'Bayar Sekarang'
                                : 'Top Up Saldo'
                              : 'Daftar / Login'}
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                  </>
                )}
              </Row>
            </div>

            <AccordionGameFfComponent />
          </Col>
        </Row>
        <Alert
          header="Perhatian"
          btnText="OK"
          text={alertError.rd}
          type="error"
          show={!!alertError.rd}
          onClosePress={closeError}
          showBorderBottom
          alertStyles={{ justifyContent: 'center' }}
        />
        <LoginModal
          show={!!loginModalShow}
          onHide={closeLoginModal}
          onConfirm={confirmLoginModal}
        />
        <LastTrxDialog
          show={!!lastTrxShow}
          onHide={closeLastTransaction}
          onConfirm={confirmLastTrx}
          search={searchLastTrx}
          onSearchChange={onSearchLastTrxChange}
          data={lastTrxFiltered}
        />
        <GameModal
          show={!!gameModalShow}
          onHide={closeGameModal}
          onConfirm={confirmGame}
          customer={customer}
          provider={provider}
          product={product}
          banner={banner}
          isSaldoOk={isSaldoOk}
        />
      </Container>
    </>
  )
}

GameFfScreen.defaultProps = {
  appIsLoading: false,
  appUserId: '',
  appUserPin: '',
  appUserBalance: 0,
  appUserIsEmbed: false,
}

GameFfScreen.propTypes = {
  appIsLoading: PropTypes.bool,
  appUserId: PropTypes.string,
  appUserPin: PropTypes.string,
  appUserBalance: PropTypes.number,
  appUserIsEmbed: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
}

export default connect(
  (state) => ({
    appIsLoading: state.Global[globalConstant.IS_LOADING],
    appUserId: state.Auth[authConstant.USER_ID],
    appUserPin: state.Auth[authConstant.USER_PIN],
    appUserBalance: state.Auth[authConstant.USER_BALANCE],
    appUserIsEmbed: state.Auth[authConstant.USER_IS_EMBED],
  }),
  (dispatch) => ({ dispatch })
)(GameFfScreen)
