import LayananMessage from '../../message/mp/layanan'
import ReportsMessage from '../../message/mp/reports'
import MessageSender from '../../sender'
import BaseMessage from '../../message/base'
import DateTime from '../../../helper/datetime'
import authConstant from '../../../store/constant/auth'

export const reportTrxList = (userId, startDate, endDate, limit, offset, tipe) => async () => {
  const msg = new ReportsMessage()
  if (tipe === 'sukses') {
    msg.setPathSuccess()
  } else if (tipe === 'gagal') {
    msg.setPathFailed()
  } else {
    msg.setPathAll()
  }
  msg.setUserId(userId)
  msg.setStartDate(startDate)
  msg.setEndDate(endDate)
  msg.setLimit(limit)
  msg.setOffset(offset)
  msg.data.timestamp = new Date().getTime()
  const response = await new MessageSender().doGet(msg)

  return new ReportsMessage(response)
}

export const reportMutasi = (userId, startDate, endDate, limit, offset) => async () => {
  const msg = new ReportsMessage()
  msg.setPath(`${BaseMessage.MP_NODE}/dashboard/mutasi`)
  msg.setIdUser(userId)
  msg.setDateStart(startDate)
  msg.setDateEnd(endDate)
  msg.setLimit(limit)
  msg.setOffset(offset)
  msg.data.timestamp = new Date().getTime()
  const response = await new MessageSender().doGet(msg)

  return new ReportsMessage(response)
}

export const resumeTrxDaily = (userId) => async () => {
  const msg = new ReportsMessage()
  msg.setPathResumeTrx()
  msg.setUserId(userId)
  msg.setStartDate(DateTime.today(DateTime.FMT_DATE_YMD))
  msg.setEndDate(DateTime.today(DateTime.FMT_DATE_YMD))
  msg.data.timestamp = new Date().getTime()
  const response = await new MessageSender().doGet(msg)

  return new ReportsMessage(response)
}

export const resumeTrxMonth = (userId) => async () => {
  const msg = new ReportsMessage()
  msg.setPathResumeTrxAll()
  msg.setUserId(userId)
  msg.setStartDate(DateTime.startOfMonth(DateTime.FMT_DATE_YMD))
  msg.setEndDate(DateTime.endOfMonth(DateTime.FMT_DATE_YMD))
  msg.data.timestamp = new Date().getTime()
  const response = await new MessageSender().doGet(msg)

  return new ReportsMessage(response)
}

export const reportCuTrx = (outletId, pin, idTrx) => async (dispatch) => {
  const msg = new LayananMessage()
  msg.setProcessingCode('ADMTRASI')
  msg.setOutletID(outletId)
  msg.setPIN(pin)
  msg.data.includes.product_code = 'CU'
  msg.data.includes.reff_id = idTrx
  msg.setAdditionalDatum(0, '0')

  const response = await new MessageSender().doPost(msg)
  const msg2 = new LayananMessage(response)

  if (msg2.isOK()) {
    await dispatch({
      type: authConstant.USER_BALANCE,
      payload: Number(msg2.data.includes.balance),
    })
  }

  return msg2
}
