/* eslint-disable no-duplicate-case */
import produkConstant from '../constant/produk'

const initialState = {
  [produkConstant.PRODUK_PULSA]: '',
  [produkConstant.PRODUK_GAME]: '',
  [produkConstant.PRODUK_PAYMENT]: '',
  [produkConstant.PRODUK_CASH]: '',
  [produkConstant.PRODUK_PLN]: '',
  [produkConstant.PRODUK_BANK]: '',
  [produkConstant.PRODUK_BANK_VA]: '',
  [produkConstant.PRODUK_VA]: [],
  [produkConstant.PRODUK_TAGIHAN]: null,
  [produkConstant.PROD_KOMISI]: [],
  [produkConstant.PROD_KOMISI_PREMIUM]: [],
}

export default (state = initialState, action) => {
  switch (action.type) {
    case produkConstant.PRODUK_PULSA:
    case produkConstant.PRODUK_GAME:
    case produkConstant.PRODUK_PAYMENT:
    case produkConstant.PRODUK_CASH:
    case produkConstant.PRODUK_PLN:
    case produkConstant.PRODUK_BANK:
    case produkConstant.PRODUK_BANK_VA:
    case produkConstant.PRODUK_VA:
    case produkConstant.PRODUK_TAGIHAN:
    case produkConstant.PROD_KOMISI:
    case produkConstant.PROD_KOMISI_PREMIUM:
      return {
        ...state,
        [action.type]: action.payload,
      }
    case produkConstant.PRODUK:
      return {
        ...state,
        ...action.payload,
      }
    case produkConstant.PRODUK_CLEAR:
      return initialState
    default:
      return state
  }
}
