import React from 'react'
import { Button, Image, Row, Col, Accordion, Card } from 'react-bootstrap'
import './accordion.scss'

const AccordionKkBni = () => {
  return (
    <>
      <Col className="d-flex justify-content-center mt-4 mb-3 p-3">
        <h3 className="text-dark text-justify">
          Cara Cek Tagihan Kartu Kredit BNI Dan Bayar Langsung Pakai SpeedCash
        </h3>
      </Col>

      <div style={{ padding: '0px 20px 30px 20px' }}>
        <Row className="justify-content-md-center">
          <Col lg={8} xs={12} md={8} className="content-component">
            <div>
              <Row className="d-flex">
                <Row className="d-flex justify-content-center">
                  <Image
                    src="/img/cek-dan-bayar-tagihan-kartu-kredit-BNI-pasti-diskon-cek-sekarang.png"
                    data-target="cek-dan-bayar-tagihan-kartu-kredit-BNI-pasti-diskon-cek-sekarang"
                    alt="Cek Tagihan Indihome"
                    width="100%"
                  />
                </Row>
                <p className="d-block text-justify mt-3">
                  Aktivitas rutin setiap awal bulan yang banyak dilakukan orang setelah mereka
                  menerima gaji salah satunya ialah melihat total tagihan kartu kredit dan
                  membayarnya langsung.Salah satu bank BUMN yang dipercaya masyarakat dalam
                  penggunaan kredit bulanan ialah BNI (bank nasional indonesia) sehingga otamatis
                  bagi pelanggan atau nasabah kartu kredit bank BNI akan melakukan{' '}
                  <a href="https://member.speedcash.co.id/kartukredit/bni">
                    cek tagihan kartu kredit BNI
                  </a>{' '}
                  setiap bulannya.Tidak ketinggalan juga nasabah pasti mengingkan diskon bayar
                  tagihan kartu kredit BNI agar lebih hemat dalam pengeluaran.
                </p>
                <Row className="d-flex mt-4 mb-2 justify-content-center text-dark w-100">
                  <h4> Cek Tagihan Kartu Kredit BNI Tanpa Aplikasi Di SpeedCash</h4>
                </Row>
                <Row>
                  <p className="d-block text-justify">
                    Penting untuk dilakukan semua nasabah kartu kredit sebelum melakukan pembayaran
                    bulanan,begini cara cek tagihan kartu kredit BNI dan bayar langsung di SpeedCash
                    agar dapat diskon :
                  </p>
                </Row>
                <Row className="d-flex justify-content-start">
                  <ol className="text-justify list-content">
                    <li>
                      Kunjungi alamat :{' '}
                      <a href="https://member.speedcash.co.id/kartukredit/bni">
                        https://member.speedcash.co.id/kartukredit/bni
                      </a>
                    </li>
                    <li>Masukkan nomor hp yang aktif untuk login di halaman web SpeedCash</li>
                    <li>Jika sudah bisa login, maka lanjutkan dengan klik menu kartu kredit</li>
                    <li>Lalu pilih produk kartu kredit BNI (maka disitu tertera diskon Rp.950)</li>
                    <li>Lanjutkan dengan masukkan nomor kartu kredit</li>
                    <li>Masukkan nominal yang akan dibayar</li>
                    <li>Lalu klik cek tagihan kartu kredit sekarang</li>
                  </ol>
                </Row>
              </Row>
            </div>
          </Col>

          <Col lg={8} xs={12} md={8} className="accordion-bpjs">
            <Accordion className="m-0 p-0">
              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="1"
                    className="button-collapse w-100 text-left"
                  >
                    Cek Tagihan Kartu Kredit BNI Dan Bayar Langsung Di Aplikasi SpeedCash
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="1">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Ternyata untuk membayar tagihan kartu kredit / mengecek tagihan kartu kredit
                        BNI tidak hanya bisa dilakukan via web (tanpa aplikasi) saja tetapi bagi
                        kalian yang ingin mendapatkan kemudahan dalam pembayaran dapat dilakukan
                        melalui aplikasi SpeedCash.Begini cara cek tagihan kartu kredit dan bayar
                        online via aplikasi dapat diskon :
                      </p>
                      <Row className="mt-3">
                        <ul className="text-justify list-content">
                          <li>
                            Pertama download dulu aplikasi{' '}
                            <a href="https://www.speedcash.co.id/">SpeedCash</a> yang sudah tersedia
                            di playstore maupun appstore
                          </li>
                          <li>
                            Jika sudah download maka login menggunakan no hp aktif (biasanya ada
                            verifikasi yang dikirimkan via WA)
                          </li>
                          <li>
                            <p>Selanjutnya maka akan keluar tampilan home aplikasi seperti ini</p>
                            <Row className="d-flex justify-content-center">
                              <Image
                                src="/img/cek-tagihan-kk.png"
                                data-target="cek-tagihan-kk"
                                width="220px"
                                height="400px"
                              />
                            </Row>
                          </li>
                          <li>
                            <p>Jika sudah lanjutkan dengan klik menu KARTU KREDIT</p>
                            <Row className="d-flex justify-content-center">
                              <Image
                                src="/img/kk.png"
                                data-target="kartu-kredit"
                                width="220px"
                                height="400px"
                              />
                            </Row>
                          </li>
                          <li>
                            <p>Pertama pilih produk kartu kredit</p>
                            <Row className="d-flex justify-content-center">
                              <Image
                                src="/img/kk-list.png"
                                data-target="kartu-kredit-list"
                                width="220px"
                                height="400px"
                              />
                            </Row>
                          </li>
                          <li>JIka sudah lanjutkan dengan memasukan nomor kartu kreditmu</li>
                          <li>Lalu masukkan nominal tagihan kartu kredit BNI bulan ini</li>
                          <li>Jika sudah lengkap semua lalu klik LANJUTKAN</li>
                        </ul>
                      </Row>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="2"
                    className="button-collapse w-100 text-left"
                  >
                    Simak Video Cek Tagihan Kartu Kredit Di Aplikasi SpeedCash Berikut ini
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="2">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Jika kamu masih bingung dengan tutorial atau langkah diatas, kamu dapat
                        melihat video berikut ini untuk lebih jelasnya.
                      </p>
                      <iframe
                        width="100%"
                        height="480"
                        src="https://www.youtube.com/embed/5edoMyg8y3Q"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        frameBorder={0}
                        title="Cara Cek Tagihan Kartu Kredit Di Aplikasi SpeedCash"
                        className="my-3"
                      />
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="3"
                    className="button-collapse w-100 text-left"
                  >
                    Mengapa Bayar Tagihan Bulanan Pakai SpeedCash
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="3">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Ternyata SpeedCash tidak hanya bisa untuk bayar tagihan kartu kredit saja
                        loh, tetapi SpeedCash bisa juga digunakan untuk{' '}
                        <a href="https://member.speedcash.co.id/pln/cek-tagihan-listrik">
                          cek tagihan listrik bulanan
                        </a>
                        ,<a href="https://member.speedcash.co.id/game">top up diamond</a> game ff
                        dan ml dan ada beberapa manfaat menggunakan SpeedCash lainnya yang harus
                        kamu ketahui, antara lain :
                      </p>
                      <Row className="mt-1">
                        <ol className="text-justify list-content">
                          <li>SpeedCash bisa digunakan untuk beli tiket kereta api</li>
                          <li>Bisa juga untuk transfer bank bebas biaya admin</li>
                          <li>Beli tiket pesawat dengan garansi harga paling murah</li>
                          <li>Hingga bayar pajak kendaraan (samsat) </li>
                        </ol>
                      </Row>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="4"
                    className="button-collapse w-100 text-left"
                  >
                    Kendala Yang Dihadapi Saat Mengecek Tagihan Kartu Kredit Di SpeedCash
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="4">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Biasanya saat kamu akan melihat total tagihan kartu kredit dan melakukan
                        pembayaran ada hal-hal yang menjadi kendala, Saat menemui kendala ini
                        muncul, Kamu bisa mencoba melakukan pembayaran ulang beberapa menit
                        kemudian. Jika masih gagal, Anda bisa mencoba langsung menghubungi customer
                        care SpeedCash melalui live chat di aplikasi ataupun menghubungi{' '}
                        <a href="mailto:support@speedcash.co.id">support@speedcash.co.id</a>.
                      </p>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="5"
                    className="button-collapse w-100 text-left"
                  >
                    Cara Mengisi Saldo SpeedCash Untuk Pembayaran Tagihan Kartu Kredit BNI
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="5">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Sebelum kamu melakukan bayar tagihan kartu kredit menggunakan SpeedCash, hal
                        lain yang wajib kamu ketahui ialah kamu harus mengisi saldo SpeedCash
                        terlebih dahulu.Dan untuk mengisi saldo SpeedCash juga sangat mudah berikut
                        caranya :
                      </p>
                      <ul className="text-justify list-content">
                        <li>Buka aplikasi SpeedCash</li>
                        <li>Klik top up di pojok kanan atas</li>
                        <li>Tersedia 4 metode pembayaran</li>
                        <li>Pilih Salah satu metode, misal pilih transfer manual</li>
                        <li>lalu masukkan nominal saldo yang akan kamu top up</li>
                        <li>lalu pilih bank tujuan transfer, misal pilih BNI </li>
                        <li>
                          Salin nomor rekening dan nominal transfer (pastikan nominal transfer harus
                          sesuai dengan 3 digit trakhir)
                        </li>
                        <li>
                          Jika sudah kamu bisa lanjutkan dengan membuka aplikasi m-banking mandiri/
                          kunjungi ATM terdekat ya
                        </li>
                        <li>
                          Jika sudah kamu transfer dengan benar, lalu kamu bisa klik di aplikasi
                          SpeedCash tulisan “ Saya Sudah Transfer “
                        </li>
                      </ul>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="6"
                    className="button-collapse w-100 text-left"
                  >
                    Info Mengenai Bank BNI
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="6">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        PT Bank Negara Indonesia (Persero), Tbk adalah bank sentral yang didirikan
                        di Indonesia dan diberi nama Bank Negara Indonesia sesuai dengan peraturan
                        pemerintah menggantikan Undang-Undang Nomor 1.2 Tahun 1946 tanggal 5 Juli
                        1946. Setelah itu, Bank Negara Indonesia berubah nama menjadi Bank Negara
                        Indonesia pada tahun 1946 dan juga berubah status menjadi bank umum milik
                        negara. Hingga tahun 1992, BNI bertransformasi menjadi perusahaan perseroan
                        terbatas (persero). BNI merupakan salah satu bank umum (badan usaha milik
                        negara) dan juga bank umum pertama yang menjadi badan usaha milik negara
                        setelah mencatatkan saham di Bursa Efek Jakarta dan Bursa Efek Surabaya pada
                        tahun 1996. Sampai saat ini, saham-saham rekening BNI 60% dari total saham
                        dimiliki oleh Pemerintah Republik Indonesia, sedangkan 40% sisanya dimiliki
                        oleh publik. Dilihat dari total asetnya, BNI merupakan bank nasional
                        terbesar keempat di Indonesia.
                      </p>
                      <p className="d-block text-justify mt-1">
                        Untuk memaksimalkan pelayanan, BNI tentunya memiliki standar yang optimal
                        dari segala aspek, termasuk membentuk kesadaran akan visi dan misi. Dengan
                        visi menjadi lembaga keuangan yang unggul dalam pelayanan dan kinerja, BNI
                        juga mempunyai beberapa misi untuk memberikan layanan prima dan solusi
                        bernilai tambah kepada setiap nasabah, sekaligus menjadi mitra pilihan
                        utama: Meningkatkan nilai investasi luar biasa bagi investor; Menciptakan
                        kondisi terbaik bagi karyawan adalah kebanggaan dalam bekerja dan sukses;
                        Meningkatkan kesadaran dan tanggung jawab terhadap lingkungan dan
                        masyarakat; Menjadi tolok ukur dalam penerapan kepatuhan dan tata kelola
                        perusahaan yang baik.
                      </p>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="7"
                    className="button-collapse w-100 text-left"
                  >
                    Cara Cek Cicilan Kartu Kredit Melalui ATM BNI
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="7">
                  <Card.Body className="card-content">
                    <Row>
                      <ul className="text-justify list-content">
                        <li>Kunjungi ATM BNI terdekat dengan tempat tinggal kamu.</li>
                        <li>
                          Pada menu utama ATM, segera lakukan proses pembayaran dengan memasukkan
                          kartu ATM BNI ke dalam mesin ATM.
                        </li>
                        <li>Masukkan nomor PIN kamu.</li>
                        <li>Pilih menu Pembayaran.</li>
                        <li>Pilih menu Kartu Kredit.</li>
                        <li>Tentukan nama bank penerbit Kartu Kredit yang kamu gunakan.</li>
                        <li>Masukkan nomor Kartu Kredit kamu.</li>
                        <li>Dan ikuti langkah-langkah seterusnya</li>
                      </ul>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="8"
                    className="button-collapse w-100 text-left"
                  >
                    Cek Tagihan Kartu Kredit BNI Via SMS
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="8">
                  <Card.Body className="card-content">
                    <Row>
                      <ul className="text-justify list-content">
                        <li>Kunjungi ATM BNI terdekat</li>
                        <li>Masukkan kartu ATM dan pilih Registrasi e-Channel</li>
                        <li>Pilih BNI SMS Banking</li>
                        <li>Masukkan nomor HP</li>
                        <li>Masukkan nomor PIN BNI SMS Banking sebanyak 6 digit</li>
                      </ul>
                      <p className="d-block text-justify mt-1">
                        Apabila berhasil, struk akan keluar dari mesin ATM dan Anda bakal menerima
                        SMS dari nomor 3346. Selanjutnya, Anda bisa melakukan cek tagihan kartu
                        kredit dengan mengirimkan pesan ke 3346 dengan format: (VISA/MASTER/JCB)
                        (spasi) TAG (spasi) 4 Digit Terakhir Kartu. Namun, perlu diketahui bahwa
                        layanan ini tidaklah gratis alias memakan pulsa. Jadi, pastikan pulsa Anda
                        cukup ketika ingin melakukan pengecekan tagihan kartu kredit.
                      </p>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="9"
                    className="button-collapse w-100 text-left"
                  >
                    Cara cek tagihan kartu kredit BNI via UMB
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="9">
                  <Card.Body className="card-content">
                    <Row>
                      <ul className="text-justify list-content">
                        <li>Buka menu Panggilan/Call di ponsel</li>
                        <li>Ketik *141#, lalu ikuti instruksi selanjutnya</li>
                        <li>Pilih Kartu Kredit</li>
                        <li>Masukkan nomor kartu kredit BNI</li>
                      </ul>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="10"
                    className="button-collapse w-100 text-left"
                  >
                    cek tagihan kartu kredit BNI via BNI Call
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="10">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Layanan ini tersedia 24 jam. Anda bisa menghubungi BNI Call di nomor
                        1500046. Sama seperti SMS Banking, saat menghubungi BNI Call, pulsa Anda
                        akan berkurang sesuai dengan tarif yang ditentukan layanan seluler yang
                        bersangkutan.
                      </p>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="11"
                    className="button-collapse w-100 text-left"
                  >
                    Customer Service Bank BNI
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="11">
                  <Card.Body className="card-content">
                    <Row>
                      <div className="w-100">
                        <p className="d-block text-justify">Telpon PSTN : 1500046</p>
                      </div>
                      <div className="w-100 my-1">
                        <p className="d-block text-justify">Telpon seluler : 1500046</p>
                      </div>
                      <div className="w-100 my-1">
                        <p className="d-block text-justify">
                          Email : <a href="mailto:bnicall@bni.co.id">bnicall@bni.co.id</a>
                        </p>
                      </div>
                      <div className="w-100 my-1">
                        <p className="d-block text-justify">Instagram: @bni46</p>
                      </div>
                      <div className="w-100 my-1">
                        <p className="d-block text-justify">twitter: @BNI, @BNICustomerCare</p>
                      </div>
                      <div className="w-100 my-1">
                        <p className="d-block text-justify"> Facebook: BNI</p>
                      </div>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="12"
                    className="button-collapse w-100 text-left"
                  >
                    Kantor Pusat Bank BNI
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="12">
                  <Card.Body className="card-content">
                    <Row>
                      <div className="d-block text-justify w-100 mb-1">
                        <iframe
                          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15865.823624844135!2d106.8212754!3d-6.2034327!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69f5d7d988fb31%3A0x95910354891f84c0!2sGrha%20BNI!5e0!3m2!1sid!2sid!4v1711079459864!5m2!1sid!2sid"
                          width="100%"
                          height="450"
                          style={{ border: '0' }}
                          allowfullscreen=""
                          loading="lazy"
                          referrerPolicy="no-referrer-when-downgrade"
                          title="Kantor Pusat Bank BNI"
                        />
                      </div>
                      <p className="d-block text-justify mt-1">
                        Itulah tadi informasi mengenai cek tagihan kartu kredit BNI dan bayar online
                        langsung pasti dapat diskon jika menggunakan SpeedCash.Jika kamu merasa
                        tertarik untuk menggunakan aplikasi SpeedCash kamu bisa langsung mengunduh
                        melalui link dibawah ini.
                      </p>
                      <div className="mt-1 w-100 d-flex justify-content-center">
                        <h5 className="bold">Lebih Hemat Bayar Tagihan Via Aplikasi</h5>
                      </div>
                      <Row className="d-flex w-100 justify-content-center mb-3 gap-3">
                        <a
                          aria-hidden
                          href="https://play.google.com/store/apps/details?id=com.bm.sc.bebasbayar&listing=speedcash_banner_app"
                          className="mx-1"
                        >
                          <Image
                            src="/img/playstore-speedcash.png"
                            data-target="playstore-speedcash"
                            width="220px"
                            height="60px"
                          />
                        </a>
                        <a
                          aria-hidden
                          href="https://apps.apple.com/id/app/speedcash-transfer-ewallet/id6459304122"
                          className="mx-1"
                        >
                          <Image
                            src="/img/appstore-speedcash.png"
                            data-target="appstore-speedcash"
                            width="220px"
                            height="60px"
                          />
                        </a>
                      </Row>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default AccordionKkBni
