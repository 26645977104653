import React from 'react'
import { Button, Image, Row, Col, Accordion, Card } from 'react-bootstrap'
import './accordion.scss'

const AccordionFif = () => {
  return (
    <>
      <Col className="d-flex justify-content-center mt-4 mb-3 p-3">
        <h3 className="text-dark text-justify">Cara Cek Angsuran FIF | Bayar Langsung Disini</h3>
      </Col>

      <div style={{ padding: '0px 20px 30px 20px' }}>
        <Row className="justify-content-md-center">
          <Col lg={8} xs={12} md={8} className="content-component">
            <div>
              <Row className="d-flex justify-content-center">
                <Image
                  src="/img/Bayar-Angsuran-FIF-Group-Diskon-Langsung-Disini.png"
                  data-target="Bayar-Angsuran-FIF-Group-Diskon-Langsung-Disini"
                  alt="Bayar-Angsuran-FIF-Group-Diskon-Langsung-Disini"
                  width="100%"
                />
              </Row>
              <Row className="d-flex justify-content-center mt-3">
                <p className="d-block text-justify">
                  Angsuran FIF merupakan sejumlah uang yang wajib dibayarkan konsumen FIF sesuai
                  dengan tagihan yang telah ditetapkan pada saat mengambil pembiayaan dari FIF tiap
                  bulannya sebelum tanggal jatuh tempo /batas waktu pembayaran angsuran. Untuk
                  memudahkan konsumen FIF, kami telah menyediakan alat dalam website ini untuk{' '}
                  <a href="https://member.speedcash.co.id/finance/angsuran-fif">cek angsuran FIF</a>{' '}
                  setiap bulannya. Berikut caranya :
                </p>
              </Row>
              <Row>
                <ol className="text-justify list-content">
                  <li>Kunjungi alamat : https://member.speedcash.co.id/finance/angsuran-fif</li>
                  <li>Lalu login dulu menggunakan nomor hp yang aktif ya</li>
                  <li>Jika sudah, lanjutkan untuk masuk ke menu angsuran kredit</li>
                  <li>Masukkan ID Pelanggan FIF</li>
                  <li>Lalu klik Cek tagihan FIF Sekarang</li>
                  <li>Dan akan muncul total tagihan beserta diskon yang kamu bayar</li>
                  <li>jika sudah ikuti saja instruksi selanjutnya</li>
                </ol>
              </Row>
            </div>
          </Col>

          <Col lg={8} xs={12} md={8} className="accordion-component">
            <Accordion className="m-0 p-0">
              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="2"
                    className="button-collapse w-100 text-left"
                  >
                    Cek Angsuran FIF Melalui Aplikasi
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="2">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Ternyata selain mengecek angsuran FIF setiap bulan melalui website ada cara
                        lain untuk melihat total tagihan angsuran kredit FIF melalui aplikasi,
                        caranya sangat mudah. Yuk! ikuti caranya berikut ini :
                      </p>
                      <Row className="mt-3">
                        <ul className="text-justify list-content">
                          <li>
                            Downoad aplikasi SpeedCash yang saat ini sudah tersedia di playstore
                            maupun appstore (IOS)
                          </li>
                          <li>Lalu login menggunakan no hp yang aktif ya</li>
                          <li>
                            <p>Jika sudah login, langsung saja kamu klik menu MULTIFINANCE</p>
                            <Row className="d-flex justify-content-center">
                              <Image
                                src="/img/cek_angsuran_fif.png"
                                data-target="cek_angsuran_fif"
                                width="220px"
                                height="400px"
                              />
                            </Row>
                          </li>
                          <li>
                            <p>Pilih produk cicilan FIF</p>
                            <Row className="d-flex justify-content-center">
                              <Image
                                src="/img/angsuran_fif.png"
                                data-target="angsuran_fif"
                                width="220px"
                                height="400px"
                              />
                            </Row>
                          </li>
                          <li>Jika sudah, masukkan ID pelanggan FIF</li>
                          <li>Lalu klik lanjutkan dan ikuti instruksi selanjutnya</li>
                        </ul>
                      </Row>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="3"
                    className="button-collapse w-100 text-left"
                  >
                    Bayar Tagihan Kredit FIF Bulanan Disini
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="3">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Ternyata untuk bayar tagihan kredit/FIF bulanan bisa dilakukan juga melalui{' '}
                        <a href="https://www.speedcash.co.id/">SpeedCash</a>. Dan kamu bisa memilih
                        untuk membayar tagihan angsuran FIF melalui website SpeedCash ataupun via
                        aplikasi. Cara bayarnya juga sama dengan mengikuti langkah-langkah diatas.
                      </p>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="4"
                    className="button-collapse w-100 text-left"
                  >
                    Mengapa Harus Cek Angsuran FIF Dan Bayar Online Di SpeedCash ?
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="4">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block-text-justify">
                        Setiap awal bulan aktivitas yang tidak boleh dilewatkan ialah mengecek
                        tagihan maupun angsuran pinjaman online.Agar terhindar dari denda
                        keterlambatan pembayaran angsuran. Kamu bisa memanfaatkan layanan dari
                        SpeedCash untuk cek angsuran FIF maupun membayar langsung di SpeedCash. Dan
                        ternyata kamu bisa mendapatkan layanan lain ketika memakai SpeedCash layanan
                        tersebut antara lain seperti :{' '}
                        <a href="https://member.speedcash.co.id/pln/cek-tagihan-listrik">
                          cek tagihan listrik
                        </a>
                        ,{' '}
                        <a href="https://member.speedcash.co.id/cek-bpjs-kesehatan">
                          cek BPJS kesehatan
                        </a>
                        ,{' '}
                        <a href="https://member.speedcash.co.id/pascabayar/telkomsel-halo">
                          cek tagihan kartu halo
                        </a>
                        , hingga untuk beli pulsa dan masih banyak layanan lainnya yang bisa kamu
                        dapatkan dengan memakai SpeedCash.
                      </p>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="5"
                    className="button-collapse w-100 text-left"
                  >
                    Keunggulan Lain Memakai SpeedCash
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="5">
                  <Card.Body className="card-content">
                    <Row>
                      <ol className="text-justify list-content">
                        <li>Gratis notifikasi pengingat tagihan angsuran FIF tiap bulan</li>
                        <li>
                          Lebih dari untuk angsuran FIF saja, namun lengkap bisa untuk beli pulsa,
                          paket internet, top up gopay, ovo, shopeepay, dana, beli voucher game
                          online dll
                        </li>
                        <li>Transfer bank gratis biaya admin</li>
                        <li>Harga termurah untuk beli tiket pesawat</li>
                        <li>Bebas biaya admin & layanan untuk tiket kereta api</li>
                        <li>Selalu ada diskon tiap transaksi (hemat s.d rp.50.000/bulan)</li>
                        <li>Aplikasinya gratis 100% (tidak ada biaya pendaftaran apapun)</li>
                        <li>Tidak wajib punya rekening bank pribadi</li>
                      </ol>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="6"
                    className="button-collapse w-100 text-left"
                  >
                    Kendala Saat Cek Angsuran FIF Dan Pembayarannya
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="6">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Mungkin saat kamu melakukan cek angsuran FIF atau saat melakukan pembayaran
                        tagihan FIF di SpeedCash kamu menghadapi kendala seperti :
                      </p>
                      <div className="mt-1">
                        <b>Total tagihan tidak muncul : </b>
                        <p className="d-block text-justify mt-1">
                          Biasanya jika tagihan FIF belum muncul, kamu bisa mengulangi di tanggal /
                          hari berikutnya (biasanya total tagihan belum muncul jika kamu mengecek di
                          tanggal sebelum tanggal jatuh tempo)
                        </p>
                      </div>
                      <div className="mt-1">
                        <b>Pembayaran Gagal : </b>
                        <p className="d-block text-justify mt-1">
                          Jika pembayaran mu gagal kamu tidak perlu panik.Kamu bisa menghubungi
                          customer service SpeedCash yang selalu stand by 24/7 yaitu selama 24 jam
                          dalam 7 hari. Menghubungi customer service SpeedCash juga bisa dilakukan
                          melalui fitur live chat dari aplikasi maupun dari web browser dengan
                          mengunjungi link : https://member.speedcash.co.id/
                        </p>
                      </div>
                      <div className="mt-1">
                        <b>Sudah Bayar Tapi Selalu Muncul Tagihan : </b>
                        <p className="d-block text-justify mt-1">
                          Untuk kendala yang satu ini kamu dapat langsung menghubungi CS dari FIF
                          yang dapat kamu hubungi di nomor WhatsApp di 08952-1500-343 maupun di
                          website resmi FIF. Jika kamu ingin lebih jelas lagi kamu bisa mendatangi
                          kantor cabang FIF yang ada di kotamu.
                        </p>
                      </div>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="7"
                    className="button-collapse w-100 text-left"
                  >
                    Sekilas Tentang FIF
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="7">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        FIF (Federal International Finance) adalah salah satu program pembiayaan
                        yang ditawarkan oleh FIF Group kepada konsumen untuk memudahkan mereka dalam
                        membeli barang-barang konsumtif seperti sepeda motor, mobil, elektronik, dan
                        barang-barang lainnya. Melalui program ini, konsumen dapat memperoleh barang
                        yang diinginkan dengan cara membayar secara cicilan dalam jangka waktu
                        tertentu sesuai dengan kesepakatan yang telah ditetapkan. Selain itu,
                        Angsuran FIF juga menyediakan berbagai pilihan tenor atau jangka waktu
                        pembayaran yang fleksibel, sehingga konsumen dapat memilih tenor yang sesuai
                        dengan kemampuan pembayaran mereka.
                      </p>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="8"
                    className="button-collapse w-100 text-left"
                  >
                    Jenis Pembiayaan FIF
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="8">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Di FIF ternyata ada banyak layanan yang bisa kamu gunakan untuk mengajukan
                        pembiayaan.Beberapa kategori pembiayaan di FIF antara lain : pembiayaan
                        kredit motor, pembiayaan multi produk (jenis ini kamu bisa mengajukan untuk
                        beli produk seperti HP, televisi, dan produk rumah tangga lainnya) ,
                        Pembiayaan multiguna, pembiayaan usaha mikro (jika kamu memerlukan modal
                        usaha, kamu bisa mengajukan pinjaman dana melalui FIF di kategori ini),
                        pembiayaan haji & umroh (ternyata di FIF juga bisa mengajukan pembiayaan
                        untuk ibadah haji dan umroh juga loh!)
                      </p>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="9"
                    className="button-collapse w-100 text-left"
                  >
                    Denda Angsuran FIF
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="9">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Jika kamu telat membayar angsuran FIF, hal yang perlu kamu ketahui ialah
                        besaran denda angsuran yang harus kamu bayar. berikut perhitungan denda
                        angsuran FIF :
                      </p>
                      <div className="d-flex flex-column w-100">
                        <p className="d-block text-justify mt-3">
                          Besaran denda keterlambatan angsuran FIF dihitung berdasarkan 2 faktor:
                        </p>
                        <Row className="mt-1">
                          <ol className="text-justify list-content">
                            <li>
                              Presentase: Dikenakan 0,5% dari saldo pinjaman pokok per hari
                              keterlambatan.
                            </li>
                            <li>Minimal: Denda minimal Rp.25.000 per hari.</li>
                          </ol>
                        </Row>
                      </div>
                      <div className="d-flex flex-column w-100">
                        <p className="d-block text-justify mt-3">Contoh Perhitungan Denda:</p>
                        <Row className="mt-1">
                          <ul className="text-justify list-content">
                            <li>Saldo pinjaman pokok: Rp10.000.000</li>
                            <li>Terlambat: 5 hari</li>
                          </ul>
                        </Row>
                      </div>
                      <div className="d-flex flex-row w-100">
                        <p className="d-block text-justify mt-1">
                          Denda = 0,5% x Rp10.000.000 x 5 hari = Rp250.000
                        </p>
                      </div>
                      <div className="d-flex flex-column w-100">
                        <p className="d-block text-justify mt-3"> Catatan:</p>
                        <Row className="mt-1">
                          <ul className="text-justify list-content">
                            <li>Denda dihitung setelah tanggal jatuh tempo angsuran.</li>
                            <li>Denda diakumulasikan setiap hari keterlambatan.</li>
                            <li>Denda harus dibayarkan bersama angsuran yang tertunggak.</li>
                          </ul>
                        </Row>
                      </div>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="10"
                    className="button-collapse w-100 text-left"
                  >
                    Cara Pengajuan Kredit FIF
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="10">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Proses pengajuan kredit FIF mudah dan cepat. Berikut adalah
                        langkah-langkahnya:
                      </p>
                      <ol className="text-justify list-content">
                        <li>
                          <div className="d-flex flex-column">
                            <p className="d-block text-justify">Siapkan dokumen yang diperlukan:</p>
                            <Row className="mt-1">
                              <ul className="text-justify list-content">
                                <li>KTP elektronik yang masih berlaku</li>
                                <li>Kartu Keluarga (KK)</li>
                                <li>
                                  Bukti penghasilan (slip gaji, rekening koran, atau surat
                                  keterangan usaha)
                                </li>
                                <li>NPWP (opsional)</li>
                                <li>Surat keterangan domisili (opsional)</li>
                              </ul>
                            </Row>
                          </div>
                        </li>
                        <li>
                          <div className="d-flex flex-column">
                            <p className="d-block text-justify">
                              Kunjungi dealer FIF terdekat atau ajukan online:
                            </p>
                            <Row className="mt-1">
                              <ul className="text-justify list-content">
                                <li>
                                  Anda dapat mengunjungi dealer FIF terdekat untuk mengajukan kredit
                                  secara langsung.
                                </li>
                                <li>
                                  Anda juga dapat mengajukan kredit secara online melalui website
                                  FIFGROUP (https://www.fif.com/) atau aplikasi FIFGROUP Mobile
                                  Customer.
                                </li>
                              </ul>
                            </Row>
                          </div>
                        </li>
                        <li>
                          <div className="d-flex flex-column">
                            <p className="d-block text-justify">Isi formulir pengajuan kredit:</p>
                            <Row className="mt-1">
                              <ul className="text-justify list-content">
                                <li>Isi formulir pengajuan kredit dengan lengkap dan benar.</li>
                                <li>
                                  Pastikan Anda membaca dan memahami semua syarat dan ketentuan yang
                                  berlaku.
                                </li>
                              </ul>
                            </Row>
                          </div>
                        </li>
                        <li>
                          <div className="d-flex flex-column">
                            <p className="d-block text-justify">
                              Serahkan dokumen yang diperlukan:
                            </p>
                            <Row className="mt-1">
                              <ul className="text-justify list-content">
                                <li>Serahkan dokumen yang diperlukan kepada petugas FIF.</li>
                                <li>Petugas FIF akan melakukan verifikasi data Anda.</li>
                              </ul>
                            </Row>
                          </div>
                        </li>
                        <li>
                          <div className="d-flex flex-column">
                            <p className="d-block text-justify">Tunggu persetujuan:</p>
                            <Row className="mt-1">
                              <ul className="text-justify list-content">
                                <li>Proses persetujuan biasanya memakan waktu 1-2 hari kerja.</li>
                                <li>
                                  Anda akan dihubungi oleh FIF jika pengajuan kredit Anda disetujui.
                                </li>
                              </ul>
                            </Row>
                          </div>
                        </li>
                        <li>
                          <div className="d-flex flex-column">
                            <p className="d-block text-justify">Tanda tangan kontrak pembiayaan:</p>
                            <Row className="mt-1">
                              <ul className="text-justify list-content">
                                <li>
                                  Jika pengajuan kredit Anda disetujui, Anda akan diminta untuk
                                  menandatangani kontrak pembiayaan.
                                </li>
                                <li>
                                  Pastikan Anda membaca dan memahami semua isi kontrak pembiayaan
                                  sebelum menandatanganinya.
                                </li>
                              </ul>
                            </Row>
                          </div>
                        </li>
                        <li>
                          <div className="d-flex flex-column">
                            <p className="d-block text-justify">Bayar uang muka:</p>
                            <Row className="mt-1">
                              <ul className="text-justify list-content">
                                <li>
                                  Bayar uang muka sesuai dengan ketentuan yang telah disepakati.
                                </li>
                              </ul>
                            </Row>
                          </div>
                        </li>
                        <li>
                          <div className="d-flex flex-column">
                            <p className="d-block text-justify">Terima barang:</p>
                            <Row className="mt-1">
                              <ul className="text-justify list-content">
                                <li>
                                  Anda dapat menerima barang yang Anda beli setelah melunasi uang
                                  muka.
                                </li>
                              </ul>
                            </Row>
                          </div>
                        </li>
                      </ol>
                      <div className="d-flex flex-column">
                        <p className="d-block text-justify mt-3">Tips:</p>
                        <Row className="mt-1">
                          <ul className="text-justify list-content">
                            <li>
                              Pastikan Anda memiliki riwayat kredit yang baik sebelum mengajukan
                              kredit FIF.
                            </li>
                            <li>
                              Bandingkan suku bunga dan biaya yang ditawarkan oleh FIF dengan
                              perusahaan pembiayaan lainnya sebelum membuat keputusan.
                            </li>
                            <li>
                              Baca dan pahami semua syarat dan ketentuan yang berlaku sebelum
                              menandatangani kontrak pembiayaan.
                            </li>
                          </ul>
                        </Row>
                      </div>
                      <div className="d-flex flex-column">
                        <p className="d-block text-justify mt-3">Pengajuan kredit FIF online:</p>
                        <p className="d-block text-justify ml-4">
                          Berikut adalah langkah-langkah untuk mengajukan kredit FIF online:
                        </p>
                        <Row className="mt-1 ml-4">
                          <ul className="text-justify list-content">
                            <li>
                              Kunjungi website FIFGROUP (https://www.fif.com/) atau download
                              aplikasi FIFGROUP Mobile Customer.
                            </li>
                            <li>Pilih produk pembiayaan yang Anda inginkan.</li>
                            <li>Isi formulir pengajuan kredit secara online.</li>
                            <li>Upload dokumen yang diperlukan.</li>
                            <li>Tunggu persetujuan.</li>
                            <li>
                              Jika pengajuan kredit Anda disetujui, Anda akan dihubungi oleh FIF.
                            </li>
                          </ul>
                        </Row>
                      </div>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="11"
                    className="button-collapse w-100 text-left"
                  >
                    Cek Angsuran FIF Melalui SMS
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="11">
                  <Card.Body className="card-content">
                    <Row>
                      <div className="w-100 my-1">
                        <p className="d-block text-justify">
                          Berikut cara mudah cek angsuran FIF lewat SMS :
                        </p>
                      </div>
                      <div className="w-100 my-1">
                        <div className="ml-3">
                          <ul className="text-justify list-content">
                            <li>
                              Ketik SMS dengan format FIN(spasi)NO.KONTRAK dan kirim ke nomor
                              1500343.
                            </li>
                            <li>Contoh: FIN SPASI B001234567 dan kirim ke 1500343.</li>
                            <li>
                              Anda akan menerima balasan SMS berisi informasi angsuran, termasuk
                              sisa tagihan, tanggal jatuh tempo, dan total angsuran.
                            </li>
                          </ul>
                        </div>
                      </div>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="12"
                    className="button-collapse w-100 text-left"
                  >
                    Cek Angsuran FIF Melalui Whatsapp
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="12">
                  <Card.Body className="card-content">
                    <Row>
                      <div className="w-100 my-1">
                        <p className="d-block text-justify">
                          Cara lain untuk cek angsuran fif bisa melalui whatsapp,berikut caranya :
                        </p>
                      </div>
                      <div className="w-100 my-1">
                        <div className="ml-3">
                          <ul className="text-justify list-content">
                            <li>
                              Simpan nomor Whatsapp FIF (FIONA) di HP Anda, yaitu +62 8952-1500-343.
                            </li>
                            <li>Buka aplikasi Whatsapp dan buka chat dengan FIF (FIONA).</li>
                            <li>
                              Ketik pesan &ldquo;Cek angsuran&rdquo; atau &ldquo;Info
                              angsuran&rdquo;.
                            </li>
                            <li>
                              Ikuti instruksi yang diberikan oleh FIONA untuk mengecek angsuran FIF
                              Anda.
                            </li>
                          </ul>
                        </div>
                      </div>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="13"
                    className="button-collapse w-100 text-left"
                  >
                    Cek Angsuran FIF Lewat Website
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="13">
                  <Card.Body className="card-content">
                    <Row>
                      <div className="w-100 my-1">
                        <p className="d-block text-justify">
                          Untuk melihat total tagihan angsuran FIF bisa juga melalui website,
                          berikut caranya :
                        </p>
                      </div>
                      <div className="w-100 my-1">
                        <div className="ml-3">
                          <ul className="text-justify list-content">
                            <li>
                              Buka browser di HP Anda dan kunjungi website resmi FIFGROUP di
                              https://fifgroup.co.id/.
                            </li>
                            <li>Klik &ldquo;Masuk&rdquo; dan login ke akun FIFGROUP Anda.</li>
                            <li>Pilih menu &ldquo;Angsuran&rdquo;.</li>
                            <li>Pilih kontrak FIF yang ingin Anda cek angsurannya.</li>
                            <li>
                              Informasi angsuran, termasuk sisa tagihan, tanggal jatuh tempo, dan
                              total angsuran akan ditampilkan.
                            </li>
                          </ul>
                        </div>
                      </div>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="14"
                    className="button-collapse w-100 text-left"
                  >
                    F.A.Q Cek Angsuran FIF & Bayar Melalui SpeedCash
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="14">
                  <Card.Body className="card-content">
                    <Row>
                      <div className="w-100">
                        <p className="d-block text-justify">
                          <strong>
                            Q: Apakah bisa melakukan pembayaran angsuran FIF melalui Speedcash tanpa
                            memiliki akun?
                          </strong>
                        </p>
                      </div>
                      <div className="w-100 my-1">
                        <p className="d-block text-justify">
                          {' '}
                          A: Tidak, Anda perlu memiliki akun Speedcash untuk melakukan pembayaran.
                        </p>
                      </div>
                      <div className="w-100 my-1">
                        <p className="d-block text-justify">
                          <strong>Q: Apakah Speedcash menawarkan opsi pembayaran cicilan?</strong>
                        </p>
                      </div>
                      <div className="w-100 my-1">
                        <p className="d-block text-justify">
                          A: Tidak, Speedcash hingga saat ini belum menyediakan opsi pembayaran
                          cicilan.
                        </p>
                      </div>
                      <div className="w-100 my-1">
                        <p className="d-block text-justify">
                          <strong>
                            Q: Berapa lama waktu yang diperlukan untuk proses pembayaran menggunakan
                            Speedcash?
                          </strong>
                        </p>
                      </div>
                      <div className="w-100 my-1">
                        <p className="d-block text-justify">
                          A: Proses pembayaran menggunakan Speedcash sangat cepat, biasanya hanya
                          membutuhkan beberapa menit.
                        </p>
                      </div>
                      <div className="w-100 my-1">
                        <p className="d-block text-justify">
                          <strong>Q: Apakah ada biaya tambahan untuk menggunakan Speedcash?</strong>
                        </p>
                      </div>
                      <div className="w-100 my-1">
                        <p className="d-block text-justify">
                          A: Tidak, Speedcash tidak mengenakan biaya tambahan untuk melakukan
                          pembayaran angsuran FIF, selain itu kamu juga akan mendapatkan diskon
                          potongan langsung sebesar Rp.1.600.
                        </p>
                      </div>
                      <div className="w-100 my-1">
                        <p className="d-block text-justify">
                          <strong>
                            Q: Bagaimana jika terjadi kesalahan dalam proses pembayaran?
                          </strong>
                        </p>
                      </div>
                      <div className="w-100 my-1">
                        <p className="d-block text-justify">
                          A: Jika terjadi kesalahan, segera hubungi layanan pelanggan Speedcash
                          untuk mendapatkan bantuan.
                        </p>
                      </div>
                      <div className="w-100 my-1">
                        <p className="d-block text-justify">
                          <strong>
                            Q: Bisakah saya mengatur pembayaran otomatis untuk angsuran FIF melalui
                            Speedcash?
                          </strong>
                        </p>
                      </div>
                      <div className="w-100 my-1">
                        <p className="d-block text-justify">
                          A: Ya, Anda dapat mengatur pembayaran otomatis untuk memudahkan Anda dalam
                          mengelola pembayaran angsuran.
                        </p>
                      </div>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="15"
                    className="button-collapse w-100 text-left"
                  >
                    Layanan Pelanggan FIF Yang Harus Kamu Ketahui
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="15">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        FIF menyediakan berbagai layanan pelanggan untuk membantu konsumen dengan
                        berbagai kebutuhan terkait pembiayaan. Berikut beberapa cara untuk
                        menghubungi FIF:
                      </p>
                      <div className="w-100">
                        <p className="d-block text-justify mt-1">
                          <strong>1. Call Center HaloFIF:</strong>
                        </p>
                        <p className="d-block text-justify mt-1">Nomor: 1500-343</p>
                        <p className="d-block text-justify mt-1">
                          Jam Operasional: Senin - Jumat, 08.00 - 17.00 WIB
                        </p>
                      </div>
                      <div className="w-100">
                        <p className="d-block text-justify mt-1">
                          <strong>2. WhatsApp Fiona:</strong>
                        </p>
                        <p className="d-block text-justify mt-1">Nomor: 08952-1500-343</p>
                        <p className="d-block text-justify mt-1">Layanan 24 Jam</p>
                      </div>
                      <div className="w-100">
                        <p className="d-block text-justify mt-1">
                          <strong>3. Email:</strong>
                        </p>
                        <p className="d-block text-justify mt-1">
                          Alamat: halofif@fifgroup.astra.co.id
                        </p>
                      </div>
                      <p className="d-block text-justify mt-1">
                        Itulah tadi informasi mengenai cara cek angsuran FIF dan bayar tagihan
                        kredit FIF langsung melalui SpeedCash. Sekarang anda bisa lebih mudah lagi
                        dalam mengelolan tagihan pembayaran bulanan melalui SpeedCash baik via
                        website maupun aplikasi SpeedCash. Untuk mulai menggunakan SpeedCash kamu
                        bisa mendowload aplikasi SpeedCash melalui link dibawah ini.
                      </p>
                      <div className="mt-1 w-100 d-flex justify-content-center">
                        <h5 className="bold">Yuk! Dapatkan Aplikasi Cek Angsuran FIF Disini</h5>
                      </div>
                      <Row className="d-flex w-100 justify-content-center mb-3 gap-3">
                        <a
                          aria-hidden
                          href="https://play.google.com/store/apps/details?id=com.bm.sc.bebasbayar&listing=speedcash_banner_app"
                          className="mx-1"
                        >
                          <Image
                            src="/img/playstore-speedcash.png"
                            data-target="playstore-speedcash"
                            width="220px"
                            height="60px"
                          />
                        </a>
                        <a
                          aria-hidden
                          href="https://apps.apple.com/id/app/speedcash-transfer-ewallet/id6459304122"
                          className="mx-1"
                        >
                          <Image
                            src="/img/appstore-speedcash.png"
                            data-target="appstore-speedcash"
                            width="220px"
                            height="60px"
                          />
                        </a>
                      </Row>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default AccordionFif
