import qs from 'qs'

const Browser = {
  baseURL(path) {
    const base = `${window.location.protocol}//${window.location.host}/`

    if (path) {
      return base + path
    }
    return base
  },

  getCurrentPath() {
    return window.location.pathname.replace(/^\/+/, '')
  },

  getQueryString(key) {
    if (!window.location.search) {
      return ''
    }
    const map = qs.parse(window.location.search.replace(/^\?+/, ''))

    if (key) {
      return map[key]
    }

    return map
  },

  redirect(url) {
    if (url && url.indexOf('://') >= 0) {
      window.location.href = url
    } else {
      window.location.href = this.baseURL(url)
    }
  },

  reload() {
    window.location.reload()
  },

  openInNewTab(url) {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  },
}

export default Browser
