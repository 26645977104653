import React from 'react'
import { connect } from 'react-redux'
import { Spinner, Modal, Button, Image, Form, InputGroup, Alert } from 'react-bootstrap'
import PropTypes from 'prop-types'

import globalConstant from '../../../store/constant/global'
import authConstant from '../../../store/constant/auth'
import HookHelper from '../../../helper/hookhelper'
import Formatter from '../../../helper/formatter'

import { depositInq } from '../../../api/action/deposit/deposit'

import './dialogDeposit.scss'

const MandiriPcModal = ({
  appUserId,
  appUserPin,
  onHide,
  show,
  onConfirm,
  appIsLoading,
  dataRes,
  dispatch,
}) => {
  const [alertError, setAlertError] = React.useState('')
  const [saldo, setSaldo] = React.useState('')
  const [saldoLabel, setSaldoLabel] = React.useState('')

  const closeError = React.useCallback(() => setAlertError(''), [])

  const onSaldoChange = React.useCallback(
    (e) => {
      const { value } = e.target
      setSaldo(value)
      setSaldoLabel(Formatter.price(value.replace(/[^A-Z0-9]/gi, '')))
    },
    [setSaldo, setSaldoLabel]
  )

  const processDeposit = React.useCallback(async () => {
    if (!saldo) {
      setAlertError({ rc: '', rd: 'Nominal saldo harus lebih dari 0' })
      return false
    }

    const responseInq = await dispatch(depositInq(appUserId, appUserPin, dataRes.kode_bank, saldo))
    if (responseInq.isOK()) {
      onConfirm(responseInq.getData())
    } else {
      setAlertError({ rc: responseInq.getRC(), rd: responseInq.getRD() })
    }

    return true
  }, [dispatch, appUserId, appUserPin, dataRes, saldo, onConfirm, setAlertError])

  React.useEffect(() => {
    HookHelper.resetLoading()
  }, [])

  return (
    <Modal
      show={show}
      onHide={onHide}
      backdrop="static"
      keyboard={false}
      centered
      contentClassName="radius pln"
      animation={false}
    >
      <Modal.Header className="header">
        <div style={{ width: '95%' }}>
          <Modal.Title className="title">
            {dataRes ? dataRes.nama_bank : 'Bank Mandiri'}
          </Modal.Title>
        </div>
        <div className="close-modal">
          <Image
            src="/icon/clear.png"
            onClick={onHide}
            roundedCircle
            className="justify-content-end"
          />
        </div>
      </Modal.Header>

      <Modal.Body className="body">
        <Alert variant="info" style={{ textAlign: 'center' }}>
          <Alert.Heading>
            Minimal Deposit Rp. {Formatter.price(dataRes ? dataRes.min_deposit : 0)}
          </Alert.Heading>
        </Alert>
        <div
          style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}
        >
          <Image
            src={dataRes ? dataRes.url_image : ''}
            style={{ height: '100%', width: '50%', marginTop: '20px', marginBottom: '20px' }}
            rounded
          />
        </div>
        <Form>
          <Form.Group controlId="formSaldo">
            <Form.Label className="label-bold">Nominal Deposit</Form.Label>
            <InputGroup className="mb-3">
              <InputGroup.Prepend>
                <InputGroup.Text className="label-bold">Rp.</InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                placeholder="Masukkan Nominal Saldo"
                aria-label="saldo"
                className="label-text txt-bold"
                name="saldo"
                value={saldoLabel}
                onChange={onSaldoChange}
              />
            </InputGroup>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer style={{ position: 'sticky', bottom: 0 }}>
        <Button
          variant="warning"
          type="button"
          block
          onClick={processDeposit}
          disabled={appIsLoading}
        >
          {appIsLoading && (
            <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
          )}
          {appIsLoading ? ' Mohon Tunggu...' : 'Isi Saldo Sekarang'}
        </Button>
      </Modal.Footer>
      <Alert
        variant="danger"
        show={!!alertError.rd}
        onClose={closeError}
        transition={null}
        dismissible
      >
        {alertError.rd}
      </Alert>
    </Modal>
  )
}

MandiriPcModal.defaultProps = {
  appIsLoading: false,
  onHide: null,
  onConfirm: null,
  show: false,
  dataRes: null,
  appUserId: '',
  appUserPin: '',
}

MandiriPcModal.propTypes = {
  onHide: PropTypes.func,
  onConfirm: PropTypes.func,
  show: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  dataRes: PropTypes.object,
  appUserId: PropTypes.string,
  appUserPin: PropTypes.string,
  appIsLoading: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
}

export default connect(
  (state) => ({
    appIsLoading: state.Global[globalConstant.IS_LOADING],
    appUserId: state.Auth[authConstant.USER_ID],
    appUserPin: state.Auth[authConstant.USER_PIN],
  }),
  (dispatch) => ({ dispatch })
)(MandiriPcModal)
