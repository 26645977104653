import store from '../store'
import globalConstant from '../store/constant/global'

const HookHelper = {
  resetLoading: () => {
    store.dispatch({
      type: globalConstant.IS_LOADING,
      payload: false,
    })
  },
}

export default HookHelper
