import React from 'react'
import { Button, Image, Row, Col, Accordion, Card } from 'react-bootstrap'
import './accordion.scss'

const AccordionPkbJatim = () => {
  return (
    <>
      <Col className="d-flex justify-content-center mt-4 mb-3 p-3">
        <h3 className="text-dark text-justify">
          Cara Cek Pajak Kendaraan E-Samsat Jatim Di SpeedCash
        </h3>
      </Col>

      <div style={{ padding: '0px 20px 30px 20px' }}>
        <Row className="justify-content-md-center">
          <Col lg={8} xs={12} md={8} className="content-component">
            <div>
              <Row className="d-flex justify-content-center">
                <p className="d-block text-justify">
                  <i>“ora antri ora repot maneh”</i> sekarang sudah ga ada alasan lagi kalau bayar
                  pajak kendaraan di jawa timur harus nunggu waktu luang apalagi nunggu hari libur,
                  karena sekarang sudah bisa langsung{' '}
                  <a
                    href="https://member.speedcash.co.id/e-samsat/cek-pajak-kendaraan-jatim"
                    rel="dofollow"
                    title="cek pajak kendaraan online jatim"
                  >
                    cek pajak kendaraan jatim
                  </a>{' '}
                  bayar e samsat langsung di aplikasi ataupun via web.
                </p>
                <p className="d-block text-justify mt-1">
                  Gaya hidup masyarakat sudah berubah kalau dulu bayar pajak kendaraan setiap tahun
                  harus datang ke kantor samsat yang ada di kota atau kabupaten provinsi jawa timur
                  serta juga harus rela antri dari pagi sampai siang.{' '}
                </p>
                <p className="d-block text-justify mt-1">
                  Tapi sekarang warga jatim sudah bisa langsung cek dan bayar pajak STNK kendaraan
                  cukup dari HP karena bisa online 24 jam jadi bisa dilakukan kapanpun dimanapun.
                  Mau tau caranya ? simak ulasan cara cek pajak kendaraan motor atau mobil dan bayar
                  e samsat jatim langsung disini.
                </p>
              </Row>
              <Row className="d-flex justify-content-center" style={{ gap: '30px' }}>
                <Image
                  src="/img/cek-pajak-kendaraan-jatim.png"
                  data-target="cek-pajak-kendaraan-jatim"
                  width="220px"
                />
                <Image src="/img/e-samsat-jatim.png" data-target="e-samsat-jatim" width="220px" />
              </Row>
              <div className="mt-4 w-100 d-flex justify-content-center">
                <h5 className="bold">Cek Pajak Kendaraan Jatim Ora Repot Download Disini</h5>
              </div>
              <Row className="d-flex w-100 justify-content-center mb-3 gap-3">
                <a
                  aria-hidden
                  href="https://play.google.com/store/apps/details?id=com.bm.sc.bebasbayar&listing=bayarpajak_samsatjatim_online"
                  className="mx-1"
                  rel="dofollow"
                  title="cek pajak kendaraan online jatim"
                >
                  <Image
                    src="/img/playstore-speedcash.png"
                    data-target="playstore-speedcash"
                    width="220px"
                    height="60px"
                  />
                </a>
                <a
                  aria-hidden
                  href="https://apps.apple.com/id/app/speedcash-transfer-ewallet/id6459304122"
                  className="mx-1"
                  rel="dofollow"
                  title="cek pajak kendaraan online jatim"
                >
                  <Image
                    src="/img/appstore-speedcash.png"
                    data-target="appstore-speedcash"
                    width="220px"
                    height="60px"
                  />
                </a>
              </Row>
              <Row className="d-flex justify-content-center text-dark mt-5">
                <h4>Simak Video Cara Mengecek Pajak Kendaraan Jatim Di Aplikasi</h4>
              </Row>
              <Row className="d-flex justify-content-center mt-3">
                <iframe
                  width="100%"
                  height="480"
                  src="https://www.youtube.com/embed/VBCX6au2-iE/"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  frameBorder={0}
                  title="Cara Mengecek Pajak Kendaraan Jatim Di Aplikasi"
                />
              </Row>
            </div>
          </Col>

          <Col lg={8} xs={12} md={8} className="accordion-component">
            <Accordion className="m-0 p-0">
              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="1"
                    className="button-collapse w-100 text-left"
                  >
                    Cara Cek Pajak Kendaraan Jatim Online Di Website
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="1">
                  <Card.Body className="card-content">
                    <Row className="w-100">
                      <ol className="text-justify list-content w-100">
                        <li>
                          Kunjungi situs :
                          https://member.speedcash.co.id/e-samsat/cek-pajak-kendaraan-jatim
                        </li>
                        <li>Pilih menu &ldquo;Pajak Kendaraan&rdquo;</li>
                        <li>Pilih samsat Jawa Timur</li>
                        <li>Masukkan nomor Plat kendaraan</li>
                        <li>Masukkan No. mesin kendaraan.</li>
                        <li>Masukkan No. KTP</li>
                        <li>Masukkan no hp dan juga alamat email</li>
                        <li>Klik cek tagihan sekarang</li>
                        <li>Selanjutnya rincian tagihan pajak kendaraan akan terlihat</li>
                        <li>Segera lakukan pembayaran</li>
                        <li>
                          <p>
                            Setelah muncul tulisan pembayaran berhasil, tunggu sesaat hingga
                            E-samsat jatim akan mengirim SMS seperti berikut
                          </p>
                          <Row className="d-flex justify-content-center">
                            <Image
                              src="/img/file-e-tbpkp.png"
                              data-target="file-e-tbpkp"
                              width="220px"
                              height="400px"
                            />
                          </Row>
                        </li>
                        <li>
                          Setelah menerima SMS dari samsat jatim seperti diatas, klik saja link
                          untuk mendownload file E-TBPKP lalu kamu bisa cetak/print di fotocopy
                          terdekat maupun kamu simpan di galeri handphone sebagai bukti sah telah
                          lunas pajak kendaraan bermotor tahunan.
                        </li>
                      </ol>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="2"
                    className="button-collapse w-100 text-left"
                  >
                    Cek Pajak Kendaraan Jatim Dan Bayar E Samsat Di SpeedCash Dapat Bonus Poin
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="2">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Tidak punya banyak waktu ? tenang, sekarang kamu bisa pakai dompet digital
                        SpeedCash untuk bayar dan cek pajak kendaraan jatim dan langsung bayar e
                        samsat agar mendapat bonus poin, ini caranya :
                      </p>
                    </Row>

                    <Row className="mt-3 w-100">
                      <ol className="text-justify list-content w-100">
                        <li>
                          <p>
                            Buka aplikasi SpeedCash, anda bisa mengunduhnya di Google Play ataupun
                            IOS
                          </p>
                          <Row className="d-flex justify-content-center">
                            <Image
                              src="/img/aplikasi-cek-pajak-kendaraan-jatim.png"
                              data-target="aplikasi-cek-pajak-kendaraan-jatim"
                              width="220px"
                              height="400px"
                            />
                          </Row>
                        </li>
                        <li>Pilih menu &ldquo;E-Samsat&rdquo;</li>
                        <li>
                          <p>Pilih Samsat Jawa Timur</p>
                          <Row className="d-flex justify-content-center">
                            <Image
                              src="/img/pilih-e-samsat-jatim.png"
                              data-target="pilih e-samsat-jatim"
                              width="220px"
                              height="400px"
                            />
                          </Row>
                        </li>
                        <li>
                          <p>Masukkan No plat kendaraan jatim</p>
                          <Row className="d-flex justify-content-center">
                            <Image
                              src="/img/bayar-pajak-kendaraan-online-jatim.png"
                              data-target="bayar-pajak-kendaraan-online-jatim"
                              width="220px"
                              height="400px"
                            />
                          </Row>
                        </li>
                        <li>Dan masukkan No. mesin kendaraan.</li>
                        <li>Lanjutkan Input No. HP (pastikan nomor HP anda yang aktif)</li>
                        <li>Masukkan alamat email</li>
                        <li>
                          Klik cek tagihan, (maka rincian tagihan pajak kendaraan akan terlihat)
                        </li>
                        <li>Tahap akhir klik bayar</li>
                        <li>
                          <p>Setelah pembayaran berhasil, Samsat jatim seperti gambar berikut : </p>
                          <Row className="d-flex justify-content-center">
                            <Image
                              src="/img/file-e-tbpkp.png"
                              data-target="file-e-tbpkp"
                              width="220px"
                              height="400px"
                            />
                          </Row>
                        </li>
                        <li>
                          Jika sudah menerima SMS seperti diatas, kamu bisa langsung klik link yang
                          dikirim untuk, selanjutnya pastikan link tersebut berisi file E-TBPKP yang
                          bisa kamu download dan cetak di fotocopy terdekat maupun disimpan di
                          galeri handphone sebagai bukti sah dan sudah lunas pembayaran pajak
                          kendaraan bermotor tahunan jawa timur
                        </li>
                      </ol>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="3"
                    className="button-collapse w-100 text-left"
                  >
                    Gimana Cara Dapetin Poin Setelah Bayar Pajak Kendaraan Jatim ?
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="3">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Buat kamu yang ingin lebih hemat pengularan lagi, memakai SpeedCash adalah
                        langkah yang sangat tepat karena selain kamu bisa hemat waktu kamu juga
                        pasti otomatis makin hemat pengeluran sebagai contoh saat kamu membayar
                        pajak kendaraan jatim maka otomatis akan langsung dapat poin, dan sistem
                        poin ini tidak hanya berlaku ketika bayar samsat jatim saja namun berlaku
                        juga di setiap transaksi yang berhasil terbayar.
                      </p>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="4"
                    className="button-collapse w-100 text-left"
                  >
                    Cek Pajak Kendaraan Online Jatim Tanpa Nomor Rangka, Apa Bisa ?
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="4">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Untuk saat ini jika mau cek dan bayar pajak STNK tahunan online jatim
                        melalui SpeedCash bisa tanpa nomor rangka kamu cukup melengkapi data yang
                        diminta seperti : no plat kendaraan, nomor mesin kendaraan, KTP (yang sesuai
                        dengan data pemilik kendaraan) tidak lupa juga isi no hp serta email.
                      </p>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="5"
                    className="button-collapse w-100 text-left"
                  >
                    Bisakah Cek Dan Bayar Pajak STNK 5 Tahunan Online Disini ?
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="5">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Untuk saat ini cek pajak STNK periode 5 tahunan wajib datang ke kantor
                        samsat jatim karena ada proses cek fisik kendaraan, dan juga kamu harus
                        membawa dokumen identitas diri seperti KTP,KK dan juga paspor jika
                        diperlukan
                      </p>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="6"
                    className="button-collapse w-100 text-left"
                  >
                    Bayar Dan Cek Pajak Kendaraan Samsat Kota Lain Apakah Bisa Lewat SpeedCash ?
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="6">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Kabar baiknya kini cek pajak motor atau mobil untuk samsat kota lain juga
                        bisa loh! jadi semisal kamu punya kendaraan di kalimantan timur kamu bisa
                        pakai Speedcash buat cek pajak kendaraan kaltim ataupun cek pajak kendaraan
                        banten sehingga penggunaan SpeedCash tidak terbatas untuk samsat jawa timur
                        saja.
                      </p>
                      <p className="d-block text-justify mt-1">
                        Dengan adanya SpeedCash kami berharap bisa membawa kemudahan untuk
                        masyarakat ketika jatuh tempo bayar pajak kendaraan jadi tidak perlu susah
                        payah lagi harus datang ke kantor samsat dan membuang waktu lagi
                      </p>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="7"
                    className="button-collapse w-100 text-left"
                  >
                    Selain Cek Pajak Kendaraan Jatim, Apa Saja Yang Bisa Dilakukan di SpeedCash
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="7">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Sudah waktunya beralih ke cara yang lebih praktis untuk aktivitas yang lebih
                        dinamis, kini semua aktivitas pembayaran digital semakin mudah dan cepat
                        dalam 1 aplikasi, SpeedCash tidak hanya untuk mengecek pajak kendaraan samat
                        jatim saja tetapi juga bisa digunakan buat{' '}
                        <a
                          href="https://member.speedcash.co.id/game/top-up-ff"
                          rel="dofollow"
                          title="top up ff termurah"
                        >
                          top up ff{' '}
                        </a>
                        , beli pulsa murah cocok buat kamu yang punya konter dan lagi cari sumber{' '}
                        <a
                          href="https://member.speedcash.co.id/isi-pulsa-termurah"
                          rel="dofollow"
                          title="aplikasi agen pulsa termurah"
                        >
                          agen pulsa termurah
                        </a>{' '}
                        ,{' '}
                        <a
                          href="https://member.speedcash.co.id/cek-bpjs-kesehatan"
                          rel="dofollow"
                          title="cek tagihan bpjs"
                        >
                          bayar tagihan BPJS Kesehatan
                        </a>{' '}
                        dan semua kebutuhan tagihan bulanan tentunya dengan poin setiap transaksi
                        semakin bikin betah pakai SpeedCash.
                      </p>
                    </Row>
                    <Row className="d-flex justify-content-start my-1 mx-2 w-100">
                      <ul className="text-justify list-content">
                        <li>Gratis notifikasi pengingat tagihan bulanan</li>
                        <li>
                          Tidak hanya untuk pajak kendaraan online saja, tapi lengkap dengan fitur
                          beli pulsa, paket data, top up gopay, ovo, shopeepay, dana, game online
                          dll
                        </li>
                        <li>Transfer uang ke semua bank gratis biaya admin</li>
                        <li>Tiket pesawat garansi harga termurah</li>
                        <li>Tiket Kereta Api gratis biaya admin & layanan</li>
                        <li>Pasti Dapat Diskon disetiap transaksi</li>
                        <li>Bisa cetak struk / transaksi pembayaran</li>
                        <li>Bisa custom harga juga loh!</li>
                        <li>Downloadnya aplikasinya gratis 100%</li>
                        <li>Tidak perlu punya rekening bank pribadi</li>
                      </ul>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="10"
                    className="button-collapse w-100 text-left"
                  >
                    Sekilas Tentang Cek Pajak Kendaraan Online Samsat Jatim
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="10">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Cek pajak kendaraan online di Jawa Timur, yang dikenal dengan nama E-Samsat,
                        merupakan inovasi yang bertujuan untuk mempermudah masyarakat dalam
                        mengakses informasi dan melakukan pembayaran Pajak Kendaraan Bermotor (PKB)
                        secara efisien.
                      </p>
                      <p className="d-block text-justify mt-1">
                        Layanan E-Samsat jatim resmi diperkenalkan di Jawa Timur pada tahun 2020.
                        Ini merupakan langkah strategis untuk mendukung program pemerintah dalam
                        mempermudah akses layanan publik dan meningkatkan kepatuhan masyarakat
                        terhadap kewajiban pajak.
                      </p>
                      <p className="d-block text-justify mt-1">
                        Melalui E-Samsat, pemilik kendaraan dapat melakukan cek pajak kendaraan
                        secara online dengan mengakses website resmi atau aplikasi yang disediakan.
                        Pengguna hanya perlu memasukkan nomor polisi dan informasi kendaraan jatim
                        untuk mendapatkan rincian pajak yang harus dibayar.
                      </p>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="11"
                    className="button-collapse w-100 text-left"
                  >
                    Cara Cek Pajak Kendaraan Jatim Di Situs Resmi Bapenda ?
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="11">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Bagi sebagai masyarakat yang masih familiar untuk bayar pajak kendaraan di
                        samsat tentunya akan sangat melekat dengan bapenda jatim, karena samsat
                        jatim berada dibawah pengawasan bapenda maka untuk cek pajak kendaraan
                        online di situs resmi bapenda jatim juga bisa dilakukan, ini caranya :
                      </p>
                    </Row>
                    <Row className="d-flex justify-content-start my-1 mx-2 w-100">
                      <ol className="text-justify list-content">
                        <li>kunjungi situs : https://bapenda.jatimprov.go.id/info/pkb</li>
                        <li>masukkan plat nomor kerndaraan</li>
                        <li>masukkan 5 digit terakhir nomor rangka kendaraan</li>
                        <li>jangan lupa masukkan juga kode captcha yang diminta</li>
                        <li>lalu klik submit, dan ikuti instruksi selanjutnya hingga selesai</li>
                      </ol>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="12"
                    className="button-collapse w-100 text-left"
                  >
                    Cara Melihat Info Pajak Kendaraan Bermotor Jawa timur (PKB Jatim) ?
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="12">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Tidak sedikit masyarakat yang ingin besaran biaya pajak kendaraan yang harus
                        dibayar tiap tahunnya, oleh sebab itu dipenda jatim (dinas pendapatan daerah
                        jawa timur) memberikan kemudahan untuk bisa melihat informasi pajak
                        kendaraan bermotor, caranya sebagai berikut :
                      </p>
                    </Row>
                    <Row className="d-flex justify-content-start my-1 mx-2 w-100">
                      <ol className="text-justify list-content">
                        <li>
                          kunjungi situs :https://info.dipendajatim.go.id/index.php?page=info_pkb
                        </li>
                        <li>masukkan plat nomor polisi</li>
                        <li>dan 5 digit terakhir nomor rangka kendaraan</li>
                        <li>jangan lupa masukkan juga kode captcha yang diminta</li>
                        <li>
                          lalu klik cari maka nanti akan terlihat info pajak kendaraan bermotor yang
                          kamu cari
                        </li>
                      </ol>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="13"
                    className="button-collapse w-100 text-left"
                  >
                    Cara Mengetahui Info Nilai Jual Kendaraan Bermotor (NJKB)
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="13">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Buat kamu yang inign tahu tentang nilai jual kendaraan bermotor, kamu juga
                        bisa mengakses melalui situs resmi dipenda jatim, begini langkahnya :
                      </p>
                    </Row>
                    <Row className="d-flex justify-content-start my-1 mx-2 w-100">
                      <ol className="text-justify list-content">
                        <li>
                          Masuk ke url : https://info.dipendajatim.go.id/index.php?page=info_njkb
                        </li>
                        <li>Input jenis kendaraan</li>
                        <li>Dan model kendaraan</li>
                        <li>Lanjutkan merk kendaraan </li>
                        <li>Dan juga type kendaraan dan tahun pembuatannya ya!</li>
                      </ol>
                    </Row>
                    <Row>
                      <p className="d-block text-justify mt-1">
                        Jika sudah terisi semua jangan lupa submit kode captcha dan klik cari, nanti
                        akan tampil info nilai jual kendaraan bermotor milikmu.
                      </p>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="14"
                    className="button-collapse w-100 text-left"
                  >
                    Sudah Bayar Dan Pajak Kendaraan Di Jatim, Lalu Gimana Cara Cetak File E-TBPKP ?
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="14">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Bagi masyarakat jawa timur yang mencoba cek pajak kendaraan jatim dan bayar
                        online di e-samsat atau melalui e-wallet seperti SpeedCash tapi masih
                        bingung harus melakukan apa setelah pajak terbayar, maka selanjutnya bisa
                        cetak langsung file E-TBPKP, yaitu tanda bukti pelunasan kewajiban
                        pembayaran
                      </p>
                      <p className="d-block text-justify mt-1">
                        file e-tbpkp biasanya akan dikirim berupa link melalui sms dari samsat
                        jatim, namun jika belum menerima sms dari samsat jatim tenang jangan
                        khawatir kamu bisa cetak sendiri file e-tbpkp, ini caranya :
                      </p>
                    </Row>
                    <Row className="d-flex justify-content-start my-1 mx-2 w-100">
                      <ol className="text-justify list-content">
                        <li>
                          Masuk ke web dipenda jatim yang beralamtkan di :
                          https://info.dipendajatim.go.id/index.php?page=info bpkp
                        </li>
                        <li>lalu masukkan nomor kode bayar yang sudah didapatkan</li>
                        <li>masukkan kode captcha dan klik cari</li>
                      </ol>
                    </Row>
                    <Row>
                      <p className="d-block text-justify mt-1">
                        ikuti instruksinya hingga selesai hingga akan tampil file E-TBPKP yang bisa
                        kamu cetak sendiri
                      </p>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="15"
                    className="button-collapse w-100 text-left"
                  >
                    Daftar Kode Plat Kendaraan Wilayah Jatim
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="15">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Agar kamu tidak salah tempat dan tau lebih jelas mengenai kode plat nomor
                        kendaraan bermotor yang berada di wilayah jawa timur, kamu harus melihat
                        tabel berikut.
                      </p>
                    </Row>
                    <Row>
                      <table className="my-2 table table-bordered">
                        <tr>
                          <th className="bold">Kode Kendaraan</th>
                          <th className="bold">Wilayah / Daerah</th>
                        </tr>
                        <tr>
                          <td>Plat AG</td>
                          <td>Blitar, Kediri, Nganjuk, Trenggalek, dan Tulungagung.</td>
                        </tr>
                        <tr>
                          <td>Plat AE</td>
                          <td>Madiun, Magetan, Ngawi, Pacitan, dan Ponorogo.</td>
                        </tr>
                        <tr>
                          <td>Plat L</td>
                          <td>Surabaya (ibu kota jawa timur)</td>
                        </tr>
                        <tr>
                          <td>Plat M</td>
                          <td>Bangkalan, Pamekasan, Sampang, dan Sumenep, (Pulau Madura)</td>
                        </tr>
                        <tr>
                          <td>Plat N</td>
                          <td>Batu, Lumajang, Malang, Pasuruan, dan Probolinggo.</td>
                        </tr>
                        <tr>
                          <td>Plat S</td>
                          <td>Bojonegoro, Jombang, Lamongan, Mojokerto, dan Tuban.</td>
                        </tr>
                        <tr>
                          <td>Plat W</td>
                          <td>Gresik dan Sidoarjo.</td>
                        </tr>
                        <tr>
                          <td>Plat P</td>
                          <td>Banyuwangi, Bondowoso, Jember dan Situbondo.</td>
                        </tr>
                      </table>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="16"
                    className="button-collapse w-100 text-left"
                  >
                    Lalu Apa Saja Layanan Yang Ada Di Kantor Samsat ?
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="16">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Mungkin di benak sebagian besar masyarakat jika mendengar kata samsat hal
                        yang timbul dipikiran tempat bayar pajak motor ataupun mobil, lebih dari
                        sekedar itu ternyata ada beberapa layanan samsat yang harus diketahui agar
                        tidak salah saat berkunjung ke samsat. Berikut layanan samsat yang wajib
                        diketahui :
                      </p>
                    </Row>
                    <Row className="mt-1">
                      <b>Pembayaran Pajak Kendaraan Bermotor (PKB):</b>
                      <p className="d-block text-justify">
                        Mungkin di benak sebagian besar masyarakat jika mendengar kata samsat hal
                        yang timbul dipikiran tempat bayar pajak motor ataupun mobil, lebih dari
                        sekedar itu ternyata ada beberapa layanan samsat yang harus diketahui agar
                        tidak salah saat berkunjung ke samsat. Berikut layanan samsat yang wajib
                        diketahui :
                      </p>
                    </Row>
                    <Row className="mt-1">
                      <b>Bea Balik Nama Kendaraan Bermotor (BBN-KB)</b>
                      <p className="d-block text-justify">
                        Bea Balik Nama Kendaraan Bermotor (BBN-KB) adalah prosedur yang dilakukan
                        ketika sebuah kendaraan ditransfer dari pemilik sebelumnya ke pemilik baru,
                        di mana mereka perlu mengubah nama kendaraan agar terdaftar atas nama
                        mereka.
                      </p>
                    </Row>
                    <Row className="mt-1">
                      <b>Pengesahan STNK Tahunan</b>
                      <p className="d-block text-justify">
                        Setiap tahun, pengesahan STNK tahunan harus diperpanjang, dan SAMSAT
                        memastikan layanan ini tetap aktif dan sesuai ketentuan.
                      </p>
                    </Row>
                    <Row className="mt-1">
                      <b>Perpanjangan STNK Lima Tahunan</b>
                      <p className="d-block text-justify">
                        Perpanjangan STNK Lima Tahunan: Selain pengesahan tahunan, STNK harus
                        diperbarui melalui pemeriksaan fisik dan penggantian plat nomor kendaraan
                        setiap lima tahun.
                      </p>
                    </Row>
                    <Row className="mt-1">
                      <b>Pelayanan Asuransi Kecelakaan Lalu Lintas</b>
                      <p className="d-block text-justify">
                        Untuk melindungi pemilik kendaraan dan penumpang dari risiko kecelakaan,
                        SAMSAT menyediakan layanan asuransi kecelakaan lalu lintas melalui Jasa
                        Raharja.
                      </p>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="17"
                    className="button-collapse w-100 text-left"
                  >
                    Lalu Apa Saja Jenis Samsat ?
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="17">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Jika dulu masyarakat hanya tau samsat sebagai tempat bayar pajak kendaraan
                        tahunan dan lima tahunan, sekarang masyarakat harus mulai tau bahwa ada
                        beberapa jenis samat yang bisa dimanfaatkan untuk cek dan bayar pajak
                        kendaaraan baik itu secara online maupun offline :
                      </p>
                    </Row>
                    <Row className="mt-1">
                      <b>Samsat Online Nasional</b>
                      <p className="d-block text-justify">
                        Layanan SAMSAT Online Nasional memungkinkan pemilik mobil membayar pajak dan
                        mengesahkan STNK secara online, yang menghemat waktu dan tenaga.
                      </p>
                    </Row>
                    <Row className="mt-1">
                      <b>Samsat Keliling</b>
                      <p className="d-block text-justify">
                        Untuk membantu orang-orang yang jauh dari kantor SAMSAT utama, unit layanan
                        bergerak SAMSAT Keliling berkeliling di berbagai wilayah untuk melayani
                        perpanjangan pajak kendaraan tahunan.
                      </p>
                    </Row>
                    <Row className="mt-1">
                      <b>Samsat Drive Thru</b>
                      <p className="d-block text-justify">
                        Pemilik kendaraan dapat menyelesaikan pengesahan STNK dan pembayaran PKB
                        dengan SAMSAT Drive Thru tanpa turun dari kendaraan.
                      </p>
                    </Row>
                    <Row className="mt-1">
                      <b>Samsat Digital Nasional (Signal)</b>
                      <p className="d-block text-justify">
                        Sebuah aplikasi yang diluncurkan untuk memberikan memudahkan masyarakat
                        Indonesia melakukan Pengesahan STNK Tahunan, Pembayaran Pajak Kendaran
                        Bermotor (PKB) dan Sumbangan Dana Wajib Kecelakaan Lalu Lintas Jalan
                        (SWDKLLJ) secara berani dengan terbitnya dokumen digital berupa E-Pengesahan
                        (POLRI), E- TBPKP (Bapenda Provinsi) dan E-KD (PT. Jasa Raharja).
                      </p>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="18"
                    className="button-collapse w-100 text-left"
                  >
                    Undang-Undang Pajak Kendaraan Bermotor (PKB)
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="18">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Peraturan pertama untuk pembayaran pajak kendaraan bermotor di tingkat
                        daerah adalah Undang-Undang Nomor 28 Tahun 2009 tentang Pajak Daerah dan
                        Retribusi Daerah. Undang-undang ini mencakup pajak kendaraan bermotor
                        sebagai salah satu jenis pajak daerah yang dikelola oleh pemerintah
                        provinsi. Selain itu, undang-undang ini mengatur tarif, objek pajak, dan
                        subjek pajak kendaraan bermotor.
                      </p>
                      <p className="d-block text-justify mt-1">
                        Peraturan Pemerintah Nomor 65 Tahun 2001 tentang Pajak Daerah mengatur
                        pelaksanaan pajak daerah, termasuk PKB, meskipun UU Nomor 28 Tahun 2009
                        telah diberlakukan. Beberapa ketentuan dalam PP ini tetap relevan sebagai
                        pedoman teknis.
                      </p>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="19"
                    className="button-collapse w-100 text-left"
                  >
                    Kendala Saat Bayar Dan Cek Pajak Kendaraan Jatim Online
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="19">
                  <Card.Body className="card-content">
                    <Row>
                      <b>Cek Pajak Kendaraan Jatim Di SpeedCash (nomor tagihan tidak ditemukan)</b>
                      <p className="d-block text-justify">
                        Jika saat cek pajak motor atau mobil di SpeedCash muncul tulisan peringatan
                        nomor tagihan tidak ditemukan, langkah pertama silahkan cek kembali nomor
                        polisi anda jika sudah benar dan masih muncul peringatan tersebut silahkan
                        hubungi samsat induk di kota anda untuk menanyakan status nomor polisi
                        kendaraan anda.
                      </p>
                    </Row>
                    <Row className="mt-1">
                      <b>Pembayaran Pajak Kendaraan Online Gagal</b>
                      <p className="d-block text-justify">
                        Jika pembayaran tagihan pajak Samsat di SpeedCash gagal, dana akan
                        dikembalikan ke akun Anda. Silakan coba kembali pembayaran dalam beberapa
                        saat, dan jika masih gagal, silakan hubungi live chat Customer Service
                        SpeedCash. Anda juga dapat menggunakan Saldo SpeedCash untuk membeli atau
                        membayar berbagai produk yang ada di aplikasi.
                      </p>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="20"
                    className="button-collapse w-100 text-left"
                  >
                    Apakah Cek Pajak Kendaraan Jatim Via SpeedCash Bisa 24 Jam ?
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="20">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Dengan hadirnya{' '}
                        <a
                          href="https://www.speedcash.co.id/"
                          rel="dofollow"
                          title="dompet digital terbaik di  indonesia"
                        >
                          dompet digital
                        </a>{' '}
                        SpeedCash maka kamu tidak perlu lagi harus repot antri datang ke samsat,
                        karena sekarang semua kemudahan untuk bayar atau cekpajak kendaraan jatim
                        sudah bisa dilakukan via aplikasi maupun tanpa aplikasi (website)
                      </p>
                      <p className="d-block text-justify mt-1">
                        Selama kamu terhubung dengan internet maka kamu bisa cek pajak kendaraan
                        online 24 jam di aplikasi ataupun website.
                      </p>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="21"
                    className="button-collapse w-100 text-left"
                  >
                    Apa Saja Yang Diperlukan Untuk Cek Pajak Kendaraan Jatim Online ?
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="21">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Hadir dengan membawa kemudahan pokoknya anti ribet deh ! jika kamu mau cek
                        dan bayar pajak kendaraan motor / mobil jatim melalui aplikasi
                        SpeedCash,kamu cukup masukkan data yang ada di STNK serta KTP, no HP maka
                        nanti akan muncul total tagihan pajak kendaraan yang harus kamu bayar
                      </p>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="22"
                    className="button-collapse w-100 text-left"
                  >
                    Jika Sudah Bayar Di SpeedCash Apa Yang Harus Saya Lakukan ?
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="22">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Jika pembayaran pajak kendaraan online di SpeedCash sudah berhasil, kamu
                        akan mendapatkan bukti bayar pajak kendaraan dan juga akan mendapat SMS yang
                        berisi link e-TBPKP (tanda bukti pelunasan Pajak Kendaraan) yang bisa kamu
                        cetak secara mandiri, ataupun kamu bisa datang ke samsat terdekat untuk
                        cetak struk pajak kendaraan terbaru
                      </p>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="23"
                    className="button-collapse w-100 text-left"
                  >
                    Lalu Apa Itu File E-STNK
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="23">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        E-STNK atau Elektronik STNK adalah versi digital dari Surat Tanda Nomor
                        Kendaraan (STNK) yang disediakan dalam bentuk aplikasi atau file digital.
                        E-STNK dikembangkan untuk mempermudah pemilik kendaraan dalam mengakses dan
                        menyimpan data STNK tanpa perlu membawa dokumen fisik.
                      </p>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="24"
                    className="button-collapse w-100 text-left"
                  >
                    Apakah File E-STNK Sah ?
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="24">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        e-STNK atau STNK elektronik dianggap sah dan memiliki kekuatan hukum selama
                        dikeluarkan oleh pihak berwenang, seperti kepolisian atau Dinas Pendapatan
                        Daerah yang bekerja sama dengan SAMSAT. Kementerian Dalam Negeri dan
                        Kepolisian Republik Indonesia telah mulai mendukung digitalisasi dokumen
                        kendaraan sebagai bagian dari upaya modernisasi layanan publik dan
                        administrasi kendaraan.
                      </p>
                      <p className="d-block text-justify mt-1">
                        Namun, keabsahan e-STNK juga tergantung pada peraturan di masing-masing
                        daerah dan penerapan peraturan di lapangan oleh kepolisian. Saat ini,
                        beberapa wilayah di Indonesia sudah mulai mengakui e-STNK, terutama di
                        wilayah yang mendukung pembayaran dan pengecekan pajak kendaraan secara
                        online.
                      </p>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="25"
                    className="button-collapse w-100 text-left"
                  >
                    Daftar Lokasi Kantor Samsat Semua Kota Di Jatim
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="25">
                  <Card.Body className="card-content">
                    <Row className="d-flex flex-column justify-content-start w-100">
                      <b className="mb-2">Kantor Bersama Samsat Jombang</b>
                      <p>Alamat</p>
                      <p>Janti, Kec. Jogoroto, Jombang, Jawa Timur, Indonesia 61485</p>
                      <p>Layanan informasi dan pengaduan : 0858 1551 1614</p>
                    </Row>
                    <Row className="d-flex flex-column justify-content-start w-100">
                      <b className="mb-2">Samsat Payment Point Karangrejo</b>
                      <p>Alamat</p>
                      <p>
                        Jl. Raya Ngawi No.73, Prampelan, Kec. Karangrejo, Kabupaten Magetan, Jawa
                        Timur 63395, Indonesia (halaman kantor Kec.Karangrejo Magetan)
                      </p>
                      <p>Jadwal</p>
                      <p>
                        Senin - Kamis : 08.00-12.00 Jum&apos;at : 08.00 - 11.00 Sabtu : 08.00-12.00
                      </p>
                    </Row>
                    <Row className="d-flex flex-column justify-content-start w-100">
                      <b className="mb-2">Payment Point Padangan</b>
                      <p>Alamat</p>
                      <p>Jl. A. Yani No.80, Kuncen, Kec. Padangan, Kabupaten Bojonegoro</p>
                      <p>Jadwal</p>
                      <p>Senin - Kamis : 08.00 - 12.00 | Jum&apos;at - Sabtu : 08.00 - 10.30</p>
                    </Row>
                    <Row className="d-flex flex-column justify-content-start w-100">
                      <b className="mb-2">KB (kantor bersama) Samsat Madiun Kota</b>
                      <p>Alamat</p>
                      <p>Jl. Serayu No.86, Pandean, Kec. Taman, Kota Madiun, Jawa Timur 63133</p>
                      <p>Jadwal</p>
                      <p>
                        Senin - Kamis : Pkl. 08.00 - 13.00 WIB, Jum&apos;at : Pkl. 08.00 - 11.00
                        WIB, dan Sabtu : Pkl. 08.00 - 12.00 WIB
                      </p>
                    </Row>
                    <Row className="d-flex flex-column justify-content-start w-100">
                      <b className="mb-2">KB Samsat Madiun Kabupaten</b>
                      <p>Alamat</p>
                      <p>
                        Jl. Mayjend D I Panjaitan 14 Taman, Pandean, Kec. Taman, Kota Madiun, Jawa
                        Timur 63163
                      </p>
                      <p>Jadwal</p>
                      <p>
                        Senin - Kamis : Pkl. 08.00 - 13.00 WIB Jumat : Pkl. 08.00 - 11.00 WIB Sabtu
                        : Pkl. 08.00 - 13.00 WIB
                      </p>
                    </Row>
                    <Row className="d-flex flex-column justify-content-start w-100">
                      <b className="mb-2"> Payment Point Ngraho</b>
                      <p>Alamat</p>
                      <p>
                        Jl. Nasional 20, Dusun Blimbing Gede, Blimbinggede, Kec. Ngraho, Kabupaten
                        Bojonegoro
                      </p>
                      <p>Jadwal</p>
                      <p>Senin - Kamis : 08.00 - 12.00 | Jum&apos;at - Sabtu : 08.00 - 10.30</p>
                    </Row>
                    <Row className="d-flex flex-column justify-content-start w-100">
                      <b className="mb-2">Payment Point Basuki Rahmat</b>
                      <p>Alamat</p>
                      <p>Jl. Basuki Rahmat No.44, Mojo Kp. Mojokampung, Kec. Bojonegoro</p>
                      <p>Jadwal</p>
                      <p>Senin - Kamis : 08.00 - 12.00 | Jum&apos;at - Sabtu : 08.00 - 10.30</p>
                    </Row>
                    <Row className="d-flex flex-column justify-content-start w-100">
                      <b className="mb-2">MPP Polres Mojokerto Kota</b>
                      <p>Alamat</p>
                      <p>
                        Jl. Bhayangkara No.25, Mergelo, Sentanan, Kec. Magersari, Kota Mojokerto,
                        61312
                      </p>
                      <p>Jadwal</p>
                      <p>Senin - Sabtu = 09.00 - 14.00 WIB</p>
                    </Row>
                    <Row className="d-flex flex-column justify-content-start w-100">
                      <b className="mb-2">
                        {' '}
                        Samling (samsat keliling) Mandiri (Balai Desa Poh Jejer Gondang)
                      </b>
                      <p>Alamat</p>
                      <p>
                        Jl. Raya Pohjejer No.56, Pohjejer, Kec. Gondang, Kabupaten Mojokerto, 61372
                      </p>
                      <p>Jadwal</p>
                      <p>Kamis = 08.00 - 12.00 WIB</p>
                    </Row>
                    <Row className="d-flex flex-column justify-content-start w-100">
                      <b className="mb-2">Samsat Induk Sidoarjo Kota</b>
                      <p>Alamat</p>
                      <p>
                        Jl. Raya Cemeng Kalang No.12, Ngemplak, Cemeng Kalang, Kec. Sidoarjo,
                        Kabupaten Sidoarjo, Jawa Timur 61234
                      </p>
                      <p>Jadwal</p>
                      <p>Senin-Kamis: 08.00 - 13.00 Jumat: 08.00 - 11.30 Sabtu: 08.00 - 12.00</p>
                    </Row>
                    <Row className="d-flex flex-column justify-content-start w-100">
                      <b className="mb-2">Samsat Induk Krian</b>
                      <p>Alamat</p>
                      <p>
                        Jl. Raya Kemasan No.17, Kemasan, Kec. Krian, Kabupaten Sidoarjo, Jawa Timur
                        61262
                      </p>
                      <p>Jadwal</p>
                      <p>Senin-Kamis: 08.00 - 13.00 Jumat: 08.00 - 11.30 Sabtu: 08.00 - 12.00</p>
                    </Row>
                    <Row className="d-flex flex-column justify-content-start w-100">
                      <b className="mb-2">Samsat Surabaya Barat</b>
                      <p>Alamat</p>
                      <p>
                        Jl. Raya Tandes Lor No.1, Tanjungsari, Kec. Sukomanunggal, Kota SBY, Jawa
                        Timur 60187
                      </p>
                      <p>Jadwal</p>
                      <p>
                        Senin – Kamis: 08.00 – 13.00 Jum’at: 08.00 – 11.00 Sabtu: 08.00 – 12.00
                        Minggu: Tutup
                      </p>
                    </Row>
                    <Row className="d-flex flex-column justify-content-start w-100">
                      <b className="mb-2">Payment Point Prajekan</b>
                      <p>Alamat</p>
                      <p>
                        JL. Raya Situbondo, Seraten I, Prajekan Kidul, Kec. Prajekan, Kabupaten
                        Bondowoso, Jawa Timur 68285
                      </p>
                    </Row>
                    <Row className="mt-5">
                      <p className="d-block text-justify mt-1">
                        Itulah tadi informasi mengenai cek pajak kendaraan jatim dan bayare-samsat
                        langsung di SpeedCash.Jika kamu merasa terbantu dengan informasi ini maka
                        kamu bisa coba download langsung aplikasi SpeedCash melalui link download
                        yang tersedia dibawah ini.
                      </p>
                      <div className="mt-1 w-100 d-flex justify-content-center">
                        <h5 className="bold">
                          “Ora Antri” Cek Pajak Kendaraan Jatim Online Bayar E-Samsat Disini
                        </h5>
                      </div>
                    </Row>
                    <Row className="d-flex w-100 justify-content-center mb-3 gap-3">
                      <a
                        aria-hidden
                        href="https://play.google.com/store/apps/details?id=com.bm.sc.bebasbayar&listing=bayarpajak_samsatjatim_online"
                        className="mx-1"
                        rel="nofollow"
                        title="cek pajak kendaraan online jawa timur"
                      >
                        <Image
                          src="/img/playstore-speedcash.png"
                          data-target="playstore-speedcash"
                          width="220px"
                          height="60px"
                        />
                      </a>
                      <a
                        aria-hidden
                        href="https://apps.apple.com/id/app/speedcash-transfer-ewallet/id6459304122"
                        className="mx-1"
                        rel="nofollow"
                        title="cek pajak kendaraan online jawa timur"
                      >
                        <Image
                          src="/img/appstore-speedcash.png"
                          data-target="appstore-speedcash"
                          width="220px"
                          height="60px"
                        />
                      </a>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default AccordionPkbJatim
