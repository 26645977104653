import React from 'react'
import { Button, Image, Row, Col, Accordion, Card } from 'react-bootstrap'
import './accordion.scss'

const AccordionPascaTelkomsel = () => {
  return (
    <>
      <Col className="d-flex justify-content-center mt-4 mb-3 p-3">
        <h3 className="text-dark text-justify">
          Cek Tagihan Kartu Halo Bayar Langsung Diskon Disini
        </h3>
      </Col>

      <div style={{ padding: '0px 20px 30px 20px' }}>
        <Row className="justify-content-md-center">
          <Col lg={8} xs={12} md={8} className="content-component">
            <div>
              <Row className="d-flex">
                <Row>
                  <p className="d-block text-justify">
                    Kartu halo merupakan solusi praktis yang ditawarkan telkomsel kepada pelanggan
                    yang tidak ingin repot untuk mengisi pulsa jika sudah kehabisan maupun pelanggan
                    yang ingin mempunyai paket internet maupun telepon secara otomatis.Namun
                    pelanggan kartu halo telkomsel juga tidak boleh untuk melupakan kewajiban setiap
                    bulannya yaitu cek tagihan kartu halo karena kartu halo merupakan layanan
                    pascabayar dari telkomsel yang wajib dibayar setiap bulannya.
                  </p>
                </Row>
                <Row className="d-flex justify-content-center my-2">
                  <Image
                    src="/img/telkomsel.png"
                    data-target="telkomsel"
                    alt="telkomsel"
                    width="100%"
                  />
                </Row>
                <div className="mt-5">
                  <Row className="d-flex justify-content-center text-dark">
                    <h4>Cek Total Tagihan Kartu Halo Telkomsel</h4>
                  </Row>
                  <Row>
                    <p className="d-block text-justify">
                      Untuk melihat total tagihan kartu halo telkomsel setiap bulannya juga sangat
                      mudah, kamu bisa menggunakan SpeedCash. Berikut cara cek tagihan kartu halo
                      dan bayar telkomsel di SpeedCash tanpa aplikasi :
                    </p>
                    <Row className="mt-2">
                      <ul className="text-justify list-content">
                        <li>
                          Kunjungi alamat web :
                          <a href="https://member.speedcash.co.id/pascabayar/telkomsel-halo">
                            https://member.speedcash.co.id/pascabayar/telkomsel-halo
                          </a>
                        </li>
                        <li>Pilih menu PASCABAYAR</li>
                        <li>Pilih produk pascabayar TELKOMSEL</li>
                        <li>Masukkan nomor HP</li>
                        <li>Klik cek tagihan sekarang</li>
                        <li>Ikuti instruksi selanjutnya hingga pembayaran berhasil</li>
                      </ul>
                    </Row>
                  </Row>
                </div>
              </Row>
            </div>
          </Col>

          <Col lg={8} xs={12} md={8} className="accordion-component">
            <Accordion className="m-0 p-0">
              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="1"
                    className="button-collapse w-100 text-left"
                  >
                    Cek Tagihan Kartu Halo Telkomsel Via Aplikasi
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="1">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Ternyata ada cara yang lebih mudah untuk cek tagihan kartu halo telkomsel
                        pacabayar, yaitu menggunakan aplikasi SpeedCash.berikut caranya :
                      </p>
                      <Row className="mt-3">
                        <ul className="text-justify list-content">
                          <li>Download aplikasi SpeedCash Di Playstore Ataupun Appstore</li>
                          <li>
                            JIka sudah login dulu menggunakan nomor HP (jika belum bisa login maka
                            kamu wajib daftar dulu menggunakan nomor HP)
                          </li>
                          <li>
                            Jika sudah bisa login lanjutkan dengan klik menu TELEPON PASCABAYAR
                          </li>
                          <li>
                            <p>Pilih produk TELKOMSEL (HALO)</p>
                            <Row className="d-flex justify-content-center">
                              <Image
                                src="/img/mockup-telkomsel.png"
                                data-target="bayar pasca telkomsel halo"
                                width="220px"
                                height="400px"
                              />
                            </Row>
                          </li>

                          <li>Masukkan Nomor HP</li>
                          <li>
                            Klik lanjutkan dan ikuti instruksi selanjutnya hingga pembayaran
                            berhasil
                          </li>
                        </ul>
                      </Row>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="2"
                    className="button-collapse w-100 text-left"
                  >
                    Bayar Tagihan Telkomsel Pascabayar Dapat Diskon
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="2">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Ternyata ketika kamu mengecek tagihan kartu halo telkomsel dan bayar tagihan
                        via SpeedCash kamu bakal mendapatkan diskon langsung.Diskon bayar tagihan
                        ini juga berlaku untuk semua layanan yang ada di SpeedCash seperti :{' '}
                        <a href="https://member.speedcash.co.id/pln/cek-tagihan-listrik">
                          bayar tagihan listrik PLN
                        </a>
                        , <a href="https://member.speedcash.co.id/pdam">cek tagihan PDAM</a>,{' '}
                        <a href="https://member.speedcash.co.id/cek-bpjs-kesehatan">
                          cek tagihan BPJS
                        </a>
                        , <a href="https://member.speedcash.co.id/telkom">telkomsel indihome</a>
                        dan tentunya masih banyak lagi lainnya
                      </p>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="3"
                    className="button-collapse w-100 text-left"
                  >
                    Beli Pulsa Telkomsel Juga Bisa
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="3">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Ga cuma buat bayar tagihan telkomsel kartu halo pascabayar saja, tapi
                        menggunakan SpeedCash kamu juga bisa untuk beli pulsa telkomsel tersedia
                        nominal pulsa tekomsel mulai harga 5 ribu tersedia juga beragam paket
                        seperti paket reguler, paket transfer,paket telepon dan juga BY.U jika kamu
                        ingin beli masa aktif telkomsel juga bisa.
                      </p>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="4"
                    className="button-collapse w-100 text-left"
                  >
                    Mengapa Harus Cek Tagihan Telkomsel Pascabayar Disini
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="4">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Kartu halo merupakan layanan pascabayar telkomsel yang memudahkan para
                        penggunanya agar lebih praktis dalam mengakses layanan telkomsel tanpa harus
                        repot mengisi pulsa maupun membeli paket telepon dan juga internet.
                      </p>
                      <p className="d-block text-justify mt-1">
                        Saat ini untuk mengecek tagihan kartu halo telkomsel kamu bisa lakukan cukup
                        melalui 1 aplikasi atau bisa juga kamu lakukan via web browser yaitu dengan
                        SpeedCash.Keuntungan yang bisa kamu dapatkan jika menggunakan SpeedCash
                        untuk cek tagihan telkomsel pascabayar pastinya kamu mendapat diskon
                        langsung ketika melakukan bayar tagihan kartu halo disini.Dan tidak itu saja
                        kamu juga bisa mendapatkan diskon S.D 50.000 untuk membayar tagihan lainnya
                        seperti: tagihan listrik, tagihan indihome, tagihan PDAM dan masih banyak
                        lagi lainnya.
                      </p>
                      <p className="d-block text-justify mt-1">
                        Dan tidak hanya itu jika kamu menggunakan SpeedCash untuk bayar tagihan
                        telkomsel pascabayar kamu bisa mendapatkan struk/bukti bayar resmi tentunya
                        hal ini dapat kamu jadikan sebuah peluang usaha bagus untuk dijual kembali.
                      </p>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="5"
                    className="button-collapse w-100 text-left"
                  >
                    Cara Isi Saldo SpeedCash Untuk Bayar Tagihan Telkomsel
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="5">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Sebelum kamu menggunakan SpeedCash untuk mambayar tagihan telkomsel
                        pascabayar kartu halo,hal yang tidak boleh kamu lewatkan ialah mengisi saldo
                        SpeedCash dulu.Berikut video tutorial cara mengisi saldo SpeedCash.
                      </p>
                      <iframe
                        width="100%"
                        height="480"
                        src="https://www.youtube.com/embed/sI1fLYglLwk"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        frameBorder={0}
                        title="cara isi saldo SpeedCash"
                        className="my-3"
                      />
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="6"
                    className="button-collapse w-100 text-left"
                  >
                    Kendala Saat Bayar Dan Cek Tagihan Kartu Halo
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="6">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Ada beberapa kendala yang seringkali dihadapi saat akan melihat total
                        tagihan kartu halo bulanan, untuk mengatasi hal itu kamu tidak perlu panik
                        lagi karena di SpeedCash tersedia layanan live chat CS 24 jam yang siap
                        melayani kamu.Sehingga saat terjadi kendala kamu cukup klik menu live chat
                        yang tersedia di pojok bawah.
                      </p>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="7"
                    className="button-collapse w-100 text-left"
                  >
                    Info Mengenai Kartu Halo (Telkomsel Pascabayar)
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="7">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block-text-justify">
                        Pertama kali didirikan di dunia telekomunikasi Indonesia pada tahun 1995,
                        Kartu HALO merupakan kartu pascabayar terkemuka pertama di Indonesia. HALO
                        Card merupakan salah satu produk kartu yang disetujui oleh PT.
                        Telekomunikasi Seluler atau Telkom. Dianggap sebagai kartu pascabayar
                        terbaik di Indonesia, Kartu Halo memiliki jaringan sinyal yang kuat dengan
                        jaringan luas yang mencakup seluruh wilayah Indonesia.
                      </p>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="8"
                    className="button-collapse w-100 text-left"
                  >
                    Beberapa kelebihan memakai Kartu Halo adalah:
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="8">
                  <Card.Body className="card-content">
                    <Row>
                      <ol className="text-justify list-content">
                        <li>
                          Jaringan Luas: Kartu Halo menggunakan jaringan Telkomsel yang luas dan
                          stabil, sehingga Anda dapat tetap terhubung di berbagai lokasi di seluruh
                          Indonesia.
                        </li>
                        <li>
                          Beragam Paket: Telkomsel menyediakan beragam paket dengan berbagai kuota
                          internet, pulsa, dan layanan tambahan lainnya sesuai kebutuhan Anda.
                        </li>
                        <li>
                          Program Loyalti: Dengan Kartu Halo, Anda dapat mengakses program loyalitas
                          seperti TAU, yang memberikan berbagai keuntungan seperti diskon, promo
                          eksklusif, dan hadiah menarik.
                        </li>
                        <li>
                          Kualitas Layanan: Telkomsel dikenal karena kualitas layanannya yang baik,
                          termasuk layanan pelanggan yang responsif dan bantuan teknis yang cepat.
                        </li>
                        <li>
                          Akses Kecepatan Tinggi: Dengan Kartu Halo, Anda dapat menikmati akses
                          internet dengan kecepatan tinggi, memungkinkan untuk streaming video,
                          bermain game online, dan menjalankan aplikasi berat dengan lancar.
                        </li>
                      </ol>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="9"
                    className="button-collapse w-100 text-left"
                  >
                    Tarif Dan Paket Kartu Halo
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="9">
                  <Card.Body className="card-content">
                    <Row>
                      <table className="my-2 table table-bordered">
                        <tr>
                          <th className="bold">Paket Halo 80k</th>
                          <th aria-hidden />
                        </tr>
                        <tr>
                          <td>Internet</td>
                          <td>10 GB</td>
                        </tr>
                        <tr>
                          <td>Roaming Halo</td>
                          <td>100 MB</td>
                        </tr>
                        <tr>
                          <td>Telepon Ke Semua Operator</td>
                          <td>60 Menit</td>
                        </tr>
                        <tr>
                          <td>SMS Ke Semua Operator</td>
                          <td>100 SMS</td>
                        </tr>
                      </table>
                      <table className="my-2 table table-bordered">
                        <tr>
                          <th className="bold">Paket Halo 100k</th>
                          <th aria-hidden />
                        </tr>
                        <tr>
                          <td>Internet</td>
                          <td>15 GB</td>
                        </tr>
                        <tr>
                          <td>Roaming Halo</td>
                          <td>150 MB</td>
                        </tr>
                        <tr>
                          <td>Telepon Ke Semua Operator</td>
                          <td>120 Menit</td>
                        </tr>
                        <tr>
                          <td>SMS Ke Semua Operator</td>
                          <td>200 SMS</td>
                        </tr>
                      </table>
                      <table className="my-2 table table-bordered">
                        <tr>
                          <th className="bold">Paket Halo 150k</th>
                          <th aria-hidden />
                        </tr>
                        <tr>
                          <td>Internet</td>
                          <td>30 GB</td>
                        </tr>
                        <tr>
                          <td>Roaming Halo</td>
                          <td>200 MB</td>
                        </tr>
                        <tr>
                          <td>Telepon Ke Semua Operator</td>
                          <td>240 Menit</td>
                        </tr>
                        <tr>
                          <td>SMS Ke Semua Operator</td>
                          <td>400 SMS</td>
                        </tr>
                      </table>
                      <table className="my-2 table table-bordered">
                        <tr>
                          <th className="bold">Paket Halo 225k</th>
                          <th aria-hidden />
                        </tr>
                        <tr>
                          <td>Internet</td>
                          <td>50 GB</td>
                        </tr>
                        <tr>
                          <td>Roaming Halo</td>
                          <td>300 MB</td>
                        </tr>
                        <tr>
                          <td>Telepon Ke Semua Operator</td>
                          <td>400 Menit</td>
                        </tr>
                        <tr>
                          <td>SMS Ke Semua Operator</td>
                          <td>700 SMS</td>
                        </tr>
                      </table>
                      <table className="my-2 table table-bordered">
                        <tr>
                          <th className="bold">Paket Halo 300k</th>
                          <th aria-hidden />
                        </tr>
                        <tr>
                          <td>Internet</td>
                          <td>70 GB</td>
                        </tr>
                        <tr>
                          <td>Roaming Halo</td>
                          <td>400 MB</td>
                        </tr>
                        <tr>
                          <td>Telepon Ke Semua Operator</td>
                          <td>500 Menit</td>
                        </tr>
                        <tr>
                          <td>SMS Ke Semua Operator</td>
                          <td>1000 SMS</td>
                        </tr>
                      </table>
                      <table className="my-2 table table-bordered">
                        <tr>
                          <th className="bold">Paket Halo 550k</th>
                          <th aria-hidden />
                        </tr>
                        <tr>
                          <td>Internet</td>
                          <td>150 GB</td>
                        </tr>
                        <tr>
                          <td>Roaming Halo</td>
                          <td>500 MB</td>
                        </tr>
                        <tr>
                          <td>Telepon Ke Semua Operator</td>
                          <td>1000 Menit</td>
                        </tr>
                        <tr>
                          <td>SMS Ke Semua Operator</td>
                          <td>2000 SMS</td>
                        </tr>
                      </table>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="10"
                    className="button-collapse w-100 text-left"
                  >
                    Customer Service Telkomsel Halo
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="10">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Kamu dapat menghubungi Customer Service Kartu Halo di nomor 188 atau di
                        nomor 08071811811.
                      </p>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="11"
                    className="button-collapse w-100 text-left"
                  >
                    Cara Cek Nomor Kartu Halo Telkomsel
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="11">
                  <Card.Body className="card-content">
                    <Row>
                      <div className="w-100 my-1">
                        <p className="d-block text-justify">Cek nomor kartu Halo melalui USSD</p>
                        <ol className="text-justify list-content">
                          <li>
                            Ada beberapa cara untuk mengecek nomor kartu Halo. Yang pertama adalah
                            menggunakan Dial atau memasukkan beberapa nomor.
                          </li>
                          <li>
                            Tekan *808# lalu tekan tombol OK/YES di ponsel. Cara ini bisa digunakan
                            jika Anda lupa nomor kartu pintar atau nomor ponsel Halo Card Anda.
                          </li>
                          <li>Tekan *999# lalu pilih OK/YES dari ponsel Anda.</li>
                          <li>Setelah beberapa saat, menu utama akan muncul.</li>
                          <li>Anda dapat memilih informasi yang Anda butuhkan dari layar menu.</li>
                          <li>
                            Pilih informasi untuk memeriksa nomor Anda sendiri, jika Anda lupa atau
                            ingin memeriksa nomor kartu keberangkatan Anda.
                          </li>
                          <li>Ikuti saja petunjuknya dengan tepat.</li>
                        </ol>
                      </div>
                      <div className="w-100 my-1">
                        <p className="d-block text-justify">
                          Cara kedua adalah dengan meminta bantuan melalui call center. Cara ini
                          sangat sederhana namun Anda harus mengetahui dengan jelas nomor telepon
                          yang akan Anda hubungi nantinya. Berikut langkah-langkahnya
                        </p>
                        <ol className="text-justify list-content">
                          <li>
                            Call center atau layanan pelanggan tersedia 24 jam sehari, khusus 133
                            dan 188. Cukup masukkan nomor ini di ponsel Anda dan tunggu beberapa
                            saat hingga ponsel terhubung dengan operator atau layanan pelanggan
                            papan tombol. Bagi pengguna Kartu Halo dapat menghubungi nomor bebas
                            pulsa 133.
                          </li>
                          <li>
                            Layanan ini tersedia dengan tarif PSTN lokal. Anda dapat menghubungi
                            switchboard nasional di 08071811811. Jika Anda lupa membawa ponsel yang
                            berisi smart card Telkomsel, Anda dapat menghubungi switchboard dari
                            nomor operator jaringan lain.
                          </li>
                        </ol>
                      </div>
                      <div className="w-100 my-1">
                        <p className="d-block text-justify">
                          Cara cek nomor kartu Halo melalui aplikasi myTelkomsel
                        </p>
                        <p className="d-block text-justify mt -1">
                          Berikut cara mengecek di aplikasi MyTelkomsel :{' '}
                        </p>
                        <ol className="text-justify list-content">
                          <li>Daftarkan nomor kartu Halo Anda.</li>
                          <li>Setelah Daftar, silakan login.</li>
                          <li>
                            Lalu Akan muncul jendela baru di mana Anda akan menemukan informasi
                            tentang berbagai fitur yang dapat dipilih.
                          </li>
                          <li>Klik informasi yang ingin Anda ketahui.</li>
                          <li>
                            Tunggu sebentar, informasi detail nomor terkait akan segera muncul .
                          </li>
                        </ol>
                      </div>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="12"
                    className="button-collapse w-100 text-left"
                  >
                    Cara Cek Kuota Kartu Halo Telkomsel
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="12">
                  <Card.Body className="card-content">
                    <Row>
                      <div className="w-100 my-1">
                        <p className="d-block text-justify">
                          Untuk mengecek tagihan Kartu Halo, Anda dapat memilih beberapa cara. Yang
                          pertama adalah menggunakan aplikasi MyTelkomsel.
                        </p>
                        <ol className="text-justify list-content">
                          <li>
                            Pastikan Anda sudah mendownload aplikasi MyTelkomsel ke smartphone Anda.
                            Jika belum, Anda dapat menginstalnya melalui Playstore atau Appstore.
                          </li>
                          <li>Setelah diunduh, buka aplikasinya.</li>
                          <li>Daftarkan nomor ponsel Anda.</li>
                          <li>
                            Kemudian Anda dapat memeriksa Cek kuota Telkomsel yang Anda inginkan,
                            seperti internet, telepon, SMS, fasilitas, dll.
                          </li>
                        </ol>
                      </div>
                      <div className="w-100 my-1">
                        <p className="d-block text-justify">Cara kedua melalui SMS yaitu:</p>
                        <ol className="text-justify list-content">
                          <li>Masuk FLASH INFO.</li>
                          <li>Kirim ke 3636.</li>
                          <li>
                            Anda akan menerima balasan melalui SMS dengan detail kuota pendaftaran
                            nomor telepon anda.
                          </li>
                        </ol>
                      </div>
                      <div className="w-100 my-1">
                        <p className="d-block text-justify">
                          Cara ketiga melalui Dial, ini langkah paling sederhana dibandingkan cara
                          lainnya.{' '}
                        </p>
                        <ol className="text-justify list-content">
                          <li>Anda bisa langsung dial *888#</li>
                          <li>kan muncul jendela baru, pilih Centang Opsi Kuota.</li>
                          <li>
                            Kemudian akan muncul jendela baru lagi, pilih batas berlangganan yang
                            ingin Anda periksa.
                          </li>
                          <li>
                            Kemudian akan muncul notifikasi mengenai detail berlangganan paket
                            langsung di layar ponsel Anda.
                          </li>
                        </ol>
                      </div>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="13"
                    className="button-collapse w-100 text-left"
                  >
                    Cek Tagihan Kartu Halo Via My Telkomsel
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="13">
                  <Card.Body className="card-content">
                    <Row>
                      <ol className="text-justify list-content">
                        <li>
                          Pastikan Anda sudah mendownload aplikasi MyTelkomsel di smartphone Anda.
                          Jika tidak, Anda dapat menginstal aplikasi ini dari Playstore atau
                          Appstore.
                        </li>
                        <li>Setelah mengunduh, buka aplikasi.</li>
                        <li>
                          Bagi pengguna baru, Anda dapat melihat informasi pembayaran di menu
                          profil.{' '}
                        </li>
                        <li>
                          Sedangkan bagi pengguna lama, Anda dapat melihat informasi jumlah tagihan
                          kartu Halo pascabayar beserta pajaknya.
                        </li>
                      </ol>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="14"
                    className="button-collapse w-100 text-left"
                  >
                    Cek Tagihan Telkomsel Melalui kode USSD
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="14">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Cara ketiga adalah dengan menggunakan kode USSD, ini merupakan langkah
                        paling sederhana dan paling banyak digunakan oleh pengguna kartu Halo
                        pascabayar.
                      </p>
                      <div className="w-100 my-1">
                        <ol className="text-justify list-content">
                          <li>
                            Masuk ke menu panggilan dan ketik *111# kemudian Klik Telepon atau
                            Telepon
                          </li>
                          <li>Pilih menu status dan invoice</li>
                          <li>
                            Pilih informasi dan invoice, lalu periksa invoice kartu Halo Anda.
                          </li>
                          <li>
                            Untuk cara yang lebih singkat, Anda bisa langsung masuk ke *111*1*5*2#
                            lalu klik Panggil.
                          </li>
                        </ol>
                      </div>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="15"
                    className="button-collapse w-100 text-left"
                  >
                    S & K Pembayaran Telkomsel Pacabayar (Kartu Halo)
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="15">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Berikut Syarat dan Ketentuan pembayaran Tagihan Pascabayar Kartu Halo di
                        SpeedCash:
                      </p>
                      <div className="w-100 my-1">
                        <ol className="text-justify list-content">
                          <li>
                            Pastikan Anda sudah memiliki akun SpeedCash dan sudah melakukan
                            verifikasi nomor handphone.
                          </li>
                          <li>
                            Pengguna akan dikenakan biaya administrasi bergantung pada jenis Tagihan
                            Pascabayar Kartu Halo.
                          </li>
                          <li>
                            Biaya administrasi dikenakan per Tagihan Pascabayar Kartu Halo bulanan.
                            Jadi, jika Anda melakukan pembayaran 2 bulan Tagihan Pascabayar Kartu
                            Halo dalam satu transaksi, Anda akan dikenakan 2x biaya administrasi.
                          </li>
                          <li>
                            Dalam 1 transaksi, jumlah total Tagihan Pascabayar Kartu Halo yang
                            ditampilkan adalah seluruh komponen biaya meliputi akumulasi seluruh
                            Tagihan Pascabayar Kartu Halo bulanan belum terbayarkan, denda (bila
                            ada), dan biaya administrasi.
                          </li>
                          <li>
                            Apabila pembayaran Tagihan Pascabayar Kartu Halo dilakukan setelah
                            tanggal jatuh tempo setiap bulannya akan dikenakan denda. Penetapan
                            tanggal jatuh tempo bergantung pada partner Tagihan Pascabayar Kartu
                            Halo.
                          </li>
                          <li>
                            SpeedCash tidak bertanggung jawab atas kesalahan pembayaran Tagihan
                            Pascabayar Kartu Halo dikarenakan kesalahan input nomor pelanggan oleh
                            pengguna.
                          </li>
                          <li>
                            Pihak resmi SpeedCash tidak pernah meminta email dan password akun
                            pengguna. Mohon untuk tidak memberikan data tersebut ke pihak manapun.
                          </li>
                          <li>Harga dapat berubah sewaktu-waktu tanpa pemberitahuan.</li>
                          <li>
                            Proses verifikasi pembayaran Tagihan Pascabayar Kartu Halo bisa
                            membutuhkan waktu hingga 2×24 jam.
                          </li>
                          <li>
                            Informasi lebih lanjut seputar pembayaran dan hal berkaitan dengan
                            Tagihan Pascabayar Kartu Halo, Anda dapat menghubungi partner tagihan
                            secara langsung.
                          </li>
                        </ol>
                      </div>
                      <p className="d-block text-justify mt-1">
                        Itulah tadi informasi mengenai cek tagihan kartu halo telkomsel pascabayar
                        dan cara bayar tagihan telkomsel via online langsung di SpeedCash agar
                        mendapatkan Spesial Diskon setiap bulannya.
                      </p>
                      <p className="d-block text-justify mt-1">
                        Untuk memudahkan melihat total tagihan telkomsel pascabayar kartu halo kamu
                        bisa mendownload aplikasi SpeedCash melalui Link download Dibawah ini.
                      </p>

                      <Row className="d-flex w-100 justify-content-center mb-3 gap-3">
                        <a
                          aria-hidden
                          href="https://play.google.com/store/apps/details?id=com.bm.sc.bebasbayar&listing=speedcash_banner_app"
                          className="mx-1"
                        >
                          <Image
                            src="/img/playstore-speedcash.png"
                            data-target="playstore-speedcash"
                            width="220px"
                            height="60px"
                          />
                        </a>
                        <a
                          aria-hidden
                          href="https://apps.apple.com/id/app/speedcash-transfer-ewallet/id6459304122"
                          className="mx-1"
                        >
                          <Image
                            src="/img/appstore-speedcash.png"
                            data-target="appstore-speedcash"
                            width="220px"
                            height="60px"
                          />
                        </a>
                      </Row>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default AccordionPascaTelkomsel
