/* eslint-disable no-nested-ternary */
import React from 'react'
import { connect } from 'react-redux'
import { Row, Col, Image, Alert, Modal, Spinner } from 'react-bootstrap'
import PropTypes from 'prop-types'
import DOMHelper from '../../../helper/domhelper'
import HookHelper from '../../../helper/hookhelper'
import Formatter from '../../../helper/formatter'

import './deposit.scss'
import globalConstant from '../../../store/constant/global'
import authConstant from '../../../store/constant/auth'
import produkConstant from '../../../store/constant/produk'

import DepositVaModal from '../../dialog/deposit/va'
import MandiriPcModal from '../../dialog/deposit/mandiripc'
import DepositBankModal from '../../dialog/deposit/bank'

import { depositDetail } from '../../../api/action/deposit/deposit'

const VaScreen = ({ appUserId, appProdukVa, appIsLoading, dispatch }) => {
  const DEP_PC = 'MANDIRIPC'

  const [alertError, setAlertError] = React.useState('')
  const [depositVaModalShow, setDepositVaModalShow] = React.useState(false)
  const [depositBankModalShow, setDepositBankModalShow] = React.useState(false)
  const [mandiripcModalShow, setMandiripcModalShow] = React.useState(false)
  const [dataResDeposit, setDataResDeposit] = React.useState(null)
  const [dataResMandiriPc, setDataResMandiriPc] = React.useState(null)
  const [dataResDepositBank, setDataResDepositBank] = React.useState(null)

  const closeError = React.useCallback(() => setAlertError(''), [])

  const closeDepositBankModal = React.useCallback(async () => {
    setDepositBankModalShow(false)
    // window.location.reload()
  }, [])

  const closeVaBankModal = React.useCallback(async () => {
    setDepositVaModalShow(false)
  }, [])

  const closeMandiripcModal = React.useCallback(async () => {
    setMandiripcModalShow(false)
  }, [])

  const confirmMandiriPc = React.useCallback(async (response) => {
    setDataResDepositBank(response)
    setMandiripcModalShow(false)
    setDepositBankModalShow(true)
  }, [])

  const selectVa = React.useCallback(
    (e) => {
      const { va } = e.currentTarget.dataset
      const [filtered] = appProdukVa.filter((g) => `${g.bank}` === va)
      setDataResDeposit(filtered)
      setDepositVaModalShow(true)
    },
    [appProdukVa, setDataResDeposit, setDepositVaModalShow]
  )

  const selectMandiriPc = React.useCallback(async () => {
    const response = await dispatch(depositDetail(appUserId, DEP_PC))
    if (response.isOK()) {
      const resData = response.getData()
      setDataResMandiriPc(resData.data)
      setMandiripcModalShow(true)
    } else {
      setAlertError({ rc: response.getRC(), rd: response.getRD() })
    }
  }, [dispatch, DEP_PC, appUserId, setDataResMandiriPc, setMandiripcModalShow, setAlertError])

  React.useEffect(() => {
    DOMHelper.mountClass('form-ct-body')
    HookHelper.resetLoading()

    return () => DOMHelper.unmountClass(['form-ct-body'])
  }, [])

  return (
    <>
      <div className="form-ct-body tc">
        {appProdukVa ? (
          appProdukVa[0].va_title ? (
            <span className="ct-body-label">
              Deposit lebih cepat & praktis dengan menggunakan Virtual Account, tanpa batasan waktu
              Transfer
            </span>
          ) : (
            <span className="ct-body-label">
              Ada beberapa data Virtual Account anda yang gagal diambil. Mohon lakukan logout dan
              login kembali untuk merefresh data Virtual Account Anda.
            </span>
          )
        ) : (
          <span className="ct-body-label">
            Ada beberapa data Virtual Account anda yang gagal diambil. Mohon lakukan logout dan
            login kembali untuk merefresh data Virtual Account Anda.
          </span>
        )}
        <Row className="payment-code">
          {appProdukVa
            ? appProdukVa[0].va_title
              ? appProdukVa.map((item) => (
                  <Col
                    className="listVa"
                    xs={12}
                    md={6}
                    lg={4}
                    key={item.bank}
                    data-va={item.bank}
                    onClick={selectVa}
                  >
                    <div className="icon-va icon-list">
                      <Image src={item.url_img} style={{ height: '100%', width: '80%' }} rounded />
                      <span className="icon-label">{item.va_title.split('-')[0]}</span>
                      <span className="icn-sub-label">{Formatter.serial(item.va_number, 20)}</span>
                    </div>
                  </Col>
                ))
              : ''
            : ''}
          {/* {appProdukVa.map((item) => (
            <Col
              className="listVa"
              xs={12}
              md={6}
              lg={4}
              key={item.number}
              data-va={item.bank}
              onClick={selectVa}
            >
              <div className="icon-va icon-list">
                <Image src={item.url_img} style={{ height: '100%', width: '80%' }} rounded />
                <span className="icon-label">{item.va_titles.split('-')[0]}</span>
                <span className="icn-sub-label">{Formatter.serial(item.va_number, 20)}</span>
              </div>
            </Col>
          ))} */}
          <Col
            xs={12}
            md={6}
            lg={4}
            key={99999}
            className="listVa"
            onClick={selectMandiriPc}
            disabled={appIsLoading}
          >
            <div className="icon-va icon-list">
              {appIsLoading ? (
                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
              ) : (
                <>
                  <Image src="/img/bank/mandiri.png" rounded />
                  <span className="icon-label">Mandiri Payment Code (24 Jam)</span>
                </>
              )}
            </div>
          </Col>
        </Row>
      </div>
      <Modal
        show={!!alertError.rd}
        onHide={closeError}
        animation={false}
        contentClassName="alert-dialog"
      >
        <Alert
          variant="danger"
          show={!!alertError.rd}
          onClose={closeError}
          transition={null}
          dismissible
          style={{ textAlign: 'center' }}
        >
          <span style={{ fontSize: '16px', fontWeight: 'bold' }}>{alertError.rd}</span>
        </Alert>
      </Modal>
      <DepositVaModal
        show={!!depositVaModalShow}
        onHide={closeVaBankModal}
        dataRes={dataResDeposit}
      />
      <MandiriPcModal
        show={!!mandiripcModalShow}
        onHide={closeMandiripcModal}
        onConfirm={confirmMandiriPc}
        dataRes={dataResMandiriPc}
      />
      <DepositBankModal
        show={!!depositBankModalShow}
        onHide={closeDepositBankModal}
        dataRes={dataResDepositBank}
      />
    </>
  )
}

VaScreen.defaultProps = {
  appIsLoading: false,
  appUserId: '',
  appProdukVa: [],
}

VaScreen.propTypes = {
  appUserId: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  appProdukVa: PropTypes.array,
  appIsLoading: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
}

export default connect(
  (state) => ({
    appIsLoading: state.Global[globalConstant.IS_LOADING],
    appUserId: state.Auth[authConstant.USER_ID],
    appProdukVa: state.Produk[produkConstant.PRODUK_VA],
  }),
  (dispatch) => ({ dispatch })
)(VaScreen)
