import React from 'react'
import { Modal, Image, InputGroup, FormControl, ListGroup, Row, Col } from 'react-bootstrap'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import HookHelper from '../../helper/hookhelper'

import './dialog.scss'

const KotaDialog = ({ onHide, onConfirm, show, onSearchChange, search, data }) => {
  React.useEffect(() => {
    HookHelper.resetLoading()
  }, [])

  return (
    <Modal
      show={show}
      onHide={onHide}
      backdrop="static"
      keyboard={false}
      centered
      contentClassName="radius"
      animation={false}
    >
      <Modal.Header className="header">
        <div style={{ width: '95%' }}>
          <Modal.Title className="title">Pilih Kota</Modal.Title>
        </div>
        <div className="close-modal">
          <Image
            src="/icon/clear.png"
            onClick={onHide}
            roundedCircle
            className="justify-content-end"
          />
        </div>
      </Modal.Header>

      <Modal.Body>
        <InputGroup className="mb-3">
          <FormControl placeholder="Cari.." value={search} onChange={onSearchChange} />
          <InputGroup.Append style={{ margin: 'auto' }}>
            <FontAwesomeIcon icon="search" fixedWidth className="mr-3" />
          </InputGroup.Append>
        </InputGroup>
        <ListGroup variant="flush">
          {data.map((item) => (
            <ListGroup.Item
              key={item.kotaId}
              data-target={item.kotaId}
              data-label={item.kotaNama}
              onClick={onConfirm}
              style={{ cursor: 'pointer' }}
            >
              <Row>
                <Col lg={11} xs={10} md={11}>
                  <Row>
                    <span className="label-bold medium">{item.kotaNama}</span>
                  </Row>
                </Col>
                <Col lg={1} xs={2} md={1} style={{ margin: 'auto' }}>
                  <FontAwesomeIcon icon="chevron-right" fixedWidth className="mr-3" />
                </Col>
              </Row>
            </ListGroup.Item>
          ))}
        </ListGroup>
      </Modal.Body>
    </Modal>
  )
}

KotaDialog.defaultProps = {
  onHide: null,
  onConfirm: null,
  show: false,
  data: [],
  search: '',
  onSearchChange: null,
}

KotaDialog.propTypes = {
  onHide: PropTypes.func,
  onConfirm: PropTypes.func,
  show: PropTypes.bool,
  search: PropTypes.string,
  onSearchChange: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.array,
}

export default KotaDialog
