import React from 'react'
import { connect } from 'react-redux'
import { Modal, Image, ListGroup, Row, Col } from 'react-bootstrap'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import globalConstant from '../../../store/constant/global'
import HookHelper from '../../../helper/hookhelper'
import DateTime from '../../../helper/datetime'

import './dialogDeposit.scss'
import DepositAktifDetailModal from './depositAktifDetail'

const DepositAktifModal = ({ onHide, show, status, dataDepositAktif }) => {
  const [depositAktifDetailModal, setDepositAktifDetailModal] = React.useState(false)
  const [depositDetail, setDepositDetail] = React.useState(null)

  const closeDepositAktifDetailModal = React.useCallback(async () => {
    setDepositAktifDetailModal(false)
  }, [setDepositAktifDetailModal])

  const selectDetail = React.useCallback(
    (e) => {
      const { id } = e.currentTarget.dataset
      const [filtered] = dataDepositAktif.filter((g) => `${g.id_deposit}` === id)
      setDepositDetail(filtered)
      setDepositAktifDetailModal(true)
    },
    [dataDepositAktif, setDepositDetail, setDepositAktifDetailModal]
  )

  React.useEffect(() => {
    HookHelper.resetLoading()
  }, [])

  return (
    <>
      <Modal
        show={show}
        onHide={onHide}
        backdrop="static"
        keyboard={false}
        centered
        contentClassName="radius pln"
        animation={false}
      >
        <Modal.Header className="header">
          <div style={{ width: '95%' }}>
            <Modal.Title className="title">
              {status === '0' ? 'Daftar Deposit Aktif' : 'Daftar Deposit'}
            </Modal.Title>
          </div>
          <div className="close-modal">
            <Image
              src="/icon/clear.png"
              onClick={onHide}
              roundedCircle
              className="justify-content-end"
            />
          </div>
        </Modal.Header>

        <Modal.Body>
          <ListGroup variant="flush">
            {dataDepositAktif.map((item) => (
              <ListGroup.Item
                key={item.id_deposit}
                data-id={item.id_deposit}
                onClick={selectDetail}
                className="pointing"
              >
                <Row>
                  <Col lg={11} md={11} xs={10}>
                    <Row>
                      <span className="label-bold medium">{`Transfer ke ${item.nama_bank}`}</span>
                    </Row>
                    <Row>
                      <span className="label-bold medium color-danger">{item.nominal}</span>
                    </Row>
                    <Row>
                      <span className="medium">
                        Berakhir pada{' '}
                        <span style={{ fontWeight: 'bold', lineHeight: 'unset' }}>
                          {DateTime.getExpired(item.time_request)}
                        </span>
                      </span>
                    </Row>
                  </Col>
                  <Col lg={1} md={1} xs={2} style={{ margin: 'auto' }}>
                    <FontAwesomeIcon icon="chevron-right" fixedWidth className="mr-3" />
                  </Col>
                </Row>
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Modal.Body>
      </Modal>
      <DepositAktifDetailModal
        show={!!depositAktifDetailModal}
        onHide={closeDepositAktifDetailModal}
        dataDetail={depositDetail}
      />
    </>
  )
}

DepositAktifModal.defaultProps = {
  onHide: null,
  show: false,
  status: '',
  dataDepositAktif: null,
}

DepositAktifModal.propTypes = {
  onHide: PropTypes.func,
  show: PropTypes.bool,
  status: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  dataDepositAktif: PropTypes.array,
}

export default connect(
  (state) => ({
    appIsLoading: state.Global[globalConstant.IS_LOADING],
  }),
  (dispatch) => ({ dispatch })
)(DepositAktifModal)
