/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */
import React from 'react'
import { connect } from 'react-redux'
import { Row, Col, Container, Jumbotron, Button, Card } from 'react-bootstrap'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { isMobile } from 'react-device-detect'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import { useHistory, useLocation } from 'react-router-dom'
import Alert from 'react-popup-alert'
import 'react-popup-alert/dist/index.css'
import DOMHelper from '../../../helper/domhelper'
import HookHelper from '../../../helper/hookhelper'
import DateTime from '../../../helper/datetime'
import Formatter from '../../../helper/formatter'

import './fm.scss'
import authConstant from '../../../store/constant/auth'
import SidebarComponent from '../../component/sidebar'
import HeaderComponent from '../../component/header'
import LoginModal from '../../dialog/auth/login'

import { commissionFm } from '../../../api/action/fm/dashboard'
import 'bootstrap-daterangepicker/daterangepicker.css'

const KomisiFmScreen = ({ appuserId, appUserBalance, appUserIsEmbed, dispatch }) => {
  const history = useHistory()
  const location = useLocation()
  const isMounted = React.useRef(false)
  const [isOpen, setIsOpen] = React.useState(!isMobile)
  const [alertError, setAlertError] = React.useState('')
  const [loginModalShow, setLoginModalShow] = React.useState(false)
  const [commission, setCommission] = React.useState(0)
  const [startTgl, setStartTgl] = React.useState(
    location.state
      ? location.state.startTgl
        ? location.state.startTgl
        : DateTime.startOfMonth(DateTime.FMT_DATE_YMD)
      : DateTime.startOfMonth(DateTime.FMT_DATE_YMD)
  )
  const [endTgl, setEndTgl] = React.useState(
    location.state
      ? location.state.endTgl
        ? location.state.endTgl
        : DateTime.endOfMonth(DateTime.FMT_DATE_YMD)
      : DateTime.endOfMonth(DateTime.FMT_DATE_YMD)
  )
  const toggleSidebar = React.useCallback(() => setIsOpen(!isOpen), [isOpen, setIsOpen])
  const closeError = React.useCallback(() => setAlertError(''), [])

  const openLoginModal = React.useCallback(async () => {
    setLoginModalShow(true)
  }, [])

  const confirmLoginModal = React.useCallback(async () => {
    setLoginModalShow(false)
  }, [])

  const closeLoginModal = React.useCallback(async () => {
    setLoginModalShow('')
    history.push('/')
  }, [history])

  const processData = React.useCallback(async () => {
    const response = await dispatch(commissionFm(appuserId, startTgl, endTgl))

    if (response.isOK()) {
      const { total_komisi: totalCommission } = response.getResultAsObject()

      setCommission(totalCommission)
    }
  }, [dispatch, appuserId, startTgl, endTgl, setCommission])

  const handleApply = React.useCallback(async () => {
    await processData()
  }, [processData])

  const handleCallback = React.useCallback(
    (start, end) => {
      setStartTgl(moment(start).format('YYYY-MM-DD'))
      setEndTgl(moment(end).format('YYYY-MM-DD'))
    },
    [setStartTgl, setEndTgl]
  )

  React.useEffect(() => {
    DOMHelper.mountClass('fm-layout')
    HookHelper.resetLoading()

    if (!appuserId) setLoginModalShow(true)

    if (appuserId && !isMounted.current) {
      isMounted.current = true
      processData()
    }

    return () => DOMHelper.unmountClass(['fm-layout'])
  }, [appuserId, processData, dispatch])

  return (
    <Container fluid className="fm-layout">
      {!appUserIsEmbed && (
        <Row className="header">
          <Col>
            <HeaderComponent title="Total Komisi FM" onToggle={toggleSidebar} />
          </Col>
        </Row>
      )}

      <Row>
        {!appUserIsEmbed && (
          <Col
            md={2}
            className={classNames('col-sidebar', { 'is-open': isOpen, 'is-mobile': isMobile })}
          >
            <SidebarComponent isOpen={isOpen} />
          </Col>
        )}

        <Col
          md={{
            span: appUserIsEmbed ? 12 : isOpen ? 10 : 12,
            offset: appUserIsEmbed ? 0 : isOpen ? 2 : 0,
          }}
        >
          <Jumbotron fluid className="new-banner laporan">
            <Row>
              <Col>
                <Row>
                  <span className={classNames('banner-title', { 'is-mobile': isMobile })}>
                    <FontAwesomeIcon icon="home" fixedWidth className="mr-3" />
                    {`Total Komisi FM ${location.state ? location.state.title : ''}`}
                  </span>
                </Row>
              </Col>

              {!appuserId ? (
                <Col lg={3} xs={12} md={3} style={{ margin: 'auto' }}>
                  <Button className="button-login" type="button" onClick={openLoginModal}>
                    Daftar / Login
                  </Button>
                </Col>
              ) : (
                <Col lg={3} xs={12} md={3} style={{ margin: 'auto' }}>
                  <Row className="row-saldo">
                    <Col lg={10} xs={10} md={10}>
                      <div className="banner-saldo">Saldo Anda</div>
                      <div className="banner-nominal">
                        Rp. {Formatter.price(Math.round(parseFloat(appUserBalance)))}
                      </div>
                    </Col>
                    <Col lg={2} xs={2} md={2}>
                      <span className="banner-title">
                        <FontAwesomeIcon icon="wallet" fixedWidth className="mr-3" />
                      </span>
                    </Col>
                  </Row>
                </Col>
              )}
            </Row>
          </Jumbotron>

          {appuserId && (
            <div className="content-fm">
              <div className="layanan-body">
                <Row>
                  <Col
                    xs={12}
                    md={4}
                    lg={4}
                    className={classNames('button-picker', {
                      'mgbtm-5': isMobile,
                    })}
                  >
                    <Row>
                      <Col lg={1} md={1} xs={2} className="calendar-icon">
                        <FontAwesomeIcon icon="calendar" fixedWidth className="mr-3" />
                      </Col>
                      <Col lg={11} md={11} xs={9} className="picker-border">
                        <DateRangePicker
                          minYear={moment().format('YYYY')}
                          startDate={startTgl}
                          endDate={endTgl}
                          onApply={handleApply}
                          onCallback={handleCallback}
                        >
                          <div className="input-group mb-3" style={{ height: '100%' }}>
                            <span
                              className="input-group-text form-picker form-control"
                              id="basic-addon2"
                            >
                              {moment(startTgl).format('LL')} - {moment(endTgl).format('LL')}
                            </span>
                          </div>
                        </DateRangePicker>
                      </Col>
                    </Row>
                  </Col>
                </Row>

                {commission > 0 ? (
                  <Row style={{ marginTop: '20px' }}>
                    <Col className="pd-10" md={6} lg={6} xs={12}>
                      <Card>
                        <Card.Body>
                          <Card.Title className="txt-16 txt-bold color-primary">
                            Total komisi afiliasi yang telah kamu dapatkan sebesar
                          </Card.Title>
                          <Row>
                            <Col md={9} lg={9} xs={9} style={{ margin: 'auto' }}>
                              <Card.Text className="color-primary txt-24">
                                Rp. {Formatter.price(Math.round(parseFloat(commission)))}
                              </Card.Text>
                            </Col>
                            <Col
                              md={3}
                              lg={3}
                              xs={3}
                              className="text-end"
                              style={{ margin: 'auto' }}
                            >
                              <FontAwesomeIcon
                                icon="dollar-sign"
                                fixedWidth
                                className="color-primary txt-48"
                              />
                            </Col>
                          </Row>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                ) : (
                  <div className="card-total">
                    Yahhh komisi kamu masih Rp 0, dapatkan komisi hingga jutaan rupiah dengan hanya
                    ajak teman kamu menggunakan SpeedCash.
                  </div>
                )}
              </div>
            </div>
          )}
        </Col>
      </Row>
      <Alert
        header="Perhatian"
        btnText="OK"
        text={alertError.rd}
        type="error"
        show={!!alertError.rd}
        onClosePress={closeError}
        showBorderBottom
        alertStyles={{ justifyContent: 'center' }}
      />
      <LoginModal show={!!loginModalShow} onHide={closeLoginModal} onConfirm={confirmLoginModal} />
    </Container>
  )
}

KomisiFmScreen.defaultProps = {
  appuserId: '',
  appUserBalance: 0,
  appUserIsEmbed: false,
}

KomisiFmScreen.propTypes = {
  appuserId: PropTypes.string,
  appUserBalance: PropTypes.number,
  appUserIsEmbed: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
}

export default connect(
  (state) => ({
    appuserId: state.Auth[authConstant.USER_ID],
    appUserBalance: state.Auth[authConstant.USER_BALANCE],
    appUserIsEmbed: state.Auth[authConstant.USER_IS_EMBED],
  }),
  (dispatch) => ({ dispatch })
)(KomisiFmScreen)
