import React from 'react'
import { Button, Image, Row, Col, Accordion, Card } from 'react-bootstrap'
import './accordionPln.scss'

const AccordionPln = () => {
  return (
    <>
      <Col className="d-flex justify-content-center mt-4 mb-3 p-3">
        <h3 className="text-dark text-justify">
          Cara Cek Tagihan Listrik PLN Pascabayar Via Online Di speedcash.co.id
        </h3>
      </Col>

      <div style={{ padding: '0px 20px 30px 20px' }}>
        <Row className="justify-content-md-center">
          <Col lg={8} xs={12} md={8} className="content-pln">
            <div>
              <Row className="d-flex justify-content-center">
                <p className="d-block text-justify">
                  Ternyata sangat mudah cek tagihan listrik untuk dilakukan via speedcash.co.id
                  berikut langkah-langkah yang bisa kamu ikuti
                </p>
              </Row>
              <Row>
                <ol className="text-justify list-content">
                  <li>Isikan kode ID Pelanggan PLN kamu melalui kolom diatas</li>
                  <li>Lalu Klik Lanjutkan</li>
                  <li>Dan akan muncul total tagihan listrik PLN yang harus kamu bayar</li>
                  <li>Klik Daftar/Login (jika kamu belum punya akun SpeedCash)</li>
                  <li>Jika kamu sudah punya akun SpeedCash,lalu pilih lanjutkan/bayar tagihan</li>
                </ol>
              </Row>
              <Row className="d-flex justify-content-center">
                <Image src="/img/pln-banner.jpg" data-target="pln-banner" width="100%" />
              </Row>
            </div>
            <div className="mt-5">
              <Row className="d-flex justify-content-center text-dark">
                <h4>
                  Atau bisa juga kamu simak video berikut untuk cara mengecek tagihan listrik via
                  Aplikasi :
                </h4>
              </Row>
              <Row className="d-flex justify-content-center">
                <iframe
                  width="100%"
                  height="480"
                  src="https://www.youtube.com/embed/KMGeMvCH8rA"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  frameBorder={0}
                  title="Cara cek tagihan listrik Via SpeedCash"
                />
              </Row>
            </div>
          </Col>

          <Col lg={8} xs={12} md={8} className="accordion-pln">
            <Accordion className="m-0 p-0">
              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="2"
                    className="button-collapse w-100 text-left"
                  >
                    Cek Tagihan Listrik Dan Bayar Langsung Dapat Diskon s.d 50.000
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="2">
                  <Card.Body className="card-content">
                    <Row className="d-flex justify-content-center">
                      <p className="d-block text-justify">
                        Pakai SpeedCash bikin kamu jadi lebih hemat berkali lipat,ketika kamu cek
                        tagihan listrik PLN bulanan dan bayar langsung tagihan listrik melalui
                        SpeedCash kamu langsung dapat diskon loh! dan pakai SpeedCash kamu ga hanya
                        bisa untuk bayar tagihan listrik saja tetapi bisa juga untuk{' '}
                        <a href="https://member.speedcash.co.id/pdam">cek tagihan PDAM</a>,
                        <a href="https://member.speedcash.co.id/bpjs"> Bayar tagihan BPJS</a>,
                        <a href="https://member.speedcash.co.id/telkom"> Indihome</a> dan masih
                        banyak layanan lainnya yang bisa kamu manfaatkan untuk dapat diskon hingga
                        50.000 loh!
                      </p>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="3"
                    className="button-collapse w-100 text-left"
                  >
                    Bayar Tagihan Listrik PLN Untuk Masjid/Mushola Juga Bisa
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="3">
                  <Card.Body className="card-content">
                    <Row className="d-flex justify-content-center">
                      <p className="d-block text-justify">
                        <a href="https://www.speedcash.co.id/">SpeedCash</a> memberikan kemudahan
                        untuk pelanggan setia dalam menggunakan layanan kami yang tersedia, salah
                        satunya untuk bayar tagihan listrik masjid/mushola (sedekah listrik) juga
                        bisa loh..Pastinya dengan ketentuan penggunaan yang berlaku di Speedcash
                        untuk bayar tagihan listrik ke masjid/mushola untuk listrik pascabayar.
                      </p>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="4"
                    className="button-collapse w-100 text-left"
                  >
                    Dan ini Cara Bayar Dan Cek Tagihan Listrik Untuk Masjid/Mushola
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="4">
                  <Card.Body className="card-content">
                    <Row>
                      <ol className="text-justify list-content">
                        <li>Isikan kode ID Pelanggan PLN masjid/mushola melalui kolom diatas</li>
                        <li>Lalu Klik Lanjutkan</li>
                        <li>Dan akan muncul total tagihan listrik PLN masjid/mushola</li>
                        <li>Klik Daftar/Login (jika kamu belum punya akun SpeedCash)</li>
                        <li>
                          JIka kamu sudah punya akun SpeedCash,lalu pilih lanjutkan/bayar tagihan
                        </li>
                      </ol>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="5"
                    className="button-collapse w-100 text-left"
                  >
                    Kenapa Harus Cek Tagihan Listrik Di SpeedCash ?
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="5">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Cek tagihan listrik bulanan dan bahkan mingguan merupakan aktivitas yang
                        wajib dilakukan seluruh pelanggan PLN,jika tidak ingin pasokan listrik untuk
                        rumah tangga/ruko/kantor terganggu.JIka sampai tidak mengecek tagihan
                        listrik maka resiko yang dihadapi bisa membengkak tagihan listrik bahkan ada
                        denda tagihan yang harus dibayar.Hingga pemutusan listrik.
                      </p>
                      <p className="d-block text-justify mt-1">
                        Oleh sebab itu kehadiran SpeedCash untuk melayani dan memudahkan cek tagihan
                        listrik PLN secara online baik via aplikasi maupun via website bisa
                        dilakukan kapanpun dan dimanapun asalkan kamu terhubung dengan internet maka
                        bisa dilakukan.
                      </p>
                      <p className="d-block text-justify mt-1">
                        Menghindari antrian, dan daripada harus keluar rumah atau meninggalkan
                        aktivitas penting lainnya, mengecek tagihan listrik dan bayar tagihan
                        listrik PLN via SpeedCash tergolong jauh lebih mudah,cepat dan praktis.
                      </p>
                      <p className="d-block text-justify mt-1">
                        Tidak hanya itu setelah kamu membayar tagihan listrik di SpeedCash kamu juga
                        mendapatkan bukti bayar tagihan listrik yang bisa kamu simpan atau kamu
                        cetak jika kamu memerlukan bukti tersebut.Sehingga mengecek tagihan listrik
                        dan membayar tagihan listrik PLN di SpeedCash sangat disarankan karena
                        kemudahannya.
                      </p>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="6"
                    className="button-collapse w-100 text-left"
                  >
                    Cara Cek Tagihan Listrik Online Lewat Hp Di Aplikasi (IOS atau Android)
                    Speedcash
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="6">
                  <Card.Body className="card-content">
                    <Row>
                      <ol className="text-justify list-content">
                        <li>
                          <p>Isikan kode ID Pelanggan PLN masjid/mushola melalui kolom diatas</p>
                          <Row className="d-flex justify-content-center">
                            <Image
                              src="/img/pln-mockup1.png"
                              data-target="pln-mockup1"
                              width="220px"
                              height="400px"
                            />
                          </Row>
                        </li>
                        <li>Jika sudah download, login menggunakan no hp yang aktif</li>
                        <li>
                          <p>
                            Jika sudah masuk ke menu <strong>PLN Token-Tagihan</strong>
                          </p>
                          <Row className="d-flex justify-content-center">
                            <Image
                              src="/img/pln-mockup2.png"
                              data-target="pln-mockup2"
                              width="220px"
                              height="400px"
                            />
                          </Row>
                        </li>
                        <li>
                          Jika sudah pilih menu <strong>Tagihan PLN</strong>
                        </li>
                        <li>Lalu masukkan kode pelanggan / ID pelanggan PLN</li>
                        <li>
                          <p>
                            Jika sudah lalu akan muncul total tagihan yang harus dibayar (nampak
                            seperti gambar dibawah ini)
                          </p>
                          <Row className="d-flex justify-content-center">
                            <Image
                              src="/img/pln-mockup3.png"
                              data-target="pln-mockup3"
                              width="220px"
                              height="400px"
                            />
                          </Row>
                        </li>
                      </ol>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="7"
                    className="button-collapse w-100 text-left"
                  >
                    Keuntungan Bayar Tagihan Listrik Online Disini
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="7">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Pelanggan pembayaran tagihan listrik yang mengikuti sistem layanan
                        pascabayar umumnya akan malas jika pelanggan tersebut tetap membayar dengan
                        cara offline atau langsung mendatangi cabang PLN terdekat. Pasalnya, antrian
                        yang panjang dan waktu tunggu yang sangat lama menyebabkan pelanggan
                        pascabayar terlambatmembayar tagihan listriknya sehingga menimbulkan
                        beberapa permasalahan di industri ketenagalistrikan, khususnya pemadaman
                        listrik.
                      </p>
                      <p className="d-block text-justify mt-1">
                        Akibat Sementara itu, PLN tidak lagi berdiam diri. dan berupaya memberikan
                        pelayanan terbaik agar pelanggan puas dan yakin sepenuhnya terhadap kinerja
                        PLN dan masyarakat dapat menggunakan listrik tanpa kendala. PLN memberikan
                        pelanggan kesempatan untuk membayar tagihan listrik secara online, dimanapun
                        dan kapanpun mereka mau. Pelanggan tidak perlu antri di kantor PLN dan
                        pembayaran dapat dilakukan secara virtual atau cashless.
                      </p>
                      <p className="d-block text-justify mt-1">
                        Selain itu, karena pengisian data pembayaran dilakukan sendiri, maka
                        kesalahan entri data dapat dikurangi dan pengisian data dapat dilakukan juga
                        dilakukan kembali.
                      </p>
                      <div className="d-flex flex-column justify-content-center">
                        <p className="d-block text-center mt-1">
                          <strong>Dan Ini Kelebihan Memakai SpeedCash</strong>
                        </p>
                        <Row className="d-block mt-2">
                          <ul className="text-justify list-content">
                            <li>Gratis notifikasi pengingat tagihan listrik PLN bulanan</li>
                            <li>
                              Tidak hanya PLN, namun lengkap dengan PDAM, BPJS, isi pulsa, paket
                              data, gopay, ovo, shopeepay, dana, game online dll
                            </li>
                            <li>Transfer uang ke semua bank gratis biaya admin</li>
                            <li>Tiket pesawat garansi harga termurah</li>
                            <li>Tiket Kereta Api gratis biaya admin & layanan</li>
                            <li>Pasti Dapat Diskon disetiap transaksi</li>
                            <li>Downloadnya aplikasinya gratis 100%</li>
                            <li>Tidak perlu punya rekening bank pribadi</li>
                          </ul>
                        </Row>
                      </div>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="8"
                    className="button-collapse w-100 text-left"
                  >
                    Lalu Bagaimana Cara Memperoleh Diskon Pembayaran Di SpeedCash
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="8">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Untuk mendapatkan diskon tagihan listrik caranya sangat mudah kamu cukup
                        untuk membayar tagihan listrik seperti biasanya maka nanti akan muncul di
                        struk bahwa kamu mendapatkan diskon pembayaran tagihan listrik,tidak cuma
                        itu saja ketika kamu membayar tagihan PDAM,BPJS,Indihome dan pembayaran
                        lainnya kamu pasti akan mendapatkan diskon setiap transaksi yang kamu
                        bayarkan jika ditotal diskon yang kamu peroleh hingga 50.000 setiap
                        bulannya.
                      </p>
                      <p className="d-block text-justify mt-1">
                        Tentu program ini diskon cek tagihan listrik maupun cek dan bayar tagihan
                        lainnya ini sangat menguntungkan karena membuat kamu jadi lebih hemat
                        pengeluaran tagihan bulananmu loh! ga percaya ? wah kamu harus coba sih buat
                        pakai SpeedCash.
                      </p>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="9"
                    className="button-collapse w-100 text-left"
                  >
                    Tips Lain Sebelum Cek Tagihan Listrik Di SpeedCash
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="9">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Sebelum mengecek tagihan listrik dan membayar tagihan PLN bulanan via
                        SpeedCash, kamu harus mengisi saldo SpeedCash dulu agar saat membayar nanti
                        kamu bisa lebih mudah dan cepat.
                      </p>
                      <p className="d-block text-justify mt-1">
                        Cara mengisi saldo SpeedCash juga sangat gampang sekali, kamu bisa mengisi
                        saldo SpeedCash melalui indomaret, transfer via virtual account. Berikut
                        video lengkap cara mengisi saldo SpeedCash :
                      </p>
                    </Row>
                    <Row className="d-flex justify-content-center pb-3">
                      <iframe
                        width="100%"
                        height="480"
                        src="https://www.youtube.com/embed/sI1fLYglLwk"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        frameBorder={0}
                        title="Cara isi saldo SpeedCash"
                      />
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="10"
                    className="button-collapse w-100 text-left"
                  >
                    Denda Jika Telat Membayar Tagihan Listrik PLN
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="10">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        PLN akan membebankan biaya keterlambatan pembayaran tagihan listrik (BK)
                        kepada pelanggan yang terlambat membayar tagihan listriknya. Sanksi
                        keterlambatan pembayaran tagihan listrik tergantung pada kelas harga
                        pelanggan dan jumlah listrik yang dikonsumsi. Berikut rincian denda
                        keterlambatan pembayaran tagihan listrik:
                      </p>
                      <div className="d-flex flex-column text-justify my-3">
                        <span>Kelompok harga R-1: Rp 3.000 per bulan</span>
                        <span>Kelompok harga R-2: Rp 5.000 per bulan</span>{' '}
                        <span>Daftar harga R- 3 : Rp 10.000 per bulan</span>{' '}
                        <span>Kelompok harga B-1: Rp 50.000 per bulan</span>{' '}
                        <span>
                          Kelompok harga B-2: 3% dari tagihan listrik (minimal Rp 75.000 per bulan)
                        </span>{' '}
                        <span>
                          Harga kelompok B- 3: 3% dari tagihan listrik (minimal Rp 100.000 per
                          bulan).
                        </span>
                      </div>
                      <p className="d-block text-justify">
                        Nah, Anda pasti tidak ingin kena denda karena terlambat membayar tagihan
                        listrik bukan? Selain itu, denda keterlambatan pembayaran tagihan listrik
                        mungkin terus meningkat setiap bulannya. Jadi, pastikan kamu selalu membayar
                        tagihan listrik tepat waktu ya.
                      </p>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="11"
                    className="button-collapse w-100 text-left"
                  >
                    Ketentuan Cek Tagihan Listrik PLN setiap bulan
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="11">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Untuk cek tagihan listrik setiap bulan ternyata ada ketentuan yang wajib
                        kamu ketahui antara lain :
                      </p>
                      <Row className="mt-3">
                        <ul className="text-justify list-content">
                          <li>
                            {' '}
                            Untuk cek tagihan listrik bisa lakukan kapanpun selama 24 jam dalam 7
                            hari (seminggu).
                          </li>
                          <li>
                            {' '}
                            Untuk melakukan pembayaran tagihan listrik tidak bisa dilakukan di jam
                            23.00-00.00 sesuai dengan peraturan dari PLN.
                          </li>
                          <li>
                            {' '}
                            Pelanggan bisa mengecek tagihan listrik mulai tanggal 2 atau 3 setiap
                            awal bulan (sesuai dengan ketentuan dari PLN).
                          </li>
                          <li>
                            {' '}
                            Batas akhir pembayaran tagihan listrik setiap bulan yaitu maksimal di
                            tanggal 20.
                          </li>
                        </ul>
                      </Row>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="12"
                    className="button-collapse w-100 text-left"
                  >
                    Cek Tagihan Listrik Via Email PLN
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="12">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Cek tagihan listrik bulanan juga bisa dilakukan melalui email ke PLN Mobile,
                        namun sangat sedikit orang yang menggunakan cara ini karena responnya yang
                        lama.
                      </p>
                      <p className="d-block text-justify mt-1">
                        Namun tidak menutup kemungkinan jika Kamu ingin mencoba cara ini , Kamu bisa
                        mencoba cara seperti
                      </p>
                      <p className="d-block text-justify mt-1">
                        Untuk menerima email pembayaran elektronik yang berisi tagihan listrik, Kamu
                        harus mendaftar e-pembayaran PLN terlebih dahulu. Sebelumnya, Kamu bisa
                        mendaftar langsung di websitenya. Namun karena adanya perubahan tampilan
                        situs resmi PLN, Anda dapat mengajukan permintaan melalui email ke
                        pln123@pln.co.id atau melalui akun jejaring sosial resmi PLN.
                      </p>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="13"
                    className="button-collapse w-100 text-left"
                  >
                    Cek Tagihan Listrik Via SMS
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="13">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Ternyata selain cek tagihan listrik lewat aplikasi, email, telepon, ternyata
                        cek tagihan listrik juga bisa dilakukan lewat SMS untuk cek tagihan listrik.
                        Bisa kirim SMS.
                      </p>
                      <p className="d-block text-justify mt-1">
                        Kirim SMS REK (spasi) Kode pelanggan kirim ke 8123. Misal: REK 8990987689
                        kirim ke 8123.
                      </p>
                      <p className="d-block text-justify mt-1">
                        Cara mendaftar untuk menerima pesan informasi tagihan uang: Kirim SMS ke PLN
                        (spasi) ON (spasi) ID Pelanggan 12 digit, kirim ke 8123. Contoh: PLN ON
                        987323847361, kirim ke 8123 .
                      </p>
                      <strong>Cara berhenti berlangganan notifikasi tagihan listrik:</strong>
                      <p className="d-block text-justify mt-1">
                        Selain berlangganan, Anda juga dapat berhenti berlangganan notifikasi
                        pembayaran listrik. Caranya, kirim SMS format PLN (spasi) OFF (spasi) 12
                        digit ID Pelanggan, kirim ke 8123.
                      </p>{' '}
                      <p className="d-block text-justify mt-1">
                        Contoh: PLN OFF 987323847361 kirim ke 8123.
                      </p>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="14"
                    className="button-collapse w-100 text-left"
                  >
                    Cek Tagihan Listrik Melalui Telepon
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="14">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Cara yang terkesan sederhana dan bisa diterapkan untuk segala usia adalah
                        dengan mengecek tagihan listrik melalui telepon, karena pelanggan hanya
                        perlu menghubungi CS PLN dan bisa bertanya langsung mengenai tagihan
                        listrik, air atau pertanyaan lainnya.
                      </p>
                      <p className="d-block text-justify mt-1">
                        Yang pertama langkahnya cukup tekan 123 untuk menghubungi CS. PLN dan kode
                        area juga perlu ditambah, misal kode area Jakarta 021 maka tekan (021)-123.
                        Selanjutnya operator akan memberikan instruksi dan tinggal ikuti saja
                        instruksinya. Call center PLN siap memberikan layanan 24 jam sehingga
                        pelanggan dapat menelepon kapan saja dan biaya panggilan telepon dikenakan
                        normal tergantung operator seluler yang digunakan atau yang menelpon. dari
                        rumah.
                      </p>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="15"
                    className="button-collapse w-100 text-left"
                  >
                    Perbedaan Listrik Pascabayar Dan Listrik Prabayar
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="15">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        <strong>Listrik pascabayar</strong> adalah sistem pembayaran listrik di mana
                        pelanggan membayar tagihan listrik setelah mengonsumsi energi listrik.
                        Berbeda dengan sistem prabayar yang memerlukan pengisian token listrik
                        sebelum penggunaan, pada sistem pascabayar, pelanggan menerima tagihan
                        berdasarkan penggunaan listrik mereka selama periode tertentu.
                      </p>
                      <strong className="mt-3">
                        Berikut adalah ketentuan berlangganan listrik pascabayar:
                      </strong>
                      <div className="mt-3">
                        <strong>Pemakaian Terlebih Dahulu:</strong>
                        <p className="d-block text-justify">
                          Pelanggan menggunakan listrik tanpa perlu melakukan pembayaran terlebih
                          dahulu. Mereka kemudian akan menerima tagihan berdasarkan pemakaian
                          listrik mereka selama periode tertentu, misalnya, satu bulan.
                        </p>
                      </div>
                      <div className="mt-3">
                        <strong>Pemantauan Pemakaian:</strong>
                        <p className="d-block text-justify">
                          Pengguna listrik pascabayar biasanya dapat memantau pemakaian listrik
                          mereka melalui tagihan bulanan. Tagihan tersebut mencakup rincian
                          penggunaan listrik dan biaya yang harus dibayarkan.
                        </p>
                      </div>
                      <div className="mt-3">
                        <strong>Jatuh Tempo Pembayaran:</strong>
                        <p className="d-block text-justify">
                          Tagihan listrik pascabayar memiliki jatuh tempo pembayaran tertentu.
                          Pelanggan diharapkan untuk membayar tagihan tersebut sebelum batas waktu
                          yang ditentukan untuk menghindari denda atau pemutusan layanan.
                        </p>
                      </div>
                      <div className="mt-3">
                        <strong>Layanan Pelanggan:</strong>
                        <p className="d-block text-justify">
                          Pelanggan listrik pascabayar dapat menghubungi layanan pelanggan PLN atau
                          penyedia layanan listrik setempat untuk pertanyaan terkait tagihan,
                          pemakaian, atau layanan lainnya.
                        </p>
                      </div>
                      <div className="mt-3">
                        <strong>Fleksibilitas Penggunaan:</strong>
                        <p className="d-block text-justify">
                          Listrik pascabayar memberikan fleksibilitas kepada pelanggan dalam hal
                          penggunaan listrik, namun, mereka perlu memastikan pembayaran tagihan
                          dilakukan secara tepat waktu agar tetap mendapatkan layanan listrik yang
                          lancar.
                        </p>
                      </div>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="16"
                    className="button-collapse w-100 text-left"
                  >
                    Lalu Apa Itu Listrik Prabayar ?
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="16">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        <strong>Listrik prabayar</strong> adalah sistem pembayaran listrik di mana
                        pengguna harus melakukan pembayaran terlebih dahulu sebelum dapat
                        menggunakan listrik. Dalam hal ini, pelanggan membeli token listrik atau
                        melakukan pengisian saldo pada meteran prabayar mereka untuk mendapatkan
                        akses ke pasokan listrik.
                      </p>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="17"
                    className="button-collapse w-100 text-left"
                  >
                    Berikut ketentuan yang harus diperhatikan pelanggan listrik prabayar
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="17">
                  <Card.Body className="card-content">
                    <Row>
                      <div>
                        <strong>Pembelian Token atau Pengisian Saldo:</strong>
                        <p className="d-block text-justify">
                          Pengguna listrik prabayar perlu membeli token listrik atau mengisi saldo
                          pada meteran prabayar mereka sebelum dapat menggunakan listrik. Token atau
                          saldo tersebut berisi nilai tertentu yang akan digunakan untuk membeli
                          sejumlah energi listrik.
                        </p>
                      </div>
                      <div className="mt-3">
                        <strong>Kontrol Pemakaian:</strong>
                        <p className="d-block text-justify">
                          Listrik prabayar memberikan kontrol lebih besar kepada pengguna atas
                          pemakaian listrik mereka. Mereka dapat memantau sisa saldo atau kredit
                          listrik mereka dan mengatur penggunaan listrik sesuai dengan kebutuhan.
                        </p>
                      </div>
                      <div className="mt-3">
                        <strong>Pemberitahuan Saldo:</strong>
                        <p className="d-block text-justify">
                          Meteran prabayar biasanya dilengkapi dengan fitur pemberitahuan saldo
                          rendah. Pengguna akan diberitahu ketika saldo mereka mendekati habis,
                          memungkinkan mereka untuk mengambil tindakan preventif atau mengisi ulang
                          saldo.
                        </p>
                      </div>
                      <div className="mt-3">
                        <strong>Tidak Ada Tagihan Bulanan:</strong>
                        <p className="d-block text-justify">
                          Dalam sistem prabayar, tidak ada tagihan bulanan yang harus dibayar oleh
                          pelanggan. Mereka membayar sebelum menggunakan listrik, sehingga tidak
                          perlu menunggu tagihan setiap bulan.
                        </p>
                      </div>
                      <div className="mt-3">
                        <strong>Ketersediaan 24 Jam:</strong>
                        <p className="d-block text-justify">
                          Pengguna dapat mengisi ulang token atau saldo listrik prabayar kapan saja,
                          24 jam sehari. Hal ini memberikan fleksibilitas kepada pengguna untuk
                          mengelola kebutuhan energi mereka tanpa harus bergantung pada jam kantor
                          atau waktu tertentu.
                        </p>
                      </div>
                      <div className="mt-3">
                        <strong>Pemutusan Otomatis:</strong>
                        <p className="d-block text-justify">
                          Jika saldo listrik habis, maka akses listrik otomatis terputus. Pengguna
                          perlu mengisi ulang saldo untuk mendapatkan kembali akses listrik.
                        </p>
                      </div>
                      <p className="d-block text-justify mt-1">
                        Listrik prabayar sering digunakan sebagai solusi untuk memberikan kontrol
                        lebih besar kepada konsumen atas pengeluaran energi mereka dan mencegah
                        tunggakan pembayaran.
                      </p>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="18"
                    className="button-collapse w-100 text-left"
                  >
                    Sejarah Berdirinya PLN
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="18">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Perusahaan Listrik Negara (PLN) adalah perusahaan milik pemerintah Indonesia
                        yang bertanggung jawab atas pengelolaan dan penyediaan listrik di seluruh
                        wilayah Indonesia. Berikut adalah sejarah singkat PLN
                      </p>
                      <div className="mt-3">
                        <strong>Era Hindia Belanda:</strong>
                        <p className="d-block text-justify">
                          Sejarah PLN dimulai pada masa pemerintahan Hindia Belanda. Pada tahun
                          1942, pemerintah Hindia Belanda membentuk Nederlandsch-Indische
                          Electriciteits Maatschappij (NIEM), sebuah perusahaan listrik yang menjadi
                          cikal bakal PLN.
                        </p>
                      </div>
                      <div className="mt-3">
                        <strong>Kemerdekaan Indonesia:</strong>
                        <p className="d-block text-justify">
                          Setelah proklamasi kemerdekaan Indonesia pada tahun 1945, NIEM diambil
                          alih oleh pemerintah Indonesia. Pada tanggal 27 Oktober 1945, Soekarno,
                          yang saat itu menjabat sebagai Presiden RI, mengeluarkan Surat Keputusan
                          Presiden (SKP) No. 8, yang mengubah NIEM menjadi Perusahaan Listrik Negara
                          (PLN). Hal ini menandai berdirinya PLN sebagai badan usaha milik negara
                          yang bertanggung jawab atas penyediaan listrik di Indonesia.
                        </p>
                      </div>
                      <div className="mt-3">
                        <strong>Perkembangan dan Ekspansi:</strong>
                        <p className="d-block text-justify">
                          PLN terus mengalami perkembangan dan ekspansi seiring berjalannya waktu.
                          Perusahaan ini melakukan pembangunan pembangkit listrik, transmisi, dan
                          distribusi untuk memenuhi kebutuhan listrik yang semakin meningkat di
                          seluruh Indonesia.
                        </p>
                      </div>
                      <div className="mt-3">
                        <strong>Era Otonomi Daerah:</strong>
                        <p className="d-block text-justify">
                          Pada era otonomi daerah, PLN berupaya meningkatkan kemitraan dengan
                          pemerintah daerah guna meningkatkan efisiensi dan pelayanan listrik di
                          tingkat lokal.
                        </p>
                      </div>
                      <div className="mt-3">
                        <strong>Program 35.000 MW:</strong>
                        <p className="d-block text-justify">
                          Pada tahun 2015, pemerintah Indonesia meluncurkan program &ldquo;35.000
                          MW&rdquo; yang bertujuan untuk meningkatkan kapasitas pembangkit listrik
                          di Indonesia. PLN menjadi pelaksana utama program ini, mengelola
                          proyek-proyek pembangkit listrik baru.
                        </p>
                      </div>
                      <div className="mt-3">
                        <strong>Pengembangan Energi Terbarukan:</strong>
                        <p className="d-block text-justify">
                          PLN juga terlibat dalam pengembangan sumber daya energi terbarukan,
                          seperti pembangkit listrik tenaga surya, tenaga angin, dan lainnya,
                          sebagai bagian dari komitmen untuk mendukung keberlanjutan lingkungan.
                        </p>
                      </div>
                      <div className="mt-3">
                        <strong>Digitalisasi dan Inovasi:</strong>
                        <p className="d-block text-justify">
                          PLN terus melakukan langkah-langkah digitalisasi dan inovasi dalam
                          manajemen jaringan listrik untuk meningkatkan efisiensi operasional dan
                          pelayanan kepada pelanggan.
                        </p>
                      </div>
                      <p className="d-block text-justify mt-1">
                        Sejak berdiri, PLN telah menjadi pilar utama dalam mendukung pembangunan
                        infrastruktur kelistrikan Indonesia, menjadikan listrik sebagai kebutuhan
                        dasar yang tersedia di seluruh penjuru negeri.
                      </p>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="19"
                    className="button-collapse w-100 text-left"
                  >
                    Filosofi Logo PLN
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="19">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Logo PLN (Perusahaan Listrik Negara) memiliki filosofi yang mencerminkan
                        nilai-nilai dan visi dari perusahaan tersebut. Berikut adalah beberapa
                        elemen dalam filosofi logo PLN:
                      </p>
                      <div className="mt-3">
                        <strong>Bentuk Kilatan Petir:</strong>
                        <p className="d-block text-justify">
                          Bentuk kilatan petir pada logo PLN melambangkan energi listrik dan daya
                          yang kuat. Kilatan petir juga dapat diartikan sebagai simbol kecepatan dan
                          keefisienan dalam penyediaan listrik.
                        </p>
                      </div>
                      <div className="mt-3">
                        <strong>Bentuk Bulat:</strong>
                        <p className="d-block text-justify">
                          Bentuk bulat pada logo menggambarkan keselarasan dan kesatuan. Ini bisa
                          mencerminkan integrasi seluruh aspek penyediaan listrik, dari produksi
                          hingga distribusi, dalam sebuah sistem yang terkoordinasi.
                        </p>
                      </div>
                      <div className="mt-3">
                        <strong>Warna Biru dan Hijau:</strong>
                        <p className="d-block text-justify">
                          Warna biru dan hijau pada logo PLN biasanya diartikan sebagai warna yang
                          melambangkan keberlanjutan (sustainability), keandalan, dan harmoni dengan
                          lingkungan. Biru sering kali dikaitkan dengan keamanan dan kestabilan,
                          sementara hijau mencerminkan keberlanjutan dan tanggung jawab lingkungan.
                        </p>
                      </div>
                      <div className="mt-3">
                        <strong>Huruf PLN:</strong>
                        <p className="d-block text-justify">
                          Huruf &ldquo;PLN&rdquo; yang tercetak di tengah logo memberikan identitas
                          perusahaan dengan jelas. Huruf ini seringkali ditampilkan dalam warna
                          kontras agar mudah dikenali.
                        </p>
                      </div>
                      <div className="mt-3">
                        <strong>Motif Lingkaran pada Huruf &ldquo;N&rdquo;:</strong>
                        <p className="d-block text-justify">
                          Motif lingkaran pada huruf &ldquo;N&rdquo; dapat melambangkan sirkulasi
                          listrik atau perputaran energi. Ini bisa diartikan sebagai representasi
                          dari peran PLN dalam menyediakan listrik untuk masyarakat.
                        </p>
                      </div>
                      <div className="mt-3">
                        <strong>Keseimbangan dan Keteraturan:</strong>
                        <p className="d-block text-justify">
                          Susunan elemen-elemen dalam logo menciptakan keseimbangan dan keteraturan,
                          mencerminkan tata kelola yang baik dan kinerja yang terorganisir.
                        </p>
                      </div>
                      <div className="mt-3">
                        <strong>Logo Bergerak:</strong>
                        <p className="d-block text-justify">
                          Beberapa versi logo PLN memiliki elemen yang dapat memberikan kesan gerak,
                          yang mencerminkan dinamika dan kemajuan perusahaan dalam menyediakan
                          layanan listrik.
                        </p>
                      </div>
                      <p className="d-block text-justify mt-1">
                        Filosofi logo PLN secara keseluruhan mencerminkan tekad perusahaan untuk
                        memberikan listrik yang andal, efisien, dan berkelanjutan, serta menjadi
                        mitra yang kuat dalam mendukung pembangunan masyarakat dan negara.
                      </p>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="20"
                    className="button-collapse w-100 text-left"
                  >
                    Layanan Yang Tersedia Di PLN
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="20">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        PLN (Perusahaan Listrik Negara) memberikan pelayanan yang menyeluruh kepada
                        seluruh masyarakat Indonesia dan akan menjamin kepuasan pelanggan. Oleh
                        karena itu, PLN melayani secara online dan melayani pelanggan secara
                        langsung. Layanan online PLN memudahkan pelanggan melakukan pengecekan
                        tagihan listrik, pemasangan listrik baru dan menyampaikan pengaduan.
                      </p>
                      <Row className="d-flex w-100 justify-content-center mt-3">
                        <h5>Berikut beberapa layanan online PLN:</h5>
                      </Row>
                      <div className="mt-3">
                        <strong>Pendaftaran Pelanggan PLN Prabayar</strong>
                        <p className="d-block text-justify">
                          Kini PLN tanggap terhadap permasalahan kelistrikan memerlukan instalasi
                          secara online dan tanpa perlu mendatangi kantor PLN terdekat. Oleh karena
                          itu, layanan ini dapat diberikan kapan saja, di mana saja selama ada
                          koneksi Internet di area tersebut.
                        </p>
                      </div>
                      <div className="mt-3">
                        <strong>Penambahan daya pasokan listrik</strong>
                        <p className="d-block text-justify">
                          Terkadang pelanggan mempunyai kebutuhan tambahan di rumah sehingga
                          kapasitas listriknya meningkat. Sama seperti dunia usaha atau industri,
                          lama kelamaan daya listriknya akan bertambah.Pelanggan kini bisa meminta
                          tambahan daya secara online sekarang dan prosesnya sangat mudah sehingga
                          pelanggan tidak perlu datang ke cabang PLN terdekat.
                        </p>
                      </div>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="21"
                    className="button-collapse w-100 text-left"
                  >
                    Cara Berhenti Berlangganan Listrik PLN
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="21">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Untuk berhenti berlangganan layanan listrik PLN, pelanggan harus mengirimkan
                        permohonan ke PLN dan menyebutkan alasan berhenti berlangganan layanan
                        listrik PLN, data diri serta fotokopi KTP. Cantumkan juga denah rumah dan
                        nomor pelanggan beserta surat lamaran yang diserahkan. PLN akan melanjutkan
                        review dan menyetujui surat lamaran tersebut, diterima atau tidak.
                      </p>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="22"
                    className="button-collapse w-100 text-left"
                  >
                    Ini Dia Rumus Menghitung Tagihan Listrik PLN Pascabayar
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="22">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Dari tahun ke tahun banyak masyarakat Indonesia yang menggunakan listrik
                        prabayar sebagai sistem pembayaran karena tidak perlu membayar tagihan
                        listrik bulanan. Namun jumlah pelanggan pascabayar semakin banyak karena
                        masih menganut sistem lama, terutama di masyarakat pedesaan.
                      </p>
                      <p className="d-block text-justify mt-1">
                        Pascabayar berasal dari kata post+pay, post mempunyai arti sebagai berikut
                        dan pay artinya memberikan suatu hal tertentu jumlah uang. untuk keperluan
                        transaksi, secara harfiah berarti pelanggan wajib membayar tagihan setelah
                        energi listrik yang digunakan habis.
                      </p>
                      <p className="d-block text-justify mt-1">
                        Oleh karena itu, wajib bagi pelanggan untuk mengetahui semua peralatan
                        Listrik yang ada di rumah dan semua benda di dalam rumah menggunakan energi
                        listrik. Setelah dilakukan pendataan furnitur dalam rumah dan mendapatkan
                        informasi listrik, hitung total tagihan listrik dengan rumus berikut:
                      </p>
                      <p className="d-block text-justify mt-1">
                        Jika listrik dalam rumah 10.000 watt dengan rating 1.400 kWh, maka biayanya
                        selama 30 hari adalah : 10 x 1400 x 30 = 520.000
                      </p>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="23"
                    className="button-collapse w-100 text-left"
                  >
                    Tips Menghemat Listrik Pascabayar
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="23">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Listrik merupakan kebutuhan penting bagi seluruh umat manusia. Kita tentu
                        sudah sangat familiar dengan perbedaan listrik prabayar dan pascabayar.
                        Meski listrik prabayar saat ini banyak diminati masyarakat Indonesia, namun
                        jumlah pelanggan yang menggunakan listrik pascabayar tidak berkurang dan
                        diminati banyak masyarakat. Untuk apa? Karena pelanggan setia dengan listrik
                        pascabayar karena tidak ada batasan listrik yang digunakan, tidak perlu
                        rutin mengisi ulang listrik danterlebih lagi KWh meter lebih awet dengan
                        cara perhitungan manual. Dan Kamu bisa mengecek tagihan listrik melalui
                        meteran yang tersedia.
                      </p>
                      <p className="d-block text-justify mt-1">
                        Jadi, inilah saatnya menggunakan listrik sesuai kebutuhanmu dan bukan
                        rumah/tempat tinggal yang selalu boros listrik. Kamu dapat menjaga tagihan
                        listrik bulanan tetap rendah dan juga menjaga keamanan kelistrikan agar
                        tidak terjadi hal-hal yang tidak diinginkan. Jadi Kamu tidak perlu lagi
                        khawatir dengan tagihan listrik bulanan. Sebab saat ini pembayaran listrik
                        online hanya bisa dilakukan di SpeedCash
                      </p>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="24"
                    className="button-collapse w-100 text-left"
                  >
                    Cek Tagihan Listrik Via Aplikasi PLN Mobile
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="24">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Untuk kamu pengguna Android, Cek tagihan listrik melalui aplikasi PLN Mobile
                        sangatlah mudah karena pengguna aplikasi Android lebih banyak dibandingkan
                        pengguna iOS. Cara cek tagihan listrik sangat mudah, Anda hanya perlu
                        mengikuti petunjuk di bawah ini:
                      </p>
                    </Row>
                    <Row className="d-flex justify-content-start">
                      <ol className="text-justify list-content">
                        <li>
                          {' '}
                          Masuk ke Google Play, ketik PLN Mobile lalu download dan install
                          aplikasinya.
                        </li>
                        <li> Pilih daftar jika belum mendaftar aplikasi PLN Mobile</li>
                        <li> Setelah mendaftar silahkan login lalu pilih menu informasi</li>
                        <li> Pilih informasi tagihan listrik</li>
                        <li> Masukkan nama pengguna atau nomor meteran</li>
                        <li> Tekan cari</li>
                        <li> Tagihan listrik Anda akan muncul</li>
                      </ol>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="25"
                    className="button-collapse w-100 text-left"
                  >
                    Kantor Pusat PLN
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="25">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Kantor Pusat PT PLN (Persero) terletak di Jalan Trunojoyo Nomor 1, Kebayoran
                        Baru, Jakarta Selatan, DKI Jakarta. Kantor Pusat PLN ini merupakan kantor
                        pusat dari seluruh kegiatan operasional PT PLN (Persero) di seluruh
                        Indonesia.
                      </p>
                      <div className="d-flex flex-column">
                        <strong className="mb-1">
                          Berikut adalah alamat lengkap Kantor Pusat PT PLN (Persero):
                        </strong>
                        <span>
                          Jalan Trunojoyo Nomor 1, Kebayoran Baru, Jakarta Selatan, DKI Jakarta
                        </span>
                        <span>Kode Pos: 12110Telepon: (021) 7261123</span>
                        <span>Faksimili: (021) 7261124</span>
                      </div>
                      <div className="d-flex flex-column">
                        <p className="d-block text-justify mt-3">
                          Kantor PLN di Indonesia dapat dikelompokkan menjadi beberapa kategori,
                          yaitu:
                        </p>
                        <Row className="mt-1">
                          <ul className="text-justify list-content">
                            <li>PLN Kantor Pusat</li>
                            <li>Kantor Wilayah PLN</li>
                            <li>Kantor Wilayah PLN</li>
                            <li>Kantor Unit Customer Service (ULP)</li>
                            <li>Unit Office Mobile Layanan Pelanggan (ULP Seluler)</li>
                          </ul>
                        </Row>
                      </div>
                      <div className="mt-3">
                        <h4 className="d-block">Kantor Wilayah PLN</h4>
                        <p className="d-block text-justify">
                          Kantor Wilayah PLN adalah kantor yang membawahi beberapa Kantor Area PLN.
                          Kantor Wilayah PLN bertanggung jawab atas operasional PLN di wilayah kerja
                          masing-masing.
                        </p>
                      </div>
                      <div className="mt-3">
                        <h4 className="d-block">Kantor Area PLN</h4>
                        <p className="d-block text-justify">
                          Kantor Area PLN adalah kantor yang membawahi beberapa Kantor Unit Layanan
                          Pelanggan. Kantor Area PLN bertanggung jawab atas operasional PLN di
                          wilayah kerja masing-masing.
                        </p>
                      </div>
                      <div className="mt-3">
                        <h4 className="d-block">Kantor Unit PLN</h4>
                        <p className="d-block text-justify">
                          Kantor Unit Layanan Pelanggan (ULP) adalah kantor yang melayani pelanggan
                          PLN di tingkat kabupaten/kota. ULP menyediakan berbagai layanan pelanggan,
                          seperti:
                        </p>
                        <Row className="mt-1">
                          <ul className="text-justify list-content">
                            <li> Layanan informasi tagihan listrik</li>
                            <li> Layanan perubahan daya</li>
                            <li> Layanan penyambungan baru</li>
                            <li> Layanan pengaduan pelanggan</li>
                            <li> Layanan lainnya</li>
                          </ul>
                        </Row>
                      </div>
                      <div className="mt-3">
                        <h4 className="d-block">Kantor Unit Layanan Pelangggan Keliling</h4>
                        <p className="d-block text-justify">
                          Kantor Unit Layanan Pelanggan Keliling (ULP Keliling) adalah kantor yang
                          melayani pelanggan PLN di daerah-daerah terpencil dan terluar. ULP
                          Keliling menyediakan layanan pelanggan yang sama dengan ULP.
                        </p>
                      </div>
                      <p className="d-block text-justify mt-1">
                        Itulah tadi informasi mengenai cek tagihan listrik PLN dan langsung bayar
                        tagihan listrik bulanan di SpeedCash, jika kamu merasa informasi ini sangat
                        bermanfaat dan alat cek tagihan listrik ini bisa membantu untuk mengontrol
                        tagihan listrik bulanan,Kamu bisa mendownload aplikasi SpeedCash melalui
                        link download dibawah ini.
                      </p>
                      <div className="mt-1 w-100 d-flex justify-content-center">
                        <h5 className="bold">
                          Cek Dan Bayar Tagihan Listrik Via Aplikasi Lebih Cepat:
                        </h5>
                      </div>
                      <Row className="d-flex w-100 justify-content-center mb-3 gap-3">
                        <a
                          aria-hidden
                          href="https://play.google.com/store/apps/details?id=com.bm.sc.bebasbayar&listing=token-listrik"
                          className="mx-1"
                        >
                          <Image
                            src="/img/playstore-speedcash.png"
                            data-target="playstore-speedcash"
                            width="220px"
                            height="60px"
                          />
                        </a>
                        <a
                          aria-hidden
                          href="https://apps.apple.com/id/app/speedcash-transfer-ewallet/id6459304122"
                          className="mx-1"
                        >
                          <Image
                            src="/img/appstore-speedcash.png"
                            data-target="appstore-speedcash"
                            width="220px"
                            height="60px"
                          />
                        </a>
                      </Row>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default AccordionPln
