// import authConstant from '../../../store/constant/auth'
// import globalConstant from '../../../store/constant/global'
// import produkConstant from '../../../store/constant/produk'

export default () => async (dispatch) => {
  try {
    const delay = (ms) => new Promise((res) => setTimeout(res, ms))
    await dispatch({
      type: 'USER_LOGOUT',
    })
    // await dispatch({
    //   type: authConstant.CLEAR,
    // })
    // await dispatch({
    //   type: produkConstant.PRODUK_CLEAR,
    // })
    // await dispatch({
    //   type: globalConstant.CLEAR,
    // })

    await delay(500)
    window.location.href = `${window.location.protocol}//${window.location.host}`
    return { rc: '00', rd: 'Logout Berhasil.' }
  } catch {
    return { rc: '01', rd: 'Logout Gagal. Silahkan Ulangi Kembali.' }
  }
}
