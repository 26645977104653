/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-nested-ternary */
import _ from 'lodash'
import React from 'react'
import { connect } from 'react-redux'
import {
  Spinner,
  Form,
  InputGroup,
  Container,
  Jumbotron,
  Button,
  Image,
  Row,
  Col,
} from 'react-bootstrap'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { isMobile } from 'react-device-detect'
import { useHistory, useLocation } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Alert from 'react-popup-alert'
import 'react-popup-alert/dist/index.css'
import DOMHelper from '../../../helper/domhelper'
import Formatter from '../../../helper/formatter'
import HookHelper from '../../../helper/hookhelper'

import './layanan.scss'
import globalConstant from '../../../store/constant/global'
import authConstant from '../../../store/constant/auth'
import produkConstant from '../../../store/constant/produk'
import SidebarComponent from '../../component/sidebar'
import HeaderComponent from '../../component/header'
import LoginModal from '../../dialog/auth/login'
import PenerimaanPajakModal from '../../dialog/layanan/djkm'
import LastTrxDialog from '../../dialog/lastTransaction'

import { djkmInq, djkmPay } from '../../../api/action/layanan/tagihan'
import { lastTransaction } from '../../../api/action/global'

const PenerimaanNegaraScreen = ({
  appUserId,
  appUserPin,
  appUserBalance,
  appUserIsEmbed,
  appIsLoading,
  dispatch,
}) => {
  const PRODUK = 'DJKM'
  const history = useHistory()
  const location = useLocation()
  const [isOpen, setIsOpen] = React.useState(!isMobile)
  const [isInqWeb, setIsInqWeb] = React.useState(false)
  const [alertError, setAlertError] = React.useState('')
  const [loginModalShow, setLoginModalShow] = React.useState(false)
  const [tagihanModalShow, setTagihanModalShow] = React.useState(false)
  const [userId] = React.useState(!appUserId ? process.env.REACT_APP_USER_DEMO_ID : appUserId)
  const [userPin] = React.useState(
    !appUserId ? process.env.REACT_APP_USER_DEMO_PASSWORD : appUserPin
  )
  const [lastTrxShow, setLastTrxShow] = React.useState(false)
  const [lastTrx, setLastTransaction] = React.useState([])
  const [lastTrxFiltered, setLastTransactionFiltered] = React.useState([])
  const [searchLastTrx, setSearchLastTrx] = React.useState('')
  const [isSaldoOk, setIsSaldoOk] = React.useState(false)
  const [customer, setCustomer] = React.useState('')
  const [dataRes, setDataRes] = React.useState(null)

  const toggleSidebar = React.useCallback(() => setIsOpen(!isOpen), [isOpen, setIsOpen])

  const closeError = React.useCallback(() => setAlertError(''), [])

  const openLoginModal = React.useCallback(async () => {
    setLoginModalShow(true)
  }, [])

  const confirmLoginModal = React.useCallback(async () => {
    setLoginModalShow(false)
    // window.location.reload()
  }, [])

  const closeLoginModal = React.useCallback(async () => {
    setLoginModalShow(false)
  }, [])

  const closeTagihanModal = React.useCallback(async () => {
    setTagihanModalShow(false)
  }, [])

  const openLastTransaction = React.useCallback(async () => {
    setLastTrxShow(true)
  }, [setLastTrxShow])

  const closeLastTransaction = React.useCallback(async () => {
    setLastTrxShow(false)
  }, [setLastTrxShow])

  const onSearchLastTrxChange = React.useCallback(
    (e) => {
      const { value } = e.target
      const newData = _.filter(lastTrx, (item) => {
        return (
          _.includes(item.id_pelanggan, value) ||
          _.includes(item.nama_pelanggan.toLowerCase(), value.toLowerCase())
        )
      })

      setSearchLastTrx(value)
      setLastTransactionFiltered(newData)
    },
    [lastTrx, setSearchLastTrx, setLastTransactionFiltered]
  )

  const confirmLastTrx = React.useCallback(
    (e) => {
      const { target } = e.currentTarget.dataset
      setCustomer(Formatter.number(target))
      setLastTrxShow(false)
    },
    [setCustomer, setLastTrxShow]
  )

  const onCustomerChange = React.useCallback(
    (e) => {
      const { value } = e.target
      setCustomer(Formatter.number(value))
    },
    [setCustomer]
  )

  const handleClear = React.useCallback(
    (e) => {
      const { target } = e.currentTarget.dataset
      if (target === 'customer') {
        setCustomer('')
      }

      setIsInqWeb(false)
      setDataRes(null)
    },
    [setCustomer, setIsInqWeb, setDataRes]
  )

  const onProcess = React.useCallback(async () => {
    if (!customer) {
      setAlertError({ rc: '', rd: 'Masukkan Billing ID Terlebih Dahulu' })
      return false
    }

    const responseInq = await dispatch(djkmInq(userId, userPin, PRODUK, customer))
    if (responseInq.isOK()) {
      const dataRespon = responseInq.getData()
      const { amount } = dataRespon.includes
      const { admin } = dataRespon.includes
      const total =
        parseInt(amount, 0) + parseInt(admin, 0) + parseInt(dataRespon.additional_datas[93], 10)
      if (Math.round(parseFloat(appUserBalance)) >= total) {
        setIsSaldoOk(true)
      }

      setDataRes(dataRespon)
      if (isMobile) {
        setTagihanModalShow(true)
      } else {
        setIsInqWeb(true)
      }
    } else {
      setAlertError({ rc: responseInq.getRC(), rd: responseInq.getRD() })
    }

    return true
  }, [
    dispatch,
    userId,
    userPin,
    appUserBalance,
    customer,
    setIsInqWeb,
    setIsSaldoOk,
    setAlertError,
  ])

  const redirectToDeposit = React.useCallback(() => {
    history.push('/deposit')
  }, [history])

  const confirmPayment = React.useCallback(async () => {
    if (!appUserId) {
      setTagihanModalShow(false)
      setLoginModalShow(true)
    } else if (isSaldoOk) {
      const dataIncludes = dataRes.includes
      const reffId = dataIncludes.reff_id
      const responsePay = await dispatch(djkmPay(userId, userPin, PRODUK, customer, reffId))
      if (responsePay.isOK()) {
        history.push({
          pathname: '/struk',
          state: {
            parent: 'penerimaan-negara',
            parentPath: location.pathname,
            title: 'Penerimaan Negara MPN',
            icon: 'landmark',
            transaksi: responsePay.data.includes.reff_id,
            trxDate: responsePay.data.transmission_datetime,
            trxTime: responsePay.data.transmission_datetime,
            total: responsePay.data.includes.amount,
            customerId: responsePay.data.includes.customer_id_1,
            produk: PRODUK,
            info: 'sukses',
          },
        })
      } else if (
        responsePay.getRC() === '' ||
        responsePay.getRC() === '68' ||
        responsePay.getRC() === responsePay.data.includes.reff_id
      ) {
        history.push({
          pathname: '/struk',
          state: {
            parent: 'penerimaan-negara',
            parentPath: location.pathname,
            title: 'Penerimaan Negara MPN',
            icon: 'landmark',
            transaksi: responsePay.data.includes.reff_id,
            trxDate: responsePay.data.transmission_datetime,
            trxTime: responsePay.data.transmission_datetime,
            total: responsePay.data.includes.amount,
            customerId: responsePay.data.includes.customer_id_1,
            produk: PRODUK,
            info: 'pending',
          },
        })
      } else {
        setAlertError({ rc: responsePay.getRC(), rd: responsePay.getRD() })
      }
    } else {
      redirectToDeposit()
    }
  }, [
    dispatch,
    history,
    location,
    isSaldoOk,
    appUserId,
    userId,
    userPin,
    customer,
    dataRes,
    setAlertError,
    redirectToDeposit,
  ])

  React.useEffect(() => {
    DOMHelper.mountClass('layanan-layout')
    HookHelper.resetLoading()

    dispatch(lastTransaction(userId, PRODUK, '0')).then((response) => {
      if (response.isOK()) {
        setLastTransaction(response.getResultAsList())
        setLastTransactionFiltered(response.getResultAsList())
      } else {
        setLastTransaction([])
        setLastTransactionFiltered([])
      }
    })

    return () => DOMHelper.unmountClass(['layanan-layout'])
  }, [dispatch, userId, setLastTransaction, setLastTransactionFiltered])

  return (
    <Container fluid className="layanan-layout">
      {!appUserIsEmbed && (
        <Row className="header">
          <Col>
            <HeaderComponent title="Penerimaan Negara MPN" onToggle={toggleSidebar} />
          </Col>
        </Row>
      )}

      <Row>
        {!appUserIsEmbed && (
          <Col
            md={2}
            className={classNames('col-sidebar', { 'is-open': isOpen, 'is-mobile': isMobile })}
          >
            <SidebarComponent isOpen={isOpen} />
          </Col>
        )}

        <Col
          md={{
            span: appUserIsEmbed ? 12 : isOpen ? 10 : 12,
            offset: appUserIsEmbed ? 0 : isOpen ? 2 : 0,
          }}
        >
          <Jumbotron fluid className="banner">
            <Row>
              <Col>
                <Row>
                  <span className={classNames('banner-title', { 'is-mobile': isMobile })}>
                    <FontAwesomeIcon icon="landmark" fixedWidth className="mr-3" />
                    Penerimaan Negara MPN
                  </span>
                </Row>
              </Col>

              {!appUserId ? (
                <Col lg={3} xs={4} md={3} style={{ margin: 'auto' }}>
                  <Button
                    className={classNames('button-login', { 'is-mobile': isMobile })}
                    type="button"
                    onClick={openLoginModal}
                  >
                    Daftar / Login
                  </Button>
                </Col>
              ) : (
                <Col lg={3} xs={12} md={3} style={{ margin: 'auto' }}>
                  <Row>
                    <Col lg={10} xs={10} md={10}>
                      <div className="banner-saldo">Saldo Anda</div>
                      <div className="banner-nominal">
                        Rp. {Formatter.price(Math.round(parseFloat(appUserBalance)))}
                      </div>
                    </Col>
                    <Col lg={2} xs={2} md={2}>
                      <span className="banner-title">
                        <FontAwesomeIcon icon="wallet" fixedWidth className="mr-3" />
                      </span>
                    </Col>
                  </Row>
                </Col>
              )}
            </Row>
          </Jumbotron>

          <div className="content-layanan">
            <Row className="justify-content-md-center">
              <Col lg={isInqWeb ? 7 : 8} xs={12} md={isInqWeb ? 7 : 8} className="bd-form">
                <Form className="form-pelanggan single">
                  <Form.Group>
                    <Form.Label className="form-label start">Billing ID</Form.Label>
                    <InputGroup className="mb-12">
                      <Form.Control
                        placeholder="Masukkan Billing ID"
                        value={Formatter.serial(customer)}
                        onChange={onCustomerChange}
                      />
                      <InputGroup.Append className="flex align-items-center">
                        <Image
                          className={classNames('form-icon-btn', { 'ic-mobile': isMobile })}
                          src="/icon/clear.png"
                          data-target="customer"
                          onClick={handleClear}
                          disabled={isInqWeb || tagihanModalShow}
                        />
                      </InputGroup.Append>
                    </InputGroup>
                  </Form.Group>
                </Form>
                <Row className="row-proses">
                  <Col className="nominal-minimal" />
                  <Col style={{ margin: 'auto' }}>
                    <Button
                      className="btn-process"
                      size="lg"
                      block
                      onClick={onProcess}
                      disabled={appIsLoading}
                    >
                      {appIsLoading && !isMobile && (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      )}
                      {appIsLoading && !isMobile ? ' Mohon Tunggu...' : 'Cek Tagihan Sekarang'}
                    </Button>
                  </Col>
                </Row>
                {lastTrx.length > 0 && (
                  <div className="form-last-trx">
                    <span className="form-label">Transaksi Terakhir</span>
                    <Row
                      style={{
                        border: '1px solid #DDDDDD',
                        borderRadius: '10px',
                        alignItems: 'center',
                        padding: '10px',
                      }}
                    >
                      <Col lg={1} xs={2} md={1}>
                        <Image src={lastTrx[0].image_url} height={40} />
                      </Col>
                      <Col
                        lg={8}
                        xs={10}
                        md={8}
                        data-target={lastTrx[0].id_pelanggan}
                        onClick={confirmLastTrx}
                        className="pointing"
                      >
                        <Row>
                          <span className="form-label-main">{lastTrx[0].id_pelanggan}</span>
                        </Row>
                        <Row>
                          <span className="label-text">{lastTrx[0].nama_pelanggan}</span>
                        </Row>
                      </Col>
                      <Col lg={3} xs={8} md={3} onClick={openLastTransaction}>
                        <div className="label-text color-primary pointing">
                          Lihat riwayat transaksi
                        </div>
                      </Col>
                    </Row>
                  </div>
                )}
              </Col>
              {isInqWeb && (
                <>
                  <Col md="auto" />
                  <Col lg={4} xs={12} md={4} className="bd-form-inq">
                    <Row className="justify-content-md-center">
                      <Col className="inq-header txt-bold">
                        {dataRes ? `${dataRes.biller_info.productName}` : 'PEMBAYARAN'}
                      </Col>
                    </Row>
                    <Row className="justify-content-md-center">
                      <Col className="txt-center txt-bold txt-14">RINCIAN PEMBAYARAN</Col>
                    </Row>
                    {dataRes &&
                      dataRes.strukMaps.map((val) => (
                        <Row key={Object.keys(val)[0]}>
                          <Col>{Object.keys(val)[0]}</Col>
                          <Col
                            className={classNames('inq-end-label', {
                              'txt-bold':
                                Object.keys(val)[0].toUpperCase() === 'TOTAL BAYAR' ||
                                Object.keys(val)[0].toUpperCase() === 'JUMLAH SETORAN',
                            })}
                          >
                            {val ? val[Object.keys(val)[0]] : ''}
                          </Col>
                        </Row>
                      ))}
                    <Row className="justify-content-md-center inq-body-last">
                      <Col className="inq-balance">
                        <Row style={{ display: 'flex', alignItems: 'center' }}>
                          <Col lg={2} xs={2} md={2}>
                            <Image src="/img/sc.png" roundedCircle />
                          </Col>
                          <Col lg={5} xs={5} md={5}>
                            Saldo Speedcash
                          </Col>
                          <Col lg={5} xs={5} md={5} className="inq-end-label txt-bold">
                            {dataRes
                              ? `${Formatter.price(
                                  Math.round(parseFloat(dataRes.includes.balance))
                                )}`
                              : 'Rp 0,-'}
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    {!isSaldoOk && appUserId && (
                      <Row className="justify-content-md-center inq-body-last">
                        <Col className="inq-balance not-ok">
                          <Row className="txt-bold">
                            Saldo Anda belum mencukupi untuk transaksi ini. Segera Top Up
                          </Row>
                        </Col>
                      </Row>
                    )}
                    <Row className="justify-content-md-center">
                      <Col>
                        <Button
                          className="btn-process"
                          type="button"
                          block
                          onClick={
                            appUserId
                              ? isSaldoOk
                                ? confirmPayment
                                : redirectToDeposit
                              : openLoginModal
                          }
                        >
                          {appIsLoading && (
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />
                          )}
                          {appIsLoading
                            ? ' Mohon Tunggu...'
                            : appUserId
                            ? isSaldoOk
                              ? 'Bayar Sekarang'
                              : 'Top Up Saldo'
                            : 'Daftar / Login'}
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </>
              )}
            </Row>
          </div>
        </Col>
      </Row>
      <Alert
        header="Perhatian"
        btnText="OK"
        text={alertError.rd}
        type="error"
        show={!!alertError.rd}
        onClosePress={closeError}
        showBorderBottom
        alertStyles={{ justifyContent: 'center' }}
      />
      <LoginModal show={!!loginModalShow} onHide={closeLoginModal} onConfirm={confirmLoginModal} />
      <PenerimaanPajakModal
        show={!!tagihanModalShow}
        onHide={closeTagihanModal}
        onConfirm={confirmPayment}
        dataRes={dataRes}
        isSaldoOk={isSaldoOk}
      />
      <LastTrxDialog
        show={!!lastTrxShow}
        onHide={closeLastTransaction}
        onConfirm={confirmLastTrx}
        search={searchLastTrx}
        onSearchChange={onSearchLastTrxChange}
        data={lastTrxFiltered}
      />
    </Container>
  )
}

PenerimaanNegaraScreen.defaultProps = {
  appIsLoading: false,
  appUserId: '',
  appUserPin: '',
  appUserBalance: 0,
  appUserIsEmbed: false,
}

PenerimaanNegaraScreen.propTypes = {
  appUserId: PropTypes.string,
  appUserPin: PropTypes.string,
  appUserBalance: PropTypes.number,
  appUserIsEmbed: PropTypes.bool,
  appIsLoading: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
}

export default connect(
  (state) => ({
    appIsLoading: state.Global[globalConstant.IS_LOADING],
    appUserId: state.Auth[authConstant.USER_ID],
    appUserPin: state.Auth[authConstant.USER_PIN],
    appUserBalance: state.Auth[authConstant.USER_BALANCE],
    appUserPhone: state.Auth[authConstant.USER_PHONE],
    appUserIsEmbed: state.Auth[authConstant.USER_IS_EMBED],
    appProdukKomisiPremium: state.Produk[produkConstant.PROD_KOMISI_PREMIUM],
    appProdukTagihan: state.Produk[produkConstant.PRODUK_TAGIHAN],
  }),
  (dispatch) => ({ dispatch })
)(PenerimaanNegaraScreen)
