import BaseMessage from '../../message/base'
import DepositMessage from '../../message/web/deposit'
import MessageSender from '../../sender'

export const depositInq = (outletId, pin, bank, saldo) => async () => {
  const msg = new DepositMessage()
  msg.setMessageType('')
  msg.setProcessingCode('CIINQ')
  msg.setOutletID(outletId)
  msg.setPIN(pin)

  msg.data.includes.product_code = 'SCCIDEP'

  msg.setAdditionalDatum(0, bank)
  msg.setAdditionalDatum(1, saldo)
  msg.setAdditionalDatum(4, 'IDR')
  msg.setAdditionalDatum(9, outletId)

  msg.data.campaign.utm_user_id = outletId

  const response = await new MessageSender().doPost(msg)
  return new DepositMessage(response)
}

export const depositList = (outletId, status) => async () => {
  const msg = new BaseMessage()
  msg.setPath(`${BaseMessage.SC_API}/merchant/ldeposit`)
  msg.data.id = outletId
  msg.data.status = status

  msg.utm_user_id = outletId
  msg.utm_medium = ''
  msg.utm_content = ''
  msg.utm_name = ''
  msg.utm_term = ''
  msg.utm_source = ''
  msg.utm_campaign = ''
  msg.data.timestamp = new Date().getTime()

  const response = await new MessageSender().doGet(msg)
  return new BaseMessage(response)
}

export const depositDetail = (outletId, bank) => async () => {
  const msg = new BaseMessage()
  msg.setPath(`${BaseMessage.SC_API}/products/deposit`)
  msg.data.idOutlet = outletId
  msg.data.codeSource = bank

  msg.data.utm_user_id = outletId
  msg.data.utm_medium = ''
  msg.data.utm_content = ''
  msg.data.utm_name = ''
  msg.data.utm_term = ''
  msg.data.utm_source = ''
  msg.data.utm_campaign = ''
  msg.data.timestamp = new Date().getTime()

  const response = await new MessageSender().doGet(msg)
  return new DepositMessage(response)
}
