import BaseMessage from '../../message/base'
import LayananMessage from '../../message/mp/layanan'
import MessageSender from '../../sender'
import authConstant from '../../../store/constant/auth'

export const pulsaProduk = (outletId, customer) => async () => {
  const msg = new BaseMessage()
  msg.setPath(`${BaseMessage.SC_API}/products/productspulsa`)
  msg.data.id = outletId
  msg.data.number = customer
  msg.data.timestamp = new Date().getTime()

  const response = await new MessageSender().doGet(msg)
  return new BaseMessage(response)
}

export const pulsaPay = (outletId, pin, customer, discount, product) => async (dispatch) => {
  const msg = new LayananMessage()
  msg.setProcessingCode('PAY')
  msg.setOutletID(outletId)
  msg.setPIN(pin)
  msg.data.includes.product_code = product
  msg.data.includes.customer_id_1 = customer
  msg.data.includes.discount = discount

  msg.setAdditionalDatum(0, customer)

  msg.data.campaign.utm_user_id = outletId

  const response = await new MessageSender().doPost(msg)
  const msg2 = new LayananMessage(response)
  if (msg2.isOK()) {
    await dispatch({
      type: authConstant.USER_BALANCE,
      payload: Number(msg2.data.includes.balance),
    })
  }

  return msg2
}
