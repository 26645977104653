/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react'
import { connect, useSelector } from 'react-redux'
import { Accordion, Nav, Container, Row, Col } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useLocation } from 'react-router-dom'
import { isMobile } from 'react-device-detect'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { use100vh } from 'react-div-100vh'

import './sidebar.scss'
import authConstant from '../../store/constant/auth'
import SubMenuComponent from './submenu'
import logout from '../../api/action/auth/logout'

const SidebarComponent = ({ isOpen, dispatch, appuserId }) => {
  // const history = useHistory()
  const location = useLocation()
  const heightVh = use100vh()
  const { pathname } = location

  const doLogout = React.useCallback(async () => {
    await dispatch(logout())
    // const response = await dispatch(logout())
    // if (response.rc === '00') {
    //   // window.location.reload()
    //   // console.log('process.env.REACT_APP_BASE_URL', process.env.REACT_APP_BASE_URL)
    //   // history.push('/')
    //   // window.location.href = process.env.REACT_APP_BASE_URL
    // }
  }, [dispatch])

  const userMarketer = useSelector((state) => state.Auth[authConstant.USER_IS_MARKETER])

  return (
    <Container fluid className={classNames('sidebar', { 'is-web': !isMobile })}>
      <Row
        style={{
          height: appuserId
            ? isMobile
              ? heightVh
                ? heightVh * 0.83
                : '83vh'
              : heightVh
              ? heightVh * 0.82
              : '82vh'
            : isMobile
            ? heightVh
              ? heightVh * 0.9
              : '90vh'
            : heightVh
            ? heightVh * 0.87
            : '87vh',
          overflowY: 'scroll',
          backgroundColor: '#FFFFFF',
        }}
      >
        <Nav className="sidebar-content flex-column">
          <Accordion
            defaultActiveKey={
              pathname.includes('fm')
                ? '0'
                : pathname.includes('report') || pathname.includes('laporan')
                ? '1'
                : pathname.includes('transfer')
                ? '3'
                : '2'
            }
          >
            <Nav.Item>
              <Nav.Link
                href="/home"
                className={classNames('nav-item', 'nav-link', {
                  active: pathname === '/home',
                })}
              >
                <FontAwesomeIcon icon="home" fixedWidth className="mr-2" />
                Home
              </Nav.Link>
            </Nav.Item>

            <Nav.Item className={classNames(appuserId && appuserId !== '' ? 'last' : '')}>
              <Nav.Link
                href="/deposit"
                className={classNames('nav-item', 'nav-link', {
                  active: pathname === '/deposit',
                })}
              >
                <FontAwesomeIcon icon="wallet" fixedWidth className="mr-2" />
                Isi Saldo
              </Nav.Link>
            </Nav.Item>

            {!appuserId && (
              <Nav.Item className={classNames('last')}>
                <Nav.Link
                  href="https://play.google.com/store/apps/details?id=com.bm.sc.bebasbayar"
                  className={classNames('nav-item', 'nav-link')}
                >
                  <FontAwesomeIcon icon={['fab', 'google-play']} fixedWidth className="mr-2" />
                  Install SpeedCash
                </Nav.Link>
              </Nav.Item>
            )}

            {userMarketer && (
              <SubMenuComponent
                id="0"
                classStyle={['first']}
                title="Freelance Marketing"
                items={[
                  {
                    path: '/fm/report',
                    title: 'Rapot FM',
                    icon: 'file-alt',
                  },
                  {
                    path: '/fm/commission',
                    title: 'Total Komisi FM',
                    icon: 'coins',
                  },
                  {
                    path: '/fm/companion',
                    title: 'Total Teman Kamu',
                    icon: 'address-book',
                  },
                  {
                    path: '/fm/friend',
                    title: 'Temanku',
                    icon: 'user-friends',
                  },
                  {
                    path: '/fm/disbursement',
                    title: 'Pencairan Komisi',
                    icon: 'money-bill-alt',
                  },
                ]}
              />
            )}

            <SubMenuComponent
              id="1"
              title="Laporan"
              items={[
                {
                  path: '/report/transaction',
                  title: 'Laporan Transaksi',
                  icon: 'file-alt',
                },
                {
                  path: '/report/mutation',
                  title: 'Laporan Mutasi Saldo',
                  icon: 'exchange-alt',
                },
              ]}
            />

            <SubMenuComponent
              id="2"
              title="Pembayaran Tagihan"
              items={[
                {
                  path: '/isi-pulsa-termurah',
                  title: 'Pulsa & Data',
                  icon: 'mobile-alt',
                },
                {
                  path: '/ecommerce',
                  title: 'E-Commerce',
                  icon: 'shopping-cart',
                },
                {
                  path: '/emoney',
                  title: 'Top Up E-money',
                  icon: 'wallet',
                },
                {
                  path: '/game',
                  title: 'Voucher Game',
                  icon: 'gamepad',
                },
                {
                  path: '/pln',
                  title: 'PLN',
                  icon: 'bolt',
                },
                {
                  path: '/penerimaan-negara',
                  title: 'Penerimaan Negara MPN',
                  icon: 'landmark',
                },
                {
                  path: '/pdam',
                  title: 'PDAM',
                  icon: 'tint',
                },
                {
                  path: '/cek-bpjs-kesehatan',
                  title: 'BPJS Kesehatan',
                  icon: 'user-shield',
                },
                {
                  path: '/telkom',
                  title: 'Telkom & Indihome',
                  icon: 'phone-square-alt',
                },
                {
                  path: '/tv',
                  title: 'TV Berlangganan',
                  icon: 'tv',
                },
                {
                  path: '/asuransi',
                  title: 'Asuransi',
                  icon: 'shield-alt',
                },
                {
                  path: '/kartukredit',
                  title: 'Kartu Kredit',
                  icon: 'credit-card',
                },
                {
                  path: '/finance',
                  title: 'Angsuran Kredit',
                  icon: 'calculator',
                },
                {
                  path: '/pascabayar',
                  title: 'Pascabayar',
                  icon: 'phone-volume',
                },
                {
                  path: '/zakat',
                  title: 'Zakat',
                  icon: 'hand-holding-heart',
                },
                {
                  path: '/gas',
                  title: 'PGN',
                  icon: 'burn',
                },
                {
                  path: '/e-samsat',
                  title: 'Pajak Kendaraan',
                  icon: 'motorcycle',
                },
                {
                  path: '/pbb',
                  title: 'Pajak Daerah',
                  icon: 'building',
                },
              ]}
            />

            <SubMenuComponent
              id="3"
              title="Transaksi Keuangan"
              items={[
                {
                  path: '/transfer/bank',
                  title: 'Transaksi Antar Bank',
                  icon: 'university',
                },
                {
                  path: '/transfer/saldo',
                  title: 'Transaksi Antar Member',
                  icon: 'exchange-alt',
                },
                {
                  path: '/transfer/spesial',
                  title: 'Transaksi Bank Spesial',
                  icon: 'tags',
                },
              ]}
            />

            {/* <SubMenuComponent
            title="Tiket & Reservasi"
            items={[
              {
                path: '/report/transaction4',
                title: 'Tiket Pesawat',
                icon: 'plane',
              },
              {
                path: '/report/mutation18',
                title: 'Tiket Kereta Api',
                icon: 'subway',
              },
            ]}
          /> */}

            <SubMenuComponent
              id="4"
              title="Pusat Bantuan"
              items={[
                {
                  path: '/contact-us',
                  title: 'Kontak Kami',
                  icon: 'headset',
                },
              ]}
            />
          </Accordion>
        </Nav>
      </Row>
      {appuserId && (
        <Row className={classNames('footer-logout', { 'is-open': isOpen, 'is-mobile': isMobile })}>
          <Col onClick={doLogout}>
            <FontAwesomeIcon icon="sign-out-alt" className="mt-1 mr-2" fixedWidth />
            Keluar
          </Col>
        </Row>
      )}
    </Container>
  )
}

SidebarComponent.defaultProps = {
  isOpen: false,
  appuserId: '',
}

SidebarComponent.propTypes = {
  isOpen: PropTypes.bool,
  appuserId: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
}

export default connect(
  (state) => ({
    appuserId: state.Auth[authConstant.USER_ID],
  }),
  (dispatch) => ({ dispatch })
)(SidebarComponent)
