export default {
  GLOBAL: 'GLOBAL',
  IS_LOADING: 'GLOBAL_IS_LOADING',
  CLEAR: 'GLOBAL_CLEAR',
  PATHMP: 'PATHMP',
  SETTING_MENU: 'SETTING_MENU',
  KOTA: 'KOTA',
  PROPINSI: 'PROPINSI',
  COOKIE: 'COOKIE',
  CSRF_TOKEN: 'CSRF_TOKEN',
}
