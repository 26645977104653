import React from 'react'
import { Button, Image, Row, Col, Accordion, Card } from 'react-bootstrap'
import './accordion.scss'

const AccordionPascaIndosat = () => {
  return (
    <>
      <Col className="d-flex justify-content-center mt-4 mb-3 p-3">
        <h3 className="text-dark text-justify">Cara Cek Tagihan Indosat Pascabayar Via Online</h3>
      </Col>

      <div style={{ padding: '0px 20px 30px 20px' }}>
        <Row className="justify-content-md-center">
          <Col lg={8} xs={12} md={8} className="content-component">
            <div>
              <Row className="d-flex">
                <Row>
                  <p className="d-block text-justify">
                    Indosat memiliki beberapa produk kartu seluler yang banyak dikenal masyarakat
                    indonesia antara lain seperti indosat IM3 pascabayar (post paid tagihan bulanan)
                    dan IM3 prabayar. Aktivitas yang harus dilakukan pelanggan indosat setiap
                    bulannya ialah{' '}
                    <a href="https://member.speedcash.co.id/pascabayar/indosat">
                      cek tagihan indosat pascabayar
                    </a>{' '}
                    (karena pascabayar wajib dibayar setiap bulannya). Lalu bagaimana cara bayar
                    tagihan indosat setiap bulan agar lebih mudah dan tentunya mendapat diskon
                    ketika membayarnya. Berikut caranya.
                  </p>
                </Row>
                <Row className="d-flex justify-content-center my-2">
                  <Image
                    src="/img/cashless-adalah.png"
                    data-target="cashless-adala"
                    alt="cashless-adala"
                    width="100%"
                  />
                </Row>
              </Row>
            </div>
          </Col>

          <Col lg={8} xs={12} md={8} className="accordion-component">
            <Accordion className="m-0 p-0">
              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="1"
                    className="button-collapse w-100 text-left"
                  >
                    Cek Tagihan Indosat Pascabayar Tanpa Aplikasi
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="1">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Melihat total tagihan indosat pascabayar tanpa aplikasi sangatlah mudah,
                        kamu bisa melakukan dengan beberapa langkah berikut ini :{' '}
                      </p>
                      <Row className="mt-3">
                        <ul className="text-justify list-content">
                          <li>
                            Kunjungi alamat web : https://member.speedcash.co.id/pascabayar/indosat
                          </li>
                          <li>Pilih menu PASCABAYAR</li>
                          <li>Pilih produk pascabayar INDOSAT</li>
                          <li>Masukkan nomor HP</li>
                          <li>Klik cek tagihan sekarang</li>
                          <li>Ikuti instruksi selanjutnya hingga pembayaran berhasil</li>
                        </ul>
                      </Row>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="2"
                    className="button-collapse w-100 text-left"
                  >
                    Cara Lihat Tagihan Indosat IM3 Pascabayar Di Aplikasi
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="2">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Ternyata mengecek tagihan indosat IM3 pascabayar bisa juga memakai aplikasi
                        agar lebih mudah dan menghindari keterlambatan pembayaran, berikut cara
                        bayar tagihan indosat IM3 pascabayar di aplikasi resmi :
                      </p>
                      <Row className="mt-3">
                        <ul className="text-justify list-content">
                          <li>Download aplikasi SpeedCash Di Playstore Ataupun Appstore</li>
                          <li>
                            JIka sudah login dulu menggunakan nomor HP (jika belum bisa login maka
                            kamu wajib daftar dulu menggunakan nomor HP)
                          </li>
                          <li>
                            Jika sudah bisa login lanjutkan dengan klik menu TELEPON PASCABAYAR
                          </li>
                          <li>
                            <p>Pilih produk INDOSAT (IM3 Pascabayar/Matrix)</p>
                            <Row className="d-flex justify-content-center">
                              <Image
                                src="/img/bayar-tagihan-indosat-pascabayar.png"
                                data-target="bayar-tagihan-indosat-pascabayar"
                                width="220px"
                                height="400px"
                              />
                            </Row>
                          </li>

                          <li>Masukkan Nomor HP</li>
                          <li>
                            Klik lanjutkan dan ikuti instruksi selanjutnya hingga pembayaran
                            berhasil
                          </li>
                        </ul>
                      </Row>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="3"
                    className="button-collapse w-100 text-left"
                  >
                    Mengapa Harus Bayar Tagihan Indosat Postpaid (Pascabayar) Di SpeedCash
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="3">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Beberapa alasan mengapa harus bayar tagihan indosat bulan ini melalui
                        SpeedCash, salah satunya karena pembayaran tagihan bulanan di speedcash bisa
                        hemat hingga 50.000. Tidak hanya itu jika menggunakan SpeedCash bisa juga
                        untuk{' '}
                        <a href="https://member.speedcash.co.id/pln/cek-tagihan-listrik">
                          bayar tagihan bulanan PLN
                        </a>
                        , <a href="https://member.speedcash.co.id/pdam">PDAM</a>, hingga untuk{' '}
                        <a href="https://member.speedcash.co.id/game">top up game</a> maupun beli
                        voucher game juga.SpeedCash mempunyai lebih dari 100 produk yang bisa
                        dimanfaatkan masyarakat.Untuk melihat semua layanan SpeedCash secara lengkap
                        kamu bisa langsung mendownload aplikasi SpeedCash yang sudah tersedia di
                        Playstore dan Appstore.
                      </p>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="4"
                    className="button-collapse w-100 text-left"
                  >
                    Keamanan Bayar Tagihan Indosat Pascabayar Di SpeedCash
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="4">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        SpeedCash.co.id berkomitmen untuk melindungi dan merahasiakan data pribadi
                        Anda. Seluruh data atau informasi yang Anda masukkan selama proses pengajuan
                        dilindungi menggunakan teknologi enkripsi dan keamanan termutakhir sehingga
                        terlindungi dengan baik.
                      </p>
                      <p className="d-block text-justify mt-1">
                        Agar keamanan data pribadi Anda tetap selalu terjaga, berikut beberapa tips
                        dan hal yang perlu diperhatikan:
                      </p>
                      <p className="d-block text-justify mt-1 font-italic">
                        Jangan Sembarangan Memberikan Informasi Pribadi
                      </p>
                      <p className="d-block text-justify mt-1">
                        Jangan pernah sembarangan memberikan informasi pribadi kepada siapapun di
                        luar situs SpeedCash. Data pribadi yang dimaksud antara lain adalah
                        informasi pribadi, sandi (password), KTP, Foto Selfie, NPWP, dll.
                      </p>
                      <p className="d-block text-justify mt-1 font-italic">
                        Jaga Kerahasiaan Kode OTP
                      </p>
                      <p className="d-block text-justify mt-1">
                        Jangan memberikan kode OTP yang masuk melalui SMS / e-mail kepada siapapun
                        termasuk pihak-pihak yang mengatasnamakan diri sebagai SpeedCash.{' '}
                      </p>
                      <p className="d-block text-justify mt-1 font-italic">
                        Jangan Berkomentar Sembarangan{' '}
                      </p>
                      <p className="d-block text-justify mt-1">
                        Jangan pernah mempublikasikan data pribadi Anda di kolom komentar media
                        sosial manapun agar tetap aman.
                      </p>
                      <p className="d-block text-justify mt-1 font-italic">
                        Waspada Terhadap Akun Media Sosial Palsu
                      </p>
                      <p className="d-block text-justify mt-1">
                        Hati-hati terhadap segala informasi yang diberikan oleh akun palsu yang
                        mengatasnamakan diri sebagai SpeedCash. Berikut akun media sosial SpeedCash
                        yang terverifikasi:{' '}
                      </p>
                      <div className="w-100 d-flex flex-column mb-3">
                        <span className="d-block text-justify mt-1">
                          Instagram Resmi SpeedCash (@SpeedCashindonesia)
                        </span>
                        <span className="d-block text-justify mt-1">
                          Facebook Resmi SpeedCash (@SpeedCashindonesia)
                        </span>
                      </div>
                      <p className="d-block text-justify mt-1 font-italic">
                        Gunakan Aplikasi Resmi SpeedCash di Play Store dan Appstore{' '}
                      </p>
                      <p className="d-block text-justify mt-1">
                        Unduh aplikasi resmi SpeedCash melalui Play Store. Hindari mengunduh
                        aplikasi SpeedCash dari website atau link lain selain dari Google Play Store
                        maupun Appstore{' '}
                      </p>
                      <p className="d-block text-justify mt-1 font-italic">
                        Waspada Terhadap Link Mencurigakan{' '}
                      </p>
                      <p className="d-block text-justify mt-1">
                        Website resmi SpeedCash hanya bisa diakses pada domain
                        https://www.SpeedCash.co.id/. Harap berhati-hati jika Anda menerima pesan
                        atau informasi dari seseorang untuk mengakses/mengklik tautan tertentu di
                        luar situs resmi SpeedCash atau akun media sosial.{' '}
                      </p>
                      <p className="d-block text-justify mt-1 font-italic">
                        Informasi pengiriman promosi, pengiriman dan informasi email lainnya hanya
                        dapat dikirimkan melalui alamat email resmi SpeedCash berikut ini:
                      </p>
                      <p className="d-block text-justify mt-1">
                        @SpeedCashindonesia Abaikan jika Anda menerima email lain dengan alamat
                        berbeda atas nama Anda sebagai pihak dalam SpeedCash.
                      </p>
                      <p className="d-block text-justify mt-1 font-italic">
                        Selalu perbarui kata sandi akun SpeedCash Anda
                      </p>
                      <p className="d-block text-justify mt-1">
                        Untuk menjaga keamanan akun Anda, perbarui kata sandi akun SpeedCash Anda
                        setiap 3 bulan. Pembaruan kata sandi dapat dilakukan melalui menu Akun Saya
                        dan memilih Ubah Kata Sandi. Jika Anda ceroboh atau merasa akun Anda tidak
                        aman, harap segera ubah kata sandi akun SpeedCash Anda untuk menjamin
                        keamanan akun Anda.
                      </p>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="5"
                    className="button-collapse w-100 text-left"
                  >
                    Cara Isi Saldo SpeedCash
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="5">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Setelah mengecek tagihan indosat pascabayar dan saat akan melakukan
                        pembayaran tagihan pastikan akun SpeedCashmu sudah terisi saldo yang
                        cukup.Untuk mengisi saldo SpeedCash juga caranya sangat mudah, kamu bisa
                        mengikuti cara seperti yang ada di video ini.
                      </p>
                      <iframe
                        width="100%"
                        height="480"
                        src="https://www.youtube.com/embed/sI1fLYglLwk"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        frameBorder={0}
                        title="cara isi saldo SpeedCash"
                        className="my-3"
                      />
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="6"
                    className="button-collapse w-100 text-left"
                  >
                    Beli Pulsa Indosat IM3 Banyak Pilihannya
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="6">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Mungkin saat ini banyak sekali orang yang memiliki kartu seluler lebih dari
                        satu, dan bisa jadi dari beberapa kartu seluler juga yang dimiliki merupakan
                        indosat M3 prabayar. Ternyata SpeedCash tidak hanya bisa untuk bayar tagihan
                        bulanan indosat tetapi juga bisa untuk beli pulsa indosat maupun paket
                        internet dengan pilihan terlengkap mulai dari pulsa regular, pulsa transfer
                        (tanpa masa aktif) pulsa telepon hingga pulsa SMS. Harganya juga murah mulai
                        dari harga 5.000 rupiah saja.
                      </p>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="7"
                    className="button-collapse w-100 text-left"
                  >
                    Keuntungan Menggunakan Indosat Pascabayar
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="7">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block-text-justify">
                        Meski tak sepopuler kartu prabayar, kartu SIM dengan sistem pascabayar
                        ternyata bisa membawa banyak keuntungan bagi penggunanya. Bahkan, tanpa
                        disadari, kelebihan SIM pascabayar lebih cocok untuk pengguna prabayar.
                      </p>
                      <p className="d-block-text-justify mt-1">
                        <b>Komunikasi yang aman dan nyaman:</b>
                      </p>
                      <p className="d-block-text-justify mt-1">
                        Kelebihan menggunakan kartu layanan pascabayar berarti komunikasi terjamin
                        dapat dilakukan di kapan pun. Karena bisa memanfaatkan fitur-fitur yang
                        ditawarkan tanpa harus melakukan transaksi terlebih dahulu, pengguna kartu
                        SIM pascabayar tidak perlu khawatir tidak bisa menggunakan layanan tersebut,
                        apalagi saat ada urusan pekerjaan.
                      </p>
                      <p className="d-block-text-justify mt-1">
                        <b>Kebebasan memilih kuota dan masa pengoperasian</b>
                      </p>
                      <p className="d-block-text-justify mt-1">
                        Kartu pascabayar juga memberikan keuntungan berupa kebebasan memilih kuota
                        atau masa pengoperasian. Salah satu hal yang membuat kartu SIM prabayar
                        menjadi sulit adalah Anda harus mengganti atau memperbarui layanan ketika
                        waktu pengoperasian atau batas penggunaan habis. Sedangkan untuk tipe
                        pascabayar, Anda hanya perlu memilih jenis layanan pada kontrak penggunaan
                        pertama. Kontrak layanan ini biasanya berlangsung dari 12 hingga 24 bulan.
                        Jadi Anda tidak perlu khawatir untuk membeli SIM baru seperti saat
                        menggunakan kartu prabayar.
                      </p>
                      <p className="d-block-text-justify mt-1">
                        <b>Banyak promosi dan bonus</b>
                      </p>
                      <p className="d-block-text-justify mt-1">
                        Keuntungan menggunakan layanan pascabayar selanjutnya adalah mendapatkan
                        banyak promosi atau promosi menarik. penawaran dari operator kartu SIM. Oleh
                        karena itu, dengan memanfaatkan promosi berupa diskon dan refund dari
                        penyedia kartu pascabayar bukan tidak mungkin namun penggunaan SIM jenis ini
                        menjadi jauh lebih masuk akal.
                      </p>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="8"
                    className="button-collapse w-100 text-left"
                  >
                    Kendala Saat Cek Tagihan Indosat Maupun Saat Bayar Indosat Bulanan
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="8">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Apabila pembayaran maupun{' '}
                        <a href="https://member.speedcash.co.id/pascabayar/indosat">
                          cek tagihan indosat
                        </a>{' '}
                        pascabayar di SpeedCash tidak berhasil, maka jumlah tersebut akan
                        dikembalikan ke saldo SpeedCash. Tidak perlu khawatir dengan uang Anda, Anda
                        dapat menarik dana dari saldo SpeedCash Anda dan menggunakannya untuk
                        membeli atau membayar banyak produk lainnya di SpeedCash.
                      </p>
                      <p className="d-block text-justify mt-1">
                        <b>Pembayaran Sudah Berhasil Namun Tagihan Masih Muncul</b> : Jika
                        pembayaran tagihan pascabayar sudah dinyatakan berhasil oleh SpeedCash namun
                        tagihan pascabayar masih muncul, Anda dapat menghubungi langsung pihak
                        Indosat terkait untuk melakukan pemeriksaan lebih lanjut.
                      </p>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="9"
                    className="button-collapse w-100 text-left"
                  >
                    Cara Daftar Indosat Pascabayar
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="9">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Anda dapat membeli langganan pascabayar dengan mendaftarkan kartu pascabayar
                        di tempat penjualan penyedia layanan seluler pascabayar Anda. Indosat
                        pascabayar adalah penyedia jaringan yang menyediakan layanan pascabayar di
                        Indonesia. (Sebelumnya produk ini dikenal dengan nama Indosat Matrix)
                      </p>
                      <p className="d-block text-justify mt-1">
                        Untuk informasi lebih lanjut mengenai afiliasi masing-masing operator
                        seluler, silakan kunjungi situs resmi masing-masing operator.
                      </p>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="10"
                    className="button-collapse w-100 text-left"
                  >
                    Cara Pindah Indosat Prabayar Ke Pascabayar
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="10">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Untuk mengubah kartu indosat IM3 mu dari prabayar menjadi pascabayar
                        ternyata juga cukup mudah, kamu bisa lakukan langsung di gerai indosat
                        terdekat yang ada dikotamu agar kamu mendapatkan informasi juga mengenai
                        detail paket indosat pascabayar yang tersedia.
                      </p>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="11"
                    className="button-collapse w-100 text-left"
                  >
                    Hal Yang Harus Diketahui Tentang Pascabayar Indosat
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="11">
                  <Card.Body className="card-content">
                    <Row>
                      <div className="w-100 my-1">
                        <ol className="text-justify list-content">
                          <li>Batas waktu pembayaran invoice pascabayar</li>

                          <li>
                            Pembayaran tagihan pascabayar dapat dilakukan setelah invoice
                            diterbitkan.
                          </li>

                          <li>Risiko keterlambatan pembayaran invoice pascabayar </li>
                        </ol>
                        <p className="d-block text-justify mt -1">
                          Kartu pascabayar akan langsung dinonaktifkan oleh jaringan setiap kali
                          Anda melakukan pembayaran terlambat. Kartu tersebut biasanya tidak dapat
                          digunakan sampai tagihan saat ini telah dibayar lunas.
                        </p>
                      </div>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="12"
                    className="button-collapse w-100 text-left"
                  >
                    F.A.Q Cek Tagihan Dan Bayar Indosat Pascabayar Di SpeedCash
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="12">
                  <Card.Body className="card-content">
                    <Row>
                      <div className="w-100 mt-1 mb-3 d-flex flex-column">
                        <span className="d-block text-justify">
                          <b>Q: Berapa tagihan kartu pascabayar saya?</b>
                        </span>
                        <p className="d-block text-justify mt-1">
                          Periksa tagihan kartu pascabayar Anda sekarang di halaman Tagihan
                          Pascabayar SpeedCash. Sistem akan secara otomatis mengekspor rincian
                          tagihan pascabayar ke nomor telepon yang Anda masukkan.
                        </p>
                      </div>
                      <div className="w-100 mt-1 mb-3 d-flex flex-column">
                        <span className="d-block text-justify">
                          <b>Q: Kapan saya bisa membayar tagihan pascabayar saya?</b>
                        </span>
                        <p className="d-block text-justify mt-1">
                          Anda dapat melakukannya Bayar tagihan pascabayar Anda setelah diterbitkan
                          oleh ponsel pascabayar operator. Faktur dikirim setiap 30 hari sejak
                          aktivasi pertama paket pascabayar. Beberapa operator menawarkan jangka
                          waktu pembayaran invoice pascabayar hingga 30 hari setelah invoice
                          diterbitkan, termasuk INDOSAT pascabayar. Untuk informasi lebih lengkap,
                          cek tanggal jatuh tempo tagihan pascabayar Anda di SpeedCash Tagihan
                          Pascabayar.
                        </p>
                      </div>
                      <div className="w-100 mt-1 mb-3 d-flex flex-column">
                        <span className="d-block text-justify">
                          <b>
                            Q: Apa yang harus saya lakukan jika saya terlambat membayar tagihan
                            pascabayar?
                          </b>
                        </span>
                        <p className="d-block text-justify mt-1">
                          Kartu Anda akan dinonaktifkan oleh Anda dan pihak penyedia layanan seluler
                          tidak akan dapat menggunakan kartu pascabayar sampai pembayaran dilakukan.
                        </p>
                      </div>
                      <div className="w-100 mt-1 mb-3 d-flex flex-column">
                        <span className="d-block text-justify">
                          <b>Q: Apa yang terjadi jika saya salah memasukkan nomor kartu?</b>
                        </span>
                        <p className="d-block text-justify mt-1">
                          Detail yang diberikan meliputi nama lengkap pemegang kartu pascabayar
                          nomor ponsel, selalu perhatikan nama pada detail invoice yang diterbitkan
                          untuk menghindari kesalahan saat Membayar tagihan pascabayar.
                        </p>
                      </div>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="13"
                    className="button-collapse w-100 text-left"
                  >
                    Syarat & Ketentuan Cek Tagihan Indosat Pascabayar Di SpeedCash
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="13">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Berikut syarat dan ketentuan pembayaran tagihan Indosat pascabayar di
                        SpeedCash:
                      </p>
                      <div className="w-100 my-1">
                        <ol className="text-justify list-content">
                          <li>
                            Pastikan Anda memiliki akun SpeedCash dan telah memverifikasi nomor
                            ponsel Anda.
                          </li>

                          <li>
                            Pengguna akan dikenakan biaya administrasi tergantung pada jenis tagihan
                            pascabayar.
                          </li>

                          <li>
                            Biaya administrasi dikenakan untuk setiap invoice bulanan pascabayar
                            dari Indosat. Jadi, jika Anda membayar tagihan pascabayar 2 bulan dalam
                            satu kali transaksi, Anda akan dikenakan biaya administrasi sebesar dua
                            kali lipat.
                          </li>

                          <li>
                            Dalam 1 transaksi, total tagihan pascabayar yang ditampilkan adalah
                            seluruh item Biaya termasuk akumulasi seluruh hutang bulanan . tagihan
                            pascabayar, denda (jika ada) dan biaya administrasi.
                          </li>

                          <li>
                            Jika pembayaran tagihan pascabayar dilakukan setelah tanggal jatuh tempo
                            bulanan, Anda akan dikenakan biaya penalti. Penentuan tanggal jatuh
                            tempo bergantung pada partner pembayaran pascabayar.
                          </li>

                          <li>
                            SpeedCash tidak bertanggung jawab atas kesalahan pembayaran tagihan
                            pascabayar akibat pengguna salah memasukkan nomor pelanggan.
                          </li>

                          <li>
                            SpeedCash tidak pernah meminta email akun pengguna. dan kata sandi.
                            Mohon untuk tidak memberikan data ini kepada siapa pun.
                          </li>

                          <li>Biaya tagihan dapat berubah sewaktu-waktu tanpa pemberitahuan.</li>

                          <li>
                            Verifikasi pembayaran tagihan pascabayar mungkin memerlukan waktu hingga
                            2×24 jam.
                          </li>

                          <li>
                            Informasi lebih lanjut Untuk lebih lanjut informasi mengenai pembayaran
                            dan pertanyaan terkait tagihan pascabayar dapat langsung menghubungi
                            Indosat.
                          </li>
                        </ol>
                      </div>
                      <p className="d-block text-justify mt-1">
                        Itulah tadi informasi mengenai cek tagihan indosat dan bayar pascabayar
                        indosat bulanan melalui SpeedCash dengan aplikasi maupun via website resmi.
                        Masing masing memiliki kemudahan yang bisa anda dapatkan langsung.Jika kamu
                        ingin mendapatkan SpeedCash kamu bisa langsung mendownload SpeedCash melalui
                        link dibawah ini.
                      </p>

                      <Row className="d-flex w-100 justify-content-center mb-3 gap-3">
                        <a
                          aria-hidden
                          href="https://play.google.com/store/apps/details?id=com.bm.sc.bebasbayar&listing=speedcash_banner_app"
                          className="mx-1"
                        >
                          <Image
                            src="/img/playstore-speedcash.png"
                            data-target="playstore-speedcash"
                            width="220px"
                            height="60px"
                          />
                        </a>
                        <a
                          aria-hidden
                          href="https://apps.apple.com/id/app/speedcash-transfer-ewallet/id6459304122"
                          className="mx-1"
                        >
                          <Image
                            src="/img/appstore-speedcash.png"
                            data-target="appstore-speedcash"
                            width="220px"
                            height="60px"
                          />
                        </a>
                      </Row>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default AccordionPascaIndosat
