import moment from 'moment'
import 'moment/locale/id'

moment.locale('id')

const DateTime = {
  FMT_DAY_E: 'dddd',
  FMT_DATE_D: 'DD',
  FMT_DATE_M: 'MM',
  FMT_DATE_Y: 'YYYY',
  FMT_DATE_MY_LONG: 'MMMM YYYY',
  FMT_DATE_YMD: 'YYYY-MM-DD',
  FMT_TIME_HM: 'HH:mm',
  FMT_TIME_HMS: 'HH:mm:ss',
  FMT_DATE_TIME_YMDHMS_NS: 'YYYYMMDDHHmmss',
  FMT_DATE_TIME_YMDHM: 'YYYY-MM-DD HH:mm',
  FMT_DATE_TIME_YMDHMS: 'YYYY-MM-DD HH:mm:ss',
  FMT_DATE_TIME_YMDHMSU: 'YYYY-MM-DD HH:mm:ss.SSS',
  FMT_DATE_TIME_DMYHM: 'DD-MM-YYYY HH:mm',
  FMT_DATE_TIME_DMYHMS: 'DD-MM-YYYY HH:mm:ss',

  addHour(hour, format) {
    return moment()
      .add(hour, 'hour')
      .format(format || DateTime.FMT_DATE_TIME_YMDHMS)
  },

  today(format) {
    return moment().format(format)
  },

  startOfMonth(format) {
    return moment().startOf('month').format(format)
  },

  endOfMonth(format) {
    return moment().endOf('month').format(format)
  },

  parse(str, format) {
    const result = moment(str, format)
    if (!result.isValid()) {
      return moment()
    }

    return result
  },

  format(date, format) {
    return moment(date).format(format)
  },

  convert(str, format, newFormat) {
    return DateTime.parse(str, format).format(newFormat)
  },

  getExpired(date, format = this.FMT_DATE_TIME_YMDHMS) {
    const convertFormatDate = this.convert(date, format, this.FMT_DATE_TIME_YMDHMS)

    return moment(convertFormatDate).add(2, 'hours').format(this.FMT_DATE_TIME_DMYHM)
  },

  isExpired(date) {
    const expiredDate = moment(date).add(2, 'hours').format(this.FMT_DATE_TIME_YMDHMS)
    const currDate = moment().format(this.FMT_DATE_TIME_YMDHMS)

    return currDate > expiredDate
  },

  listYears(back) {
    const year = new Date().getFullYear()
    return Array.from({ length: back }, (v, i) => year - back + i + 1).reverse()
  },
}

export default DateTime
