import React from 'react'
import { Button, Image, Row, Col, Accordion, Card } from 'react-bootstrap'
import './accordionGame.scss'

const AccordionGameMl = () => {
  return (
    <>
      <Col className="d-flex justify-content-center mt-4 mb-3 p-3">
        <h3 className="text-dark text-justify">
          Top Up ML (Mobile Legend) Beli Diamond Murah Pakai SpeedCash
        </h3>
      </Col>

      <div style={{ padding: '0px 20px 30px 20px' }}>
        <Row className="justify-content-md-center">
          <Col lg={8} xs={12} md={8} className="content-game">
            <div>
              <Row className="d-flex justify-content-center">
                <Image src="/img/banner-ml.png" data-target="banner-ml" width="100%" />
              </Row>
              <Row className="d-flex justify-content-center">
                <p className="d-block text-justify mt-3">
                  Mendapatkan tempat top up ML murah merupakan suatu keharusan bagi para pecinta
                  game mobile legend yang ingin beli diamond dengan harga termurah.Saat ini buat
                  kamu yang gemar bermain game ini bisa langsung membeli diamond ML melalui halaman
                  ini.Tentunya lebih cepat,aman legal dan terpercaya,yuk mari simak langkahnya jika
                  top up mobile legend (ML) tanpa aplikasi melalui SpeedCash.
                </p>
              </Row>
              <Row className="d-flex mt-4 mb-2 justify-content-center text-dark">
                <h4> Langkah Top Up Diamond ML Via SpeedCash</h4>
              </Row>
              <Row>
                <p className="d-block text-justify">
                  Untuk membeli diamond /{' '}
                  <a href="https://www.speedcash.co.id/">top up ML via SpeedCash</a> cukup mudah,
                  berikut langkahnya :
                </p>
              </Row>
              <Row className="d-flex justify-content-start">
                <ol className="text-justify list-content">
                  <li>
                    kunjungi :{' '}
                    <a href="https://member.speedcash.co.id/game">
                      https://member.speedcash.co.id/game
                    </a>{' '}
                    lalu Klik Masuk / Login
                  </li>
                  <li>Masukkan nomor telepon kamu, lalu lanjutkan</li>
                  <li>Ikuti langkah-langkahnya, klik lanjutkan</li>
                  <li>Tunggu ada notifikasi yang masuk via WA/SMS</li>
                  <li>
                    Masukkan 4 digit terakhir nomor yang diberikan via WA/SMS, lalu klik lanjutkan.
                  </li>
                  <li>Pertama pastikan isi saldo SpeedCash mu dulu </li>
                  <li>Jika sudah lanjutkan dengan pilih menu VOUCHER GAME</li>
                  <li>Lalu Pilih menu MOBILE LEGEND atau pilih game lain yang kamu inginkan.</li>
                  <li>Pilih nominalnya, kemudian beli.</li>
                  <li>Ikuti instruksi yang diberikan</li>
                </ol>
              </Row>
            </div>
          </Col>

          <Col lg={8} xs={12} md={8} className="accordion-game">
            <Accordion className="m-0 p-0">
              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="1"
                    className="button-collapse w-100 text-left"
                  >
                    Top Up ML (Mobile Legend) Diamond Di Aplikasi SpeedCash
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="1">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Jika kamu merasa puas beli diamond ML melalui website SpeedCash,artinya kamu
                        wajib coba untuk Top Up melalui aplikasi SpeedCash, caranya pun sangat
                        mudah.Berikut langkahnya :
                      </p>
                      <Row className="mt-3">
                        <ol className="text-justify list-content">
                          <li>
                            Download aplikasi SpeedCash jika kamu belum punya (speedcash tersedia di
                            android dan IOS)
                          </li>
                          <li>
                            Masuk/daftar SpeedCash (jika sudah pernah daftar, kamu bisa login
                            langsung menggunakan no hp yang terdaftar di SpeedCash)
                          </li>
                          <li>Jika sudah, kamu bisa isi saldo SpeedCash dulu</li>
                          <li>
                            <p>Lalu pilih menu VOUCHER GAME</p>
                            <Row className="d-flex justify-content-center">
                              <Image
                                src="/img/voucher-game1.png"
                                data-target="voucher-game1"
                                width="220px"
                                height="400px"
                              />
                            </Row>
                          </li>
                          <li>
                            <p>Pilih Top Up</p>
                            <Row className="d-flex justify-content-center">
                              <Image
                                src="/img/voucher-game2.png"
                                data-target="voucher-game2"
                                width="220px"
                                height="400px"
                              />
                            </Row>
                          </li>
                          <li>Lanjutkan dengan klik icon / pilih MOBILE LEGEND</li>
                          <li>
                            <p>Masukan ID Pengguna</p>
                            <Row className="d-flex justify-content-center">
                              <Image
                                src="/img/voucher-game4.png"
                                data-target="voucher-game4"
                                width="220px"
                                height="400px"
                              />
                            </Row>
                          </li>
                          <li>Pilih diamond ML yang sesuai dengan pilihan kamu</li>
                          <li>Ikuti instruksi selanjutnya hingga kamu berhasil top up</li>
                        </ol>
                      </Row>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="2"
                    className="button-collapse w-100 text-left"
                  >
                    Keuntungan Top Up ML (Mobile Legend) Di SpeedCash
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="2">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Membeli diamond / top up ML di SpeedCash memberikan banyak keuntungan buat
                        kamu, daripada kamu membeli diamond di tempat lain,karena di SpeedCash
                        tersedia banyak pilihan diamond.Bahkan mulai harga 8.500 rupiah kamu sudah
                        mendapatkan 36 diamond. Keuntungan lainnya seperti :
                      </p>
                    </Row>
                    <Row className="d-flex justify-content-start">
                      <ol className="text-justify list-content">
                        <li>Banyaknya pilihan Denom Diamond ML yang bisa kamu beli</li>
                        <li>Tersedia denom diamond mulai 36 Mobile Legend Diamond</li>
                        <li>Harga Diamond mulai 8.500 rupiah</li>
                        <li>Bisa top up Diamond tanpa aplikasi</li>
                        <li>
                          Masih banyak kelebihan yang bisa kamu dapatkan jika top up ff di SpeedCash
                        </li>
                        <li>
                          Selain membeli diamond kamu juga bisa menggunakan fitur SpeedCash lainnya
                          seperti : transfer bank gratis , beli tiket kereta , hingga bayar tilang
                          online
                        </li>
                      </ol>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="3"
                    className="button-collapse w-100 text-left"
                  >
                    Cara Isi Saldo SpeedCash Sebelum Beli Diamond ML
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="3">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Cara menambahkan saldo SpeedCash juga sangat mudah karena terdapat beberapa
                        cara yang bisa kamu pilih seperti mengisi via Transfer bank,Virtual account
                        bank hingga mengisi saldo SpeedCash melalui indomaret/alfamaret ataupun
                        minimarket lainnya.
                      </p>
                      <p className="d-block text-justify mt-1">
                        Untuk mengetahui lebih jelas tentang cara isi saldo SpeedCash kamu bisa
                        mengikuti tutorial yang ada di Video Ini :
                      </p>
                      <Row className="d-flex w-100 justify-content-center mb-3">
                        <iframe
                          width="100%"
                          height="480"
                          src="https://www.youtube.com/embed/sI1fLYglLwk"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                          frameBorder={0}
                          title="Cara Isi Saldo Di SpeedCash"
                        />
                      </Row>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="4"
                    className="button-collapse w-100 text-left"
                  >
                    Jika Ada Kendala Dalam Membeli Diamond ML Di SpeedCash
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="4">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Saat kamu membeli diamond Mobile Legend (ML) di SpeedCash hal yang harus
                        kamu perhatikan utamanya ialah ID Pengguna pastikan kamu sudah benar.Namun
                        jika masih ada masalah,kamu bisa langsung menghubungi pusat bantuan (CS)
                        layanan customer service di SpeedCash siap membantumu selama 24 jam sehari
                        (24/7).Dengan senang hati kami akan membantu mengatasi keluhanmu saat
                        mengalami kendala di SpeedCash.
                      </p>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="5"
                    className="button-collapse w-100 text-left"
                  >
                    Sekilas Info Mengenai Game Mobile Legend
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="5">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Mobile Legends: Bang Bang, sering disingkat menjadi Mobile Legends atau ML,
                        adalah permainan video multiplayer online battle arena (MOBA) yang khusus
                        dirancang untuk perangkat seluler seperti ponsel cerdas dan tablet.
                      </p>
                      <p className="d-block text-justify mt-1">
                        Moonton, anak perusahaan dari ByteDance, mengembangkan dan menerbitkan game
                        ini.
                      </p>
                      <div className="d-flex flex-column">
                        <p className="d-block text-justify mt-3">
                          <b> Berikut beberapa hal mendasar tentang Mobile Legends:</b>
                        </p>
                        <Row className="mt-1">
                          <ul className="text-justify list-content">
                            <li>
                              <b>Genre:</b> MOBA
                            </li>
                            <li>
                              <b>Platform:</b> Android, iOS
                            </li>
                            <li>
                              <b>Pengembang dan Penerbit:</b> Moonton
                            </li>
                            <li>
                              <b>Gameplay:</b> Pertempuran tim 5 vs 5 di arena virtual
                            </li>
                            <li>
                              <b>Fitur:</b> Berbagai pilihan hero dengan role berbeda, mode
                              permainan beragam, dan komunitas pemain yang aktif
                            </li>
                          </ul>
                        </Row>
                      </div>
                      <p className="d-block text-justify mt-1">
                        Mobile Legends merupakan salah satu game MOBA terpopuler di Indonesia dan
                        dunia. Game ini menawarkan gameplay yang kompetitif, hero dengan kemampuan
                        unik, dan berbagai mode permainan yang seru.
                      </p>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="6"
                    className="button-collapse w-100 text-left"
                  >
                    Sejarah Game Mobile Legend
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="6">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Sejarah Mobile Legends: Bang Bang (MLBB) dimulai pada tahun 2016 ketika game
                        ini pertama kali dirilis oleh Moonton, sebuah perusahaan pengembang game
                        asal Tiongkok. Awalnya, MLBB diluncurkan secara eksklusif untuk platform
                        mobile, termasuk Android dan iOS. Game ini segera mendapat perhatian besar
                        dari pemain di seluruh dunia berkat gameplay yang seru dan kompetitif.
                      </p>
                      <p className="d-block text-justify mt-1">
                        Pada awalnya, Mobile Legends menawarkan pengalaman bermain yang mirip dengan
                        permainan MOBA lainnya, di mana pemain bergabung dalam tim untuk bertarung
                        melawan tim lawan dalam pertempuran yang intens di sebuah arena. Setiap
                        pemain memilih karakter (hero) dengan kemampuan dan peran yang berbeda-beda
                        untuk membantu tim mencapai kemenangan dengan strategi dan kerjasama yang
                        baik.
                      </p>
                      <p className="d-block text-justify mt-1">
                        Seiring berjalannya waktu, Mobile Legends terus mengalami perkembangan dan
                        pembaruan. Moonton secara teratur memperkenalkan pembaruan baru, termasuk
                        penambahan hero, peningkatan fitur permainan, dan penyempurnaan dalam
                        keseimbangan permainan. Hal ini membantu menjaga minat pemain tetap tinggi
                        dan membuat Mobile Legends tetap relevan di pasar game mobile yang
                        kompetitif.
                      </p>
                      <p className="d-block text-justify mt-1">
                        Selain itu, Mobile Legends juga menjadi salah satu game eSports mobile yang
                        paling populer. Turnamen-turnamen besar, baik di tingkat regional maupun
                        global, diadakan secara rutin untuk mempertandingkan pemain-pemain terbaik
                        dari berbagai belahan dunia. Ini membawa Mobile Legends ke panggung
                        internasional dan membantu meningkatkan popularitasnya sebagai salah satu
                        game mobile terkemuka di dunia.
                      </p>
                      <p className="d-block text-justify mt-1">
                        Sejak itu, Mobile Legends terus berkembang dan menjadi salah satu game
                        mobile paling populer di dunia, dengan jutaan pemain yang aktif bermain
                        setiap hari. Meskipun tidak tanpa kontroversi, Mobile Legends tetap menjadi
                        favorit di kalangan penggemar game MOBA mobile dan terus menarik perhatian
                        dengan berbagai pembaruan dan acara in-game yang menarik.
                      </p>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="7"
                    className="button-collapse w-100 text-left"
                  >
                    Apa Itu Twilight Pass Di Mobile Legend ?
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="7">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Twilight Pass adalah fitur terbaru di game Mobile Legends: Bang Bang (MLBB)
                        yang bertujuan untuk meningkatkan pengalaman bermain para penggunanya.
                      </p>
                      <p className="d-block text-justify mt-1">
                        <b>Berikut beberapa hal tentang Twilight Pass:</b>
                      </p>
                      <p className="d-block text-justify mt-1">
                        <b>Fitur berbayar:</b> Twilight Pass mengharuskan pemain untuk membayar
                        sejumlah uang untuk dapat mengaksesnya.
                      </p>
                      <p className="d-block text-justify mt-1">
                        <b>Hadiah:</b> Pemain yang membeli Twilight Pass akan mendapatkan berbagai
                        macam hadiah, seperti:
                      </p>
                      <p className="d-block text-justify mt-1">
                        <b>Skin permanen:</b> Skin permanen untuk hero tertentu, yang biasanya
                        menjadi hadiah utama.
                      </p>
                      <p className="d-block text-justify mt-1">
                        <b>Tiket:</b> Digunakan untuk meningkatkan level emblem dan membeli item di
                        Toko.
                      </p>
                      <p className="d-block text-justify mt-1">
                        <b>Skin trial:</b>Memberikan kesempatan untuk mencoba skin hero tertentu
                        selama periode tertentu (biasanya 1 hari).
                      </p>
                      <p className="d-block text-justify mt-1">
                        <b>Diamond Hero Trial Card Pack:</b> Memberikan kesempatan untuk mencoba
                        hero tertentu selama periode tertentu (biasanya 1 hari).
                      </p>
                      <p className="d-block text-justify mt-1">
                        <b>Level:</b> Semakin lama pemain bermain dan menyelesaikan misi dalam
                        Twilight Pass, mereka akan semakin naik level dan mendapatkan hadiah
                        tambahan.
                      </p>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="8"
                    className="button-collapse w-100 text-left"
                  >
                    Apa Itu Diamond Dalam Game Mobile Legend (ML)
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="8">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Diamond dalam permainan Mobile Legends: Bang Bang (MLBB) adalah mata uang
                        dalam game yang digunakan untuk melakukan pembelian berbagai item, seperti
                        skin hero, hero baru, emblem, dan berbagai item lainnya yang dapat
                        meningkatkan pengalaman bermain. Diamonds dapat diperoleh melalui pembelian
                        dengan uang sungguhan atau hadiah dari berbagai event dan misi di dalam
                        game. Diamond merupakan aset berharga dalam Mobile Legends yang memungkinkan
                        pemain untuk menyesuaikan tampilan karakter mereka dan meningkatkan kualitas
                        permainan mereka secara keseluruhan.
                      </p>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="9"
                    className="button-collapse w-100 text-left"
                  >
                    Apa Fungsi Diamond ML
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="9">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Diamond dalam (ML) Mobile Legends memiliki beberapa fungsi penting:
                      </p>
                      <p className="d-block text-justify mt-1">
                        <b>Membeli Skin Hero:</b> Salah satu fungsi utama diamond adalah untuk
                        membeli skin atau kostum hero dalam permainan. Skin ini tidak hanya
                        memberikan tampilan yang berbeda untuk hero Anda, tetapi juga dapat
                        meningkatkan tampilan dan efek khusus selama pertempuran, membuat pengalaman
                        bermain menjadi lebih menarik.
                      </p>
                      <p className="d-block text-justify mt-1">
                        <b>Membeli Hero Baru:</b> Diamond juga dapat digunakan untuk membeli hero
                        baru yang tersedia di toko game. Memiliki beragam hero dengan kemampuan yang
                        berbeda dapat memperluas strategi Anda dalam permainan dan meningkatkan
                        kesenangan dalam bermain.
                      </p>
                      <p className="d-block text-justify mt-1">
                        <b>Membeli Emblem dan Fragment:</b> Diamond dapat digunakan untuk membeli
                        emblem, serta fragment yang digunakan untuk meningkatkan level emblem Anda.
                        Emblem memberikan bonus atribut tambahan untuk hero Anda, yang dapat
                        membantu meningkatkan kinerja mereka dalam pertempuran.
                      </p>
                      <p className="d-block text-justify mt-1">
                        <b>Membeli Item Lainnya:</b> Diamond juga dapat digunakan untuk membeli item
                        lainnya dalam game, seperti boost exp atau battle points, ticket untuk acara
                        spesial, serta berbagai item kosmetik dan fungsional lainnya.
                      </p>
                      <p className="d-block text-justify mt-1">
                        <b>Mendukung Pengembangan Game:</b> Dengan membeli diamond, Anda juga turut
                        mendukung pengembangan dan pemeliharaan Mobile Legends oleh pengembang game.
                        Diamond yang dibeli oleh pemain digunakan untuk mendukung pembaruan,
                        pengembangan konten baru, serta penyelenggaraan turnamen dan acara in-game.
                      </p>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="10"
                    className="button-collapse w-100 text-left"
                  >
                    Bisakah Bermain ML (Mobile Legend) Di Laptop ?
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="10">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Mobile Legends secara resmi tidak memiliki versi PC, namun Kamu bisa
                        memainkannya di laptop menggunakan emulator Android. Emulator adalah
                        software yang memungkinkan komputer mu menjalankan aplikasi dan game
                        Android.
                      </p>
                      <div className="d-flex flex-column">
                        <p className="d-block text-justify mt-3">
                          Berikut langkah-langkah dasar memainkan Mobile Legends di laptop
                          menggunakan emulator:
                        </p>
                        <Row className="mt-1">
                          <ol className="text-justify list-content">
                            <li>
                              <p>Download dan instal emulator Android:</p>
                              <Row className="mt-1 flex-column">
                                <p className="d-block">
                                  Ada beberapa pilihan emulator gratis yang populer, seperti:
                                </p>
                                <div>
                                  <ol className="text-justify list-content">
                                    <li>BlueStacks</li>
                                    <li>NoxPlayer</li>
                                    <li>LDPlayer</li>
                                    <li>MEmu Play</li>
                                  </ol>
                                </div>
                              </Row>
                            </li>
                            <li>
                              <p> Buka emulator dan masuk ke Play Store:</p>
                              <Row className="mt-1">
                                <p>
                                  Setelah instalasi emulator selesai, buka aplikasinya dan masuk
                                  menggunakan akun Google Anda.
                                </p>
                              </Row>
                            </li>
                            <li>
                              <p> Cari dan instal Mobile Legends:</p>
                              <Row className="mt-1">
                                <p>
                                  Gunakan Play Store untuk mencari dan mengunduh game Mobile
                                  Legends.
                                </p>
                              </Row>
                            </li>
                            <li>
                              <p> Download data tambahan dan mulai bermain:</p>
                              <Row className="mt-1">
                                <p>
                                  Setelah proses instalasi selesai, Mobile Legends akan mengunduh
                                  data tambahan. Setelah selesai, Kamu bisa mulai bermain game
                                  tersebut.
                                </p>
                              </Row>
                            </li>
                          </ol>
                        </Row>
                      </div>
                      <p className="d-block text-justify">
                        <b>Beberapa hal yang perlu diperhatikan:</b>
                      </p>
                      <p className="d-block text-justify">
                        Menggunakan emulator mungkin tidak didukung oleh developer Mobile Legends
                        dan bisa melanggar kebijakan mereka. Performa game di emulator tergantung
                        pada spesifikasi laptop Anda.Emulator dengan spesifikasi tinggi umumnya
                        membutuhkan resource komputer yang besar.
                      </p>
                      <p className="d-block text-justify">
                        Selain itu, ada alternatif lain untuk bermain game selain menggunakan
                        emulator, yaitu dengan menggunakan cloud gaming. Cloud gaming memungkinkan
                        Anda untuk bermain game secara langsung di browser web tanpa perlu instalasi
                        software tambahan. Namun, cloud gaming biasanya membutuhkan koneksi internet
                        yang stabil dan cepat.
                      </p>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="11"
                    className="button-collapse w-100 text-left"
                  >
                    Cara Melihat ID Pemain Lain Mobile Legend
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="11">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Untuk melihat ID pemain lain di Mobile Legends, Kamu bisa mengikuti
                        langkah-langkah berikut:
                      </p>

                      <p className="d-block text-justify mt-1">
                        <b>Buka Profil Pemain</b>: Masuklah ke dalam permainan Mobile Legends dan
                        buka menu utama.
                      </p>
                      <p className="d-block text-justify mt-1">
                        <b>Pilih Tab &ldquo;Pemain&rdquo;</b>: Pada menu utama, cari dan pilih tab
                        yang berjudul &ldquo;Pemain&rdquo;. Biasanya, tab ini berada di bagian bawah
                        layar atau di bagian atas, tergantung pada versi permainan.
                      </p>
                      <p className="d-block text-justify mt-1">
                        <b>Cari Nama Pemain</b>: Di dalam tab &ldquo;Pemain&rdquo;, Kamu akan
                        melihat daftar pemain yang mungkin terdiri dari teman-teman, pemain yang
                        baru saja Anda mainkan dengannya, atau pemain yang Kamu ikuti. Gunakan
                        fungsi pencarian atau gulir ke bawah untuk menemukan nama pemain yang lagi
                        di cari.
                      </p>
                      <p className="d-block text-justify mt-1">
                        <b>Klik Profil Pemain</b>: Setelah menemukan nama pemain yang di cari, klik
                        atau ketuk profil pemain tersebut untuk melihat detailnya.
                      </p>
                      <p className="d-block text-justify mt-1">
                        <b>Lihat ID Pemain</b>: Di dalam profil pemain, Kamu akan melihat berbagai
                        informasi, termasuk ID pemain. Biasanya, ID pemain ditampilkan di bagian
                        atas atau bawah profil. ID pemain dapat berupa serangkaian angka yang unik.
                      </p>
                      <p className="d-block text-justify mt-1">
                        Dengan mengikuti langkah-langkah di atas, Kamu dapat melihat ID pemain lain
                        di Mobile Legends dengan mudah. Ini dapat berguna jika Anda ingin
                        menambahkan mereka sebagai teman atau melakukan interaksi lain di dalam
                        permainan.
                      </p>
                      <p className="d-block text-justify mt-1">
                        Demikian informasi mengenai tempat top up ML di SpeedCash termurah, selain
                        bisa top up Diamond mobile legend di SpeedCash, SpeedCash juga bisa kamu
                        gunakan untuk: top up diamond FF, cek tagihan BPJS, beli pulsa ke semua
                        supplier dengan harga termurah. kenaikan harga untuk membayar pajak mobil
                        secara online, gratis transfer antar bank dan masih banyak layanan SpeedCash
                        lainnya yang bisa Kamu gunakan.
                      </p>
                      <div className="mt-1 w-100 d-flex justify-content-center">
                        <h5 className="bold">Download Aplikasi Top Up ML Murah Disini : </h5>
                      </div>
                      <Row className="d-flex w-100 justify-content-center mb-3 gap-3">
                        <a
                          aria-hidden
                          href="https://play.google.com/store/apps/details?id=com.bm.sc.bebasbayar&listing=topup-ml"
                          className="mx-1"
                        >
                          <Image
                            src="/img/playstore-speedcash.png"
                            data-target="playstore-speedcash"
                            width="220px"
                            height="60px"
                          />
                        </a>
                        <a
                          aria-hidden
                          href="https://apps.apple.com/id/app/speedcash-transfer-ewallet/id6459304122"
                          className="mx-1"
                        >
                          <Image
                            src="/img/appstore-speedcash.png"
                            data-target="appstore-speedcash"
                            width="220px"
                            height="60px"
                          />
                        </a>
                      </Row>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default AccordionGameMl
